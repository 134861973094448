import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Input,
  Select,
  Form,
  Divider,
  Modal,
  Upload,
  Icon,
  Row,
  Col,
  message,
} from "antd";
import {
  _imageUpload,
  _bikeModelList,
  _updateBikeModelStatus,
  _deleteBikeModel,
  _bikeModelDetail,
  _saveBikeModel,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const { Option } = Select;
const modalTitle = ["添加车辆型号", "编辑车辆型号"];
class BikeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      fileList: [], //上传格式
      bikeModelList: [], //车辆型号列表
      initialValues: {}, //车辆详情
      id: "", //电池型号id
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      disableForEdit: false,
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "名称",
        dataIndex: "name",
      },
      {
        title: "添加时间",
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "车辆数",
        align: "right",
        width: 100,
        dataIndex: "bikeCount",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            disabled={!getJurisdiction(761)}
            title={getJurisdiction(761) ? "" : "暂无操作权限！"}
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(760)}
              title={getJurisdiction(760) ? "" : "暂无操作权限！"}
              onClick={this.getVehicleModelDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            {!!(text.bikeCount === 0) && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(762)}
                  title={getJurisdiction(762) ? "" : "暂无操作权限！"}
                  onClick={this.handleDeleteChange.bind(this, text.id)}
                >
                  删除
                </Button>
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="名称">
              {getFieldDecorator("nameSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否自营">
              {getFieldDecorator("selfSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">自营</Option>
                  <Option value="0">非自营</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">禁用</Option>
                  <Option value="1">正常</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      bikeModelList,
      initialValues,
      fileList,
      pageSize,
      totalCount,
      curPage,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 5 },
        sm: { span: 12, offset: 5 },
        md: { span: 10 },
      },
    };
    return (
      <div>
        {!!getJurisdiction(759) ? (
          <div className="BikeModel">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(764)}
                title={getJurisdiction(764) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                新增
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={bikeModelList}
                scroll={{ x: 1000, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="型号名称">
                    {getFieldDecorator("name", {
                      initialValue: initialValues.name,
                      rules: [{ required: true, message: "请输入型号名称！" }],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车型分类">
                    {getFieldDecorator("modelType", {
                      initialValue: initialValues.modelType,
                      rules: [{ required: true, message: "请选择车型分类！" }],
                    })(
                      <Select
                        placeholder="请选择"
                        allowClear
                        style={{ width: "100%" }}
                      >
                        <Option value={1}>两轮车</Option>
                        <Option value={2}>三轮车</Option>
                        <Option value={3}>四轮车</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="是否自营">
                    {getFieldDecorator("self", {
                      initialValue:
                        typeof initialValues.self === "number"
                          ? initialValues.self
                          : 1,
                    })(
                      <Select
                        placeholder="请选择"
                        allowClear
                        style={{ width: "100%" }}
                      >
                        <Option value={1}>自营</Option>
                        <Option value={0}>非自营</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="图片">
                    {getFieldDecorator("img", {
                      initialValue: initialValues.img_id || undefined,
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        name="uploadFile"
                        action={_imageUpload}
                        listType="picture-card"
                        fileList={fileList}
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                        onChange={this.handleChange.bind(this)}
                        data={{
                          scene: "active",
                        }}
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <Icon type="plus" style={{ fontSize: "28px" }} />
                            <p>车辆型号上传</p>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {this.renderPeriodItems(
                    formItemLayout,
                    formItemLayoutWithOutLabel
                  )}
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getVehicleModelList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  addPeriodItem = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("periodKeys");
    const nextKey = keys.concat(keys[keys.length - 1] + 1);

    form.setFieldsValue({
      periodKeys: nextKey,
    });
  };

  removePeriodItem = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue("periodKeys");
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      periodKeys: keys.filter((key) => key !== k),
    });
  };

  //自定义input参数
  renderPeriodItems = (formItemLayout, formItemLayoutWithOutLabel) => {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;
    const { initialValues, disableForEdit } = this.state;
    let activeKeys = [];
    let editCustomN = {};
    let editCustomV = {};
    if (initialValues.paramList) {
      initialValues.paramList.forEach((item, index) => {
        activeKeys.push(index);
        editCustomN[`key_${index}`] = item.keyName;
        editCustomV[`key_${index}`] = item.keyValue;
      });
    }
    getFieldDecorator("periodKeys", {
      initialValue: activeKeys.length > 0 ? activeKeys : [0],
    });
    const periodKeys = getFieldValue("periodKeys");
    const periodItems = periodKeys.map((k, index) => {
      return (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? "自定义参数" : ""}
          key={`handle_period_${k}`}
        >
          {getFieldDecorator(`keyName['key_${k}']`, {
            initialValue: editCustomN[`key_${k}`]
              ? editCustomN[`key_${k}`]
              : undefined,
            validateTrigger: ["onChange", "onBlur"],
          })(
            <Input
              disabled={disableForEdit}
              placeholder="请输入参数名"
              style={{ width: "40%", marginRight: 10 }}
            />
          )}
          {getFieldDecorator(`keyValue['key_${k}']`, {
            initialValue: editCustomV[`key_${k}`]
              ? editCustomV[`key_${k}`]
              : undefined,
            validateTrigger: ["onChange", "onBlur"],
          })(
            <Input
              disabled={disableForEdit}
              placeholder="请输入参数值"
              style={{ width: "40%", marginRight: 10 }}
            />
          )}
          {periodKeys.length > 1 && !disableForEdit ? (
            <Icon
              type="minus-circle-o"
              disabled={periodKeys.length === 1}
              onClick={() => this.removePeriodItem(k)}
            />
          ) : null}
        </Form.Item>
      );
    });
    if (!disableForEdit) {
      periodItems.push(
        <Form.Item key="last" {...formItemLayoutWithOutLabel}>
          <Button
            type="dashed"
            onClick={this.addPeriodItem}
            style={{ width: "40%" }}
          >
            <Icon type="plus" /> 添加参数
          </Button>
        </Form.Item>
      );
    }

    return periodItems;
  };

  //获取车辆型号列表
  getVehicleModelList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _bikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeModelList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getVehicleModelList(curPage, pageSize, this.state.queryFormValues);
  }

  //车辆模型状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateBikeModelStatus(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getVehicleModelList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.self;
      delete values.periodKeys;
      delete values.keyName;
      delete values.keyValue;
      if (values.nameSearch) {
        values.name = values.nameSearch;
        delete values.nameSearch;
      }
      if (values.selfSearch) {
        values.self = values.selfSearch;
        delete values.selfSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getVehicleModelList(1, this.state.pageSize, values);
    });
  };

  //删除型号
  handleDeleteChange(id) {
    let self = this;
    Modal.confirm({
      title: "删除信息",
      content: "是否删除此数据？",
      onOk() {
        let params = { id };
        _deleteBikeModel(params)
          .then((res) => {
            console.log(res);
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getVehicleModelList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  }

  //获取车辆型号详情
  getVehicleModelDetail(id, type) {
    let params = { id };
    _bikeModelDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: "done",
                url: res.data.data.imgObject.path,
              },
            ]);
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.img && values.img.constructor === Array) {
        values.img =
          values.img.length === 1 ? values.img[0].response.data.id : "";
      }
      let parameters = [];
      let flg = true;
      values.periodKeys.map((item, index) => {
        if (values.keyName[`key_${index}`] && values.keyValue[`key_${index}`]) {
          parameters[index] = {
            keyName: values.keyName[`key_${index}`],
            keyValue: values.keyValue[`key_${index}`],
          };
        } else if (
          (values.keyName[`key_${item}`] && !values.keyValue[`key_${item}`]) ||
          (!values.keyName[`key_${item}`] && values.keyValue[`key_${item}`])
        ) {
          flg = false;
        }
        return item;
      });
      values.params = parameters;
      if (modalType === 1) {
        values.status = 1;
      }
      delete values.keyName;
      delete values.keyValue;
      delete values.periodKeys;
      if (flg) {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveBikeModel(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.warning("自定义参数请填写完整！");
      }
    });
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //updata end

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.updateFileList([]);
    this.setState({
      id: "",
      initialValues: {},
      visible: false,
    });
  }
}
BikeModel = Form.create({})(BikeModel);
export { BikeModel };
