import React,{
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';
var myChart = null
const Index = forwardRef((props,ref) => {

    useEffect(()=>{
      myChart = echarts.init(document.getElementById('v_card'));
    },[])

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);

    var init = ({TimeXScale,data})=>{
        var options = {
            grid: {
                left: '2%',
                right: '2%',
                bottom: '10%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: TimeXScale,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} V'
                },
                splitLine: {
                    lineStyle: {
                      type: "dashed",
                      color:"rgba(255,255,255,.1)"
                    },
                }
            },
            series: [
                {
                  data: data,
                  type: 'line',
                }
            ]
        }
        myChart.setOption(options)
    }

    return (
        <>
            <div id="v_card" style={{height: '100%',width: '100%'}}></div>
        </>
    )
})

export default Index