import './index.less'
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Form,
  message,
  Input,
  Spin,
  Row,
  Col,
  Switch,
  Icon,
  InputNumber,
} from 'antd'
import {
  _getRuleDetails,
  _getRuleDetailsById,
  _savePackageTranferRuleInfo,
} from '@/statics/js/api.js'
import BigNumber from 'bignumber.js'

const Index = Form.create({})(
  forwardRef((props, ref) => {
    const { form, by } = props
    const {
      getFieldDecorator,
      resetFields,
      getFieldValue,
      setFieldsValue,
      validateFields,
    } = form
    const [id, setId] = useState('')
    const [modalType, setModalType] = useState('')
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [tempParams, setTempParams] = useState('')

    const init = async (type, { id = '' }) => {
      setModalType(type)
      setId(id)
      resetFields()
      setInitialValues({})
      if (type !== 1) {
        await getDetail(id)
      }
    }
    const getDetail = async (id) => {
      const params = {
        id,
      }
      setLoading(true)
      const res = await (by == 'id'
        ? _getRuleDetailsById(params)
        : _getRuleDetails(params))
      setLoading(false)
      const { code, data = {}, message: msg } = res.data || {}
      if (code !== 200) {
        message.error(msg)
        return
      }
      setInitialValues(data || {})
    }
    const handleOk = async () => {
      if (modalType === 3) {
        return
      }
      let values = await validateFields()
      let params = {
        ...values,
        packageTransferStatus: values.packageTransferStatus ? 1 : 0,
      }
      if (id) {
        params.pid = id
        params.fid = initialValues.fid
        params.id = initialValues.id || undefined
      }
      if (JSON.stringify(params) === JSON.stringify(tempParams)) {
        return { id }
      } else {
        setTempParams(params)
      }
      const res = await _savePackageTranferRuleInfo(params)
      const { code, message: msg } = res.data || {}
      if (code !== 200) {
        message.warning(msg)
        return
      }
      message.success('操作成功')
      return { id }
    }
    const handleNotTransferMoneyChange = async (e) => {
      const { value } = e.target || {}
      setTransferMoney({
        custServiceMoney: getFieldValue('custServiceMoney'),
        notTransferMoney: value ? parseFloat(value) : 0,
        transferRatio: getFieldValue('transferRatio'),
      })
    }
    const handleCustServiceMoneyChange = async (e) => {
      const { value } = e.target || {}
      setTransferMoney({
        custServiceMoney: value ? parseFloat(value) : 0,
        notTransferMoney: getFieldValue('notTransferMoney'),
        transferRatio: getFieldValue('transferRatio'),
      })
    }
    const handleTransferRatioChange = async (value) => {
      setTransferMoney({
        custServiceMoney: getFieldValue('custServiceMoney'),
        notTransferMoney: getFieldValue('notTransferMoney'),
        transferRatio: value,
      })
    }
    const handleInsureRatioChange = async (value) => {
      setInsureMoney({
        insureRatio: value,
      })
    }
    const setTransferMoney = async ({
      custServiceMoney,
      notTransferMoney,
      transferRatio,
    }) => {
      custServiceMoney = custServiceMoney ? parseFloat(custServiceMoney) : 0
      notTransferMoney = notTransferMoney ? parseFloat(notTransferMoney) : 0
      const { pmoney = 0 } = initialValues || {}
      const ratio = (transferRatio || 0) / 100
      let platformTransferAmount = pmoney * ratio + custServiceMoney
      platformTransferAmount =
        platformTransferAmount < 0
          ? 0
          : (platformTransferAmount + 0.000001).toFixed(2)
      let custTransferAmount =
        pmoney - parseFloat(platformTransferAmount) - notTransferMoney
      custTransferAmount =
        custTransferAmount < 0 ? 0 : (custTransferAmount + 0.000001).toFixed(2)
      setInitialValues((value) => {
        return {
          ...value,
          platformTransferAmount,
          custTransferAmount,
        }
      })
    }
    const setInsureMoney = async ({ insureRatio }) => {
      const { insureMoney = 0 } = initialValues || {}
      const ratio = (insureRatio || 0) / 100
      let platformInsureAmount = insureMoney * ratio
      platformInsureAmount =
        platformInsureAmount < 0
          ? 0
          : (platformInsureAmount + 0.000001).toFixed(2)
      let custInsureAmount = insureMoney - parseFloat(platformInsureAmount)
      custInsureAmount =
        custInsureAmount < 0 ? 0 : (custInsureAmount + 0.000001).toFixed(2)
      setInitialValues((value) => {
        return {
          ...value,
          platformInsureAmount,
          custInsureAmount,
        }
      })
    }
    useImperativeHandle(
      ref,
      () => ({
        init,
        handleOk,
      }),
      [init, handleOk]
    )
    return (
      <Form>
        <Spin spinning={loading}>
          <Row>
            <Col span={12}>
              <Form.Item label="代理商">
                <span>{initialValues.fname}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="套餐金额">
                <span>{initialValues.pmoney}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="套餐名称">
                <span>{initialValues.pname}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="免赔服务">
                <span>{initialValues.insureMoney}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="套餐基础服务费（元）">
                <span>{initialValues.serviceMoney}</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="商家基础服务费">
                {modalType === 3 ? (
                  <span>{initialValues.custServiceMoney}</span>
                ) : (
                  getFieldDecorator('custServiceMoney', {
                    initialValue: initialValues.custServiceMoney,
                    rules: [
                      {
                        message: '请输入正确的金额（保留两位小数）',
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                      },
                    ],
                  })(
                    <Input
                      allowClear
                      placeholder="请输入"
                      onChange={handleCustServiceMoneyChange}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="套餐不即时分润金额（元）">
                {modalType === 3 ? (
                  <span>{initialValues.notTransferMoney}</span>
                ) : (
                  getFieldDecorator('notTransferMoney', {
                    initialValue: initialValues.notTransferMoney,
                    rules: [
                      {
                        message: '请输入正确的金额（保留两位小数）',
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                      },
                    ],
                  })(
                    <Input
                      allowClear
                      placeholder="请输入"
                      onChange={handleNotTransferMoneyChange}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="套餐分润平台占比（%）">
                {modalType === 3 ? (
                  <span>{initialValues.transferRatio}</span>
                ) : (
                  getFieldDecorator('transferRatio', {
                    initialValue: initialValues.transferRatio,
                    rules: [{ required: true, message: '请输入百分比' }],
                  })(
                    <InputNumber
                      allowClear
                      min={0}
                      max={100}
                      precision={2}
                      onChange={handleTransferRatioChange}
                    />
                  )
                )}
                <Row className="benefit_money">
                  <Col span={10}>
                    平台分润金额：{initialValues.platformTransferAmount}元
                  </Col>
                  <Col span={14}>
                    代理商分润金额：{initialValues.custTransferAmount}元
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="免赔服务分润平台占比（%）">
                {modalType === 3 ? (
                  <span>{initialValues.insureRatio}</span>
                ) : (
                  getFieldDecorator('insureRatio', {
                    initialValue: initialValues.insureRatio,
                    rules: [{ required: true, message: '请输入百分比' }],
                  })(
                    <InputNumber
                      allowClear
                      min={0}
                      max={100}
                      precision={2}
                      onChange={handleInsureRatioChange}
                    />
                  )
                )}
                <Row className="benefit_money">
                  <Col span={10}>
                    平台分润金额：{initialValues.platformInsureAmount}元
                  </Col>
                  <Col span={14}>
                    代理商分润金额：{initialValues.custInsureAmount}元
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="转账账户名称">
                {modalType === 3 ? (
                  <span>{initialValues.transferAccountName}</span>
                ) : (
                  getFieldDecorator('transferAccountName', {
                    initialValue: initialValues.transferAccountName,
                    rules: [{ required: true, message: '请输入账户名称' }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="转账账户">
                {modalType === 3 ? (
                  <span>{initialValues.transferAccount}</span>
                ) : (
                  getFieldDecorator('transferAccount', {
                    initialValue: initialValues.transferAccount,
                    rules: [{ required: true, message: '请输入账户' }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="备注">
                {modalType === 3 ? (
                  <span>{initialValues.remark}</span>
                ) : (
                  getFieldDecorator('remark', {
                    initialValue: initialValues.remark,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <div className="account_tip">
                  <div className="account_tip_item">
                    订单分润计算公式:给代理商即时分润=支付金额-套餐基础服务费-商家基础服务费-(套餐金额(下单时套餐金额)*订单分润平台占比+免赔服务(下单时免赔服务)*免赔分润平台占比+不分润金额)
                  </div>
                  <div className="account_tip_item">
                    订单分润计算公式(日还款):(给代理商即时分润=支付金额-套餐基础服务费-商家基础服务费-(套餐金额(下单时套餐金额)*订单分润平台占比+免赔服务(下单时免赔服务)*免赔分润平台占比+不分润金额))/日还款期数
                  </div>
                </div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="管理状态">
                {getFieldDecorator('packageTransferStatus', {
                  initialValue: !!initialValues.packageTransferStatus,
                  valuePropName: 'checked',
                })(
                  <Switch
                    disabled={modalType === 3}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    )
  })
)

export default Index
