import React, { useState } from "react";
import { Upload, message, Icon } from "antd";
import { _uploadBattery } from "@/statics/js/api";
import "./index.less";
const Index = () => {
  const handleChange = ({ file, fileList }) => {
    if (!!file.response) {
      if (file.response.code !== 200) {
        message.warning(file.response.message);
        setShow(false);
      } else {
        setShow(true);
        message.success("执行成功！");
      }
    }
    setFileList(fileList);
  };
  const [fileList, setFileList] = useState([]);
  const [show, setShow] = useState(false);
  return (
    <div className="uploadContent">
      {show ? (
        <div className="uploadSuccess">
          <Icon type="check-circle" style={{ fontSize: "28px" }} />
          <p>执行成功</p>
        </div>
      ) : (
        <Upload
          className="upload"
          name="file"
          action={_uploadBattery}
          onChange={handleChange}
        >
          {fileList.length >= 1 ? null : (
            <div className="uploadSuccess">
              <Icon type="upload" style={{ fontSize: "28px" }} />
              <p>文件上传</p>
            </div>
          )}
        </Upload>
      )}
    </div>
  );
};

export default Index;
