import React,{ 
    useEffect,
    forwardRef,
} from 'react'
import useList from '@/hooks/list'
import {
    Select,
} from "antd";
const { Option } = Select;

const Index = forwardRef((props,ref)=>{
    const fid = localStorage.getItem("fid")
    const userType = localStorage.getItem("type")
    const {
        getSiteList,
        siteList,
        setSiteList
    } = useList()

    useEffect(() => {
        init()
    },[props.fid]);
    const init = async ()=>{
        setSiteList([])
        if(userType == 0){
            if(props.fid){
                getSiteList(props.fid)
            }
        }else{
            getSiteList(fid)
        }
    }
    return (
        <Select
            placeholder="请选择"
            allowClear
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...props}
        >
            {
                siteList.map((item, index) => (
                    item.sstate ?
                    <Option key={index} value={item.sid}>{item.sname}</Option>
                    :
                    null
                ))
            }
        </Select>
    )
})

export default Index