import React,{ 
    useEffect,
    forwardRef,
} from 'react'
import useList from '@/hooks/list'
import {
    Select,
} from "antd";
const { Option } = Select;

const Index = forwardRef((props,ref)=>{
    const {config={}} = props
    const {
        packageList,
        getPackageList,
    } = useList()

    useEffect(() => {
        getPackageList()
    },[]);
    
    return (
        <Select
            placeholder="请选择"
            allowClear
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...props}
        >
            {
                packageList.map((item, index) => <Option key={item.id} value={item[config.value || 'id']}>{item[config.label || 'pname']}</Option>)
            }
        </Select>
    )
})

export default Index