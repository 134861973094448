import React, { useRef } from "react";
import SearchBox from "./components/searchBox";
import CommonTable from "@/components/commonTable";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _dayDayRepaymentInfoList } from "@/statics/js/api";
import { Link } from "react-router-dom";
const MonthlyPayment = (props) => {
  const tableRef = useRef();
  const handleSearch = async (params) => {
    tableRef.current.handleSearch(params);
  };
  const columns = [
    {
      title: "关联的租赁订单号",
      dataIndex: "orderSn",
      width: 180,
      render: (text) => (
        <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
          {text}
        </Link>
      ),
    },
    {
      title: "代理商",
      width: 160,
      dataIndex: "agentName",
    },
    {
      title: "站点",
      width: 150,
      dataIndex: "siteName",
    },
    {
      title: "对应的月期数",
      width: 120,
      dataIndex: "month",
      render: (text) => <span>第{text}期</span>,
    },
    {
      title: "扣款金额",
      width: 100,
      dataIndex: "payAmount",
    },
    {
      title: "期数",
      width: 80,
      dataIndex: "period",
    },
    {
      title: "流水号",
      width: 260,
      dataIndex: "tradeNo",
    },
    {
      title: "计划扣款时间",
      width: 120,
      dataIndex: "deductTime",
    },
    {
      title: "实际扣款时间",
      width: 160,
      dataIndex: "payTime",
    },
    {
      title: "扣款状态",
      width: 100,
      dataIndex: "statusName",
    },
    {
      title: "代扣方式",
      width: 100,
      dataIndex: "payType",
    },
    {
      title: "备注",
      width: 150,
      dataIndex: "remark",
    },
  ];

  return !getJurisdiction(1104) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox orderSn={props.orderSn} search={handleSearch} />
      </div>
      <CommonTable
        action={_dayDayRepaymentInfoList}
        auto={false}
        showSort
        pageSizeKey="rows"
        curPageKey="page"
        options={{
          columns: columns,
          scroll: { x: 1600, y: 500 },
        }}
        ref={tableRef}
      />
    </div>
  );
};

export default MonthlyPayment;
