import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  DatePicker,
  Select,
  message,
  Row,
  Col,
} from "antd";
import {
  _franchiseeList,
  _orderStaticList,
  _exportOrderStatic,
} from "@/statics/js/api";
import moment from "moment";

import { getJurisdiction } from "@/statics/js/jurisdiction";
const Option = Select.Option;
class ReportManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商列表
      packageOrderList: [], //套餐订单列表
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "联系人",
        width: 100,
        dataIndex: "fcontacts",
      },
      {
        title: "城市",
        width: 100,
        dataIndex: "cityName",
      },
      {
        title: "新增订单",
        align: "right",
        width: 100,
        dataIndex: "newOrderNum",
      },
      {
        title: "续租订单",
        align: "right",
        width: 100,
        dataIndex: "reletOrderNum",
      },
      {
        title: "租车中订单",
        align: "right",
        width: 110,
        dataIndex: "rentOrderNum",
      },
      {
        title: "申请退租订单",
        align: "right",
        width: 120,
        dataIndex: "tuizuOrderNum",
      },
      {
        title: "待提车订单",
        align: "right",
        width: 110,
        dataIndex: "waitOrderNum",
      },
      {
        title: "库存车辆",
        align: "right",
        width: 100,
        dataIndex: "bikeNum",
      },
      {
        title: "总车辆",
        align: "right",
        width: 100,
        dataIndex: "bikeTotalNum",
      },
      {
        title: "库存电池",
        align: "right",
        width: 100,
        dataIndex: "batteryNum",
      },
      {
        title: "总电池",
        align: "right",
        width: 100,
        dataIndex: "batteryTotalNum",
      },
    ];
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6}  md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator("fid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6}  md={12} sm={24}>
            <Form.Item label="下单时间">
              {getFieldDecorator("searchDate")(<DatePicker />)}
            </Form.Item>
          </Col>
          <Col lg={6}  md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(825)}
                title={getJurisdiction(825) ? "" : "暂无操作权限！"}
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, packageOrderList } = this.state;
    return (
      <div>
        {getJurisdiction(824) ? (
          <div className="ReportManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="fid"
                bordered
                columns={this.columns}
                dataSource={packageOrderList}
                scroll={{ x: 1500, y: 500 }}
                pagination={{
                  showQuickJumper:true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getPackageOrderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取订单报表
  getPackageOrderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _orderStaticList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            packageOrderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageOrderList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.searchDate) {
        values.searchDate = moment(values.searchDate).format("YYYY-MM-DD");
      }
      this.setState({
        queryFormValues: values,
      });
      this.getPackageOrderList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.searchDate) {
        values.searchDate = moment(values.searchDate).format("YYYY-MM-DD");
      }
      let params = {
        ...values,
      };
      _exportOrderStatic(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

ReportManagement = Form.create({})(ReportManagement);
export { ReportManagement };