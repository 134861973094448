import React from "react";
import { useEffect, useState } from "react";
import { _imageUpload, _baoUpdateComplain } from "@/statics/js/api.js";
import { Form, Input, Modal, Upload, Select, Icon, message } from "antd";
const { Option } = Select;

var Index = (props) => {
  const { visible, close, form, info, done = () => {} } = props;
  const { getFieldDecorator } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (visible) {
      setFileList([]);
      form.resetFields();
    }
  }, [visible]);
  // 关闭
  const handleCancel = async () => {
    close();
  };
  // 提交
  const handleOk = async () => {
    form.validateFields((err, values) => {
      if (err) return;
      if (values.imageList && values.imageList.constructor === Array) {
        let imgs = [];
        // let file = [];
        values.imageList.map((item, index) => {
          if (item.response) {
            imgs.push(item.response.data.id);
             values[`file${index+1}`]= item.originFileObj
            // file = item.originFileObj;
          }
          return item;
        });
        values.imgs = imgs.join(",");
        // values.file = file;
      }
      delete values.imageList;
      const params = {
        id: info.id,
        complainEventId: info.complainEventId,
        ...values,
      };
      handleSave(params);
    });
  };
  // 保存
  const handleSave = async (params) => {
    let formdata = new FormData();
    for (let key in params) {
      formdata.append(key, params[key]);
    }
    setConfirmLoading(true);
    const res = await _baoUpdateComplain(formdata);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };

  const handleChange = ({ file, fileList }) => {
    console.log(file, fileList);
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    setFileList(fileList);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div>
      <Modal
        title="处理投诉"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Form.Item label="投诉工单">
            <span>{info.complainEventId}</span>
          </Form.Item>
          <Form.Item label="反馈类目">
            {getFieldDecorator("feedbackCode", {
              rules: [{ required: true, message: "请选择反馈类目！" }],
            })(
              <Select placeholder="请选择" style={{ width: "100%" }}>
                <Option value="00">使用体验保障金退款</Option>
                <Option value="02">通过其他方式退款</Option>
                <Option value="03">已发货</Option>
                <Option value="04">其他</Option>
                <Option value="05">已完成售后服务</Option>
                <Option value="06">非我方责任范围</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="反馈内容">
            {getFieldDecorator("feedbackContent", {
              rules: [{ required: true, message: "请填写反馈内容！" }],
            })(<Input.TextArea rows={4} placeholder="请输入投诉处理的内容" />)}
          </Form.Item>
          <Form.Item label="上传图片">
            {getFieldDecorator("imageList", {
              getValueFromEvent: normFile,
            })(
              <Upload
                name="uploadFile"
                action={_imageUpload}
                listType="picture-card"
                fileList={fileList}
                accept="image/jpg,image/jpeg,image/png,image/gif"
                onChange={handleChange}
                data={{
                  scene: "active",
                }}
              >
                {fileList.length >= 4 ? null : (
                  <div>
                    <Icon type="plus" style={{ fontSize: "28px" }} />
                    <p>图片上传</p>
                  </div>
                )}
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

Index = Form.create({})(Index);
export default Index;
