import React, { 
    useEffect,
    useState,
    useRef
} from 'react';
import {
    Row,
    Col,
} from "antd";
import {
    _batteryInfo,
    _historicalBatInfo,
} from "@/statics/js/api.js"
import "./index.less"
import moment from "moment";
import BarCard from "./components/barCard";
import PositionCard from "./components/positionCard";
import VoltageCard from "./components/voltageCard";
import CurrentCard from "./components/currentCard";
import RemainCard from "./components/remainCard";
import queryString from 'query-string';

const Index = (props)=> {
    const barRef = useRef();
    const positionRef = useRef();
    const voltageRef = useRef();
    const currentRef = useRef();
    const remainRef = useRef();
    console.log(props ,'props');
    // const {id} = props.location.query
    // 升级router v4 移除了 query ,通过search解析
    const { id } =  queryString.parse(props.location.search)
    const [info,setInfo] = useState({})
    const [time,setTime] = useState('')
    useEffect(() => {
        init()
        runTime()
    },[]);
    const runTime = async ()=>{
        setInterval(()=>{
            setTime(moment().format("YYYY-MM-DD HH:mm:ss") + moment().format('dddd'))
        },1000)
    }
    const init = async ()=>{
        await Promise.all([
            getInfo(),
            getLine()
        ])
        setTimeout(()=>{
            init()
        },5000)
    }
    const getInfo = async () => {
        const params = {
          id,
        };
        const res = await _batteryInfo(params);
        const { data={}, code } = res.data || {};
        if (code !== 200) {
          return;
        }
        setInfo(data)
        barRef.current.init(data.cellList || [])
        positionRef.current.init({
            position: [parseFloat(data.lng),parseFloat(data.lat)],
            time: data.locTime,
            address: data.address
        })
    }
    const getLine = async ()=>{
        const params = {
            id,
            startTime: moment(Date.parse(new Date()) - (20*60*1000)).format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        }
        const res = await _historicalBatInfo(params)
        const {data={},code} = res.data || {}
        if(code !== 200 || !data){
            return
        }
        const {batteryHisVOlist=[]} = data || {}
        if(!batteryHisVOlist.length){
            return
        }
        let TimeXScale = []
        let voltage = []
        let current = []
        let remain = []
        let list = batteryHisVOlist.slice(0,30).reverse()
        list.map(item=>{
            let time = item.time.split(' ') || []
            TimeXScale.push(time[1])
            voltage.push(item.voltage || 0)
            current.push(item.current)
            remain.push(parseFloat(item.remain || 0))
        })
        voltageRef.current.init({
            TimeXScale,
            data: voltage,
        })
        currentRef.current.init({
            TimeXScale,
            data: current,
        })
        remainRef.current.init({
            TimeXScale: 0,
            data: remain,
            current,
        })
    }
    return (<div className="wrap">
        <div className="header">
            <Row>
                <Col span={8}>
                    <div className="header_left">云马超充 <span>充电就像呼吸一样简单</span></div>
                </Col>
                <Col span={8}>
                    <div className="header_title">智慧云马</div>
                </Col>
                <Col span={8}>
                    <div className="header_right">{time}<p></p></div>
                </Col>
            </Row>
            <Row gutter={16} className="statics_wrap">
                <Col span={8}>
                    <div className="statics">
                        <div className="statics_label">总充电次数</div>
                        <div className="statics_value">{info?.chargeNumTotal || '-'}</div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className="statics">
                        <div className="statics_label">总充电量</div>
                        <div className="statics_value">{info?.chargeSumTotal || '-'} AH</div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className="statics">
                        <div className="statics_label">总充电时长</div>
                        <div className="statics_value">{info?.chargeTimeTotal || '-'}</div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="board">
            <Row className="board_row">
                <Col span={8} className="sheet">
                    <div className="sheet_header">电池基本信息</div>
                    <div className="sheet_content row_box">
                        <div className="row">
                            <div className="row_label">电池型号</div>
                            <div className="row_value">{info?.modelName}</div>
                        </div>
                        <div className="row">
                            <div className="row_label">电池编码</div>
                            <div className="row_value">{info?.uid}</div>
                        </div>
                        <div className="row">
                            <div className="row_label">数据采集时间</div>
                            <div className="row_value">{info?.batTime}</div>
                        </div>
                        <div className="row">
                            <div className="row_label">电池状态</div>
                            <div className="row_value">{info?.chargeStatusName}</div>
                        </div>
                    </div>
                </Col>
                <Col span={8} className="sheet">
                    <div className="sheet_header">电池电量</div>
                    <div className="sheet_content">
                        <RemainCard ref={remainRef} />
                    </div>
                </Col>
                <Col span={8} className="sheet">
                    <div className="sheet_header">电池电流</div>
                    <div className="sheet_content">
                        <CurrentCard ref={currentRef} />
                    </div>
                </Col>
            </Row>
            <Row className="board_row">
                <Col span={8} className="sheet">
                    <div className="sheet_header">电池当前位置</div>
                    <div className="sheet_content">
                        <PositionCard ref={positionRef} />
                    </div>
                </Col>
                <Col span={8} className="sheet">
                    <div className="sheet_header">电芯电压</div>
                    <div className="sheet_content">
                        <BarCard ref={barRef} />
                    </div>
                </Col>
                <Col span={8} className="sheet">
                    <div className="sheet_header">电池电压</div>
                    <div className="sheet_content">
                        <VoltageCard ref={voltageRef} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>)
}

export default Index