import React, { useState, useImperativeHandle, forwardRef } from "react";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";
import {
  _imageUpload,
  _queryTjUser,
  _saveTjConfig,
  _tjConfigDetail,
  _getAccountInfo,
  _addAccountBalance,
  _getAccountPayCode,
} from "@/statics/js/api.js";
import {
  Col,
  Row,
  Form,
  Input,
  Modal,
  Radio,
  Switch,
  message,
  InputNumber,
} from "antd";

// 弹框
var RuleModal = Form.create({})(
  forwardRef((props, ref) => {
    const { done, form } = props;
    const { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } =
      form;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [recommendList, setRecommendList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState(0);
    const [id, setId] = useState("");
    const userType = localStorage.getItem("type");
    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );
    var open = async ({ type, id }) => {
      setVisible(true);
      setType(type);
      resetFields();
      setInitialValues({});
      setRecommendList([]);
      setId(id);
      if (type !== 0) {
        getDetail(id);
      }
    };
    const close = async () => {
      setVisible(false);
    };
    // 提交
    const handleOk = async () => {
      if (type == 2) {
        close();
        return;
      }
      form.validateFields(async (err, values) => {
        if (err) return;
        values.status = Number(values.status);
        const params = {
          id,
          ...values,
        };
        setConfirmLoading(true);
        const res = await _saveTjConfig(params);
        setConfirmLoading(false);
        const { code, message: msg } = res.data || {};
        if (code != 200) {
          message.warning(msg);
          return;
        }
        message.success("操作成功");
        done();
        close();
      });
    };
    //详情
    const getDetail = async (id) => {
      const params = {
        id,
      };
      const res = await _tjConfigDetail(params);
      const { code, data = {} } = res.data || {};
      if (code != 200) {
        return;
      }
      setInitialValues(data || {});
      const arr = await getUsers({
        tjMobile: data.mobile,
      });
      setRecommendList(arr);
      setFieldsValue({ kid: data.kid });
    };
    //获取推荐人
    const handleKeywordChange = async (e) => {
      const value = e.target.value;
      if (value.length != 8 && value.length != 11) {
        setRecommendList([]);
        setFieldsValue({ kid: "" });
        return;
      }
      const params = {
        tjMobile: value,
      };
      const arr = await getUsers(params);
      setRecommendList(arr);
      const { kid } = arr[0] || {};
      setFieldsValue({ kid });
    };
    const getUsers = async (params) => {
      const res = await _queryTjUser(params);
      const { data = [] } = res.data || {};
      const arr = data || [];
      return arr;
    };
    return (
      <div>
        <Modal
          title="推荐人分润规则"
          visible={visible}
          width={700}
          onOk={handleOk}
          onCancel={close}
          confirmLoading={confirmLoading}
        >
          <Form>
            <Row>
              {userType === "0" && (
                <Col span={24}>
                  <Form.Item label="代理商">
                    {type == 2
                      ? initialValues.fname
                      : getFieldDecorator("fid", {
                          initialValue: initialValues.fid,
                          rules: [{ required: true, message: "请选择代理商" }],
                        })(
                          <FranchiseeSelect
                            onChange={() => {
                              setFieldsValue({ sid: "" });
                            }}
                          />
                        )}
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label="站点">
                  {type == 2
                    ? initialValues.sname
                    : getFieldDecorator("sid", {
                        initialValue: initialValues.sid,
                        rules: [{ required: true, message: "请选择站点" }],
                      })(<SiteSelect fid={getFieldValue("fid")} />)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="手机号/推荐码">
                  {type == 2
                    ? initialValues.realName + "/" + initialValues.mobile
                    : getFieldDecorator("mobile", {
                        initialValue: initialValues.mobile,
                        rules: [
                          { required: true, message: "请输入手机号/推荐码" },
                        ],
                      })(
                        <Input
                          onChange={handleKeywordChange}
                          allowClear
                          placeholder={"请输入新推荐人完整手机号或推荐码"}
                        />
                      )}
                </Form.Item>
              </Col>
              {type != 2 && (
                <Col span={24}>
                  <Form.Item label="推荐人信息">
                    {getFieldDecorator("kid", {
                      rules: [{ required: true, message: "推荐人不能为空" }],
                    })(
                      <Radio.Group>
                        {recommendList.map((item) => (
                          <div key={item.kid} style={{ margin: "6px 0" }}>
                            <Radio value={item.kid}>
                              {item.realname}（{item.inviteCode}）
                            </Radio>
                          </div>
                        ))}
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label="选择分润规则">
                  {getFieldDecorator("type", {
                    initialValue: initialValues.type ? initialValues.type : 1,
                    rules: [{ required: true, message: "请输入推荐人占比" }],
                  })(
                    <Radio.Group disabled={type == 2 && true}>
                      <Radio value={1}>按套餐金额比例分润</Radio>
                      <Radio value={2}>按固定金额分润</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              {getFieldValue("type") == 1 && (
                <Col span={24}>
                  <Form.Item label="推荐人占比">
                    {type == 2
                      ? initialValues.contentStr
                      : getFieldDecorator("content", {
                          initialValue: initialValues.content,
                          rules: [
                            { required: true, message: "请输入推荐人占比" },
                          ],
                        })(
                          <InputNumber
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            min={0}
                            precision={2}
                          />
                        )}
                  </Form.Item>
                </Col>
              )}
              {getFieldValue("type") == 2 && (
                <Col span={24}>
                  <Form.Item label="推荐人分润金额">
                    {type == 2
                      ? initialValues.contentStr
                      : getFieldDecorator("content", {
                          initialValue: initialValues.content,
                          rules: [
                            { required: true, message: "请输入推荐人分润金额" },
                          ],
                        })(
                          <InputNumber
                            formatter={(value) => `${value}元`}
                            parser={(value) => value.replace("元", "")}
                            min={0}
                            precision={2}
                          />
                        )}
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label="备注">
                  {type == 2
                    ? initialValues.remark
                    : getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input placeholder="请输入备注" />)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="管理状态">
                  {getFieldDecorator("status", {
                    initialValue: initialValues.status === 0 ? false : true,
                    valuePropName: "checked",
                  })(
                    <Switch
                      disabled={type == 2 && true}
                      checkedChildren="启用"
                      unCheckedChildren="禁用"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  })
);

export default RuleModal;
