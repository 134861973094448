import React from "react";
import { Tabs } from "antd";
import DailyPayment from "./DailyPayment";
import MonthlyPayment from "./MonthlyPayment";
import HistoryOverdue from "./HistoryOverdue";
import OverdueSummary from "./OverdueSummary";

const { TabPane } = Tabs;
const orderStatusItem = [
  { key: 1, title: '已下单' },
  { key: 2, title: '待提车' },
  { key: 3, title: '租车中' },
  { key: 4, title: '已超时' },
  { key: 5, title: '已完成' },
  { key: 6, title: '申请退租' },
  { key: 7, title: '待生效' },
  { key: 8, title: '已关闭' },
  { key: 9, title: '待签约' },
]

const Index = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="逾期总表" key="1">
        <OverdueSummary orderStatusItem={orderStatusItem} />
      </TabPane>
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="日扣逾期" key="2">
          <DailyPayment orderStatusItem={orderStatusItem} />
        </TabPane>
      )}
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="月扣逾期" key="3">
          <MonthlyPayment orderStatusItem={orderStatusItem} />
        </TabPane>
      )}
      {localStorage.getItem("type") === "0" && (
        <TabPane tab="历史逾期" key="4">
          <HistoryOverdue orderStatusItem={orderStatusItem} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default Index;
