import React, { Component, createRef } from "react";
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Select,
  message,
  Modal,
  Divider,
} from "antd";
import {
  _kaUsersList,
  _deleteUser,
  _batchDeleteUser,
  _kaUpdateUserStatus,
  _franchiseeList,
  _kaUserLogList,
  _kaUserListExport,
  _updateKaUsers,
  _saveKaUser,
  _kaUserDetail,
  _kaUserReturn,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";

import "./style.less";
const { Option } = Select;
// 操作弹窗
class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      id: "", //代理商id
      initialValues: {}, //代理商详情
      franchiseeList: [],
      visible: false,
      userType: localStorage.getItem("type")
        ? Number(localStorage.getItem("type"))
        : "",
      userId: localStorage.getItem("userId"),
      fid: localStorage.getItem("fid")
        ? Number(localStorage.getItem("fid"))
        : "",
      nameDisabled: false,
      cardNoDisabled: false,
    };
    props.onRef(this);
    this.open = this.open.bind(this);
  }
  open({ type, id }) {
    const { fid, userType } = this.state;
    this.setState(
      {
        visible: true,
        type,
        id,
      },
      () => {
        this.getFranchiseeList();
        if (type == 1) {
          // this.props.form && this.props.form.resetFields();
          if (userType != 0) {
            this.setState({
              initialValues: {
                fid,
              },
            });
          }
        } else if (type == 2) {
          this.getUserDetail();
        }
      }
    );
  }
  //Modal取消事件
  close() {
    let { form } = this.props;
    form && form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
    });
  }
  //获取用户详情
  getUserDetail() {
    let { id } = this.state;
    let params = { id };
    _kaUserDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { province, city, county, street } = res.data.data || {};
          this.setState({
            initialValues: {
              ...res.data.data,
              areaList: [province, city, county, street].filter((item) => item),
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
      ftype: 1,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form, done } = this.props;
    const { id, type } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      let params = {};
      if (type == 1) {
        params = { ...values };
        this.saveUser(params);
      } else if (type == 2) {
        params = { id, ...values };
        this.updateUser(params);
      }
    });
  }
  // 新增
  saveUser(params) {
    _saveKaUser(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 修改
  updateUser(params) {
    _updateKaUsers(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleTelChange(e) {
    const value = e.currentTarget.value;
    const { type } = this.state;
    if (type == 2) {
      return;
    }
    if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
      this.getUserInfo(value);
    } else {
      const { form } = this.props;
      if (this.state.nameDisabled) {
        form.setFieldsValue({
          name: "",
        });
      }
      if (this.state.cardNoDisabled) {
        form.setFieldsValue({
          cardNo: "",
        });
      }
      this.setState({
        nameDisabled: false,
        cardNoDisabled: false,
      });
    }
  }
  async getUserInfo(tel) {
    const { form } = this.props;
    const params = {
      tel,
      id: this.state.userId,
    };
    const res = await _kaUserReturn(params);
    const { code, data = {} } = res.data || {};
    if (code !== 200) {
      return;
    }
    if (data && data.kid) {
      form.setFieldsValue({
        name: data.name,
        cardNo: data.cardNo,
      });
      this.setState({
        nameDisabled: !!data.name,
        cardNoDisabled: !!data.cardNo,
      });
    } else {
      this.setState({
        nameDisabled: false,
        cardNoDisabled: false,
      });
    }
  }
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      initialValues,
      fileList,
      type,
      visible,
      cardNoDisabled,
      nameDisabled,
      userType,
    } = this.state;
    return (
      <div>
        <Modal
          className="bigModal"
          title={type == 1 ? "添加用户" : "修改用户"}
          destroyOnClose
          maskClosable={false}
          visible={visible}
          onOk={this.handleOk.bind(this)}
          onCancel={() => this.close()}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="代理商名称">
                {getFieldDecorator("fid", {
                  initialValue: initialValues.fid,
                  rules: [{ required: true, message: "请选择名称！" }],
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    disabled={type == 2 || userType != 0}
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="联系人">
                {getFieldDecorator("name", {
                  initialValue: initialValues.name,
                  rules: [{ required: true, message: "请输入联系人！" }],
                })(
                  <Input
                    allowClear
                    placeholder="请输入"
                    disabled={type == 2 || nameDisabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="手机号">
                {getFieldDecorator("tel", {
                  validateTrigger: "onBlur",
                  rules: [
                    {
                      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                      message: "请输入正确的手机号格式",
                    },
                    { required: true, message: "请输入手机号！" },
                  ],
                  initialValue: initialValues.tel,
                })(
                  <Input
                    allowClear
                    placeholder="请输入"
                    disabled={type == 2}
                    onChange={this.handleTelChange.bind(this)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="身份证号">
                {getFieldDecorator("cardNo", {
                  initialValue: initialValues.cardNo,
                  rules: [
                    {
                      pattern: /^(\d{18,18}|\d{17,17}X)$/,
                      message: "请输入正确的身份证格式",
                    },
                    { required: true, message: "请输入身份证号！" },
                  ],
                })(
                  <Input
                    allowClear
                    placeholder="请输入"
                    disabled={type == 2 || cardNoDisabled}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="地区">
                {getFieldDecorator("areaList", {
                  initialValue: initialValues.areaList,
                })(
                  <AreaCascader
                    changeOnSelect
                    placeholder="地区"
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="详情地址">
                {getFieldDecorator("address", {
                  initialValue: initialValues.address,
                  rules: [{ required: true, message: "请输入详情地址！" }],
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="紧急联系人">
                {getFieldDecorator("emergencyBy", {
                  initialValue: initialValues.emergencyBy,
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="紧急联系方式">
                {getFieldDecorator("emergencyTel", {
                  initialValue: initialValues.emergencyTel,
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="备注">
                {getFieldDecorator("remarks", {
                  initialValue: initialValues.remarks,
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

AddModal = Form.create({})(AddModal);

const modalTypeList = {
  1: "两轮电动车",
  2: "三轮车",
  3: "四轮车",
  4: "电池",
};
// 记录弹窗
class RecordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      list: [],
      visible: false,
    };
    props.onRef(this);
    this.open = this.open.bind(this);
    this.columns = [
      {
        title: "使用单号",
        dataIndex: "useNo",
      },
      {
        title: "所属站点",
        dataIndex: "sname",
      },
      {
        title: "设备类别",
        dataIndex: "modelType",
        render: (text) => modalTypeList[text],
      },
      {
        title: "设备型号",
        dataIndex: "bikeModel",
      },
      {
        title: "设备信息",
        render: (text) => (
          <div>
            <div>车架号：{text.bikeId}</div>
            <div>GPS号：{text.gpsNumber}</div>
            <div>二维码：{text.imei}</div>
            <div>车牌号：{text.bikeNumber}</div>
          </div>
        ),
      },
      {
        title: "开始时间",
        dataIndex: "startTime",
      },
      {
        title: "结束时间",
        dataIndex: "endTime",
      },
    ];
  }
  open({ id }) {
    this.setState(
      {
        visible: true,
        id,
      },
      () => {
        this.getRecordList();
      }
    );
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  //分页
  handlePageChange(curPage, pageSize) {
    this.setState(
      {
        curPage,
        pageSize,
      },
      () => {
        this.getRecordList();
      }
    );
  }
  async getRecordList() {
    let { id, curPage, pageSize } = this.state;
    let params = {
      id,
      curPage,
      pageSize,
    };
    const res = await _kaUserLogList(params);
    let {
      code,
      data: { rows, totalCount },
    } = res.data || {};
    if (code === 200) {
      if (!rows.length) {
        message.warning("暂无数据！");
      }
      this.setState({
        list: rows,
        totalCount: totalCount,
        curPage: curPage,
      });
    }
  }
  render() {
    let { list, pageSize, totalCount, curPage, visible } = this.state;
    return (
      <div>
        <Modal
          title="使用记录"
          destroyOnClose
          width={800}
          maskClosable={false}
          visible={visible}
          onOk={() => this.close()}
          onCancel={() => this.close()}
        >
          <Table
            rowKey="id"
            bordered
            columns={this.columns}
            dataSource={list}
            components={this.components}
            scroll={{ x: 1300, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </Modal>
      </div>
    );
  }
}

class KaMemberManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      usersList: [], //会员列表
      queryFormValues: {
        sstate: 1,
      }, // 搜索项
      kid: "", //用户id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      franchiseeList: [],
      exportload: false,
    };
    this.addModal = createRef(null);
    this.recordModal = createRef(null);
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "所属公司",
        dataIndex: "fname",
      },
      {
        title: "用户名",
        dataIndex: "name",
      },
      {
        title: "身份证",
        dataIndex: "cardNo",
      },
      {
        title: "联系方式",
        dataIndex: "tel",
      },
      // {
      //   title: "头像",
      //   dataIndex: "avatar",
      //   render: (text) => (
      //     <img style={{ width: "50px", height: "50px" }} src={text} alt="" />
      //   ),
      // },
      // {
      //   title: "性别",
      //   dataIndex: "sex",
      //   width: 80,
      //   render: (text) => (text === 1 ? "男" : text === 2 ? "女" : "保密"),
      // },
      {
        title: "联系地址",
        dataIndex: "address",
      },
      {
        title: "紧急联系人",
        dataIndex: "emergencyBy",
      },
      {
        title: "紧急联系人方式",
        dataIndex: "emergencyTel",
      },
      {
        title: "注册时间",
        dataIndex: "createTime",
        width: 135,
      },
      {
        title: "备注",
        dataIndex: "remarks",
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 80,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <div>
            {/* <Button
              type="link"
              onClick={this.handleDeleteChange.bind(this, text.kid)}
            >
              删除
            </Button> */}
            <Divider type="vertical" />
            <Button type="link" onClick={() => this.showUserModal(text.id, 2)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Button type="link" onClick={this.handleRecord.bind(this, text.id)}>
              使用记录
            </Button>
          </div>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户名/手机">
              {getFieldDecorator("name")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("sstate", {
                initialValue: this.state.queryFormValues.sstate,
              })(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      loading,
      usersList,
      pageSize,
      totalCount,
      curPage,
      selectedRowKeys,
      recordVisible,
      curId,
      exportload,
      modalType,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div>
        {!!getJurisdiction(979) ? (
          <div className="MemberManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              {/* <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`} */}
              <Button
                icon="plus"
                type="primary"
                onClick={() => this.showUserModal("", 1)}
              >
                添加用户
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={exportload}
              >
                导出
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                // rowSelection={rowSelection}
                columns={this.columns}
                dataSource={usersList}
                components={this.components}
                scroll={{ x: 1300, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
        <RecordModal
          onRef={(ref) => {
            this.recordModal = ref;
          }}
        />
        <AddModal
          onRef={(ref) => {
            this.addModal = ref;
          }}
        />
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getUsersList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
      ftype: 1,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取用户列表
  getUsersList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _kaUsersList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            usersList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getUsersList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      this.setState({
        queryFormValues: values,
      });
      this.getUsersList(1, this.state.pageSize, values);
    });
  };
  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      let params = {
        ...values,
      };
      _kaUserListExport(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  // 使用记录
  handleRecord(id) {
    this.recordModal.open({ id });
  }
  showUserModal(id, type) {
    this.addModal.open({ id, type });
  }
  //单条删除
  handleDeleteChange(kid) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { kid };
        _deleteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getUsersList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
            self.setState({
              selectedRowKeys: [],
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _kaUpdateUserStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getUsersList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
}

KaMemberManagement = Form.create({})(KaMemberManagement);
export { KaMemberManagement };
