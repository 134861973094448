import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Tabs,
  Table,
  Select,
  message,
  Progress,
  Button,
  Tooltip,
  Icon,
} from 'antd'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import {
  _outTimeOrderSumList,
  _getHomePageInfo,
  _getHomePageInfoFotSiteList,
  _franchiseeList,
  _getSignStaticInfo,
  _propertyList,
  _firstBatteryList,
  _firstBikeList,
  _HomeData,
} from '@/statics/js/api'
import './style.less'
const { Option } = Select
const { TabPane } = Tabs
class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showIndex: localStorage.getItem('showIndex'),
      fid: null,
      defaultActiveKey: '1', //tabs默认key
      getHomePageInfo: {}, //数据总览
      signStaticInfo: {}, //数据
      franchiseeList: [], //代理商列表
      homeData: {},
    }
    this.columns = [
      {
        title: '站点名称',
        dataIndex: 'name',
      },
      {
        title: '车辆总数',
        dataIndex: 'totalVehicle',
      },
      {
        title: '在租车辆',
        dataIndex: 'useVehicle',
      },
      {
        title: '电池总数',
        dataIndex: 'totalBattery',
      },
      {
        title: '在租电池',
        dataIndex: 'useBattery',
      },
      {
        title: '租车中',
        dataIndex: 'rentalOrder',
      },
      {
        title: '待提车',
        dataIndex: 'waitOrder',
      },
      {
        title: '申请退租',
        dataIndex: 'withdrawalOrder',
      },
      {
        title: '超时订单',
        dataIndex: 'overTimeOrder',
      },
    ]
    this.overtimeColumns = [
      {
        title: '超时天数',
        width: 105,
        dataIndex: 'outTimeDay',
      },
      {
        title: '挂起',
        dataIndex: 'yesHangUp',
      },
      {
        title: '未挂起',
        dataIndex: 'noHangUp',
      },
      {
        title: '已扣租金未缴纳逾期租金违约金',
        width: 120,
        dataIndex: 'noPaymentMoney',
      },
      {
        title: '挂起占比',
        dataIndex: 'yesHangScale',
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip
            placement="top"
            title="超时天数范围内的挂起订单与累计挂起订单的占比"
          >
            <Icon type="question-circle-o" style={{ marginLeft: 1 }} />
          </Tooltip>
        ),
      },
      {
        title: '未挂起占比',
        dataIndex: 'noHangScale',
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip
            placement="top"
            title="超时天数范围内的未挂起订单与累计未挂起订单的占比"
          >
            <Icon type="question-circle-o" style={{ marginLeft: 1 }} />
          </Tooltip>
        ),
      },
      {
        title: '挂起总订单占比',
        dataIndex: 'yesHangSumScale',
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip
            placement="top"
            title="超时天数范围内的挂起订单与挂起总订单的占比"
          >
            <Icon type="question-circle-o" style={{ marginLeft: 1 }} />
          </Tooltip>
        ),
      },
      {
        title: '未挂起总订单占比',
        dataIndex: 'noHangSumScale',
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip
            placement="top"
            title="超时天数范围内的未挂起订单与未挂起总订单的占比"
          >
            <Icon type="question-circle-o" style={{ marginLeft: 1 }} />
          </Tooltip>
        ),
      },
    ]
    this.bikeColumns = [
      {
        title: '车辆型号',
        dataIndex: 'bikeModel',
      },
      {
        title: '在租车辆',
        dataIndex: 'inRentBike',
      },
      {
        title: '启用车辆',
        dataIndex: 'openBike',
      },
      {
        title: '车辆使用率',
        dataIndex: 'bikeUseScale',
      },
      {
        title: '销售车辆',
        dataIndex: 'sellBike',
      },
      {
        title: '禁用车辆',
        dataIndex: 'shutBike',
      },
      {
        title: '车辆总计',
        dataIndex: 'bikeSum',
      },
    ]
    this.batteryColumns = [
      {
        title: '电池类型',
        dataIndex: 'batteryModel',
      },
      {
        title: '在租电池',
        dataIndex: 'inRentBattery',
      },
      {
        title: '启用电池',
        dataIndex: 'openBattery',
      },
      {
        title: '电池使用率',
        dataIndex: 'batteryUseScale',
      },
      {
        title: '销售电池',
        dataIndex: 'sellBattery',
      },
      {
        title: '禁用电池',
        dataIndex: 'shutBattery',
      },
      {
        title: '电池总数',
        dataIndex: 'batterySum',
      },
    ]
  }

  render() {
    const { signStaticInfo, getHomePageInfo, homeData, fid, showIndex } =
      this.state
    return (
      <div className="Index">
        {showIndex !== '1' ? (
          <div className="IndexBg">
            {getJurisdiction(1049) && (
              <Button
                className="IndexBgBtn"
                onClick={this.handleIndexChange.bind(this)}
              >
                进入首页 >>
              </Button>
            )}
          </div>
        ) : (
          <div>
            <div className="index-top">
              <div className="top-title">
                Hi {localStorage.getItem('username')},欢迎登录！
              </div>
              {localStorage.getItem('type') === '0' &&
                localStorage.getItem('userId') !== '1288' && (
                  <div>
                    代理商：
                    <Select
                      placeholder="请选择代理商"
                      allowClear
                      style={{ width: 200 }}
                      showSearch
                      onChange={this.handleSelectChange.bind(this)}
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      }}
                    >
                      {this.state.franchiseeList.length &&
                        this.state.franchiseeList.map((item, index) => {
                          return (
                            <Option key={index} value={item.fid}>
                              {item.fname}
                            </Option>
                          )
                        })}
                    </Select>
                  </div>
                )}
            </div>
            <div className="index-table">
              <Row gutter={16}>
                {localStorage.getItem('userId') === '1288' ? null : (
                  <Col className="index-table-col" sm={24} lg={24}>
                    <div
                      className="col-content"
                      style={{ paddingBottom: '20px' }}
                    >
                      <h2 className="col-content-title">资产详情</h2>
                      <Col className="index-table-col" sm={24} lg={12}>
                        <Col className="index-table-col" sm={6} lg={6}>
                          <Progress
                            width={100}
                            type="circle"
                            strokeColor={'#1ADEC2'}
                            percent={
                              getHomePageInfo && getHomePageInfo.useVehicleRate
                                ? getHomePageInfo.useVehicleRate
                                : 0
                            }
                            format={(percent) => (
                              <span
                                style={{ fontSize: '14px', fontWeight: 500 }}
                              >
                                {percent}%<br />
                                车辆使用率
                              </span>
                            )}
                          />
                        </Col>
                        <Col className="index-table-col" sm={16} lg={16}>
                          <div className="chart-annotation">
                            <Link
                              style={{
                                color: 'rgba(0, 0, 0, 0.65)',
                              }}
                              target="_blank"
                              to={
                                '/bikeManagement?useOrder=Y' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              在租车辆
                              <span
                                style={{
                                  color: '#444',
                                  paddingLeft: 5,
                                }}
                              >
                                >
                              </span>
                            </Link>
                            <span
                              style={{
                                lineHeight: '32px',
                                fontSize: '32px',
                              }}
                            >
                              {getHomePageInfo && getHomePageInfo.useVehicle}
                            </span>
                          </div>
                          <Progress
                            strokeColor={'#1ADEC2'}
                            percent={
                              getHomePageInfo &&
                              (getHomePageInfo.useVehicle /
                                getHomePageInfo.totalVehicle) *
                                100
                            }
                            showInfo={false}
                          />
                          <div className="chart-annotation">
                            <Link
                              style={{
                                color: 'rgba(0, 0, 0, 0.65)',
                              }}
                              target="_blank"
                              to={
                                '/bikeManagement?status=1' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              总车辆
                              <span
                                style={{
                                  color: '#444',
                                  paddingLeft: 5,
                                }}
                              >
                                >
                              </span>
                            </Link>
                            <span
                              style={{
                                lineHeight: '32px',
                                fontSize: '32px',
                              }}
                            >
                              {getHomePageInfo && getHomePageInfo.totalVehicle}
                            </span>
                          </div>
                          <Progress
                            strokeColor={'#1ADEC2'}
                            percent={100}
                            showInfo={false}
                          />
                        </Col>
                      </Col>
                      <Col className="index-table-col" sm={24} lg={12}>
                        <Col className="index-table-col" sm={6} lg={6}>
                          <Progress
                            width={100}
                            type="circle"
                            strokeColor={'#21DE1A'}
                            percent={
                              getHomePageInfo && getHomePageInfo.useBatteryRate
                                ? getHomePageInfo.useBatteryRate
                                : 0
                            }
                            format={(percent) => (
                              <span
                                style={{ fontSize: '14px', fontWeight: 500 }}
                              >
                                {percent}%<br />
                                电池使用率
                              </span>
                            )}
                          />
                        </Col>
                        <Col className="index-table-col" sm={16} lg={16}>
                          <div className="chart-annotation">
                            <Link
                              style={{
                                color: 'rgba(0, 0, 0, 0.65)',
                              }}
                              target="_blank"
                              to={
                                '/batteryManagement?useOrder=Y' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              在租电池
                              <span
                                style={{
                                  color: '#444',
                                  paddingLeft: 5,
                                }}
                              >
                                >
                              </span>
                            </Link>
                            <span
                              style={{
                                lineHeight: '32px',
                                fontSize: '32px',
                              }}
                            >
                              {getHomePageInfo && getHomePageInfo.useBattery}
                            </span>
                          </div>
                          <Progress
                            strokeColor={'#21DE1A'}
                            percent={
                              getHomePageInfo &&
                              (getHomePageInfo.useBattery /
                                getHomePageInfo.totalBattery) *
                                100
                            }
                            showInfo={false}
                          />
                          <div className="chart-annotation">
                            <Link
                              style={{
                                color: 'rgba(0, 0, 0, 0.65)',
                              }}
                              target="_blank"
                              to={
                                '/batteryManagement?status=1' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              总电池
                              <span
                                style={{
                                  color: '#444',
                                  paddingLeft: 5,
                                }}
                              >
                                >
                              </span>
                            </Link>
                            <span
                              style={{
                                lineHeight: '32px',
                                fontSize: '32px',
                              }}
                            >
                              {getHomePageInfo && getHomePageInfo.totalBattery}
                            </span>
                          </div>
                          <Progress
                            strokeColor={'#21DE1A'}
                            percent={100}
                            showInfo={false}
                          />
                        </Col>
                      </Col>
                    </div>
                  </Col>
                )}
                {localStorage.getItem('userId') === '1288' ? (
                  <Col className="index-table-col" sm={24} lg={24}>
                    <div className="col-content">
                      <h2 className="col-content-title">订单总览</h2>
                      <div className="table-div">
                        <table className="content-table">
                          <tbody>
                            <tr>
                              <td className="font-right">今日新增订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo &&
                                    getHomePageInfo.newOrderTotal}
                                </span>
                              </td>
                              <td className="font-right">今日超时订单</td>
                              <td>
                                <span className="big-number fontSize14">0</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-right">待提车订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo && getHomePageInfo.waitOrder}
                                </span>
                              </td>
                              <td className="font-right">今日违约订单</td>
                              <td>
                                <span className="big-number fontSize14">0</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-right">今日续租订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo &&
                                    getHomePageInfo.renewalOrderTotal}
                                </span>
                              </td>
                              <td className="font-right">超时订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo &&
                                    getHomePageInfo.overTimeOrder}
                                </span>
                                其中挂起：
                                <span className="td-number">
                                  {getHomePageInfo &&
                                    getHomePageInfo.overTimeHangUpOrder}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-right">申请退租订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo &&
                                    getHomePageInfo.withdrawalOrder}
                                </span>
                                退车成功
                                <span className="td-number">
                                  {getHomePageInfo &&
                                    getHomePageInfo.withdrawalOrderTotal}
                                </span>
                              </td>
                              <td className="font-right">挂起订单</td>
                              <td>
                                <span className="big-number fontSize14">
                                  {getHomePageInfo &&
                                    getHomePageInfo.withdrawalHangUpOrder}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col className="index-table-col" sm={24} lg={24}>
                    <div className="col-content">
                      <h3 className="col-content-subtitle">订单总览</h3>
                      <div className="col-content-flex">
                        <div className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=2' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            待提车订单 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo && getHomePageInfo.waitOrder}
                          </span>
                          <span></span>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=3' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            租车中订单 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo && getHomePageInfo.rentalOrder}
                          </span>
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=3&hangUp=1' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            其中挂起 >
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.rentalHangUpOrder}
                            </span>
                          </Link>
                        </div>
                        <div style={{ flex: 2 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=4' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            超时订单 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo && getHomePageInfo.overTimeOrder}
                          </span>
                          <div>
                            <Link
                              target="_blank"
                              to={
                                '/packageOrderManagement?orderStatus=4&hangUp=1' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              其中挂起 >
                              <span className="td-number">
                                {getHomePageInfo &&
                                  getHomePageInfo.overTimeHangUpOrder}
                              </span>
                            </Link>
                            <Link
                              target="_blank"
                              to={
                                '/PackagesOrderLogList?orderStatus=4' +
                                (fid ? '&fid=' + fid : '')
                              }
                            >
                              超时代扣成功 >
                              <span className="td-number">
                                {getHomePageInfo &&
                                  getHomePageInfo.overTimeDeductio}
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=6' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            申请退租订单 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo && getHomePageInfo.withdrawalOrder}
                          </span>
                          <Link
                            target="_blank"
                            to={
                              '/packageOrderManagement?orderStatus=6&hangUp=1' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            其中挂起 >
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.withdrawalHangUpOrder}
                            </span>
                          </Link>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={`/operation?active=4&fid=${fid || ''}`}
                          >
                            挂起代扣成功订单 >
                          </Link>
                          <span className="big-number">
                            {homeData && homeData.outTimePayNum}
                          </span>
                          <Link
                            target="_blank"
                            to={`/operation?active=4&fid=${
                              fid || ''
                            }&date=yesterday`}
                          >
                            昨日新增 >
                            <span className="td-number">
                              {homeData && homeData.dayOutTimePayNum}
                            </span>
                          </Link>
                        </div>
                      </div>
                      <h3 className="col-content-subtitle">日订单报表</h3>
                      <div className="col-content-flex">
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/todayOrder?isRenewal=1' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            今日新增 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo && getHomePageInfo.newOrderTotal}
                          </span>
                          <span>
                            相较昨天
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.compareYesterday}
                            </span>
                          </span>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/todayOrder?isRenewal=2' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            今日续租 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo &&
                              getHomePageInfo.renewalOrderTotal}
                          </span>
                          <span>
                            相较昨天
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.yesterRenewalOrderTotal}
                            </span>
                          </span>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/todayOrder?orderStatus=65' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            今日退租 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo &&
                              getHomePageInfo.todayeWithdrawalOrderTotal}
                          </span>
                          <span>
                            相较昨天
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.yesterWithdrawalOrderTotal}
                            </span>
                          </span>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/todayOrder?newOrderStatus=4' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            今日超时 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo &&
                              getHomePageInfo.todayOverTimeOrder}
                          </span>
                          <span>
                            相较昨天
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.yesterOverTimeOrder}
                            </span>
                          </span>
                        </div>
                        <div style={{ flex: 1 }} className="col-content-item">
                          <Link
                            target="_blank"
                            to={
                              '/todayOrder?hangUp=1' +
                              (fid ? '&fid=' + fid : '')
                            }
                          >
                            今日挂起 >
                          </Link>
                          <span className="big-number">
                            {getHomePageInfo &&
                              getHomePageInfo.todayHangUpOrder}
                          </span>
                          <span>
                            相较昨天
                            <span className="td-number">
                              {getHomePageInfo &&
                                getHomePageInfo.yesterHangUpOrder}
                            </span>
                          </span>
                        </div>
                      </div>
                      <h3 className="col-content-subtitle">累计超时订单报表</h3>
                      <Table
                        style={{ margin: '0 20px 20px' }}
                        rowKey="id"
                        bordered
                        columns={this.overtimeColumns}
                        dataSource={this.state.outTimeOrderSumList}
                        pagination={false}
                      />
                    </div>
                  </Col>
                )}
                {!!signStaticInfo.isShow && (
                  <Col className="index-table-col" sm={24} lg={24}>
                    <div
                      className="col-content"
                      style={{ paddingBottom: '20px' }}
                    >
                      <h2 className="col-content-title">合约数据</h2>
                      <Col className="index-table-col" sm={24} lg={12}>
                        <Col className="index-table-col" sm={8} lg={8}>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#1ADEC2',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            实时签约订单
                          </div>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#21DE1A',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            订单在跑量
                          </div>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#F7D145',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            累计履约订单
                          </div>
                        </Col>
                        <Col className="index-table-col" sm={16} lg={16}>
                          <Link
                            to={
                              '/performanceRecordList?fid=' + (fid ? fid : '')
                            }
                          >
                            <Progress
                              className="contractRight"
                              strokeColor={'#1ADEC2'}
                              percent={
                                signStaticInfo &&
                                (signStaticInfo.signNum /
                                  signStaticInfo.totalNum) *
                                  100
                              }
                              format={() => `${signStaticInfo.signNum}`}
                            />
                          </Link>
                          <Link to={'/bikeManagement?fid=' + (fid ? fid : '')}>
                            <Progress
                              className="contractRight"
                              strokeColor={'#21DE1A'}
                              percent={
                                signStaticInfo &&
                                (signStaticInfo.orderNum /
                                  signStaticInfo.totalNum) *
                                  100
                              }
                              format={() => `${signStaticInfo.orderNum}`}
                            />
                          </Link>
                          <Progress
                            className="contractRight"
                            strokeColor={'#F7D145'}
                            percent={
                              signStaticInfo.totalNum
                                ? signStaticInfo.totalNum
                                : 0
                            }
                            format={() => `${signStaticInfo.totalNum}`}
                          />
                        </Col>
                        <Col
                          className="index-table-col"
                          offset={2}
                          sm={8}
                          lg={8}
                        >
                          <Progress
                            width={100}
                            type="circle"
                            strokeColor={'#F7D145'}
                            trailColor={'#FFF0B8'}
                            percent={
                              getHomePageInfo &&
                              (signStaticInfo.insureNum /
                                signStaticInfo.signNum) *
                                100
                            }
                            format={(percent) => (
                              <span
                                style={{ fontSize: '14px', fontWeight: 500 }}
                              >
                                {percent.toFixed(2)}%<br />
                                免赔服务购买率
                              </span>
                            )}
                          />
                        </Col>
                        <Col className="index-table-col" sm={8} lg={8}>
                          <div
                            style={{
                              marginTop: 20,
                              height: 40,
                              fontWeight: 500,
                            }}
                          >
                            <span
                              style={{
                                width: 15,
                                height: 15,
                                display: 'inline-block',
                                background: '#F7D145',
                                marginRight: 20,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            免赔服务购买量
                          </div>
                          <div style={{ height: 40, fontWeight: 500 }}>
                            <span
                              style={{
                                width: 15,
                                height: 15,
                                display: 'inline-block',
                                background: '#FFF0B8',
                                marginRight: 20,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            实时签约订单
                          </div>
                        </Col>
                      </Col>
                      <Col className="index-table-col" sm={24} lg={12}>
                        <Col className="index-table-col" sm={8} lg={8}>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#1ADEC2',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            订单违约量
                          </div>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#21DE1A',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            免赔服务购买量
                          </div>
                          <div className="contractLeft">
                            <span
                              style={{
                                width: 5,
                                height: 5,
                                borderRadius: '50%',
                                display: 'inline-block',
                                background: '#F7D145',
                                marginRight: 15,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            免赔服务生效数
                          </div>
                        </Col>
                        <Col className="index-table-col" sm={16} lg={16}>
                          <Progress
                            className="contractRight"
                            strokeColor={'#1ADEC2'}
                            percent={
                              signStaticInfo &&
                              (signStaticInfo.penaltyNum /
                                signStaticInfo.insureNum) *
                                100
                            }
                            format={() => `${signStaticInfo.penaltyNum}`}
                          />
                          <Progress
                            className="contractRight"
                            strokeColor={'#21DE1A'}
                            percent={
                              signStaticInfo.insureNum
                                ? signStaticInfo.insureNum
                                : 0
                            }
                            format={() => `${signStaticInfo.insureNum}`}
                          />
                          <Progress
                            className="contractRight"
                            strokeColor={'#F7D145'}
                            percent={
                              signStaticInfo &&
                              (signStaticInfo.effectNum /
                                signStaticInfo.insureNum) *
                                100
                            }
                            format={() => `${signStaticInfo.effectNum}`}
                          />
                        </Col>
                        <Col
                          className="index-table-col"
                          offset={2}
                          sm={8}
                          lg={8}
                        >
                          <Progress
                            width={100}
                            type="circle"
                            strokeColor={'#F7D145'}
                            trailColor={'#FFF0B8'}
                            percent={
                              getHomePageInfo &&
                              (signStaticInfo.effectNum /
                                signStaticInfo.insureNum) *
                                100
                            }
                            format={(percent) => (
                              <span
                                style={{ fontSize: '14px', fontWeight: 500 }}
                              >
                                {percent.toFixed(2)}%<br />
                                免赔服务生效率
                              </span>
                            )}
                          />
                        </Col>
                        <Col className="index-table-col" sm={8} lg={8}>
                          <div
                            style={{
                              marginTop: 20,
                              height: 40,
                              fontWeight: 500,
                            }}
                          >
                            <span
                              style={{
                                width: 15,
                                height: 15,
                                display: 'inline-block',
                                background: '#F7D145',
                                marginRight: 20,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            免赔服务生效量
                          </div>
                          <div style={{ height: 40, fontWeight: 500 }}>
                            <span
                              style={{
                                width: 15,
                                height: 15,
                                display: 'inline-block',
                                background: '#FFF0B8',
                                marginRight: 20,
                                verticalAlign: 'middle',
                              }}
                            ></span>
                            免赔服务购买量
                          </div>
                        </Col>
                      </Col>
                    </div>
                  </Col>
                )}
                {localStorage.getItem('userId') === '1288' ? null : (
                  <Col className="index-table-col" sm={24} lg={24}>
                    <div className="col-content col-height">
                      {/* <h2 className="col-content-title">站点详情</h2> */}
                      <Tabs
                        defaultActiveKey={this.state.defaultActiveKey}
                        onChange={this.onTabsChange.bind(this)}
                      >
                        <TabPane tab="站点详情" key="1">
                          <Table
                            style={{ margin: 20 }}
                            rowKey="id"
                            bordered
                            columns={this.columns}
                            dataSource={this.state.siteList}
                          />
                        </TabPane>
                        <TabPane tab="车辆数据" key="2">
                          <Table
                            style={{ margin: 20 }}
                            rowKey="id"
                            bordered
                            columns={this.bikeColumns}
                            dataSource={this.state.firstBikeList}
                          />
                        </TabPane>
                        <TabPane tab="电池数据" key="3">
                          <Table
                            style={{ margin: 20 }}
                            rowKey="id"
                            bordered
                            columns={this.batteryColumns}
                            dataSource={this.state.firstBatteryList}
                          />
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}
      </div>
    )
  }

  componentDidMount() {
    if (localStorage.getItem('showIndex') === '1') {
      this.getOutTimeOrderSumList()
      this.getPropertyList()
      this.getSignStaticInfo()
      this.getHomePageInfo()
      this.getHomeData()
      this.getHomePageInfoFotSiteList()
      this.getFranchiseeList()
    }
  }

  handleIndexChange() {
    this.setState({ showIndex: '1' })
    localStorage.setItem('showIndex', '1')
    this.getOutTimeOrderSumList()
    this.getPropertyList()
    this.getSignStaticInfo()
    this.getHomePageInfo()
    this.getHomeData()
    this.getHomePageInfoFotSiteList()
    this.getFranchiseeList()
  }
  onTabsChange(key) {
    this.setState({
      defaultActiveKey: key,
    })
    if (key === '1') {
      this.getHomePageInfoFotSiteList(this.state.fid)
    } else if (key === '2') {
      this.getFirstBikeList(this.state.fid)
    } else if (key === '3') {
      this.getFirstBatteryList(this.state.fid)
    }
  }
  //选择代理商
  handleSelectChange(value) {
    this.setState({ fid: value })
    this.getHomePageInfo(value)
    this.getHomeData(value)
    this.getSignStaticInfo(value)
    this.getOutTimeOrderSumList(value)
    this.getPropertyList(value)
    if (this.state.defaultActiveKey === '1') {
      this.getHomePageInfoFotSiteList(value)
    } else if (this.state.defaultActiveKey === '2') {
      this.getFirstBikeList(value)
    } else if (this.state.defaultActiveKey === '3') {
      this.getFirstBatteryList(value)
    }
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取累计超时报表
  getOutTimeOrderSumList(fid) {
    let params = { fid }
    _outTimeOrderSumList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            outTimeOrderSumList: res.data.data,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取资产报表
  getPropertyList(fid) {
    let params = { fid }
    _propertyList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            propertyList: res.data.data,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取首页信息
  getSignStaticInfo(fid) {
    let params = { fid }
    _getSignStaticInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            signStaticInfo: res.data.data ? res.data.data : {},
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取首页数据
  getHomeData(fid) {
    let params = {
      fid:
        localStorage.getItem('type') !== '0'
          ? Number(localStorage.getItem('fid'))
          : fid,
    }
    _HomeData(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            homeData: res.data.data || {},
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取首页信息
  getHomePageInfo(fid) {
    let params = { fid }
    _getHomePageInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            getHomePageInfo: res.data.data ? res.data.data[0] : {},
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取站点信息
  getHomePageInfoFotSiteList(fid) {
    let params = {
      fid,
      curPage: 1,
      pageSize: 999,
    }
    _getHomePageInfoFotSiteList(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { rows = [] } = res.data.data || {}
          this.setState({
            siteList: rows || [],
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取车辆信息
  getFirstBikeList(fid) {
    let params = {
      fid,
      curPage: 1,
      pageSize: 999,
    }
    _firstBikeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            firstBikeList: res.data.data.rows,
          })
          console.log(this.state.firstBikeList)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取电池信息
  getFirstBatteryList(fid) {
    let params = {
      fid,
      curPage: 1,
      pageSize: 999,
    }
    _firstBatteryList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            firstBatteryList: res.data.data.rows,
          })
          console.log(this.state.firstBatteryList)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export default Index
