import React, { Component, createRef } from "react";
import { Input, Table, Button, Tabs, Modal, message } from "antd";
import {
  _batteryAmount,
  _getBatteryStatus,
  _batteryChargingList,
  _batteryChargingDetail,
  _getBatteryPositionInfo,
} from "@/statics/js/api";
import "./style.less";
import CommonTable from "@/components/commonTable";
import { Map, Marker, InfoWindow } from "react-amap";
const { TabPane } = Tabs;

const amapkey = "4792ad0f37a527c1535e83599762d894";
class JZBatteryMgt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imei: null, //电池IMEI
      curPage: 1, //当前页数
      pageSize: 10, // 每页展示个数
      totalCount: 0, //数据总数量
      batteryList: [], //电池列表
      batteryAmount: {}, //电池统计
      visibleDetail: false, //Modal是否打开（详情）
      batteryAmountPackage: [], //电池订单列表
      defaultActiveKey: "1",
    };
    this.tableRef = createRef();
    this.tableRef1 = createRef();
    this.columns = [
      {
        title: "电池编号",
        dataIndex: "imei",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "电池型号",
        dataIndex: "modelName",
      },
      {
        title: "使用订单",
        dataIndex: "",
        render: (text) => (
          <Button
            type="link"
            onClick={this.handleDetail.bind(this, text.imei, text.orderSn)}
          >
            {text.orderSn}
          </Button>
        ),
      },
      {
        title: "定位",
        dataIndex: "",
        render: (text) => (
          <Button type="link" onClick={this.modalViewMap.bind(this, text)}>
            查看定位
          </Button>
        ),
      },
    ];
    this.packageColumns = [
      {
        title: "订单号",
        dataIndex: "orderSn",
        width: 160,
      },
      {
        title: "套餐名称",
        dataIndex: "pname",
      },
      {
        title: "套餐类型",
        dataIndex: "ptype",
        width: 90,
      },
      {
        title: "套餐金额",
        dataIndex: "moeny",
        width: 90,
      },
      {
        title: "电池型号",
        dataIndex: "modelName",
      },
      {
        title: "设备编号",
        dataIndex: "imei",
        width: 180,
      },
      {
        title: "使用人",
        dataIndex: "userName",
      },
      {
        title: "使用手机号",
        dataIndex: "tel",
        width: 120,
      },
      {
        title: "订单开始时间",
        dataIndex: "startTime",
        width: 120,
      },
    ];
  }
  render() {
    const {
      batteryAmount,
      visibleDetail,
      defaultActiveKey,
      batteryAmountPackage,
    } = this.state;
    return (
      <div className="JZBatteryMgt">
        <div className="container">
          <div className="container_title">
            <h3>数据统计</h3>
          </div>
          <div className="flex_box">
            <div className="flex_item bg1">
              <div>
                <p className="number">{batteryAmount.batterySum}</p>
                <p className="desc">总电池数</p>
              </div>
              <div>
                <img
                  style={{ width: "80%" }}
                  src={require("@/statics/img/jz/jz_icon1.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="flex_item bg2">
              <div>
                <p className="number">{batteryAmount.batteryOrderSum}</p>
                <p className="desc">在租电池数</p>
              </div>
              <div>
                <img
                  style={{ width: "80%" }}
                  src={require("@/statics/img/jz/jz_icon2.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="flex_item bg3">
              <div>
                <p className="number">{batteryAmount.usage}</p>
                <p className="desc">电池出租率</p>
              </div>
              <div>
                <img
                  style={{ width: "80%" }}
                  src={require("@/statics/img/jz/jz_icon3.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container_title">
            <h3>电池列表</h3>
            <div className="flex_search">
              <div style={{ width: 50 }}>编号：</div>
              <Input
                style={{ width: 200 }}
                allowClear
                placeholder="请输入"
                onChange={this.handleInputChange.bind(this)}
              />
              <Button
                style={{ marginLeft: 20 }}
                type="primary"
                onClick={this.handleSearchChange.bind(this)}
              >
                搜索
              </Button>
            </div>
          </div>
          <Tabs
            activeKey={defaultActiveKey}
            onChange={this.onTabsChange.bind(this)}
          >
            <TabPane tab="租赁电池" key="1">
              <CommonTable
                params={{
                  source: 0,
                }}
                action={_batteryChargingList}
                options={{
                  columns: this.columns,
                  scroll: { x: 1200, y: 500 },
                }}
                ref={this.tableRef}
                pageSizeKey="rows"
                curPageKey="page"
              />
            </TabPane>
            <TabPane tab="换电电池" key="2">
              <CommonTable
                params={{
                  source: 1,
                }}
                action={_batteryChargingList}
                options={{
                  columns: this.columns,
                  scroll: { x: 1200, y: 500 },
                }}
                ref={this.tableRef1}
                pageSizeKey="rows"
                curPageKey="page"
              />
            </TabPane>
          </Tabs>
        </div>
        <Modal
          className="bigModal"
          title="订单详情"
          destroyOnClose
          maskClosable={false}
          visible={visibleDetail}
          onOk={this.initDetailModal.bind(this)}
          onCancel={this.initDetailModal.bind(this)}
        >
          <Table
            rowKey="orderSn"
            bordered
            columns={this.packageColumns}
            dataSource={batteryAmountPackage}
            scroll={{ x: 1200, y: 500 }}
          />
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getBatteryAmount();
  }

  // 输入框处理
  handleInputChange(e) {
    this.setState({
      imei: e.target.value,
    });
  }

  // 点击搜索
  handleSearchChange() {
    let _that = this;
    let imei = _that.state.imei;
    _that.state.defaultActiveKey === "1"
      ? _that.tableRef.current.handleSearch({ imei })
      : _that.tableRef1.current.handleSearch({ imei });
  }

  //tabs切换
  onTabsChange(key) {
    let _that = this;
    let imei = _that.state.imei;
    _that.setState(
      {
        defaultActiveKey: key,
      },
      () => {
        _that.state.defaultActiveKey === "1"
          ? _that.tableRef.current.handleSearch({ imei })
          : _that.tableRef1.current.handleSearch({ imei });
      }
    );
  }

  //获取电池统计信息
  getBatteryAmount() {
    let params = {};
    _batteryAmount(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          batteryAmount: res.data.data,
        });
      }
    });
  }

  // 获取订单详情
  async handleDetail(imei, orderSn) {
    let params = {
      imei,
      orderSn,
    };
    let res = await _batteryChargingDetail(params);
    if (res.data.code === 200) {
      this.setState({
        batteryAmountPackage: [res.data.data],
      });
    }
    this.showDetialModal();
  }

  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      batteryAmountPackage: [],
      visibleDetail: false,
    });
  }

  //查看定位
  modalViewMap({ imei, type, source }) {
    const _that = this;
    let params = { imei, type, source };
    _getBatteryPositionInfo(params).then(async (res) => {
      if (!!res.data.data) {
        let mapPosition = [
          parseFloat(res.data.data.lon),
          parseFloat(res.data.data.lat),
        ];
        let modal_instance = Modal.info({
          title: `查看定位 [${imei}]`,
          width: "1000px",
          maskClosable: true,
          content: (
            <div style={{ height: 500 }}>
              <Map amapkey={amapkey} zoom={14} center={mapPosition}>
                <Marker
                  position={mapPosition}
                  events={{
                    click: () => {
                      modal_instance.destroy();
                      _that.handleStatistic({ imei });
                    },
                  }}
                />
                <InfoWindow
                  visible={true}
                  position={mapPosition}
                  offset={[0, -40]}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: 10,
                      width: 300,
                    }}
                  ></div>
                  <p>最后定位时间：{res.data.data.time}</p>
                  <p>最后定位地址：{res.data.data.address}</p>
                </InfoWindow>
              </Map>
            </div>
          ),
          onOk() {},
        });
      } else {
        message.warning("未查询到该电池的定位信息！");
      }
    });
  }
}

export default JZBatteryMgt;
