import React,{ 
    useEffect, 
    useRef,
    useState,
} from 'react'
// import { browserHistory } from "react-router";
import { useLocation, useHistory } from 'react-router-dom';
import './index.less'
import {
    Icon
} from "antd";
import menus from '@/views/common/menus'
import { dropByCacheKey } from 'react-router-cache-route';

const Index = (props)=>{
    const navigateRef = useRef()
    const [list,setList] = useState([])
    // const route = browserHistory.getCurrentLocation()
    const browserHistory = useHistory();
    const route = useLocation()
    useEffect(() => {
        setList([])
    },[]);
    useEffect(() => {
        init()
    },[route.pathname]);
    const getCurrentMenu = (pathname,arr)=>{
        let currentMenu = {}
        for(let i = 0; i < arr.length; i++){
            let item = arr[i]
            if(!item.children && item.path == pathname){
                currentMenu = item
                break;
            }else if(item.children && item.children.length){
                currentMenu = getCurrentMenu(pathname,item.children)
                if(currentMenu.path){
                    break;
                }
            } 
        }
        return currentMenu
    }
    const init = ()=>{
        if(!list.some(item=>item.pathname == route.pathname && item.search == route.search) && route.pathname != '/'){
            let currentMenu = getCurrentMenu(route.pathname,menus)
            let currentRoute = {
                ...route,
                title: currentMenu.title,
            }
            setList([...list,currentRoute])
        }
    }
    const handleClose = (e,{pathname})=>{
        e.stopPropagation();
        e.cancelBubble = true;
        e.preventDefault();
        setList(value=>value.filter(item=>item.pathname != pathname))
        dropByCacheKey(pathname)
        if(route.pathname == pathname){
            browserHistory.push({ pathname: '/' });
        }
    }
    const handleSelect = ({pathname,query,search})=>{
        if(route.pathname == pathname && route.search == search){
            return
        }
        browserHistory.push({ 
            pathname,
            query
        });
    }
    return (
        <div className="navigate_bar" ref={navigateRef}>
            <div className="navigate_content">
                <span className={'navigate_tag ' + (route.pathname == '/' ? 'navigate_active' : '')} onClick={()=>handleSelect({pathname: '/'})}>
                    <span>首页</span>
                </span>
                {
                    list.map((item,index)=>(
                        <span className={'navigate_tag ' + (route.pathname == item.pathname ? 'navigate_active' : '')} onClick={()=>handleSelect(item)} key={index}>
                            <span>{item.title}</span>
                            <Icon type="close" className="close_icon" onClick={(e)=>handleClose(e,item)} />
                        </span>
                    ))
                }
            </div>
        </div>
    )
}

export default Index