import React, { useRef, useState } from "react";
import { Table, Switch, message, Button, Divider, Modal } from "antd";
import SearchBox from "./components/searchBox";
import CreateModal from "./components/createModal";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _gpsList, _gpsStatus, _deleteGps } from "@/statics/js/api.js";
import moment from "moment";

const Index = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "GPS编号",
      dataIndex: "codeId",
    },
    {
      title: "代理商",
      dataIndex: "agentName",
    },
    {
      title: "定位系统",
      width: 90,
      dataIndex: "typeName",
    },
    {
      title: "绑定的设备二维码",
      dataIndex: "imei",
    },
    {
      title: "sim卡编号",
      dataIndex: "simId",
    },
    {
      title: "卡状态",
      width: 80,
      dataIndex: "simStatusName",
    },
    {
      title: "当月剩余流量",
      width: 120,
      dataIndex: "flowSurplus",
    },
    {
      title: "到期时间",
      width: 120,
      dataIndex: "expirationTime",
    },
    {
      title: "系统刷新时间",
      width: 120,
      dataIndex: "turnoverTime",
    },
    {
      title: "是否到期",
      width: 90,
      dataIndex: "isExpire",
    },
    {
      title: "是否绑定设备",
      width: 120,
      dataIndex: "exist",
    },
    {
      title: "加入白名单时间",
      width: 135,
      dataIndex: "addTime",
      render: (text) =>
        text && moment(text * 1000).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "管理",
      dataIndex: "",
      render: (text) => (
        <Switch
          checked={!!text.status}
          checkedChildren="启用"
          unCheckedChildren="禁用"
          onChange={(checked) => handleStateChange(text.id, checked)}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "",
      fixed: "right",
      width: 80,
      key: "Action",
      render: (text) => (
        <span>
          <Button
            type="link"
            disabled={!getJurisdiction(1099)}
            title={getJurisdiction(1099) ? "" : "暂无操作权限！"}
            onClick={() => handleModalShow({ type: 3, id: text.id })}
          >
            查看
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            disabled={!getJurisdiction(1100)}
            title={getJurisdiction(1100) ? "" : "暂无操作权限！"}
            onClick={() => handleModalShow({ type: 2, id: text.id })}
          >
            编辑
          </Button>
        </span>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const modalRef = useRef();
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
    handleRefresh,
  } = useTable({
    action: _gpsList,
    pageSizeKey: "rows",
    curPageKey: "page",
  });
  const search = (values) => {
    let [addTimeStart, addTimeEnd] = values.addTime || [];
    let [startExpirationTime, endExpirationTime] = values.expirationTime || [];
    values.addTimeStart = addTimeStart
      ? moment(addTimeStart).format("YYYY-MM-DD") + " 00:00:00"
      : undefined;
    values.addTimeEnd = addTimeEnd
      ? moment(addTimeEnd).format("YYYY-MM-DD") + " 23:59:59"
      : undefined;
    delete values.addTime;
    values.startExpirationTime = startExpirationTime
      ? moment(startExpirationTime).format("YYYY-MM-DD") + " 00:00:00"
      : undefined;
    values.endExpirationTime = endExpirationTime
      ? moment(endExpirationTime).format("YYYY-MM-DD") + " 23:59:59"
      : undefined;
    delete values.expirationTime;
    const params = {
      ...values,
    };
    handleSearch(params);
  };
  const handleStateChange = async (id, checked) => {
    let params = { id, status: Number(checked) };
    const res = await _gpsStatus(params);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success(msg);
    handleRefresh();
  };
  const handleModalShow = ({ type, id }) => {
    modalRef.current.open({ type, id });
  };
  const handleDelete = async () => {
    Modal.confirm({
      title: "确定要删除吗?",
      content: `删除条目一共${selectedRowKeys.length}项`,
      onOk: async () => {
        const params = selectedRowKeys;
        const res = await _deleteGps(params);
        const { code, message: msg } = res.data || {};
        if (code != 200) {
          message.warning(msg);
          return;
        }
        message.success(msg);
        handleRefresh();
      },
    });
  };
  return !getJurisdiction(1051) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={search} />
        <Button
          type="primary"
          disabled={!getJurisdiction(1101)}
          title={getJurisdiction(1101) ? "" : "暂无操作权限！"}
          onClick={() => handleModalShow({ type: 1 })}
        >
          导入白名单
        </Button>
        <Divider type="vertical" />
        <Button
          type="danger"
          onClick={handleDelete}
          disabled={!selectedRowKeys.length}
        >
          批量删除
        </Button>
        {!!selectedRowKeys.length && (
          <>
            <Divider type="vertical" />
            {`已选中 ${selectedRowKeys.length} 项`}
          </>
        )}
      </div>
      <Table
        rowKey="id"
        bordered
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (rowKeys) => setSelectedRowKeys(rowKeys),
        }}
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 2100, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
      <CreateModal
        wrappedComponentRef={(ref) => (modalRef.current = ref)}
        done={handleRefresh}
      />
    </div>
  );
};

export default Index;
