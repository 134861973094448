import React, { Component,createRef } from "react";
import {
  Button,
  Table,
  Modal,
  Switch,
  Divider,
  Select,
  Input,
  Form,
  Row,
  Col,
  message,
  InputNumber,
} from "antd";
import {
  _franchiseeList,
  _modelPriceList,
  _modelPriceDetail,
  _modelPriceUpdateStatus,
  _modelPriceSave,
  _bikeModelList,
  _merchantBikeModels,
  _batteryModelList,
  _merchantBatModels,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const { Option } = Select;
const modalTitle = ["添加型号价格", "编辑型号价格", "型号价格详情"];

// 操作弹窗
class AddModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      type: 1,
      id: "",
      initialValues: {
        fid: '',
        type: '',
        modelId: '',
        commonPrice: 0,
        kaPrice: 0,
        companyPrice: 0,
        status: 1,
        remark: ''
      },
      visible: false,
      modelList: [],
    };
    props.onRef(this)
    this.open = this.open.bind(this)
  }
  open({id,type}){
    this.setState({
      visible: true,
      type,
      id,
    },()=>{
      if(type == 1){
        // this.props.form && this.props.form.resetFields();
      }else if(type == 2){
        this.getDetail()
      }else if(type == 3){
        this.getDetail()
      }
    })
  }
  close(){
    let {
      form,
    } = this.props
    form && form.resetFields();
    this.setState({
      visible: false,
      initialValues: {
        fid: '',
        type: '',
        modelId: '',
        commonPrice: 0,
        kaPrice: 0,
        companyPrice: 0,
        status: 1,
        remark: ''
      },
      modelList: [],
    })
  }
  handleTypeChange(value){
    const { setFieldsValue } = this.props.form;
    const {bikeModelList,batteryModelList} = this.props
    setFieldsValue({modelId: ''})
    if(value == 1){
      this.setState({
        modelList: [...bikeModelList]
      })
    }else if(value == 2){
      this.setState({
        modelList: [...batteryModelList]
      })
    }else {
      this.setState({
        modelList: []
      }) 
    }
  }
  //获取代理商详情
  getDetail() {
    let {id} = this.state
    let {bikeModelList,batteryModelList} = this.props
    let params = { id };
    _modelPriceDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let values = res.data.data
          this.setState({
            initialValues: {...values},
            modelList: values.type == 1 ? [...bikeModelList] : [...batteryModelList]
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const {id,type} = this.state
    form.validateFields((err, values) => {
      if (err) return;
      if (type === 3) {
        this.close()
      } else {
        let params =
        type === 1
            ? {
                ...values,
              }
            : type === 2
            ? {
                id,
                ...values,
              }
            : {};
            params.status = params.status ? 1 : 0
            _modelPriceSave(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
  render(){
    const { 
      form: {
        getFieldDecorator
      },
      franchiseeList,
    } = this.props;
    const {initialValues,type,visible,modelList} = this.state
    return (
      <div>
        <Modal
          className="bigModal"
          title={modalTitle[type - 1]}
          destroyOnClose
          maskClosable={false}
          visible={visible}
          onOk={this.handleOk.bind(this)}
          onCancel={()=>this.close()}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="公司名称">
                {type === 3 ? (
                  <span>{initialValues.fname}</span>
                ) : (
                  getFieldDecorator("fid",{
                    initialValue: initialValues.fid,
                    rules: [{ required: true, message: "请选择公司名称" }],
                  })(
                    <Select
                      placeholder="请选择"
                      allowClear
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {franchiseeList.length &&
                        franchiseeList.map((item, index) => {
                          return (
                            <Option key={index} value={item.fid}>
                              {item.fname}
                            </Option>
                          );
                        })}
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="设备类别">
                {type === 3 ? (
                    <span>{initialValues.typeName}</span>
                  ) : (getFieldDecorator("type", {
                    initialValue: initialValues.type,
                    rules: [{ required: true, message: "请选择设备类别" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      onChange={this.handleTypeChange.bind(this)}
                    >
                      <Option value={1}>车辆</Option>
                      <Option value={2}>电池</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="型号名称">
                {type === 3 ? (
                      <span>{initialValues.modelName}</span>
                    ) : (getFieldDecorator("modelId", {
                    initialValue: initialValues.modelId,
                    rules: [{ required: true, message: "请选择型号！" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {modelList.length &&
                        modelList.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="厂家月租金">
                {type === 3 ? (
                        <span>{initialValues.companyPrice}</span>
                      ) : (getFieldDecorator("companyPrice", {
                    initialValue: initialValues.companyPrice,
                  })(
                    <InputNumber
                      min={0}
                      precision={2}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="大客户月租金">
                {type === 3 ? (
                      <span>{initialValues.kaPrice}</span>
                    ) : (getFieldDecorator("kaPrice", {
                    initialValue: initialValues.kaPrice,
                    rules: [{ required: true, message: "请输入大客户月租金" }],
                  })(
                    <InputNumber
                      min={0}
                      precision={2}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="用户月租金">
                {type === 3 ? (
                        <span>{initialValues.commonPrice}</span>
                      ) : (getFieldDecorator("commonPrice", {
                    initialValue: initialValues.commonPrice,
                  })(
                    <InputNumber
                      min={0}
                      precision={2}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="备注">
                {type === 3 ? (
                          <span>{initialValues.remark}</span>
                        ) : (getFieldDecorator("remark", {
                    initialValue: initialValues.remark,
                  })( 
                    <Input placeholder="备注"/>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status === 0 ? false : true,
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={type === 3 && true}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

AddModal = Form.create({})(AddModal);

class ModelPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      list: [], //代理商管理列表
      queryFormValues: {
        status: 1,
        type: '',
        fid: '',
        modelId: '',
      }, //筛选项
      selectedRowKeys: [], // Check here to configure the default column
      loading: false, //加载
      franchiseeList: [],
      userType: localStorage.getItem("type"),
      bikeModelList: [],
      batteryModelList: [],
      modelList: [],
    };
    this.addModal = createRef(null)
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "公司名称",
        dataIndex: "fname",
      },
      {
        title: "设备类别",
        dataIndex: "type",
        render: (text) => text == 1 ? '车辆' : text == 2 ? '电池' : '',
      },
      {
        title: "型号名称",
        dataIndex: "modelName",
      },
      {
        title: "厂家月租金",
        dataIndex: "companyPrice",
      },
      {
        title: "大客户月租金",
        dataIndex: "kaPrice",
      },
      {
        title: "用户租金",
        dataIndex: "commonPrice",
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={()=>this.showModal(3,text.id)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={()=>this.showModal(2, text.id)}
            >
              编辑
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { 
      form:{
        getFieldDecorator
      },
    } = this.props;
    const {queryFormValues,franchiseeList,modelList} = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="公司名称">
              {getFieldDecorator("fid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {franchiseeList.length &&
                    franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="设备类别">
              {getFieldDecorator("type", {
                initialValue: queryFormValues.type,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                  onChange={this.handleTypeChange.bind(this)}
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>车辆</Option>
                  <Option value={2}>电池</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="型号名称">
              {getFieldDecorator("modelId")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {modelList.length &&
                    modelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status", {
                initialValue: queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={4} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      // selectedRowKeys,
      list,
      totalCount,
      curPage,
      pageSize,
      bikeModelList,
      batteryModelList,
      franchiseeList,
    } = this.state;
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange.bind(this),
    // };
    // const hasSelected = selectedRowKeys.length > 0;
    
    return (
      <div>
        {!!getJurisdiction(1032) ? (
          <div className="AgentManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={()=>this.showModal(1,'')}
              >
                添加型号价格
              </Button>
              {/* <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`} */}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                // rowSelection={rowSelection}
                columns={this.columns}
                dataSource={list}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper:true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <AddModal
              onRef={ref=>{this.addModal = ref}}
              franchiseeList={franchiseeList}
              bikeModelList={bikeModelList}
              batteryModelList={batteryModelList}
            />
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getBikeModelList()
    this.getBatteryModelList()
    this.getFranchiseeList()
    this.getList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  handleTypeChange(value){
    const { setFieldsValue } = this.props.form;
    const {bikeModelList,batteryModelList} = this.state
    setFieldsValue({modelId: ''})
    if(value == 1){
      this.setState({
        modelList: [...bikeModelList]
      })
    }else if(value == 2){
      this.setState({
        modelList: [...batteryModelList]
      })
    }else {
      this.setState({
        modelList: []
      }) 
    }
  }
  //获取车辆型号列表
  getBikeModelList() {
    let params;
    if (this.state.userType === "0") {
      params = {
        curPage: 1,
        pageSize: 999,
      };
      _bikeModelList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              bikeModelList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params = {
        fid: localStorage.getItem("fid"),
        curPage: 1,
        pageSize: 999,
      };
      _merchantBikeModels(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              bikeModelList: res.data.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  //获取车辆型号列表
  getBatteryModelList() {
    let params;
    if (this.state.userType === "0") {
      params = {
        curPage: 1,
        pageSize: 999,
      };
      _batteryModelList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              batteryModelList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params = {
        fid: localStorage.getItem("fid"),
        curPage: 1,
        pageSize: 999,
      };
      _merchantBatModels(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              batteryModelList: res.data.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
      ftype: 1
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取列表
  getList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _modelPriceList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            list: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getList(curPage, pageSize, this.state.queryFormValues);
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null
        ) {
          delete values[key];
        }
      }
      values = {
        ...this.state.queryFormValues,
        ...values
      }
      this.setState({
        queryFormValues: values,
      });
      this.getList(1, this.state.pageSize, values);
    });
  };

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _modelPriceUpdateStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(type,id) {
    this.addModal.open({id,type})
  }
  //选中数据
  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }
}

ModelPrice = Form.create({})(ModelPrice);
export { ModelPrice };
