import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Input,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import {
  _batteryModelList,
  _getBatteryModelRuleList,
  _getBatteryModelRuleDetails,
  _deleteBatteryModelRuleInfo,
  _saveBatteryModelRuleInfo,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";
const { Option } = Select;
const modalTitle = ["添加电池分润规则", "编辑电池分润规则", "电池分润规则详情"];
class BatteryTranferRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      batteryRuleList: [], //电池分润列表
      batteryModelList: [], //代理商列表
      queryFormValues: {}, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      id: "", //电池分润id
      initialValues: {}, //电池分润详情
    };
    this.columns = [
      {
        title: "序号",
        width: 80,
        dataIndex: "id",
      },

      {
        title: "电池型号",
        dataIndex: "modelName",
      },
      {
        title: "城市",
        dataIndex: "",
        render: (text) => text.provinceName + "/" + text.cityName,
      },
      {
        title: "转账账户名称",
        dataIndex: "transferAccountName",
      },
      {
        title: "转账账户",
        align: "right",
        dataIndex: "transferAccount",
      },
      {
        title: "转账金额",
        align: "right",
        width: 100,
        dataIndex: "transferAmount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "成本价",
        align: "right",
        width: 100,
        dataIndex: "price",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "addTime",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.batteryModelStatus}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            disabled={!getJurisdiction(882)}
            title={getJurisdiction(882) ? "" : "暂无操作权限！"}
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(869)}
              title={getJurisdiction(869) ? "" : "暂无操作权限！"}
              onClick={this.getBatteryModelRuleDetails.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(867)}
              title={getJurisdiction(867) ? "" : "暂无操作权限！"}
              onClick={this.getBatteryModelRuleDetails.bind(this, text.id, 2)}
            >
              编辑
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.batteryModelList.length &&
                    this.state.batteryModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { totalCount, curPage, pageSize, batteryRuleList, initialValues } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {getJurisdiction(866) ? (
          <div className="BatteryTranferRule">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(868)}
                title={getJurisdiction(868) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加电池分润规则
              </Button>
            </div>
            <div className="content-table">
              <Table
                scroll={{ x: 1300, y: 500 }}
                rowKey="id"
                columns={this.columns}
                dataSource={batteryRuleList}
                bordered
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="电池型号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.modelName}</span>
                    ) : (
                      getFieldDecorator("modelId", {
                        initialValue: initialValues.modelId,
                        rules: [
                          { required: true, message: "请选择电池型号！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.batteryModelList.length &&
                            this.state.batteryModelList.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="城市">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.provinceName} / {initialValues.cityName}
                      </span>
                    ) : (
                      getFieldDecorator("areaList", {
                        initialValue: initialValues.areaList,
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: "请选择地区！",
                          },
                        ],
                      })(
                        <AreaCascader
                          changeOnSelect
                          placeholder="城市"
                          style={{ width: "100%" }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账账户名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.transferAccountName}</span>
                    ) : (
                      getFieldDecorator("transferAccountName", {
                        initialValue: initialValues.transferAccountName,
                        rules: [
                          { required: true, message: "请输入转账账号名称！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账账户">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.transferAccount}</span>
                    ) : (
                      getFieldDecorator("transferAccount", {
                        initialValue: initialValues.transferAccount,
                        rules: [
                          { required: true, message: "请输入转账账号！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账金额">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.transferAmount}</span>
                    ) : (
                      getFieldDecorator("transferAmount", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的金额（保留两位小数）",
                            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                          },
                          { required: true, message: "请输入转账金额！" },
                        ],
                        initialValue: initialValues.transferAmount,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="成本价">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.price}</span>
                    ) : (
                      getFieldDecorator("price", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的金额（保留两位小数）",
                            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                          },
                          { required: true, message: "请输入成本价！" },
                        ],
                        initialValue: initialValues.price,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("batteryModelStatus", {
                      initialValue:
                        initialValues.batteryModelStatus === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getBtteryModelList();
    this.getBatteryModelRuleList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取电池分润列表
  getBatteryModelRuleList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _getBatteryModelRuleList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            batteryRuleList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getBtteryModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _batteryModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            batteryModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getBatteryModelRuleList(curPage, pageSize, this.state.queryFormValues);
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, batteryModelStatus: Number(checked) };
    _deleteBatteryModelRuleInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBatteryModelRuleList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.batteryModelStatus;
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getBatteryModelRuleList(1, this.state.pageSize, values);
    });
  };

  //获取电池规则详情
  getBatteryModelRuleDetails(id, type) {
    let params = { id };
    _getBatteryModelRuleDetails(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { province, city, county, street } = res.data.data || {};
          this.setState({
            id,
            initialValues: {
              ...res.data.data,
              areaList: [province, city, county, street].filter((item) => item),
            },
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.batteryModelStatus !== undefined) {
        values.batteryModelStatus = Number(values.batteryModelStatus);
      }
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1]
          ? values.areaList[1]
          : values.areaList[0];
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveBatteryModelRuleInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              this.initFormModal();
              this.getBatteryModelRuleList(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              );
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
      modalType: null,
    });
  }
}

BatteryTranferRule = Form.create({})(BatteryTranferRule);
export { BatteryTranferRule };
