import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Select,
  Modal,
  Form,
  Checkbox,
  Row,
  Col,
  message,
} from "antd";
import {
  _franchiseeList,
  _siteRepairList,
  _siteRepairUserList,
  _deleteSiteUser,
  _batchDeleteSiteUser,
  _updateSiteUserStatus,
  _siteUserDetail,
  _saveSiteUser,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import md5 from "md5";
const { Option } = Select;
const { TextArea } = Input;
const modalTitle = ["添加退车点管理员", "编辑管理员"];
class SiteRepairAdministrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商列表
      siteUserList: [], //站点列表
      siteRepairList: [], //站点列表
      queryFormValues: {}, //筛选项
      id: "", //退车点用户id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      initialValues: {}, //站点管理员详情
      visible: false, //Modal是否打开
      modalType: "", //1-添加，2-编辑
      selectType: "", //选中类型
      userType: localStorage.getItem("type"),
      userFid: localStorage.getItem("fid"),
    };
    this.columns = [
      {
        title: "用户名",
        dataIndex: "username",
      },
      {
        title: "手机号",
        align: "right",
        width: 150,
        dataIndex: "phone",
      },

      {
        title: "用户类型",
        width: 120,
        dataIndex: "typeName",
      },
      {
        title: "角色",
        width: 110,
        dataIndex: "authGroupName",
      },
      {
        title: "描述说明",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(896)}
            title={getJurisdiction(896) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },

      {
        title: "添加时间",
        width: 120,
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "更新时间",
        width: 120,
        dataIndex: "updateTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(892)}
              title={getJurisdiction(892) ? "" : "暂无操作权限！"}
              onClick={this.getSiteUserDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(895)}
              title={getJurisdiction(895) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteSiteUserChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="手机/用户名">
              {getFieldDecorator("queryString")(
                <Input placeholder="请输入" allowClear />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="退站点名称/代理商">
              {getFieldDecorator("typeName")(
                <Input placeholder="请输入" allowClear />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      loading,
      selectedRowKeys,
      totalCount,
      initialValues,
      curPage,
      pageSize,
      siteUserList,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(891) ? (
          <div className="SiteRepairAdministrator">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(893)}
                title={getJurisdiction(893) ? "" : "暂无操作权限！"}
                onClick={this.showModalAdd.bind(this, 1)}
              >
                添加退车点管理员
              </Button>
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(894) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={siteUserList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="用户名">
                    {getFieldDecorator("username", {
                      initialValue: initialValues.username,
                      rules: [{ required: true, message: "请输入用户名！" }],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="手机号码">
                    {getFieldDecorator("phone", {
                      validateTrigger: "onBlur",
                      rules: [
                        {
                          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                          message: "请输入正确的手机号格式",
                        },
                        { required: true, message: "请输入手机号码！" },
                      ],
                      initialValue: initialValues.phone,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="登录密码">
                    {getFieldDecorator("password", {
                      validateTrigger: "onBlur",
                      rules: [
                        {
                          message: "请输入至少六位数密码",
                          pattern: /^.{6,}$/,
                        },
                      ],
                    })(
                      <Input
                        style={{ width: "60%" }}
                        allowClear
                        placeholder="默认密码123456"
                      />
                    )}
                    <span>
                      {this.state.modalType === 1 ? (
                        <span> 请输入用户密码</span>
                      ) : (
                        <span> 不修改则无需填写</span>
                      )}
                    </span>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="用户类型">
                    {getFieldDecorator("type", {
                      initialValue: (initialValues.type = 3),
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        onChange={this.handleSelectTypeChange.bind(this)}
                        disabled
                      >
                        <Option value={3}>退车点用户</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="所属角色">
                    {getFieldDecorator("authGroupId", {
                      initialValue: (initialValues.type = 9),
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        disabled
                      >
                        <Option value={9}>管理员</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="所属代理商">
                    {getFieldDecorator("fid", {
                      initialValue:
                        initialValues.franchisee === undefined ||
                        initialValues.franchisee === null
                          ? undefined
                          : initialValues.franchisee.fid,
                      rules: [
                        { required: true, message: "请选择所属代理商！" },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        onChange={this.getSiteRepairList.bind(this)}
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.franchiseeList.length &&
                          this.state.franchiseeList.map((item, index) => {
                            return (
                              <Option key={index} value={item.fid}>
                                {item.fname}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="所属站点">
                    {getFieldDecorator("rids", {
                      initialValue: initialValues.siteRepairList,
                      rules: [{ required: true, message: "请选择所属站点！" }],
                    })(<Checkbox.Group options={this.state.siteRepairList} />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="描述说明">
                    {getFieldDecorator("remark", {
                      initialValue: initialValues.remark,
                    })(
                      <TextArea
                        placeholder="请输入"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getSiteUserList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  handleSelectTypeChange(value) {
    this.setState({
      selectType: value,
    });
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取退车点列表
  getSiteRepairList(value) {
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteRepairList(params)
      .then((res) => {
        if (res.data.code === 200) {
          let siteRepairList = res.data.data.rows;
          if (siteRepairList instanceof Array) {
            siteRepairList = siteRepairList.map((item, index) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
          } else {
            siteRepairList = [];
          }
          this.setState({
            siteRepairList,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点管理员列表
  getSiteUserList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _siteRepairUserList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            siteUserList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getSiteUserList(curPage, pageSize, this.state.queryFormValues);
  }

  //单条删除
  handleDeleteSiteUserChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据吗？",
      onOk() {
        let params = { id };
        _deleteSiteUser(params).then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteSiteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
            self.setState({
              selectedRowKeys: [],
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateSiteUserStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSiteUserList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商详情
  showModalAdd(type) {
    this.state.userType === "1" && this.getSiteRepairList(this.state.userFid);
    this.showModal(type);
  }

  //获取退车点管理员详情
  getSiteUserDetail(id, type) {
    let params = { id };
    _siteUserDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let initialValues = res.data.data;
          let siteRepairList = [];
          if (
            !!initialValues.siteRepairList &&
            initialValues.siteRepairList.length
          ) {
            initialValues.siteRepairList.map((item, index) => {
              siteRepairList.push(item.id);
              return item;
            });
          }
          initialValues.siteRepairList = siteRepairList;
          initialValues.franchisee &&
            this.getSiteRepairList(initialValues.franchisee.fid);
          this.setState({
            id,
            initialValues: initialValues,
            selectType: String(res.data.data.type),
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      this.state.userType === "1" && (values.fid = this.state.userFid);
      !!values.password && (values.password = md5(values.password));
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? {
                ...values,
              }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveSiteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: 0,
      initialValues: {},
      siteRepairList: [],
      selectType: "",
      visible: false,
    });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      this.setState({
        queryFormValues: values,
      });
      this.getSiteUserList(1, this.state.pageSize, values);
    });
  };
}

SiteRepairAdministrator = Form.create({})(SiteRepairAdministrator);
export { SiteRepairAdministrator };
