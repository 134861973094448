import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  Switch,
  Divider,
  DatePicker,
  Cascader,
  Input,
  Form,
  Row,
  Col,
  message,
} from "antd";
import {
  _repCustomerList,
  _repCustomerDetail,
  _getSonsOfRegion,
  _updateRepCustomerStatus,
  _addRepCustomer,
  _deleteRepCustomerStatus,
  _batchDeleteRepCustomer,
} from "@/statics/js/api";
import md5 from "md5";
import moment from "moment";

const { RangePicker } = DatePicker;
const modalTitle = ["添加代理商", "编辑代理商", "代理商详情"];
class KARepCustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      customerList: [], //代理商管理列表
      queryFormValues: {}, //筛选项
      id: "", //代理商id
      initialValues: {}, //代理商详情
      visible: false, //Modal是否打开
      selectedRowKeys: [], // Check here to configure the default column
      loading: false, //加载
      modalType: "", //1-添加，2-编辑，3-查看
      fileList: [], //上传格式
      options: [], //地区
    };
    this.columns = [
      {
        title: "代理商编号",
        width: 120,
        dataIndex: "id",
      },
      {
        title: "代理商名称",
        width: 250,
        dataIndex: "name",
      },
      {
        title: "联系人",
        width: 120,
        dataIndex: "contacts",
      },
      {
        title: "手机号",
        align: "right",
        width: 150,
        dataIndex: "mobile",
      },
      {
        title: "城市",
        width: 120,
        dataIndex: "areaName",
      },
      {
        title: "合作期限",
        width: 145,
        dataIndex: "",
        render: (text) => (
          <span>
            {text.startCooperation ? text.startCooperation : ""}
            {text.startCooperation && text.endCooperation ? (
              <span>
                <br />
                ~
                <br />
              </span>
            ) : null}
            {text.endCooperation ? text.endCooperation : ""}
          </span>
        ),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "updateTime",
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        key: "Action",
        width: 100,
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.getCustomerDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.getCustomerDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDeleteFranchiseeChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商/手机号：">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <Cascader
                  options={this.state.options}
                  loadData={this.getLoadData.bind(this)}
                  changeOnSelect
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      initialValues,
      customerList,
      totalCount,
      curPage,
      pageSize,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="KARepCustomerList">
        <div className="select-form">{this.renderForm()}</div>
        <div className="top-btn">
          <Button
            icon="plus"
            type="primary"
            onClick={this.showModal.bind(this, 1)}
          >
            添加代理商
          </Button>
          <Button
            onClick={this.handleBatchDeleteChange.bind(this)}
            disabled={!hasSelected}
            loading={loading}
            icon="delete"
            type="danger"
          >
            批量删除
          </Button>
          {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
        </div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={customerList}
            scroll={{ x: 1400, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title={modalTitle[this.state.modalType - 1]}
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="代理商名称">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.name}</span>
                ) : (
                  getFieldDecorator("name", {
                    initialValue: initialValues.name,
                    rules: [{ required: true, message: "请输入代理商名称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="联系人">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.contacts}</span>
                ) : (
                  getFieldDecorator("contacts", {
                    initialValue: initialValues.contacts,
                    rules: [{ required: true, message: "请输入联系人！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="手机号">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.mobile}</span>
                ) : (
                  getFieldDecorator("mobile", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: "请输入正确的手机号格式",
                      },
                      { required: true, message: "请输入手机号！" },
                    ],
                    initialValue: initialValues.mobile,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            {this.state.modalType === 1 && (
              <Col span={24}>
                <Form.Item label="密码">
                  {getFieldDecorator("password", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        message: "请输入至少六位数密码",
                        pattern: /^.{6,}$/,
                      },
                    ],
                  })(<Input allowClear placeholder="默认密码123456" />)}
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item label="合作期限">
                {this.state.modalType === 3 ? (
                  <span>
                    {initialValues.startCooperation &&
                    initialValues.endCooperation
                      ? initialValues.startCooperation +
                        "～" +
                        initialValues.startCooperation
                      : ""}
                  </span>
                ) : (
                  getFieldDecorator("cooperation", {
                    initialValue:
                      initialValues.startCooperation &&
                      initialValues.endCooperation
                        ? [
                            moment(initialValues.startCooperation),
                            moment(initialValues.endCooperation),
                          ]
                        : undefined,
                    rules: [{ required: true, message: "请选择合作期限！" }],
                  })(<RangePicker format="YYYY-MM-DD" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="地区">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.areaName}</span>
                ) : (
                  getFieldDecorator("areaList", {
                    rules: [
                      {
                        required: this.state.modalType === 1 ? true : false,
                        message: "请选择地区！",
                      },
                    ],
                  })(
                    <Cascader
                      placeholder={
                        initialValues.areaName && initialValues.areaName
                      }
                      style={{ width: "100%" }}
                      options={this.state.options}
                      loadData={this.getLoadData.bind(this)}
                      changeOnSelect
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="详情地址">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.address}</span>
                ) : (
                  getFieldDecorator("address", {
                    initialValue: initialValues.address,
                    rules: [{ required: true, message: "请输入详情地址！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="公司全称">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.companyName}</span>
                ) : (
                  getFieldDecorator("companyName", {
                    initialValue: initialValues.companyName,
                    rules: [{ required: true, message: "请输入公司全称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="纳税识别号">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.taxNo}</span>
                ) : (
                  getFieldDecorator("taxNo", {
                    initialValue: initialValues.taxNo,
                    rules: [{ required: true, message: "请输入纳税识别号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="开户行">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.bankName}</span>
                ) : (
                  getFieldDecorator("bankName", {
                    initialValue: initialValues.bankName,
                    rules: [{ required: true, message: "请输入开户行！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="银行账号">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.bankAccount}</span>
                ) : (
                  getFieldDecorator("bankAccount", {
                    initialValue: initialValues.bankAccount,
                    rules: [{ required: true, message: "请输入银行账号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付宝名称">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.alipayName}</span>
                ) : (
                  getFieldDecorator("alipayName", {
                    initialValue: initialValues.alipayName,
                    rules: [{ required: true, message: "请输入支付宝名称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付宝账号">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.alipayAccounts}</span>
                ) : (
                  getFieldDecorator("alipayAccounts", {
                    initialValue: initialValues.alipayAccounts,
                    rules: [{ required: true, message: "请输入支付宝账号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status === 0 ? false : true,
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={this.state.modalType === 3 && true}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.getRepCustomerList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
    this.getSonsOfRegion(0);
  }

  //获取代理商列表
  getRepCustomerList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _repCustomerList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            customerList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商详情
  getCustomerDetail(id, type) {
    let params = { id };
    _repCustomerDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //加载地区数据
  getLoadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let params = { parentid: targetOption.value };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          targetOption.children = areaList;
          targetOption.loading = false;
          let options = [...this.state.options];
          this.setState({
            options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取一级地区
  getSonsOfRegion(parentid) {
    let params = { parentid };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          this.setState({
            options: [...areaList],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getRepCustomerList(curPage, pageSize, this.state.queryFormValues);
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      let province, city, county, street;
      if (values.areaList) {
        province = values.areaList[0] ? values.areaList[0] : null;
        city = values.areaList[1] ? values.areaList[1] : null;
        county = values.areaList[2] ? values.areaList[2] : null;
        street = values.areaList[3] ? values.areaList[3] : null;
        values.province = province;
        values.city = city;
        values.county = county;
        values.street = street;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getRepCustomerList(1, this.state.pageSize, values);
    });
  };

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateRepCustomerStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getRepCustomerList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.password) {
        values.password = md5(values.password);
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (values.cooperation && values.cooperation.length) {
        values.startCooperation = moment(values.cooperation[0]).format(
          "YYYY-MM-DD"
        );
        values.endCooperation = moment(values.cooperation[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.cooperation;
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _addRepCustomer(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: 0,
      initialValues: {},
      visible: false,
    });
  }

  //单条删除
  handleDeleteFranchiseeChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据吗？",
      onOk() {
        let params = { id };
        _deleteRepCustomerStatus(params).then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteRepCustomer(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //选中数据
  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }
}

KARepCustomerList = Form.create({})(KARepCustomerList);
export { KARepCustomerList };
