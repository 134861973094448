import React, { useEffect, forwardRef } from "react";
import { Cascader } from "antd";
import { _getSonsOfRegion } from "@/statics/js/api";
import { updateRegionAction } from "@/redux/actions/region";
import { useSelector, useDispatch } from "react-redux";

const AreaCascader = forwardRef((props, ref) => {
  const options = useSelector((state) => state.region);
  const dispatch = useDispatch();

  useEffect(() => {
    initRegion();
  }, [props.value]);

  // 初始化
  const initRegion = async () => {
    let arr = [...options];
    if (!arr.length) {
      arr = await getRegion(0);
    }
    const list = await getList(arr);
    dispatch(updateRegionAction([...list]));
  };
  // 同步递归请求数据
  const getList = async (list = []) => {
    const { value = [] } = props;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (
        value.includes(item.value) &&
        !item.isLeaf &&
        (!item.children || !item.children.length)
      ) {
        let arr = await getRegion(item.value);
        if (arr.length) {
          item.children = await getList(arr);
        }
      }
    }
    return list;
  };
  // 动态加载
  const getLoadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.children) {
      return;
    }
    targetOption.loading = true;
    // load options lazily
    let parentid = targetOption.value;
    const areaList = await getRegion(parentid);
    targetOption.children = areaList;
    targetOption.loading = false;
    dispatch(updateRegionAction([...options]));
  };
  // 获取层级数据
  const getRegion = async (parentid) => {
    const params = { parentid };
    const { data } = await _getSonsOfRegion(params);
    let areaList = data.data || [];
    if (data.code == 200) {
      if (areaList instanceof Array) {
        areaList = areaList.map((item, index) => {
          return {
            label: item.name,
            value: +item.areaid,
            isLeaf: !item.hasChild,
          };
        });
      }
    }
    return areaList;
  };

  return <Cascader options={options} loadData={getLoadData} {...props} />;
});

export default AreaCascader;
