import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  DatePicker,
  Input,
  Select,
  message,
  Row,
  Col,
} from "antd";
import {
  _allPerformanceRecordList,
  _franchiseeList,
  _siteBoxList,
  _exportAllPerformanceRecordList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import queryString from 'query-string';

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
class PerformanceRecordList extends Component {
  constructor(props) {
    super(props);
    const { fid } = queryString.parse(this.props.location.search);
    this.state = {
      queryFormValues: {
        fid: fid
          ? Number(fid)
          : undefined,
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      performanceRecordList: [], //履约记录列表
      franchiseeList: [], //代理商列表
      siteList: [], //站点列表
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 60,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "订单号",
        align: "right",
        width: 140,
        dataIndex: "orderSn",
      },
      {
        title: "流水号",
        align: "right",
        width: 120,
        dataIndex: "tradeNo",
      },
      {
        title: "所属站点",
        dataIndex: "",
        width: 160,
        render: (text) => (
          <span>
            {text.fname}
            <br />
            {text.sname}
          </span>
        ),
      },
      {
        title: "车辆型号",
        width: 120,
        dataIndex: "bikeModelName",
      },
      {
        title: "车辆信息",
        dataIndex: "",
        width: 240,
        render: (text) => (
          <span>
            {text.bikeId && "车架号:" + text.bikeId}
            {text.bikeId && <br />}
            {text.ccodeId && "GPS号:" + text.ccodeId}
            {text.ccodeId && <br />}
            {text.qcodeId && "二维码:" + text.qcodeId}
            {text.qcodeId && <br />}
            {text.vin && "车牌号:" + text.vin}
          </span>
        ),
      },
      {
        title: "电池",
        width: 200,
        dataIndex: "batVinCode",
        render: (text) =>
          text ? <span style={{ whiteSpace: "pre-line" }}>{text}</span> : " 无",
      },
      {
        title: "电池型号",
        width: 200,
        dataIndex: "batModelName",
        render: (text) =>
          text ? <span style={{ whiteSpace: "pre-line" }}>{text}</span> : " 无",
      },
      {
        title: "挂起状态",
        dataIndex: "",
        width: 110,
        render: (text) => (
          <span>
            {text.hangUp}
            <br />
            {text.hangUpTime && text.hangUpTime}
          </span>
        ),
      },
      {
        title: "扣款金额",
        align: "right",
        width: 85,
        dataIndex: "payAmount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "期数",
        width: 60,
        dataIndex: "period",
        render: (text) => text && text + "期",
      },
      {
        title: "计划扣款时间",
        width: 120,
        dataIndex: "deductTimeStr",
      },
      {
        title: "实际扣款时间",
        width: 120,
        dataIndex: "payTimeStr",
      },
      {
        title: "扣款状态",
        width: 90,
        dataIndex: "statusString",
      },
      {
        title: "合同状态",
        width: 85,
        dataIndex: "sinLonStatusString",
      },
      {
        title: "用户信息",
        dataIndex: "",
        width: 130,
        render: (text) => text.realname + text.mobile,
      },
      {
        title: "下单时间",
        width: 120,
        dataIndex: "addTimeStr",
      },
      {
        title: "备注",
        width: 90,
        dataIndex: "remark",
      },
    ];
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid", {
                  initialValue: this.state.queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.getSiteList.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.siteList.length &&
                    this.state.siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="下单时间">
              {getFieldDecorator("addTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户/手机号">
              {getFieldDecorator("mobile")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="计划扣款时间">
              {getFieldDecorator("deductTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="实际扣款时间">
              {getFieldDecorator("payTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否挂起">
              {getFieldDecorator("hangUp")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">未挂起</Option>
                  <Option value="1">已挂起</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(856)}
                title={getJurisdiction(856) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, performanceRecordList } = this.state;
    return (
      <div>
        {!!getJurisdiction(855) ? (
          <div className="PerformanceRecordList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={performanceRecordList}
                scroll={{ x: 2400, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    localStorage.getItem("type") === "1"
      ? this.getSiteList(localStorage.getItem("fid"))
      : this.getFranchiseeList();
    this.getPerformanceRecordList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    this.props.form.setFieldsValue({
      sid: undefined,
    });
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取履约记录列表
  getPerformanceRecordList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _allPerformanceRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            performanceRecordList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPerformanceRecordList(
      curPage,
      pageSize,
      this.state.queryFormValues
    );
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payTimeEnd = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      if (values.deductTime && values.deductTime.length) {
        values.deductTimeStart = moment(values.deductTime[0]).format(
          "YYYY-MM-DD"
        );
        values.deductTimeEnd = moment(values.deductTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.deductTime;
      this.setState({
        queryFormValues: values,
      });
      this.getPerformanceRecordList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payTimeEnd = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      if (values.deductTime && values.deductTime.length) {
        values.deductTimeStart = moment(values.deductTime[0]).format(
          "YYYY-MM-DD"
        );
        values.deductTimeEnd = moment(values.deductTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.deductTime;
      let params = {
        ...values,
      };
      _exportAllPerformanceRecordList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

PerformanceRecordList = Form.create({})(PerformanceRecordList);
export { PerformanceRecordList };
