// import { browserHistory } from "react-router";
const getJurisdiction = (code) => {
  let menuList = localStorage.getItem("menuIds"); //权限列表
  let sys = localStorage.getItem("sys");
  if (sys) {
    menuList = menuList.split(",");
    if (sys === "1") {
      return true;
    } else if (menuList.length) {
      if (menuList.indexOf(String(code)) === -1) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    setTimeout(function () {
      window._history.push({ pathname: "/login" });
      // browserHistory.push({ pathname: "/login" });
    }, 2000);
  }
};
export { getJurisdiction };
