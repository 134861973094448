import React,{
    useState,
    useEffect,
} from 'react';
import './index.less'
import CountUp from 'react-countup';

const Index = (props,ref) => {
    const {value,title} = props
    useEffect(()=>{
        
    },[])
    return (
        <div className="number_card">
            {
                ['left_top', 'right_top', 'left_bottom', 'right_bottom'].map(item=>(
                    <svg key={item} width="30px" height="30px" className={'border_item border_' + item}>
                        <polygon fill="#d4e2f8" points="40, 0 5, 0 0, 5 0, 16 3, 19 3, 7 7, 3 35, 3"/>
                    </svg>
                ))
            }
            <div className="number_title">{title}</div>
            <CountUp
                className="number_value"
                end={value} 
                separator="," 
                preserveValue={true}
            />
        </div>
    )
}

export default Index
