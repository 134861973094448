import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  Divider,
  Select,
  message,
  Row,
  Col,
  Modal,
} from "antd";
import {
  _selectTransfer,
  _franchiseeList,
  _siteBoxList,
  _packageBoxList,
  _packageList,
  _updateTransferDetailed,
  _endTransfer,
  _transferDetailed,
  _transferExport,
  _transferDetailedExport,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const Option = Select.Option;
class TransferringOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packagesOrderLogList: [], //套餐订单列表
      franchiseeList: [], //代理商列表
      siteList: [], //站点列表
      packageList: [], //套餐列表
      visibleDetail: false,
      initialValues: {},
      modalType: "", //1-添加，2-编辑
      deviceType: ''
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "调拨单号",
        dataIndex: "transferNumber",
      },
      {
        title: "发起方",
        dataIndex: "",
        render: (text) =>
          text.launchBy
            ? text.launchBy +
              (text.launchByType === 0
                ? "(代理商)"
                : text.launchByType === 1
                ? "(维修中心)"
                : text.launchByType === 2
                ? "(站点)"
                : null)
            : null,
      },
      {
        title: "设备类型",
        dataIndex: "type",
        render: (text)=> text == 1 ? "电池" : "车辆"
      },
      {
        title: "设备数量",
        dataIndex: "bikeSize",
      },
      {
        title: "调出站点",
        dataIndex: "transferInFrom",
      },
      {
        title: "调入站点",
        dataIndex: "transferOutSite",
      },
      {
        title: "类型",
        dataIndex: "transferType",
        render: (text) =>
          text === 0
            ? "投放"
            : text === 1
            ? "代理商调拨"
            : text === 2
            ? "维修调拨（交付中心）"
            : text === 3
            ? "维修调拨（站点）"
            : text === 4
            ? "调拨退回"
            : null,
      },
      {
        title: "调出单号",
        dataIndex: "transferOutNumber",
      },
      {
        title: "调出单状态",
        dataIndex: "transferOutStatus",
        render: (text) =>
          text === 0
            ? "未出库"
            : text === 1
            ? "已出库"
            : text === 3
            ? "部分出库"
            : text === 2
            ? "调拨单终止"
            : text === 4
            ? "超时"
            : null,
      },
      {
        title: "调出标记信息",
        dataIndex: "transferOutSign",
        render: (text) =>
          text === 0 ? "未标记" : text === 1 ? "已标记" : null,
      },
      {
        title: "调出单生成时间",
        dataIndex: "transferTime",
      },
      {
        title: "调出单完成时间",
        dataIndex: "confirmOutTime",
      },
      {
        title: "调入单号",
        dataIndex: "transferInNumber",
      },
      {
        title: "调入单状态",
        dataIndex: "transferInStatus",
        render: (text) =>
          text === 0
            ? "未入库"
            : text === 1
            ? "已入库"
            : text === 2
            ? "调拨单终止"
            : text === 3
            ? "超时"
            : null,
      },
      {
        title: "完成时间",
        dataIndex: "confirmInTime",
      },
      {
        title: "操作",
        dataIndex: "",
        key: "Action",
        width: 120,
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(947)}
              title={getJurisdiction(947) ? "" : "暂无操作权限！"}
              onClick={this.getBikeDetail.bind(
                this,
                1,
                {...text}
              )}
            >
              清单
            </Button>
            {!text.transferInStatus && <Divider type="vertical" />}
            {!text.transferInStatus && (
              <Button
                type="link"
                disabled={!getJurisdiction(948)}
                title={getJurisdiction(948) ? "" : "暂无操作权限！"}
                onClick={this.getBikeDetail.bind(
                  this,
                  2,
                  {...text}
                )}
              >
                编辑
              </Button>
            )}
            {localStorage.getItem("type") === "0" && (
              <Divider type="vertical" />
            )}
            {localStorage.getItem("type") === "0" && (
              <Button
                type="link"
                disabled={!getJurisdiction(949)}
                title={getJurisdiction(949) ? "" : "暂无操作权限！"}
                onClick={this.handleTerminationChange.bind(
                  this,
                  {...text}
                )}
              >
                终止调拨单
              </Button>
            )}
          </span>
        ),
      },
    ];
    this.detailColumns = []
    this.bikeColumns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "车架号",
        dataIndex: "imei",
      },
      {
        title: "车辆二维码",
        dataIndex: "qrcode",
      },
      {
        title: "GPS号",
        dataIndex: "ccodeId",
      },
      {
        title: "车牌号",
        dataIndex: "vin",
      },
      {
        title: "车辆型号",
        dataIndex: "model",
      },
      {
        title: "标记",
        dataIndex: "transferSignText",
      },
      {
        title: "是否出库",
        dataIndex: "bikeStatus",
        render: (text) =>
          text === 0
            ? "未确认"
            : text === 1
            ? "已确认"
            : text === 2
            ? "已出库"
            : text === 3
            ? "已入库"
            : text === 4
            ? "已标记"
            : "",
      },
    ];
    this.batteryColumns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "电池编号",
        dataIndex: "imei",
      },
      {
        title: "电池二维码",
        dataIndex: "qrcode",
      },
      {
        title: "GPS号",
        dataIndex: "ccodeId",
      },
      {
        title: "电池型号",
        dataIndex: "model",
      },
      {
        title: "标记",
        dataIndex: "transferSignText",
      },
      {
        title: "是否出库",
        dataIndex: "bikeStatus",
        render: (text) =>
          text === 0
            ? "未确认"
            : text === 1
            ? "已确认"
            : text === 2
            ? "已出库"
            : text === 3
            ? "已入库"
            : text === 4
            ? "已标记"
            : "",
      },
    ];
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="调拨单号">
              {getFieldDecorator("transferNumber")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="设备二维码">
              {getFieldDecorator("qrcode")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    onChange={this.getSiteList.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="发起方">
              {getFieldDecorator("launchName")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="调入站点">
              {getFieldDecorator("transferOutSite")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.siteList.length &&
                    this.state.siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="类型">
              {getFieldDecorator("transferType")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>投放</Option>
                  <Option value={1}>代理商调拨</Option>
                  <Option value={2}>维修调拨</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="调出单状态">
              {getFieldDecorator("transferOutStatus")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>未出库</Option>
                  <Option value={1}>已出库</Option>
                  <Option value={3}>部分出库</Option>
                  <Option value={2}>调拨单终止</Option>
                  <Option value={4}>超时</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="调入单状态">
              {getFieldDecorator("transferInStatus")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>未入库</Option>
                  <Option value={1}>已入库</Option>
                  <Option value={2}>调拨单终止</Option>
                  <Option value={3}>超时</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="有无标记">
              {getFieldDecorator("transferOutSign")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>未标识</Option>
                  <Option value={1}>已标识</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="设备类型">
              {getFieldDecorator("type")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>车辆</Option>
                  <Option value={1}>电池</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(945)}
                title={getJurisdiction(945) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      totalCount,
      curPage,
      pageSize,
      packagesOrderLogList,
      initialValues,
    } = this.state;
    return (
      <div>
        {!!getJurisdiction(945) ? (
          <div className="TransferringOrder">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={packagesOrderLogList}
                scroll={{ x: 2300, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title="调拨单"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleDetail}
              onOk={this.handleDetailOk.bind(this)}
              onCancel={this.initDetailModal.bind(this)}
            >
              <Row>
                <Col span={12}>
                  <p>
                    代理商：
                    <span style={{ color: "#999" }}>{initialValues.fname}</span>
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    调拨单号：
                    <span style={{ color: "#999" }}>
                      {initialValues.transferNumber}
                    </span>
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    调入站点：
                    <span style={{ color: "#999" }}>
                      {initialValues.transferOutSite}
                    </span>
                  </p>
                </Col>
                {this.state.modalType === 1 ? (
                  <Col span={12}>
                    <p>
                      调拨单终止原因：
                      <span style={{ color: "#999" }}>
                        {initialValues.transferText}
                      </span>
                    </p>
                  </Col>
                ) : (
                  <Col span={12}>
                    更改调入站点：
                    <Select
                      style={{ width: "50%" }}
                      placeholder="请选择"
                      onChange={(value) => {
                        this.setState({
                          transferOutSite: value,
                        });
                      }}
                      allowClear
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.state.siteList.length &&
                        this.state.siteList.map((item, index) => {
                          return (
                            <Option key={index} value={item.sid}>
                              {item.sname}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                )}
                <Col
                  span={24}
                  style={{
                    clear: "both",
                    fontSize: "16px",
                    fontWeight: "500",
                    paddingBottom: "10px",
                  }}
                >
                  设备列表
                  <Button
                    onClick={this.handleExportDetailChange.bind(
                      this,
                      this.state.transferNumber
                    )}
                    disabled={!getJurisdiction(950)}
                    title={getJurisdiction(950) ? "" : "暂无操作权限！"}
                    style={{ marginLeft: 10 }}
                    loading={this.state.exportload}
                  >
                    导出
                  </Button>
                </Col>
              </Row>
              <Table
                rowKey="id"
                bordered
                scroll={{ x: 1000, y: 500 }}
                columns={this.detailColumns}
                dataSource={initialValues.detailedVOS}
              />
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    localStorage.getItem("type") === "1"
      ? this.getSiteList(localStorage.getItem("fid"))
      : this.getFranchiseeList();
    this.getPackageList();
    this.getTransferringOrder(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    this.props.form.setFieldsValue({
      sid: undefined,
    });
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取套餐列表
  getPackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    };
    if (localStorage.getItem("type") === "0") {
      _packageBoxList(params)
        .then((res) => {
          if (res.data.code === 200) {
            if (!res.data.data.rows.length) {
              message.warning("暂无数据！");
            }
            this.setState({
              packageList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params["fid"] = localStorage.getItem("fid");
      _packageList(params)
        .then((res) => {
          if (res.data.code === 200) {
            if (!res.data.data.rows.length) {
              message.warning("暂无数据！");
            }
            this.setState({
              packageList: res.data.data.rows,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //获取套餐订单列表
  getTransferringOrder(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _selectTransfer(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            packagesOrderLogList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getTransferringOrder(curPage, pageSize, this.state.queryFormValues);
  }

  //不可选择时间
  disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payTimeEnd = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      this.setState({
        queryFormValues: values,
      });
      this.getTransferringOrder(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payTimeEnd = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      let params = {
        ...values,
      };
      _transferExport(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  // 导出
  handleExportDetailChange(transferNumber) {
    this.setState({
      exportload: true,
    });
    let params = {
      transferNumber,
    };
    _transferDetailedExport(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            exportload: false,
          });
          window.open(`${res.data.data}`, "_blank").focus();
        } else {
          message.warning(res.data.message);
          this.setState({
            exportload: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //终止操作
  handleTerminationChange({transferNumber,type}) {
    let self = this;
    let transferText;
    Modal.confirm({
      title: "终止调拨单",
      content: (
        <div className="diffModalDiv" style={{ marginTop: 30 }}>
          *请填写终止调拨单原因
          <Form.Item label="">
            <Input.TextArea
              onChange={(e) => {
                transferText = e.target.value;
              }}
              allowClear
              placeholder="请输入"
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        if (transferText) {
          let params = {
            transferNumber,
            transferText,
            type,
          };
          _endTransfer(params)
            .then((result) => {
              if (result.data.code === 200) {
                message.success(result.data.message);
                self.getTransferringOrder(
                  self.state.curPage,
                  self.state.pageSize,
                  self.state.queryFormValues
                );
              } else {
                message.warning(result.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
              message.warning("操作失败");
            });
        } else {
          message.warning("请填写终止调拨单原因！");
        }
      },
    });
  }
  //获取车辆详情
  getBikeDetail(modalType,{transferNumber, fid, type}) {
    let params = { transferNumber, curPage: 1, pageSize: 999 };
    _transferDetailed(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            transferNumber,
            modalType,
            initialValues: res.data.data,
          });
          this.detailColumns = type == 1 ? this.batteryColumns : this.bikeColumns
          this.showDetialModal();
          this.getSiteList(fid);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }
  //查看详情（确定）
  handleDetailOk(e) {
    if (this.state.modalType === 2 && this.state.transferOutSite) {
      let params = {
        transferNumber: this.state.transferNumber,
        transferOutSite: this.state.transferOutSite,
      };
      _updateTransferDetailed(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.message);
            this.getTransferringOrder(
              this.state.curPage,
              this.state.pageSize,
              this.state.queryFormValues
            );
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.setState({
      transferNumber: null,
      transferOutSite: null,
      initialValues: {},
      siteList: {},
      visibleDetail: false,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }
}

TransferringOrder = Form.create({})(TransferringOrder);
export { TransferringOrder };
