import React from 'react';
import OrderData from "./orderData"
import OrderBd from "./orderBd"
import OrderPackage from "./orderPackage"

const Index = (props)=> {
    const {value} = props
    return (<>
        <div style={{display: value == 1 ? 'block' : 'none'}}>
            <OrderData />
        </div>
        <div style={{display: value == 2 ? 'block' : 'none'}}>
            <OrderBd />
        </div>
        <div style={{display: value == 3 ? 'block' : 'none'}}>
            <OrderPackage />
        </div>
    </>)
}

export default Index