const { location } = window;
let AXIOS_URL;
let envVersion = 'production'
if (location.origin.indexOf("http://ymzc-web.fintechzh.com") > -1) {
  // 测试环境
  AXIOS_URL = "http://ymzc-api.fintechzh.com";
  envVersion = 'test'
} else if (location.origin.indexOf("https://admin.old.umazc.com") > -1) {
  // 线上测试环境
  AXIOS_URL = "https://api.old.umazc.com";
  envVersion = 'gray'
} else if (location.origin.indexOf("https://admin.umazc.com") > -1) {
  // 生产环境
  AXIOS_URL = "https://api.umazc.com";
  envVersion = 'production'
} else {
  envVersion = 'develop'
  // AXIOS_URL = "http://ymzc-api.fintechzh.com";
  // AXIOS_URL = "https://api.umazc.com";
  AXIOS_URL = "https://api.old.umazc.com";
  // AXIOS_URL = "http://192.168.0.87:20008";
  // AXIOS_URL = "http://192.168.0.21:20008";
}
export { 
  AXIOS_URL,
  envVersion
};
