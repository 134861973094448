import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Select,
  DatePicker,
  Col,
  message,
} from "antd";
import FranchiseeSelect from '@/components/franchiseeSelect'
import { _baoComplainListExport } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const { Option } = Select;
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const [exportload, setExportload] = useState(false);
  const userType = localStorage.getItem("type")
  const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''

  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = form.getFieldsValue();
    const params = getParams(values);
    search(params);
  };

  const handleExportChange = async () => {
    setExportload(true);
    let values = form.getFieldsValue();
    const params = getParams(values);
    const res = await _baoComplainListExport({
      fid: userType !== '0' ? fid : undefined,
      ...params,
    });
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };

  const getParams = (values) => {
    let [complainStartTime, complainEndTime] = values.complainTime || [];
    values.complainStartTime = complainStartTime
      ? moment(complainStartTime).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    values.complainEndTime = complainEndTime
      ? moment(complainEndTime).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    delete values.complainTime;
    let [updateStartTime, updateEndTime] = values.updateTime || [];
    values.updateStartTime = updateStartTime
      ? moment(updateStartTime).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    values.updateEndTime = updateEndTime
      ? moment(updateEndTime).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    delete values.updateTime;
    return values;
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投诉单号">
              {getFieldDecorator("complainEventId")(
                <Input placeholder="请输入" allowClear />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="商家订单号">
              {getFieldDecorator("orderId")(<Input placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投诉单状态">
              {getFieldDecorator("complainStatus")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>商家处理中</Option>
                  <Option value={1}>商家已反馈</Option>
                  <Option value={2}>投诉已完结</Option>
                  <Option value={3}>投诉已撤销</Option>
                  <Option value={4}>平台处理中</Option>
                  <Option value={5}>平台处理完结</Option>
                  <Option value={6}>系统关闭</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投诉单创建">
              {getFieldDecorator("complainTime")(
                <DatePicker.RangePicker format="YYYY-MM-DD HH:mm:ss" showTime={{ format: 'HH:mm:ss' }} style={{width: '100%'}}/>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="修改时间">
              {getFieldDecorator("updateTime")(
                <DatePicker.RangePicker format="YYYY-MM-DD HH:mm:ss" showTime={{ format: 'HH:mm:ss' }} style={{width: '100%'}}/>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投诉人手机号">
              {getFieldDecorator("phone")(<Input placeholder="请输入" />)}
            </Form.Item>
          </Col>
          {
            userType === '0' && 
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <FranchiseeSelect />
                )}
              </Form.Item>
            </Col>
          }
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1085)}
                title={getJurisdiction(1085) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
