import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _packageTransferList,
  _packageOrderUpdateTransferStatus,
} from "@/statics/js/api.js";
import { Modal, message, Button, Table, Radio } from "antd";

var approvalModal = forwardRef((props, ref) => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "代理商",
      dataIndex: "fname",
    },
    {
      title: "审核原因",
      dataIndex: "checkReason",
    },
    {
      title: "套餐名",
      dataIndex: "pname",
    },
    {
      title: "支付流水号",
      dataIndex: "payTradeNo",
    },
    {
      title: "套餐金额",
      align: "right",
      width: 90,
      dataIndex: "pamount",
      render: (text) => text && text.toFixed(2),
    },
    {
      title: "订单金额",
      align: "right",
      width: 90,
      dataIndex: "orderAmount",
      render: (text) => text && text.toFixed(2),
    },
    {
      title: "支付金额",
      align: "right",
      width: 90,
      dataIndex: "payAmount",
      render: (text) => text && text.toFixed(2),
    },
    {
      title: "待转账金额",
      align: "right",
      width: 105,
      dataIndex: "transferAmount",
      render: (text) => text && text.toFixed(2),
    },
    {
      title: "备注",
      dataIndex: "remark",
    },

    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <Button
          type="link"
          disabled={!getJurisdiction(1079)}
          title={getJurisdiction(1079) ? "" : "暂无操作权限！"}
          onClick={() => handleDelete(text.id)}
        >
          移除
        </Button>
      ),
    },
  ];
  const { done } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [ids, setIds] = useState([]);
  const [status, setStatus] = useState(2);

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const open = async ({ ids }) => {
    setVisible(true);
    setIds(ids);
    const res = await _packageTransferList({ ids });
    setList(res.data.data.rows);
  };
  const handleDelete = (id) => {
    setIds((value) => value.filter((item) => item !== id));
    setList((value) => value.filter((item) => item.id !== id));
  };

  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    const params = {
      ids,
      status,
    };
    handleCheck(params);
  };

  //审核
  const handleCheck = async (params) => {
    setConfirmLoading(true);
    const res = await _packageOrderUpdateTransferStatus(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    } else {
      message.success(msg);
    }
    done();
    close();
  };

  return (
    <div>
      <Modal
        title="批量审核"
        visible={visible}
        width={1000}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <h3>已选中 {list.length}</h3>
        <Radio.Group
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <Radio value={2}>通过</Radio>
          <Radio value={3}>拒绝</Radio>
        </Radio.Group>
        <br /> <br />
        <Table
          rowKey="id"
          bordered
          columns={columns}
          scroll={{ x: 1000, y: 500 }}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    </div>
  );
});

export default approvalModal;
