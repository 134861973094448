import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import BdSelect from '@/components/bdSelect'
import moment from 'moment';
import {
    _operateBdOrderList,
    _operateBdOrderExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Input,
    Row,
    Col,
    DatePicker
} from "antd";
const {WeekPicker} = DatePicker

const columns = [
    {
        title: "BD",
        dataIndex: "bdName",
    },
    {
        title: "累计在租",
        dataIndex: "totalRentNum",
    },
    {
        title: "累计退租",
        dataIndex: "totalOutNum",
    },
    {
        title: "测试订单",
        dataIndex: "testOrderNum",
    },
    {
        title: "正常订单",
        dataIndex: "orderNum",
    },
    {
        title: "车电订单",
        dataIndex: "bikeAndBatteryOrder",
    },
]

const Index = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue} = form
    const tableRef = useRef();
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        handleSearch()
    },[])
    
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week} = values
            let time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week} = values
        let time = {
            startTime: moment(week).day(1).format('YYYY-MM-DD'),
            endTime: moment(week).day(7).format('YYYY-MM-DD'),
        }
        handleDownload({
            action: _operateBdOrderExport,
            query: {
                ...values,
                ...time
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="BD名称/手机号">
                        {getFieldDecorator("namePhone")(
                            <BdSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间范围">
                        {getFieldDecorator("week",{
                            initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                        })(
                            <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为周维度</p>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateBdOrderList}
            options={{
                columns: columns
            }}
        />
    </>)
})

export default Index