import React, { Component } from "react";
import { Input, Button, message, Form, Icon } from "antd";
// import {browserHistory} from '@/statics/js/browserHistory';
import { _login, _accountDetail } from "@/statics/js/api";
import md5 from "md5";
import "./style.less";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator("phone", {
            rules: [{ required: true, message: "请输入您正确的账号！" }],
          })(
            <Input
              className="login-input"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="账号"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "请输入您正确的密码！" }],
          })(
            <Input
              className="login-input"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="密码"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-loginbtn">
            登录
          </Button>
        </Form.Item>
      </Form>
    );
  }

  render() {
    return (
      <div className="Login">
        <div className="login-container">
          <h1 className="login-title">后台登录</h1>
          {this.renderForm()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEnterKey);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEnterKey);
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      values.password = md5(values.password);
      let params = {
        ...values,
      };
      _login(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功！");
            let loginInfo = res.data.data;
            localStorage.setItem("authorization", loginInfo.authorization);
            localStorage.setItem("refreshToken", loginInfo.refreshToken);
            localStorage.setItem("userId", loginInfo.userId);
            localStorage.setItem("menuIds", loginInfo.menuIds);
            localStorage.setItem("showIndex", "0");
            this.getAccountDetail(loginInfo.userId);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // 获取账户详情
  getAccountDetail(id) {
    let params = { id };
    _accountDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let userInfo = res.data.data;
          localStorage.setItem("authGroupName", userInfo.authGroupName);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          localStorage.setItem("sys", userInfo.sys);
          localStorage.setItem("fid", userInfo.fid);
          localStorage.setItem("type", userInfo.type);
          localStorage.setItem("username", userInfo.username);
          const { history: browserHistory } = this.props;
          if (localStorage.getItem("userId") === "1722") {
            setTimeout(function () {
              browserHistory.push({
                pathname: "/jzBatteryMgt",
              });
            }, 1000);
          } else {
            setTimeout(function () {
              browserHistory.push({
                pathname: "/",
              });
            }, 1000);
          }
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //回车登录
  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit.bind(this);
    }
  };
}
Login = Form.create({})(Login);
export default Login;
