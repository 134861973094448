import React,{
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';
var myChart = null
const Index = forwardRef((props,ref) => {

    useEffect(()=>{
      myChart = echarts.init(document.getElementById('line_card'));
    },[])

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);

    var init = ({TimeXScale,voltage,current,remain,config})=>{
        var colors = ['#ff2000','#3398DB','#00cdff','#ff0066','#ff8000','#37ff00',,'#884400',,'#888800',,'#88AA00',,'#88FF00']
        var options = {
          color: [colors[2],colors[3],colors[5],colors[4]],
          backgroundColor: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#000022' // 0% 处的颜色
            }, {
                offset: 1, color: '#000066' // 100% 处的颜色
            }],
            globalCoord: false // 缺省为 false
          },
          tooltip : {
            trigger: 'axis',
            formatter:function(params){
              var res ='';
              for (let i = 0; i <params.length ; i++){
                if(params[i].seriesName=="电压"){
                  res+=params[i].value[0]+"  电压："+(params[i].value[1])+"  (V)"+'<br/>';
                }else if(params[i].seriesName=="电流"){
                  res+=params[i].value[0]+"  电流："+(params[i].value[1])+"  (A)"+'<br/>';
                }else if(params[i].seriesName=="剩余电量"){
                  res+=params[i].value[0]+"  电量："+params[i].value[1]+"  (%)"+'<br/>';
                }else if(params[i].seriesName=="运动识别"){
                  res='';
                }
              }
              return res;
            }
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              dataView: {readOnly: false},
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          legend: {
            data:[
              '电压',
              '电流',
              '剩余电量',
              // '运动识别'
            ],
            textStyle:{
              color:'white'
            }
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '12%',
            containLabel: false
          },
          dataZoom:[{
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: 0,
            backgroundColor:"rgba(47,69,84,0.1)",
            fillerColor:"rgba(151, 153, 255,0.3)",
          }],
          xAxis : [
            {
              type : 'time',
              minInterval: 30 * 60 * 1000,
              data : TimeXScale,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel:{
                rotate:0,
                width:60,
              },
              splitLine:false,
              axisLine: {                 //坐标 轴线
                show:true,             //是否显示坐标轴轴线
                onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                lineStyle: {
                  color:'white',               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
                  //shadowColor:"red",          //阴影颜色
                  shadowOffsetX:0,            //阴影水平方向上的偏移距离。
                  shadowOffsetY:0,            //阴影垂直方向上的偏移距离
                  //shadowBlur:10,              //图形阴影的模糊大小。
                  type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
                  width:1,                    //坐标轴线线宽
                  opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
                }
              },
            }
          ],
          yAxis : [
            {
              type : 'value',
              position:'left',
              name:'电压(V)',
              min:0,
              max: config.vMax,    //均值刻度取最大值
              nameTextStyle: {
                color: colors[2],                //文字颜色
                fontSize:15,                 //字体大小
              },
              splitLine:false,
              axisLine: {                 //坐标 轴线
                show:true,             //是否显示坐标轴轴线
                onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                lineStyle: {
                  color:colors[2],               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
                  //shadowColor:"red",          //阴影颜色
                  shadowOffsetX:0,            //阴影水平方向上的偏移距离。
                  shadowOffsetY:0,            //阴影垂直方向上的偏移距离
                  //shadowBlur:10,              //图形阴影的模糊大小。
                  type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
                  width:1,                    //坐标轴线线宽
                  opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
                }
              },
              axisTick :{                 //坐标轴刻度相关设置
                show:true,              //是否显示坐标轴刻度。
                alignWithLabel:false,  //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
                interval:0,          //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
                inside:true,           //坐标轴刻度是否朝内，默认朝外。
                length:5
              }
            },
            {
              type : 'value',
              position:'left',
              offset:-40,
              name:'电流(A)',
              min: config.cMin,
              max: config.cMax,
              nameTextStyle: {
                color: colors[3],                //文字颜色
                fontStyle:"normal",         //italic斜体  oblique倾斜
                fontWeight:"normal",        //文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
                fontFamily:"sans-serif",    //字体系列
                fontSize:15,                 //字体大小
              },
              splitLine:false,
              axisLine: {                 //坐标 轴线
                show:true,             //是否显示坐标轴轴线
                onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                lineStyle: {
                  color:colors[3],               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
                  //shadowColor:"red",          //阴影颜色
                  shadowOffsetX:0,            //阴影水平方向上的偏移距离。
                  shadowOffsetY:0,            //阴影垂直方向上的偏移距离
                  //shadowBlur:10,              //图形阴影的模糊大小。
                  type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
                  width:1,                    //坐标轴线线宽
                  opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
                }
              },
              axisTick :{                 //坐标轴刻度相关设置
                show:true,              //是否显示坐标轴刻度。
                alignWithLabel:false,  //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
                interval:0,          //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
                inside:true,           //坐标轴刻度是否朝内，默认朝外。
                length:5
              }
            },
            {
              type : 'value',
              position:'right',
              name:'电量(%)',
              min: 0,
              max: 200,
              offset:0,
              nameTextStyle: {
                color: colors[5],                //文字颜色
                fontStyle:"normal",         //italic斜体  oblique倾斜
                fontWeight:"normal",        //文字粗细bold   bolder   lighter  100 | 200 | 300 | 400...
                fontFamily:"sans-serif",    //字体系列
                fontSize:15,                 //字体大小
              },
              splitLine:false,
              axisLine: {                 //坐标 轴线
                show:true,             //是否显示坐标轴轴线
                onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
                lineStyle: {
                  color:colors[5],               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
                  //shadowColor:"red",          //阴影颜色
                  shadowOffsetX:0,            //阴影水平方向上的偏移距离。
                  shadowOffsetY:0,            //阴影垂直方向上的偏移距离
                  //shadowBlur:10,              //图形阴影的模糊大小。
                  type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
                  width:1,                    //坐标轴线线宽
                  opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
                }
              },
              axisTick :{                 //坐标轴刻度相关设置
                show:true,              //是否显示坐标轴刻度。
                alignWithLabel:false,  //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
                interval:0,          //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
                inside:true,           //坐标轴刻度是否朝内，默认朝外。
                length:5
              },
              axisLabel:{
                formatter: function (value, index) {
                  if (value>100) return "";
                  else return value+"%";
                }
              }
            },
            // {
            //   type : 'value',
            //   position:'left',
            //   show:false,
            //   name:'里程(KM)',
            //   min:0,
            //   max: 0,    //均值刻度取最大值
            //   offset:-200,
            //   nameTextStyle: {
            //     fontSize:13,                 //字体大小
            //   },
            //   splitLine:false,
            //   axisLine: {                 //坐标 轴线
            //     show:true,             //是否显示坐标轴轴线
            //     onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
            //     lineStyle: {
            //       color:colors[4],               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
            //       //shadowColor:"red",          //阴影颜色
            //       shadowOffsetX:0,            //阴影水平方向上的偏移距离。
            //       shadowOffsetY:0,            //阴影垂直方向上的偏移距离
            //       //shadowBlur:10,              //图形阴影的模糊大小。
            //       type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
            //       width:1,                    //坐标轴线线宽
            //       opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
            //     }
            //   },
            //   axisTick :{                 //坐标轴刻度相关设置
            //     show:true,              //是否显示坐标轴刻度。
            //     alignWithLabel:false,  //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
            //     interval:0,          //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
            //     inside:true,           //坐标轴刻度是否朝内，默认朝外。
            //     length:5
            //   }
            // },
            // {
            //   type : 'value',
            //   position:'left',
            //   name:'',
            //   min:0,
            //   max: 10,    //均值刻度取最大值
            //   offset:-220,
            //   nameTextStyle: {
            //     fontSize:13,                 //字体大小
            //   },
            //   splitLine:false,
            //   axisLine: {                 //坐标 轴线
            //     show:true,             //是否显示坐标轴轴线
            //     onZero:true,           //X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效
            //     lineStyle: {
            //       color:"#000000",               //颜色，'rgb(128, 128, 128)'，'rgba(128, 128, 128, 0.5)'，支持线性渐变，径向渐变，纹理填充
            //       //shadowColor:"red",          //阴影颜色
            //       shadowOffsetX:0,            //阴影水平方向上的偏移距离。
            //       shadowOffsetY:0,            //阴影垂直方向上的偏移距离
            //       //shadowBlur:10,              //图形阴影的模糊大小。
            //       type:"solid",               //坐标轴线线的类型，solid，dashed，dotted
            //       width:1,                    //坐标轴线线宽
            //       opacity:1,                  //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
            //     }
            //   },
            //   axisTick :{                 //坐标轴刻度相关设置
            //     show:true,              //是否显示坐标轴刻度。
            //     alignWithLabel:false,  //类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐
            //     interval:0,          //坐标轴刻度的显示间隔，在类目轴中有效。默认会采用标签不重叠的策略间隔显示标签。可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
            //     inside:true,           //坐标轴刻度是否朝内，默认朝外。
            //     length:5
            //   }
            // }
          ],
          series : [
            {
              name:'电压',
              type:'line',
              showSymbol:true,
              symbolSize:3,
              symbol:'emptyCircle',
              showAllSymbol:true,
              data: voltage,
              yAxisIndex:0,
              tooltip:{
                formatter: '{b0}: {c0} V '
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                        color: 'black'
                    },
                  }
                }
              }
            },
            {
              name:'电流',
              type:'line',
              step:'end',// 显示为阶梯图
              showSymbol:true,
              symbolSize:3,
              symbol:'circle',
              showAllSymbol:true,
              data: current,
              yAxisIndex:1,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgb(187, 0, 0,0.4)'
                }, {
                  offset: 1,
                  color: 'rgb(0, 235, 180,0.8)'
                }])
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: 'black'
                    }
                  },
                  lineStyle:{
                    color:colors[3]
                  }
                }
              }
            },
            {
              name:'剩余电量',
              type:'line',
              showSymbol:true,
              symbolSize:3,
              symbol:'circle',
              showAllSymbol:true,
              data: remain,
              yAxisIndex:2,
              itemStyle: {
                color:colors[5],
                normal: {
                  label: {
                    show: false, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: 'black'
                    }
                  },
                  lineStyle:{
                    color:colors[5]
                  },
                }
              }
            },
            // {
            //   name:'里程参考',
            //   type:'line',
            //   showSymbol:true,
            //   show:false,
            //   symbolSize:2,
            //   symbol:'circle',
            //   showAllSymbol:true,
            //   data: [],
            //   yAxisIndex:3,
            //   itemStyle: {
            //     color:colors[4],
            //     normal: {
            //       label: {
            //         show: false, //开启显示
            //         position: 'top', //在上方显示
            //         textStyle: { //数值样式
            //           color: 'black'
            //         }
            //       },
            //       lineStyle:{
            //         color:colors[4]
            //       }
            //     }
            //   }
            // },
            // {
            //   name:'运动识别',
            //   type:'bar',
            //   showSymbol:true,
            //   barWidth:5,
            //   barMinWidth:5,
            //   barMaxWidth:5,
            //   symbolSize:2,
            //   symbol:'circle',
            //   showAllSymbol:true,
            //   data: [],
            //   yAxisIndex:4,
            //   itemStyle: {
            //     color:"#cc00cc",
            //     normal: {
            //       label: {
            //         show: false, //开启显示
            //         position: 'top', //在上方显示
            //         textStyle: { //数值样式
            //           color: 'black'
            //         }
            //       },
            //       lineStyle:{
            //         color:"#cc00cc"
            //       },
            //       color:"#cc00cc"
            //     }
            //   }
            // },
          ]
        }
        myChart.setOption(options)
    }

    return (
        <>
            <div id="line_card" style={{height: '650px',width: '100%'}}></div>
        </>
    )
})

export default Index