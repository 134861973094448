import "babel-polyfill";
import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
// import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory as createHistory } from "history"; //解决Jenkins打包报错
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";
import "./App.css";
// -----------公共部分------------
import BankPage from "./views/bankPage"; //银行跳转页
import { View as Common } from "./views/common"; //整站公共部分（头部,侧边栏）
import { View as Index } from "./views/index"; //首页
import { View as Login } from "./views/login"; //登录
import { View as Terms } from "./views/terms"; //声明条款
import { View as AppDownload } from "./views/appDownload"; //下载
import { View as ApplyView } from "./views/applyView"; //h5代付页面
import Upload from "./views/upload";
import BatteryBoard from "@/views/equipmentMGT/batteryBoard"; // 电池大屏
//-----------数据大屏------------
import Company from "./views/board/company";
import Pay from "./views/pay";
import Website from "./views/website";
import routeConfig from "@/router";

class App extends Component {
  getRouteList(routes) {
    let list = [];
    function processRoutes(routes) {
      routes.forEach((route) => {
        if (route.path && route.path !== "/") {
          list.push(route);
        }
        if (route?.childRoutes && route.childRoutes.length > 0) {
          processRoutes(route.childRoutes);
        }
      });
    }
    processRoutes(routes);
    return list;
  }

  render() {
    const browserHistory = createHistory(this.props);
    let routeList = this.getRouteList(
      routeConfig.filter((route) => route?.path === "/")
    );
    window._history = browserHistory; // 声明全局变量 >> 用于外部方法调用
    return (
      <ConfigProvider locale={zh_CN}>
        <div className="App">
          <Router history={browserHistory}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/batteryBoard" component={BatteryBoard} />
              <Route path="/company" component={Company} />
              <Route path="/uploadBattery" component={Upload} />
              <Route path="/bankPage" component={BankPage} />
              <Route path="/terms" component={Terms} />
              <Route path="/appDownload" component={AppDownload} />
              <Route path="/applyView" component={ApplyView} />
              {/* <Route path="/pay" component={Pay} /> */}
              <Route path="/website" component={Website} />
              <Route
                component={(props) => {
                  return (
                    <Common {...props}>
                      <CacheSwitch>
                        <Route exact path="/" component={Index} />
                        {routeList.map(({ path, component }) => {
                          return (
                            <CacheRoute
                              path={path}
                              component={component}
                              key={path}
                              cacheKey={path}
                            />
                          );
                        })}
                      </CacheSwitch>
                    </Common>
                  );
                }}
              />
            </Switch>
          </Router>
        </div>
      </ConfigProvider>
    );
  }
}

export default App;
