import React, { useState } from "react";
import { Form, Button, Input, Row, Col, message } from "antd";
import { _extractApplyExport } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const [exportload, setExportload] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      search(values);
    });
  };
  const handleExportChange = async () => {
    setExportload(true);
    let params = form.getFieldsValue();
    const res = await _extractApplyExport(params);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };
  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户名/手机号">
              {getFieldDecorator("userNameMobile")(
                <Input placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                loading={exportload}
                onClick={handleExportChange}
                disabled={!getJurisdiction(1025)}
                title={getJurisdiction(1025) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
