import React,{
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';

const Index = forwardRef((props,ref) => {
    const myChart = useRef()
    useEffect(()=>{
        myChart.current = echarts.init(document.getElementById('line'))
    },[])
    const init = ({xdata=[],ydata=[]})=>{
        let option = {
            grid: {
                left: '12%',
                right: '6%',
                top: '14%',
                bottom: '8%'
            },
            xAxis: {
                type: 'category',
                data: xdata,
                axisLabel: {
                    color: '#b9b7cd'
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#b9b7cd'
                },
                splitLine: {
                    lineStyle: {
                        color: '#484753',
                    }
                },
                name: '万',
                nameTextStyle: {
                    color: '#fff'
                },
            },
            series: [
                {
                    data: ydata,
                    type: 'line',
                    barWidth: 20,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: '#4992ff',
                    },
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#4992ff'
                            },
                            {
                                offset: 1,
                                color: 'rgba(0,0,0,0)'
                            }
                        ])
                    }
                }
            ]
        }
        myChart.current.setOption(option);
    }
    useImperativeHandle(ref, () => ({
        init,
    }),[]);
    return (
        <div id="line" style={{width: '100%',height: '100%'}}></div>
    )
})

export default Index
