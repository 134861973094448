import React, { useRef } from "react";
import { Button, Switch, Divider, message } from "antd";
import SearchBox from "./components/searchBox";
import RuleModal from "./components/ruleModal";
import CommonTable from "@/components/commonTable";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _tjConfigList,
  _updateTjConfigStatus,
} from "@/statics/js/api.js";
import moment from "moment";
const ReferrerRule = () => {
  const columns = [
    {
      title: "代理商",
      dataIndex: "fname",
    },
    {
      title: "站点",
      dataIndex: "sname",
    },
    {
      title: "推荐人姓名/手机号",
      dataIndex: "",
      render: (text) => text.realName + "/" + text.mobile,
    },
    {
      title: "分润类型",
      dataIndex: "typeName",
    },
    {
      title: "分润数值",
      dataIndex: "contentStr",
    },
    {
      title: "配置时间",
      width: 120,
      dataIndex: "addTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "管理状态",
      width: 100,
      dataIndex: "",
      render: (text) => (
        <Switch
          defaultChecked={!!text.status}
          disabled={!getJurisdiction(1093)}
          title={getJurisdiction(1093) ? "" : "暂无操作权限！"}
          checkedChildren="启用"
          unCheckedChildren="禁用"
          onChange={(checked) => handleStateChange(text.id, checked)}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <span>
          <Button
            disabled={!getJurisdiction(1091)}
            title={getJurisdiction(1091) ? "" : "暂无操作权限！"}
            type="link"
            onClick={() => handleDetail(text.id)}
          >
            查看
          </Button>
          <Divider type="vertical" />
          <Button
            disabled={!getJurisdiction(1089)}
            title={getJurisdiction(1089) ? "" : "暂无操作权限！"}
            type="link"
            onClick={() => handleEdit(text.id)}
          >
            编辑
          </Button>
        </span>
      ),
    },
  ];
  const payRef = useRef();
  const tableRef = useRef();
  // 状态更新
  const handleStateChange = async (id, checked) => {
    let params = {
      id,
      status: Number(checked),
    };
    const res = await _updateTjConfigStatus(params);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    handleRefresh();
  };
  // 新建
  const handleCreate = async () => {
    console.log(payRef.current);
    payRef.current.open({ type: 0 });
  };
  // 编辑
  const handleEdit = async (id) => {
    payRef.current.open({ type: 1, id });
  };
  // 详情
  const handleDetail = async (id) => {
    payRef.current.open({ type: 2, id });
  };
  //搜索
  const handleSearch = async (values) => {
    tableRef.current.handleSearch(values);
  };
  //刷新表格
  const handleRefresh = async () => {
    tableRef.current.handleRefresh();
  };
  return !getJurisdiction(1088) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <div className="top-btn">
        <Button
          type="primary"
          disabled={!getJurisdiction(1090)}
          title={getJurisdiction(1090) ? "" : "暂无操作权限！"}
          onClick={handleCreate}
        >
          新增
        </Button>
      </div>
      <CommonTable
        ref={tableRef}
        action={_tjConfigList}
        options={{
          columns: columns,
          scroll: { x: 1300, y: 500 },
        }}
      />
      <RuleModal
        wrappedComponentRef={(ref) => (payRef.current = ref)}
        done={handleRefresh}
      />
    </div>
  );
};

export default ReferrerRule;
