import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Select,
  Col,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { _exportGpsList } from "@/statics/js/api.js";
import TransferSelect from "@/components/transferSelect";
import useList from "@/hooks/list";
import { getJurisdiction } from "@/statics/js/jurisdiction";
const { Option } = Select;

var Index = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const { getGpsTypeList, gpsTypeList } = useList();
  const { franchiseeList, getFranchiseeList } = useList();
  useEffect(() => {
    getGpsTypeList();
    getFranchiseeList({ hideForbidden: false });
  }, []);
  const [exportload, setExportload] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      search(values);
    });
  };
  const handleExportChange = async () => {
    setExportload(true);
    let values = form.getFieldsValue();
    let [addTimeStart, addTimeEnd] = values.addTime || [];
    let [startExpirationTime, endExpirationTime] = values.expirationTime || [];
    values.addTimeStart = addTimeStart
      ? moment(addTimeStart).format("YYYY-MM-DD") + " 00:00:00"
      : undefined;
    values.addTimeEnd = addTimeEnd
      ? moment(addTimeEnd).format("YYYY-MM-DD") + " 23:59:59"
      : undefined;
    delete values.addTime;
    values.startExpirationTime = startExpirationTime
      ? moment(startExpirationTime).format("YYYY-MM-DD") + " 00:00:00"
      : undefined;
    values.endExpirationTime = endExpirationTime
      ? moment(endExpirationTime).format("YYYY-MM-DD") + " 23:59:59"
      : undefined;
    delete values.addTime;
    delete values.expirationTime;
    const params = {
      ...values,
    };
    const res = await _exportGpsList(params);
    setExportload(false);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="GPS编号">
              {getFieldDecorator("codeId")(
                <Input placeholder="请输入" allowClear />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="定位系统">
              {getFieldDecorator("type")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {gpsTypeList.length &&
                    gpsTypeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.gpsType}>
                          {item.gpsName}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("agentIdList")(
                  <TransferSelect
                    dataSource={franchiseeList.map((item) => ({
                      key: item.fid,
                      title: item.fname,
                    }))}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否绑定设备">
              {getFieldDecorator("exist")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={2}>否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="加入白名单时间">
              {getFieldDecorator("addTime")(
                <DatePicker.RangePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="到期时间">
              {getFieldDecorator("expirationTime")(
                <DatePicker.RangePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="SIM状态">
              {getFieldDecorator("simStatus")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  <Option value={0}>未激活</Option>
                  <Option value={1}>已激活</Option>
                  <Option value={2}>停机</Option>
                  <Option value={4}>预销户</Option>
                  <Option value={5}>已销户</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否到期">
              {getFieldDecorator("expire")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={2}>否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1052)}
                title={getJurisdiction(1052) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

Index = Form.create({})(Index);
export default Index;
