import React, { 
    useEffect,
    useState,
    useRef,
} from 'react';
import moment from "moment";
import {
    _getGpsHis,
} from "../../../statics/js/api.js"
import MapCard from './components/MapCard'
import JourneySheet from './components/JourneySheet'
import TrackSheet from './components/TrackSheet'
import AlarmSheet from './components/AlarmSheet'
import StopSheet from './components/StopSheet'
import {
    Form,
    Button,
    DatePicker,
    message,
    Card,
    Slider,
    Divider,
    Tabs,
    Row,
    Col,
} from "antd";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

var Index = (props)=> {
    const {form,imei} = props
    const { getFieldDecorator } = form;
    const mapRef = useRef();
    const [filter,setFilter] = useState({
        startTime: moment(moment().format('YYYY-MM-DD') + ' 00:00:00', "YYYY-MM-DD HH:mm:ss"),
        endTime: moment(moment().format('YYYY-MM-DD') + ' 23:59:59', "YYYY-MM-DD HH:mm:ss")
    })
    const [totalDistance,setTotalDistance] = useState(0)
    const [speed,setSpeed] = useState(500)
    const [show,setShow] = useState(false)
    useEffect(() => {
        initData()
    },[]);
    // 开始巡航
    const handleStart = () => {
        mapRef.current.setSpeed(speed)
        mapRef.current.start()
    }
    // 暂停巡航
    const handlePause = () => {
        mapRef.current.pause()
    }
    // 恢复巡航
    const handleResume = () => {
        mapRef.current.resume()
    }
    // 清除巡航线路
    const handleClear = () => {
        mapRef.current.clear()
    }
    // 搜索
    const handleSearch = async (e)=>{
        e.preventDefault();
        form.validateFields((err, values) => {
            if(err){
                message.warning('请选择日期范围');
                return
            }
            initData()
        })
    }
    const initData = async () => {
        const values = form.getFieldsValue()
        let [startTime,endTime] = values.date
        setFilter({
            ...filter,
            startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        })
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 100);
        await loadData({
            startTime,
            endTime,
        })
    }
    // 加载数据
    const loadData = async (query={}) => {
        const {
            startTime = '',
            endTime = '',
        } = query
        const params = {
            imei,
            startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        }
        const res = await _getGpsHis(params)
        const {data,code,message:msg} = res.data
        if(code !== 200){
            message.warning(msg)
            return
        }
        if(!data){
            message.warning('暂无数据')
            return
        }
        const { gpsHisConnects} = data || {}
        const list = []
        gpsHisConnects.map(item=>{
            let {latitude,longitude} = item
            list.push([parseFloat(longitude),parseFloat(latitude)])
        })
        mapRef.current.load(list,gpsHisConnects)
    }

    const tipFormatter = (value)=>{
        return value + 'KM/H'
    }
    const handleSpeedChange = (value)=>{
        setSpeed(value)
        mapRef.current.setSpeed(value)
    }
    return (<div className="card_wrap">
        <div className="layout_wrap">
            <div className="content_wrap">
                <Card bodyStyle={{padding: '10px'}}>
                    <Form onSubmit={handleSearch}>
                        <Row gutter={16}>
                            <Col lg={7} md={12} sm={24}>
                                <Form.Item>
                                    {
                                        getFieldDecorator("date",{
                                            initialValue: [filter.startTime,filter.endTime],
                                            rules: [{ required: true, message: "请选择日期范围" }],
                                            valuePropName: "defaultValue",
                                        })(
                                            <RangePicker
                                                showTime={{ format: 'HH:mm:ss' }} 
                                                format="YYYY-MM-DD HH:mm:ss"
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                            <Col lg={5} md={12} sm={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">搜索</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className="track_bar">
                        <Button icon="caret-right" onClick={handleStart}>播放</Button>
                        <Button icon="border" onClick={handlePause}>暂停</Button>
                        <Button onClick={handleResume}>恢复</Button>
                        <Button onClick={handleClear}>清除</Button>
                        <Divider type="vertical" />
                        <div className="slider_box" id="slider_box">
                            <Slider 
                                min={0}
                                max={10000}
                                defaultValue={speed} 
                                onChange={handleSpeedChange}
                                tooltipVisible 
                                tipFormatter={tipFormatter} 
                                getTooltipPopupContainer={()=>document.getElementById('slider_box')}
                            />
                        </div>
                        <Divider type="vertical" />
                        <div className="track_tip">
                            <div>从{moment(filter.startTime).format('YYYY-MM-DD HH:mm:ss')} 到 {moment(filter.endTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                    <div className="track_map">
                        <MapCard ref={mapRef} />
                    </div>
                    <Divider />
                    {
                        show ? 
                        <div>
                            <Tabs defaultActiveKey="1" type="card">
                                <TabPane tab="行程信息" key="1">
                                    <JourneySheet filter={{imei,...filter}} />
                                </TabPane>
                                <TabPane tab="轨迹信息" key="2">
                                    <TrackSheet filter={{imei,...filter}} />
                                </TabPane>
                                <TabPane tab="告警信息" key="3">
                                    <AlarmSheet filter={{imei,...filter}} />
                                </TabPane>
                                <TabPane tab="停留记录" key="4">
                                    <StopSheet filter={{imei,...filter}} />
                                </TabPane>
                            </Tabs>
                        </div>
                        : null
                    }
                </Card>
            </div>
        </div>
    </div>)
}

Index = Form.create({})(Index);
export default Index