import React,{
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { 
    
} from 'antd';
import * as echarts from 'echarts';

const Index = forwardRef((props,ref) => {
    const myChart = useRef()
    useEffect(()=>{
        myChart.current = echarts.init(document.getElementById('bar'))
    },[])
    const init = ({xdata=[],ydata=[]})=>{
        let option = {
            grid: {
                left: '12%',
                right: '6%',
                top: '8%',
                bottom: '8%'
            },
            xAxis: {
                type: 'category',
                data: xdata,
                axisLabel: {
                    color: '#b9b7cd'
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#b9b7cd'
                },
                splitLine: {
                    lineStyle: {
                        color: '#484753',
                    }
                },

            },
            series: [
                {
                    data: ydata,
                    type: 'bar',
                    barWidth: 15,
                    label: {
                        show: true,
                        position: 'top',
                        color: '#fff'
                    },
                    itemStyle: {
                        color: '#4992ff',
                    },
                }
            ]
        }
        myChart.current.setOption(option);
    }
    useImperativeHandle(ref, () => ({
        init,
    }),[]);
    return (
        <div id="bar" style={{width: '100%',height: '100%'}}></div>
    )
})

export default Index
