import React from 'react';
import TimeoutData from "./timeoutData"
import TimeoutDeal from "./timeoutDeal"

const Index = (props)=> {
    const {value} = props
    return (<>
        <div style={{display: value == 1 ? 'block' : 'none'}}>
            <TimeoutData />
        </div>
        <div style={{display: value == 2 ? 'block' : 'none'}}>
            <TimeoutDeal />
        </div>
    </>)
}

export default Index