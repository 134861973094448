import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import FranchiseeSelect from '@/components/franchiseeSelect'
import SiteSelect from '@/components/siteSelect'
import moment from 'moment';
import {
    _operateSiteBikeList,
    _operateSiteBikeExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Row,
    Col,
    Tooltip,
    Icon,
    DatePicker
} from "antd";
const {WeekPicker} = DatePicker

const columns = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "站点",
        dataIndex: "sname",
    },
    {
        title: "设备类型",
        dataIndex: "self",
        render: (text) => (text === 1 ? "自营" : "非自营"),
    },
    {
        title: ()=>(
            <>
                <span>本周订单</span>&nbsp;
                <Tooltip placement="top" title="发车（绑定）时间在本周">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "weekOrderSize",
    },
    {
        title: ()=>(
            <>
                <span>累积订单数</span>&nbsp;
                <Tooltip placement="top" title="绑有订单的车辆数">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "accumulateOrderSize",
    },
    {
        title: "累积订单数环比",
        dataIndex: "accumulateOrderSizeScale",
    },
    {
        title: ()=>(
            <>
                <span>投放车辆数</span>&nbsp;
                <Tooltip placement="top" title="车辆状态=启用+待确认+维修中">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "pushSize",
    },
    {
        title: "站点投放车辆占比",
        dataIndex: "pushSizeProportion",
    },
    {
        title: ()=>(
            <>
                <span>闲置车辆</span>&nbsp;
                <Tooltip placement="top" title="未绑定订单">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "idleBikeSize",
    },
    {
        title: "车辆出租率",
        dataIndex: "bikeRentProportion",
    },
    {
        title: "车辆出租率环比",
        dataIndex: "bikeRentScale",
    },
]

const Index = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,setFieldsValue,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week} = values
            let time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week} = values
        let time = {
            startTime: moment(week).day(1).format('YYYY-MM-DD'),
            endTime: moment(week).day(7).format('YYYY-MM-DD'),
        }
        handleDownload({
            action: _operateSiteBikeExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={7} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect onChange={()=>{setFieldsValue({ sid: "" })}} />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={7} md={12} sm={24}>
                    <Form.Item label="站点">
                        {getFieldDecorator("sid")(
                            <SiteSelect fid={getFieldValue('fid')} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24}>
                    <Form.Item label="时间范围">
                        {getFieldDecorator("week",{
                            initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                        })(
                            <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                        )}
                    </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为周维度</p>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateSiteBikeList}
            options={{
                columns: columns
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

export default Index