import React from "react";
import { Table, Button } from "antd";
import SearchBox from "./components/searchBox";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _batteryAlarmList } from "@/statics/js/api.js";
import moment from "moment";

const Index = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "电池信息",
      dataIndex: "",
      render: (text) =>
        text && (
          <span>
            {text.batteryId && "电池编号：" + text.batteryId}
            {text.batteryId && <br />}
            {text.imei && "二维码：" + text.imei}
            {text.imei && <br />}
            {text.ccodeId && "GPS号：" + text.ccodeId}
          </span>
        ),
    },
    {
      title: "代理商",
      dataIndex: "",
      render: (text) => (
        <span>
          {text.fname}
          <br />
          {text.sname}
        </span>
      ),
    },
    {
      title: "电池型号",
      dataIndex: "modelName",
    },
    {
      title: "异常原因",
      dataIndex: "alarm",
    },
    {
      title: "异常时间",
      dataIndex: "addTime",
    },
    {
      title: "事件",
      dataIndex: "type",
      render: (text) =>
        text === 1 ? (
          <span
            style={{
              background: "#009688",
              color: "#fff",
              padding: "5px 8px",
              fontSize: 12,
              borderRadius: 3,
            }}
          >
            产生
          </span>
        ) : (
          <span
            style={{
              background: "#FF5722",
              color: "#fff",
              padding: "5px 8px",
              fontSize: 12,
              borderRadius: 3,
            }}
          >
            恢复
          </span>
        ),
    },
    {
      title: "操作",
      dataIndex: "",
      fixed: "right",
      width: 80,
      key: "Action",
      render: (text) => (
        <Button
          type="link"
          target="_blank"
          href={"/batteryManagement?imei=" + text.imei}
        >
          查看
        </Button>
      ),
    },
  ];
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
  } = useTable({
    action: _batteryAlarmList,
    response: async (res) => {
      return res.data.data;
    },
  });
  const search = (values) => {
    let [addTimeStart, addTimeEnd] = values.addTime || [];
    values.addTimeStart = addTimeStart
      ? moment(addTimeStart).format("YYYY-MM-DD")
      : undefined;
    values.addTimeEnd = addTimeEnd
      ? moment(addTimeEnd).format("YYYY-MM-DD")
      : undefined;
    delete values.addTime;
    const params = {
      ...values,
    };
    handleSearch(params);
  };
  return !getJurisdiction(1030) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={search} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 1200, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
    </div>
  );
};

export default Index;
