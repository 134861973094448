/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Row, Col, message } from "antd";
import { _urgentSettleInfo, _confirmSettle } from "@/statics/js/api";

const DataLabelingModal = (props) => {
  const { modalVisible, modalParams, updateVisible, updateTable } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [settleInfo, setSettleInfo] = useState({
    settleCount: 0,
    settleAmount: 0,
  });

  useEffect(() => {
    if (modalVisible) {
      _urgentSettleInfo(formatParams).then((res) => {
        if (res?.data && res?.data?.code == 200) {
          const { settleCount, settleAmount } = res.data.data;
          setSettleInfo({ settleCount, settleAmount });
        }
      });
    }
  }, [modalVisible]);

  const formatParams = useMemo(() => {
    const { fid, sid } = modalParams;
    return {
      ...modalParams,
      fid: fid ? fid.key : undefined,
      sid: sid ? sid.key : undefined,
      settle: 0, // 未结算
      callResult: 1, // 已接通
    };
  }, [modalParams]);

  const format = useCallback(
    (str) => {
      const value = modalParams[str];
      if (value) {
        return value.label ? value.label : "全部";
      }
      return "全部";
    },
    [modalParams]
  );

  const fidLabel = useMemo(() => format("fid"), [format]);

  const sidLabel = useMemo(() => format("sid"), [format]);

  const time = useMemo(() => {
    const { timeStart, timeEnd } = modalParams;
    return timeStart && timeEnd ? `${timeStart} ~ ${timeEnd}` : "全部";
  }, [modalParams]);

  const handleModalOk = () => {
    setConfirmLoading(true);
    _confirmSettle(formatParams)
      .then((res) => {
        if (res?.data?.code == 200) {
          message.success("操作成功");
          updateTable();
        } else {
          message.warning(res?.data?.message);
        }
      })
      .finally(() => {
        setConfirmLoading(false);
      });
    updateVisible(false);
  };
  const handleModalCancel = () => {
    updateVisible(false);
  };
  return (
    <Modal
      className="bigModal"
      title="标记列表数据为已结算"
      destroyOnClose
      maskClosable={false}
      visible={modalVisible}
      confirmLoading={confirmLoading}
      okText="确定标记为已结算"
      okButtonProps={{ disabled: settleInfo.settleCount <= 0 }}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
    >
      <div>
        <div
          style={{
            marginBottom: "10px",
            fontWeight: 500,
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          筛选条件
        </div>
        <div>
          <Row
            gutter={[20, 10]}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              margin: 0,
              padding: "5px 0",
            }}
          >
            <Col lg={12}>代理商：{fidLabel}</Col>
            <Col lg={12}>站点：{sidLabel}</Col>
            <Col lg={12}>是否接通：已接通</Col>
            <Col lg={12}>是否结算：未结算</Col>
            <Col lg={12}>催收时间：{time}</Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          marginBottom: "10px",
          fontWeight: 500,
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.85)",
          marginTop: "20px",
        }}
      >
        <Row gutter={16}>
          <Col lg={6}>总条数：{settleInfo.settleCount || 0}条</Col>
          <Col lg={12}>总费用：{settleInfo.settleAmount?.toFixed(2)}元</Col>
        </Row>
      </div>
    </Modal>
  );
};

export default DataLabelingModal;
