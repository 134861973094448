import React,{
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';
import "echarts-liquidfill";
import './index.less';

const Item = (props) => {
    const {id,value} = props
    const myChart = useRef()
    useEffect(()=>{
        myChart.current = echarts.init(document.getElementById(id))
    },[])
    useEffect(()=>{
        init()
    },[props.value])
    const init = ()=>{
        let option = {
            series: [
                {
                    type: 'liquidFill',
                    data: [value],
                    // color: ['#4992ff'],
                    color: [
                        {
                            colorStops: [
                                {
                                  offset: 0,
                                  color: "#4f99d1",
                                },
                                {
                                  offset: 1,
                                  color: "#76f2af",
                                },
                            ],
                        }
                    ],
                    radius: '100%',
                    backgroundStyle: {
                        color: "rgba(74,158,248,.2)"
                    },
                    outline: {
                        itemStyle: {
                          borderColor: "rgba(74,158,248,.4)",
                          borderWidth: 3,
                        },
                    },
                    label: {
                        show: true,
                        color: '#fff',
                        insideColor: '#fff',
                        fontSize: 50,
                    },
                },
            ]
        }
        myChart.current.setOption(option);
    }
    return (
        <div className="liqiud_content">
            <div id={id} className="liqiud_box" style={{width: '100%',height: '100%'}}></div>
        </div>
    )
}

const Index = forwardRef((props,ref) => {
    const [percent,setPercent] = useState({
        bike: 0,
        battery: 0,
    })
    useEffect(()=>{

    },[])
    const init = ({bike=0,battery=0})=>{
        setPercent({
            bike: bike,
            battery: battery,
        })
    }
    useImperativeHandle(ref, () => ({
        init,
    }),[]);
    return (
        <div className="liqiud_wrap">
            <div className="liqiud_item">
                <Item id="liqiud1" value={percent.bike} />
                <div className="liqiud_title">车辆使用率</div>
            </div>
            <div className="liqiud_item">
                <Item id="liqiud2" value={percent.battery} />
                <div className="liqiud_title">电池使用率</div>
            </div>
        </div>
    )
})

export default Index
