import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import FranchiseeSelect from '@/components/franchiseeSelect'
import SiteSelect from '@/components/siteSelect'
import PackageSelect from '@/components/packageSelect'
import moment from 'moment';
import {
    _operateFranchiseePackageList,
    _operateFranchiseePackageExport,
    _operatePackageOrder,
    _operatePackageOrderExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Select,
    Row,
    Col,
    DatePicker
} from "antd";
const { Option } = Select;
const {WeekPicker} = DatePicker

const columns1 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "站点",
        dataIndex: "sname",
    },
    {
        title: "先付套餐",
        dataIndex: "firstPayNum",
    },
    {
        title: "后付套餐",
        dataIndex: "afterPayNum",
    },
    {
        title: "无代扣套餐",
        dataIndex: "noPayNum",
    },
]

const columns2 = [
    {
        title: "套餐名称",
        dataIndex: "pname",
    },
    {
        title: "新增订单数",
        dataIndex: "newOrderNum",
    },
    {
        title: "续租订单数",
        dataIndex: "reOrderNum",
    },
    {
        title: "总占比（新增+续租/总新增+续租订单数）",
        dataIndex: "orderNumScale",
    },
]

const Index1 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue,setFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        
    },[])
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        validateFields((err, values) => {
            tableRef.current.handleSearch(values)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        handleDownload({
            action: _operateFranchiseePackageExport,
            query: values
        })
    }
    return (<>
        <Form onSubmit={handleSubmit} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect onChange={()=>{setFieldsValue({ sid: "" })}} />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="站点">
                        {getFieldDecorator("sid")(
                            <SiteSelect fid={getFieldValue('fid')} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为月维度</p>
        <CommonTable
            ref={tableRef}
            action={_operateFranchiseePackageList}
            options={{
                columns: columns1
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index2 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,setFieldsValue,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])

    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type} = values
            let time = {}
            if(type == 0){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type} = values
        let time = {}
        if(type == 0){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }
        handleDownload({
            action: _operatePackageOrderExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 0
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>月报表</Option>
                                <Option value={0}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="套餐名称">
                        {getFieldDecorator("pid")(
                            <PackageSelect config={{value: 'pname'}} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operatePackageOrder}
            options={{
                columns: columns2
            }}
        />
    </>)
})

const Index = ()=>{
    return (<>
        <Index1 />
        <br />
        <Index2 />
    </>)
}

export default Index