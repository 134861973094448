import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Radio,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Divider,
  message,
  Input,
} from "antd";
import {
  _franchiseeList,
  _packageBoxList,
  _getSettlementRulesList,
  _deleteSettlementRuleInfo,
  _saveSettlementRuleInfo,
  _getSettlementRuleDetails,
  _exportSettlementRulesList
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import "./style.less";

const { Option } = Select;
const modalTitle = ["添加分润规则", "编辑分润规则", "分润规则详情"];
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  state = {
    editing: true,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      console.log({ ...values });
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record } = this.props;
    const { editing } = this.state;
    return editing && record[dataIndex] !== undefined ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: "请输入",
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            disabled={record[dataIndex] === undefined}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class SettlementRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      settlementRulesList: [], //分润规则列表
      packageList: [], //套餐列表
      franchiseeList: [], //代理商列表
      queryFormValues: {}, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      id: "", //分润规则id
      initialValues: {}, //分润规则详情
      dataSource: [],
      exportload: false,
    };
    this.columns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "套餐名称",
        dataIndex: "pname",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "代理商金额",
        align: "right",
        width: 110,
        dataIndex: "agentMoney",
      },
      {
        title: "平台金额",
        align: "right",
        width: 100,
        dataIndex: "platMoney",
      },
      {
        title: "车辆金额",
        align: "right",
        width: 100,
        dataIndex: "bikeMoney",
      },
      {
        title: "电池金额",
        align: "right",
        width: 100,
        dataIndex: "dcMoney",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            disabled={!getJurisdiction(883)}
            title={getJurisdiction(883) ? "" : "暂无操作权限！"}
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(873)}
              title={getJurisdiction(873) ? "" : "暂无操作权限！"}
              onClick={this.getSettlementRuleDetails.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(871)}
              title={getJurisdiction(871) ? "" : "暂无操作权限！"}
              onClick={this.getSettlementRuleDetails.bind(this, text.id, 2)}
            >
              编辑
            </Button>
          </span>
        ),
      },
    ];
    this.ruleColumns = [
      {
        title: "分润规则",
        dataIndex: "key",
        render: (text) =>
          "分润规则" +
          (text === "0"
            ? "一"
            : text === "1"
            ? "二"
            : text === "2"
            ? "三"
            : text === "3"
            ? "四"
            : ""),
      },
      {
        title: "代理商金额",
        dataIndex: "agentMoney",
        editable: true,
      },
      {
        title: "二级代理商金额",
        dataIndex: "subAgentMoney",
        editable: true,
      },
      {
        title: "站点（BD）",
        dataIndex: "siteMoney",
        editable: true,
      },
      {
        title: "平台金额",
        dataIndex: "platMoney",
        editable: true,
      },
      {
        title: "车辆金额",
        dataIndex: "bikeMoney",
        editable: true,
      },
      {
        title: "电池金额",
        dataIndex: "dcMoney",
        editable: true,
      },
      {
        title: "车型规则",
        dataIndex: "bikeModelRule",
        editable: true,
      },
    ];
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  renderAaa() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.ruleColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    );
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator("fidSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐名称">
              {getFieldDecorator("pidSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.packageList &&
                    this.state.packageList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.pname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      totalCount,
      curPage,
      pageSize,
      settlementRulesList,
      initialValues,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {getJurisdiction(870) ? (
          <div className="SettlementRule">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(872)}
                title={getJurisdiction(872) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加分润规则
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
                onClick={this.handleExportChange.bind(this)}
              >
                导出
              </Button>
            </div>
            <div className="content-table">
              <Table
                scroll={{ x: 1200, y: 500 }}
                rowKey="id"
                columns={this.columns}
                dataSource={settlementRulesList}
                bordered
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="套餐名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.pname}</span>
                    ) : (
                      getFieldDecorator("pid", {
                        initialValue: initialValues.pid,
                        rules: [
                          { required: true, message: "请选择套餐名称！" },
                        ],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.packageList &&
                            this.state.packageList.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.pname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="套餐是否有优惠券">
                    {getFieldDecorator("useCoupon", {
                      initialValue: initialValues.useCoupon,
                      rules: [{ required: true, message: "请选择！" }],
                    })(
                      <Radio.Group
                        onChange={this.handleSelectChange.bind(
                          this,
                          "useCoupon"
                        )}
                      >
                        <Radio value={0}>无优惠券</Radio>
                        <Radio value={1}>有优惠券</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="是否有二级代理商分润">
                    {getFieldDecorator("subAgent", {
                      initialValue: initialValues.subAgent,
                      rules: [{ required: true, message: "请选择！" }],
                    })(
                      <Radio.Group
                        onChange={this.handleSelectChange.bind(
                          this,
                          "subAgent"
                        )}
                      >
                        <Radio value={0}>无二级代理商</Radio>
                        <Radio value={1}>有二级代理商</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {getFieldDecorator("rules", {
                    initialValue: initialValues.rules,
                  })(
                    <Form.Item label="分润规则制定">
                      <div style={{ width: "100%" }}>
                        {this.state.useCoupon === 0 &&
                        this.state.subAgent === 0 ? (
                          <p>
                            1.无二级代理商
                            无优惠券，分润规则按“分润规则一”进行分润设置；
                          </p>
                        ) : this.state.useCoupon === 1 &&
                          this.state.subAgent === 0 ? (
                          <p>
                            1.订单未使用优惠券分润规则按“分润规则一”进行分润设置；
                            <br />
                            2.订单使用优惠券分润规则按“分润规则二”进行分润设置；
                          </p>
                        ) : this.state.useCoupon === 0 &&
                          this.state.subAgent === 1 ? (
                          <p>
                            1.无二级代理商分润订单，分润规则按“分润规则一”进行分润设置；
                            <br />
                            2.有二级代理商分润订单，分润规则按“分润规则二”进行分润设
                          </p>
                        ) : this.state.useCoupon === 1 &&
                          this.state.subAgent === 1 ? (
                          <p>
                            1.使用优惠券没有二级代理商分润订单，分润规则按“分润规则一”进行分润设置；
                            <br />
                            2.使用优惠券有二级代理商分润订单，分润规则按”分润规则二”进行分润设置；
                            <br />
                            3.未使用优惠券没有二级代理商分润订单，分润规则按“分润规则三”进行分润设置；
                            <br />
                            4.未使用优惠券有二级代理商分润订单，分润规则按“分润规则四”进行分润设置；
                          </p>
                        ) : (
                          ""
                        )}
                        {this.renderAaa()}
                      </div>
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getPackageList();
    this.getSettlementRulesList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  // 导出
  async handleExportChange(){
    const values = await this.getQueryForm()
    this.setState({
      exportload: true,
    });
    const res = await _exportSettlementRulesList(values)
    this.setState({
      exportload: false,
    });
    const {code,data,message:msg} = res.data || {}
    if(code !== 200) {
      message.warning(msg);
      return
    }
    if(!data){
      message.warning('暂无数据');
      return
    }
    window.open(`${data}`, "_blank").focus();
  }
  //获取分润规则列表
  getSettlementRulesList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _getSettlementRulesList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            settlementRulesList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取套餐列表
  getPackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    };
    _packageBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getSettlementRulesList(curPage, pageSize, this.state.queryFormValues);
  }

  handleSelectChange(type, e) {
    let argument = "";
    if (type === "subAgent") {
      argument = String(e.target.value) + String(this.state.useCoupon);
    } else {
      argument = String(this.state.subAgent) + String(e.target.value);
    }
    this.setState({ [type]: e.target.value });
    switch (argument) {
      case "00":
        this.setState({
          dataSource: [
            {
              key: "0",
              agentMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
          ],
        });
        break;
      case "10":
        this.setState({
          dataSource: [
            {
              key: "0",
              agentMoney: "",
              subAgentMoney: "",
              siteMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
            {
              key: "1",
              agentMoney: "",
              subAgentMoney: "",
              siteMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
          ],
        });
        break;
      case "01":
        this.setState({
          dataSource: [
            {
              key: "0",
              agentMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
            {
              key: "1",
              agentMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
          ],
        });
        break;
      case "11":
        this.setState({
          dataSource: [
            {
              key: "0",
              agentMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
            {
              key: "1",
              agentMoney: "",
              subAgentMoney: "",
              siteMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
            {
              key: "2",
              agentMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
            {
              key: "3",
              agentMoney: "",
              subAgentMoney: "",
              siteMoney: "",
              platMoney: "",
              bikeMoney: "",
              dcMoney: "",
              bikeModelRule: "",
            },
          ],
        });
        break;
      default:
        this.setState({
          dataSource: [],
        });
    }
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _deleteSettlementRuleInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSettlementRulesList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 获取筛选
  async getQueryForm(){
    const { form } = this.props;
    const values = await form.getFieldsValue()
    for (let key in values) {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ""
      ) {
        delete values[key];
      }
    }
    delete values.status;
    if (values.fidSearch) {
      values.fid = values.fidSearch;
      delete values.fidSearch;
    }
    if (values.pidSearch) {
      values.pid = values.pidSearch;
      delete values.pidSearch;
    }
    return values
  }
  // 表单筛选
  handleSubmit = async (e)=> {
    e.preventDefault();
    const values = await this.getQueryForm()
    this.setState({
      queryFormValues: values,
    });
    this.getSettlementRulesList(1, this.state.pageSize, values);
  };

  //获取分润规则详情
  getSettlementRuleDetails(id, type) {
    let params = { id };
    _getSettlementRuleDetails(params)
      .then((res) => {
        if (res.data.code === 200) {
          let dataSource = [];
          if (
            res.data.data.ruleDetail === null &&
            res.data.data.useCoupon === 0 &&
            res.data.data.subAgent === 0
          ) {
            let dataSourceItem = {};
            res.data.data.ruleDetails.map((item, index) => {
              return (
                (dataSourceItem.key = item.key),
                (dataSourceItem.agentMoney = item.agentMoney),
                (dataSourceItem.platMoney = item.platMoney),
                (dataSourceItem.bikeMoney = item.bikeMoney),
                (dataSourceItem.dcMoney = item.dcMoney),
                (dataSourceItem.bikeModelRule = item.bikeModelRule)
              );
            });
            dataSource.push(dataSourceItem);
          } else {
            dataSource = JSON.parse(res.data.data.ruleDetail);
          }
          this.setState({
            id,
            dataSource,
            initialValues: res.data.data,
            subAgent: res.data.data.subAgent,
            useCoupon: res.data.data.useCoupon,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      values.ruleDetail = JSON.stringify(this.state.dataSource);
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveSettlementRuleInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              this.initFormModal();
              this.getSettlementRulesList(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              );
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
      dataSource: [],
      subAgent: null,
      useCoupon: null,
    });
  }
}

SettlementRule = Form.create({})(SettlementRule);
export { SettlementRule };
