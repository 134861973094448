import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Button, Divider, Form, message } from "antd";
import SearchBox from "./components/searchBox";
import ComplaintModal from "./components/complaintModal";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _baoComplainList, _complainHandleList } from "@/statics/js/api";

const CardComplaint = (props) => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "投诉单号",
      dataIndex: "complainEventId",
    },
    {
      title: "商家订单号",
      dataIndex: "orderId",
      render: (text) => (
        <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
          {text}
        </Link>
      ),
    },
    {
      title: "投诉单状态",
      dataIndex: "complainStatus",
      render: (text) =>
        text === 0
          ? "商家处理中"
          : text === 1
          ? "商家已反馈"
          : text === 2
          ? "投诉已完结"
          : text === 3
          ? "投诉已撤销"
          : text === 4
          ? "平台处理中"
          : text === 5
          ? "平台处理完结"
          : text === 6
          ? "系统关闭"
          : "",
    },
    {
      title: "投诉单创建时间",
      dataIndex: "gmtCreateTime",
    },
    {
      title: "投诉单修改时间",
      dataIndex: "gmtModifiedTime",
    },
    {
      title: "用户投诉诉求",
      dataIndex: "leafCategoryName",
    },
    {
      title: "用户投诉内容",
      dataIndex: "content",
    },
    {
      title: "用户投诉图片",
      dataIndex: "imageList",
      render: (text) => (
        <Button
          disabled={!getJurisdiction(1083)}
          title={getJurisdiction(1083) ? "" : "暂无操作权限！"}
          type="link"
          onClick={() => {
            modalViewQrcode(text);
          }}
        >查看图片</Button>
      ),
    },
    {
      title: "交易金额(元)",
      dataIndex: "tradeAmount",
    },
    {
      title: "投诉人手机号",
      dataIndex: "phone",
    },
    {
      title: "交易号",
      dataIndex: "tradeNo",
    },
    {
      title: "投诉单完成时间",
      dataIndex: "gmtFinishedTime",
    },
    {
      title: "代理商",
      dataIndex: "fname",
    },
    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <>
          <Button
            type="link"
            disabled={!getJurisdiction(1084)}
            title={getJurisdiction(1084) ? "" : "暂无操作权限！"}
            onClick={() => {
              modalViewCheck(text.id);
            }}
          >
            查看
          </Button>
          {text.complainStatus === 0 && (
            <>
              <Divider type="vertical" />
              <Button
                type="link"
                disabled={!getJurisdiction(1086)}
                title={getJurisdiction(1086) ? "" : "暂无操作权限！"}
                onClick={() => {
                  showModal(text);
                }}
              >
                处理
              </Button>
            </>
          )}
        </>
      ),
    },
  ];
  const userType = localStorage.getItem("type")
  const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
    handleRefresh,
  } = useTable({
    action: _baoComplainList,
    params: {
      fid: userType !== '0' ? fid : undefined
    }
  });

  const [visible, setVisible] = useState(false);
  const [info, setInfo] = useState("");
  // 开启弹框
  const showModal = async (text) => {
    setInfo(text);
    setVisible(true);
  };
  // 关闭弹框
  const handleClose = async () => {
    setVisible(false);
  };
  const handleDone = async () => {
    handleRefresh()
    props.refresh()
  };
  const modalViewQrcode = (imgs) => {
    if (imgs) {
      Modal.info({
        title: "查看图片",
        content: (
          <div>
            {imgs.map((item, index) => {
              return (
                <a target="_blank" key={index} href={item}>
                  <img style={{ width: "30%", margin: "1%" }} src={item} />
                </a>
              );
            })}
          </div>
        ),
      });
    } else {
      message.warning("暂无图片");
    }
  };
  //查看投诉
  const modalViewCheck = (id) => {
    let params = { id };
    _complainHandleList(params).then((res) => {
      if (res.data.code === 200 && res.data.data) {
        Modal.info({
          title: "查看处理结果",
          content: (
            <div style={{ marginTop: 30 }}>
              <Form.Item label="投诉工单">
                <span>{res.data.data.complainEventId}</span>
              </Form.Item>
              <Form.Item label="工单创建时间">
                <span>{res.data.data.gmtCreateTime}</span>
              </Form.Item>
              <Form.Item label="反馈类目">
                <span>{res.data.data.feedbackCode}</span>
              </Form.Item>
              <Form.Item label="反馈内容">
                <span>{res.data.data.feedbackContent}</span>
              </Form.Item>
              <Form.Item label="上传图片">
                {res.data.data.imageList &&
                  res.data.data.imageList.map((item, index) => {
                    return (
                      <a target="_blank" key={index} href={item}>
                        <img
                          key={index}
                          style={{ width: "30%", marginRight: "3%" }}
                          src={item}
                        />
                      </a>
                    );
                  })}
              </Form.Item>
              {res.data.data.list &&
                res.data.data.list.map((item, index) => {
                  return (
                    <div key={index}>
                      <Form.Item label="工单修改时间">
                        <span>{item.gmtModifiedTime}</span>
                      </Form.Item>
                      <Form.Item label="反馈类目">
                        <span>{item.feedbackCode}</span>
                      </Form.Item>
                      <Form.Item label="反馈内容">
                        <span>{item.feedbackContent}</span>
                      </Form.Item>
                      <Form.Item label="上传图片">
                        {item.imageList &&
                          item.imageList.map((items, indexs) => {
                            return (
                              <a target="_blank" key={indexs} href={items}>
                                <img
                                  key={indexs}
                                  style={{ width: "30%", marginRight: "3%" }}
                                  src={items}
                                />
                              </a>
                            );
                          })}
                      </Form.Item>
                    </div>
                  );
                })}
            </div>
          ),
        });
      } else {
        message.warning("暂无数据");
      }
    });
  };
  return !getJurisdiction(1082) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div style={{ color: "#2693FC", marginBottom: 20 }}>
        请在投诉工单创建或修改时间72小时内处理
      </div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 2000, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
      <ComplaintModal
        visible={visible}
        info={info}
        close={handleClose}
        done={handleDone}
      />
    </div>
  );
};

export default CardComplaint;
