import React, { Component } from 'react'
import {
  Button,
  Table,
  Modal,
  Switch,
  Divider,
  DatePicker,
  InputNumber,
  Select,
  Input,
  Icon,
  Form,
  Row,
  Col,
  Upload,
  message,
} from 'antd'
import {
  _upload,
  _franchiseeList,
  _franchiseeDetail,
  _updateFranchiseeStatus,
  _saveFranchisee,
  _deleteFranchisee,
  _batchDeleteFranchisee,
  _exportFranchiseeList,
} from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import AreaCascader from '@/components/areaCascader'
import md5 from 'md5'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select
const modalTitle = ['添加代理商', '编辑代理商', '代理商详情']
class AgentManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商管理列表
      queryFormValues: {
        status: 1,
      }, //筛选项
      fid: '', //代理商id
      initialValues: {}, //代理商详情
      visible: false, //Modal是否打开
      selectedRowKeys: [], // Check here to configure the default column
      loading: false, //加载
      modalType: '', //1-添加，2-编辑，3-查看
      fileList: [], //上传格式
    }
    this.columns = [
      {
        title: '代理商编号',
        width: 120,
        dataIndex: 'fid',
      },
      {
        title: '代理商名称',
        width: 250,
        dataIndex: 'fname',
      },
      {
        title: '联系人',
        width: 120,
        dataIndex: 'fcontacts',
      },
      {
        title: '手机号',
        align: 'right',
        width: 150,
        dataIndex: 'ftel',
      },
      {
        title: '城市',
        width: 120,
        dataIndex: 'fcityName',
      },
      {
        title: '合作期限',
        width: 145,
        dataIndex: '',
        render: (text) => (
          <span>
            {text.fstartCooperation ? text.fstartCooperation : ''}
            {text.fstartCooperation && text.fendCooperation ? (
              <span>
                <br />
                ~
                <br />
              </span>
            ) : null}
            {text.fendCooperation ? text.fendCooperation : ''}
          </span>
        ),
      },
      {
        title: '添加时间',
        width: 120,
        dataIndex: 'fupdate',
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '管理状态',
        dataIndex: '',
        width: 100,
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(696)}
            title={getJurisdiction(696) ? '' : '暂无操作权限！'}
            defaultChecked={!!text.fstate}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.fid)}
          />
        ),
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        key: 'Action',
        fixed: 'right',
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(695)}
              title={getJurisdiction(695) ? '' : '暂无操作权限！'}
              onClick={this.getFranchiseeDetail.bind(this, text.fid, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(694)}
              title={getJurisdiction(694) ? '' : '暂无操作权限！'}
              onClick={this.getFranchiseeDetail.bind(this, text.fid, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(693)}
              title={getJurisdiction(693) ? '' : '暂无操作权限！'}
              onClick={this.handleDeleteFranchiseeChange.bind(this, text.fid)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ]
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商/手机号">
              {getFieldDecorator('queryString')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator('areaList')(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator('status', {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={''}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      initialValues,
      franchiseeList,
      fileList,
      totalCount,
      curPage,
      pageSize,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
    }
    const hasSelected = selectedRowKeys.length > 0
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        {!!getJurisdiction(692) ? (
          <div className="AgentManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={this.showModal.bind(this, 1)}
                disabled={!getJurisdiction(702)}
                title={getJurisdiction(702) ? '' : '暂无操作权限！'}
              >
                添加代理商
              </Button>
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(693) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="fid"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={franchiseeList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator('fname', {
                        initialValue: initialValues.fname,
                        rules: [
                          { required: true, message: '请输入代理商名称！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="代理商类型">
                    {this.state.modalType === 3 ? (
                      <span>
                        {
                          {
                            0: '纯sass代理商',
                            1: '混合代理商',
                            2: '自营代理商',
                          }[initialValues.f_add_type]
                        }
                      </span>
                    ) : (
                      getFieldDecorator('f_add_type', {
                        initialValue: initialValues.f_add_type,
                      })(
                        <Select
                          style={{ width: '100%' }}
                          placeholder="请选择"
                          allowClear
                        >
                          <Option value={0}>纯sass代理商</Option>
                          <Option value={1}>混合代理商</Option>
                          <Option value={2}>自营代理商</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="联系人">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fcontacts}</span>
                    ) : (
                      getFieldDecorator('fcontacts', {
                        initialValue: initialValues.fcontacts,
                        rules: [{ required: true, message: '请输入联系人！' }],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="手机号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.ftel}</span>
                    ) : (
                      getFieldDecorator('ftel', {
                        validateTrigger: 'onBlur',
                        rules: [
                          {
                            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                            message: '请输入正确的手机号格式',
                          },
                          { required: true, message: '请输入手机号！' },
                        ],
                        initialValue: initialValues.ftel,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                {this.state.modalType === 1 && (
                  <Col span={24}>
                    <Form.Item label="密码">
                      {getFieldDecorator('password', {
                        validateTrigger: 'onBlur',
                        rules: [
                          {
                            message: '请输入至少六位数密码',
                            pattern: /^.{6,}$/,
                          },
                        ],
                      })(<Input allowClear placeholder="默认密码123456" />)}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="套餐基础服务费">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fpackageFee}</span>
                    ) : (
                      getFieldDecorator('fpackageFee', {
                        validateTrigger: 'onBlur',
                        initialValue: initialValues.fpackageFee,
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          allowClear
                          min={0}
                          max={50}
                          precision={2}
                          placeholder="请输入"
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <Form.Item label="套餐分润平台占比(%)" style={{ flex: 1 }}>
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fpackageRent}</span>
                    ) : (
                      getFieldDecorator('fpackageRent', {
                        validateTrigger: 'onBlur',
                        initialValue: initialValues.fpackageRent,
                        rules: [
                          {
                            message: '请输入0.00到100.00的数值',
                            pattern:
                              /^(?:(?!0\d)\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/,
                          },
                        ],
                      })(<InputNumber allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                  <Form.Item label="免赔分润平台占比(%)" style={{ flex: 1 }}>
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.finsuranceRent}</span>
                    ) : (
                      getFieldDecorator('finsuranceRent', {
                        validateTrigger: 'onBlur',
                        initialValue: initialValues.finsuranceRent,
                        rules: [
                          {
                            message: '请输入0.00到100.00的数值',
                            pattern:
                              /^(?:(?!0\d)\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/,
                          },
                        ],
                      })(<InputNumber allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item label="首运营分成比例(%)">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.froyaltyBuy}</span>
                    ) : (
                      getFieldDecorator("froyaltyBuy", {
                        validateTrigger: "onBlur",
                        initialValue: initialValues.froyaltyBuy,
                        rules: [
                          {
                            message: "请输入0.00到100.00的数值",
                            pattern:
                              /^(?:(?!0\d)\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/,
                          },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="20次分成比例(%)">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.froyaltyRent}</span>
                    ) : (
                      getFieldDecorator("froyaltyRent", {
                        initialValue: initialValues.froyaltyRent,
                        rules: [
                          {
                            message: "请输入0.00到100.00的数值",
                            pattern:
                              /^(?:(?!0\d)\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/,
                          },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item label="合作期限">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.fstartCooperation &&
                        initialValues.fendCooperation
                          ? initialValues.fstartCooperation +
                            '～' +
                            initialValues.fstartCooperation
                          : ''}
                      </span>
                    ) : (
                      getFieldDecorator('fcooperation', {
                        initialValue:
                          initialValues.fstartCooperation &&
                          initialValues.fendCooperation
                            ? [
                                moment(initialValues.fstartCooperation),
                                moment(initialValues.fendCooperation),
                              ]
                            : undefined,
                        rules: [
                          { required: true, message: '请选择合作期限！' },
                        ],
                      })(<RangePicker format="YYYY-MM-DD" />)
                    )}
                  </Form.Item>
                </Col>
                {this.state.modalType !== 3 && (
                  <Col span={24}>
                    <Form.Item label="合同文档">
                      {getFieldDecorator('fwork', {
                        initialValue: initialValues.fwork || undefined,
                        getValueFromEvent: this.normFile,
                      })(
                        <Upload
                          name="uploadFile"
                          action={_upload}
                          listType="picture-card"
                          fileList={fileList}
                          accept=".pdf"
                          onChange={this.handleChange.bind(this)}
                          data={{
                            scene: 'active',
                          }}
                        >
                          {fileList.length >= 1 ? null : (
                            <div>
                              <Icon type="plus" style={{ fontSize: '28px' }} />
                              <p>PDF文件上传</p>
                            </div>
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="地区">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.areaName}</span>
                    ) : (
                      getFieldDecorator('areaList', {
                        initialValue: initialValues.areaList,
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: '请选择地区！',
                          },
                        ],
                      })(
                        <AreaCascader
                          changeOnSelect
                          placeholder="城市"
                          style={{ width: '100%' }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="详情地址">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fadd}</span>
                    ) : (
                      getFieldDecorator('fadd', {
                        initialValue: initialValues.fadd,
                        rules: [
                          { required: true, message: '请输入详情地址！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="公司全称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fcompanyName}</span>
                    ) : (
                      getFieldDecorator('fcompanyName', {
                        initialValue: initialValues.fcompanyName,
                        rules: [
                          { required: true, message: '请输入公司全称！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="纳税识别号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.ftaxNo}</span>
                    ) : (
                      getFieldDecorator('ftaxNo', {
                        initialValue: initialValues.ftaxNo,
                        rules: [
                          { required: true, message: '请输入纳税识别号！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="开户行">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fbankName}</span>
                    ) : (
                      getFieldDecorator('fbankName', {
                        initialValue: initialValues.fbankName,
                        rules: [{ required: true, message: '请输入开户行！' }],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="银行账号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fbankAccounts}</span>
                    ) : (
                      getFieldDecorator('fbankAccounts', {
                        initialValue: initialValues.fbankAccounts,
                        rules: [
                          { required: true, message: '请输入银行账号！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="支付宝名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.falipayName}</span>
                    ) : (
                      getFieldDecorator('falipayName', {
                        initialValue: initialValues.falipayName,
                        rules: [
                          { required: true, message: '请输入支付宝名称！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="支付宝账号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.falipayAccounts}</span>
                    ) : (
                      getFieldDecorator('falipayAccounts', {
                        initialValue: initialValues.falipayAccounts,
                        rules: [
                          { required: true, message: '请输入支付宝账号！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                {/* <Col span={24}>
                  <Form.Item label="是否支持修改订单金额">
                    {getFieldDecorator("", {
                      initialValue: initialValues.fstate === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item label="是否支持修改订单金额">
                    {getFieldDecorator('modifyPrice', {
                      initialValue: !!initialValues.modifyPrice,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                        disabled={
                          this.state.modalType == 2 &&
                          initialValues.fupdate * 1000 < Date.parse('2023/9/15')
                        }
                        onChange={this.hanldeModifyPriceChange.bind(this)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="分期付业务">
                    {getFieldDecorator('dayDeduct', {
                      initialValue: !!initialValues.dayDeduct,
                      valuePropName: 'checked',
                    })(
                      <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="状态">
                    {getFieldDecorator('fstate', {
                      initialValue: initialValues.fstate === 0 ? false : true,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getFranchiseeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      delete values.fstate
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null
        values.scity = values.areaList[1] ? values.areaList[1] : null
        values.scounty = values.areaList[2] ? values.areaList[2] : null
        values.sstreet = values.areaList[3] ? values.areaList[3] : null
        delete values.areaList
      }
      _exportFranchiseeList({ ...values })
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.setState({
            exportload: false,
          })
        })
    })
  }

  //获取代理商列表
  getFranchiseeList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            franchiseeList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取代理商详情
  getFranchiseeDetail(fid, type) {
    let params = { fid }
    _franchiseeDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { fprovince, fcity, fcounty, fstreet } = res.data.data || {}
          this.setState({
            fid,
            initialValues: {
              ...res.data.data,
              areaList: [fprovince, fcity, fcounty, fstreet].filter(
                (item) => item
              ),
            },
          })
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: 'done',
                url: res.data.data.imgObject.path,
              },
            ])
          this.showModal(type)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message)
          fileList.splice(0, 1)
        }
      }
    }
    this.updateFileList(fileList)
  }
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    })
  }
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getFranchiseeList(curPage, pageSize, this.state.queryFormValues)
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      delete values.fstate
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null
        values.scity = values.areaList[1] ? values.areaList[1] : null
        values.scounty = values.areaList[2] ? values.areaList[2] : null
        values.sstreet = values.areaList[3] ? values.areaList[3] : null
        delete values.areaList
      }
      this.setState({
        queryFormValues: values,
      })
      this.getFranchiseeList(1, this.state.pageSize, values)
    })
  }

  //管理状态Switch
  handleStateChange(fid, checked) {
    let params = { fid, fstate: Number(checked) }
    _updateFranchiseeStatus(params)
      .then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          message.success('操作成功')
          this.getFranchiseeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          )
        } else {
          message.warning(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields()
    this.setState({
      modalType,
      visible: true,
    })
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault()
    const { form } = this.props
    const { modalType, fid } = this.state
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.fwork && values.fwork.constructor === Array) {
        values.fwork =
          values.fwork.length === 1 ? values.fwork[0].response.data.id : ''
      }
      if (values.password) {
        values.password = md5(values.password)
      }
      if (values.fstate !== undefined) {
        values.fstate = Number(values.fstate)
      }
      values.modifyPrice = Number(values.modifyPrice)
      // values.fstate === undefined && modalType === 1
      // ? (values.fstate = 1)
      // : !!values.fstate
      // ? (values.fstate = 1)
      // : (values.fstate = 0);
      if (values.fcooperation && values.fcooperation.length) {
        values.fstartCooperation = moment(values.fcooperation[0]).format(
          'YYYY-MM-DD'
        )
        values.fendCooperation = moment(values.fcooperation[1]).format(
          'YYYY-MM-DD'
        )
      }
      values.dayDeduct = Number(values.dayDeduct)
      delete values.fcooperation
      if (values.areaList) {
        values.fprovince = values.areaList[0] ? values.areaList[0] : null
        values.fcity = values.areaList[1] ? values.areaList[1] : null
        values.fcounty = values.areaList[2] ? values.areaList[2] : null
        values.fstreet = values.areaList[3] ? values.areaList[3] : null
        delete values.areaList
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        })
      } else {
        let params =
          modalType === 1
            ? {
                ...values,
              }
            : modalType === 2
            ? {
                fid,
                ...values,
              }
            : {}
        _saveFranchisee(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              this.setState({
                visible: false,
              })
              setTimeout(function () {
                window.location.reload()
              }, 1000)
            } else {
              message.warning(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields()
    this.updateFileList([])
    this.setState({
      fid: null,
      initialValues: {},
      visible: false,
      modalType: null,
    })
  }

  //单条删除
  handleDeleteFranchiseeChange(fid) {
    Modal.confirm({
      title: '删除信息',
      content: '是否确定删除此数据吗？',
      onOk() {
        let params = { fid }
        _deleteFranchisee(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              setTimeout(function () {
                window.location.reload()
              }, 1000)
            } else {
              message.warning(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
    })
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this
    self.setState({ loading: true })
    Modal.confirm({
      title: '删除信息',
      content: '是否确定批量删除数据吗？',
      onOk() {
        let params = self.state.selectedRowKeys
        _batchDeleteFranchisee(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              setTimeout(function () {
                window.location.reload()
              }, 1000)
            }
          })
          .catch((err) => {
            console.log(err)
            message.warning('操作失败')
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              })
            }, 1000)
          })
      },
    })
  }

  //选中数据
  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys })
  }
  hanldeModifyPriceChange(value) {
    const { setFieldsValue } = this.props.form
    Modal.confirm({
      title: '提示',
      content: `你确定要${value ? '开启' : '关闭'}支持修改订单金额`,
      onCancel() {
        setFieldsValue({ modifyPrice: !value })
      },
    })
  }
}

AgentManagement = Form.create({})(AgentManagement)
export { AgentManagement }
