import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Select,
  Icon,
  Modal,
  Form,
  Row,
  Upload,
  TimePicker,
  Col,
  message,
  AutoComplete,
} from "antd";
import {
  _batteryChargeList,
  _chargeDetail,
  _saveBatteryCharge,
  _updateChargeStatus,
  _imageUpload,
  _franchiseeList,
  _exportBatteryChargeStatic,
  _mapGD,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";
import { zipImg } from "@/statics/js/util";
import { Map, Marker } from "react-amap";
import moment from "moment";
const amapkey = "4792ad0f37a527c1535e83599762d894";

const { Option } = Select;
const modalTitle = ["添加电柜", "编辑电柜", "电柜详情"];
class ChargeBattery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      chargeList: [], //电柜列表
      franchiseeList: [], //代理商列表
      queryFormValues: {
        status: 1,
      }, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      initialValues: {}, //站点详情
      sid: "", //站点id
      fileList: [], //上传格式
      addressName: "", //定位时的地址（地图地址）
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
      mockData: [],
      targetKeys: [],
      autoList: [],
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商名称",
        dataIndex: "fname",
      },
      {
        title: "电柜名称",
        dataIndex: "chargeName",
      },
      {
        title: "电柜编号",
        dataIndex: "chargeNo",
      },
      {
        title: "类型",
        dataIndex: "chargeType",
        render: (text) =>
          text === 1
            ? "充电柜"
            : text === 2
            ? "换电柜"
            : text === 3
            ? "快充点"
            : "",
      },
      {
        title: "厂家",
        dataIndex: "companyName",
      },
      {
        title: "城市",
        width: 120,
        dataIndex: "cityName",
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "电价",
        dataIndex: "",
        width: 200,
        render: (text) => (
          <span>
            {text.userPrice ? "用户电价:" + text.userPrice + "元/kW·h" : ""}
            <br />
            {text.agentPrice ? "商家电价:" + text.agentPrice + "元/kW·h" : ""}
            <br />
            {text.price ? "成本电价:" + text.price + "元/kW·h" : ""}
            <br />
            {text.explain ? "电价说明:" + text.explain : ""}
          </span>
        ),
      },
      {
        title: "联系人",
        dataIndex: "name",
      },
      {
        title: "手机号",
        dataIndex: "phone",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(822)}
              title={getJurisdiction(822) ? "" : "暂无操作权限！"}
              onClick={this.getSiteDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(823)}
              title={getJurisdiction(823) ? "" : "暂无操作权限！"}
              onClick={this.getSiteDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            {text.chargeType === 3 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  target="_blank"
                  href={"/evCharger?id=" + text.id}
                  disabled={!getJurisdiction(921)}
                  title={getJurisdiction(921) ? "" : "暂无操作权限！"}
                >
                  充电桩管理
                </Button>
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电柜名称">
              {getFieldDecorator("chargeStrSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="类型">
              {getFieldDecorator("chargeTypeSearch")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={3}>快充点</Option>
                  <Option value={1}>充电柜</Option>
                  <Option value={2}>换电柜</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaListSearch")(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fidSearch")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("statusSearch", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理员/手机号">
              {getFieldDecorator("userName", {
                initialValue: this.state.queryFormValues.userName,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(1033)}
                title={getJurisdiction(1033) ? "" : "暂无操作权限！"}
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      fileList,
      totalCount,
      curPage,
      pageSize,
      chargeList,
      initialValues,
      autoList,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(845) ? (
          <div className="ChargeBattery">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(821)}
                title={getJurisdiction(821) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加电柜
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={chargeList}
                scroll={{ x: 1600, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          disabled={localStorage.getItem("type") != 0}
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList.length &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电柜名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.chargeName}</span>
                    ) : (
                      getFieldDecorator("chargeName", {
                        initialValue: initialValues.chargeName,
                        rules: [
                          { required: true, message: "请输入电柜名称！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电柜编号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.chargeNo}</span>
                    ) : (
                      getFieldDecorator("chargeNo", {
                        initialValue: initialValues.chargeNo,
                        rules: [
                          { required: true, message: "请输入电柜编号！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="厂家">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.companyName}</span>
                    ) : (
                      getFieldDecorator("companyName", {
                        initialValue: initialValues.companyName,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="类型">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.chargeType === 1
                          ? "充电柜"
                          : initialValues.chargeType === 2
                          ? "换电柜"
                          : initialValues.chargeType === 3
                          ? "快充点"
                          : ""}
                      </span>
                    ) : (
                      getFieldDecorator("chargeType", {
                        initialValue: initialValues.chargeType,
                        rules: [{ required: true, message: "请选择类型！" }],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                        >
                          <Option value={1}>充电柜</Option>
                          <Option value={2}>换电柜</Option>
                          <Option value={3}>快充点</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电柜图片">
                    {getFieldDecorator("img", {
                      initialValue: initialValues.img || undefined,
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        disabled={this.state.modalType === 3 ? true : false}
                        name="uploadFile"
                        action={_imageUpload}
                        listType="picture-card"
                        fileList={fileList}
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                        onChange={this.handleChange.bind(this)}
                        beforeUpload={this.handleBeforeUpload.bind(this)}
                        data={{
                          scene: "active",
                        }}
                      >
                        {fileList.length >= 5 ? null : (
                          <div>
                            <Icon type="plus" style={{ fontSize: "28px" }} />
                            <p>图片上传</p>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="开始营业时间">
                      {getFieldDecorator("openTime1", {
                        initialValue: initialValues.openTime
                          ? moment(
                              initialValues.openTime.split("-")[0],
                              "HH:mm"
                            )
                          : undefined,
                        rules: [
                          { required: true, message: "请选择开始营业时间！" },
                        ],
                      })(<TimePicker format="HH:mm" />)}
                    </Form.Item>
                    <Form.Item label="结束营业时间">
                      {getFieldDecorator("openTime2", {
                        initialValue: initialValues.openTime
                          ? moment(
                              initialValues.openTime.split("-")[1],
                              "HH:mm"
                            )
                          : undefined,
                        rules: [
                          { required: true, message: "请选择结束营业时间！" },
                        ],
                      })(<TimePicker format="HH:mm" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="联系人">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.name}</span>
                      ) : (
                        getFieldDecorator("name", {
                          initialValue: initialValues.name,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="联系号码">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.phone}</span>
                      ) : (
                        getFieldDecorator("phone", {
                          initialValue: initialValues.phone,
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                              message: "请输入正确的手机号格式",
                            },
                            { required: true, message: "请输入手机号码！" },
                          ],
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="商家电价（元）">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.agentPrice}</span>
                      ) : (
                        getFieldDecorator("agentPrice", {
                          initialValue: initialValues.agentPrice,
                          rules: [
                            {
                              required: true,
                              message: "请输入商家电价（元）！",
                            },
                          ],
                        })(
                          <Input
                            allowClear
                            placeholder="请输入"
                            suffix="kW·h"
                          />
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="用户电价（元）">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.userPrice}</span>
                      ) : (
                        getFieldDecorator("userPrice", {
                          initialValue: initialValues.userPrice,
                          rules: [
                            {
                              required: true,
                              message: "请输入用户电价（元）！",
                            },
                          ],
                        })(
                          <Input
                            allowClear
                            placeholder="请输入"
                            suffix="kW·h"
                          />
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="成本电价（元）">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.price}</span>
                      ) : (
                        getFieldDecorator("price", {
                          initialValue: initialValues.price,
                        })(
                          <Input
                            allowClear
                            placeholder="请输入"
                            suffix="kW·h"
                          />
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.props.form.getFieldValue("chargeType") === 3 && (
                  <Col span={24}>
                    <Form.Item label="电价说明">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.explain}</span>
                      ) : (
                        getFieldDecorator("explain", {
                          initialValue: initialValues.explain,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.modalType === 3 && (
                  <Col span={24}>
                    <Form.Item label="站点管理员">
                      {initialValues.detailStr}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="地区">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.areaName}</span>
                    ) : (
                      getFieldDecorator("areaList", {
                        initialValue: initialValues.areaList,
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: "请输入地区！",
                          },
                        ],
                      })(
                        <AreaCascader
                          changeOnSelect
                          placeholder="城市"
                          style={{ width: "100%" }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="详情地址">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.address}</span>
                    ) : (
                      getFieldDecorator("address", {
                        initialValue: initialValues.address,
                        rules: [
                          { required: true, message: "请输入详情地址！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="坐标">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.coordinate}</span>
                    ) : (
                      getFieldDecorator("coordinate", {
                        initialValue: initialValues.coordinate,
                        rules: [{ required: true, message: "请选择坐标！" }],
                      })(
                        <div>
                          <Input
                            disabled
                            value={
                              this.state.markerPosition.longitude +
                              "，" +
                              this.state.markerPosition.latitude
                            }
                          />
                          <p className="top_map_p">
                            当前地址：
                            {this.state.addressName}
                          </p>

                          <div style={{ width: "100%", height: 300 }}>
                            <Map
                              amapkey={amapkey}
                              zoom={8}
                              events={{
                                click: (e) => {
                                  this.setState({
                                    markerPosition: {
                                      longitude: e.lnglat.getLng(),
                                      latitude: e.lnglat.getLat(),
                                    },
                                  });
                                  this.handleClickAmapChange(
                                    e.lnglat.getLng(),
                                    e.lnglat.getLat()
                                  );
                                },
                              }}
                              center={this.state.mapCenter}
                            >
                              <Marker position={this.state.markerPosition} />
                              <AutoComplete
                                style={{
                                  width: 300,
                                  position: "absolute",
                                  left: "10px",
                                  top: "10px",
                                }}
                                placeholder="搜索"
                                optionLabelProp="value"
                                dataSource={autoList.map((item, index) => (
                                  <AutoComplete.Option
                                    key={index}
                                    value={item.name}
                                    position={item.location}
                                  >
                                    {item.name}
                                    <span
                                      style={{ color: "rgba(0, 0, 0, 0.3)" }}
                                    >
                                      {item.district}
                                    </span>
                                  </AutoComplete.Option>
                                ))}
                                onSelect={(value, option) => {
                                  const { position = {} } = option.props;
                                  this.setState({
                                    mapCenter: {
                                      longitude: position.lng,
                                      latitude: position.lat,
                                    },
                                    markerPosition: {
                                      longitude: position.lng,
                                      latitude: position.lat,
                                    },
                                  });
                                  this.handleClickAmapChange(
                                    position.lng,
                                    position.lat
                                  );
                                }}
                                onSearch={async (keywords) => {
                                  window.AMap.plugin(
                                    "AMap.Autocomplete",
                                    async () => {
                                      let autoComplete =
                                        await new window.AMap.Autocomplete({
                                          city: "全国",
                                        });
                                      autoComplete.search(
                                        keywords,
                                        (status, result) => {
                                          if (status == "complete") {
                                            let { tips = [] } = result || {};
                                            tips = tips.filter(
                                              (item) => item.location
                                            );
                                            this.setState({
                                              autoList: tips || [],
                                            });
                                          } else {
                                            this.setState({ autoList: [] });
                                          }
                                        }
                                      );
                                    }
                                  );
                                }}
                              />
                            </Map>
                          </div>
                        </div>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getChargeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  //压缩图片
  async handleBeforeUpload(file) {
    const res = await zipImg({
      file,
    });
    return res;
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取坐标的详细地址
  handleClickAmapChange(longitude, latitude) {
    let location = longitude + "," + latitude;
    let params = {
      output: "json",
      location: location,
      key: "1607b8afa5a91ccba6d84b8cd4cd5a24",
      radius: 1000,
      extensions: "base",
    };
    _mapGD({ params: params })
      .then((res) => {
        this.setState({
          addressName: res.data.regeocode.formatted_address,
          province: res.data.regeocode.addressComponent.province,
          city: res.data.regeocode.addressComponent.city,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取电柜列表
  getChargeList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _batteryChargeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            chargeList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getChargeList(curPage, pageSize, this.state.queryFormValues);
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    fileList.map((item, index) => {
      if (item.status === "error") {
        fileList.splice(index, 1);
      }
      if (item.response) {
        if (item.response.code !== 200) {
          message.warning(item.response.message);
          fileList.splice(index, 1);
        }
      }
      return item;
    });
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateChargeStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getChargeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (typeof values.statusSearch === "number") {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.chargeStrSearch) {
        values.chargeStr = values.chargeStrSearch;
        delete values.chargeStrSearch;
      }
      if (values.chargeTypeSearch) {
        values.chargeType = values.chargeTypeSearch;
        delete values.chargeTypeSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.areaListSearch) {
        values.province = values.areaListSearch[0]
          ? values.areaListSearch[0]
          : null;
        values.city = values.areaListSearch[1]
          ? values.areaListSearch[1]
          : null;
        values.county = values.areaListSearch[2]
          ? values.areaListSearch[2]
          : null;
        values.street = values.areaListSearch[3]
          ? values.areaListSearch[3]
          : null;
        delete values.areaListSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getChargeList(1, this.state.pageSize, values);
    });
  };

  //获取工地详情
  getSiteDetail(id, type) {
    let params = { id };
    _chargeDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let longitude, latitude;
          let coordinate = res.data.data.coordinate;
          let { province, city, county, street } = res.data.data || {};
          longitude = coordinate ? coordinate.split(",")[0] : 0;
          latitude = coordinate ? coordinate.split(",")[1] : 0;
          this.setState({
            id,
            initialValues: {
              ...res.data.data,
              areaList: [province, city, county, street].filter((item) => item),
            },
            mapCenter: { longitude, latitude },
            markerPosition: { longitude, latitude },
          });
          let thumbImages = [];
          res.data.data.thumbImages &&
            res.data.data.thumbImages.map((item, index) => {
              thumbImages.push({
                uid: index,
                name: item.id,
                status: "done",
                url: item.path,
              });
              return item;
            });
          this.updateFileList(thumbImages);
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.setState(
      {
        modalType,
        visible: true,
      },
      () => {
        if (this.props.form) {
          this.props.form.resetFields();
          let type = localStorage.getItem("type");
          let fid = localStorage.getItem("fid");
          if (modalType == 1) {
            this.props.form.setFieldsValue({
              fid: type === "0" ? "" : Number(fid),
            });
          } else if (modalType == 2) {
            this.props.form.setFieldsValue({
              fid: this.state.initialValues.fid,
            });
          }
        }
      }
    );
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.statusSearch;
      delete values.chargeStrSearch;
      delete values.chargeTypeSearch;
      delete values.fidSearch;
      delete values.areaListSearch;
      if (values.img && values.img.constructor === Array) {
        let img = [];
        values.img.map((item, index) => {
          if (item.response) {
            img.push(item.response.data.id);
          }
          return item;
        });
        values.img = img.join(",");
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (
        this.state.markerPosition.longitude &&
        this.state.markerPosition.latitude
      ) {
        values.coordinate =
          this.state.markerPosition.longitude +
          "," +
          this.state.markerPosition.latitude;
      }
      if (values.openTime1 && values.openTime2) {
        values.openTime =
          moment(values.openTime1).format("HH:mm") +
          "-" +
          moment(values.openTime2).format("HH:mm");
        delete values.openTime1;
        delete values.openTime2;
      }
      console.log(values);
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveBatteryCharge(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (typeof values.statusSearch === "number") {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.chargeStrSearch) {
        values.chargeStr = values.chargeStrSearch;
        delete values.chargeStrSearch;
      }
      if (values.chargeTypeSearch) {
        values.chargeType = values.chargeTypeSearch;
        delete values.chargeTypeSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.areaListSearch) {
        values.province = values.areaListSearch[0]
          ? values.areaListSearch[0]
          : null;
        values.city = values.areaListSearch[1]
          ? values.areaListSearch[1]
          : null;
        values.county = values.areaListSearch[2]
          ? values.areaListSearch[2]
          : null;
        values.street = values.areaListSearch[3]
          ? values.areaListSearch[3]
          : null;
        delete values.areaListSearch;
      }
      let params = {
        ...values,
      };
      _exportBatteryChargeStatic(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.updateFileList([]);
    this.setState({
      sid: null,
      initialValues: {},
      modalType: null,
      visible: false,
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
    });
  }
}

ChargeBattery = Form.create({})(ChargeBattery);
export { ChargeBattery };
