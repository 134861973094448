import React, { useEffect } from "react";
import useList from "@/hooks/list";
import { Form, Button, Select, Row, Col } from "antd";
const { Option } = Select;

var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const { franchiseeList, getFranchiseeList } = useList();
  const userType = localStorage.getItem("type");

  useEffect(() => {
    getFranchiseeList({ ftype: 1 });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      search(values);
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          {userType == 9 || userType == 10 ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="公司名称">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="类型">
              {getFieldDecorator("type")(
                <Select placeholder="请选择" allowClear>
                  <Option value={""}>全部</Option>
                  <Option value={1}>收入</Option>
                  <Option value={2}>支出</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={4} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
