import React from 'react';
import OperationData from "./operationData"
import OperationSaas from "./operationSaas"
import OperationBattery from "./operationBattery"

const Index = (props)=> {
    const {value} = props
    return (<>
        <div style={{display: value == 1 ? 'block' : 'none'}}>
            <OperationData />
        </div>
        <div style={{display: value == 2 ? 'block' : 'none'}}>
            <OperationSaas />
        </div>
        <div style={{display: value == 3 ? 'block' : 'none'}}>
            <OperationBattery />
        </div>
    </>)
}

export default Index