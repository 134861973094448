import React, { useEffect } from "react";
import useHandle from "@/hooks/handle";
import { _exportPackageList } from "@/statics/js/api.js";
import FranchiseeSelect from "@/components/franchiseeSelect";
import BikeModelSelect from "@/components/bikeModelSelect";
import BatteryModelSelect from "@/components/batteryModelSelect";
import CityCascader from "@/components/cityCascader";
import { Form, Button, Input, Select, Row, Col } from "antd";
const { Option } = Select;

const Index = Form.create({})((props) => {
  const { search, form } = props;
  const { getFieldDecorator, getFieldsValue } = form;
  const userType = localStorage.getItem("type");
  const {
    handleDownload,
    loading: exportLoading
  } = useHandle()
  useEffect(() => {
    
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      let query = {...values}
      if(query.areaList && query.areaList.length){
        query.sprovince = query.areaList[0] ? query.areaList[0] : null;
        query.scity = query.areaList[1]
          ? query.areaList[1]
          : query.areaList[0];
        query.scounty = query.areaList[2] ? query.areaList[2] : null;
        query.sstreet = query.areaList[3] ? query.areaList[3] : null;
        delete query.areaList;
      }else{
        query = {
          ...query,
          sprovince: undefined,
          scity: undefined,
          scounty: undefined,
          sstreet: undefined,
        }
      }
      search(query);
    });
  };

  const handleExportChange = async ()=>{
    let values = getFieldsValue()
    if(values.areaList && values.areaList.length){
      values.sprovince = values.areaList[0] ? values.areaList[0] : null;
      values.scity = values.areaList[1]
        ? values.areaList[1]
        : values.areaList[0];
      values.scounty = values.areaList[2] ? values.areaList[2] : null;
      values.sstreet = values.areaList[3] ? values.areaList[3] : null;
      delete values.areaList;
    }else{
      values = {
        ...values,
        sprovince: undefined,
        scity: undefined,
        scounty: undefined,
        sstreet: undefined,
      }
    }
    handleDownload({
      action: _exportPackageList,
      query: values
    })
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐名称">
              {getFieldDecorator("pname")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {
            userType != 0 ? null :
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <FranchiseeSelect />
                )}
              </Form.Item>
            </Col>
          }
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <CityCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆型号">
              {getFieldDecorator("bikeModelId")(
                <BikeModelSelect />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池型号">
              {getFieldDecorator("dcModelId")(
                <BatteryModelSelect />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("pstatus")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">启用</Option>
                  <Option value="0">禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐类型">
              {getFieldDecorator("ptype")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>租车</Option>
                  <Option value={2}>销售</Option>
                  <Option value={3}>以租代售</Option>
                  <Option value={4}>连续租赁</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="合同类型">
              {getFieldDecorator("contractType")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">自营</Option>
                  <Option value="2">非自营</Option>
                  <Option value="3">定制</Option>
                  <Option value="4">钢铁侠</Option>
                  <Option value="5">东北专用</Option>
                  <Option value="6">三个月合同</Option>
                  <Option value="7">销售合同(花呗分期)</Option>
                  <Option value="8">销售合同(全款)</Option>
                  <Option value="9">销售合同(合约购)</Option>
                  <Option value="10">四川威信合同</Option>
                  <Option value="11">租房合同</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否检修">
              {getFieldDecorator("needRepair")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否平台套餐">
              {getFieldDecorator("self")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否首页显示">
              {getFieldDecorator("showStatus")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="检修类型">
              {getFieldDecorator("repairType")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>原站点检修</Option>
                  <Option value={2}>退车点检修</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                loading={exportLoading}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
})

export default Index;
