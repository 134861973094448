import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  _savePackageAuth,
  _packageAuthDetailTransfe,
  _packageAuthDetailReturn,
  _packageAuthDetail,
} from "@/statics/js/api.js";
import { Tabs, Modal, message, Tree } from "antd";
const { TabPane } = Tabs;
const { TreeNode } = Tree;

const TreeDom = forwardRef((props, ref) => {
  const [list, setList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const init = ({ checkedKeys, expandedKeys, list }) => {
    setList(list);
    setCheckedKeys(checkedKeys);
    setExpandedKeys(expandedKeys);
  };
  const getCheckedKeys = () => {
    return checkedKeys;
  };
  useImperativeHandle(
    ref,
    () => ({
      init,
      getCheckedKeys,
    }),
    [init, getCheckedKeys]
  );
  return (
    <Tree
      checkable
      onCheck={(keys) => setCheckedKeys(keys)}
      onExpand={(keys) => {
        setExpandedKeys(keys);
        setAutoExpandParent(false);
      }}
      checkedKeys={checkedKeys}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
    >
      {list.map((item) => {
        if (item.children && item.children.length) {
          return (
            <TreeNode
              className="siteBindTree"
              title={item.title}
              key={item.key}
              dataRef={item}
            >
              {item.children.map((item1) => (
                <TreeNode
                  treeDefaultExpandedKeys={true}
                  key={item1.key}
                  {...item1}
                />
              ))}
            </TreeNode>
          );
        }
        return (
          <TreeNode treeDefaultExpandedKeys={true} key={item.key} {...item} />
        );
      })}
    </Tree>
  );
});

const Index = forwardRef((props, ref) => {
  const { onFinish = () => {} } = props;
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transferType, setTransferType] = useState(0);
  const [returnType, setReturnType] = useState(0);
  const treeRef1 = useRef();
  const treeRef2 = useRef();
  const treeRef3 = useRef();
  const open = async ({ id, transferType,returnType }) => {
    console.log(transferType,returnType)
    setVisible(true);
    setId(id);
    setTransferType(transferType);
    setReturnType(returnType);
    const res1 = await getList(_packageAuthDetail, { id });
    treeRef1.current.init({ ...res1 });
    if (returnType == 2) {
      const res2 = await getList(_packageAuthDetailReturn, { id });
      treeRef2.current.init({ ...res2 });
    }
    if (transferType == 2) {
      const res3 = await getList(_packageAuthDetailTransfe, { id });
      treeRef3.current.init({ ...res3 });
    }
  };
  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open]
  );
  const getList = async (action, { id }) => {
    const params = { id };
    const res = await action(params);
    const { code, data } = res.data || {};
    if (code !== 200) {
      return {
        expandedKeys: [],
        list: [],
        checkedKeys: [],
      };
    }
    let expandedKeys = [];
    let menusList = data.flist || [];
    menusList = menusList.map((item, index) => {
      expandedKeys.push(item.key);
      if (item.children) {
        item.children.map((item1, index1) => {
          expandedKeys.push(item1.key);
          delete item1.children;
          return item1;
        });
      } else {
        delete item.children;
      }
      return item;
    });
    return {
      expandedKeys: expandedKeys,
      list: menusList,
      checkedKeys: data.authIds,
    };
  };
  const handleOk = async () => {
    const keys1 = treeRef1.current.getCheckedKeys();
    const keys2 = treeRef2.current ? treeRef2.current.getCheckedKeys() : [];
    const keys3 = treeRef3.current ? treeRef3.current.getCheckedKeys() : [];
    const params = {
      id,
      authIds: [...keys1, ...keys2, ...keys3],
    };
    setConfirmLoading(true);
    const res = await _savePackageAuth(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.error(msg);
      return;
    }
    setVisible(false);
    onFinish();
  };
  return (
    <Modal
      width={800}
      title="绑定站点"
      visible={visible}
      destroyOnClose
      maskClosable={false}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      confirmLoading={confirmLoading}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="租车站点" key="1">
          <TreeDom ref={treeRef1} />
        </TabPane>
        {/* <TabPane disabled={disabled} forceRender tab="换车站点" key="2">
            <TreeDom ref={treeRef2} />
          </TabPane> */}
        {returnType == 2 && (
          <TabPane forceRender tab="退车站点" key="2">
            <TreeDom ref={treeRef2} />
          </TabPane>
        )}
        {transferType == 2 && (
          <TabPane forceRender tab="换车站点" key="3">
            <TreeDom ref={treeRef3} />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
});

export default Index;
