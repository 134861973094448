import React from "react";
import { useEffect, useState } from "react";
import useList from "@/hooks/list";
import { _bindUser, _imageUpload, _kaUsersList } from "@/statics/js/api.js";
import {
  Form,
  Input,
  Modal,
  Select,
  Row,
  Col,
  message,
  Upload,
  Icon,
} from "antd";
const { Option } = Select;

var BindUser = (props) => {
  const { visible, close, form, id, flag, done } = props;
  const { getFieldDecorator } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [initialValues, setInitialValue] = useState({
    fid: "",
    kid: "",
    img: "",
    remark: "",
  });
  const limit = {
    1: 6,
    2: 3,
  };
  const { franchiseeList, getFranchiseeList } = useList();
  const userType = localStorage.getItem("type");
  const fid = localStorage.getItem("fid");

  useEffect(() => {
    if (visible) {
      getFranchiseeList({ ftype: 1 });
      form.resetFields();
      let id = "";
      if (userType == 9 || userType == 10) {
        id = Number(fid);
        getUsersList(id);
      }
      setInitialValue({
        fid: id,
        kid: "",
        img: "",
        remark: "",
      });
      setFileList([]);
    }
  }, [visible]);

  const handleFranchiseeChange = async (value) => {
    setUserList([]);
    form.setFieldsValue({ kid: "" });
    if (value) {
      getUsersList(value);
    }
  };
  //用户列表
  const getUsersList = async (id) => {
    let params = {
      fid: id,
      curPage: 1,
      pageSize: 999,
    };
    const res = await _kaUsersList(params);
    const { code, data } = res.data || {};
    if (code !== 200) return;
    let list = data.rows || [];
    list.forEach((item) => {
      item.text = item.name + "（" + item.tel + "）";
    });
    setUserList(list);
  };
  // 上传回调
  const handleUploadChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    setFileList(fileList);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  // 关闭
  const handleCancel = async () => {
    close();
  };
  // 提交
  const handleOk = async () => {
    form.validateFields((err, values) => {
      if (err) return;
      const params = {
        bikeId: id,
        flag,
        ...values,
        img: values.img
          .map((item) => (item.response && item.response.data.id) || item.name)
          .join(","),
      };
      handleSave(params);
    });
  };
  // 保存
  const handleSave = async (params) => {
    setConfirmLoading(true);
    const res = await _bindUser(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };

  return (
    <div>
      <Modal
        title="绑定用户"
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item label="公司名称">
                {getFieldDecorator("fid", {
                  initialValue: initialValues.fid,
                  rules: [{ required: true, message: "请选择公司名称" }],
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    disabled={userType == 9 || userType == 10}
                    onChange={handleFranchiseeChange}
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="用户">
                {getFieldDecorator("kid", {
                  initialValue: initialValues.kid,
                  rules: [{ required: true, message: "请选择用户" }],
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {userList.length &&
                      userList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.text}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="设备照片">
                {getFieldDecorator("img", {
                  initialValue: initialValues.img || [],
                  getValueFromEvent: normFile,
                  rules: [
                    {
                      required: true,
                      message: "请上传1到" + limit[flag] + "张设备照片",
                      type: "array",
                      min: 1,
                      max: limit[flag],
                    },
                  ],
                })(
                  <Upload
                    name="uploadFile"
                    action={_imageUpload}
                    listType="picture-card"
                    fileList={fileList}
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    onChange={handleUploadChange.bind(this)}
                    data={{
                      scene: "active",
                    }}
                  >
                    {fileList.length >= limit[flag] ? null : (
                      <div>
                        <Icon type="plus" style={{ fontSize: "28px" }} />
                        <p>上传图片</p>
                      </div>
                    )}
                  </Upload>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="备注">
                {getFieldDecorator("remark", {
                  initialValue: initialValues.remark,
                })(<Input placeholder="备注" />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

BindUser = Form.create({})(BindUser);
export default BindUser;
