import React from "react";
import { Form, Button, Input, Row, Col } from "antd";
import useHandle from "@/hooks/handle";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";
import { _exportTjConfigList } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";

var SearchBox = (props) => {
  const { search, form } = props;
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const userType = localStorage.getItem("type");
  const { loading: exportLoading, handleDownload } = useHandle();
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      search(values);
    });
  };
  const handleExport = async () => {
    const values = getFieldsValue();
    handleDownload({
      action: _exportTjConfigList,
      query: {
        ...values,
      },
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          {userType === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <FranchiseeSelect
                    onChange={() => {
                      setFieldsValue({ sid: "" });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <SiteSelect fid={getFieldValue("fid")} />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="推荐人姓名/手机号">
              {getFieldDecorator("queryString")(<Input placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={4} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={handleExport}
                style={{ marginLeft: 10 }}
                loading={exportLoading}
                disabled={!getJurisdiction(1092)}
                title={getJurisdiction(1092) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
