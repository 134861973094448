import React, { Component } from 'react'
import {
  Button,
  Table,
  Form,
  DatePicker,
  Select,
  message,
  Row,
  Col,
} from 'antd'
import {
  _profitStaticList,
  _franchiseeList,
  _exportProfitStatic,
} from '@/statics/js/api'
import moment from 'moment'

import { getJurisdiction } from '@/statics/js/jurisdiction'
const Option = Select.Option
const MonthPicker = DatePicker.MonthPicker
class LeaseDistributionalReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      profitStaticList: [], //套餐订单列表
      franchiseeList: [], //代理商列表
    }

    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '订单状态',
        dataIndex: 'orderStatus',
      },
      {
        title: '推荐人',
        align: 'right',
        dataIndex: 'tjMobile',
      },
      {
        title: '支付宝流水号',
        align: 'right',
        width: 160,
        dataIndex: 'tradeNo',
      },
      {
        title: '车辆二维码',
        align: 'right',
        dataIndex: 'vin',
      },
      {
        title: '电池编号',
        align: 'right',
        dataIndex: 'imei',
      },
      {
        title: '套餐名称',
        dataIndex: 'pname',
      },
      {
        title: '订单开始时间',
        width: 120,
        dataIndex: 'orderStartTime',
      },
      {
        title: '套餐金额',
        align: 'right',
        dataIndex: 'pmoney',
      },
      {
        title: '是否买免赔服务',
        dataIndex: 'isInsure',
      },
      {
        title: '站点名称',
        dataIndex: 'sname',
      },
      {
        title: '代理商名称',
        dataIndex: 'fname',
      },
      {
        title: '最近实付时间',
        width: 120,
        dataIndex: 'lastPayTime',
      },
      {
        title: '实付金额',
        align: 'right',
        width: 100,
        dataIndex: 'payMoney',
      },
      {
        title: '代理商收款',
        align: 'right',
        width: 110,
        dataIndex: 'agentMoney',
      },
      {
        title: '代理商收款（免赔）',
        align: 'right',
        width: 110,
        dataIndex: 'agentInsureMoney',
      },
      {
        title: '车辆租金',
        align: 'right',
        width: 100,
        dataIndex: 'bikeMoney',
      },
      {
        title: '电池租金',
        align: 'right',
        width: 100,
        dataIndex: 'batteryMoney',
      },
      {
        title: '平台租金',
        align: 'right',
        width: 100,
        dataIndex: 'platformMoney',
      },
      {
        title: '平台免赔服务费',
        align: 'right',
        width: 110,
        dataIndex: 'platformInsureMoney',
      },
      {
        title: '是否平台车辆',
        width: 120,
        dataIndex: 'isBelong',
      },
    ]
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="选择月份">
              {getFieldDecorator('staticMonth')(
                <MonthPicker
                  disabledDate={this.disabledDate.bind(this)}
                  placeholder="选择月份"
                />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem('type') === '0' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator('fid')(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(832)}
                title={getJurisdiction(832) ? '' : '暂无操作权限！'}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const { totalCount, curPage, pageSize, profitStaticList } = this.state
    return (
      <div>
        {getJurisdiction(831) ? (
          <div className="LeaseDistributionalReport">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={profitStaticList}
                scroll={{ x: 2400, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getFranchiseeList()
    this.getProfitStaticList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取套餐订单列表
  getProfitStaticList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    }
    _profitStaticList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            profitStaticList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getProfitStaticList(curPage, pageSize, this.state.queryFormValues)
  }

  //不可选择时间
  disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.staticMonth) {
        values.staticMonth = moment(values.staticMonth).format('YYYY-MM')
      }
      this.setState({
        queryFormValues: values,
      })
      this.getProfitStaticList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.staticMonth) {
        values.staticMonth = moment(values.staticMonth).format('YYYY-MM')
      }
      let params = {
        ...values,
      }
      _exportProfitStatic(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}

LeaseDistributionalReport = Form.create({})(LeaseDistributionalReport)
export { LeaseDistributionalReport }
