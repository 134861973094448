import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import BikeModelSelect from '@/components/bikeModelSelect'
import FranchiseeSelect from '@/components/franchiseeSelect'
import SiteSelect from '@/components/siteSelect'
import moment from 'moment';
import {
    _modelSelfBikeDataList,
    _modelSelfBikeDataExport,
    _siteSelfBikeDataList,
    _siteSelfBikeDataExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Select,
    Row,
    Col,
    Tooltip,
    Icon,
    DatePicker
} from "antd";
const { Option } = Select;
const {WeekPicker} = DatePicker

const columns1 = [
    {
        title: "车辆型号",
        dataIndex: "modelName",
    },
    {
        title: ()=>(
            <>
                <span>在租车辆</span>&nbsp;
                <Tooltip placement="top" title="绑定订单且未挂起，维修中绑定订单不计算">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "rentBikeSize",
    },
    {
        title: "在租车辆环比",
        dataIndex: "rentBikeSizeScale",
    },
    {
        title: "在租车辆同比",
        dataIndex: "rentBikeSizeYoy",
    },
    {
        title: ()=>(
            <>
                <span>销售车辆</span>&nbsp;
                <Tooltip placement="top" title="销售时间为时间维度范围内的数据">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "sellBikeSize",
    },
    {
        title: "销售车辆环比",
        dataIndex: "sellBikeSizeScale",
    },
    {
        title: ()=>(
            <>
                <span>新增车辆</span>&nbsp;
                <Tooltip placement="top" title="添加时间为时间维度内的车辆数">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "addBikeSize",
    },
    {
        title: "新增车辆环比",
        dataIndex: "addBikeSizeScale",
    },
    {
        title: "车辆总计",
        dataIndex: "bikeSum",
    },
    {
        title: "车辆总计环比",
        dataIndex: "bikeSumScale",
    },
    {
        title: ()=>(
            <>
                <span>平均使用次数</span>&nbsp;
                <Tooltip placement="top" title="系统内使用时长/30">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "useTime",
    },
]

const columns2 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "站点",
        dataIndex: "sname",
    },
    {
        title: ()=>(
            <>
                <span>保有车辆</span>&nbsp;
                <Tooltip placement="top" title="管理状态为启用、待确认、维修中的车辆数">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "haveBikeSize",
    },
    {
        title: "保有车辆环比",
        dataIndex: "haveBikeSizeScale",
    },
    {
        title: ()=>(
            <>
                <span>运营效率</span>&nbsp;
                <Tooltip placement="top" title="订单数（线上+线下处理订单为订单延时+有金额结束订单）/时间段内的车辆数（保有车辆）">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "operationalEfficiency",
    },
    {
        title: "运营效率环比",
        dataIndex: "operationalEfficiencyScale",
    },
    {
        title: "运营效率同比",
        dataIndex: "operationalEfficiencyYoy",
    },
]

const Index1 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        handleSearch()
    },[])
    
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type} = values
            let time = {}
            if(type == 0){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type} = values
        let time = {}
        if(type == 0){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }
        handleDownload({
            action: _modelSelfBikeDataExport,
            query: {
                ...values,
                groupType: 1,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 0
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>月报表</Option>
                                <Option value={0}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="车辆型号">
                        {getFieldDecorator("modelId")(
                            <BikeModelSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_modelSelfBikeDataList}
            options={{
                columns: columns1,
            }}
            params={{
                groupType: 1,
            }}
        />
    </>)
})

const Index2 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,setFieldsValue,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type} = values
            let time = {}
            if(type == 0){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type} = values
        let time = {}
        if(type == 0){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }
        handleDownload({
            action: _siteSelfBikeDataExport,
            query: {
                ...values,
                groupType: 0,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={4} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 0
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>月报表</Option>
                                <Option value={0}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 0 && 
                    <Col lg={4} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                }
                {
                    userType == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect onChange={()=>{setFieldsValue({ sid: "" })}} />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={5} md={12} sm={24}>
                    <Form.Item label="站点">
                        {getFieldDecorator("sid")(
                            <SiteSelect fid={getFieldValue('fid')} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={3} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_siteSelfBikeDataList}
            options={{
                columns: columns2,
            }}
            params={{
                groupType: 0,
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index = ()=>{
    return (<>
        <Index1 />
        <br/>
        <Index2 />
    </>)
}

export default Index