import React, { 
    useEffect,
    useRef,
    useState,
} from 'react';
import LineCard from './components/LineCard'
import moment from "moment";
import {
    _historicalBatInfo
} from "@/statics/js/api";
import {
    Form,
    Button,
    Row,
    Col,
    DatePicker,
    message,
} from "antd";
const { RangePicker } = DatePicker;

let timer = null
var Index = (props)=> {
    const {form,id} = props
    const { getFieldDecorator } = form;
    const lineRef = useRef()
    const [empty,setEmpty] = useState(true)
    const [filter,setFilter] = useState({
        startTime: moment(moment().format('YYYY-MM-DD') + ' 00:00:00', "YYYY-MM-DD HH:mm:ss"),
        endTime: moment(moment().format('YYYY-MM-DD') + ' 23:59:59', "YYYY-MM-DD HH:mm:ss")
    })
    useEffect(() => {
        autoDrive()
        return ()=>{
            clearTimeout(timer)
            timer = null
        }
    },[]);
    const autoDrive = async ()=>{
        clearTimeout(timer)
        timer = null
        await initData()
        timer = setTimeout(()=>{
            if(timer){
                autoDrive()
            }
        },10000)
    }
    const handleSearch = async (e)=>{
        e.preventDefault();
        form.validateFields((err, values) => {
            if(err){
                message.warning('请选择日期范围');
                return
            }
            initData()
        })
    }

    const handleReset = async (e)=>{
        form.setFieldsValue({
            date: [
                moment(moment().format('YYYY-MM-DD') + ' 00:00:00', "YYYY-MM-DD HH:mm:ss"),
                moment(moment().format('YYYY-MM-DD') + ' 23:59:59', "YYYY-MM-DD HH:mm:ss")
            ]
        });
        initData()
    }

    const initData = async () => {
        const values = form.getFieldsValue() || {}
        let [startTime,endTime] = values.date || []
        setFilter({
            ...filter,
            startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        })
        await loadData({
            startTime,
            endTime,
        })
    }
    // 加载数据
    const loadData = async (query={}) => {
        const {
            startTime = '',
            endTime = '',
        } = query
        const params = {
            id,
            startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        }
        const res = await _historicalBatInfo(params)
        const {data,code,message:msg} = res.data
        if(code !== 200){
            setEmpty(msg || '暂无数据')
            return
        }
        if(!data){
            setEmpty('暂无数据')
            return
        }
        const {batteryHisVOlist=[]} = data || {}
        if(!batteryHisVOlist.length){
            setEmpty('暂无数据')
            return
        }
        setEmpty('')
        let TimeXScale = []
        let voltage = []
        let current = []
        let remain = []
        batteryHisVOlist.map((item)=>{
            TimeXScale.push(item.time)
            voltage.push([item.time,item.voltage || 0])
            current.push([item.time,item.current || 0])
            remain.push([item.time,parseFloat(item.remain || 0)])
        })
        let minCC = getMin(current);
        let maxCC = getMax(current);
        if (minCC < 0 ) minCC = -1*minCC;
        if (maxCC < 0 ) maxCC = -1*maxCC;
        let MaxCur = maxCC>minCC?maxCC:minCC;
        let config = {
            vMax: Number(((getMax(voltage)+20)).toFixed(0)),
            cMin: -Number((2*MaxCur).toFixed(0)),
            cMax: Number((2*MaxCur).toFixed(0)),
        }
        voltage = voltage.map(item=>{
            item[1] = Number((Number(item[1])).toFixed(0))
            return item
        })
        lineRef.current.init({
            TimeXScale,
            voltage,
            current,
            remain,
            config
        })
    }
    const getMin = (d)=>{
        let [_,min] = d.reduce((a,b)=>a[1]<b[1]?a:b)
        return min;
    }
    const getMax = (d)=>{
        let [_,max=0] = d.reduce((a,b)=>a[1]>b[1]?a:b)
        max = parseInt(max).toString();
        var a = parseInt(max.substring(0,1))+1;
        for (var i = 0; i < max.length-1; i++) {
            a = a*10;
        }
        return a;
    }

    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={8} md={8} sm={24}>
                    <Form.Item>
                    {
                        getFieldDecorator("date",{
                            initialValue: [filter.startTime,filter.endTime],
                            rules: [{ required: true, message: "请选择日期范围" }],
                            valuePropName: "defaultValue",
                        })(
                            <RangePicker
                                showTime={{ format: 'HH:mm:ss' }} 
                                format="YYYY-MM-DD HH:mm:ss" 
                                style={{width: '100%'}}
                            />
                        )
                    }
                    </Form.Item>
                </Col>
                <Col lg={4} md={8} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <br />
        <div style={{height: empty ? '0' : 'auto',overflow: 'hidden'}}>
           <LineCard ref={lineRef} />     
        </div>
        {
            empty ? <div style={{textAlign: 'center',padding: '60px 0'}}>{empty}</div> : null 
        }
    </>)
}

Index = Form.create({})(Index);
export default Index