import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Select,
  Modal,
  Form,
  Checkbox,
  Row,
  Col,
  message,
} from "antd";
import {
  _authGroupList,
  _franchiseeList,
  _siteBoxList,
  _siteUserList,
  _deleteSiteUser,
  _batchDeleteSiteUser,
  _updateSiteUserStatus,
  _siteUserDetail,
  _saveSiteUser,
  _genInviteCode,
  _batteryChargeList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import md5 from "md5";

const { Option } = Select;
const { TextArea } = Input;
const modalTitle = ["添加站点管理员", "编辑站点管理员"];
class SiteAdministrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商列表
      siteUserList: [], //站点列表
      siteList: [], //站点列表
      authGroupList: [], //角色列表
      queryFormValues: {
        status: 1,
      }, //筛选项
      id: "", //站点用户id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      initialValues: {}, //站点管理员详情
      visible: false, //Modal是否打开
      modalType: "", //1-添加，2-编辑
      selectType: "", //选中类型
      selectAuthGroup: "", //选中角色
      userType: localStorage.getItem("type"),
      userFid: localStorage.getItem("fid"),
      indeterminate: true,
      checkAll: false,
      cids_indeterminate: true,
      cids_checkAll: false,
      chargeList: [],
    };
    this.columns = [
      {
        title: "用户名",
        dataIndex: "username",
      },
      {
        title: "手机号",
        align: "right",
        dataIndex: "phone",
      },
      {
        title: "用户类型",
        width: 120,
        dataIndex: "typeName",
      },
      {
        title: "角色",
        dataIndex: "authGroupName",
      },
      {
        title: "描述说明",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(633)}
            title={getJurisdiction(633) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "更新时间",
        width: 120,
        dataIndex: "updateTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(632)}
              title={getJurisdiction(632) ? "" : "暂无操作权限！"}
              onClick={this.getSiteUserDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(631)}
              title={getJurisdiction(631) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteSiteUserChange.bind(this, text.id)}
            >
              删除
            </Button>
            {text.qrCode && <Divider type="vertical" />}
            {text.qrCode && (
              <Button
                type="link"
                onClick={this.modalViewQrcode.bind(
                  this,
                  text.qrCode,
                  text.username
                )}
              >
                二维码
              </Button>
            )}
          </span>
        ),
      },
    ];
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleSidsAllChange(e){
    const {checked} = e.target
    const {siteList} = this.state
    const checkedList = checked ? siteList : []
    this.props.form.setFieldsValue({
      sids: checkedList.map(item=>item.value),
    });
    this.setState({
      indeterminate: false,
      checkAll: checked,
    });
  }
  handleSidsChange(checkedList) {
    const {siteList} = this.state
    this.setState({
      indeterminate: !!checkedList.length && checkedList.length < siteList.length,
      checkAll: checkedList.length === siteList.length,
    });
  }
  handleCidsAllChange(e){
    const {checked} = e.target
    const {chargeList} = this.state
    const checkedList = checked ? chargeList : []
    this.props.form.setFieldsValue({
      cids: checkedList.map(item=>item.value),
    });
    this.setState({
      cids_indeterminate: false,
      cids_checkAll: checked,
    });
  }
  handleCidsChange(checkedList) {
    const {chargeList} = this.state
    this.setState({
      cids_indeterminate: !!checkedList.length && checkedList.length < chargeList.length,
      cids_checkAll: checkedList.length === chargeList.length,
    });
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户类型">
              {getFieldDecorator("typeSearch")(
                <Select allowClear placeholder="请选择">
                  <Option value="">全部</Option>
                  <Option value="0">管理员</Option>
                  <Option value="1">代理商</Option>
                  <Option value="2">站点用户</Option>
                  <Option value="8">二级代理商</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="角色">
              {getFieldDecorator("authGroupIdSearch")(
                <Select allowClear placeholder="请选择">
                  <Option value="">全部</Option>
                  {this.state.authGroupList.length &&
                    this.state.authGroupList.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="手机/用户名">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点名称/代理商">
              {getFieldDecorator("typeName")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      loading,
      selectedRowKeys,
      totalCount,
      initialValues,
      curPage,
      pageSize,
      siteUserList,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(630) ? (
          <div className="SiteAdministrator">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(632)}
                title={getJurisdiction(632) ? "" : "暂无操作权限！"}
                onClick={this.showModalAdd.bind(this, 1)}
              >
                添加站点管理员
              </Button>
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(631) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={siteUserList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="用户名">
                    {getFieldDecorator("username", {
                      initialValue: initialValues.username,
                      rules: [{ required: true, message: "请输入用户名！" }],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="手机号码">
                    {getFieldDecorator("phone", {
                      validateTrigger: "onBlur",
                      rules: [
                        {
                          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                          message: "请输入正确的手机号格式",
                        },
                        { required: true, message: "请输入手机号码！" },
                      ],
                      initialValue: initialValues.phone,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="登录密码">
                    {getFieldDecorator("password", {
                      validateTrigger: "onBlur",
                      rules: [
                        {
                          message: "请输入六位数密码",
                          pattern: /^\w{6}$/,
                        },
                      ],
                    })(
                      <Input
                        allowClear
                        style={{ width: "60%" }}
                        placeholder="默认密码123456"
                      />
                    )}
                    <span>
                      {this.state.modalType === 1 ? (
                        <span> 请输入用户密码</span>
                      ) : (
                        <span> 不修改则无需填写</span>
                      )}
                    </span>
                  </Form.Item>
                </Col>
                {this.state.userType === "0" && (
                  <Col span={24}>
                    <Form.Item label="用户类型">
                      {getFieldDecorator("type", {
                        initialValue:
                          initialValues.type === undefined ||
                          initialValues.type === null
                            ? undefined
                            : String(initialValues.type),
                        rules: [
                          { required: true, message: "请选择用户类型！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          onChange={this.handleSelectTypeChange.bind(this)}
                          allowClear
                        >
                          <Option value="0">管理员</Option>
                          <Option value="1">代理商</Option>
                          <Option value="2">站点用户</Option>
                          <Option value="8">二级代理商</Option>
                          <Option value="9">代理商（ka）</Option>
                          <Option value="10">二级代理商（ka）</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.userType === "1" && (
                  <Col span={24}>
                    <Form.Item label="用户类型">
                      {getFieldDecorator("type", {
                        initialValue:
                          initialValues.type === undefined ||
                          initialValues.type === null
                            ? undefined
                            : String(initialValues.type),
                        rules: [
                          { required: true, message: "请选择用户类型！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          onChange={this.handleSelectTypeChange.bind(this)}
                          allowClear
                        >
                          <Option value="2">站点用户</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.userType === "0" && (
                  <Col span={24}>
                    <Form.Item label="所属角色">
                      {getFieldDecorator("authGroupId", {
                        initialValue:
                          initialValues.authGroupId === undefined ||
                          initialValues.authGroupId === null
                            ? undefined
                            : initialValues.authGroupId,
                        rules: [
                          { required: true, message: "请选择所属角色！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          onChange={this.handleSelectAuthGroupChange.bind(this)}
                          allowClear
                        >
                          {this.state.authGroupList.length &&
                            this.state.authGroupList.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                      {this.state.modalType === 2 && (
                        <span>修改运营商角色将导致子角色权限清空</span>
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.userType === "1" && (
                  <Col span={24}>
                    <Form.Item label="所属角色">
                      {getFieldDecorator("authGroupId", {
                        initialValue:
                          initialValues.authGroupId === undefined ||
                          initialValues.authGroupId === null
                            ? undefined
                            : initialValues.authGroupId,
                        rules: [
                          { required: true, message: "请选择所属角色！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                        >
                          <Option value={9}>管理员</Option>
                        </Select>
                      )}
                      {this.state.modalType === 2 && (
                        <span>修改运营商角色将导致子角色权限清空</span>
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.userType === "0" &&
                  (this.state.selectType === "1" ||
                    this.state.selectType === "2" ||
                    this.state.selectType === "8" ||
                    this.state.selectType === "9" ||
                    this.state.selectType === "10") && (
                    <Col span={24}>
                      <Form.Item label="所属代理商">
                        {getFieldDecorator("fid", {
                          initialValue:
                            initialValues.franchisee === undefined ||
                            initialValues.franchisee === null
                              ? undefined
                              : initialValues.franchisee.fid,
                          rules: [
                            { required: true, message: "请选择所属代理商！" },
                          ],
                        })(
                          <Select
                            style={{ width: "100%" }}
                            placeholder="请选择"
                            allowClear
                            showSearch
                            onChange={this.handleFranchiseeChange.bind(this)}
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.state.franchiseeList.length &&
                              this.state.franchiseeList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.fid}>
                                    {item.fname}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                {this.state.selectType === "8" && (
                  <Col span={24}>
                    <Form.Item label="邀请码">
                      <Row gutter={8}>
                        <Col span={8}>
                          {getFieldDecorator("inviteCode", {
                            initialValue: initialValues.inviteCode
                              ? initialValues.inviteCode
                              : this.state.inviteCode,
                            rules: [
                              { required: true, message: "请生成邀请码！" },
                            ],
                          })(<Input disabled />)}
                        </Col>
                        {this.state.modalType === 1 && (
                          <Col span={8}>
                            <Button
                              onClick={() => {
                                _genInviteCode()
                                  .then((res) => {
                                    if (res.data.code === 200) {
                                      this.setState({
                                        inviteCode: res.data.data,
                                      });
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              disabled={!!this.state.inviteCode}
                              type="primary"
                            >
                              点击生成邀请码
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                  </Col>
                )}
                {(this.state.selectType === "2" ||
                  this.state.selectType === "9" ||
                  (this.state.selectType === "10" &&
                    this.state.selectAuthGroup !== 24) ||
                  (this.state.selectType === "8" &&
                    this.state.selectAuthGroup !== 24) ||
                  (this.state.selectAuthGroup === 24 &&
                    this.state.modalType === 2)) && (
                  <>
                    <Col span={24}>
                      <Form.Item label="管理站点">
                        {
                          this.state.siteList.length ? 
                          <Checkbox
                            indeterminate={this.state.indeterminate}
                            onChange={this.handleSidsAllChange.bind(this)}
                            checked={this.state.checkAll}
                          >
                            全选
                          </Checkbox> : null
                        }
                        {getFieldDecorator("sids", {
                          initialValue: initialValues.siteList,
                        })(
                          <Checkbox.Group 
                            options={this.state.siteList} 
                            onChange={this.handleSidsChange.bind(this)} 
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="充电点">
                        {
                          this.state.chargeList.length ? 
                          <Checkbox
                            indeterminate={this.state.cids_indeterminate}
                            onChange={this.handleCidsAllChange.bind(this)}
                            checked={this.state.cids_checkAll}
                          >
                            全选
                          </Checkbox> : null
                        }
                        {getFieldDecorator("cids", {
                          initialValue: initialValues.chargeList,
                        })(
                          <Checkbox.Group 
                            options={this.state.chargeList} 
                            onChange={this.handleCidsChange.bind(this)} 
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="描述说明">
                    {getFieldDecorator("remark", {
                      initialValue: initialValues.remark,
                    })(
                      <TextArea
                        placeholder="请输入"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getAuthGroupList();
    this.getSiteUserList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  
  handleSelectTypeChange(value) {
    this.setState({
      selectType: value,
    });
  }
  handleSelectAuthGroupChange(value) {
    this.setState({
      selectAuthGroup: value,
    });
  }

  //获取角色列表
  getAuthGroupList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _authGroupList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            authGroupList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 选择代理商
  handleFranchiseeChange(value){
    this.setState({
      chargeList: []
    })
    this.getSiteList(value)
    this.getChargeList(value)
  }
  // 充电点列表
  async getChargeList(fid){
    const params = {
      fid,
      chargeType: 3,
      status: 1,
      curPage: 1,
      pageSize: 9999,
    }
    const res = await _batteryChargeList(params)
    const {data={}} = res.data || {}
    const rows = data.rows || []
    const chargeList = rows.map(item=>(
      {
        value: item.id,
        label: item.chargeName,
      }
    ))
    this.setState({
      chargeList
    })
  }
  //获取站点列表
  getSiteList(value) {
    const that = this
    let params;
    if (this.state.selectAuthGroup === 24) {
      if (this.state.modalType === 1) {
        this.setState({
          siteList: [],
        });
        return
      } else {
        params = {
          subFId: this.state.initialValues.fid,
          curPage: 1,
          pageSize: 999,
          status: 1,
        };
      }
    } else {
      params = {
        fid: value,
        curPage: 1,
        pageSize: 999,
        status: 1,
      };
    }
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code == 200) {
          let siteList = res.data.data.rows || [];
          siteList = siteList.map((item, index) => {
            return {
              value: item.sid,
              label: item.sname,
            };
          });
          const sids = this.props.form.getFieldValue('sids') || []
          that.setState({
            siteList,
            checkAll: sids.length == siteList.length
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点管理员列表
  getSiteUserList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _siteUserList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            siteUserList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  //分页
  handlePageChange(curPage, pageSize) {
    this.getSiteUserList(curPage, pageSize, this.state.queryFormValues);
  }

  //二维码查看
  modalViewQrcode(url, username) {
    Modal.info({
      title: `BD二维码（${username}）`,
      content: <img src={url} style={{ width: "100%" }} alt="" />,
      onOk() {},
    });
  }

  //单条删除
  handleDeleteSiteUserChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据吗？",
      onOk() {
        let params = { id };
        _deleteSiteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteSiteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
            self.setState({
              selectedRowKeys: [],
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateSiteUserStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSiteUserList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商详情
  showModalAdd(type) {
    this.setState({
      modalType: type,
      visible: true,
    },()=>{
      this.props.form && this.props.form.resetFields();
      this.state.userType === "1" && this.getSiteList(this.state.userFid);
    });
  }

  //获取站点管理员详情
  getSiteUserDetail(id, type) {
    let params = { id };
    _siteUserDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let initialValues = res.data.data;
          let siteList = [];
          if (!!initialValues.siteList && initialValues.siteList.length) {
            initialValues.siteList.map((item, index) => {
              siteList.push(item.sid);
              return item;
            });
          }
          let chargeList = initialValues.chargeList || []
          initialValues.chargeList = chargeList.map(item=>item.id)
          this.setState({
            id,
            initialValues: initialValues,
            selectType: String(res.data.data.type),
            selectAuthGroup: res.data.data.authGroupId,
          });
          this.showModal(type);
          initialValues.siteList = siteList;
          if(initialValues.franchisee){
            this.getSiteList(initialValues.franchisee.fid);
            this.getChargeList(initialValues.franchisee.fid);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      this.state.userType === "1" && (values.fid = this.state.userFid);
      !!values.password && (values.password = md5(values.password));
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? {
                ...values,
              }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveSiteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: 0,
      initialValues: {},
      siteList: [],
      selectType: "",
      selectAuthGroup: "",
      visible: false,
      inviteCode: null,
    });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status === true) {
        values.status = 1;
      } else if (values.status === false) {
        values.status = 0;
      }
      if (values.typeSearch) {
        values.type = values.typeSearch;
        delete values.typeSearch;
      }
      if (values.authGroupIdSearch) {
        values.authGroupId = values.authGroupIdSearch;
        delete values.authGroupIdSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getSiteUserList(1, this.state.pageSize, values);
    });
  };
}

SiteAdministrator = Form.create({})(SiteAdministrator);
export { SiteAdministrator };
