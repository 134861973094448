import React, { useState } from "react";
import { Tabs } from "antd";
import DetailSheet from "./DetailSheet";
import SummarySheet from "./SummarySheet";
const { TabPane } = Tabs;
const Index = () => {
  const [active, setActive] = useState("1");
  const [curOrderSn, setCurOrderSn] = useState("");
  const handleOrderGo = (orderSn) => {
    setCurOrderSn(orderSn);
    setActive("2");
  };
  return (
    <Tabs
      activeKey={active}
      onChange={(key) => {
        setActive(key);
      }}
    >
      <TabPane tab="分期付订单汇总表" key="1">
        <SummarySheet orderGo={handleOrderGo} />
      </TabPane>
      <TabPane tab="分期付订单明细表" key="2">
        <DetailSheet orderSn={curOrderSn} />
      </TabPane>
    </Tabs>
  );
};

export default Index;
