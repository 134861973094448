import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Tabs,
  Select,
  Icon,
  Modal,
  Form,
  Row,
  Upload,
  Col,
  message,
} from "antd";
import {
  _imageUpload,
  _franchiseeList,
  _kaSiteList,
  _deleteSite,
  _batchDeleteSite,
  _updateKaSiteStatus,
  _updateSiteIsLock,
  _kaSiteDetail,
  _saveKaSiteInfo,
  _exportSiteList,
  _mapGD,
  _kaPyStockSelectSize,
  _kaPyStockBikeLis,
  _kaPyStockBatteryList,
  _kaBikeRecordList,
  _kaBatteryRecordList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";
import moment from "moment";

const { TabPane } = Tabs;
const { Option } = Select;
const modalTitle = ["添加站点", "编辑站点", "站点详情"];
class KaSiteManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      siteList: [], //站点列表
      franchiseeList: [], //代理商列表
      parentFranchiseeList: [], //二级代理商列表
      queryFormValues: {
        status: 1,
      }, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      initialValues: {}, //站点详情
      sid: "", //站点id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      fileList: [], //上传格式
      addressName: "", //定位时的地址（地图地址）
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
      userType: localStorage.getItem("type"),
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "站点名称",
        width: 140,
        dataIndex: "sname",
      },
      {
        title: "所属代理商",
        width: 120,
        dataIndex: "fname",
      },
      {
        title: "所属账号",
        width: 120,
        dataIndex: "subFName",
      },
      {
        title: "站点编号",
        align: "right",
        width: 90,
        dataIndex: "sid",
      },
      // {
      //   title: "站点类型",
      //   width: 90,
      //   dataIndex: "selfLogo",
      //   render: (text) => (text === 1 ? "自营" : "非自营"),
      // },
      {
        title: "联系人",
        width: 100,
        dataIndex: "scontacts",
      },
      {
        title: "手机号",
        align: "right",
        width: 130,
        dataIndex: "stel",
      },
      {
        title: "城市",
        width: 120,
        dataIndex: "cityName",
      },
      {
        title: "地址",
        width: 160,
        dataIndex: "sadd",
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "supdate",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      // {
      //   title: "锁车管理",
      //   width: 100,
      //   dataIndex: "",
      //   render: (text) => (
      //     <Switch
      //       defaultChecked={!!text.isLock}
      //       checkedChildren="启用"
      //       unCheckedChildren="禁用"
      //       onChange={this.handleUpdateSiteIsLockChange.bind(this, text.sid)}
      //     />
      //   ),
      // },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.sstate}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.sid)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 125,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.getSiteDetail.bind(this, text.sid, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.getSiteDetail.bind(this, text.sid, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.showDetialModal.bind(this, text.sid)}
            >
              库存
            </Button>
            {/* <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDeleteSiteChange.bind(this, text.sid)}
            >
              删除
            </Button> */}
          </span>
        ),
      },
    ];
    localStorage.getItem("type") !== "0" && this.columns.splice(4, 1);
    this.pyStockBikeColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "设备类型",
        dataIndex: "modelType",
        render: (text) =>
          text === 1
            ? "两轮车"
            : text === 2
            ? "三轮车"
            : text === 3
            ? "四轮车"
            : null,
      },
      {
        title: "车辆型号",
        dataIndex: "bikeModel",
      },
      {
        title: "车架号",
        dataIndex: "bikeid",
      },
      {
        title: "车二维码",
        dataIndex: "imei",
      },
      {
        title: "GPS号",
        dataIndex: "gpsNumber",
      },
      {
        title: "车牌号",
        dataIndex: "bikeNumber",
      },
      // {
      //   title: "维修状态",
      //   dataIndex: "repairStatus",
      //   render: (text) => (text === 0 ? "正常" : text === 1 ? "维修中" : null),
      // },
      {
        title: "设备状态",
        dataIndex: "equipmentStatus",
        render: (text) =>
          text === 0
            ? "使用中"
            : text === 1
            ? "维修中"
            : text === 2
            ? "闲置中"
            : null,
      },
      // {
      //   title: "设备来源",
      //   dataIndex: "bikeFrom",
      // },
      // {
      //   title: "入库类型",
      //   dataIndex: "inType",
      //   render: (text) =>
      //     text === 0
      //       ? "投放"
      //       : text === 1
      //       ? "代理商调拨"
      //       : text === 2
      //       ? "维修调拨"
      //       : null,
      // },
      {
        title: "入库时间",
        dataIndex: "inTime",
      },
    ];
    this.bikeRecordColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "设备类型",
        dataIndex: "modelType",
        render: (text) =>
          text === 1
            ? "两轮车"
            : text === 2
            ? "三轮车"
            : text === 3
            ? "四轮车"
            : null,
      },
      {
        title: "车辆型号",
        dataIndex: "bikeModel",
      },
      {
        title: "车架号",
        dataIndex: "imei",
      },
      {
        title: "车二维码",
        dataIndex: "qrcode",
      },
      {
        title: "GPS号",
        dataIndex: "gpsNumber",
      },
      {
        title: "车牌号",
        dataIndex: "bikeNumber",
      },
      // {
      //   title: "入库来源",
      //   dataIndex: "inFrom",
      // },
      // {
      //   title: "入库类型",
      //   dataIndex: "inType",
      //   render: (text) =>
      //     text === 0
      //       ? "投放"
      //       : text === 1
      //       ? "代理商调拨"
      //       : text === 2
      //       ? "维修调拨"
      //       : null,
      // },
      {
        title: "入库时间",
        dataIndex: "inTime",
      },
      // {
      //   title: "出库目的地",
      //   dataIndex: "outSite",
      // },
      // {
      //   title: "出库类型",
      //   dataIndex: "outType",
      //   render: (text) =>
      //     text === 0 ? "代理商调拨" : text === 1 ? "维修调拨" : null,
      // },
      {
        title: "出库时间",
        dataIndex: "outTime",
      },
    ];
    this.pyStockBatteryColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "电池型号",
        dataIndex: "batteryModel",
      },
      {
        title: "电池编号",
        dataIndex: "batteryId",
      },
      {
        title: "电池二维码",
        dataIndex: "imei",
      },
      {
        title: "GPS号",
        dataIndex: "gpsNumber",
      },
      {
        title: "设备状态",
        dataIndex: "equipmentStatus ",
        render: (text) =>
          text === 0 ? "闲置中" : text === 1 ? "使用中" : null,
      },
      // {
      //   title: "设备来源",
      //   dataIndex: "batteryFrom",
      // },
      // {
      //   title: "入库类型",
      //   dataIndex: "inType",
      //   render: (text) =>
      //     text === 0
      //       ? "投放"
      //       : text === 1
      //       ? "代理商调拨"
      //       : text === 2
      //       ? "维修调拨"
      //       : null,
      // },
      {
        title: "入库时间",
        dataIndex: "inTime",
      },
    ];
    this.batteryRecordColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "电池型号",
        dataIndex: "batteryModel",
      },
      {
        title: "电池编号",
        dataIndex: "imei",
      },
      {
        title: "电池二维码",
        dataIndex: "qrcode",
      },
      {
        title: "GPS号",
        dataIndex: "gpsNumber",
      },
      // {
      //   title: "入库来源",
      //   dataIndex: "inFrom",
      // },
      // {
      //   title: "入库类型",
      //   dataIndex: "inType",
      //   render: (text) =>
      //     text === 0
      //       ? "投放"
      //       : text === 1
      //       ? "代理商调拨"
      //       : text === 2
      //       ? "维修调拨"
      //       : null,
      // },
      {
        title: "入库时间",
        dataIndex: "inTime",
      },
      // {
      //   title: "出库目的地",
      //   dataIndex: "outSite",
      // },
      // {
      //   title: "出库类型",
      //   dataIndex: "outType",
      //   render: (text) =>
      //     text === 0
      //       ? "代理商调拨"
      //       : text === 1
      //       ? "维修调拨"
      //       : text === 2
      //       ? "退租"
      //       : null,
      // },
      {
        title: "出库时间",
        dataIndex: "outTime",
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点名称">
              {getFieldDecorator("snameSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fidSearch")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="站点类型">
                {getFieldDecorator("selfLogo")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                  >
                    <Option value={1}>自营</Option>
                    <Option value={2}>非自营</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              {/* <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      fileList,
      totalCount,
      curPage,
      pageSize,
      siteList,
      initialValues,
      userType,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(973) ? (
          <div className="SiteManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={this.showModal.bind(this, 1)}
              >
                添加站点
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="sid"
                bordered
                columns={this.columns}
                dataSource={siteList}
                scroll={{ x: 1500, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                      })(
                        <Select
                          disabled={userType == 9 || userType == 10}
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          onChange={this.handleFranchiseeChange.bind(this)}
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList.length &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="所属账号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("subFId", {
                        initialValue: initialValues.subFId,
                      })(
                        <Select
                          disabled={userType == 10}
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.parentFranchiseeList.length &&
                            this.state.parentFranchiseeList.map(
                              (item, index) => {
                                return (
                                  <Option key={index} value={item.fid}>
                                    {item.fname}
                                    {item.inviteCode &&
                                      "（" + item.inviteCode + "）"}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="站点名">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.sname}</span>
                    ) : (
                      getFieldDecorator("sname", {
                        initialValue: initialValues.sname,
                        rules: [{ required: true, message: "请输入站点名！" }],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="站点图片">
                    {getFieldDecorator("img", {
                      initialValue: initialValues.img || undefined,
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        disabled={this.state.modalType === 3 ? true : false}
                        name="uploadFile"
                        action={_imageUpload}
                        listType="picture-card"
                        fileList={fileList}
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                        onChange={this.handleChange.bind(this)}
                        data={{
                          scene: "active",
                        }}
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <Icon type="plus" style={{ fontSize: "28px" }} />
                            <p>站点图片上传</p>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="站点联系人">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.scontacts}</span>
                    ) : (
                      getFieldDecorator("scontacts", {
                        initialValue: initialValues.scontacts,
                        rules: [
                          { required: true, message: "请输入站点联系人！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="联系人电话">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.stel}</span>
                    ) : (
                      getFieldDecorator("stel", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                            message: "请输入正确的手机号格式",
                          },
                          { required: true, message: "请输入联系人电话！" },
                        ],
                        initialValue: initialValues.stel,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="地区">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.areaName}</span>
                    ) : (
                      getFieldDecorator("areaList", {
                        initialValue: initialValues.areaList,
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: "请输入地区！",
                          },
                        ],
                      })(
                        <AreaCascader
                          changeOnSelect
                          placeholder="城市"
                          style={{ width: "100%" }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="详情地址">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.sadd}</span>
                    ) : (
                      getFieldDecorator("sadd", {
                        initialValue: initialValues.sadd,
                        rules: [
                          { required: true, message: "请输入详情地址！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="库存详情"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleDetail}
              onOk={this.handleDetailOk.bind(this)}
              onCancel={this.initDetailModal.bind(this)}
            >
              <div>
                <Row>
                  <Col span={12}>
                    <p>
                      代理商：
                      <span style={{ color: "#999" }}>
                        {this.state.pyStockSelectSize &&
                          this.state.pyStockSelectSize.fname}
                      </span>
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      站点：
                      <span style={{ color: "#999" }}>
                        {this.state.pyStockSelectSize &&
                          this.state.pyStockSelectSize.sname}
                      </span>
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      车辆总数：
                      <span style={{ color: "#999" }}>
                        {this.state.pyStockSelectSize &&
                          this.state.pyStockSelectSize.bikeSize}
                      </span>
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      电池总数：
                      <span style={{ color: "#999" }}>
                        {this.state.pyStockSelectSize &&
                          this.state.pyStockSelectSize.batterySize}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="车辆库存" key="1">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.pyStockBikeColumns}
                      dataSource={this.state.pyStockBikeLis}
                      scroll={{ x: 1500, y: 500 }}
                    />
                  </TabPane>
                  <TabPane tab="车辆库存记录" key="2">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.bikeRecordColumns}
                      dataSource={this.state.bikeRecordList}
                      scroll={{ x: 1500, y: 500 }}
                    />
                  </TabPane>
                  <TabPane tab="电池库存" key="3">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.pyStockBatteryColumns}
                      dataSource={this.state.pyStockBatteryList}
                      scroll={{ x: 1500, y: 500 }}
                    />
                  </TabPane>
                  <TabPane tab="电池库存记录" key="4">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.batteryRecordColumns}
                      dataSource={this.state.batteryRecordList}
                      scroll={{ x: 1500, y: 500 }}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getSiteList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getPyStockSelectSize(sid) {
    let params = {
      sid,
    };
    _kaPyStockSelectSize(params)
      .then((res) => {
        this.setState({
          pyStockSelectSize: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取代理商列表
  getPyStockBikeLis(sid) {
    let params = {
      sid,
      curPage: 1,
      pageSize: 999,
    };
    _kaPyStockBikeLis(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            pyStockBikeLis: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取代理商列表
  getPyStockBatteryList(sid) {
    let params = {
      sid,
      curPage: 1,
      pageSize: 999,
    };
    _kaPyStockBatteryList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            pyStockBatteryList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取车辆列表
  getBikeRecordList(sid) {
    let params = {
      sid,
      curPage: 1,
      pageSize: 999,
    };
    _kaBikeRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeRecordList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取代理商列表
  getBatteryRecordList(sid) {
    let params = {
      sid,
      curPage: 1,
      pageSize: 999,
    };
    _kaBatteryRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryRecordList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleFranchiseeChange(value) {
    const { form } = this.props;
    form.setFieldsValue({
      subFId: "",
    });
    this.setState({
      parentFranchiseeList: [],
    });
    if (value) {
      this.getParentFranchiseeList(value);
    }
  }
  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
      ftype: 1,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取二级代理商列表
  getParentFranchiseeList(parentId) {
    let params = {
      curPage: 1,
      pageSize: 999,
      parentId,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            parentFranchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取坐标的详细地址
  handleClickAmapChange(longitude, latitude) {
    let location = longitude + "," + latitude;
    let params = {
      output: "json",
      location: location,
      key: "1607b8afa5a91ccba6d84b8cd4cd5a24",
      radius: 1000,
      extensions: "base",
    };
    _mapGD({ params: params })
      .then((res) => {
        console.log(res.data.regeocode);
        this.setState({
          addressName: res.data.regeocode.formatted_address,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _kaSiteList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            siteList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getSiteList(curPage, pageSize, this.state.queryFormValues);
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  //单条删除
  handleDeleteSiteChange(sid) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { sid };
        _deleteSite(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteSite(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //锁车管理Switch
  handleUpdateSiteIsLockChange(sid, checked) {
    let params = { sid, isLock: Number(checked) };
    _updateSiteIsLock(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSiteList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //管理状态Switch
  handleStateChange(sid, checked) {
    let params = { sid, sstate: Number(checked) };
    _updateKaSiteStatus(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSiteList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.sstate;
      if (values.snameSearch) {
        values.sname = values.snameSearch;
        delete values.snameSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null;
        values.scity = values.areaList[1] ? values.areaList[1] : null;
        values.scounty = values.areaList[2] ? values.areaList[2] : null;
        values.sstreet = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getSiteList(1, this.state.pageSize, values);
    });
  };
  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.sstate;
      if (values.snameSearch) {
        values.sname = values.snameSearch;
        delete values.snameSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null;
        values.scity = values.areaList[1] ? values.areaList[1] : null;
        values.scounty = values.areaList[2] ? values.areaList[2] : null;
        values.sstreet = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      let params = {
        ...values,
      };
      _exportSiteList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //获取工地详情
  getSiteDetail(sid, type) {
    let params = { sid };
    _kaSiteDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let longitude, latitude;
          let scoordinate = res.data.data.scoordinate;
          longitude = scoordinate ? scoordinate.split(",")[0] : 0;
          latitude = scoordinate ? scoordinate.split(",")[1] : 0;
          let { sprovince, scity, scounty, sstreet } = res.data.data || {};
          this.setState({
            sid,
            initialValues: {
              ...res.data.data,
              areaList: [sprovince, scity, scounty, sstreet].filter(
                (item) => item
              ),
            },
            mapCenter: { longitude, latitude },
            markerPosition: { longitude, latitude },
          });
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: "done",
                url: res.data.data.imgObject.path,
              },
            ]);
          this.showModal(type);
          this.getParentFranchiseeList(res.data.data.fid);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState(
      {
        modalType,
        visible: true,
      },
      () => {
        const { userType, userInfo } = this.state;
        const { form } = this.props;
        if (userType == 9) {
          form.setFieldsValue({
            fid: userInfo.fid,
          });
          this.getParentFranchiseeList(userInfo.fid);
        } else if (userType == 10) {
          form.setFieldsValue({
            fid: userInfo.parentId,
            subFId: userInfo.fid,
          });
          this.getParentFranchiseeList(userInfo.parentId);
        }
      }
    );
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    // if (
    //   this.state.markerPosition.longitude &&
    //   this.state.markerPosition.latitude
    // ) {
    const { form } = this.props;
    const { modalType, sid } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.img && values.img.constructor === Array) {
        values.img =
          values.img.length === 1 ? values.img[0].response.data.id : "";
      }
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null;
        values.scity = values.areaList[1] ? values.areaList[1] : null;
        values.scounty = values.areaList[2] ? values.areaList[2] : null;
        values.sstreet = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      values.scoordinate =
        this.state.markerPosition.longitude +
        "," +
        this.state.markerPosition.latitude;
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params = {};
        if (modalType === 1) {
          params = { ...values, sstate: 1 };
        } else if (modalType === 2) {
          params = {
            sid,
            ...values,
          };
        }
        _saveKaSiteInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
    // } else {
    //   message.warning("地图坐标请选择！");
    // }
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.updateFileList([]);
    this.setState({
      sid: null,
      initialValues: {},
      modalType: null,
      visible: false,
      addressName: null,
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
      parentFranchiseeList: [],
    });
  }

  //查看详情（打开）
  showDetialModal(sid) {
    this.getBikeRecordList(sid);
    this.getPyStockBatteryList(sid);
    this.getPyStockBikeLis(sid);
    this.getPyStockSelectSize(sid);
    this.getBatteryRecordList(sid);
    this.setState({
      visibleDetail: true,
    });
  }

  //查看详情（确定）
  handleDetailOk(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }
}

KaSiteManagement = Form.create({})(KaSiteManagement);
export { KaSiteManagement };
