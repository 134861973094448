import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  message,
  Modal,
} from "antd";
import {
  _userFeedbackList,
  _deleteUser,
  _updateUserStatus,
  _complainListExport,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";
import moment from "moment";
const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      usersList: [], //会员列表
      queryFormValues: "", // 搜索项
      kid: "", //用户id
      loading: false,
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "问题类型",
        dataIndex: "typeName",
      },
      {
        title: "问题描述",
        dataIndex: "content",
      },
      {
        title: "用户",
        dataIndex: "userName",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
      },
      {
        title: "城市",
        dataIndex: "city",
      },
      {
        title: "反馈时间",
        dataIndex: "addTime",
        width: 135,
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="问题类型">
              {getFieldDecorator("type")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>订单问题</Option>
                  <Option value={2}>自提点问题</Option>
                  <Option value={3}>收费问题</Option>
                  <Option value={4}>维修问题</Option>
                  <Option value={5}>退租问题</Option>
                  <Option value={6}>其他</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="反馈时间">
              {getFieldDecorator("time")(<RangePicker format="YYYY-MM-DD" />)}
            </Form.Item>
          </Col>

          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(1036)}
                title={getJurisdiction(1036) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { usersList, pageSize, totalCount, curPage } = this.state;

    return (
      <div>
        {!!getJurisdiction(1034) ? (
          <div className="Feedback">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={usersList}
                components={this.components}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getUsersList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取站点列表
  getUsersList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _userFeedbackList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            usersList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getUsersList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.time && values.time.length) {
        values.timeStart = moment(values.time[0]).format("YYYY-MM-DD");
        values.timeEnd = moment(values.time[1]).format("YYYY-MM-DD");
      }
      delete values.time;
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getUsersList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.time && values.time.length) {
        values.timeStart = moment(values.time[0]).format("YYYY-MM-DD");
        values.timeEnd = moment(values.time[1]).format("YYYY-MM-DD");
      }
      delete values.time;
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        delete values.areaList;
      }
      let params = {
        ...values,
      };
      _complainListExport(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  //单条删除
  handleDeleteChange(kid) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { kid };
        _deleteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(kid, checked) {
    let params = { kid, status: Number(checked) };
    _updateUserStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getUsersList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

Feedback = Form.create({})(Feedback);
export default Feedback;
