import React, { useEffect, useState, useRef } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import "./index.less";
import BarCard from "./components/BarCard";
import moment from "moment";
import { Row, Col, Card, Tag, message, Table, Collapse } from "antd";
import { _batteryInfo, _batteryAlarmImei } from "@/statics/js/api";
const amapkey = "4792ad0f37a527c1535e83599762d894";

const Index = (props) => {
  const { id,imei } = props;
  const [info, setInfo] = useState({});
  const chartRef = useRef();
  const [address, setAddress] = useState("");
  const [batteryAlarmList, setBatteryAlarmList] = useState([]);
  const columns = [
    {
      title: "告警日期",
      dataIndex: "addTime",
    },
    {
      title: "告警类型",
      dataIndex: "alarm",
    },
    {
      title: "事件",
      dataIndex: "type",
      render: (text) =>
        text === 1 ? (
          <span
            style={{
              background: "#009688",
              color: "#fff",
              padding: "5px 8px",
              fontSize: 12,
              borderRadius: 3,
            }}
          >
            产生
          </span>
        ) : (
          <span
            style={{
              background: "#FF5722",
              color: "#fff",
              padding: "5px 8px",
              fontSize: 12,
              borderRadius: 3,
            }}
          >
            恢复
          </span>
        ),
    },
  ];
  useEffect(() => {
    getInfo();
    getList();
  }, []);

  const getList = async () => {
    const params = {
      imei,
      curPage: 1,
      pageSize: 999,
    };
    const res = await _batteryAlarmImei(params);
    console.log(res);
    if (res.data.code === 200) {
      setBatteryAlarmList(res.data.data.rows);
    }
  };
  const getInfo = async () => {
    const params = {
      id,
    };
    const res = await _batteryInfo(params);
    const { data, code, message: msg } = res.data || {};
    if (code !== 200) {
      message.warning(msg);
      return;
    }
    let { lat, lng } = data;
    let lnglat = [parseFloat(lng), parseFloat(lat)];
    initMap(lnglat);
    setInfo(data);
    let cellList = data.cellList || [];
    let max = Math.max(...cellList);
    let min = Math.min(...cellList);
    let hasMax = false,
      hasMin = false;
    let TimeXScale = [];
    cellList = cellList.map((item, index) => {
      TimeXScale.push(index + 1);
      if (item == max && !hasMax) {
        hasMax = true;
        return {
          value: item,
          itemStyle: {
            color: "#FFB800",
          },
        };
      }
      if (item == min && !hasMin) {
        hasMin = true;
        return {
          value: item,
          itemStyle: {
            color: "#1E9FFF",
          },
        };
      }
      return item;
    });
    chartRef.current.init({
      data: cellList,
      TimeXScale,
    });
  };
  const initMap = async (lnglat) => {
    let AMap = await AMapLoader.load({
      key: amapkey,
      version: "2.0",
    });
    AMap.plugin("AMap.Geocoder", function () {
      let geocoder = new AMap.Geocoder({
        city: "010",
      });
      geocoder.getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          let { formattedAddress } = result.regeocode;
          setAddress(formattedAddress);
        }
      });
    });
  };

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <Card type="inner" title="基本信息">
            <Row>
              <Col span={12}>
                <label className="cell_label">电池包编码</label>
                <Tag color="#f50">{info.uid}</Tag>
              </Col>
              <Col span={12}>
                <label className="cell_label">IMEI</label>
                <Tag>{info.imei}</Tag>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="cell_label">GPSID</label>
                <span>{info.uid}</span>
              </Col>
              <Col span={12}>
                <label className="cell_label">IMSI</label>
                <span>--</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="cell_label">数据时间</label>
                <span>
                  {moment(info.batTime, "YYYYMMDDHHmmss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </span>
              </Col>
              <Col span={12}>
                <label className="cell_label">BMS版本</label>
                <span>{info.bms}</span>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <label className="cell_label">在线</label>
                <Tag color="#FFB800">{info.online === 1 ? "在线" : "静置"}</Tag>
              </Col>
              <Col span={6}>
                <label className="cell_label">电池电量</label>
                <Tag color="#87d068">{info.remain}</Tag>
              </Col>
              <Col span={6}>
                <label className="cell_label">充电状态</label>
                <Tag color="#2db7f5">
                  {Number(info.current) > 0 ? "充电中" : "空闲"}
                </Tag>
              </Col>
              <Col span={6}>
                <label className="cell_label">放电状态</label>
                <Tag color="#2db7f5">
                  {Number(info.current) < 0 ? "放电中" : "空闲"}
                </Tag>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <label className="cell_label">电流</label>
                <Tag color="#f50">{info.current}</Tag>A
              </Col>
              <Col span={6}>
                <label className="cell_label">型号</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">充电开关</label>
                <Tag color="#009688">{info.charge == 1 ? "关" : "开"}</Tag>
              </Col>
              <Col span={6}>
                <label className="cell_label">放电开关</label>
                <Tag color="#009688">{info.discharge == 1 ? "关" : "开"}</Tag>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <label className="cell_label">电压</label>
                <span>{info.voltage}</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">循环次数</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">GPS版本</label>
                <span>--</span>
              </Col>
            </Row>
          </Card>
          <Card type="inner" title="位置信息">
            <Row>
              <Col span={6}>
                <label className="cell_label">总里程</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">当日里程</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">租赁状态</label>
                <Tag color="#f50">{info.useStatus}</Tag>
              </Col>
              <Col span={6}>
                <label className="cell_label">租赁人</label>
                <span>{info.userName}</span>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <label className="cell_label">4G信号</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">BMS信息</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">GPS</label>
                <span>--</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="cell_label">位置时间</label>
                <span>
                  {moment(info.locTime, "YYYYMMDDHHmmss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </span>
              </Col>
              <Col span={12}>
                <label className="cell_label">经纬度</label>
                <span>
                  {parseFloat(info.lng).toFixed(10)}，
                  {parseFloat(info.lat).toFixed(10)}
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label className="cell_label">最后位置</label>
                <span>{address}</span>
              </Col>
            </Row>
          </Card>
          <Card type="inner" title="状态信息">
            <Row>
              <Col span={6}>
                <Tag color="#009688">{info.current > 0 ? "是" : "否"}</Tag>
                <label className="cell_label">充电中</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">
                  {parseFloat(info.remain) >= 100 ? "是" : "否"}
                </Tag>
                <label className="cell_label">充满</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.chargeOc === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">充电过流</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.cellOv === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">电芯过压</label>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Tag color="#009688">{info.current < 0 ? "是" : "否"}</Tag>
                <label className="cell_label">放电中</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.sc === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">短路</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">
                  {info.disChargeOc === 1 ? "是" : "否"}
                </Tag>
                <label className="cell_label">放电过流(b6)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.cellUv === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">电芯欠压</label>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">电芯侦测线开路</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">温感侦测线开路</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.cellTempH === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">电芯温度过高</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.cellTempL === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">电芯温度过低</label>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Tag color="#009688">{info.bmsTempH === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">BMS温度过高</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">保留参数</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.charge === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">禁止充电</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">{info.disCharge === 1 ? "是" : "否"}</Tag>
                <label className="cell_label">禁止放电</label>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">放电MOS失效(b16)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">充电MOS状态(b17)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">放电MOS状态(b18)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">充电MOS失效(b19)</label>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">电芯高低压失效(b20)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">电芯超高温失效(b21)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">电芯压差大(b22)</label>
              </Col>
              <Col span={6}>
                <Tag color="#009688">--</Tag>
                <label className="cell_label">电池温差大(b23)</label>
              </Col>
            </Row>
          </Card>
          <Card type="inner" title="温度采集信息">
            <Row>
              <Col span={6}>
                <label className="cell_label">BMS(1)</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">BMS(2)</label>
                <span>--</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">电芯(1)</label>
                <span>{info.tempC1}</span>
              </Col>
              <Col span={6}>
                <label className="cell_label">电芯(2)</label>
                <span>{info.tempC2}</span>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card type="inner" title="实时电芯电压">
            <BarCard ref={chartRef} />
          </Card>
          <Collapse>
            <Collapse.Panel header="告警消息记录" key="1">
              <Table
                rowKey="id"
                bordered
                columns={columns}
                dataSource={batteryAlarmList}
              />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default Index;
