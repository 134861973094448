import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import BdSelect from '@/components/bdSelect'
import moment from 'moment';
import {
    _operateBdBatteryList,
    _operateBdBatteryExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Select,
    Input,
    Row,
    Col,
    DatePicker
} from "antd";
const { Option } = Select;
const {WeekPicker} = DatePicker

const columns = [
    {
        title: "BD名称/手机号",
        dataIndex: "bdName",
    },
    {
        title: "闲置电池",
        dataIndex: "idleBatterySize",
    },
    {
        title: "闲置电池环比",
        dataIndex: "idleBatterySizeScale",
    },
    {
        title: "在租电池",
        dataIndex: "rentBatterySize",
    },
    {
        title: "在租电池环比",
        dataIndex: "rentBatterySizeScale",
    },
    {
        title: "电池数",
        dataIndex: "batterySize",
    },
    {
        title: "电池数环比",
        dataIndex: "batterySizeScale",
    },
    {
        title: "电池出租率",
        dataIndex: "batteryHireProportion",
    },
    {
        title: "电池出租率环比",
        dataIndex: "batteryHireProportionScale",
    },
]

const Index = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        handleSearch()
    },[])
    
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type} = values
            let time = {}
            if(type == 0){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type} = values
        let time = {}
        if(type == 0){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }
        handleDownload({
            action: _operateBdBatteryExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 0
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>月报表</Option>
                                <Option value={0}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="BD名称/手机号">
                        {getFieldDecorator("namePhone")(
                            <BdSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateBdBatteryList}
            options={{
                columns: columns
            }}
        />
    </>)
})

export default Index