import axios from './request'
import { AXIOS_URL } from './config'
//登录
export function _login(data) {
  return axios.post('/account/mng/login', data)
}
//推出登录
export function _logout(data) {
  return axios.post('/account/mng/logout', data)
}
//首页车辆信息
export function _firstBikeList(data) {
  return axios.post('/order/mng/firstBikeList', data)
}
//累计超时报表
export function _outTimeOrderSumList(data) {
  return axios.post('/order/mng/outTimeOrderSumList', data)
}
//累计超时报表导出
export function _outTimeOrderSumListExport(data) {
  return axios.post('/order/mng/outTimeOrderSumListExport', data)
}
//首页电池信息
export function _firstBatteryList(data) {
  return axios.post('/order/mng/firstBatteryList', data)
}
//获取账户详情
export function _accountDetail(data) {
  return axios.post('/account/mng/accountDetail', data)
}
//获取账户详情
export function _getHomePageInfo(data) {
  return axios.post('/bike/mng/getHomePageInfo', data)
}
//获取账户详情
export function _getHomePageInfoFotSiteList(data) {
  return axios.post('/bike/mng/getHomePageInfoFotSiteList', data)
}
//获取首页详情
export function _getSignStaticInfo(data) {
  return axios.post('/bike/mng/getSignStaticInfo', data)
}
//保存账户信息
export function _saveAccountInfo(data) {
  return axios.post('/account/mng/saveAccountInfo', data)
}
//修改密码
export function _modifyPassword(data) {
  return axios.post('/account/mng/modifyPassword', data)
}
//菜单列表
export function _menuList(data) {
  return axios.post('/menu/mng/menuList', data)
}
//菜单详情
export function _menuDetail(data) {
  return axios.post('/menu/mng/menuDetail', data)
}
//删除菜单
export function _deleteMenu(data) {
  return axios.post('/menu/mng/deleteMenu', data)
}
//更新菜单状态
export function _updateMenuStatus(data) {
  return axios.post('/menu/mng/updateMenuStatus', data)
}
//保存菜单
export function _saveMenu(data) {
  return axios.post('/menu/mng/saveMenu', data)
}
//角色列表
export function _authGroupList(data) {
  return axios.post('/role/mng/authGroupList', data)
}
//角色详情
export function _authGroupDetail(data) {
  return axios.post('/role/mng/authGroupDetail', data)
}
//保存角色
export function _saveAuthGroup(data) {
  return axios.post('/role/mng/saveAuthGroup', data)
}
//所有菜单
export function _allMenus(data) {
  return axios.post('/role/mng/allMenus', data)
}
//更新角色状态
export function _updateAuthGroupStatus(data) {
  return axios.post('/role/mng/updateAuthGroupStatus', data)
}
//删除角色
export function _deleteAuthGroup(data) {
  return axios.post('/role/mng/deleteAuthGroup', data)
}
//批量删除角色
export function _batchDeleteAuthGroup(data) {
  return axios.post('/role/mng/batchDelete', data)
}
//代理商列表
export function _franchiseeList(data) {
  return axios.post('/franchisee/mng/franchiseeList', data)
}
//代理商详情
export function _franchiseeDetail(data) {
  return axios.post('/franchisee/mng/franchiseeDetail', data)
}
//ka代理商详情
export function _kaFranchiseeDetail(data) {
  return axios.post('/kaFranchisee/mng/kaFranchiseeDetail', data)
}
//地区列表
export function _getSonsOfRegion(data) {
  return axios.post('/area/getSonsOfRegion', data)
}
//更新代理商状态
export function _updateFranchiseeStatus(data) {
  return axios.post('/franchisee/mng/updateFranchiseeStatus', data)
}
//更新ka代理商状态
export function _updateKaFranchiseeStatus(data) {
  return axios.post('/kaFranchisee/mng/updateKaFranchiseeStatus', data)
}
//保存代理商
export function _saveFranchisee(data) {
  return axios.post('/franchisee/mng/saveFranchisee', data)
}
//保存ka代理商
export function _saveKaFranchisee(data) {
  return axios.post('/kaFranchisee/mng/saveKaFranchisee', data)
}
//删除代理商
export function _deleteFranchisee(data) {
  return axios.post('/franchisee/mng/deleteFranchisee', data)
}
//批量删除代理商
export function _batchDeleteFranchisee(data) {
  return axios.post('/franchisee/mng/batchDelete', data)
}
//代理商列表导出
export function _exportFranchiseeList(data) {
  return axios.post('/franchisee/mng/exportFranchiseeList', data)
}
//站点列表
export function _siteList(data) {
  return axios.post('/site/mng/siteList', data)
}
//ka站点列表
export function _kaSiteList(data) {
  return axios.post('/kaFranchisee/mng/kaSiteList', data)
}
//站点列表(搜索列表)
export function _siteBoxList(data) {
  return axios.post('/site/mng/siteBoxList', data)
}
//站点列表导出
export function _exportSiteList(data) {
  return axios.post('/site/mng/exportSiteList', data)
}
//删除站点
export function _deleteSite(data) {
  return axios.post('/site/mng/deleteSite', data)
}
//批量删除站点
export function _batchDeleteSite(data) {
  return axios.post('/site/mng/batchDeleteSite', data)
}
//站点锁车状态
export function _updateSiteIsLock(data) {
  return axios.post('/site/mng/updateSiteIsLock', data)
}
//更新站点状态
export function _updateSiteStatus(data) {
  return axios.post('/site/mng/updateSiteStatus', data)
}
//ka更新站点状态
export function _updateKaSiteStatus(data) {
  return axios.post('/kaFranchisee/mng/updateKaSiteStatus', data)
}

//站点详情
export function _siteDetail(data) {
  return axios.post('/site/mng/siteDetail', data)
}
//ka站点详情
export function _kaSiteDetail(data) {
  return axios.post('/kaFranchisee/mng/kaSiteDetail', data)
}

//保存站点
export function _saveSiteInfo(data) {
  return axios.post('/site/mng/saveSiteInfo', data)
}
//ka保存站点
export function _saveKaSiteInfo(data) {
  return axios.post('/kaFranchisee/mng/kaSaveSiteInfo', data)
}
//维修项详情列表
export function _bikeRepairDetailList(data) {
  return axios.post('/bikeRepair/mng/bikeRepairDetailList', data)
}
//维修项详情导出
export function _exportBikeRepairDetailList(data) {
  return axios.post('/bikeRepair/mng/exportBikeRepairDetailList ', data)
}
//维修项详情修改
export function _updateBikeRepairDetailList(data) {
  return axios.post('/bikeRepair/mng/updateBikeRepairDetailList', data)
}
//退车点列表
export function _siteRepairList(data) {
  return axios.post('/siteRepair/mng/siteRepairList', data)
}
//退车点列表(搜索列表)
export function _siteRepairBoxList(data) {
  return axios.post('/siteRepair/mng/siteRepairBoxList', data)
}
//删除退车点
export function _deleteSiteRepair(data) {
  return axios.post('/siteRepair/mng/deleteSiteRepair', data)
}
//批量删除退车点
export function _batchDelSiteRepair(data) {
  return axios.post('/siteRepair/mng/batchDelSiteRepair', data)
}
//更新退车点状态
export function _updateSiteRepairStatus(data) {
  return axios.post('/siteRepair/mng/updateSiteRepairStatus', data)
}
//退车点详情
export function _siteRepairDetail(data) {
  return axios.post('/siteRepair/mng/siteRepairDetail', data)
}
//保存退车点
export function _saveSiteRepairInfo(data) {
  return axios.post('/siteRepair/mng/saveSiteRepairInfo', data)
}
//车辆检修列表
export function _bikeRepairList(data) {
  return axios.post('/bikeRepair/mng/bikeRepairList', data)
}
//删除车辆检修
export function _deleteBikeRepair(data) {
  return axios.post('/bikeRepair/mng/deleteBikeRepair', data)
}
//批量删除车辆检修
export function _batchDelBikeRepair(data) {
  return axios.post('/bikeRepair/mng/batchDelBikeRepair', data)
}
//更新车辆检修状态
export function _updateBikeRepairStatus(data) {
  return axios.post('/bikeRepair/mng/updateBikeRepairStatus', data)
}
//车辆检修详情
export function _bikeRepairDetail(data) {
  return axios.post('/bikeRepair/mng/bikeRepairDetail', data)
}
//保存车辆检修
export function _saveBikeRepairInfo(data) {
  return axios.post('/bikeRepair/mng/saveBikeRepairInfo', data)
}
//退车点管理员列表
export function _siteRepairUserList(data) {
  return axios.post('/siteRepair/mng/siteRepairUserList', data)
}
//站点管理员列表
export function _siteUserList(data) {
  return axios.post('/site/mng/siteUserList', data)
}
//删除站点管理员
export function _deleteSiteUser(data) {
  return axios.post('/site/mng/deleteSiteUser', data)
}
//获取站点套餐
export function _sitePackageList(data) {
  return axios.post('/package/mng/sitePackageList', data)
}
//站点套餐禁用
export function _handleSitePackages(data) {
  return axios.post('/package/mng/handleSitePackages', data)
}
//批量删除站点管理员
export function _batchDeleteSiteUser(data) {
  return axios.post('/site/mng/batchDeleteSiteUser', data)
}
//更新站点管理员状态
export function _updateSiteUserStatus(data) {
  return axios.post('/site/mng/updateSiteUserStatus', data)
}
//站点管理员详情
export function _siteUserDetail(data) {
  return axios.post('/site/mng/siteUserDetail', data)
}
//生成邀请码
export function _genInviteCode(data) {
  return axios.post('/franchisee/mng/genInviteCode', data)
}
//保存站点管理员
export function _saveSiteUser(data) {
  return axios.post('/site/mng/saveSiteUser', data)
}
//用户列表
export function _usersList(data) {
  return axios.post('/pcUser/mng/usersList', data)
}
//用户认证信息
export function _returnReal(data) {
  return axios.post('/pcUser/mng/returnReal', data)
}
//会员认证信息修改
export function _updateReal(data) {
  return axios.post('/pcUser/mng/updateReal', data)
}
//反馈列表
export function _userFeedbackList(data) {
  return axios.post('/user/mng/userFeedbackList', data)
}
// ka用户列表
export function _kaUsersList(data) {
  return axios.post('/kaFranchisee/mng/kaUserList', data)
}
//ka用户使用记录列表
export function _kaUserLogList(data) {
  return axios.post('/kaFranchisee/mng/kaUserLogList', data)
}
// ka导出用户
export function _kaUserListExport(data) {
  return axios.post('/kaFranchisee/mng/kaUserListExport', data)
}
//ka保存用户
export function _saveKaUser(data) {
  return axios.post('/kaFranchisee/mng/saveKaUser', data)
}
//ka更新用户
export function _updateKaUsers(data) {
  return axios.post('/kaFranchisee/mng/updateUsers', data)
}
//ka用户详情
export function _kaUserDetail(data) {
  return axios.post('/kaFranchisee/mng/returnUsers', data)
}
//删除用户
export function _deleteUser(data) {
  return axios.post('/user/mng/deleteUser', data)
}
//批量删除用户
export function _batchDeleteUser(data) {
  return axios.post('/user/mng/batchDeleteUser', data)
}
//更新管理状态
export function _updateUserStatus(data) {
  return axios.post('/user/mng/updateUserStatus', data)
}
//ka更新用户管理状态
export function _kaUpdateUserStatus(data) {
  return axios.post('/kaFranchisee/mng/updateStatus', data)
}
//用户详情
// export function _userDetail(data) {
//   return axios.post("/user/mng/userDetail", data);
// }
//保存用户
// export function _saveUserInfo(data) {
//   return axios.post("/user/mng/saveUserInfo", data);
// }
//充电桩管理
export function _fastChargeList(data) {
  return axios.post('/fastCharge/mng/fastChargeList', data)
}
//保存充电桩
export function _saveFastCharge(data) {
  return axios.post('/fastCharge/mng/saveFastCharge', data)
}
//充电桩详情
export function _fastChargeDetail(data) {
  return axios.post('/fastCharge/mng/fastChargeDetail', data)
}
//充电桩控制
export function _updateFastChargeControl(data) {
  return axios.post('/fastCharge/mng/updateFastChargeControl', data)
}
//更新充电桩状态
export function _updateFastChargeStatus(data) {
  return axios.post('/fastCharge/mng/updateFastChargeStatus', data)
}
//导入充电桩
export function _batchImportFastCharge(data) {
  return axios.post('/fastCharge/mng/batchImportFastCharge', data)
}
//充电桩记录
export function _fastChargeLogList(data) {
  return axios.post('/fastChargeLog/mng/fastChargeLogList', data)
}
//导出充电器记录
export function _exportInsureFastChargeLog(data) {
  return axios.post('/fastChargeLog/mng/exportInsureFastChargeLog', data)
}
//充电桩统计
export function _fastChargeCapacityMoney(data) {
  return axios.post('/fastChargeLog/mng/fastChargeCapacityMoney', data)
}
//充电桩型号
export function _fastChargeModelList(data) {
  return axios.post('/fastChargeModel/mng/fastChargeModelList', data)
}
//保存充电桩型号
export function _saveFastChargeModel(data) {
  return axios.post('/fastChargeModel/mng/saveFastChargeModel', data)
}
//充电桩详情
export function _fastChargeModelDetail(data) {
  return axios.post('/fastChargeModel/mng/fastChargeModelDetail', data)
}
//更新充电桩状态
export function _updateFastChargeModelStatus(data) {
  return axios.post('/fastChargeModel/mng/updateFastChargeModelStatus', data)
}
//电池换充
export function _batteryChargeList(data) {
  return axios.post('/charge/mng/batteryChargeList', data)
}
//电池换充详情
export function _chargeDetail(data) {
  return axios.post('/charge/mng/chargeDetail', data)
}
//保存电池换充
export function _saveBatteryCharge(data) {
  return axios.post('/charge/mng/saveBatteryCharge', data)
}
//更新电池换充
export function _updateChargeStatus(data) {
  return axios.post('/charge/mng/updateChargeStatus', data)
}
//电池列表
export function _batteryList(data) {
  return axios.post('/battery/mng/batteryList', data)
}
//电池状态列表
export function _getBatteryStatus(data) {
  return axios.post('/battery/mng/getBatteryStatus', data)
}
//电池详情
export function _batteryDetail(data) {
  return axios.post('/battery/mng/batteryDetail', data)
}
//电池数据
export function _batteryInfo(data) {
  return axios.post(AXIOS_URL + '/battery/mng/batteryInfo', data)
}
//电池折线图
export function _historicalBatInfo(data) {
  return axios.post(AXIOS_URL + '/battery/mng/historicalBatInfo', data)
}
//电池轨迹
export function _batteryTrack(data) {
  return axios.post(AXIOS_URL + '/battery/mng/batteryTrack', data)
}
//历史电池列表
export function _batteryOrderList(data) {
  return axios.post('/order/mng/batteryOrderList', data)
}
//导出电池列表
export function _exportBatteryList(data) {
  return axios.post('/battery/mng/exportBatteryList', data)
}
//获取二维码
export function _getQRCode(data) {
  return axios.post('/franchisee/mng/getQRCode', data)
}
//电池操作
export function _sendInstructionBattery(data) {
  return axios.post('/battery/mng/sendInstruction', data)
}
//电池定位
export function _devicePositionBattery(data) {
  return axios.post('/battery/mng/devicePosition', data)
}
//更新电池状态
export function _updateBatteryStatus(data) {
  return axios.post('/battery/mng/updateBatteryStatus', data)
}
//删除电池
export function _deleteBattery(data) {
  return axios.post('/battery/mng/deleteBattery', data)
}
//保存电池
export function _saveBattery(data) {
  return axios.post('/battery/mng/saveBattery', data)
}
//投放电池
export function _batchPushBattery(data) {
  return axios.post('/battery/mng/batchPush', data)
}
//导入电池
export function _batchImportBattery(data) {
  return axios.post('/battery/mng/batchImport', data)
}
//电池型号列表
export function _batteryModelList(data) {
  return axios.post('/battery/mng/batteryModelList', data)
}
//电池型号列表(代理商)
export function _merchantBatModels(data) {
  return axios.post('/battery/mng/merchantBatModels', data)
}
//更新电池型号状态
export function _updateBatteryModelStatus(data) {
  return axios.post('/battery/mng/updateBatteryModelStatus', data)
}
//电池型号详情
export function _batteryModelDetail(data) {
  return axios.post('/battery/mng/batteryModelDetail', data)
}
//删除电池型号
export function _deleteBatteryModel(data) {
  return axios.post('/battery/mng/deleteBatteryModel', data)
}
//保存电池型号
export function _saveBatteryModel(data) {
  return axios.post('/battery/mng/saveBatteryModel', data)
}
//车辆详情
export function _bikeDetail(data) {
  return axios.post('/bike/mng/bikeDetail', data)
}
//历史车辆列表
export function _bikeOrderList(data) {
  return axios.post('/order/mng/bikeOrderList', data)
}
//设备绑定列表
export function _deviceChangeList(data) {
  return axios.post('/bike/mng/deviceChangeList', data)
}
//设备绑定用户
export function _bindUser(data) {
  return axios.post('/kaFranchisee/mng/bindUser', data)
}
// 设备退还
export function _unbindUser(data) {
  return axios.post('/kaFranchisee/mng/unbindUser', data)
}
// 设备绑定信息
export function _deviceBindInfo(data) {
  return axios.post('/kaFranchisee/mng/deviceBindInfo', data)
}
// 设备使用记录
export function _deviceUseLog(data) {
  return axios.post('/kaFranchisee/mng/deviceUseLog', data)
}
//车辆型号列表
export function _bikeModelList(data) {
  return axios.post('/bike/mng/bikeModelList', data)
}
//车辆型号列表(代理商)
export function _merchantBikeModels(data) {
  return axios.post('/bike/mng/merchantBikeModels', data)
}
//导出车辆列表
export function _exportBikeList(data) {
  return axios.post('/bike/mng/exportBikeList', data)
}
//车辆操作
export function _sendInstructionBike(data) {
  return axios.post('/bike/mng/sendInstruction', data)
}
//车辆定位
export function _devicePositionBike(data) {
  return axios.post('/bike/mng/devicePosition', data)
}
//更新车辆状态
export function _updateBikeStatus(data) {
  return axios.post('/bike/mng/updateBikeStatus', data)
}
//删除车辆
export function _deleteBike(data) {
  return axios.post('/bike/mng/deleteBike', data)
}
//车辆列表
export function _bikeList(data) {
  return axios.post('/bike/mng/bikeList', data)
}
//车辆状态列表
export function _getBikeStatus(data) {
  return axios.post('/bike/mng/getBikeStatus', data)
}
//保存车辆
export function _saveBikeInfo(data) {
  return axios.post('/bike/mng/saveBikeInfo', data)
}
//车辆调拨
export function _insertTransfer(data) {
  return axios.post('/pyTransfer/insertTransfer', data)
}
//电池调拨
export function _batteryInsertTransfer(data) {
  return axios.post('/pyTransfer/batteryInsertTransfer', data)
}
//ka车辆调拨
export function _kaBikeTransfer(data) {
  return axios.post('/kaFranchisee/mng/kaBikeTransfer', data)
}
//车辆调拨
export function _pyStockSelectSize(data) {
  return axios.post('/pyStock/selectSize', data)
}
//电池调拨记录
export function _kaBatteryTransferList(data) {
  return axios.post('/kaFranchisee/mng/kaBatteryTransferList', data)
}
//车辆调拨记录
export function _kaBikeTransferList(data) {
  return axios.post('/kaFranchisee/mng/kaBikeTransferList', data)
}
// ka站点库存
export function _kaPyStockSelectSize(data) {
  return axios.post('/kaFranchisee/mng/selectKaStock', data)
}
//车辆调拨
export function _pyStockBikeLis(data) {
  return axios.post('/pyStock/bikeList', data)
}
//ka站点车辆库存列表
export function _kaPyStockBikeLis(data) {
  return axios.post('/kaFranchisee/mng/kaBikeStockList', data)
}
//车辆调拨
export function _pyStockBatteryList(data) {
  return axios.post('/pyStock/batteryList', data)
}
// ka电池库存
export function _kaPyStockBatteryList(data) {
  return axios.post('/kaFranchisee/mng/kaBatteryStockList', data)
}
//车辆调拨
export function _bikeRecordList(data) {
  return axios.post('/pyStock/bikeRecordList', data)
}
//ka车辆库存记录
export function _kaBikeRecordList(data) {
  return axios.post('/kaFranchisee/mng/KaBikeStockRecordList', data)
}
//车辆调拨
export function _batteryRecordList(data) {
  return axios.post('/pyStock/batteryRecordList', data)
}
//电池库存记录
export function _kaBatteryRecordList(data) {
  return axios.post('/kaFranchisee/mng/kaBatteryStockRecordList', data)
}
//车辆调拨
export function _updateTransferDetailed(data) {
  return axios.post('/pyTransfer/updateTransferDetailed', data)
}
//车辆调拨
export function _transferExport(data) {
  return axios.post('/pyTransfer/transferExport', data)
}
//车辆调拨
export function _transferDetailedExport(data) {
  return axios.post('/pyTransfer/transferDetailedExport', data)
}
//投放车辆
export function _batchPushBike(data) {
  return axios.post('/bike/mng/batchPush', data)
}
//导入车辆
export function _batchImportBike(data) {
  return axios.post('/bike/mng/batchImport', data)
}
//更新车辆型号状态
export function _updateBikeModelStatus(data) {
  return axios.post('/bike/mng/updateBikeModelStatus', data)
}
//删除车辆型号
export function _deleteBikeModel(data) {
  return axios.post('/bike/mng/deleteBikeModel', data)
}
//车辆型号详情
export function _bikeModelDetail(data) {
  return axios.post('/bike/mng/bikeModelDetail', data)
}
//保存车辆型号
export function _saveBikeModel(data) {
  return axios.post('/bike/mng/saveBikeModel', data)
}
//车辆维修记录
export function _bikeRepairLogList(data) {
  return axios.post('/bikeRepair/mng/bikeRepairLogList ', data)
}
//电池类型列表
export function _getBatteryTypeList(data) {
  return axios.post('/express/mng/getBatteryTypeList', data)
}
//物流单列表
export function _expressRecordList(data) {
  return axios.post('/express/mng/expressRecordList', data)
}
//车辆GPS列表
export function _getGpsTypeList(data) {
  return axios.post('/express/mng/getGpsTypeList', data)
}
//导出物流单列表
export function _exportExpressRecord(data) {
  return axios.post('/express/mng/exportExpressRecord', data)
}
//导出物流单清单列表
export function _exportRecordDetailList(data) {
  return axios.post('/express/mng/exportRecordDetailList', data)
}
//物流单详情
export function _expressRecordDetail(data) {
  return axios.post('/express/mng/expressRecordDetail', data)
}
//更新物流单状态
export function _updateExpressStatus(data) {
  return axios.post('/express/mng/updateExpressStatus', data)
}
//更新清单状态
export function _updateDetailStatus(data) {
  return axios.post('/express/mng/updateDetailStatus', data)
}
//保存清单
export function _saveExpressDetail(data) {
  return axios.post('/express/mng/saveExpressDetail', data)
}
//保存物流单
export function _saveExpressRecord(data) {
  return axios.post('/express/mng/saveExpressRecord', data)
}
//物流单录入
export function _batchAddDetail(data) {
  return axios.post('/express/mng/batchAddDetail', data)
}
//支付方式列表
export function _getPayMethods(data) {
  return axios.post('/package/mng/getPayMethodsNew', data)
}
//调拨单列表
export function _selectTransfer(data) {
  return axios.post('/pyTransfer/selectTransfer', data)
}
//调拨单清单
export function _transferDetailed(data) {
  return axios.post('/pyTransfer/transferDetailed', data)
}
//终止调拨单
export function _endTransfer(data) {
  return axios.post('/pyTransfer/endTransfer', data)
}
//活动列表
export function _activityList(data) {
  return axios.post('/activity/mng/activityList', data)
}
//保存活动
export function _saveActivityInfo(data) {
  return axios.post('/activity/mng/saveActivityInfo', data)
}
//活动详情
export function _activityDetail(data) {
  return axios.post('/activity/mng/activityDetail', data)
}
//更新活动状态
export function _updateActivityStatus(data) {
  return axios.post('/activity/mng/updateActivityStatus', data)
}
//优惠券列表
export function _couponList(data) {
  return axios.post('/activity/mng/couponList', data)
}
//保存优惠券
export function _saveCouponInfo(data) {
  return axios.post('/activity/mng/saveCouponInfo', data)
}
//优惠券详情
export function _couponDetail(data) {
  return axios.post('/activity/mng/couponDetail', data)
}
//更新优惠券状态
export function _updateCouponStatus(data) {
  return axios.post('/activity/mng/updateCouponStatus', data)
}
//发放优惠券
export function _sendCoupon(data) {
  return axios.post('/activity/mng/sendCoupon', data)
}
//发放记录
export function _couponSendList(data) {
  return axios.post('/activity/mng/couponSendList', data)
}
//提现申请列表
export function _cashApplyList(data) {
  return axios.post('/user/mng/cashApplyList', data)
}
//更新提现申请状态
export function _updateCashApplyStatus(data) {
  return axios.post('/user/mng/updateCashApplyStatus', data)
}
//套餐列表
export function _packageList(data) {
  return axios.post('/package/mng/packageList', data)
}
//套餐列表(搜索列表)
export function _packageBoxList(data) {
  return axios.post('/package/mng/packageBoxList', data)
}
//导出套餐列表
export function _exportPackageList(data) {
  return axios.post('/package/mng/exportPackageList', data)
}
//删除套餐
export function _deletePackage(data) {
  return axios.post('/package/mng/deletePackage', data)
}
//批量删除套餐
export function _batchDeletePackage(data) {
  return axios.post('/package/mng/batchDelete', data)
}
//套餐申请
export function _packageApplyList(data) {
  return axios.post('/package/mng/packageApplyList', data)
}
//套餐审核
export function _updateApplyStatus(data) {
  return axios.post('/package/mng/updateApplyStatus', data)
}
//违约金维护
export function _savePackagesDiffConfigInfo(data) {
  return axios.post('/package/mng/savePackagesDiffConfigInfo', data)
}
//更新套餐状态
export function _updatePackageStatus(data) {
  return axios.post('/package/mng/updatePackageStatus', data)
}
//套餐详情
export function _packageDetail(data) {
  return axios.post('/package/mng/packageDetail', data)
}
//套餐地区
export function _getSonsOfRegionPackage(data) {
  return axios.post('/package/getSonsOfRegion', data)
}
//保存套餐
export function _savePackageInfo(data) {
  return axios.post('/package/mng/newSavePackages', data)
}
//获取套餐租期
export function _getRentType(data) {
  return axios.post('/package/mng/getRentType', data)
}
//获取绑定站点信息
export function _packageAuthDetail(data) {
  return axios.post('/package/mng/packageAuthDetail', data)
}
//获取换车站点信息
export function _packageAuthDetailTransfe(data) {
  return axios.post('/package/mng/packageAuthDetailTransfer', data)
}
//保存绑定站点信息
export function _savePackageAuth(data) {
  return axios.post('/package/mng/savePackageAuth', data)
}
//套餐车辆列表
export function _getBikeModels(data) {
  return axios.post('/package/mng/getBikeModels', data)
}
//套餐分润规则列表
export function _getPagePackageTranferRuleInfo(data) {
  return axios.post('/package/mng/getPagePackageTranferRuleList', data)
}
//删除套餐分润规则
export function _deletePackageTranferRuleInfo(data) {
  return axios.post('/package/mng/deletePackageTranferRuleInfo', data)
}
//保存套餐分润规则
export function _savePackageTranferRuleInfo(data) {
  return axios.post('/package/mng/savePackageTranferRuleInfo', data)
}
//套餐分润规则详情
export function _getRuleDetails(data) {
  const { id = '' } = data || {}
  return axios.get(`/package/mng/getRuleDetails/${id}`)
}
//维修分润规则列表
export function _repairTransferRules(data) {
  return axios.post('/transfer/mng/repairTransferRules', data)
}
//维修分润规则详情
export function _repairRuleDetail(data) {
  return axios.post('/transfer/mng/repairRuleDetail', data)
}
//保存维修分润规则
export function _saveRepairTransferRule(data) {
  return axios.post('/transfer/mng/saveRepairTransferRule', data)
}
//更新维修分润规则状态
export function _updateTransferRuleStatus(data) {
  return axios.post('/transfer/mng/updateTransferRuleStatus', data)
}
//电池分润规则列表
export function _getBatteryModelRuleList(data) {
  return axios.post('/package/mng/getBatteryModelRuleList', data)
}
//删除电池分润规则
export function _deleteBatteryModelRuleInfo(data) {
  return axios.post('/package/mng/deleteBatteryModelRuleInfo', data)
}
//保存电池分润规则
export function _saveBatteryModelRuleInfo(data) {
  return axios.post('/package/mng/saveBatteryModelRuleInfo', data)
}
//电池分润规则详情
export function _getBatteryModelRuleDetails(data) {
  return axios.post('/package/mng/getBatteryModelRuleDetails', data)
}
//分润规则配置列表
export function _getSettlementRulesList(data) {
  return axios.post('/package/mng/getSettlementRulesList', data)
}
//删除分润规则配置
export function _deleteSettlementRuleInfo(data) {
  return axios.post('/package/mng/deleteSettlementRuleInfo', data)
}
//保存分润规则配置
export function _saveSettlementRuleInfo(data) {
  return axios.post('/package/mng/saveSettlementRuleInfo', data)
}
//分润规则配置详情
export function _getSettlementRuleDetails(data) {
  return axios.post('/package/mng/getSettlementRuleDetails', data)
}
//套餐订单列表
export function _packageOrderList(data) {
  return axios.post('/order/mng/packageOrderList', data)
}
//导出套餐订单
export function _exportPackageOrder(data) {
  return axios.post('/order/mng/exportPackageOrder', data)
}
//履约记录列表
export function _performanceRecordList(data) {
  return axios.post('/order/mng/performanceRecordList', data)
}
//预授权押金列表
export function _authInfo(data) {
  return axios.post('/order/mng/authInfo', data)
}
//取消履约记录
export function _cancelDeductLog(data) {
  return axios.post('/order/mng/cancelDeductLog', data)
}
//订单备注列表
export function _orderRemarkList(data) {
  return axios.post('/order/mng/orderRemarkList', data)
}
//提交订单备注
export function _saveOrderRemark(data) {
  return axios.post('/order/mng/saveOrderRemark', data)
}
//状态变更列表
export function _orderStatusLogRecordList(data) {
  return axios.post('/order/mng/orderStatusLogRecordList', data)
}
//订单续租列表
export function _packageOrderLogList(data) {
  return axios.post('/order/mng/packageOrderLogList', data)
}
//订单图片
export function _orderDeviceImages(data) {
  return axios.post('/order/mng/orderDeviceImages', data)
}
//订单变更列表
export function _orderChangeList(data) {
  return axios.post('/order/mng/orderChangeList', data)
}
//订单退款
export function _orderRefund(data) {
  return axios.post('/refund/orderRefund', data)
  // return axios.post("/packageOrder/mng/orderRefund", data);
}
//订单退款回调
export function _orderRefundDetails(data) {
  return axios.post('/order/orderRefundDetails', data)
}
//订单退款记录
export function _orderRefundLogs(data) {
  return axios.post('/order/mng/orderRefundLogs', data)
}
//取消订单
export function _orderCancel(data) {
  return axios.post('/packageOrder/mng/orderCancel', data)
}
//挂起订单
export function _orderHangUp(data) {
  return axios.post('/packageOrder/mng/orderHangUp', data)
}
//获取订单逾期租金违约金信息
export function _orderDiffInfo(data) {
  return axios.post('/order/mng/orderDiffInfo', data)
}
//获取订单违约金信息
export function _orderPenaltyInfo(data) {
  return axios.post('/order/mng/orderPenaltyInfo', data)
}
//调单(站点)
export function _orderChange(data) {
  return axios.post('/packageOrder/mng/newOrderChange', data)
}
//调单(退车点)
export function _orderChangeSiteRepair(data) {
  return axios.post('/packageOrder/mng/newTuiOrderChange', data)
}
//调单站点关联列表
export function _selectOrderChange(data) {
  return axios.post('/packageOrder/mng/selectoOrderChange', data)
}
//修改订单
export function _savePackageOrder(data) {
  return axios.post('/order/mng/savePackageOrder', data)
}
//订单代付管理
export function _applyList(data) {
  return axios.post('/paymentApply/mng/applyList', data)
}
//导出订单代付
export function _exportApplyList(data) {
  return axios.post('/paymentApply/mng/exportApplyList', data)
}
//代付二维码
export function _getPayCode(data) {
  return axios.post('/paymentApply/mng/getPayCode', data)
}
//今日订单列表
export function _todayBillList(data) {
  return axios.post('/order/mng/todayBillList', data)
}
//导出今日订单
export function _exportTodayBillList(data) {
  return axios.post('/order/mng/exportTodayBillList', data)
}
//线下处理订单列表
export function _exceptionOrderList(data) {
  return axios.post('/order/mng/exceptionOrderList', data)
}
//查询订单
export function _queryExistOrder(data) {
  return axios.post('/order/mng/queryExistOrder', data)
}
//保存线下处理订单
export function _saveExceptionOrder(data) {
  return axios.post('/order/mng/saveExceptionOrder', data)
}
//线下处理订单详情
export function _orderExceptionDetail(data) {
  return axios.post('/order/mng/orderExceptionDetail', data)
}
//线下处理订单审核
export function _exceptionOrderStatus(data) {
  return axios.post('/order/mng/exceptionOrderStatus', data)
}
//导出线下处理订单
export function _exportExceptionOrder(data) {
  return axios.post('/order/mng/exportExceptionOrder', data)
}
//套餐即时分润列表
export function _packageTransferList(data) {
  return axios.post('/packageOrder/mng/packageTransferList', data)
}
//导出套餐即时分润
export function _exportTransferLog(data) {
  return axios.post('/packageOrder/mng/exportTransferLog', data)
}
//资产报表
export function _propertyList(data) {
  return axios.post('/order/mng/propertyList', data)
}
//订单报表
export function _operateOrderList(data) {
  return axios.post('/order/mng/operateOrderList ', data)
}
//超时订单报表
export function _outTimeOrderList(data) {
  return axios.post('/order/mng/outTimeOrderList', data)
}
//资产报表导出
export function _propertyListExport(data) {
  return axios.post('/order/mng/propertyListExport', data)
}
//订单报表导出
export function _operateOrderListExport(data) {
  return axios.post('/order/mng/operateOrderListExport', data)
}
//超时订单报表导出
export function _outTimeOrderListExport(data) {
  return axios.post('/order/mng/outTimeOrderListExport', data)
}
//订单报表
export function _orderStaticList(data) {
  return axios.post('/static/mng/orderStaticList', data)
}
//导出订单报表
export function _exportOrderStatic(data) {
  return axios.post('/static/mng/exportOrderStatic', data)
}
//套餐分润审核
export function _packageOrderUpdateTransferStatus(data) {
  return axios.post('/packageOrder/mng/updateTransferStatus', data)
}
//账单管理
export function _billingManagementList(data) {
  return axios.post('/order/mng/billingManagementList', data)
}
//导出账单
export function _exportPackageOrderBill(data) {
  return axios.post('/order/mng/exportPackageOrderBill', data)
}
//车架列表
export function _bikeStaticList(data) {
  return axios.post('/static/mng/bikeStaticList', data)
}
//车架订单列表
export function _bikeOrderStaticList(data) {
  return axios.post('/static/mng/bikeOrderStaticList', data)
}
//导出车架
export function _exportBikeStatic(data) {
  return axios.post('/static/mng/exportBikeStatic', data)
}
//写入车架
export function _insertBikePayed(data) {
  return axios.post('/static/mng/insertBikePayed', data)
}
//租赁分润列表
export function _profitStaticList(data) {
  return axios.post('/static/mng/profitStaticList', data)
}
//导出租赁分润
export function _exportProfitStatic(data) {
  return axios.post('/static/mng/exportProfitStatic', data)
}
//实付逾期租金违约金列表
export function _diffRecordList(data) {
  return axios.post('/static/mng/diffRecordList', data)
}
//导出实付逾期租金违约金
export function _exportDiffRecord(data) {
  return axios.post('/static/mng/exportDiffRecord', data)
}
//实付免赔列表
export function _insureRecordList(data) {
  return axios.post('/static/mng/insureRecordList', data)
}
//导出实付免赔
export function _exportInsureRecord(data) {
  return axios.post('/static/mng/exportInsureRecord', data)
}
//套餐车辆即时分润列表
export function _bikeTransferList(data) {
  return axios.post('/bike/mng/bikeTransferList', data)
}
//导出车辆套餐即时分润
export function _exportTransferLogBike(data) {
  return axios.post('/bike/mng/exportTransferLog', data)
}
//发放记录
export function _exportCouponSend(data) {
  return axios.post('/activity/mng/exportCouponSend', data)
}
//套餐电池即时分润列表
export function _batteryTransferList(data) {
  return axios.post('/battery/mng/batteryTransferList', data)
}
//导出电池套餐即时分润
export function _exportTransferLogBattery(data) {
  return axios.post('/battery/mng/exportTransferLog', data)
}
//电池分润审核
export function _batteryUpdateTransferStatus(data) {
  return axios.post('/battery/mng/updateTransferStatus', data)
}
//KA维修车辆管理列表
export function _repBikeList(data) {
  return axios.post('/repBike/mng/repBikeList', data)
}
//车辆详情
export function _repBikeDetail(data) {
  return axios.post('/repBike/mng/repBikeDetail', data)
}
//添加车辆
export function _saveRepBikeInfo(data) {
  return axios.post('/repBike/mng/saveRepBikeInfo', data)
}
//删除车辆
export function _deleteRepBike(data) {
  return axios.post('/repBike/mng/deleteRepBike', data)
}
//批量删除车辆
export function _deleteRepBikes(data) {
  return axios.post('/repBike/mng/deleteRepBikes', data)
}
//更新车辆状态
export function _updateRepBikeStatus(data) {
  return axios.post('/repBike/mng/updateRepBikeStatus', data)
}
//KA维修车辆型号列表
export function _repBikeModelList(data) {
  return axios.post('/repBikeModel/mng/repBikeModelList', data)
}
//添加车辆型号
export function _addRepBikeModel(data) {
  return axios.post('/repBikeModel/mng/addRepBikeModel', data)
}
//车辆型号详情
export function _repBikeModelDetail(data) {
  return axios.post('/repBikeModel/mng/repBikeModelDetail', data)
}
//删除车辆型号
export function _deleteRepBikeModel(data) {
  return axios.post('/repBikeModel/mng/deleteRepBikeModel', data)
}
//更新车辆型号状态
export function _updateRepBikeModelStatus(data) {
  return axios.post('/repBikeModel/mng/updateRepBikeModelStatus', data)
}
//维修用户管理(KA)
export function _repCustomerList(data) {
  return axios.post('/repCustomer/mng/repCustomerList', data)
}
//维修用户详情(KA)
export function _repCustomerDetail(data) {
  return axios.post('/repCustomer/mng/repCustomerDetail', data)
}
//添加维修用户(KA)
export function _addRepCustomer(data) {
  return axios.post('/repCustomer/mng/addRepCustomer', data)
}
//删除维修用户(KA)
export function _deleteRepCustomerStatus(data) {
  return axios.post('/repCustomer/mng/deleteRepCustomerStatus', data)
}
//批量删除维修用户(KA)
export function _batchDeleteRepCustomer(data) {
  return axios.post('/repCustomer/mng/batchDeleteRepCustomer', data)
}
//更新维修管理状态(KA)
export function _updateRepCustomerStatus(data) {
  return axios.post('/repCustomer/mng/updateRepCustomerStatus', data)
}
//维修代理商管理
export function _repAgentList(data) {
  return axios.post('/repAgent/mng/repAgentList', data)
}
//维修代理商详情
export function _repAgentDetail(data) {
  return axios.post('/repAgent/mng/repAgentDetail', data)
}
//添加维修代理商
export function _saveRepAgent(data) {
  return axios.post('/repAgent/mng/saveRepAgent', data)
}
//删除维修代理商
export function _deleteRepAgent(data) {
  return axios.post('/repAgent/mng/deleteRepAgent', data)
}
//批量删除维修代理商
export function _batchDeleteRepAgent(data) {
  return axios.post('/repAgent/mng/batchDeleteRepAgent', data)
}
//更新维修代理商
export function _updateRepAgent(data) {
  return axios.post('/repAgent/mng/updateRepAgent', data)
}
//维修师管理
export function _repManList(data) {
  return axios.post('/repMan/mng/repManList', data)
}
//维修师详情
export function _repManDetail(data) {
  return axios.post('/repMan/mng/repManDetail', data)
}
//添加维修师
export function _addRepMan(data) {
  return axios.post('/repMan/mng/addRepMan', data)
}
//删除维修师
export function _deleteRepMan(data) {
  return axios.post('/repMan/mng/deleteRepMan', data)
}
//批量删除维修师
export function _batchDeleteRepMan(data) {
  return axios.post('/repMan/mng/batchDeleteRepMan', data)
}
//更新维修师状态
export function _updateRepManStatus(data) {
  return axios.post('/repMan/mng/updateRepManStatus', data)
}
//骑手管理
export function _repRiderList(data) {
  return axios.post('/repRider/mng/repRiderList', data)
}
//骑手详情
export function _repRiderDetail(data) {
  return axios.post('/repRider/mng/repRiderDetail', data)
}
//添加骑手
export function _addRepRider(data) {
  return axios.post('/repRider/mng/addRepRider', data)
}
//删除骑手
export function _deleteRepRiderStatus(data) {
  return axios.post('/repRider/mng/deleteRepRiderStatus', data)
}
//批量删除骑手
export function _batchDeleteRepRider(data) {
  return axios.post('/repRider/mng/batchDeleteRepRider', data)
}
//更新骑手状态
export function _updateRepRiderStatus(data) {
  return axios.post('/repRider/mng/updateRepRiderStatus', data)
}
//流水号报表
export function _packagesOrderLogList(data) {
  return axios.post('/order/mng/packagesOrderLogList', data)
}
//流水号报表导出
export function _exportpackagesOrderLogList(data) {
  return axios.post('/order/mng/exportpackagesOrderLogList', data)
}
//履约记录报表
export function _allPerformanceRecordList(data) {
  return axios.post('/order/mng/allPerformanceRecordList', data)
}
//履约记录导出
export function _exportAllPerformanceRecordList(data) {
  return axios.post('/order/mng/exportAllPerformanceRecordList', data)
}
//换车列表
export function _transferList(data) {
  return axios.post('/transfer/transfer/transferList', data)
}
//换车站点列表
export function _transferSite(data) {
  return axios.post('/transfer/transfer/transferSite', data)
}
//更换换车站点
export function _updateTransferSite(data) {
  return axios.post('/transfer/transfer/updateTransferSite', data)
}
//换车列表导出
export function _exportInsureTransferLog(data) {
  return axios.post(
    '/transfer/transfer/exportInsureTransferBatteryBikeLog',
    data
  )
}
//换车详情
export function _transferBatteryBikeInfo(data) {
  return axios.post('/transfer/transfer/transferBatteryBikeInfo', data)
}
//KA型号价格列表
export function _modelPriceList(data) {
  return axios.post('/modelPrice/mng/list', data)
}
//KA型号价格列表
export function _modelPriceSave(data) {
  return axios.post('/modelPrice/mng/save', data)
}
//KA型号价格详情
export function _modelPriceDetail(data) {
  return axios.post('/modelPrice/mng/detail', data)
}
//KA型号状态更新
export function _modelPriceUpdateStatus(data) {
  return axios.post('/modelPrice/mng/updateStatus', data)
}
//出账时间设置
export function _updateBillDate(data) {
  return axios.post('/express/mng/updateBillDate', data)
}
//会员余额列表
export function _userAccountList(data) {
  return axios.post('/franchisee/mng/userAccountList', data)
}
//会员余额列表导出
export function _userAccountExport(data) {
  return axios.post('/franchisee/mng/userAccountExport', data)
}
//会员总余额
export function _allAmount(data) {
  return axios.post('/franchisee/mng/allAmount', data)
}
//会员充值列表
export function _userRechargeList(data) {
  return axios.post('/franchisee/mng/userRechargeList', data)
}
//会员充值列表导出
export function _userRechargeExport(data) {
  return axios.post('/franchisee/mng/userRechargeExport', data)
}
//会员提现申请列表
export function _extractApplyList(data) {
  return axios.post('/franchisee/mng/extractApplyList', data)
}
//会员提现申请列表导出
export function _extractApplyExport(data) {
  return axios.post('/franchisee/mng/extractApplyExport', data)
}
//会员提现申请审核
export function _extractProcess(data) {
  return axios.post('/franchisee/mng/extractProcess', data)
}
//流水号报表
export function _tradeList(data) {
  return axios.post('/kaTrade/mng/tradeList', data)
}
//财务账单
export function _billList(data) {
  return axios.post('/kaTrade/mng/billList', data)
}
//账单详情
export function _billDetail(data) {
  return axios.post('/kaTrade/mng/billDetail', data)
}
//更新账单状态
export function _updateBillStatus(data) {
  return axios.post('/kaTrade/mng/updateBillStatus', data)
}
//余额列表
export function _balanceList(data) {
  return axios.post('/kaTrade/mng/balanceList', data)
}
//ka账户余额
export function _kaAccountDetail(data) {
  return axios.post('/kaTrade/mng/accountDetail', data)
}
//ka银行卡充值
export function _addAccountBalance(data) {
  return axios.post('/kaTrade/mng/addAccountBalance', data)
}
//ka获取充值码
export function _getAccountPayCode(data) {
  return axios.post('/kaTrade/mng/getAccountPayCode', data)
}
//收款方信息
export function _getAccountInfo(data) {
  return axios.post('/kaTrade/mng/getAccountInfo', data)
}
//充值详情
export function _balanceDetail(data) {
  return axios.post('/kaTrade/mng/balanceDetail', data)
}
//获取账单充值码
export function _getBillPayCode(data) {
  return axios.post('/kaTrade/mng/getBillPayCode', data)
}
//账单支付
export function _payBill(data) {
  return axios.post('/kaTrade/mng/payBill', data)
}
//账单支付记录
export function _payBilInfo(data) {
  return axios.post('/kaTrade/mng/payBilInfo', data)
}
//余额支付
export function _payBillAccount(data) {
  return axios.post('/kaTrade/mng/payBillAccount', data)
}
//会员信息
export function _kaUserReturn(data) {
  return axios.post('/kaFranchisee/mng/kaUserReturn', data)
}
//电池报警列表
export function _batteryAlarmList(data) {
  return axios.post('/battery/mng/batteryAlarmList', data)
}
//导出电池报警
export function _exportBatteryAlarmList(data) {
  return axios.post('/battery/mng/exportBatteryAlarmList', data)
}
//异常类型列表
export function _getBatteryAlarmType(data) {
  return axios.post('/express/mng/getBatteryAlarmType', data)
}
//电池异常列表
export function _batteryAlarmImei(data) {
  return axios.post('/battery/mng/batteryAlarmImei', data)
}
//导出电柜列表
export function _exportBatteryChargeStatic(data) {
  return axios.post('/charge/mng/exportBatteryChargeStatic', data)
}
//支付宝用户意见反馈列表
export function _userComplainList(data) {
  return axios.post('/pcUser/mng/userComplainList', data)
}
//处理投诉反馈查看
export function _complainHandleList(data) {
  return axios.post('/pcUser/mng/complainHandleList', data)
}
//支付宝用户处理投诉
export function _updateComplain(data) {
  return axios.post('/pcUser/mng/updateComplain', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
//投诉单消息数
export function _complainMsgSize(data) {
  return axios.post('/pcUser/mng/complainMsgSize', data)
}
//支付宝投诉列表导出
export function _userComplainListExport(data) {
  return axios.post('/pcUser/mng/userComplainListExport', data)
}
//用户反馈列表导出
export function _complainListExport(data) {
  return axios.post('/user/mng/userComplainListExport', data)
}

//高德地图
export function _mapGD(data) {
  return axios.get('https://restapi.amap.com/v3/geocode/regeo', data)
}

//图片上传
export function _imageUpload() {
  return AXIOS_URL + '/file/mng/imageUpload'
}

//电池上传
export function _uploadBattery() {
  return AXIOS_URL + '/battery/mng/uploadBattery'
}

//文件上传
export function _upload() {
  return AXIOS_URL + '/file/mng/upload'
}

//车辆轨迹
export function _getGpsHis(data) {
  return axios.post('/bike/mng/getGpsHis', data)
}
//车辆行程
export function _getTrackSegment(data) {
  return axios.post('/bike/mng/getTrackSegment', data)
}
//车辆告警
export function _getAlarm(data) {
  return axios.post('/bike/mng/getAlarm', data)
}
// 车辆停留
export function _getBikeStop(data) {
  return axios.post('/bike/mng/getBikeStop', data)
}
// 查询推荐人
export function _queryTjUser(data) {
  return axios.post('/user/mng/queryTjUser', data)
}
// 修改推荐人
export function _updateTjUser(data) {
  return axios.post('/user/mng/updateTjUser', data)
}
// 推荐人历史记录
export function _userTjLogList(data) {
  return axios.post('/user/mng/userTjLogList', data)
}
// 选中套餐列表
export function _batchGetPackage(data) {
  return axios.post('/package/mng/batchGetPackage', data)
}
// 套餐批量绑定站点
export function _saveSitePackage(data) {
  return axios.post('/package/mng/saveSitePackage', data)
}
// 流水结算
export function _orderLogSettle(data) {
  return axios.post('/packageOrder/mng/orderLogSettle', data)
}
// gps列表
export function _gpsList(data) {
  return axios.post('/white/gps/whiteList', data)
}
// gps状态
export function _gpsStatus(data) {
  return axios.post('/white/gps/updateWhiteStatus', data)
}
// gps导出
export function _exportGpsList(data) {
  return axios.post('/white/gps/exportWhiteList', data)
}
// gps删除
export function _deleteGps(data) {
  return axios.post('/white/gps/deleteWhite', data)
}
// gps导入
export function _gpsImport(data) {
  return axios.post('/white/gps/gpsImport', data)
}
// 金租电池统计
export function _batteryAmount(data) {
  return axios.post('/battery/mng/batteryAmount', data)
}
// 金租电池定位
export function _getBatteryPositionInfo(data) {
  return axios.post('/battery/mng/getBatteryPositionInfo', data)
}
// 获取套餐二维码
export function _getPackageQRCode(data) {
  return axios.post('/package/mng/getQRCode', data)
}

// 自营数据车型分组
export function _modelSelfBikeDataList(data) {
  return axios.post('/operateData/modelSelfBikeDataList', data)
}
// 自营数据车型分组导出
export function _modelSelfBikeDataExport(data) {
  return axios.post('/operateData/modelSelfBikeDataExport', data)
}
// 自营数据站点分组
export function _siteSelfBikeDataList(data) {
  return axios.post('/operateData/siteSelfBikeDataList', data)
}
// 自营数据站点分组导出
export function _siteSelfBikeDataExport(data) {
  return axios.post('/operateData/siteSelfBikeDataExport', data)
}
// 站点车辆数据报表
export function _operateSiteBikeList(data) {
  return axios.post('/operateData/operateSiteBikeList', data)
}
// 站点车辆数据报表导出
export function _operateSiteBikeExport(data) {
  return axios.post('/operateData/operateSiteBikeExport', data)
}
// BD自营电池数据
export function _operateBdBatteryList(data) {
  return axios.post('/operateData/operateBdBatteryList', data)
}
// BD自营电池数据导出
export function _operateBdBatteryExport(data) {
  return axios.post('/operateData/operateBdBatteryExport', data)
}
// 日订单报表数据
export function _operateDayBikeOrderList(data) {
  return axios.post('/operateData/operateDayBikeOrderList', data)
}
// 日订单报表数据导出
export function _operateDayBikeOrderExport(data) {
  return axios.post('/operateData/operateDayBikeOrderExport', data)
}
// 订单报表数据(周，月)
export function _operateBikeOrderList(data) {
  return axios.post('/operateData/operateBikeOrderList', data)
}
// 订单报表数据导出(周，月)导出
export function _operateBikeOrderExport(data) {
  return axios.post('/operateData/operateBikeOrderExport', data)
}
// bd订单报表（周）
export function _operateBdOrderList(data) {
  return axios.post('/operateData/operateBdOrderList', data)
}
// bd订单报表数据导出(周)
export function _operateBdOrderExport(data) {
  return axios.post('/operateData/operateBdOrderExport', data)
}
// 代理商套餐订单报表(月)
export function _operateFranchiseePackageList(data) {
  return axios.post('/operateData/operateFranchiseePackageList', data)
}
// 代理商套餐订单报表数据导出(月)
export function _operateFranchiseePackageExport(data) {
  return axios.post('/operateData/operateFranchiseePackageExport', data)
}
// 套餐订单数据（周，月）
export function _operatePackageOrder(data) {
  return axios.post('/operateData/operatePackageOrder', data)
}
// 套餐订单数据导出（周，月）
export function _operatePackageOrderExport(data) {
  return axios.post('/operateData/operatePackageOrderExport', data)
}
// 超时订单报表（周，月）
export function _operateTimeOutOrderList(data) {
  return axios.post('/operateData/operateTimeOutOrderList', data)
}
// 超时订单报表导出（周，月）
export function _operateTimeOutOrderExport(data) {
  return axios.post('/operateData/operateTimeOutOrderExport', data)
}
// 超时订单处理效率报表（周，月）
export function _operateOutOrderHandleList(data) {
  return axios.post('/operateData/operateOutOrderHandleList', data)
}
// 超时订单处理效率报表导出
export function _operateOutOrderHandleExport(data) {
  return axios.post('/operateData/operateOutOrderHandleExport', data)
}
// 超时订单处理效率(月)
export function _operateMonthOutOrderHandleList(data) {
  return axios.post('/operateData/operateMonthOutOrderHandleList', data)
}
// 超时订单处理效率月报表导出
export function _operateMonthOutOrderHandleListExport(data) {
  return axios.post('/operateData/operateMonthOutOrderHandleListExport', data)
}

// 套餐下拉
export function _packageNameList(data) {
  return axios.post('/operateData/packageNameList', data)
}
// bd下拉
export function _bdNameList(data) {
  return axios.post('/operateData/bdNameList', data)
}

// 订单排行
export function _corporationOrderRanking(data) {
  return axios.get('/corporationOrderRanking', data)
}
// 全国订单
export function _corporationMapOrderList(data) {
  return axios.get('/corporationMapOrderList', data)
}
// 营业额
export function _corporationMoneySumList(data) {
  return axios.get('/corporationMoneySumList', data)
}
// 月订单数
export function _monthOrderSize(data) {
  return axios.get('/monthOrderSize', data)
}
// 月营业额
export function _moneySumList(data) {
  return axios.get('/moneySumList', data)
}
// 营业额排行
export function _corporationMoneyRankingList(data) {
  return axios.get('/corporationMoneyRankingList', data)
}
// 汇总数据
export function _selectAllSize(data) {
  return axios.get('/selectAllSize', data)
}

// 超时工单
export function _orderSurrenderList(data) {
  return axios.post('/surrender/mng/orderSurrenderList', data)
}
// 超时工单导出
export function _exportSurrenderList(data) {
  return axios.post('/surrender/mng/exportSurrenderList', data)
}
// 挂起代扣成功列表
export function _outTimePayList(data) {
  return axios.post('/order/mng/outTimePayList', data)
}
// 挂起代扣成功列表导出
export function _outTimePayListExport(data) {
  return axios.post('/order/mng/outTimePayListExport', data)
}
// 首页数据
export function _HomeData(data) {
  return axios.post('/bike/mng/HomeData', data)
}
// 修改金额
export function _modifyPrice(data) {
  return axios.post('/order/mng/modifyPrice', data)
}

//卡付用户意见反馈列表
export function _baoComplainList(data) {
  return axios.post('/pcUser/mng/baoComplainList', data)
}
//卡付用户意见反馈列表导出
export function _baoComplainListExport(data) {
  return axios.post('/pcUser/mng/baoComplainListExport', data)
}
//卡付用户意见反馈处理
export function _baoUpdateComplain(data) {
  return axios.post('/pcUser/mng/baoUpdateComplain', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//代扣申诉列表
export function _withholdComplainList(data) {
  return axios.post('/complain/mng/complainList', data)
}
//代扣申诉拒绝
export function _withholdUpdateComplain(data) {
  return axios.post('/complain/mng/updateComplain', data)
}
//代扣申诉导出
export function _exportWithholdComplainList(data) {
  return axios.post('/complain/mng/exportComplainList', data)
}
//代扣申诉详情
export function _detailsComplain(data) {
  return axios.post('/complain/mng/detailsComplain', data)
}
//推荐人分润规则列表
export function _tjConfigList(data) {
  return axios.post('/config/mng/tjConfigList', data)
}
//添加推荐人分润规则
export function _saveTjConfig(data) {
  return axios.post('/config/mng/saveTjConfig', data)
}
//更新推荐人分润规则
export function _updateTjConfigStatus(data) {
  return axios.post('/config/mng/updateTjConfigStatus', data)
}
//推荐人分润规则详情
export function _tjConfigDetail(data) {
  return axios.post('/config/mng/tjConfigDetail', data)
}
//导出推荐人分润规则
export function _exportTjConfigList(data) {
  return axios.post('/config/mng/exportTjConfigList', data)
}
//申诉提醒
export function _complainSize(data) {
  return axios.post('/complain/mng/complainSize', data)
}
//批量控制电池指令
export function _batteryControl(data) {
  return axios.post('/battery/mng/BatteryControl', data)
}
//换电电池列表
export function _batteryChargingList(data) {
  return axios.post('/battery/mng/batteryChargingList', data)
}
//换电电池使用订单
export function _batteryChargingDetail(data) {
  return axios.post('/battery//mng/batteryChargingDetail', data)
}
//查询苏宁支付比例
export function _getSnRatio(data) {
  return axios.post('/pay/get_sn_ratio', data)
}
//修改苏宁支付比例
export function _updateSnRatio(data) {
  return axios.post('/pay/update_sn_ratio', data)
}
//还款履约期数
export function _dayDeductMonth(data) {
  return axios.post('/dayDeduct/mng/dayDeductMonth', data)
}
//还款履约记录
export function _dayDeductList(data) {
  return axios.post('/dayDeduct/mng/dayDeductList', data)
}
//生成日还款
export function _payment(data) {
  return axios.post('/manage/pay/payment', data)
}
//GPS白名单详情
export function _whiteDetail(data) {
  return axios.post('/white/gps/whiteDetail', data)
}
//GPS白名单编辑
export function _saveWhite(data) {
  return axios.post('/white/gps/saveWhite', data)
}
//查询开关状态
export function _get_on_off_type(data) {
  return axios.post('/manage/pay/get_on_off_type', data)
}
//更改开关状态
export function _update_on_off_type(data) {
  return axios.post('/manage/pay/update_on_off_type', data)
}
//取消未支付的日代扣订单
export function _cancelDayDeduct(data) {
  return axios.post('/dayDeduct/mng/cancelDayDeduct', data)
}
//分期付订单汇总
export function _dayDayRepaymentList(data) {
  return axios.post('/dayDeduct/mng/dayDayRepaymentList', data)
}
//分期付订单明细表
export function _dayDayRepaymentInfoList(data) {
  return axios.post('/dayDeduct/mng/dayDayRepaymentInfoList', data)
}
//分期付订单汇总导出
export function _exportDayRepaymentList(data) {
  return axios.post('/dayDeduct/mng/exportDayRepaymentList', data)
}
//分期付订单明细表导出
export function _exportDayRepaymentInfoList(data) {
  return axios.post('/dayDeduct/mng/exportDayRepaymentInfoList', data)
}
//分期付订单角标
export function _overdueCount(data) {
  return axios.post('/dayDeduct/mng/overdueCount', data)
}
//日还款逾期查询
export function _dayOverdueReports(data) {
  return axios.post('/report/overdueReports/day', data)
}
//日还款逾期导出
export function _dayOverdueReportsExport(data) {
  return axios.post('/report/overdueReports/day/export', data)
}
//月还款逾期查询
export function _monthOverdueReports(data) {
  return axios.post('/report/overdueReports/month', data)
}
//月还款逾期导出
export function _monthOverdueReportsExport(data) {
  return axios.post('/report/overdueReports/month/export', data)
}
//还款逾期统计
export function _overdueReportsStatistics(data) {
  return axios.post('/report/overdueReports/statistics', data)
}

//分润规则ID获取分润套餐详情
export function _getRuleDetailsById(data) {
  return axios.post('/package/mng/getRuleDetails', data)
}

//分润规则导出
export function _exportPackageTransferRuleList(data) {
  return axios.post('/package/mng/exportPackageTransferRuleList', data)
}

//挂起详情
export function _orderLiquidated(data) {
  return axios.post('/packageOrder/mng/orderLiquidated', data)
}

//订单挂起
export function _newOrderHangHp(data) {
  return axios.post('/packageOrder/mng/newOrderHangHp', data)
}
//取消挂起
export function _cancelOrderHangHp(data) {
  return axios.post('/packageOrder/mng/cancelOrderHangHp', data)
}
//导出分润规则
export function _exportSettlementRulesList(data) {
  return axios.post('/package/mng/exportSettlementRulesList', data)
}
//历史逾期订单
export function _historyOverdueReports(data) {
  return axios.post('/report/overdueReports/history', data)
}
//导出历史逾期订单
export function _exportHistoryOverdueReports(data) {
  return axios.post('/report/overdueReports/history/export', data)
}
//逾期总表
export function _allOverdueReports(data) {
  return axios.post('/report/overdueReports/all', data)
}
//导出逾期总表
export function _exportAllOverdueReports(data) {
  return axios.post('/report/overdueReports/all/export', data)
}
/**
 * 电催报表
 * @param {fId, sId, timeStart, timeEnd, orderSn, callResult, settle, contactStr} data
 * @returns
 */
export function _urgentDetailList(data) {
  return axios.post('/packageOrder/mng/urgentDetailList', data)
}
/**
 * 电催报表导出
 * @param {fId, sId, timeStart, timeEnd, orderSn, callResult, settle, contactStr} data
 * @returns
 */
export function _exportUrgentDetail(data) {
  return axios.post('/packageOrder/mng/exportUrgentDetail', data)
}
/**
 * 结算详情
 * @param {fId, sId, timeStart, timeEnd, orderSn, callResult, settle, contactStr} data
 * @returns
 */
export function _urgentSettleInfo(data) {
  return axios.post('/packageOrder/urgent/settleInfo', data)
}
/**
 * 确认结算
 * @param {fId, sId, timeStart, timeEnd, orderSn, callResult, settle, contactStr} data
 * @returns
 */
export function _confirmSettle(data) {
  return axios.post('/packageOrder/urgent/confirmSettle', data)
}
//退车点下拉
export function _packageAuthDetailReturn(data) {
  return axios.post('/package/mng/packageAuthDetailReturn', data)
}
//挂起退车点下拉
export function _orderHangHpSite(data) {
  return axios.post('/packageOrder/mng/orderHangHpSite', data)
}
// 设备异常状态查询
export function _checkDeviceException(data) {
  return axios.post('/battery/mng/checkBattery', data)
}
