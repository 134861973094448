import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Cascader,
  Select,
  Modal,
  Form,
  Row,
  Col,
  message,
  AutoComplete
} from "antd";
import {
  _repAgentList,
  _repManList,
  _deleteRepMan,
  _batchDeleteRepMan,
  _updateRepManStatus,
  _repManDetail,
  _getSonsOfRegion,
  _addRepMan,
  _mapGD,
} from "@/statics/js/api";
import { Map, Marker } from "react-amap";

const amapkey = "4792ad0f37a527c1535e83599762d894";


const { Option } = Select;
const modalTitle = ["添加维修师", "编辑维修师", "维修师详情"];
class RepManList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      siteList: [], //站点列表
      franchiseeList: [], //代理商列表
      queryFormValues: {}, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      initialValues: {}, //站点详情
      options: [], //地区
      id: "", //站点id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      fileList: [], //上传格式
      addressName: "", //定位时的地址（地图地址）
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
      autoList: []
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "所属代理商",
        dataIndex: "agentName",
      },
      {
        title: "维修师",
        dataIndex: "name",
      },
      {
        title: "手机号",
        align: "right",
        dataIndex: "mobile",
      },
      {
        title: "城市",
        dataIndex: "areaName",
      },
      {
        title: "地址",
        dataIndex: "address",
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        key: "Action",
        width: 100,
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.getSiteDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.getSiteDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDeleteSiteChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="维修师/手机号">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <Cascader
                  options={this.state.options}
                  loadData={this.getLoadData.bind(this)}
                  changeOnSelect
                />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      totalCount,
      curPage,
      pageSize,
      siteList,
      initialValues,
      autoList
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="RepManList">
        <div className="select-form">{this.renderForm()}</div>
        <div className="top-btn">
          <Button
            icon="plus"
            type="primary"
            onClick={this.showModal.bind(this, 1)}
          >
            添加维修师
          </Button>
          <Button
            onClick={this.handleBatchDeleteChange.bind(this)}
            disabled={!hasSelected}
            loading={loading}
            icon="delete"
            type="danger"
          >
            批量删除
          </Button>
          {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
        </div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={siteList}
            scroll={{ x: 1500, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title={modalTitle[this.state.modalType - 1]}
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="维修师">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.name}</span>
                ) : (
                  getFieldDecorator("name", {
                    initialValue: initialValues.name,
                    rules: [{ required: true, message: "请输入维修师！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="电话">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.mobile}</span>
                ) : (
                  getFieldDecorator("mobile", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: "请输入正确的手机号格式",
                      },
                      { required: true, message: "请输入电话！" },
                    ],
                    initialValue: initialValues.mobile,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="地区">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.areaName}</span>
                ) : (
                  getFieldDecorator("areaList", {
                    rules: [
                      {
                        required: this.state.modalType === 1 ? true : false,
                        message: "请输入地区！",
                      },
                    ],
                  })(
                    <Cascader
                      placeholder={
                        initialValues.areaName && initialValues.areaName
                      }
                      style={{ width: "100%" }}
                      options={this.state.options}
                      loadData={this.getLoadData.bind(this)}
                      changeOnSelect
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="详情地址">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.address}</span>
                ) : (
                  getFieldDecorator("address", {
                    initialValue: initialValues.address,
                    rules: [{ required: true, message: "请输入详情地址！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="坐标">
                {this.state.modalType === 3 ? (
                  <span>{initialValues.coordinate}</span>
                ) : (
                  getFieldDecorator("coordinate", {
                    initialValue: initialValues.coordinate,
                    rules: [{ required: true, message: "请选择坐标！" }],
                  })(
                    <div>
                      <Input
                        disabled
                        value={
                          this.state.markerPosition.longitude +
                          "，" +
                          this.state.markerPosition.latitude
                        }
                      />
                      <p className="top_map_p">
                        当前地址：
                        {this.state.addressName}
                      </p>

                      <div style={{ width: "100%", height: 300 }}>
                        <Map
                          amapkey={amapkey}
                          zoom={8}
                          events={{
                            click: (e) => {
                              this.setState({
                                markerPosition: {
                                  longitude: e.lnglat.getLng(),
                                  latitude: e.lnglat.getLat(),
                                },
                              });
                              this.handleClickAmapChange(
                                e.lnglat.getLng(),
                                e.lnglat.getLat()
                              );
                            },
                          }}
                          center={this.state.mapCenter}
                        >
                          <Marker position={this.state.markerPosition} />
                          <AutoComplete
                            style={{ width: 300, position: 'absolute', left: '10px', top: '10px'}}
                            placeholder="搜索"
                            optionLabelProp="value"
                            dataSource={
                              autoList.map((item,index)=>(
                                <AutoComplete.Option key={index} value={item.name} position={item.location}>
                                  {item.name}
                                  <span style={{color: 'rgba(0, 0, 0, 0.3)'}}>{item.district}</span>
                                </AutoComplete.Option>
                              ))
                            }
                            onSelect={(value,option)=>{
                              const {position={}} = option.props
                              this.setState({
                                mapCenter: {
                                  longitude: position.lng,
                                  latitude: position.lat,
                                },
                                markerPosition: {
                                  longitude: position.lng,
                                  latitude: position.lat,
                                },
                              });
                              this.handleClickAmapChange(
                                position.lng,
                                position.lat
                              );
                            }}
                            onSearch={async (keywords)=>{
                              window.AMap.plugin('AMap.Autocomplete',async ()=>{
                                let autoComplete = await new window.AMap.Autocomplete({city: '全国'})
                                autoComplete.search(keywords, (status, result)=> {
                                  if(status == 'complete'){
                                    let {tips=[]} = result || {}
                                    tips = tips.filter(item=>item.location)
                                    this.setState({autoList: tips || []})
                                  }else{
                                    this.setState({autoList: []})
                                  }
                                })
                              })
                            }}
                          />
                        </Map>
                      </div>
                    </div>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="管理状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status === 0 ? false : true,
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={this.state.modalType === 3 && true}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getSiteList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
    this.getSonsOfRegion(0);
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _repAgentList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取坐标的详细地址
  handleClickAmapChange(longitude, latitude) {
    let location = longitude + "," + latitude;
    let params = {
      output: "json",
      location: location,
      key: "1607b8afa5a91ccba6d84b8cd4cd5a24",
      radius: 1000,
      extensions: "base",
    };
    _mapGD({ params: params })
      .then((res) => {
        this.setState({
          addressName: res.data.regeocode.formatted_address,
          province: res.data.regeocode.addressComponent.province,
          city: res.data.regeocode.addressComponent.city,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _repManList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            siteList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getSiteList(curPage, pageSize, this.state.queryFormValues);
  }

  //单条删除
  handleDeleteSiteChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteRepMan(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            message.warning("操作失败");
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteRepMan(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(() => {
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateRepManStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getSiteList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getSiteList(1, this.state.pageSize, values);
    });
  };

  //获取工地详情
  getSiteDetail(id, type) {
    let params = { id };
    _repManDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let longitude, latitude;
          let coordinate = res.data.data.coordinate;
          longitude = coordinate ? coordinate.split(",")[0] : 0;
          latitude = coordinate ? coordinate.split(",")[1] : 0;
          this.setState({
            id,
            initialValues: res.data.data,
            mapCenter: { longitude, latitude },
            markerPosition: { longitude, latitude },
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //加载地区数据
  getLoadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let params = { parentid: targetOption.value };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          targetOption.children = areaList;
          targetOption.loading = false;
          let options = [...this.state.options];
          this.setState({
            options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取一级地区
  getSonsOfRegion(parentid) {
    let params = { parentid };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          this.setState({
            options: [...areaList],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      options: [],
      modalType,
      visible: true,
    });
    this.getSonsOfRegion(0);
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (
        this.state.markerPosition.longitude &&
        this.state.markerPosition.latitude
      ) {
        values.coordinate =
          this.state.markerPosition.longitude +
          "," +
          this.state.markerPosition.latitude;
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _addRepMan(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: "",
      initialValues: {},
      options: [],
      visible: false,
      mapCenter: { longitude: 0, latitude: 0 },
      markerPosition: { longitude: 0, latitude: 0 },
    });
    this.getSonsOfRegion(0);
  }
}

RepManList = Form.create({})(RepManList);
export { RepManList };
