import React, { Component } from "react";
// import { browserHistory } from "react-router-dom";
// import {browserHistory} from '@/statics/js/browserHistory';
import {
  Button,
  Table,
  Switch,
  Divider,
  Modal,
  message,
  Input,
  Select,
  Form,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  _repBikeDetail,
  _repCustomerList,
  _bikeOrderList,
  _repBikeModelList,
  _getQRCode,
  _updateRepBikeStatus,
  _repBikeList,
  _saveRepBikeInfo,
  _batchImportBike,
  _deleteRepBike,
} from "@/statics/js/api";
import moment from "moment";
import "./style.less";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const modalTitle = ["添加车辆", "编辑车辆"];
class KABikeRepairList extends Component {
  constructor(props) {
    super(props);
    const { history: browserHistory } = this.props;
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      bikeList: [], //车辆管理列表
      repCustomerList: [], //代理商列表
      bikeModelList: [], //车辆型号列表
      initialValues: {}, //车辆详情
      siteList: [], //站点列表
      bikeOrderList: [], //车辆订单列表
      id: "", //车辆id
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      visibleDetail: false, //Modal是否打开（详情）
      visiblePush: false, //Modal是否打开（投放）
      visibleImport: false, //Modal是否打开（导出）
      selectedData: [], //选中车架号编号
      selectedRowKeys: [], // Check here to configure the default column
    };
    this.columns = [
      {
        title: "车辆信息",
        dataIndex: "imei",
      },
      {
        title: "车辆型号",
        dataIndex: "modelName",
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
        width: 135,
      },
      {
        title: "更新日期",
        width: 135,
        dataIndex: "updateTime",
      },
      {
        title: "代理商",
        dataIndex: "agentName",
      },
      {
        title: "管理状态",
        width: 90,
        dataIndex: "",
        render: (text) =>
          text.status === 2 ? (
            "维修中"
          ) : (
            <Switch
              defaultChecked={!!text.status}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={this.handleStateChange.bind(this, text.id)}
            />
          ),
      },
      {
        title: "操作",
        dataIndex: "",
        fixed: "right",
        width: 120,
        key: "Action",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.modalViewQrcode.bind(this, text.id, text.imei)}
            >
              二维码
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.getBikeDetail.bind(this, text.id, 2, text.orderId)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.getBikeDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDeleteChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];

    this.logColumns = [
      {
        title: "序号",
        width: 100,
        dataIndex: "id",
      },
      {
        title: "订单编号",
        align: "right",
        width: 200,
        dataIndex: "orderSn",
      },
      {
        title: "订单状态",
        dataIndex: "orderStatusName",
      },
      {
        title: "订单日期",
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Button
            type="link"
            onClick={() => {
              browserHistory.push({
                pathname: "/packageOrderManagement",
                query: { orderSn: text.porderSn },
              });
            }}
          >
            查看详情
          </Button>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.repCustomerList.length &&
                      this.state.repCustomerList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆信息">
              {getFieldDecorator("imeiSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="添加时间">
              {getFieldDecorator("addTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("statusSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">禁用</Option>
                  <Option value="1">启用</Option>
                  <Option value="2">维修中</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="使用订单">
              {getFieldDecorator("useOrder")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">所有</Option>
                  <Option value="Y">已使用</Option>
                  <Option value="N">未使用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.bikeModelList.length &&
                    this.state.bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      selectedRowKeys,
      bikeList,
      initialValues,
      pageSize,
      totalCount,
      bikeOrderList,
      curPage,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled:
          record.pushTime !== null &&
          record.pushTime !== 0 &&
          record.pushTime !== undefined, // Column configuration not to be checked
        // pushTime: record.pushTime,
      }),
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="KABikeRepairList">
        <div className="select-form">{this.renderForm()}</div>
        <div className="top-btn">
          <Button
            icon="plus"
            type="primary"
            onClick={this.showModal.bind(this, 1)}
          >
            添加
          </Button>
          <Button icon="cloud-upload" onClick={this.showImportModal.bind(this)}>
            导入数据
          </Button>
          {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
        </div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={bikeList}
            scroll={{ x: 1200, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title={modalTitle[this.state.modalType - 1]}
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="车辆信息">
                {getFieldDecorator("imei", {
                  initialValue: initialValues.imei,
                  rules: [{ required: true, message: "请输入车辆信息！" }],
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status,
                  rules: [{ required: true, message: "请选择状态！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                  >
                    <Option value={1}>正常</Option>
                    <Option value={0}>禁用</Option>
                    <Option value={2}>维修中</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="车辆型号">
                {getFieldDecorator("modelId", {
                  initialValue: initialValues.modelId,
                  rules: [{ required: true, message: "请选择车辆型号！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.bikeModelList.length &&
                      this.state.bikeModelList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
        <Modal
          className="bigModal"
          title="导入车辆"
          destroyOnClose
          maskClosable={false}
          visible={this.state.visibleImport}
          onOk={this.handleImportOk.bind(this)}
          onCancel={this.initImportFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="车辆型号">
                {getFieldDecorator("modelId", {
                  rules: [{ required: true, message: "请选择车辆型号！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.bikeModelList.length &&
                      this.state.bikeModelList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="编号">
                {getFieldDecorator("imeiList", {
                  rules: [{ required: true, message: "请输入编号！" }],
                })(
                  <TextArea
                    placeholder="请输入编码，一行一个"
                    autoSize={{ minRows: 10, maxRows: 15 }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
        <Modal
          className="bigModal"
          title="维修记录"
          destroyOnClose
          maskClosable={false}
          visible={this.state.visibleDetail}
          onOk={this.handleDetailOk.bind(this)}
          onCancel={this.initDetailModal.bind(this)}
        >
          <Table
            rowKey="id"
            bordered
            columns={this.logColumns}
            dataSource={bikeOrderList}
            scroll={{ x: 700, y: 500 }}
          />
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.getRepCustomerList();
    this.getBikeModelList();
    this.getBikeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }

  //查看详情（确定）
  handleDetailOk(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //查看详情（关闭）
  initDetailModal(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //获取车辆详情
  getBikeDetail(id, type, orderId) {
    let params = { id };
    _repBikeDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
            orderId,
          });
          if (type === 3) {
            this.getBikeOrderListList(id);
          } else {
            this.showModal(type);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车量订单列表
  getBikeOrderListList(bikeId) {
    let params = {
      bikeId,
      curPage: 1,
      pageSize: 999,
    };
    _bikeOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            visibleDetail: true,
            bikeOrderList: res.data.data.rows,
          });
        } else {
          message.warning("暂无数据！");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车辆型号列表
  getBikeModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _repBikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getRepCustomerList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _repCustomerList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            repCustomerList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //二维码查看
  modalViewQrcode(deviceId, imei) {
    let params = { deviceId, type: "bike" };
    _getQRCode(params)
      .then((res) => {
        if (res.data.code === 200) {
          Modal.info({
            title: `车辆二维码 [${imei}]`,
            content: (
              <img src={res.data.data} style={{ width: "100%" }} alt="" />
            ),
            onOk() {},
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //车辆状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateRepBikeStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBikeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //单条删除
  handleDeleteChange(id) {
    let self = this;
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteRepBike(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getBikeList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
          })
          .catch(() => {
            message.warning("操作失败");
          });
      },
    });
  }

  //获取车辆列表
  getBikeList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _repBikeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
            spinning: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.setState({
      selectedRowKeys: [],
    });
    this.getBikeList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.addTimeEnd = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }

      this.setState({
        queryFormValues: values,
      });
      this.getBikeList(1, this.state.pageSize, values);
    });
  };

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err.imei || err.modelId || err.status) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }

      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _saveRepBikeInfo(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: "",
      initialValues: {},
      visible: false,
      orderId: null,
    });
  }

  //已选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //Modal打开事件（导入）
  showImportModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({ visibleImport: true });
  }

  //Modal提交事件（导入）
  handleImportOk(e) {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err.modelId || err.imeiList) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.imeiList) {
        let imeiList = values.imeiList.split("\n");
        values.imeiList = Array.from(new Set(imeiList));
      }
      let params = { ...values };
      _batchImportBike(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visiblePush: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件（导入）
  initImportFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      visibleImport: false,
    });
  }
}
KABikeRepairList = Form.create({})(KABikeRepairList);
export { KABikeRepairList };
