import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputNumber, Switch, message } from "antd";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _getSnRatio,
  _updateSnRatio,
  _get_on_off_type,
  _update_on_off_type,
} from "@/statics/js/api.js";
import "./index.less";
const { confirm } = Modal;
const ShuntRule = () => {
  const [ratio, setRatio] = useState(0);
  const [state, setState] = useState(0);
  useEffect(() => {
    getSnRatio();
    getType();
  }, []);

  // 获取比例
  const getSnRatio = async () => {
    const res = await _getSnRatio();
    const { code, message: msg, data } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    setRatio(data.ratio);
  };

  //获取类型
  const getType = async () => {
    const res = await _get_on_off_type();
    const { code, message: msg, data } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    setState(data.isHidden);
  };

  //管理状态Switch
  const handleStateChange = async (checked) => {
    let params = { state: Number(!checked) };
    const res = await _update_on_off_type(params);
    const { code } = res.data || {};
    if (code != 200) {
      message.warning("操作失败");
    } else {
      message.success("操作成功");
    }
    getType();
  };
  
  //修改比例
  const showConfirm = () => {
    let ratio;
    confirm({
      title: "修改支付分流配置",
      content: (
        <Form.Item label="苏宁支付分流比例">
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
            precision={0}
            onChange={(value) => {
              ratio = value;
            }}
          />
        </Form.Item>
      ),
      async onOk() {
        let params = {
          ratio,
        };
        const res = await _updateSnRatio(params);
        const { code, message: msg, data } = res.data || {};
        if (code != 200) {
          message.warning(msg);
          return;
        }
        getSnRatio();
      },
    });
  };
  return !getJurisdiction() ? (
    "暂无操作权限！"
  ) : (
    <div className="ShuntRule">
      <div className="select-form">支付分流配置</div>
      <div className="ratioDiv">
        苏宁支付分流比例：{ratio} %
        <Button type="link" onClick={showConfirm}>
          修改
        </Button>
      </div>
      <div className="ratioDiv">
        切换支付方式开关：{console.log(!state)}
        <Switch
          checked={!state}
          checkedChildren="显示"
          unCheckedChildren="隐藏"
          onChange={handleStateChange}
        />
      </div>
      <div className="descDiv">
        <h3>支付分流配置说明：</h3>
        1.支付分流指苏宁支付与卡付之间的分流。例：如果配置苏宁支付分流比例为“10%”，会有10%的用户走苏宁支付的支付路径；
        <br />
        2.用户主动支付和代扣都会分流，并保持同样的支付路径；
        <br />
        3.卡付路径和苏宁支付路径在主动付时，都可以选择支付宝支付。
      </div>
    </div>
  );
};

export default ShuntRule;
