import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Modal,
  Table,
  Input,
  Button,
  Switch,
  Select,
  Divider,
  message,
  DatePicker,
  Icon,
  Upload,
} from "antd";
import {
  _siteBoxList,
  _activityList,
  _franchiseeList,
  _activityDetail,
  _packageBoxList,
  _saveActivityInfo,
  _updateActivityStatus,
  _imageUpload,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import AreaCascader from "@/components/areaCascader";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const modalTitle = ["添加活动", "编辑活动", "活动详情"];
class FavourableActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商管理列表
      activityList: [], //活动列表
      queryFormValues: {
        status: 1,
      }, //筛选项
      id: "", //活动id
      initialValues: {}, //代理商详情
      visible: false, //Modal是否打开
      modalType: "", //1-添加，2-编辑，3-查看
      packageList: [], //套餐订单列表
      siteList: [], //站点列表
      fileList: [], //上传格式
    };
    this.columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "活动名",
        dataIndex: "name",
      },
      {
        title: "活动类型",
        dataIndex: "typeName",
      },
      {
        title: "城市",
        dataIndex: "cityName",
      },
      {
        title: "适用套餐",
        dataIndex: "packages",
        render: (text) =>
          text &&
          text.map((item, index) => {
            if (index < 2) {
              return <span key={index}>{item.pname}/</span>;
            } else if (index === 2) {
              return <span key={index}>{item.pname}...</span>;
            } else {
              return null;
            }
          }),
      },
      {
        title: "适用代理商",
        dataIndex: "fname",
      },
      {
        title: "适用站点",
        dataIndex: "sites",
        render: (text) =>
          text &&
          text.map((item, index) => {
            if (index < 2) {
              return <span key={index}>{item.sname}/</span>;
            } else if (index === 2) {
              return <span key={index}>{item.sname}...</span>;
            } else {
              return null;
            }
          }),
      },
      {
        title: "活动期限",
        width: 180,
        dataIndex: "",
        render: (text) => (
          <span>
            {text.startTime
              ? moment(text.startTime).format("YYYY-MM-DD HH:mm:ss")
              : ""}
            {text.startTime && text.endTime ? (
              <span>
                <br />
                ~
                <br />
              </span>
            ) : null}
            {text.endTime
              ? moment(text.endTime).format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "创建时间",
        width: 180,
        dataIndex: "addTime",
        render: (text) =>
          text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "",
      },
      {
        title: "排序",
        width: 80,
        dataIndex: "orderNum",
      },
      {
        title: "是否首页显示",
        width: 80,
        dataIndex: "showStatus",
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(937)}
            title={getJurisdiction(937) ? "" : "暂无操作权限！"}
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(935)}
              title={getJurisdiction(935) ? "" : "暂无操作权限！"}
              onClick={this.getActivityDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(936)}
              title={getJurisdiction(936) ? "" : "暂无操作权限！"}
              onClick={this.getActivityDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="活动名称">
              {getFieldDecorator("nameSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator("fidSearch")(
                <Select
                  placeholder="请选择"
                  onChange={this.getSiteList.bind(this)}
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  mode="multiple"
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.siteList.length &&
                    this.state.siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐">
              {getFieldDecorator("pid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.packageList.length &&
                    this.state.packageList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.pname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <AreaCascader changeOnSelect placeholder="城市" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否首页显示">
              {getFieldDecorator("showStatusSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">是</Option>
                  <Option value="0">否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      initialValues,
      activityList,
      totalCount,
      fileList,
      curPage,
      pageSize,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(930) ? (
          <div className="FavourableActivity">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={this.showModal.bind(this, 1)}
                disabled={!getJurisdiction(934)}
                title={getJurisdiction(934) ? "" : "暂无操作权限！"}
              >
                {modalTitle[0]}
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={activityList}
                scroll={{ x: 1500, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="活动名称">
                    {this.state.modalType === 3
                      ? initialValues.name
                      : getFieldDecorator("name", {
                          initialValue: initialValues.name,
                          rules: [
                            { required: true, message: "请输入活动名称！" },
                          ],
                        })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="活动类型">
                    {this.state.modalType === 3
                      ? initialValues.type === 1
                        ? "新用户"
                        : initialValues.type === 2
                        ? "拉新"
                        : ""
                      : getFieldDecorator("type", {
                          initialValue: initialValues.type,
                          rules: [
                            { required: true, message: "请选择活动类型" },
                          ],
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                          >
                            <Option value={1}>新用户</Option>
                            <Option value={2}>拉新</Option>
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="上传banner">
                    {getFieldDecorator("img", {
                      initialValue: initialValues.img || undefined,
                      rules: [{ required: true, message: "请上传活动banner" }],
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        disabled={this.state.modalType === 3 ? true : false}
                        name="uploadFile"
                        action={_imageUpload}
                        listType="picture-card"
                        fileList={fileList}
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                        onChange={this.handleChange.bind(this)}
                        data={{
                          scene: "active",
                        }}
                      >
                        {fileList.length >= 1 ? null : (
                          <div>
                            <Icon type="plus" style={{ fontSize: "28px" }} />
                            <p style={{ fontSize: "10px" }}>
                              请上传尺寸为710px*140px大小的banner
                            </p>
                          </div>
                        )}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="详情页链接">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.path}</span>
                    ) : (
                      getFieldDecorator("path", {
                        initialValue: initialValues.path,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用套餐">
                    {this.state.modalType === 3
                      ? initialValues.packages &&
                        initialValues.packages.map((item, index) => {
                          return <span key={index}>{item.pname}/</span>;
                        })
                      : getFieldDecorator("pids", {
                          initialValue: initialValues.pids,
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                            mode="multiple"
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.state.packageList &&
                              this.state.packageList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.pname}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          onChange={this.getSiteList.bind(this)}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用站点">
                    {this.state.modalType === 3
                      ? initialValues.sites &&
                        initialValues.sites.map((item, index) => {
                          return <span key={index}>{item.sname}/</span>;
                        })
                      : getFieldDecorator("sids", {
                          initialValue: initialValues.sids,
                        })(
                          <Select
                            allowClear
                            mode="multiple"
                            placeholder="请选择"
                            style={{ width: "100%" }}
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.state.siteList &&
                              this.state.siteList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.sid}>
                                    {item.sname}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="地区">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.address}</span>
                    ) : (
                      getFieldDecorator("areaList", {
                        initialValue: initialValues.areaList,
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: "请选择地区！",
                          },
                        ],
                      })(
                        <AreaCascader
                          changeOnSelect
                          placeholder="城市"
                          style={{ width: "100%" }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="活动期限">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.startTime && initialValues.endTime
                          ? moment(initialValues.startTime).format(
                              "YYYY-MM-DD"
                            ) +
                            "～" +
                            moment(initialValues.startTime).format("YYYY-MM-DD")
                          : ""}
                      </span>
                    ) : (
                      getFieldDecorator("time", {
                        initialValue:
                          initialValues.startTime && initialValues.endTime
                            ? [
                                moment(initialValues.startTime),
                                moment(initialValues.endTime),
                              ]
                            : undefined,
                        rules: [
                          { required: true, message: "请选择活动期限！" },
                        ],
                      })(<RangePicker format="YYYY-MM-DD" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="排序">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.orderNum}</span>
                    ) : (
                      getFieldDecorator("orderNum", {
                        initialValue: initialValues.orderNum,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="是否首页显示">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.showStatus === 1
                          ? "是"
                          : initialValues.showStatus === 0
                          ? "否"
                          : null}
                      </span>
                    ) : (
                      getFieldDecorator("showStatus", {
                        initialValue:
                          typeof initialValues.showStatus === "number"
                            ? initialValues.showStatus
                            : 1,
                        rules: [
                          { required: true, message: "请选择是否首页显示！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                        >
                          <Option value={1}>是</Option>
                          <Option value={0}>否</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getpackageList();
    this.getFranchiseeList();
    this.getActivityList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    if (this.state.modalType) {
      this.props.form.setFieldsValue({
        sids: undefined,
      });
    } else {
      this.props.form.setFieldsValue({
        sid: undefined,
      });
    }
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // //获取优惠券列表
  // getCouponList() {
  //   let params = {
  //     curPage: 1,
  //     pageSize: 999,
  //   };
  //   _couponList(params)
  //     .then((res) => {
  //       if (res.data.code === 200) {
  //         if (!res.data.data.rows.length) {
  //           message.warning("暂无数据！");
  //         }
  //         this.setState({
  //           couponList: res.data.data.rows,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  //获取套餐列表
  getpackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    };
    _packageBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取活动列表
  getActivityList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _activityList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            activityList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取详情
  getActivityDetail(id, type) {
    let params = { id };
    _activityDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { province, city, county, street } = res.data.data || {};
          this.setState({
            id,
            initialValues: {
              ...res.data.data,
              areaList: [province, city, county, street].filter(
                (item) => item
              ),
            },
          });
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: "done",
                url: res.data.data.imgObject.path,
              },
            ]);
          this.getSiteList(res.data.data.fid);
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getActivityList(curPage, pageSize, this.state.queryFormValues);
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.showStatus;
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.showStatusSearch) {
        values.showStatus = values.showStatusSearch;
        delete values.showStatusSearch;
      }
      if (values.nameSearch) {
        values.name = values.nameSearch;
        delete values.nameSearch;
      }
      if (values.pidSearch) {
        values.pid = values.pidSearch;
        delete values.pidSearch;
      }
      if (values.status === true) {
        values.status = 1;
      } else if (values.status === false) {
        values.status = 0;
      }
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getActivityList(1, this.state.pageSize, values);
    });
  };

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateActivityStatus(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getActivityList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      if (values.time && values.time.length) {
        values.startTime = moment(values.time[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.time[1]).format("YYYY-MM-DD");
      }
      delete values.time;
      delete values.nameSearch;
      delete values.pidSearch;
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        delete values.areaList;
      }
      if (values.img && values.img.constructor === Array) {
        values.img =
          values.img.length === 1 ? values.img[0].response.data.id : "";
      }
      console.log(values);
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? {
                ...values,
              }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveActivityInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: null,
      initialValues: {},
      visible: false,
      modalType: null,
    });
  }
}

FavourableActivity = Form.create({})(FavourableActivity);
export { FavourableActivity };
