import React, { useState, useRef } from 'react'
import {
  Table,
  Button,
  Divider,
  Switch,
  Form,
  InputNumber,
  Modal,
  message,
} from 'antd'
import SearchBox from './components/searchBox'
import SitesBind from './components/sitesBind'
import DoubleBind from './components/doubleBind'
import PackageEdit from './components/packageEdit'
import Edit from './components/edit'
import Benefit from './components/edit/benefit'
import Info from './components/edit/info'
import useTable from '@/hooks/table'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import {
  _packageList,
  _updatePackageStatus,
  _batchDeletePackage,
  _deletePackage,
  _savePackagesDiffConfigInfo,
  _getPackageQRCode,
} from '@/statics/js/api.js'
import moment from 'moment'

const Index = (props) => {
  const columns = [
    {
      title: '套餐编号',
      width: 100,
      dataIndex: 'id',
    },
    {
      title: '添加时间',
      width: 120,
      dataIndex: 'createTime',
      render: (text) => text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '套餐名称',
      width: 180,
      dataIndex: 'pname',
    },
    {
      title: '套餐类型',
      width: 120,
      dataIndex: 'ptypeName',
    },
    {
      title: '合同类型',
      width: 100,
      dataIndex: 'contractTypeName',
    },
    {
      title: '车辆型号',
      width: 180,
      dataIndex: 'bikeModels',
      render: (text) =>
        text &&
        text.map((item, index) => {
          return <span key={index}>{item.name}/</span>
        }),
    },
    {
      title: '电池型号',
      width: 180,
      dataIndex: 'dcModels',
      render: (text) =>
        text &&
        text.map((item, index) => {
          return <span key={index}>{item.name}/</span>
        }),
    },
    {
      title: '支付方式',
      width: 120,
      dataIndex: 'payments',
      render: (text) =>
        text &&
        text.map((item, index) => {
          return <span key={index}>{item.name}/</span>
        }),
    },
    {
      title: '代扣方式',
      width: 100,
      dataIndex: 'deductType',
      render: (text) => (text === 1 ? '先付' : text === 2 ? '后付' : ''),
    },
    {
      title: '套餐金额',
      width: 100,
      align: 'right',
      dataIndex: 'money',
      render: (text) => text && text.toFixed(2),
    },
    {
      title: '电池金额',
      width: 100,
      align: 'right',
      dataIndex: 'dcMoney',
      render: (text) => text && text.toFixed(2),
    },
    {
      title: '押金类型',
      width: 120,
      align: 'right',
      dataIndex: 'authType',
      render: (text) =>
        text === 1
          ? '支付宝预授权'
          : text === 2
          ? '现金押金'
          : text === 3
          ? '免押金'
          : null,
    },
    {
      title: '押金金额',
      width: 100,
      align: 'right',
      dataIndex: 'authMoney',
      render: (text) => text && text.toFixed(2),
    },
    {
      title: '免赔服务',
      width: 100,
      align: 'right',
      dataIndex: 'insureMoney',
      render: (text) => text && text.toFixed(2),
    },
    {
      title: '套餐地',
      width: 100,
      dataIndex: 'cityName',
    },
    {
      title: '身份验证',
      width: 90,
      dataIndex: 'needCertify',
      render: (text) => (text === 1 ? '需要' : text === 0 ? '不需要' : ''),
    },
    {
      title: '是否检修',
      width: 90,
      dataIndex: 'needRepair',
      render: (text) => (text === 1 ? '是' : text === 0 ? '否' : ''),
    },
    {
      title: '检修方式',
      width: 110,
      dataIndex: 'repairType',
      render: (text) =>
        text === 1 ? '原站点检修' : text === 2 ? '退车点检修' : '',
    },
    {
      title: '退车点',
      width: 180,
      dataIndex: 'repairSiteList',
      render: (text) =>
        text &&
        text.map((item, index) => {
          return <span key={index}>{item.name}/</span>
        }),
    },
    {
      title: '排序',
      width: 80,
      dataIndex: 'orderNum',
    },
    {
      title: '是否平台套餐',
      width: 120,
      dataIndex: 'self',
      render: (text) => (text === 1 ? '是' : '否'),
    },
    {
      title: '是否首页显示',
      width: 120,
      dataIndex: 'showStatus',
      render: (text) => (text === 1 ? '是' : '否'),
    },
    {
      title: '是否站点套餐显示',
      width: 150,
      dataIndex: 'showSite',
      render: (text) => (text === 1 ? '是' : '否'),
    },
    {
      title: '管理状态',
      width: 100,
      dataIndex: '',
      render: (text) => (
        <span>
          {text.pstatus > 1 ? (
            text.pstatus == 2 ? (
              '申请中'
            ) : text.pstatus == 3 ? (
              '已拒绝'
            ) : (
              ''
            )
          ) : (
            <Switch
              defaultChecked={!!text.pstatus}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={(checked) => handleStateChange(text.id, checked)}
            />
          )}
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'Action',
      width: 120,
      fixed: 'right',
      render: (text) => (
        <span>
          <Button
            type="link"
            disabled={!getJurisdiction(689)}
            title={getJurisdiction(689) ? '' : '暂无操作权限！'}
            onClick={() => handleDetail({ ...text })}
          >
            查看
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            disabled={!getJurisdiction(688)}
            title={getJurisdiction(688) ? '' : '暂无操作权限！'}
            onClick={() => handleEdit({ ...text })}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            disabled={!getJurisdiction(687)}
            title={getJurisdiction(687) ? '' : '暂无操作权限！'}
            onClick={() => handleDelete(1, text.id)}
          >
            删除
          </Button>
          <Divider type="vertical" />
          {!!text.qrcode && text.pstatus === 1 && (
            <span>
              <Button type="link" onClick={() => handleQrcode({ ...text })}>
                二维码
              </Button>
              <Divider type="vertical" />
            </span>
          )}
          <Button
            type="link"
            disabled={!getJurisdiction(808)}
            title={getJurisdiction(808) ? '' : '暂无操作权限！'}
            onClick={() => handleBind({ ...text })}
          >
            站点绑定
          </Button>
          {(text.ptype === 3 || text.ptype === 4) && (
            <span>
              <Divider type="vertical" />
              <Button
                type="link"
                disabled={!getJurisdiction(688)}
                title={getJurisdiction(688) ? '' : '暂无操作权限！'}
                onClick={() => handleMaintain({ ...text })}
              >
                违约金维护
              </Button>
            </span>
          )}
          <span>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(865)}
              title={getJurisdiction(865) ? '' : '暂无操作权限！'}
              onClick={() => handleRuleDetails({ ...text })}
            >
              即时分润
            </Button>
          </span>
        </span>
      ),
    },
  ]
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [ruleShow, setRuleShow] = useState(false)
  const [detailShow, setDetailShow] = useState(false)
  const sitesBindRef = useRef()
  const doubleBindRef = useRef()
  const packageEditRef = useRef()
  const editRef = useRef()
  const benefitRef = useRef()
  const infoRef = useRef()
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
    handleRefresh,
  } = useTable({
    action: _packageList,
  })

  const handleStateChange = async (id, checked) => {
    const params = { id, pstatus: Number(checked) }
    const res = await _updatePackageStatus(params)
    const { code, message: msg } = res.data || {}
    if (code !== 200) {
      message.warning(msg)
      return
    }
    message.success('操作成功')
    handleRefresh()
  }
  const handleCreate = async () => {
    editRef.current.open(1, {})
    // packageEditRef.current.open(1, {});
  }
  const handleDetail = async ({ id }) => {
    setDetailShow(true)
    setTimeout(() => {
      infoRef.current.init(3, { id })
    }, 20)
    // packageEditRef.current.open(3, { id });
  }
  const handleEdit = async ({ id }) => {
    editRef.current.open(2, { id })
    // packageEditRef.current.open(2, { id });
  }
  const handleQrcode = async ({ qrcode, pname }) => {
    Modal.info({
      title: `套餐二维码（${pname}）`,
      content: <img src={qrcode} style={{ width: '100%' }} alt="" />,
    })
  }
  const handleBind = async (text) => {
    doubleBindRef.current.open({ ...text })
  }
  const handleSitesBind = async () => {
    sitesBindRef.current.open({ list: selectedRowKeys })
  }
  const handleFinish = async () => {
    setSelectedRowKeys([])
    handleRefresh()
  }
  const handleDelete = async (type, id) => {
    Modal.confirm({
      title: '删除信息',
      content:
        type == 1 ? '是否确定删除此数据吗？' : '是否确定批量删除数据吗？',
      onOk: async () => {
        const res = await (type == 1
          ? _deletePackage({ id })
          : _batchDeletePackage(selectedRowKeys))
        const { code } = res.data || {}
        if (code !== 200) {
          message.warning('操作失败')
          return
        }
        handleSearch()
        setSelectedRowKeys([])
      },
    })
  }
  const handleMaintain = async ({
    configId: id,
    id: pid,
    monthNum,
    diffAmount,
  }) => {
    Modal.confirm({
      title: `违约金维护`,
      content: (
        <div style={{ marginTop: 30 }}>
          <Form.Item label="期数(月)">
            <InputNumber
              style={{ width: '100%' }}
              onChange={(value) => {
                monthNum = value
              }}
              allowClear
              placeholder={monthNum}
              min={1}
            />
          </Form.Item>
          <Form.Item label="违约金额">
            <InputNumber
              style={{ width: '100%' }}
              onChange={(value) => {
                diffAmount = value
              }}
              allowClear
              placeholder={diffAmount}
              min={0}
            />
          </Form.Item>
        </div>
      ),
      onOk: async () => {
        let params = {
          id,
          pid,
          monthNum,
          diffAmount,
        }
        const res = await _savePackagesDiffConfigInfo(params)
        const { code, message: msg } = res.data || {}
        if (code !== 200) {
          message.warning(msg)
          return
        }
        message.success(msg)
        handleRefresh()
      },
    })
  }
  // 分润规则ID获取分润套餐详情
  const handleRuleDetails = async ({ id }) => {
    setRuleShow(true)
    setTimeout(() => {
      benefitRef.current.init(3, { id })
    })
  }
  return (
    <>
      {!getJurisdiction(686) ? (
        '暂无操作权限！'
      ) : (
        <div>
          <div className="select-form">
            <SearchBox search={handleSearch} />
          </div>
          <div className="top-btn">
            <Button
              icon="plus"
              type="primary"
              disabled={!getJurisdiction(688)}
              title={getJurisdiction(688) ? '' : '暂无操作权限！'}
              onClick={handleCreate}
            >
              添加套餐
            </Button>
            <Button
              onClick={handleSitesBind}
              disabled={!getJurisdiction(1042) || !selectedRowKeys.length}
              title={getJurisdiction(1042) ? '' : '暂无操作权限！'}
            >
              批量站点绑定
            </Button>
            <Button
              onClick={() => handleDelete(2)}
              disabled={!selectedRowKeys.length}
              icon="delete"
              type="danger"
            >
              批量删除
            </Button>
            {!!selectedRowKeys.length && `已选中 ${selectedRowKeys.length} 项`}
          </div>
          <Table
            rowKey="id"
            bordered
            columns={columns}
            dataSource={list}
            loading={loading}
            scroll={{ x: 2000, y: 500 }}
            rowSelection={{
              selectedRowKeys,
              onChange: (keys) => setSelectedRowKeys(keys),
            }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: handlePageChange,
              onChange: handlePageChange,
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
          <SitesBind
            wrappedComponentRef={(ref) => (sitesBindRef.current = ref)}
            onFinish={handleFinish}
          />
          <DoubleBind ref={doubleBindRef} onFinish={handleRefresh} />
          <PackageEdit
            wrappedComponentRef={(ref) => (packageEditRef.current = ref)}
            onFinish={handleRefresh}
          />
          <Edit ref={editRef} onFinish={handleRefresh} />
          <Modal
            className="bigModal"
            title="套餐详情"
            maskClosable={false}
            visible={detailShow}
            onOk={() => setDetailShow(false)}
            onCancel={() => setDetailShow(false)}
          >
            <Info wrappedComponentRef={(ref) => (infoRef.current = ref)} />
          </Modal>
          <Modal
            className="bigModal"
            title="查看套餐即时分润规则"
            maskClosable={false}
            visible={ruleShow}
            onOk={() => setRuleShow(false)}
            onCancel={() => setRuleShow(false)}
          >
            <Benefit
              wrappedComponentRef={(ref) => (benefitRef.current = ref)}
            />
          </Modal>
        </div>
      )}
    </>
  )
}

export default Index
