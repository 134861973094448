import React, { 
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Button,
  Table,
  Divider,
  message,
} from "antd";
import SearchBox from './components/searchBox';
import PayModal from './components/payModal'
import useTable from '@/hooks/table'
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _balanceList,
  _kaAccountDetail,
} from "@/statics/js/api.js"
import moment from "moment";
const typeObj = {
  1: '支付宝扫码',
  2: '微信扫码',
  3: '银行卡支付'
}
const SerialReport = ()=> {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "流水号",
      dataIndex: "tradeNo",
    },
    {
      title: "类型",
      dataIndex: "type",
      render: (text) => text == 1 ? '收入' : '支出',
    },
    {
      title: "支付金额",
      dataIndex: "",
      render: (text) => ((text.type == 1 ? '+' : '-') + text.changeAmount),
    },
    {
      title: "账户金额",
      dataIndex: "amount",
    },
    {
      title: "公司名称",
      dataIndex: "fname",
    },
    {
      title: "支付时间",
      width: 120,
      dataIndex: "changeTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "支付方式",
      dataIndex: "payType",
      render: (text) => typeObj[text],
    },
    {
      title: "支付账号",
      dataIndex: "accountName",
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <span>
          {
            text.payType == 3 &&
            <Button
              type="link"
              disabled={!getJurisdiction(993)}
              title={getJurisdiction(993) ? "" : "暂无操作权限！"}
              onClick={()=>showRecordModal(text.id)}
            >
              充值记录
            </Button>
          }
        </span>
      ),
    },
  ]

  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handleRefresh,
    handlePageChange,
  } = useTable({
    action: _balanceList,
  })
  const [balance,setBalance] = useState(0)
  const userType = localStorage.getItem("type")
  const payRef = useRef();

  useEffect(()=>{
    getAccountDetail()
  },[])

  // 支付
  const showPayModal = async ()=>{
    payRef.current.open({type: 0})
  }
  // 记录
  const showRecordModal = async (id)=>{
    payRef.current.open({type: 1,id})
  }
  // 账户余额
  const getAccountDetail = async ()=> {
    const res = await _kaAccountDetail();
    const {code,data={},message:msg} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    const {amount} = data || {}
    setBalance(amount)
  }
  return (
    !getJurisdiction(989) ? '暂无操作权限！' :
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <div className="top-btn">
        {
          userType != 0 &&
          <>
            <h1 style={{display: "inline-block"}}>账户余额：{balance}</h1>
            <Divider type="vertical" />
          </>
        }
        <Button 
          type="primary" 
          disabled={!getJurisdiction(994)}
          title={getJurisdiction(994) ? "" : "暂无操作权限！"}
          onClick={showPayModal}
        >
          账户充值
        </Button>
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 2000, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
      <PayModal 
        ref={payRef} 
        done={handleRefresh} 
      />
    </div>
  )
}

export default SerialReport