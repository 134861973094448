import React from 'react';
import moment from "moment";
import {
  Table,
  Popover,
} from "antd";
import useTable from '../../../../../hooks/table'
import {
  _getBikeStop,
} from "../../../../../statics/js/api.js"

const Index = (props) => {
  const { filter } = props
  let {imei,startTime,endTime} = filter
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1
    },
    {
      title: "停留类型",
      dataIndex: "stopType",
    },
    {
      title: "停留时长",
      dataIndex: "stayTime",
    },
    {
      title: "地址",
      dataIndex: "address",
      ellipsis: true,
      render: (text, record, index) => <Popover content={text} placement="left">{text}</Popover>
    },
    {
      title: "经度/纬度",
      width: 200,
      dataIndex: "",
      render: (text, record, index) => record.longitude + ',' + record.latitude
    },
    {
      title: "开始时间",
      width: 180,
      dataIndex: "startTime",
    },
    {
      title: "结束时间",
      width: 180,
      dataIndex: "endTime",
    },
  ]

  const {
    list,
    loading,
  } = useTable({
    action: _getBikeStop,
    params: {
      imei,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
    },
    response: (res) => {
      const { stopSegments = [] } = res.data.data || {}
      return {
        rows: stopSegments
      }
    }
  })

  return (<>
    <Table
      rowKey="id"
      bordered
      scroll={{ y: 500 }}
      columns={columns}
      dataSource={list}
      loading={loading}
      pagination={false}
    />
  </>)
}

export default Index