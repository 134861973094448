import React from 'react';
import './index.less'

const Index = (props)=> {
    const {header,children} = props
    return (
        <div className="layout_sheet_wrap">
            <div className="layout_sheet_header">
                {header}
            </div>
            <div className="layout_sheet_body">
                {children}
            </div>
        </div>
    )
}

export default Index
  