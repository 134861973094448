import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import { _batteryControl } from "@/statics/js/api.js";
import { Modal, message, Icon, Table } from "antd";

var LockModal = forwardRef((props, ref) => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "电池信息",
      dataIndex: "imei",
    },
    {
      title: "代理商",
      dataIndex: "",
      render: (text) => (
        <span>
          {text.fname}
          <br />
          {text.sname}
        </span>
      ),
    },
    {
      title: "电池型号",
      dataIndex: "modelName",
    },
    {
      title: "电池状态",
      dataIndex: "",
      render: (text) => (
        <span>
          <span>
            电池状态：
            {text.online === 1 ? "在线" : text.online === 2 ? "休眠" : "离线"}
          </span>
          <br />
          <span>
            电池电量：{text.electricQuantity ? text.electricQuantity : "未知"}
          </span>
          <br />
          <span>
            充电状态：
            {text.chargeStatusName}
          </span>
          <br />
          <span>
            放电状态：
            {text.dischargingStatus === 0
              ? "未放电"
              : text.dischargingStatus === 1
              ? "放电"
              : "未知"}
          </span>
          <br />
        </span>
      ),
    },
    {
      title: "使用订单",
      align: "right",
      dataIndex: "orderSn",
    },
  ];
  const { done } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleFail, setVisibleFail] = useState(false);
  const [list, setList] = useState([]);
  const [failList, setFailList] = useState([]);
  const [ids, setIds] = useState([]);
  const [type, setType] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const open = async ({ ids, selectedData, type }) => {
    setVisible(true);
    setList([...selectedData]);
    setType(type);
    setIds(ids);
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    const params = {
      bids: ids,
      instructType: type,
    };
    batteryControl(params);
  };
  
  // 电池控制
  const batteryControl = async (params) => {
    setConfirmLoading(true);
    const res = await _batteryControl(params);
    setConfirmLoading(false);
    const { data, code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    done();
    close();
    if (data) {
      setVisibleFail(true);
      setFailList([...data]);
    } else {
      message.success("操作成功");
    }
  };
  return (
    <div>
      <Modal
        title={type == 1 ? "批量锁电" : "批量解锁"}
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        okText={type == 1 ? "确认锁电" : "确认解锁"}
        confirmLoading={confirmLoading}
      >
        <h3>已选中 {list.length}</h3>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          scroll={{ x: 1000, y: 500 }}
          dataSource={list}
          pagination={false}
        />
      </Modal>
      <Modal
        title={
          type == 1 ? (
            <div style={{ color: "red" }}>
              <Icon type="exclamation-circle" />
              {"   "}
              锁电失败提醒
            </div>
          ) : (
            <div style={{ color: "red" }}>
              <Icon type="exclamation-circle" />
              {"   "}
              解锁失败提醒
            </div>
          )
        }
        visible={visibleFail}
        width={700}
        onOk={() => {
          setVisibleFail(false);
        }}
        onCancel={() => {
          setVisibleFail(false);
        }}
      >
        <h3>{type == 1 ? "锁电" : "解锁"}失败设备列表</h3>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          scroll={{ x: 1000, y: 500 }}
          dataSource={failList}
          pagination={false}
        />
      </Modal>
    </div>
  );
});

export default LockModal;
