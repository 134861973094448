import "./index.less";
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Modal, Button, Tabs } from "antd";
import Info from "./info";
import Benefit from "./benefit";
import Site from "./site";
const { TabPane } = Tabs;
const modalTitle = ["添加套餐", "编辑套餐", "套餐详情"];
const steps = [
  { label: "填写基本信息" },
  { label: "配置即时分润" },
  { label: "绑定站点" },
];

const Index = forwardRef((props, ref) => {
  const { onFinish = () => {} } = props;
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pid, setPid] = useState("");
  const [modalType, setModalType] = useState("");
  const infoRef = useRef();
  const benefitRef = useRef();
  const siteRef = useRef();
  const open = async (type, { id }) => {
    setVisible(true);
    setStep(0);
    setPid(id);
    setModalType(type);
    setTimeout(() => {
      infoRef.current.init(type, { id });
    }, 20);
  };
  // 步骤条
  const handleStep = async (action) => {
    if (confirmLoading) return;
    if (action === "back") {
      setStep((value) => (value ? --value : 0));
    } else if (action === "skip") {
      if (step === 2) {
        setVisible(false);
        onFinish();
      }
      if (step === 1) {
        setStep((value) => ++value);
        setTimeout(() => {
          const { transferType, returnType } = infoRef.current.getInfo();
          siteRef.current.init({
            id: pid,
            transferType,
            returnType,
            modalType,
          });
        }, 20);
      } else {
        setStep((value) => (value >= 2 ? 2 : ++value));
      }
    } else if (action === "next") {
      if (step === 0) {
        const { success, refresh, curId } = (await handleInfoOk()) || {};
        if (!success) return;
        setStep((value) => ++value);
        if (refresh) {
          setTimeout(() => {
            benefitRef.current.init(2, { id: curId });
          }, 20);
        }
      } else if (step === 1) {
        const { success } = (await handleBenefitOk()) || {};
        if (!success) return;
        setStep((value) => ++value);
        setTimeout(() => {
          const { transferType, returnType } = infoRef.current.getInfo();
          siteRef.current.init({
            id: pid,
            transferType,
            returnType,
            modalType,
          });
        }, 20);
      }
    }
  };
  // 编辑切换
  const handleTabChange = (value) => {
    const active = Number(value);
    setStep(active);
    if (active === 1) {
      benefitRef.current.init(2, { id: pid });
    } else if (active === 2) {
      const { transferType, returnType } = infoRef.current.getInfo();
      siteRef.current.init({ id: pid, transferType, returnType, modalType });
    }
  };
  // 编辑确定
  const handleConfirm = async () => {
    if (step === 0) {
      const { success } = (await handleInfoOk()) || {};
      if (!success) return;
    } else if (step === 1) {
      const { success } = (await handleBenefitOk()) || {};
      if (!success) return;
    } else if (step === 2) {
      const { success } = (await handleSiteOk()) || {};
      if (!success) return;
    }
    setVisible(false);
    onFinish();
  };
  // 基础信息
  const handleInfoOk = async () => {
    setConfirmLoading(true);
    try {
      const { id, refresh } = (await infoRef.current.handleOk()) || {};
      let curId = id || pid || "";
      setPid(curId);
      if (!id) {
        return;
      }
      return { success: true, refresh, curId };
    } finally {
      setConfirmLoading(false);
    }
  };
  // 分润信息
  const handleBenefitOk = async () => {
    setConfirmLoading(true);
    try {
      const { id } = (await benefitRef.current.handleOk()) || {};
      if (!id) {
        return;
      }
      return { success: true };
    } finally {
      setConfirmLoading(false);
    }
  };
  // 绑定信息
  const handleSiteOk = async () => {
    setConfirmLoading(true);
    try {
      const { id } = (await siteRef.current.handleOk()) || {};
      if (!id) {
        return;
      }
      return { success: true };
    } finally {
      setConfirmLoading(false);
    }
  };
  // 绑定车辆提交
  const handleBindSuccess = () => {
    setVisible(false);
    onFinish();
  };
  // 绑定车辆取消
  const handleBindCancel = () => {
    setStep((value) => --value);
  };
  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open]
  );

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      width={930}
      title={modalTitle[modalType - 1]}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {modalType == 1 ? (
        <div className="step_wrap">
          {steps.map((item, index) => {
            return (
              <div
                className={"step_item " + (step >= index ? "step_active" : "")}
                key={index}
              >
                <div className="step_info">
                  <div className="step_dot"></div>
                  <div className="step_label">{item.label}</div>
                </div>
                {index < steps.length - 1 ? (
                  <div
                    className={
                      "step_line " + (step > index ? "line_active" : "")
                    }
                  ></div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : (
        <Tabs activeKey={step + ""} onChange={handleTabChange}>
          <TabPane tab="填写基本信息" key="0"></TabPane>
          <TabPane tab="配置即时分润" key="1"></TabPane>
          <TabPane tab="绑定站点" key="2"></TabPane>
        </Tabs>
      )}
      <div className="edit_content">
        <div style={{ display: !step ? "block" : "none" }}>
          <Info wrappedComponentRef={(ref) => (infoRef.current = ref)} />
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <Benefit wrappedComponentRef={(ref) => (benefitRef.current = ref)} />
        </div>
        <div
          className="site_home"
          style={{ height: step === 2 ? "auto" : "0" }}
        >
          <Site
            ref={siteRef}
            onSuccess={handleBindSuccess}
            onCancel={handleBindCancel}
          />
        </div>
      </div>
      <div
        className="edit_footer"
        style={{ display: modalType == 1 ? "block" : "none" }}
      >
        {step > 0 && (
          <Button
            onClick={() => handleStep("skip")}
            style={{ marginRight: "30px" }}
          >
            跳过
          </Button>
        )}
        <Button onClick={() => setVisible(false)}>取消</Button>
        {step > 0 && <Button onClick={() => handleStep("back")}>上一步</Button>}
        <Button
          onClick={() => handleStep("next")}
          loading={confirmLoading}
          type="primary"
        >
          {step < 2 ? "下一步" : "确定"}
        </Button>
      </div>
      <div
        className="edit_footer"
        style={{ display: modalType != 1 ? "block" : "none" }}
      >
        <Button onClick={() => setVisible(false)}>取消</Button>
        <Button onClick={handleConfirm} loading={confirmLoading} type="primary">
          确定
        </Button>
      </div>
    </Modal>
  );
});

export default Index;
