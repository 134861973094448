import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  message,
  Modal,
  Row,
  Col,
  Tree,
  Form,
  Input,
} from "antd";
import {
  _authGroupList,
  _authGroupDetail,
  _saveAuthGroup,
  _allMenus,
  _updateAuthGroupStatus,
  _deleteAuthGroup,
  _batchDeleteAuthGroup,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const { TextArea } = Input;
const { TreeNode } = Tree;
const modalTitle = ["添加角色", "编辑角色"];

class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      visibleSQ: false, //Modal是否打开（授权）
      initialValues: {}, //角色详情
      authGroupList: [], //角色管理列表
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      expandedKeys: [], //展开指定的树节点
      autoExpandParent: true,
      treeData: [],
      checkedKeys: [],
    };
    this.columns = [
      {
        title: "用户角色",
        width: 200,
        dataIndex: "title",
      },
      {
        title: "描述说明",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(48)}
            title={getJurisdiction(48) ? "" : "暂无操作权限！"}
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "更新时间",
        width: 120,
        dataIndex: "updateTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) =>
          text.sys === 1 ? (
            <Button
              type="link"
              disabled={!getJurisdiction(16)}
              title={getJurisdiction(16) ? "" : "暂无操作权限！"}
              onClick={this.getAuthGroupDetail.bind(this, text.id, 2, text.sys)}
            >
              编辑
            </Button>
          ) : (
            <span>
              <Button
                type="link"
                disabled={!getJurisdiction(8)}
                title={getJurisdiction(8) ? "" : "暂无操作权限！"}
                onClick={this.showSQModal.bind(this, text.id, text.title)}
              >
                授权
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                disabled={!getJurisdiction(16)}
                title={getJurisdiction(16) ? "" : "暂无操作权限！"}
                onClick={this.getAuthGroupDetail.bind(
                  this,
                  text.id,
                  2,
                  text.sys
                )}
              >
                编辑
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                disabled={!getJurisdiction(9)}
                title={getJurisdiction(9) ? "" : "暂无操作权限！"}
                onClick={this.handleDeleteSiteChange.bind(this, text.id)}
              >
                删除
              </Button>
            </span>
          ),
      },
    ];
  }

  //treeNode渲染
  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  render() {
    const {
      loading,
      selectedRowKeys,
      authGroupList,
      pageSize,
      totalCount,
      initialValues,
      curPage,
      treeData,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(7) ? (
          <div className="RoleManagement">
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(16)}
                title={getJurisdiction(16) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加角色
              </Button>
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(9) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                scroll={{ x: 1200, y: 500 }}
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={authGroupList}
                rowSelection={rowSelection}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="角色名称">
                    {getFieldDecorator("title", {
                      initialValue: initialValues.title,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                {this.state.sys !== 1 && (
                  <Col span={24}>
                    <Form.Item label="管理状态">
                      {getFieldDecorator("status", {
                        initialValue: initialValues.status === 0 ? false : true,
                        valuePropName: "checked",
                      })(
                        <Switch
                          checkedChildren="启用"
                          unCheckedChildren="禁用"
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="描述说明">
                    {getFieldDecorator("remark", {
                      initialValue: initialValues.remark,
                    })(
                      <TextArea
                        placeholder="请输入"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="角色授权"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleSQ}
              onOk={this.handleSQOk.bind(this)}
              onCancel={this.initSQFormModal.bind(this)}
            >
              <Tree
                checkable
                onCheck={this.onCheck}
                onExpand={this.onExpand}
                checkedKeys={this.state.checkedKeys}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={this.state.autoExpandParent}
              >
                {this.renderTreeNodes(treeData)}
              </Tree>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getAuthGroupList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  //获取角色列表
  getAuthGroupList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _authGroupList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            authGroupList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getAuthGroupList(curPage, pageSize, this.state.queryFormValues);
  }

  //获取角色详情
  getAuthGroupDetail(id, type, sys) {
    let params = { id };
    _authGroupDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            sys,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中tree
  onCheck = (checkedKeys) => {
    // const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
    this.setState({ checkedKeys });
  };

  //tree展开
  onExpand = (expandedKeys) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  //Modal打开事件
  showSQModal(id, title) {
    this.getAllMenus(id);
    this.setState({
      title,
      authGroupId: id,
      visibleSQ: true,
    });
  }

  //Modal提交事件
  handleSQOk(e) {
    e.preventDefault();
    const { title, authGroupId, checkedKeys } = this.state;
    let rules = checkedKeys.join(",");
    let params = {
      id: authGroupId,
      title,
      rules,
    };
    _saveAuthGroup(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.setState({
            visibleSQ: false,
          });
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal取消事件
  initSQFormModal() {
    this.setState({
      title: "",
      authGroupId: "",
      expandedKeys: [],
      treeData: [],
      checkedKeys: [],
      visibleSQ: false,
    });
  }

  //获取授权列表
  getAllMenus(authGroupId) {
    let params = { authGroupId };
    _allMenus(params)
      .then((res) => {
        if (res.data.code === 200) {
          let expandedKeys = [];
          let menusList = res.data.data.menus;
          menusList.map((item, index) => {
            expandedKeys.push(String(item.key));
            if (item.children) {
              item.children.map((item1, index1) => {
                expandedKeys.push(String(item1.key));
                return item1;
              });
            }
            return item;
          });
          this.setState({
            expandedKeys: expandedKeys,
            treeData: res.data.data.menus,
            checkedKeys: res.data.data.menuIds,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateAuthGroupStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getAuthGroupList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //单条删除
  handleDeleteSiteChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteAuthGroup(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(() => {
            message.warning("操作失败");
          });
      },
    });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteAuthGroup(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
            self.setState({
              selectedRowKeys: [],
              loading: false,
            });
          })
          .catch(() => {
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //Modal打开事件
  showModal(modalType) {
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      let params =
        modalType === 1
          ? {
              ...values,
            }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _saveAuthGroup(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: 0,
      initialValues: {},
      visible: false,
    });
  }
}

RoleManagement = Form.create({})(RoleManagement);
export { RoleManagement };
