import React, { useEffect } from "react";
import useHandle from "@/hooks/handle";
import { _exportWithholdComplainList } from "@/statics/js/api.js";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from '@/components/siteSelect';
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from 'moment';
import { Form, Button, Input, Select, Row, Col, DatePicker } from "antd";
const { Option } = Select;
const {RangePicker} = DatePicker

const Index = Form.create({})((props) => {
  const { search, form } = props;
  const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue,setFieldsValue} = form
  const userType = localStorage.getItem("type");
  const {
    handleDownload,
    loading: exportLoading
  } = useHandle()
  useEffect(() => {
    
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      const [startTime,endTime] = values.time || []
      const params = {
        ...values,
        pushTimeStart: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
        pushTimeEnd: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
      }
      search(params);
    });
  };

  const handleExportChange = async ()=>{
    let values = getFieldsValue()
    const [startTime,endTime] = values.time || []
    const params = {
      ...values,
      pushTimeStart: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
      pushTimeEnd: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    }
    handleDownload({
      action: _exportWithholdComplainList,
      query: params
    })
  }

  return (
    <div>
      <Form onSubmit={handleSearch} layout="inline">
        <Row gutter={16}>
          {
              userType == 0 && 
              <Col lg={8} md={12} sm={24}>
                  <Form.Item label="代理商">
                      {getFieldDecorator("fid")(
                          <FranchiseeSelect onChange={()=>{setFieldsValue({ sid: "" })}} />
                      )}
                  </Form.Item>
              </Col>
          }
          <Col lg={8} md={12} sm={24}>
              <Form.Item label="站点">
                  {getFieldDecorator("sid")(
                      <SiteSelect fid={getFieldValue('fid')} />
                  )}
              </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="投诉单状态">
              {getFieldDecorator("status")(
                <Select placeholder="请选择" allowClear>
                  <Option value={0}>待处理</Option>
                  <Option value={1}>审核通过</Option>
                  <Option value={2}>取消</Option>
                  <Option value={3}>拒绝</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="申诉人/手机号">
              {getFieldDecorator("contactSTel")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="申诉时间">
              {getFieldDecorator("time")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(1095)}
                title={getJurisdiction(1095) ? "" : "暂无操作权限！"}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                loading={exportLoading}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
})

export default Index;
