import React,{ 
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react'
import useList from '@/hooks/list'
import {
    Select,
} from "antd";
const { Option } = Select;

const Index = forwardRef((props,ref)=>{
    const {
        franchiseeList,
        getFranchiseeList,
    } = useList()

    useEffect(() => {
        getFranchiseeList()
    },[]);
    const getList = ()=>{
        return franchiseeList
    }
    useImperativeHandle(
        ref,
        () => ({
            getList,
        }),
        [
            getList,
        ]
    );

    return (
        <Select
            placeholder="请选择"
            allowClear
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...props}
        >
            {
                franchiseeList.map((item, index) => (
                    item.fstate ? 
                    <Option key={index} value={item.fid}>{item.fname}</Option>
                    :
                    null
                ))
            }
        </Select>
    )
})

export default Index