import React from 'react';
import moment from "moment";
import {
    Table,
    Popover,
} from "antd";
import useTable from '../../../../../hooks/table'
import {
    _getTrackSegment,
} from "../../../../../statics/js/api.js"

const Index = (props) => {
    const { filter } = props
    let {imei,startTime,endTime} = filter
    const columns = [
        {
            title: "序号",
            dataIndex: "no",
            width: 100,
            render: (text, record, index) => index + 1
        },
        {
            title: "开始时间",
            width: 180,
            dataIndex: "startTime",
        },
        {
            title: "结束时间",
            width: 180,
            dataIndex: "endTime",
        },
        {
            title: "总里程",
            dataIndex: "mileage",
        },
        {
            title: "总用时",
            dataIndex: "totalTime",
        },
        {
            title: "平均速度",
            dataIndex: "averageSpeed",
        },
        {
            title: "最高时速",
            dataIndex: "highestSpeed",
        },
        {
            title: "分段类型",
            dataIndex: "type",
        },
        {
            title: "创建时间",
            width: 180,
            dataIndex: "createTime",
        },
        {
            title: "开始地址",
            ellipsis: true,
            dataIndex: "startAddr",
            width: 140,
            render: (text, record, index) => <Popover content={text} placement="left">{text}</Popover>
        },
        {
            title: "结束地址",
            ellipsis: true,
            dataIndex: "endAddr",
            width: 140,
            render: (text, record, index) => <Popover content={text} placement="left">{text}</Popover>
        },
    ]

    const {
        list,
        loading,
    } = useTable({
        action: _getTrackSegment,
        params: {
            imei,
            startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        response: (res) => {
            const { trackSegmentList = [] } = res.data.data || {}
            return {
                rows: trackSegmentList
            }
        }
    })

    return (<>
        <Table
            rowKey="id"
            bordered
            scroll={{ y: 500 }}
            columns={columns}
            dataSource={list}
            loading={loading}
            pagination={false}
        />
    </>)
}

export default Index