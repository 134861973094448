import React, { Component } from "react";
import {
  Button,
  Table,
  Input,
  Switch,
  Select,
  Row,
  Col,
  Form,
  Modal,
  message,
  Divider,
} from "antd";
import {
  _menuList,
  _menuDetail,
  _deleteMenu,
  _updateMenuStatus,
  _saveMenu,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const { Option } = Select;
const modalTitle = ["添加菜单", "编辑菜单"];
class SystemManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      initialValues: {}, //菜单详情
      id: "", //菜单id
      menuList: [], //菜单列表
      menuList1: [], //菜单列表1
    };
    this.columns = [
      {
        title: "排序",
        width: 80,
        dataIndex: "sort",
      },
      {
        title: "菜单名称",
        dataIndex: "title",
      },
      {
        title: "菜单链接",
        dataIndex: "name",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(14)}
            title={getJurisdiction(14) ? "" : "暂无操作权限！"}
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id, 1)}
          />
        ),
      },
      {
        title: "是否菜单",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            defaultChecked={!!text.ismenu}
            checkedChildren="是"
            unCheckedChildren="否"
            onChange={this.handleStateChange.bind(this, text.id, 2)}
          />
        ),
      },
      {
        title: "添加时间",
        dataIndex: "createTime",
        width: 120,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "更新时间",
        dataIndex: "updateTime",
        width: 120,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(12)}
              title={getJurisdiction(12) ? "" : "暂无操作权限！"}
              onClick={this.getMenuDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(13)}
              title={getJurisdiction(13) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteSiteChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  render() {
    const { menuList, pageSize, totalCount, curPage, initialValues } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(50) ? (
          <div className="SystemManagement">
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(12)}
                title={getJurisdiction(12) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加菜单
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={menuList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="上级菜单">
                    {getFieldDecorator("pid", {
                      initialValue: initialValues.pid,
                    })(
                      <Select
                        placeholder="请选择"
                        allowClear
                        style={{ width: "100%" }}
                      >
                        {this.state.menuList1.length &&
                          this.state.menuList1.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.title}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="菜单名称">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "请输入菜单名称",
                        },
                      ],
                      initialValue: initialValues.title,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="菜单链接">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "请输入菜单链接",
                        },
                      ],
                      initialValue: initialValues.name,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getMenuList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
    this.getMenuList1();
  }

  //获取菜单列表
  getMenuList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _menuList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            menuList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取菜单列表
  getMenuList1() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _menuList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            menuList1: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getMenuList(curPage, pageSize, this.state.queryFormValues);
  }

  //获取列表详情
  getMenuDetail(id, type) {
    let params = { id };
    _menuDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //单条删除
  handleDeleteSiteChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteMenu(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(() => {
            message.warning("操作失败");
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, type, checked) {
    let params = {};
    if (type === 1) {
      params = { id, status: Number(checked) };
    } else if (type === 2) {
      params = { id, ismenu: Number(checked) };
    }
    _updateMenuStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getMenuList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _saveMenu(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: "",
      initialValues: {},
      visible: false,
    });
  }
}

SystemManagement = Form.create({})(SystemManagement);
export { SystemManagement };
