import React, { Component, useState,useEffect } from "react";
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Divider,
  Select,
  message,
  Modal,
  Radio,
  Spin,
  Tabs,
} from "antd";
import {
  _usersList,
  _returnReal,
  _updateReal,
  _deleteUser,
  _batchDeleteUser,
  _updateUserStatus,
  _queryTjUser,
  _updateTjUser,
  _userTjLogList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import "./style.less";

const { Option } = Select;

const SingleRecommendModal = Form.create({})((props)=>{
  const {
    visible,
    onCancel=()=>{},
    onFinish=()=>{},
    form,
    member,
  } = props
  const { getFieldDecorator,getFieldsValue,setFieldsValue } = form
  const [recommendList,setRecommendList] = useState([])
  const [loading,setLoading] = useState(false)
  const [confirmLoading,setConfirmLoading] = useState(false)
  const [list,setList] = useState([])
  const [curPage,setCurPage] = useState(1)
  const [totalCount,setTotalCount] = useState(0)
  const [pageSize,setPageSize] = useState(10)
  const columns = [
    {
      title: "原推荐人信息",
      dataIndex: "",
      render: (text) => (
        <div>
          <p>认证信息：{text.preTjName}</p>
          <p>推荐码：{text.preTjCode}</p>
          <p>手机号：{text.preTjMobile}</p>
        </div>
      ),
    },
    {
      title: "新推荐人信息",
      dataIndex: "",
      render: (text) => (
        <div>
          <p>认证信息：{text.tjName}</p>
          <p>推荐码：{text.tjCode}</p>
          <p>手机号：{text.tjMobile}</p>
        </div>
      ),
    },
    {
      title: "修改时间",
      dataIndex: "addTime",
      render: (text)=>moment(text).format("YYYY-MM-DD HH:mm:ss")
    },
  ]
  useEffect(()=>{
    if(visible){
      setRecommendList([])
      setFieldsValue({
        tjMobile: '',
        tjId: '',
      })
      setConfirmLoading(false)
      getList()
    }
  },[visible])
  const handleKeywordChange = async (e)=>{
    const value = e.target.value
    if(value.length != 8 && value.length != 11){
      setRecommendList([])
      setFieldsValue({tjId: ''})
      return
    }
    const params = {
      tjMobile: value,
    }
    setLoading(true)
    const res = await _queryTjUser(params)
    setLoading(false)
    const {data=[]} = res.data || {}
    const arr = data || []
    setRecommendList(arr)
    const {kid} = arr[0] || {}
    setFieldsValue({tjId: kid})
  }
  const handleOk = async ()=>{
    const {tjId} = getFieldsValue()
    if(!tjId){
      message.error("推荐人不能为空");
      return
    }
    const params = {
      tjId,
      kids: [member.kid]
    }
    setConfirmLoading(true)
    const res = await _updateTjUser(params)
    setConfirmLoading(false)
    const {code,message:msg} = res.data || {}
    if(code != 200){
      message.error(msg);
      return
    }
    onCancel()
    onFinish()
  }
  const getList = async (curPage, pageSize)=>{
    const params = {
      kid: member.kid,
      curPage,
      pageSize
    }
    const res = await _userTjLogList(params)
    const {
      code,
      data: {
        rows=[],
        totalCount=0,
      }
    } = res.data || {}
    setList(rows || [])
    setCurPage(curPage)
    setTotalCount(totalCount)
  }
  const handlePageChange = (curPage, pageSize)=> {
    getList(curPage, pageSize)
  }
  return (
    <Modal
      width={900}
      title="修改推荐人"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="修改推荐人" key="1">
          <div className="box">
            <div className="box_title">原推荐人</div>
            <Row>
              <Col span={8}>
                认证信息：{member.tjName}
              </Col>
              <Col span={8}>
                手机号：{member.tjMobile}
              </Col>
              <Col span={8}>
                推荐码：{member.tjCode}
              </Col>
            </Row>
          </div>
          <h3>新推荐人信息</h3>
          <Form>
            <Form.Item label="手机号/推荐码">
              {getFieldDecorator("tjMobile",{
                rules: [{ required: true, message: '输入手机号/推荐码' }],
              })(
                <Input onChange={handleKeywordChange} allowClear placeholder="请输入新推荐人完整手机号或推荐码" />
              )}
            </Form.Item>
            <Spin spinning={loading}>
              <Form.Item label="认证信息">
                {getFieldDecorator('tjId',{
                  rules: [{ required: true, message: '推荐人不能为空' }],
                })(
                  <Radio.Group>
                    {
                      recommendList.map(item=>(
                        <div key={item.kid} style={{margin: '6px 0'}}>
                          <Radio value={item.kid}>{item.realname}（{item.inviteCode}）</Radio>
                        </div>
                      ))
                    }
                  </Radio.Group>,
                )}
              </Form.Item>
            </Spin>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="修改历史记录" key="2">
          <Table
            rowKey="id"
            bordered
            columns={columns}
            dataSource={list}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: handlePageChange,
              onChange: handlePageChange,
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
})

const RecommendModal = Form.create({})((props)=>{
  const {
    visible,
    onCancel=()=>{},
    onFinish=()=>{},
    form,
    list,
  } = props
  const { getFieldDecorator,getFieldsValue,setFieldsValue } = form
  const [recommendList,setRecommendList] = useState([])
  const [members,setMembers] = useState([])
  const [loading,setLoading] = useState(false)
  const [confirmLoading,setConfirmLoading] = useState(false)
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "认证信息",
      dataIndex: "realname",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      render: (text) => text || ''
    },
    {
      title: "性别",
      dataIndex: "sex",
      width: 80,
      render: (text) => (text === 1 ? "男" : text === 2 ? "女" : "保密"),
    },
    {
      title: "原推荐人信息",
      dataIndex: "",
      render: (text) => (
        <div>
          <p>认证信息：{text.tjName}</p>
          <p>推荐码：{text.tjCode}</p>
          <p>手机号：{text.tjMobile}</p>
        </div>
      ),
    },
    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <>
          <Button type="link" onClick={()=>handleDelete(text)}>移除</Button>
        </>
      ),
    },
  ]
  useEffect(()=>{
    if(visible){
      setMembers(list)
      setRecommendList([])
      setFieldsValue({
        tjMobile: '',
        tjId: '',
      })
      setConfirmLoading(false)
    }
  },[visible])
  const handleDelete = async ({kid})=>{
    setMembers(value=>value.filter(item=>item.kid != kid))
  }
  const handleKeywordChange = async (e)=>{
    const value = e.target.value
    if(value.length != 8 && value.length != 11){
      setRecommendList([])
      setFieldsValue({tjId: ''})
      return
    }
    const params = {
      tjMobile: value,
    }
    setLoading(true)
    const res = await _queryTjUser(params)
    setLoading(false)
    const {data=[]} = res.data || {}
    const arr = data || []
    setRecommendList(arr)
    const {kid} = arr[0] || {}
    setFieldsValue({tjId: kid})
  }
  const handleOk = async ()=>{
    const {tjId} = getFieldsValue()
    if(!tjId){
      message.error("推荐人不能为空");
      return
    }
    if(!members.length){
      message.error("用户列表不能为空");
      return
    }
    const params = {
      tjId,
      kids: members.map(item=>item.kid)
    }
    setConfirmLoading(true)
    const res = await _updateTjUser(params)
    setConfirmLoading(false)
    const {code,message:msg} = res.data || {}
    if(code != 200){
      message.error(msg);
      return
    }
    onCancel()
    onFinish()
  }
  return (
    <Modal
      width={900}
      title="批量修改推荐人"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <h3>新推荐人信息</h3>
      <Form>
        <Form.Item label="手机号/推荐码">
          {getFieldDecorator("tjMobile",{
            rules: [{ required: true, message: '输入手机号/推荐码' }],
          })(
            <Input onChange={handleKeywordChange} allowClear placeholder="请输入新推荐人完整手机号或推荐码" />
          )}
        </Form.Item>
        <Spin spinning={loading}>
          <Form.Item label="认证信息">
            {getFieldDecorator('tjId',{
              rules: [{ required: true, message: '推荐人不能为空' }],
            })(
              <Radio.Group>
                {
                  recommendList.map(item=>(
                    <div key={item.kid} style={{margin: '6px 0'}}>
                      <Radio value={item.kid}>{item.realname}（{item.inviteCode}）</Radio>
                    </div>
                  ))
                }
              </Radio.Group>,
            )}
          </Form.Item>
        </Spin>
      </Form>
      <h3>用户列表 共{members.length}人</h3>
      <Table
        rowKey="kid"
        bordered
        columns={columns}
        dataSource={members}
      />
    </Modal>
  )
})


class MemberManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      usersList: [], //会员列表
      queryFormValues: "", // 搜索项
      kid: "", //用户id
      selectedRow: [], // Check here to configure the default column
      loading: false,
      recommendVisible: false,
      singleRecommendVisible: false,
      member: {},
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "用户名",
        dataIndex: "username",
      },
      {
        title: "手机号",
        align: "right",
        width: 140,
        dataIndex: "mobile",
      },
      {
        title: "头像",
        dataIndex: "avatar",
        render: (text) => (
          <img style={{ width: "50px", height: "50px" }} src={text} alt="" />
        ),
      },
      {
        title: "性别",
        dataIndex: "sex",
        width: 80,
        render: (text) => (text === 1 ? "男" : text === 2 ? "女" : "保密"),
      },
      {
        title: "注册时间",
        dataIndex: "regTime",
        width: 135,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "认证信息",
        dataIndex: "",
        width: 135,
        render: (text) => (
          <span>
            {text.realname}
            <br />
            {text.certifyTime &&
              moment.unix(text.certifyTime).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        ),
      },
      {
        title: "推荐人",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.tjName}
            <br />
            {text.tjMobile || "无"}
          </span>
        )
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(781)}
            title={getJurisdiction(781) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.kid)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <>
            <Button
              type="link"
              disabled={!getJurisdiction(781)}
              title={getJurisdiction(781) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteChange.bind(this, text.kid)}
            >
              删除
            </Button>
            {text.isCertify ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(1027)}
                  title={getJurisdiction(1027) ? "" : "暂无操作权限！"}
                  onClick={this.handleEditChange.bind(this, text.kid)}
                >
                  编辑
                </Button>
              </>
            ) : null}
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(1041)}
              title={getJurisdiction(1041) ? "" : "暂无操作权限！"}
              onClick={this.handleSingleRecommendChange.bind(this, text)}
            >
              修改推荐人
            </Button>
          </>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户信息">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入用户姓名/手机号/邀请码" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="身份认证">
              {getFieldDecorator("isCertify")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="推荐人信息">
              {getFieldDecorator("tjString")(
                <Input allowClear placeholder="请输入推荐人姓名/手机号/邀请码" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      loading,
      usersList,
      pageSize,
      totalCount,
      curPage,
      selectedRow,
      recommendVisible,
      singleRecommendVisible,
      member,
    } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRow.map(item=>item.kid),
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRow.length > 0;

    return (
      <div>
        {!!getJurisdiction(740) ? (
          <div className="MemberManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(781) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              <Button
                onClick={this.handleRecommendChange.bind(this)}
                disabled={!getJurisdiction(1041) || !hasSelected}
                loading={loading}
              >
                批量修改推荐人
              </Button>
              {hasSelected && `已选中 ${selectedRow.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="kid"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={usersList}
                components={this.components}
                scroll={{ x: 1300, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <RecommendModal 
              visible={recommendVisible} 
              onCancel={()=>this.setState({recommendVisible: false})}
              list={selectedRow}
              onFinish={this.handleFinish.bind(this)} 
            />
            <SingleRecommendModal 
              visible={singleRecommendVisible} 
              onCancel={()=>this.setState({singleRecommendVisible: false})}
              member={member}
              onFinish={this.handleFinish.bind(this)} 
            />
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getUsersList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取站点列表
  getUsersList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _usersList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            usersList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getUsersList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      this.setState({
        queryFormValues: values,
      });
      this.getUsersList(1, this.state.pageSize, values);
    });
  };

  //单条删除
  handleDeleteChange(kid) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { kid };
        _deleteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //编辑
  handleEditChange(kid) {
    let self = this;
    let params = { kid };
    let realName, cardNo;
    _returnReal(params).then((res) => {
      if (res.data.code === 200) {
        Modal.confirm({
          title: "编辑",
          content: (
            <div>
              <div
                style={{
                  background: "#F5F4F4",
                  padding: 10,
                  fontSize: 12,
                  color: "#999",
                  borderRadius: 5,
                }}
              >
                <p>用户认证信息</p>
                <div>
                  <span>姓名：{res.data.data.username}</span>
                  <span style={{ marginLeft: 20 }}>
                    身份证：{res.data.data.cardNo}
                  </span>
                </div>
              </div>
              <div style={{ marginTop: 15, fontWeight: "bold" }}>
                修改用户认证信息
              </div>
              {res.data.data.realPrompt && (
                <span style={{ fontSize: 10, color: "#2391F3" }}>
                  {res.data.data.realPrompt}
                </span>
              )}
              <br />
              <Form.Item label="姓名">
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    realName = e.target.value;
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item label="身份证号">
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    cardNo = e.target.value;
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
          ),
          onOk() {
            return new Promise(async (resolve, reject) => {
              if (realName && cardNo) {
                let params = {
                  kid,
                  realName,
                  cardNo,
                };
                _updateReal(params)
                  .then((res) => {
                    if (res.data.code === 200) {
                      message.success("操作成功");
                      self.getUsersList(
                        self.state.curPage,
                        self.state.pageSize,
                        self.state.queryFormValues
                      );
                      resolve();
                    } else {
                      message.warning(res.data.message);
                      reject();
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    message.warning("操作失败");
                    reject();
                  });
              } else {
                message.warning("认证信息填写完成后再提交！");
                reject();
              }
            });
          },
        });
      }
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据？",
      onOk() {
        let params = self.state.selectedRow.map(item=>item.kid);
        _batchDeleteUser(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getUsersList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
            self.setState({
              selectedRow: [],
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRow: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }
  handleRecommendChange(){
    this.setState({recommendVisible: true})
  }
  handleSingleRecommendChange(text){
    this.setState({
      singleRecommendVisible: true,
      member: {...text}
    })
  }
  //管理状态Switch
  handleStateChange(kid, checked) {
    let params = { kid, status: Number(checked) };
    _updateUserStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getUsersList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys,selectedRow) => {
    this.setState({ selectedRow });
  };
  handleFinish = ()=>{
    this.getUsersList(this.state.curPage,this.state.pageSize,this.state.queryFormValues)
    this.setState({ selectedRow: [] });
  }
  
}

MemberManagement = Form.create({})(MemberManagement);
export { MemberManagement };
