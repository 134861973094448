import React from 'react';
import moment from "moment";
import {
  Table,
  Popover
} from "antd";
import useTable from '../../../../../hooks/table'
import {
  _getGpsHis,
} from "../../../../../statics/js/api.js"

const Index = (props) => {
  const { filter } = props
  let {imei,startTime,endTime} = filter
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1
    },
    {
      title: "定位类型",
      dataIndex: "locType",
    },
    {
      title: "经度/纬度",
      width: 200,
      dataIndex: "",
      render: (text, record, index) => record.longitude + ',' + record.latitude
    },
    // {
    //   title: "解析地址",
    //   dataIndex: "",
    //   ellipsis: true,
    //   width: 140,
    //   render: (text, record, index) => <Popover content={text} placement="left">{text}</Popover>
    // },
    {
      title: "平均速度（km/h）",
      dataIndex: "speed",
    },
    {
      title: "方向",
      dataIndex: "direction",
    },
    {
      title: "卫星数",
      dataIndex: "satellitesNum",
    },
    {
      title: "补传标识",
      dataIndex: "complementFlag",
    },
    {
      title: "定位时间",
      width: 180,
      dataIndex: "gpsTime",
    },
    {
      title: "网关时间",
      width: 180,
      dataIndex: "gateTime",
    },
  ]

  const {
    list,
    loading,
  } = useTable({
    action: _getGpsHis,
    params: {
      imei,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
    },
    response: (res) => {
      const { gpsHisConnects = [] } = res.data.data || {}
      return {
        rows: gpsHisConnects
      }
    }
  })

  return (<>
    <Table
      rowKey={(_, index)=>'x'+index}
      bordered
      scroll={{ y: 500 }}
      columns={columns}
      dataSource={list}
      loading={loading}
      pagination={false}
    />
  </>)
}

export default Index