import React,{
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';
var myChart = null
const Index = forwardRef((props,ref) => {

    useEffect(()=>{
        myChart = echarts.init(document.getElementById('bar_chart'));
    },[])

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);

    var init = ({TimeXScale,data})=>{
        var options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: ['电芯电压']
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                left: '2%',
                right: '2%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    data: TimeXScale
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} mV'
                    }
                }
            ],
            series: [
                {
                    name: '电压(mV)',
                    type: 'bar',
                    stack: '总量',
                    data: data,
                    color: ['#2ec133'],
                    markPoint:{
                        label: {
                            show: true,
                            position: "top",
                            distance: 7,
                            offset: [1, 1],
                            formatter: '{c} mV',
                        },
                        data: [
                            {
                                type:'max',
                                name:'最大值',
                                itemStyle:{
                                    color: '#FFB800'
                                }
                            },
                            {
                                type:'min',
                                name:'最小值',
                                itemStyle:{
                                    color: '#1E9FFF'
                                }
                            }
                        ],
                    },
                }
            ]
        };
        myChart.setOption(options)
    }

    return (
        <>
            <div id="bar_chart" style={{height: '500px',width: '100%'}}></div>
        </>
    )
})

export default Index