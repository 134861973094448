import React,{ 
    useEffect,
    forwardRef,
} from 'react'
import useList from '@/hooks/list'
import {
    Select,
} from "antd";
const { Option } = Select;

const Index = forwardRef((props,ref)=>{
    const {
        getPayTypeList,
        payTypeList,
        setPayTypeList
    } = useList()

    useEffect(() => {
        init()
    },[props.ptype]);
    const init = async ()=>{
        setPayTypeList([])
        getPayTypeList(props.ptype)
    }
    return (
        <Select
            placeholder="请选择"
            allowClear
            style={{ width: "100%" }}
            {...props}
        >
            {
                payTypeList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
            }
        </Select>
    )
})

export default Index