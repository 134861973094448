import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Radio,
  Divider,
  Select,
  message,
  Modal,
} from "antd";
import {
  _repRiderList,
  _repCustomerList,
  _repRiderDetail,
  _addRepRider,
  _deleteRepRiderStatus,
  _batchDeleteRepRider,
  _updateRepRiderStatus,
} from "@/statics/js/api";
import moment from "moment";

const modalTitle = ["添加骑手", "编辑骑手"];
const { Option } = Select;
class KARepRiderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      riderList: [], //骑手列表
      repCustomerList: [], //代理商列表
      queryFormValues: "", // 搜索项
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      initialValues: {}, //用户详情
      id: "", //用户id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "用户名",
        dataIndex: "name",
      },
      {
        title: "手机号",
        align: "right",
        dataIndex: "mobile",
      },
      {
        title: "所属代理商",
        dataIndex: "fname",
      },
      {
        title: "头像",
        dataIndex: "avatar",
        render: (text) => (
          <img style={{ width: "50px", height: "50px" }} src={text} alt="" />
        ),
      },
      {
        title: "性别",
        dataIndex: "sex",
        render: (text) => (text === 1 ? "男" : text === 2 ? "女" : "保密"),
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "管理状态",
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.getUserDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.handleDeleteChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户名/手机">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.repCustomerList.length &&
                      this.state.repCustomerList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const {
      loading,
      riderList,
      pageSize,
      totalCount,
      curPage,
      selectedRowKeys,
      initialValues,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="KARepRiderList">
        <div className="select-form">{this.renderForm()}</div>
        <div className="top-btn">
          <Button
            icon="plus"
            type="primary"
            onClick={this.showModal.bind(this, 1)}
          >
            添加骑手
          </Button>
          <Button
            onClick={this.handleBatchDeleteChange.bind(this)}
            disabled={!hasSelected}
            loading={loading}
            icon="delete"
            type="danger"
          >
            批量删除
          </Button>
          {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
        </div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={riderList}
            components={this.components}
            scroll={{ x: 1200, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title={modalTitle[this.state.modalType - 1]}
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="手机号">
                {getFieldDecorator("mobile", {
                  validateTrigger: "onBlur",
                  initialValue: initialValues.mobile,
                  rules: [
                    {
                      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                      message: "请输入正确的手机号格式",
                    },
                  ],
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="用户名">
                {getFieldDecorator("name", {
                  initialValue: initialValues.name,
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="性别">
                {getFieldDecorator("sex", {
                  initialValue: initialValues.sex,
                })(
                  <Radio.Group>
                    <Radio value={0}>保密</Radio>
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status === 0 ? false : true,
                  valuePropName: "checked",
                })(<Switch checkedChildren="启用" unCheckedChildren="禁用" />)}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.getRepCustomerList();
    this.getRepRiderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getRepCustomerList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _repCustomerList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            repCustomerList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取骑手列表
  getRepRiderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _repRiderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            riderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getRepRiderList(curPage, pageSize, this.state.queryFormValues);
  }

  //获取骑手详情
  getUserDetail(id, type) {
    let params = { id };
    _repRiderDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      this.setState({
        queryFormValues: values,
      });
      this.getRepRiderList(1, this.state.pageSize, values);
    });
  };

  //单条删除
  handleDeleteChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteRepRiderStatus(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(() => {
            message.warning("操作失败");
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDeleteRepRider(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getRepRiderList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
            self.setState({
              selectedRowKeys: [],
              loading: false,
            });
          })
          .catch(() => {
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //骑手状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateRepRiderStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getRepRiderList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _addRepRider(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: 0,
      initialValues: {},
      visible: false,
    });
  }
}

KARepRiderList = Form.create({})(KARepRiderList);
export { KARepRiderList };
