import React, { useState, useEffect } from "react";
import { Table } from "antd";
import SearchBox from "./components/searchBox";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _userAccountList, _allAmount } from "@/statics/js/api.js";

const Index = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "用户名",
      dataIndex: "realName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "充电钱包金额",
      dataIndex: "useAmount",
    },
    {
      title: "账户余额",
      dataIndex: "amount",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      render: (text) => (
        <img style={{ width: "50px", height: "50px" }} src={text} alt="" />
      ),
    },
    {
      title: "性别",
      dataIndex: "sex",
      width: 80,
      render: (text) => (text === 1 ? "男" : text === 2 ? "女" : "保密"),
    },
    {
      title: "注册时间",
      dataIndex: "regTime",
      width: 135,
    },
    {
      title: "认证信息",
      dataIndex: "",
      width: 135,
      render: (text) => (
        <span>
          {text.realName}
          <br />
          {text.certifyTime && text.certifyTime}
        </span>
      ),
    },
    {
      title: "推荐人",
      dataIndex: "tjMobile",
      render: (text) => (text ? text : "无"),
    },
  ];
  const [allAmount, setAllAmount] = useState(0);
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
  } = useTable({
    action: _userAccountList,
  });

  useEffect(() => {
    getAllAmount();
  }, []);

  const getAllAmount = async () => {
    const res = await _allAmount();
    if (res.data.code === 200) {
      let allAmount = res.data.data;
      setAllAmount(allAmount);
    }
  };

  return !getJurisdiction(1022) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <div
        style={{
          lineHeight: "56px",
          background: "#F4F4F4",
          borderRadius: "5px",
          marginBottom: "10px",
          padding: "0 50px",
          color: "#4D4D4D",
        }}
      >
        用户账户余额：
        <span
          style={{
            padding: "0 50px 0 10px",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          {allAmount}
        </span>
      </div>
      <Table
        rowKey="kid"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 1500, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
    </div>
  );
};

export default Index;
