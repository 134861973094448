import {useState, useEffect,useCallback} from 'react'
import {
    _franchiseeList,
    _merchantBikeModels,
    _bikeModelList,
    _kaSiteList,
    _batteryModelList,
    _merchantBatModels,
    _siteList,
    _getGpsTypeList,
    _getPayMethods,
    _getRentType,
    _siteRepairList,
    _packageNameList,
    _bdNameList,
    _orderHangHpSite,
  } from "@/statics/js/api";

 function useList(props) {
    const [franchiseeList,setFranchiseeList] = useState([])
    const [bikeModelList,setBikeModelList] = useState([])
    const [siteList,setSiteList] = useState([])
    const [kaSiteList,setKaSiteList] = useState([])
    const [batteryModelList,setBatteryModelList] = useState([])
    const [gpsTypeList,setGpsTypeList] = useState([])
    const [payTypeList,setPayTypeList] = useState([])
    const [rentTypeList,setRentTypeList] = useState([])
    const [repairList,setRepairList] = useState([])
    const [packageList,setPackageList] = useState([])
    const [bdList,setBdList] = useState([])
    const [hangSiteList,setHangSiteList] = useState([])

    useEffect(()=>{
        
    },[])

    const getFranchiseeList = useCallback(async (query)=> {
        let {ftype="",hideForbidden=false} = query || {}
        let params = {
            curPage: 1,
            pageSize: 999999,
            ftype,
        };
        const res = await _franchiseeList(params)
        const {code,data} = res.data || {}
        let {rows=[]} = data || {}
        if(hideForbidden){
            rows=rows.filter(item=>item.fstate)
        }
        setFranchiseeList(rows)
    },[])

    const getSiteList = useCallback(async (fid)=> {
        let params = {
            curPage: 1,
            pageSize: 999999,
            fid,
        };
        const res = await _siteList(params)
        const {code,data} = res.data || {}
        const {rows=[]} = data || {}
        setSiteList(rows)
    },[])
    
    const getKaSiteList = useCallback(async (fid)=> {
        let params = {
            curPage: 1,
            pageSize: 999999,
            fid,
        };
        const res = await _kaSiteList(params)
        const {code,data} = res.data || {}
        const {rows=[]} = data || {}
        setKaSiteList(rows)
    },[])

    const getBikeModelList = useCallback(async (query={})=> {
        const {self} = query
        const params = {
            curPage: 1,
            pageSize: 999999,
            self,
        }
        if (localStorage.getItem("type") === "0") {
            const res = await _bikeModelList(params)
            const {code,data} = res.data || {}
            if(code != 200){
                return
            }
            setBikeModelList(data.rows)
        } else {
            params.fid = localStorage.getItem("fid")
            const res = await _merchantBikeModels(params)
            const {code,data=[]} = res.data || {}
            if(code != 200){
                return
            }
            setBikeModelList(data)
        }
    },[])

    const getBatteryModelList = useCallback(async (query={})=> {
        const {self} = query
        const params = {
            curPage: 1,
            pageSize: 999999,
            self
        }
        if (localStorage.getItem("type") === "0") {
            const res = await _batteryModelList(params)
            const {code,data} = res.data || {}
            if(code != 200){
                return
            }
            setBatteryModelList(data.rows)
        } else {
            params.fid = localStorage.getItem("fid")
            const res = await _merchantBatModels(params)
            const {code,data=[]} = res.data || {}
            if(code != 200){
                return
            }
            setBatteryModelList(data)
        }
    },[])

    const getGpsTypeList = useCallback(async (query)=> {
        const res = await _getGpsTypeList()
        const {data} = res.data || {}
        setGpsTypeList(data)
    },[])

    const getPayTypeList = useCallback(async (ptype)=> {
        const params = {
            ptype
        }
        const res = await _getPayMethods(params)
        const {data=[]} = res.data || {}
        setPayTypeList(data || [])
    },[])

    const getRentTypeList = useCallback(async ()=> {
        const res = await _getRentType()
        const {data=[]} = res.data || {}
        setRentTypeList(data || [])
    },[])

    const getRepairList = useCallback(async (area=[])=> {
        const value = area || []
        const params = {
            province: value[0],
            city: value[1],
            curPage: 1,
            pageSize: 999999,
        };
        const res = await _siteRepairList(params)
        const {data={}} = res.data || {}
        const {rows=[]} = data || {}
        setRepairList(rows || [])
    },[])
    
    const getPackageList = useCallback(async (query={})=> {
        const res = await _packageNameList()
        const {data=[]} = res.data || {}
        setPackageList(data || [])
    },[])

    const getBdList = useCallback(async (query={})=> {
        const res = await _bdNameList()
        const {data=[]} = res.data || {}
        setBdList(data || [])
    },[])

    const getHangSiteList = useCallback(async (query={})=> {
        const res = await _orderHangHpSite(query)
        const {data=[]} = res.data || {}
        setHangSiteList(data || [])
    },[])

    return {
        franchiseeList,
        getFranchiseeList,
        bikeModelList,
        getBikeModelList,
        getKaSiteList,
        kaSiteList,
        setKaSiteList,
        setFranchiseeList,
        setBikeModelList,
        getBatteryModelList,
        batteryModelList,
        setBatteryModelList,
        setSiteList,
        getSiteList,
        siteList,
        getGpsTypeList,
        gpsTypeList,
        getPayTypeList,
        setPayTypeList,
        payTypeList,
        getRentTypeList,
        rentTypeList,
        setRentTypeList,
        getRepairList,
        repairList,
        setRepairList,
        packageList,
        getPackageList,
        bdList,
        getBdList,
        getHangSiteList,
        hangSiteList,
        setHangSiteList
    }
}

export default useList