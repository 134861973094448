import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  DatePicker,
  Select,
  Input,
  message,
  Row,
  Col,
} from "antd";
import {
  _franchiseeList,
  _todayBillList,
  _exportTodayBillList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import queryString from 'query-string';
const Option = Select.Option;
class TodayOrder extends Component {
  constructor(props) {
    super(props);
    const {orderStatus,newOrderStatus, hangUp,isRenewal, fid  } = queryString.parse(this.props.location.search);
    this.state = {
      queryFormValues: {
        orderStatus: orderStatus,
        newOrderStatus: newOrderStatus,
        hangUp: hangUp,
        isRenewal: isRenewal,
        fid: fid
          ? Number(fid)
          : undefined,
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商列表
      packageOrderList: [], //套餐订单列表
      userType: localStorage.getItem("type"),
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "站点",
        dataIndex: "",
        render: (text) =>
          this.state.userType === "0"
            ? text.fname + " — " + text.sname
            : text.sname,
      },
      {
        title: "车辆信息",
        dataIndex: "",
        width: 240,
        render: (text) => (
          <span>
            {text.bikeId && "车架号:" + text.bikeId}
            {text.bikeId && <br />}
            {text.ccodeId && "GPS号:" + text.ccodeId}
            {text.ccodeId && <br />}
            {text.qcodeId && "二维码:" + text.qcodeId}
          </span>
        ),
      },
      {
        title: "订单号",
        dataIndex: "orderSn",
      },
      {
        title: "骑手信息",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.userName}
            <br />
            {text.mobile}
          </span>
        ),
      },
      {
        title: "订单状态",
        dataIndex: "orderStatus",
      },
      {
        title: "状态变更时间",
        dataIndex: "orderStatusTime",
      },
      {
        title: "支付类型",
        dataIndex: "payType",
      },
      {
        title: "支付金额",
        dataIndex: "payMoney",
      },
      {
        title: "支付时间",
        dataIndex: "payTime",
      },
      {
        title: "缴费次数",
        dataIndex: "payNumber",
      },
      {
        title: `${this.state.userType === "0" ? "违约金/逾期租金违约金" : "违约金"}`,
        dataIndex: "",
        render: (text) =>
          this.state.userType === "0"
            ? text.penaltyMoney
              ? "违约金：" + text.penaltyMoney
              : null + text.diffMoney
              ? " 逾期租金违约金： " + text.diffMoney
              : null
            : text.penaltyMoney,
      },
      {
        title: `${
          this.state.userType === "0"
            ? "违约金/逾期租金违约金支付时间"
            : "违约金支付时间"
        }`,
        dataIndex: "",
        render: (text) =>
          this.state.userType === "0"
            ? text.penaltyTime
              ? "违约金：" + text.penaltyTime
              : null + text.diffTime
              ? " 逾期租金违约金： " + text.diffTime
              : null
            : text.penaltyTime,
      },

      {
        title: "新增/续租",
        dataIndex: "renewalOrNew",
      },
    ];
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {this.state.userType === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid", {
                  initialValue: this.state.queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆信息">
              {getFieldDecorator("vehicleInfo")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="骑手信息">
              {getFieldDecorator("userInfo")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单节点">
              {getFieldDecorator("orderStatus", {
                initialValue: this.state.queryFormValues.orderStatus,
              })(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="01">下单</Option>
                  <Option value="12">用户支付</Option>
                  <Option value="33">用户续租</Option>
                  <Option value="85">后台更新线下处理状态</Option>
                  <Option value="23">发车</Option>
                  <Option value="36">申请退租</Option>
                  <Option value="65">确认退租成功</Option>
                  {this.state.userType === "0" && (
                    <Option value="43">支付逾期租金违约金</Option>
                  )}
                  <Option value="19">用户支付连续租赁套餐</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单状态">
              {getFieldDecorator("newOrderStatus", {
                initialValue: this.state.queryFormValues.newOrderStatus,
              })(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">已下单</Option>
                  <Option value="2">待提车</Option>
                  <Option value="3">租车中</Option>
                  <Option value="4">已超时</Option>
                  <Option value="5">已完成</Option>
                  <Option value="6">申请退租</Option>
                  <Option value="7">待生效</Option>
                  <Option value="8">已关闭</Option>
                  <Option value="9">待签约</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("userId") === "1288" ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="是否挂起">
                {getFieldDecorator("hangUp", {
                  initialValue: this.state.queryFormValues.hangUp,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">未挂起</Option>
                    <Option value="1">已挂起</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="新增/续租">
              {getFieldDecorator("isRenewal", {
                initialValue: this.state.queryFormValues.isRenewal,
              })(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">新增</Option>
                  <Option value="2">续租</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单时间">
              {getFieldDecorator("startTime")(<DatePicker />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(820)}
                title={getJurisdiction(820) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, packageOrderList } = this.state;
    return (
      <div>
        {!!getJurisdiction(819) ? (
          <div className="TodayOrder">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="statusLogId"
                bordered
                columns={this.columns}
                dataSource={packageOrderList}
                scroll={{ x: 1800, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getPackageOrderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取订单报表
  getPackageOrderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _todayBillList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            packageOrderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageOrderList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.startTime) {
        values.startTime = moment(values.startTime).format("YYYY-MM-DD");
      }
      this.setState({
        queryFormValues: values,
      });
      this.getPackageOrderList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.startTime) {
        values.startTime = moment(values.startTime).format("YYYY-MM-DD");
      }
      let params = {
        ...values,
      };
      _exportTodayBillList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

TodayOrder = Form.create({})(TodayOrder);
export { TodayOrder };
