const menus = [
  {
    key: 1048,
    path: "/jzBatteryMgt",
    icon: "setting",
    title: "资产管理",
  },
  {
    key: 1,
    icon: "setting",
    title: "系统管理",
    children: [
      {
        key: 4,
        path: "/systemManagement",
        title: "系统菜单",
      },
      {
        key: 7,
        path: "/roleManagement",
        title: "角色管理",
      },
    ],
  },
  {
    key: 608,
    icon: "shop",
    title: "商家管理",
    children: [
      {
        key: 609,
        path: "/agentManagement",
        title: "代理商管理",
      },
      {
        key: 610,
        path: "/siteManagement",
        title: "站点管理",
      },
      {
        key: 629,
        path: "/siteAdministrator",
        title: "站点管理员",
      },
      {
        key: 968,
        path: "/kaManagement",
        title: "KA代理商管理",
      },
      {
        key: 973,
        path: "/kaSiteManagement",
        title: "KA站点管理",
      },
      {
        key: 1080,
        path: "/task",
        title: "超时工单",
      },
    ],
  },
  {
    key: 625,
    icon: "user",
    title: "用户管理",
    badgeKey: "complainMsgSize",
    children: [
      {
        key: 626,
        path: "/memberManagement",
        title: "用户管理",
      },
      {
        key: 979,
        path: "/kaMemberManagement",
        title: "KA用户管理",
      },
      {
        key: 1013,
        path: "/userFeedbackList",
        title: "用户反馈",
        badgeKey: "complainMsgSize",
      },
    ],
  },
  {
    key: 639,
    icon: "appstore",
    title: "设备管理",
    children: [
      {
        key: 803,
        path: "/chargeBattery",
        title: "电柜管理",
      },
      {
        key: 642,
        path: "/batteryManagement",
        title: "电池管理",
      },
      {
        key: 647,
        path: "/batteryModel",
        title: "电池型号",
      },
      {
        key: 732,
        path: "/bikeManagement",
        title: "车辆管理",
      },
      {
        key: 733,
        path: "/bikeModel",
        title: "车辆型号",
      },
      {
        key: 1051,
        path: "/gpsManagement",
        title: "GPS白名单管理",
      },
      {
        key: 916,
        path: "/evChargerModel",
        title: "充电桩型号",
      },
      {
        key: 1032,
        path: "/modelPrice",
        title: "型号价格",
      },
      {
        key: 1030,
        path: "/batteryAlarmList",
        title: "设备异常",
      },
      {
        key: 1119,
        path: "/exception",
        title: "设备异常查询",
      },
    ],
  },
  {
    key: 773,
    icon: "rocket",
    title: "物流管理",
    children: [
      {
        key: 774,
        path: "/logisticsSingle",
        title: "物流单",
      },
      {
        key: 945,
        path: "/transferringOrder",
        title: "调拨单",
      },
    ],
  },
  {
    key: 929,
    icon: "notification",
    title: "活动管理",
    children: [
      {
        key: 930,
        path: "/favourableActivity",
        title: "活动列表",
      },
      {
        key: 931,
        path: "/couponsList",
        title: "优惠券列表",
      },
      {
        key: 932,
        path: "/releaserecord",
        title: "发放记录",
      },
      {
        key: 933,
        path: "/cashApplyList",
        title: "提现申请",
      },
    ],
  },
  {
    key: 643,
    icon: "audit",
    title: "套餐管理",
    children: [
      {
        key: 646,
        path: "/carRentalPackages",
        title: "车辆套餐",
      },
      {
        key: 1028,
        path: "/packageApply",
        title: "套餐申请",
      },
    ],
  },
  {
    key: 704,
    icon: "file-search",
    title: "订单管理",
    badgeKey: "complainSize",
    children: [
      {
        key: 731,
        path: "/packageOrderManagement",
        title: "套餐订单管理",
      },
      {
        key: 1014,
        path: "/transferOrder",
        title: "换车订单",
      },
      {
        key: 903,
        path: "/exceptionOrderList",
        title: "线下处理订单",
      },
      {
        key: 830,
        path: "/orderApplyManagement",
        title: "订单代付管理",
      },
      // {
      //     key: 799,
      //     path: '/todayOrder',
      //     title: '今日订单',
      // },
      {
        key: 1094,
        path: "/withholdComplain",
        title: "用户代扣申诉",
        badgeKey: "complainSize",
      },
    ],
  },
  {
    key: 786,
    icon: "import",
    title: "退车管理",
    children: [
      {
        key: 787,
        path: "/siteRepairManagement",
        title: "退车点管理",
      },
      {
        key: 788,
        path: "/bikeRepairManagement",
        title: "维修项管理",
      },
      {
        key: 800,
        path: "/bikeRepairDetailList",
        title: "车辆维修项详情",
      },
      {
        key: 789,
        path: "/siteRepairAdministrator",
        title: "退车点管理员",
      },
    ],
  },
  {
    key: 988,
    icon: "area-chart",
    title: "财务管理",
    children: [
      {
        key: 989,
        path: "/balance",
        title: "账户充值",
      },
      {
        key: 991,
        path: "/serialReport",
        title: "流水号报表",
      },
      {
        key: 990,
        path: "/bill",
        title: "财务账单",
      },
    ],
  },
  {
    key: 766,
    icon: "pie-chart",
    title: "报表管理",
    badgeKey: "overdueCountSize",
    children: [
      {
        key: 1107,
        title: "业务报表",
        children: [
          {
            key: 967,
            path: "/operation",
            title: "运营报表",
          },
          {
            key: 1108,
            path: "/overdue",
            title: "逾期报表",
          },
          {
            key: 805,
            path: "/performanceRecordList",
            title: "履约记录报表",
          },
          {
            key: 916,
            path: "/fastChargeLogList",
            title: "充电桩报表",
          },
        ],
      },
      {
        title: "财务报表",
        key: 1103,
        badgeKey: "overdueCountSize",
        children: [
          {
            key: 778,
            path: "/RealTimeDistributionBattery",
            title: "供应商电池分润",
          },
          {
            key: 776,
            path: "/feeSplitting",
            title: "代理商套餐分润",
          },
          {
            key: 804,
            path: "/packagesOrderLogList",
            title: "流水号报表",
          },
          {
            key: 1104,
            path: "/repaymentOrder",
            title: "分期付订单",
            badgeKey: "overdueCountSize",
          },
          {
            key: 1116,
            path: "/electricCollectionReport",
            title: "电催报表",
          },
        ],
      },
      //   {
      //     key: 777,
      //     path: "/RealTimeDistributionBike",
      //     title: "供应商车辆即时分润",
      //   },
      //   {
      //     key: 767,
      //     path: "/reportManagement",
      //     title: "订单报表管理",
      //   },
      //   {
      //     key: 770,
      //     path: "/leaseDistributionalReport",
      //     title: "租赁分润报表",
      //   },
      //
    ],
  },
  {
    key: 1019,
    icon: "transaction",
    title: "充值管理",
    children: [
      {
        key: 1022,
        path: "/memberBalance",
        title: "会员余额",
      },
      {
        key: 1024,
        path: "/withdrawalApplication",
        title: "会员提现申请",
      },
      {
        key: 1020,
        path: "/rechargeStatement",
        title: "会员充值报表",
      },
    ],
  },
  {
    key: 782,
    icon: "snippets",
    title: "配置管理",
    children: [
      {
        key: 783,
        path: "/packageTranferRule",
        title: "套餐分润",
      },
      {
        key: 784,
        path: "/batteryTranferRule",
        title: "电池分润",
      },
      {
        key: 954,
        path: "/repairTransferRule",
        title: "维修分润",
      },
      {
        key: 785,
        path: "/settlementRule",
        title: "分润规则",
      },
      {
        key: 1087,
        path: "/referrerRule",
        title: "推荐人分润规则",
      },
      {
        key: 1098,
        path: "/shuntRule",
        title: "支付分流配置",
      },
    ],
  },
  // {
  //     key: 791,
  //     icon: 'tool',
  //     title: '维修管理',
  //     children: [
  //         {
  //             key: 792,
  //             path: '/repAgentList',
  //             title: '维修代理商管理',
  //         },
  //         {
  //             key: 793,
  //             path: '/repManList',
  //             title: '维修师管理',
  //         },
  //         {
  //             key: 795,
  //             path: '/kaRepCustomerList',
  //             title: 'KA用户管理',
  //         },
  //         {
  //             key: 796,
  //             path: '/kaBikeModelRepair',
  //             title: '车辆型号',
  //         },
  //         {
  //             key: 797,
  //             path: '/kaBikeRepairList',
  //             title: '车辆管理',
  //         },
  //         {
  //             key: 798,
  //             path: '/kaRepRiderList',
  //             title: '骑手管理',
  //         },
  //     ]
  // },
];

export default menus;
