import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Row,
  Select,
  Col,
  DatePicker,
  message,
} from "antd";
import useList from "@/hooks/list";
import {
  _exportBatteryAlarmList,
  _getBatteryAlarmType,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
const { Option } = Select;
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const {
    franchiseeList,
    getFranchiseeList,
    siteList,
    getSiteList,
    setSiteList,
    bikeModelList,
    getBikeModelList,
  } = useList();
  useEffect(() => {
    getFranchiseeList();
    getBikeModelList();
    getBatteryAlarmType();
  }, []);
  const [exportload, setExportload] = useState(false);
  const [batteryAlarmType, setBatteryAlarmType] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      search(values);
    });
  };

  const handleExportChange = async () => {
    setExportload(true);
    let params = form.getFieldsValue();
    const res = await _exportBatteryAlarmList(params);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
  };

  const getBatteryAlarmType = async () => {
    const res = await _getBatteryAlarmType();
    if (res.data.code === 200) {
      setBatteryAlarmType(res.data.data);
    }
  };

  const handleFranchiseeChange = async (value) => {
    setSiteList([]);
    form.setFieldsValue({ sid: "" });
    if (value) {
      getSiteList(value);
    }
  };
  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    onChange={handleFranchiseeChange}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}

          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {siteList.length &&
                    siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="编号">
              {getFieldDecorator("imei")(<Input placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="异常原因">
              {getFieldDecorator("alarm")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {batteryAlarmType.length &&
                    batteryAlarmType.map((item, index) => {
                      return (
                        <Option key={index} value={item.alarm}>
                          {item.alarm}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="异常时间">
              {getFieldDecorator("addTime")(
                <DatePicker.RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池型号">
              {getFieldDecorator("modelId")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {bikeModelList.length &&
                    bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1031)}
                title={getJurisdiction(1031) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
