import { 
  _imageUpload,
} from "./api.js";
import axios from "./request";

export const zipImg = ({ file, device = 6, size = 500 }) => {
  if (file[0]) {
    return Promise.all(Array.from(file).map((e) => zipImg(e, size))); // 如果是 file 数组返回 Promise 数组
  } else {
    return new Promise((resolve) => {
      const reader = new FileReader(); // 创建 FileReader
      reader.onload = ({ target: { result: src } }) => {
        const fileSize = Number((file.size / 1024).toFixed(2));
        if (fileSize <= size) {
          resolve(file);
        } else {
          const image = new Image(); // 创建 img 元素
          image.onload = async () => {
            const canvas = document.createElement("canvas"); // 创建 canvas 元素
            canvas.width = image.width;
            canvas.height = image.height;
            canvas
              .getContext("2d")
              .drawImage(image, 0, 0, image.width, image.height); // 绘制 canvas
            let canvasURL, miniFile;
            let L = true;
            let quality = 0;
            for (let i = 1; i <= device; i++) {
              canvasURL = canvas.toDataURL(
                "image/jpeg",
                L ? (quality += 1 / 2 ** i) : (quality -= 1 / 2 ** i)
              );
              const buffer = window.atob(canvasURL.split(",")[1]);
              let length = buffer.length;
              const bufferArray = new Uint8Array(new ArrayBuffer(length));
              while (length--) {
                bufferArray[length] = buffer.charCodeAt(length);
              }
              miniFile = new File([bufferArray], file.name, {
                type: "image/jpeg",
              });
              miniFile.size / 1024 > size ? (L = false) : (L = true);
            }
            miniFile.uid = file.uid;
            resolve(miniFile);
          };
          image.src = src;
        }
      };
      reader.readAsDataURL(file);
    });
  }
};

// 单图片上传
export const uploadImg = async ({file}) => {
  const formData = new FormData()
  formData.append('uploadFile',file)
  formData.append('scene','active')
  const res = await axios.request({
    url: _imageUpload(),
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  })
  const {data={}} = res.data || {}
  const {url=''} = data || {}
  return url || ''
}

// 多图片上传
export const uploadImgs = async ({files=[]}) => {
  let actions = []
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    actions.push(uploadImg({file}))
  }
  return Promise.all(actions)
}