import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import useList from "@/hooks/list";
import { _kaBikeTransfer, _insertTransfer, _batteryInsertTransfer } from "@/statics/js/api.js";
import { Form, Modal, Select, Row, Col, message, Table } from "antd";
const { Option } = Select;

var TransferModal = forwardRef((props, ref) => {
  const columns = [
    {
      title: "设备二维码",
      dataIndex: "imei",
    },
    {
      title: "设备型号",
      dataIndex: "modelName",
    },
    {
      title: "所属站点",
      dataIndex: "sname",
    },
  ];
  const { form, done, type } = props;
  const { getFieldDecorator,getFieldValue } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [ftype, setFtype] = useState("");
  const [query, setQuery] = useState({
    fid: "",
    transferOutSite: "",
    defaultType: "",
  });
  const fid = localStorage.getItem("fid");
  const userType = localStorage.getItem("type");
  const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
  const {
    franchiseeList,
    getFranchiseeList,
    getSiteList,
    siteList,
    setSiteList,
  } = useList();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const handleFranchiseeChange = async (value) => {
    let { ftype } = franchiseeList.find((item) => item.fid == value) || {};
    setFtype(ftype);
    setSiteList([]);
    form.setFieldsValue({ transferOutSite: "" });
    if (value) {
      getSiteList(value);
    }
  };

  const open = async ({ selectedData }) => {
    setVisible(true);
    setList([...selectedData]);
    form.resetFields();
    setQuery({
      fid: "",
      transferOutSite: "",
      defaultType: "",
    });
    setFtype("");
    if (userType === "0") {
      getFranchiseeList();
    } else {
      getSiteList(fid);
    }
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    form.validateFields((err, values) => {
      if (err) return;
      const params = { ...values };
      if(!params.defaultType){
        params.defaultInType = 0
      }
      params.defaultInType = params.defaultInType || 0
      if (ftype == 1 || userType == 9 || userType == 10) {
        params.type = type;
        if (userType == 9 || userType == 10) {
          params.fid = fid;
        }
        params.paramsList = list.map((item) => ({
          id: item.id,
          imei: item.imei,
          modelId: item.modelId,
          transferInFrom: item.sid,
        }));
        kaTransfer(params);
      } else {
        params.paramsList = list.map((item) => ({
          qrcode: item.imei,
          modelId: item.modelId,
          sid: item.sid,
          transferInFrom: item.sname,
        }));
        normalTransfer(params);
      }
    });
  };
  // ka调拨车辆或电池
  const kaTransfer = async (params) => {
    setConfirmLoading(true);
    const res = await _kaBikeTransfer(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };
  // 普通代理商车辆或电池调拨
  const normalTransfer = async (params) => {
    setConfirmLoading(true);
    const res = await (type == 1 ? _insertTransfer(params) : _batteryInsertTransfer(params));
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };
  return (
    <div>
      <Modal
        title="调拨"
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Row>
            {userType == "0" && (
              <Col span={24}>
                <Form.Item label="代理商">
                  {getFieldDecorator("fid", {
                    initialValue: query.fid,
                    rules: [{ required: true, message: "请选择代理商！" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      showSearch
                      onChange={handleFranchiseeChange}
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="站点">
                {getFieldDecorator("transferOutSite", {
                  initialValue: query.transferOutSite,
                  rules: [{ required: true, message: "请选择站点！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {ftype != 1 && userType != 9 && userType != 10 && (
              <>
              <Col span={24}>
                <Form.Item label="是否默认出库">
                  {getFieldDecorator("defaultType", {
                    rules: [{ required: true, message: "请选择" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={0}>否</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {
                (getFieldValue('defaultType') && (userType == 0 || fid == 104 || fid == 173 || userInfo.authGroupId == 33 || userInfo.authGroupId == 43)) ? 
                <Col span={24}>
                  <Form.Item label="是否默认入库">
                    {getFieldDecorator("defaultInType")(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={1}>是</Option>
                        <Option value={0}>否</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col> : null
              }
              </>
            )}
          </Row>
        </Form>
        <h3>调拨数量 共{list.length}</h3>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          scroll={{ x: 1000, y: 500 }}
          dataSource={list}
        />
      </Modal>
    </div>
  );
});

TransferModal = Form.create({})(TransferModal);
export default TransferModal;
