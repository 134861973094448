import React,{
    useState,
    useEffect,
} from 'react';
import './index.less'
import ScrollCard from '../scrollCard'
const columnsBase = [
    {
        dataIndex: 'index',
        width: '50px',
        align: 'center',
        render: (_,index)=><div style={{color: '#4992ff'}}>{index+1}</div>,
    },
    {
        dataIndex: 'name',
    },
    {
        dataIndex: 'value',
        width: '100px',
    }
]

const Index = (props,ref) => {
    const {data=[],columns=columnsBase} = props
    useEffect(()=>{
        
    },[])
    const renderRow = (row,index)=>{
        return (
            <div className="table_row" key={index}>
                {
                    columns.map(({dataIndex,render,width,align})=>{
                        return (
                            <div className="table_cell" key={dataIndex} style={{width,'justifyContent': align}}>
                                {
                                    render ? render(row,index) : row[dataIndex]
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return (
        <ScrollCard data={data} render={renderRow} stripe />
    )
}

export default Index
