import React from 'react'
import { Form, Row, Col, Input, Select, DatePicker, Button } from 'antd'
import moment from 'moment'
import FranchiseeSelect from '@/components/franchiseeSelect'
import SiteSelect from '@/components/siteSelect'
import useHandle from '@/hooks/handle'
import { _exportUrgentDetail } from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import queryString from 'query-string'

const { Option } = Select
const { RangePicker } = DatePicker
let FormSearch = (props) => {
  const userType = localStorage.getItem('type')
  const { form, search, formatDate, location } = props
  const { orderSn } = queryString.parse(location.search)
  const { getFieldDecorator, setFieldsValue, getFieldValue, getFieldsValue } = form
  const { loading: exportLoading, handleDownload } = useHandle()

  const handleExport = async () => {
    const values = getFieldsValue()
    const [timeStart, timeEnd] = values.date || []
    delete values.date
    const { fid, sid } = values
    handleDownload({
      action: _exportUrgentDetail,
      query: {
        ...values,
        timeStart: formatDate(timeStart),
        timeEnd: formatDate(timeEnd),
        fid: fid ? fid.key : undefined,
        sid: sid ? sid.key : undefined,
      },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let values = form.getFieldsValue()
    const params = getParams(values)
    search(params)
  }

  const getParams = (values) => {
    const { date = [] } = values
    const [timeStart, timeEnd] = date
    delete values.date
    return {
      ...values,
      timeStart: formatDate(timeStart),
      timeEnd: formatDate(timeEnd),
    }
  }

  return (
    <Form layout='inline' onSubmit={handleSubmit}>
      <Row gutter={16}>
        {userType === '0' && (
          <Col lg={6} md={12} sm={24}>
            <Form.Item label='代理商'>
              {getFieldDecorator('fid')(
                <FranchiseeSelect
                  labelInValue
                  onChange={() => {
                    setFieldsValue({ sid: '' })
                  }}
                />
              )}
            </Form.Item>
          </Col>
        )}
        <Col lg={6} md={12} sm={24}>
          <Form.Item label='站点'>
            {getFieldDecorator('sid')(<SiteSelect labelInValue fid={getFieldValue('fid')?.key} />)}
          </Form.Item>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <Form.Item label='订单号'>
            {getFieldDecorator('orderSn', {
              initialValue: orderSn ? orderSn : undefined,
            })(<Input allowClear placeholder='请输入' />)}
          </Form.Item>
        </Col>
        <Col lg={6} md={12} sm={24} hidden={userType !== '0'}>
          <Form.Item label='是否接通'>
            {getFieldDecorator('callResult', {
              initialValue: userType === '0' ? undefined : 1,
            })(
              <Select placeholder='请选择' allowClear style={{ width: '100%' }}>
                <Option value={1}>已接通</Option>
                <Option value={0}>未接通</Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <Form.Item label='用户名/手机号'>
            {getFieldDecorator('contactStr')(<Input allowClear placeholder='请输入' />)}
          </Form.Item>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <Form.Item label='日期'>
            {getFieldDecorator('date', {
              initialValue: orderSn ? [] : [moment().startOf('month'), moment()],
            })(<RangePicker format='YYYY-MM-DD' />)}
          </Form.Item>
        </Col>
        {userType === '0' && (
          <Col lg={6} md={12} sm={24}>
            <Form.Item label='是否结算'>
              {getFieldDecorator('settle')(
                <Select placeholder='请选择' allowClear style={{ width: '100%' }}>
                  <Option value={1}>已结算</Option>
                  <Option value={0}>未结算</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        )}
        <Col lg={6} md={12} sm={24}>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              搜索
            </Button>
            <Button
              onClick={handleExport}
              disabled={!getJurisdiction(1118)}
              title={getJurisdiction(1118) ? '' : '暂无操作权限！'}
              style={{ marginLeft: 10 }}
              loading={exportLoading}
            >
              导出
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

FormSearch = Form.create({})(FormSearch)
export default FormSearch
