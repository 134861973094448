import React,{
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import "./index.less"
let percentage = 0
let timer = null
const Index = forwardRef((props,ref) => {
    const [remain,setRemain] = useState(0)
    const [percent,setPercent] = useState(0)
    useEffect(()=>{
      
    },[])

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);

    var init = ({TimeXScale,data,current})=>{
        let num = data[data.length - 1]
        setRemain(num)
        setPercent(num)
        if(num >= 100){
            clearInterval(timer)
            timer = null
            return
        }
        if(current[current.length - 1] <= 0){
            clearInterval(timer)
            timer = null
            return
        }
        if(timer){
            return
        }
        timer = setInterval(()=>{
            if(percentage >= 100){
                percentage = num
            }else{
                if((100 - percentage) < 10){
                    percentage += (100 - percentage)
                }else{
                    percentage += 10
                }
            }
            setPercent(percentage)
        },150)
    }

    return (
        <div className="b_wrap">
            <div className="b_title">{remain} %</div>
            <div className="b_box">
                <div className="b_content">
                    <div className="b_percent" style={{height: percent + '%'}}></div>
                </div>
            </div>
        </div>
    )
})

export default Index