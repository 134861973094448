import React, { 
    useEffect,
    useRef,
    useState,
} from 'react';
import { Link } from "react-router-dom";
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import FranchiseeSelect from '@/components/franchiseeSelect'
import SiteSelect from '@/components/siteSelect'
import PackageSelect from '@/components/packageSelect'
import {
    _outTimePayList,
    _outTimePayListExport,
} from "@/statics/js/api"
import moment from "moment";
import {
    Form,
    Button,
    Select,
    Input,
    Row,
    Col,
    DatePicker,
    message,
} from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;

const statusList = [
    {value: 1,label: '已下单'},
    {value: 2,label: '待提车'},
    {value: 3,label: '租车中'},
    {value: 4,label: '已超时'},
    {value: 5,label: '已完成'},
    {value: 6,label: '申请退租'},
    {value: 7,label: '待生效'},
    {value: 8,label: '已关闭'},
    {value: 9,label: '待签约'},
]

const columns = [
    {
        title: "订单编号",
        dataIndex: "orderSn",
        width: 180,
        render: (text)=>(
            <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
                {text}
            </Link>
        )
    },
    {
        title: "代理商",
        dataIndex: "fname",
        width: 200,
    },
    {
        title: "站点",
        dataIndex: "sname",
        width: 200,
    },
    {
        title: "套餐名",
        dataIndex: "pname",
        width: 200,
    },
    {
        title: "套餐类型",
        dataIndex: "ptypeName",
        width: 100,
    },
    {
        title: "车辆信息",
        dataIndex: "",
        width: 230,
        render: (text)=>(
            <div>
                <div>车架号：{text.bikeNumber}</div>
                <div>GPS号：{text.bikeGpsNumber}</div>
                <div>二维码号：{text.imei}</div>
                <div>车牌号：{text.vin}</div>
            </div>
        )
    },
    {
        title: "电池信息",
        dataIndex: "",
        width: 230,
        render: (text)=>(
            <div>
                <div>电池编号：{text.batteryNumber}</div>
                <div>二维码号：{text.batteryImei}</div>
                <div>GPS号：{text.batteryGpsNumber}</div>
            </div>
        )
    },
    {
        title: "用户姓名/手机号",
        dataIndex: "",
        width: 200,
        render: (text)=><span>{text.username}/{text.phone}</span>
    },
    {
        title: "代扣金额",
        dataIndex: "payMoney",
        width: 100,
    },
    {
        title: "订单状态",
        dataIndex: "orderStatus",
        width: 100,
        render: (text)=><span>{statusList.find(item=>item.value==text)['label']}</span>
    },
    {
        title: "订单时间",
        dataIndex: "orderTime",
        width: 160,
    },
    {
        title: "租赁时间",
        dataIndex: "leaseTime",
        width: 160,
    },
    {
        title: "代扣时间",
        dataIndex: "withholdTime",
        width: 160,
    },
    {
        title: "流水号",
        dataIndex: "tradeNo",
        width: 260,
    },
]

const Index = Form.create({})((props)=> {
    let {form,defaultfid='',defaultdate=''} = props
    const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue,setFieldsValue} = form
    const tableRef = useRef();
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    let defaultStartDate = ''
    let defaultEndDate = ''
    if(defaultdate == 'yesterday'){
        defaultStartDate = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' 00:00:00'
        defaultEndDate = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' 23:59:59'
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        if(defaultfid){
            setFieldsValue({ fid: defaultfid })
        }
        if(defaultdate == 'yesterday'){
            let start = moment(moment().subtract(1, 'days').format("YYYY-MM-DD") + ' 00:00:00')
            let end = moment(moment().subtract(1, 'days').format("YYYY-MM-DD") + ' 23:59:59')
            setFieldsValue({
                time: [start,end]
            })
        }
    },[])
    const getDate = () => {
        const values = getFieldsValue()
        let [addTimeStart,addTimeEnd] = values.time || []
        addTimeStart = addTimeStart ? moment(addTimeStart).format("YYYY-MM-DD HH:mm:ss") : undefined
        addTimeEnd = addTimeEnd ? moment(addTimeEnd).format("YYYY-MM-DD HH:mm:ss") : undefined
        return [
            addTimeStart,
            addTimeEnd
        ]
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        validateFields((err, values) => {
            const [addTimeStart,addTimeEnd] = getDate()
            const params = {
                ...values,
                addTimeStart,
                addTimeEnd,
                time: undefined
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const [addTimeStart,addTimeEnd] = getDate()
        const params = {
            ...values,
            addTimeStart,
            addTimeEnd,
            time: undefined
        }
        handleDownload({
            action: _outTimePayListExport,
            query: params
        })
    }
    return (<>
        <Form onSubmit={handleSubmit} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect onChange={()=>{setFieldsValue({ sid: "" })}} />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="站点">
                        {getFieldDecorator("sid")(
                            <SiteSelect fid={getFieldValue('fid')} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="套餐名称">
                        {getFieldDecorator("pid")(
                            <PackageSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="用户姓名/手机号">
                        {getFieldDecorator("namePhone")(
                            <Input placeholder="请输入" />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="订单状态">
                        {getFieldDecorator("orderStatus")(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                {
                                    statusList.map(item=>(
                                        <Option value={item.value} key={item.value}>{item.label}</Option>
                                    ))
                                }
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="支付时间">
                        {getFieldDecorator("time")(
                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width: '100%'}} />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="订单编号">
                        {getFieldDecorator("orderSn")(
                            <Input placeholder="请输入" />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            action={_outTimePayList}
            options={{
                columns: columns,
                scroll: { x: 1800 },
            }}
            params={{
                fid: defaultfid || undefined,
                addTimeStart: defaultStartDate,
                addTimeEnd: defaultEndDate,
            }}
        />
    </>)
})

export default Index