import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  Switch,
  Divider,
  DatePicker,
  Select,
  Input,
  Form,
  Row,
  Col,
  message,
} from "antd";
import {
  _siteBoxList,
  _franchiseeList,
  _couponDetail,
  _couponList,
  _activityList,
  _saveCouponInfo,
  _sendCoupon,
  _updateCouponStatus,
  _packageBoxList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const modalTitle = ["添加优惠券", "编辑优惠券", "优惠券详情"];
class CouponsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      couponList: [], //优惠券列表
      packageList: [], //套餐订单列表
      id: "", //优惠券id
      initialValues: {}, //优惠券详情
      visible: false, //Modal是否打开
      modalType: "", //1-添加，2-编辑，3-查看
      queryFormValues: {
        status: 1,
      }, //筛选项
    };
    this.columns = [
      {
        title: "ID",
        width: 80,
        dataIndex: "id",
      },
      {
        title: "优惠券名称",
        dataIndex: "name",
      },
      {
        title: "关联活动",
        dataIndex: "activityName",
      },
      {
        title: "使用类型",
        width: 140,
        dataIndex: "",
        render: (text) =>
          text.type === 1 ? (
            <span>
              加时优惠券
              <br />
              天数：{text.days} 天
            </span>
          ) : text.type === 2 ? (
            <span>
              现金抵扣优惠券
              <br />
              金额： {text.amount} 元
            </span>
          ) : (
            <span>
              提现优惠券
              <br />
              金额： {text.amount} 元
            </span>
          ),
      },
      {
        title: "优惠券类型",
        width: 140,
        dataIndex: "belongType",
        render: (text) =>
          text === 1
            ? "套餐优惠券"
            : text === 2
            ? "新用户优惠券"
            : text === 3
            ? "拉新奖励券"
            : "",
      },
      {
        title: "使用场景",
        width: 100,
        dataIndex: "scene",
        render: (text) =>
          text === 0 ? "全场景" : text === 1 ? "新增" : "续租",
      },
      {
        title: "适用套餐",
        width: 150,
        dataIndex: "packages",
        render: (text) =>
          text &&
          text.map((item, index) => {
            if (index < 2) {
              return <span key={index}>{item.pname}/</span>;
            } else if (index === 2) {
              return <span key={index}>{item.pname}...</span>;
            } else {
              return null;
            }
          }),
      },
      {
        title: "同时领取新用户优惠券",
        dataIndex: "newUserCoupon",
        width: 120,
        render: (text) => (text === 0 ? "是" : "否"),
      },
      {
        title: "限套餐新用户使用",
        dataIndex: "userLimit",
        width: 120,
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "叠加使用",
        dataIndex: "repeatUse",
        width: 100,
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "是否可见",
        width: 90,
        dataIndex: "userVisible",
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "有效期",
        width: 180,
        dataIndex: "",
        render: (text) => (
          <span>
            {text.startTime
              ? moment(text.startTime).format("YYYY-MM-DD HH:mm:ss")
              : ""}
            {text.startTime && text.endTime ? (
              <span>
                <br />
                ~
                <br />
              </span>
            ) : null}
            {text.endTime
              ? moment(text.endTime).format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) =>
          text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(942)}
            title={getJurisdiction(942) ? "" : "暂无操作权限！"}
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 140,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(939)}
              title={getJurisdiction(939) ? "" : "暂无操作权限！"}
              onClick={this.getCouponDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(940)}
              title={getJurisdiction(940) ? "" : "暂无操作权限！"}
              onClick={this.getCouponDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(941)}
              title={getJurisdiction(941) ? "" : "暂无操作权限！"}
              onClick={this.modalViewhandle.bind(this, text.id, text.name)}
            >
              发放
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="优惠券名称">
              {getFieldDecorator("nameSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="使用类型">
              {getFieldDecorator("typeSearch")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={0}>全部</Option>
                  <Option value={1}>加时优惠券</Option>
                  <Option value={2}>现金抵扣优惠券</Option>
                  <Option value={3}>提现优惠券</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="创建时间">
              {getFieldDecorator("timeSearch")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否可见">
              {getFieldDecorator("userVisible")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { initialValues, couponList, totalCount, curPage, pageSize } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(931) ? (
          <div className="CouponsList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={this.showModal.bind(this, 1)}
                disabled={!getJurisdiction(938)}
                title={getJurisdiction(938) ? "" : "暂无操作权限！"}
              >
                {modalTitle[0]}
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={couponList}
                scroll={{ x: 2000, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="优惠券名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.name}</span>
                    ) : (
                      getFieldDecorator("name", {
                        initialValue: initialValues.name,
                        rules: [
                          { required: true, message: "请输入优惠券名称！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [
                          { required: true, message: "请选择适用代理商" },
                        ],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          onChange={this.getSiteList.bind(this)}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用站点">
                    {this.state.modalType === 3
                      ? initialValues.sites &&
                        initialValues.sites.map((item, index) => {
                          return <span key={index}>{item.sname}/</span>;
                        })
                      : getFieldDecorator("sids", {
                          initialValue: initialValues.sids,
                        })(
                          <Select
                            allowClear
                            mode="multiple"
                            placeholder="请选择"
                            style={{ width: "100%" }}
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.state.siteList &&
                              this.state.siteList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.sid}>
                                    {item.sname}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="适用套餐">
                    {this.state.modalType === 3
                      ? initialValues.packages &&
                        initialValues.packages.map((item, index) => {
                          return <span key={index}>{item.pname}/</span>;
                        })
                      : getFieldDecorator("pids", {
                          initialValue: initialValues.pids,
                          rules: [
                            { required: true, message: "请选择适用套餐" },
                          ],
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                            mode="multiple"
                            filterOption={(input, option) => {
                              return (
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {this.state.packageList &&
                              this.state.packageList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.pname}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="关联活动">
                    {this.state.modalType === 3
                      ? initialValues.activityName
                      : getFieldDecorator("activityId", {
                          initialValue: initialValues.activityId,
                          rules: [
                            { required: true, message: "请选择关联活动" },
                          ],
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                          >
                            {this.state.activityList &&
                              this.state.activityList.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="使用场景">
                    {this.state.modalType === 3
                      ? initialValues.scene === 0
                        ? "全场景"
                        : initialValues.scene === 1
                        ? "新增"
                        : "续租"
                      : getFieldDecorator("scene", {
                          initialValue: initialValues.scene,
                          rules: [
                            { required: true, message: "请选择使用场景" },
                          ],
                        })(
                            this.state.modalType === 2 ? 
                            <Select
                              placeholder="请选择"
                              allowClear
                              disabled
                              style={{ width: "100%" }}
                            >
                              <Option value={0}>全场景</Option>
                              <Option value={1}>新增</Option>
                              <Option value={2}>续租</Option>
                            </Select>
                            :
                            <Select
                              placeholder="请选择"
                              allowClear
                              style={{ width: "100%" }}
                            >
                              <Option value={1}>新增</Option>
                            </Select>
                        )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="使用类型">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.type === 1
                          ? "加时优惠券"
                          : initialValues.type === 2
                          ? "现金抵扣优惠券"
                          : "提现优惠券"}
                      </span>
                    ) : (
                      getFieldDecorator("type", {
                        initialValue: initialValues.type,
                        rules: [{ required: true, message: "请选择使用类型" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          onChange={this.handleTypeChange.bind(this)}
                        >
                          <Option value={1}>加时优惠券</Option>
                          <Option value={2}>现金抵扣优惠券</Option>
                          <Option value={3}>提现优惠券</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="优惠券类型">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.belongType === 1
                          ? "套餐优惠券"
                          : initialValues.belongType === 2
                          ? "新用户优惠券"
                          : "拉新奖励券"}
                      </span>
                    ) : (
                      getFieldDecorator("belongType", {
                        initialValue: initialValues.belongType,
                        rules: [{ required: true, message: "请选择使用类型" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          onChange={this.handleBelongTypeChange.bind(this)}
                        >
                          <Option value={1}>套餐优惠券</Option>
                          <Option value={2}>新用户优惠券</Option>
                          <Option value={3}>拉新奖励券</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                {this.state.belongType === 1 && (
                  <Col span={24}>
                    <Form.Item label="同时领取新用户优惠券">
                      {this.state.modalType === 3 ? (
                        <span>
                          {initialValues.newUserCoupon === 1 ? "是" : "否"}
                        </span>
                      ) : (
                        getFieldDecorator("newUserCoupon", {
                          initialValue:
                            typeof initialValues.newUserCoupon === "number"
                              ? initialValues.newUserCoupon
                              : 0,
                          rules: [{ required: true, message: "请选择" }],
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                          >
                            <Option value={0}>是</Option>
                            <Option value={1}>否</Option>
                          </Select>
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                {this.state.belongType === 1 && (
                  <Col span={24}>
                    <Form.Item label="限套餐新用户使用">
                      {this.state.modalType === 3 ? (
                        <span>
                          {initialValues.userLimit === 1 ? "是" : "否"}
                        </span>
                      ) : (
                        getFieldDecorator("userLimit", {
                          initialValue:
                            typeof initialValues.userLimit === "number"
                              ? initialValues.userLimit
                              : 0,
                          rules: [{ required: true, message: "请选择" }],
                        })(
                          <Select
                            placeholder="请选择"
                            allowClear
                            style={{ width: "100%" }}
                          >
                            <Option value={0}>否</Option>
                            <Option value={1}>是</Option>
                          </Select>
                        )
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="叠加使用">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.repeatUse === 1 ? "是" : "否"}</span>
                    ) : (
                      getFieldDecorator("repeatUse", {
                        initialValue:
                          typeof initialValues.repeatUse === "number"
                            ? initialValues.repeatUse
                            : 0,
                        rules: [{ required: true, message: "请选择" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                        >
                          <Option value={0}>否</Option>
                          <Option value={1}>是</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                {this.state.couponType === 1 && (
                  <Col span={24}>
                    <Form.Item label="天数">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.days + "天"}</span>
                      ) : (
                        getFieldDecorator("days", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              message: "请输入正确的天数",
                              pattern: /^[+]{0,1}(\d+)$/,
                            },
                          ],
                          initialValue: initialValues.days,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                {(this.state.couponType === 2 ||
                  this.state.couponType === 3) && (
                  <Col span={24}>
                    <Form.Item label="金额">
                      {this.state.modalType === 3 ? (
                        <span>{initialValues.amount + "元"}</span>
                      ) : (
                        getFieldDecorator("amount", {
                          validateTrigger: "onBlur",
                          rules: [
                            {
                              message: "请输入正确的金额（保留两位小数）",
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                          ],
                          initialValue: initialValues.amount,
                        })(<Input allowClear placeholder="请输入" />)
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="有效期">
                    {this.state.modalType === 3 ? (
                      <span>
                        {initialValues.startTime && initialValues.endTime
                          ? moment(initialValues.startTime).format(
                              "YYYY-MM-DD"
                            ) +
                            "～" +
                            moment(initialValues.endTime).format("YYYY-MM-DD")
                          : ""}
                      </span>
                    ) : (
                      getFieldDecorator("time", {
                        initialValue:
                          initialValues.startTime && initialValues.endTime
                            ? [
                                moment(initialValues.startTime),
                                moment(initialValues.endTime),
                              ]
                            : undefined,
                        rules: [{ required: true, message: "请选择有效期！" }],
                      })(<RangePicker format="YYYY-MM-DD" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="规则">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.rules}</span>
                    ) : (
                      getFieldDecorator("rules", {
                        initialValue: initialValues.rules,
                      })(
                        <Input.TextArea
                          rows={4}
                          allowClear
                          placeholder="请输入"
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="是否可见">
                    {getFieldDecorator("userVisible", {
                      initialValue: initialValues.userVisible,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="是"
                        unCheckedChildren="否"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getpackageList();
    this.getActivityList();
    this.getFranchiseeList();
    this.getCouponList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取优惠券列表
  getCouponList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _couponList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            couponList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    if (this.state.modalType) {
      this.props.form.setFieldsValue({
        sids: undefined,
      });
    } else {
      this.props.form.setFieldsValue({
        sid: undefined,
      });
    }
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取活动列表
  getActivityList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _activityList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            activityList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取套餐列表
  getpackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    };
    _packageBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取优惠券详情
  getCouponDetail(id, type) {
    let params = { id };
    _couponDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
            couponType: res.data.data.type,
            belongType: res.data.data.belongType,
          });
          this.getSiteList(res.data.data.fid);
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //发放操作
  modalViewhandle(couponId, name) {
    let mobiles, remark;
    Modal.confirm({
      title: `发放优惠券[${name}]`,
      okText: "发放",
      content: (
        <div>
          <Form.Item label="收券账号">
            <TextArea
              onChange={(e) => {
                let value = e.target.value.split("\n");
                mobiles = Array.from(new Set(value));
              }}
              placeholder="请输入用户的手机号，一行一个"
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>
          <Form.Item label="发券备注">
            <Input
              onChange={(e) => {
                remark = e.target.value;
              }}
              placeholder="请输入发券备注"
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = {
          couponId,
          mobiles,
          remark,
        };
        _sendCoupon(params).then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.message);
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getCouponList(curPage, pageSize, this.state.queryFormValues);
  }

  //选择使用类型
  handleTypeChange(value) {
    this.setState({
      couponType: value,
    });
  }
  //选择优惠券类型
  handleBelongTypeChange(value) {
    this.setState({
      belongType: value,
    });
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.scene;
      delete values.userLimit;
      delete values.repeatUse;
      if (values.status === true) {
        values.status = 1;
      } else if (values.status === false) {
        values.status = 0;
      }
      if (values.userVisible === true) {
        values.userVisible = 1;
      } else if (values.userVisible === false) {
        values.userVisible = 0;
      }
      if (values.nameSearch) {
        values.name = values.nameSearch;
        delete values.nameSearch;
      }
      if (values.typeSearch) {
        values.type = values.typeSearch;
        delete values.typeSearch;
      }
      if (values.timeSearch && values.timeSearch.length) {
        values.startTime = moment(values.timeSearch[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.timeSearch[1]).format("YYYY-MM-DD");
      }
      this.setState({
        queryFormValues: values,
      });
      this.getCouponList(1, this.state.pageSize, values);
    });
  };

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateCouponStatus(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getCouponList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      if (values.userVisible !== undefined) {
        values.userVisible = Number(values.userVisible);
      }
      // (values.userVisible === undefined && modalType === 1) ||
      // !!values.userVisible
      //   ? (values.userVisible = 1)
      //   : (values.userVisible = 0);
      if (values.time && values.time.length) {
        values.startTime = moment(values.time[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.time[1]).format("YYYY-MM-DD");
      }
      delete values.time;
      delete values.nameSearch;
      delete values.typeSearch;
      delete values.timeSearch;
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? {
                ...values,
              }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveCouponInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: null,
      initialValues: {},
      visible: false,
      modalType: null,
      couponType: null,
      belongType: null,
    });
  }
}

CouponsList = Form.create({})(CouponsList);
export { CouponsList };
