import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import useList from "@/hooks/list";
import { _batchImportBattery } from "@/statics/js/api.js";
import { Form, Modal, Select, Row, Col, message,Input } from "antd";
const { Option } = Select;
const { TextArea } = Input;

var Index = forwardRef((props, ref) => {
  const { form, done } = props;
  const { getFieldDecorator } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    franchiseeList,
    getFranchiseeList,
    getSiteList,
    siteList,
    setSiteList,
    getBatteryModelList,
    batteryModelList,
  } = useList();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const handleFranchiseeChange = async (value) => {
    setSiteList([]);
    form.setFieldsValue({ sid: "" });
    if (value) {
      getSiteList(value);
    }
  };

  const open = async () => {
    setVisible(true);
    setSiteList([]);
    form.resetFields();
    getFranchiseeList()
    getBatteryModelList()
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    form.validateFields(async (err, values) => {
      if (err) return;
      const params = { 
        ...values,
        imeiList: values.imeiList.split('\n')
      };
      setConfirmLoading(true);
      const res = await _batchImportBattery(params);
      setConfirmLoading(false);
      const { code, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      message.success("操作成功");
      done();
      close();
    });
  };
  return (
    <>
      <Modal
        title="导入电池"
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Row>
            <Col span={24}>
                <Form.Item label="代理商">
                    {getFieldDecorator("fid", {
                        rules: [{ required: true, message: "请选择代理商！" }],
                    })(
                    <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        onChange={handleFranchiseeChange}
                        filterOption={(input, option) => {
                        return (
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                    >
                        {franchiseeList.map((item, index) => {
                            return (
                                <Option key={index} value={item.fid}>
                                    {item.fname}
                                </Option>
                            );
                        })}
                    </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="站点">
                {getFieldDecorator("sid")(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="电池型号">
                {getFieldDecorator("modelId", {
                  rules: [{ required: true, message: "请选择电池型号！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {
                        batteryModelList.map((item, index) => {
                            return (
                                <Option key={index} value={item.id}>
                                    {item.name}
                                </Option>
                            );
                        })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="编号">
                    {getFieldDecorator("imeiList", {
                        rules: [{ required: true, message: "请输入编号！" }],
                    })(
                        <TextArea
                            placeholder="请输入编码，一行一个"
                            autoSize={{ minRows: 10, maxRows: 15 }}
                        />
                    )}
                </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});

Index = Form.create({})(Index);
export default Index;
