import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import useList from "@/hooks/list";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _bikeDetail, _saveBikeInfo } from "@/statics/js/api.js";
import { Form, Modal, Select, Row, Col, message, Input } from "antd";
const { Option } = Select;

var Index = forwardRef((props, ref) => {
  const { form, done } = props;
  const { getFieldDecorator } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(1);
  const [id, setId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [siteDisabled, setSiteDisabled] = useState(false);
  const userType = localStorage.getItem('type')
  const fid = localStorage.getItem('fid') ? Number(localStorage.getItem('fid')) : ''
  const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}
  const blacklist = [26,27,33] //sass代理商角色id
  const whitelist = [104] //哈尔滨代理商fid
  const {
    franchiseeList,
    getFranchiseeList,
    getSiteList,
    siteList,
    setSiteList,
    getBikeModelList,
    bikeModelList,
  } = useList();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const handleFranchiseeChange = async (value) => {
    setSiteList([]);
    form.setFieldsValue({ sid: "" });
    if (value) {
      getSiteList(value);
    }
  };

  const open = async (type, { id, orderId }) => {
    setVisible(true);
    setType(type);
    setId(id);
    setOrderId(orderId);
    setSiteList([]);
    form.resetFields();
    setInitialValues({});
    getFranchiseeList();
    getBikeModelList();
    if (type == 2) {
      getDetail(id);
    }
    let disabled = false
    if(type == 2){
      if(orderId){
        disabled = true
      }else{
        disabled = !getJurisdiction(1044)
        if(whitelist.includes(fid)){
          disabled = false
        }
      }
    }else{
      disabled = false
    }
    setSiteDisabled(disabled)
  };
  const getDetail = async (id) => {
    const params = {
      id,
    };
    const res = await _bikeDetail(params);
    const { code, data = {} } = res.data || {};
    if (code != 200) {
      return;
    }
    data.sid = data.sid || "";
    setInitialValues(data || {});
    getSiteList(data.fid);
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    form.validateFields(async (err, values) => {
      if (err) return;
      const params = {
        ...values,
        id: type == 2 ? id : undefined,
      };
      setConfirmLoading(true);
      const res = await _saveBikeInfo(params);
      setConfirmLoading(false);
      const { code, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      message.success("操作成功");
      done();
      close();
    });
  };
  return (
    <>
      <Modal
        title={type == 1 ? "添加车辆" : "编辑车辆"}
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item label="车辆二维码">
                {getFieldDecorator("imei", {
                  initialValue: initialValues.imei,
                  rules: [{ required: true, message: "请填写车辆二维码！" }],
                })(
                  <Input
                    allowClear
                    disabled={userType !== "0"}
                    placeholder="请输入"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: initialValues.status,
                  rules: [{ required: true, message: "请选择状态！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    disabled={userType !== "0" && !blacklist.includes(userInfo.authGroupId)}
                  >
                    <Option value={1}>正常</Option>
                    <Option value={0} disabled={blacklist.includes(userInfo.authGroupId)}>禁用</Option>
                    <Option value={2} disabled={blacklist.includes(userInfo.authGroupId)}>待确认</Option>
                    <Option value={3}>已销售</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="车辆型号">
                {getFieldDecorator("modelId", {
                  initialValue: initialValues.modelId,
                  rules: [{ required: true, message: "请选择车辆型号！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    disabled={userType !== "0"}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {type === 2 && (
              <Col span={24}>
                <Form.Item label="是否需要维修">
                  {getFieldDecorator("needRepair", {
                    initialValue: initialValues.needRepair,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      disabled={userType == 9}
                    >
                      <Option value={1}>是</Option>
                      <Option value={0}>否</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            {type === 2 && (
              <Col span={24}>
                <Form.Item label="备注信息">
                  {getFieldDecorator("remark", {
                    initialValue: initialValues.remark,
                  })(
                    <Input
                      allowClear
                      maxLength={18}
                      placeholder="请输入"
                      disabled={userType == 9}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            <h3
              style={{
                clear: "both",
                borderBottom: "1px solid #e8e8e8",
                marginBottom: "30px",
                paddingBottom: "10px",
              }}
            >
              投放所属
            </h3>
            {userType === "0" && (
              <Col span={24}>
                <Form.Item label="代理商">
                  {getFieldDecorator("fid", {
                    initialValue: initialValues.fid,
                    rules: [{ required: true, message: "请选择代理商！" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      showSearch
                      disabled={type == 2}
                      onChange={handleFranchiseeChange}
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="站点">
                {getFieldDecorator("sid", {
                  initialValue: initialValues.sid,
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    disabled={siteDisabled}
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});

Index = Form.create({})(Index);
export default Index;
