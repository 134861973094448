import React,{useState,useEffect} from "react";
import { Table,Button,Divider,Form,Input,Modal,Radio,message } from "antd";
import { Link } from "react-router-dom";
import SearchBox from "./components/searchBox";
import useTable from "../../../hooks/table";
import { getJurisdiction } from "../../../statics/js/jurisdiction";
import { _packageApplyList,_updateApplyStatus } from "../../../statics/js/api.js";
import moment from "moment";

const CheckModal = Form.create({})((props) => {
    const {visible,id,ok,cancel,ruleConfig} = props
    const {getFieldDecorator,getFieldValue,validateFields,resetFields} = props.form
    const [confirmLoading,setConfirmLoading] = useState(false)
    useEffect(()=>{
        if(visible){
            resetFields()
        }
    },[visible])
    const handleOk = async () => {
        validateFields(async (err, fieldsValue) => {
            if (err) {
              return;
            }
            const params = {
                id,
                ...fieldsValue
            }
            setConfirmLoading(true)
            const res = await _updateApplyStatus(params)
            setConfirmLoading(false)
            const {code,message:msg} = res.data || {}
            if(code != 200){
                message.warning(msg);
                return
            }
            ok()
        })
    }

    return(
        <Modal
            title="审核"
            visible={visible}
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={cancel}
        >
            {
                ruleConfig == 1 ? 
                <div style={{color: 'gray'}}>该套餐已配置分润规则</div>
                :
                <div style={{color: 'red'}}>该套餐未配置分润规则</div>
            }
            <br/>
            <Form>
                <Form.Item label="是否审核通过">
                    {getFieldDecorator('status',{
                        rules: [
                            { required: true, message: '请选择是否通过' },
                        ],
                    })(
                        <Radio.Group>
                            <Radio value={2}>通过</Radio>
                            <Radio value={3}>拒绝</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                {
                    getFieldValue('status') == 3 ? <Form.Item label="拒绝原因">
                        {getFieldDecorator('reason')(
                            <Input.TextArea rows={4} placeholder="请输入" maxLength={15} />
                        )}
                    </Form.Item> : null
                }
            </Form>
        </Modal>
    )
})

const Index = (props) => {
    const columns = [
        { 
            title: "套餐编号",
            dataIndex: "pid",
        },
        {
            title: "套餐名称",
            dataIndex: "pname",
        },
        {
            title: "申请时间",
            width: 120,
            render: (text) => text.addTime && moment(text.addTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "代理商",
            dataIndex: "fname",
        },
        {
            title: "状态",
            dataIndex: "statusName",
        },
        {
            title: "审核时间",
            width: 120,
            render: (text) => text.checkTime && moment(text.checkTime).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "审核备注",
            dataIndex: "reason",
        },
        {
            title: "操作",
            dataIndex: "",
            width: 140,
            key: "Action",
            fixed: "right",
            render: (text) => (
              <span>
                <Link target="_blank" to={"/carRentalPackages?pname=" + encodeURIComponent(text.pname)}>
                    查看
                </Link>
                {
                    text.status == 1 ?
                    <>
                        <Divider type="vertical" />
                        <Button
                            type="link"
                            disabled={!getJurisdiction(1029)}
                            title={getJurisdiction(1029) ? "" : "暂无操作权限！"}
                            onClick={()=>{handleCheck({...text})}}
                        >
                            审核
                        </Button>
                    </>
                    : null
                }
              </span>
            ),
        },
    ];

    const [visible,setVisible] = useState(false);
    const [id,setId] = useState('');
    const [ruleConfig,setRuleConfig] = useState(0);
    const handleCheck = async ({id,ruleConfig}) => {
        setVisible(true)
        setId(id)
        setRuleConfig(ruleConfig)
    }
    const finish = async () => {
        setVisible(false)
        handleRefresh()
    }
    
    const {
        pageSize,
        curPage,
        totalCount,
        list,
        loading,
        handleSearch,
        handlePageChange,
        handleRefresh,
    } = useTable({
        action: _packageApplyList,
    });

    return !getJurisdiction(1028) ? (
        "暂无操作权限！"
    ) : (
        <div>
            <div className="select-form">
                <SearchBox search={handleSearch} />
            </div>
            <Table
                rowKey="id"
                bordered
                columns={columns}
                dataSource={list}
                loading={loading}
                scroll={{ x: 2000, y: 500 }}
                pagination={{
                    showQuickJumper: true,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageChange,
                    onChange: handlePageChange,
                    total: totalCount,
                    current: curPage,
                    showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
            />
            <CheckModal visible={visible} id={id} ruleConfig={ruleConfig} ok={finish} cancel={()=>{setVisible(false)}} />
        </div>
    );
};

export default Index;