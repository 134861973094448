import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  Radio,
  Select,
  Input,
  Form,
  Row,
  Col,
  message,
} from "antd";
import { _cashApplyList, _updateCashApplyStatus } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const { Option } = Select;
class CashApplyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      cashApplyList: [], //提现申请列表
      id: "", //提现申请id
    };
    this.columns = [
      {
        title: "ID",
        width: 80,
        dataIndex: "id",
      },
      {
        title: "用户姓名",
        dataIndex: "userName",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
      },
      {
        title: "提现金额",
        dataIndex: "amount",
        render: (text) => text + "元",
      },
      {
        title: "申请时间",
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "申请状态",
        dataIndex: "status",
        render: (text) =>
          text === 1
            ? "申请中"
            : text === 2
            ? "审核通过"
            : text === 3
            ? "已打款"
            : text === 4
            ? "已拒绝"
            : "已取消",
      },
      {
        title: "打款时间",
        dataIndex: "payTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm"),
      },
      {
        title: "备注",
        dataIndex: "remark",
      },

      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) =>
          text.status === 1 ? (
            <Button
              type="link"
              disabled={!getJurisdiction(944)}
              title={getJurisdiction(944) ? "" : "暂无操作权限！"}
              onClick={this.modalViewAuditing.bind(this, text.id)}
            >
              审核
            </Button>
          ) : text.status === 2 ? (
            <Button
              type="link"
              disabled={!getJurisdiction(944)}
              title={getJurisdiction(944) ? "" : "暂无操作权限！"}
              onClick={this.modalViewRemit.bind(this, text.id)}
            >
              打款
            </Button>
          ) : (
            "暂无"
          ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="姓名/手机号">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={0}>全部</Option>
                  <Option value={1}>申请中</Option>
                  <Option value={2}>审核通过</Option>
                  <Option value={3}>已打款</Option>
                  <Option value={4}>已拒绝</Option>
                  <Option value={5}>已取消</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { cashApplyList, totalCount, curPage, pageSize } = this.state;
    return (
      <div>
        {!!getJurisdiction(933) ? (
          <div className="CashApplyList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={cashApplyList}
                scroll={{ x: 1300, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getCashApplyList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取申请提现列表
  getCashApplyList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _cashApplyList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            cashApplyList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //审核
  modalViewAuditing(id) {
    let self = this;
    let status, remark;
    Modal.confirm({
      title: "提现审核",
      content: (
        <div style={{ marginTop: 30 }}>
          <Form.Item label="审核">
            <Radio.Group
              onChange={(e) => {
                status = e.target.value;
              }}
            >
              <Radio value={2}>通过</Radio>
              <Radio value={4}>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="备注">
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                remark = e.target.value;
              }}
              allowClear
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = {
          id,
          status,
          remark,
        };
        _updateCashApplyStatus(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success(res.data.message);
              self.getCashApplyList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  }

  //打款
  modalViewRemit(id) {
    let self = this;
    Modal.confirm({
      title: "打款提醒",
      content: "是否确定打款操作？",
      onOk() {
        let params = { id, status: 3 };
        _updateCashApplyStatus(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              self.getCashApplyList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              );
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getCashApplyList(curPage, pageSize, this.state.queryFormValues);
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      this.setState({
        queryFormValues: values,
      });
      this.getCashApplyList(1, this.state.pageSize, values);
    });
  };
}

CashApplyList = Form.create({})(CashApplyList);
export { CashApplyList };
