import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  _savePackageAuth,
  _packageAuthDetailTransfe,
  _packageAuthDetailReturn,
  _packageAuthDetail,
} from "@/statics/js/api.js";
import { Tabs, Modal, message, Button, Tree } from "antd";
const { TabPane } = Tabs;
const { TreeNode } = Tree;
const steps = [
  { label: "租车站点" },
  { label: "退车站点", key: "returnType" },
  { label: "换车站点", key: "transferType" },
];

const TreeDom = forwardRef((props, ref) => {
  const [list, setList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const init = ({ checkedKeys, expandedKeys, list }) => {
    setList(list);
    setCheckedKeys(checkedKeys);
    setExpandedKeys(expandedKeys);
  };
  const getCheckedKeys = () => {
    return checkedKeys;
  };
  useImperativeHandle(
    ref,
    () => ({
      init,
      getCheckedKeys,
      setCheckedKeys,
    }),
    [init, getCheckedKeys, setCheckedKeys]
  );
  return (
    <Tree
      checkable
      onCheck={(keys) => setCheckedKeys(keys)}
      onExpand={(keys) => {
        setExpandedKeys(keys);
        setAutoExpandParent(false);
      }}
      checkedKeys={checkedKeys}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      style={{ overflow: "hidden" }}
    >
      {list.map((item) => {
        if (item.children && item.children.length) {
          return (
            <TreeNode
              className="siteBindTree"
              title={item.title}
              key={item.key}
              dataRef={item}
            >
              {item.children.map((item1) => (
                <TreeNode
                  treeDefaultExpandedKeys={true}
                  key={item1.key}
                  {...item1}
                />
              ))}
            </TreeNode>
          );
        }
        return (
          <TreeNode treeDefaultExpandedKeys={true} key={item.key} {...item} />
        );
      })}
    </Tree>
  );
});

const Index = forwardRef((props, ref) => {
  const { onSuccess, onCancel } = props;
  const [id, setId] = useState();
  const [visible, setVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const treeRef1 = useRef();
  const treeRef2 = useRef();
  const treeRef3 = useRef();
  const [stepList, setStepList] = useState([]);
  const [transferType, setTransferType] = useState(0);
  const [returnType, setReturnType] = useState(0);
  const [modalType, setModalType] = useState("");
  const init = async ({ id, transferType, returnType, modalType }) => {
    setVisible(true);
    setStep(0);
    setModalType(modalType);
    setId(id);
    let list = steps.filter((item) => {
      if (transferType != 2 && item.key == "transferType") {
        return false;
      }
      if (returnType != 2 && item.key == "returnType") {
        return false;
      }
      return true;
    });
    setStepList(list);
    setTransferType(transferType);
    setReturnType(returnType);
    const res1 = await getList(_packageAuthDetail, { id });
    treeRef1.current.init({ ...res1 });
    if (returnType == 2) {
      const res2 = await getList(_packageAuthDetailReturn, { id });
      treeRef2.current.init({ ...res2 });
    }
    if (transferType == 2) {
      const res3 = await getList(_packageAuthDetailTransfe, { id });
      treeRef3.current.init({ ...res3 });
    }
  };
  const getList = async (action, { id }) => {
    const params = { id };
    const res = await action(params);
    const { code, data } = res.data || {};
    if (code !== 200) {
      return {
        expandedKeys: [],
        list: [],
        checkedKeys: [],
      };
    }
    let expandedKeys = [];
    let menusList = data.flist || [];
    menusList = menusList.map((item, index) => {
      expandedKeys.push(item.key);
      if (item.children) {
        item.children.map((item1, index1) => {
          expandedKeys.push(item1.key);
          delete item1.children;
          return item1;
        });
      } else {
        delete item.children;
      }
      return item;
    });
    return {
      expandedKeys: expandedKeys,
      list: menusList,
      checkedKeys: data.authIds,
    };
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const keys1 = treeRef1.current.getCheckedKeys();
    const keys2 = treeRef2.current ? treeRef2.current.getCheckedKeys() : [];
    const keys3 = treeRef3.current ? treeRef3.current.getCheckedKeys() : [];
    const params = {
      id,
      authIds: [...keys1, ...keys2, ...keys3],
    };
    const res = await _savePackageAuth(params);
    const { code, message: msg } = res.data || {};
    setConfirmLoading(false);
    if (code != 200) {
      message.error(msg);
      return;
    }
    setVisible(false);
    onSuccess();
  };
  const handleCancel = async () => {
    setVisible(false);
    onCancel();
  };
  const handleStep = async (action) => {
    if (action == "next") {
      setStep((value) => ++value);
      const { key } = stepList.find((item, index) => step == index);
      if (key == "returnType" && stepList.length == 3) {
        let checkKey = treeRef2.current.getCheckedKeys();
        checkKey = checkKey.map((item) => {
          const key = item.replace("R", "H");
          return key;
        });
        treeRef3.current.setCheckedKeys(checkKey);
      }
    } else {
      setStep((value) => --value);
    }
  };
  useImperativeHandle(
    ref,
    () => ({
      init,
      handleOk,
    }),
    [init, handleOk]
  );
  return (
    <>
      {modalType == 1 ? (
        <Modal
          destroyOnClose
          maskClosable={false}
          width={850}
          title={"绑定站点"}
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="step_wrap">
            {stepList.map((item, index) => {
              return (
                <div
                  className={
                    "step_item " + (step >= index ? "step_active" : "")
                  }
                  key={index}
                >
                  <div className="step_info">
                    <div className="step_dot"></div>
                    <div className="step_label">{item.label}</div>
                  </div>
                  {index < stepList.length - 1 ? (
                    <div
                      className={
                        "step_line " + (step > index ? "line_active" : "")
                      }
                    ></div>
                  ) : null}
                </div>
              );
            })}
          </div>
          {stepList.map((item, index) => {
            if (!item.key) {
              return (
                <div
                  key={index}
                  style={{
                    overflow:"hidden",
                    height: index == step ? "auto" : "0",
                  }}
                >
                  <TreeDom ref={treeRef1} />
                </div>
              );
            } else if (item.key === "returnType") {
              return (
                <div
                  key={index}
                  style={{
                    overflow:"hidden",
                    height: index == step ? "auto" : "0",
                  }}
                >
                  <TreeDom ref={treeRef2} />
                </div>
              );
            } else if (item.key === "transferType") {
              return (
                <div
                  key={index}
                  style={{
                    overflow:"hidden",
                    height: index == step ? "auto" : "0",
                  }}
                >
                  <TreeDom ref={treeRef3} />
                </div>
              );
            }
          })}
          <div className="edit_footer">
            {step > 0 && (
              <Button onClick={() => handleStep("back")}>上一步</Button>
            )}
            {step < stepList.length - 1 && (
              <Button onClick={() => handleStep("next")} type="primary">
                下一步
              </Button>
            )}
            <Button onClick={handleOk} loading={confirmLoading} type="primary">
              完成
            </Button>
          </div>
        </Modal>
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="租车站点" key="1">
            <TreeDom ref={treeRef1} />
          </TabPane>
          {returnType == 2 && (
            <TabPane forceRender tab="退车站点" key="2">
              <TreeDom ref={treeRef2} />
            </TabPane>
          )}
          {transferType == 2 && (
            <TabPane forceRender tab="换车站点" key="3">
              <TreeDom ref={treeRef3} />
            </TabPane>
          )}
        </Tabs>
      )}
    </>
  );
});

export default Index;
