import './index.less'
import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import {
  Modal,
  Form,
  message,
  Input,
  Spin,
  Row,
  Col,
  Select,
  Button,
  Switch,
  Upload,
  Icon,
  Checkbox,
  InputNumber,
  Radio,
} from 'antd'
import PayTypeSelect from '@/components/payTypeSelect'
import BikeModelSelect from '@/components/bikeModelSelect'
import BatteryModelSelect from '@/components/batteryModelSelect'
import RentTypeSelect from '@/components/rentTypeSelect'
import CityCascader from '@/components/cityCascader'
// import RepairCheckbox from "@/components/repairCheckbox";
import FranchiseeSelect from '@/components/franchiseeSelect'
import {
  _packageDetail,
  _savePackageInfo,
  _imageUpload,
  _franchiseeDetail,
} from '@/statics/js/api.js'
import { zipImg } from '@/statics/js/util'
const { Option } = Select
const modelList = [{ id: 1 }, { id: 2 }]

const Index = Form.create({
  onValuesChange: (props, changedValues, allValues) => {
    const { form } = props
    const { setFieldsValue } = form
    const { ptype, btype, deductType, dayDeduct } = allValues
    if (dayDeduct == 0) return
    if (ptype < 3 || btype != 2 || deductType == 2) {
      setFieldsValue({
        dayDeduct: 0,
      })
    }
  },
})(
  forwardRef((props, ref) => {
    const { form } = props
    const {
      getFieldDecorator,
      resetFields,
      getFieldValue,
      getFieldsValue,
      setFieldsValue,
      validateFields,
    } = form
    const [id, setId] = useState('')
    const [modalType, setModalType] = useState('')
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [fileList, setFileList] = useState([])
    const [fileList1, setFileList1] = useState([])
    const [modifyPrice, setModifyPrice] = useState()
    const [periodList, setPeriodList] = useState([])
    const [activity, setActivity] = useState({
      modelId: 1,
      checked: [],
      logo: {},
      title1: '',
      title2: '',
      title3: '',
      des: '',
      originMoney: 0,
    })
    const [tempParams, setTempParams] = useState({})
    const [agentDayDeduct, setAgentDayDeduct] = useState(0)
    const agentRef = useRef()

    const init = async (type, { id = '' }) => {
      setTempParams({})
      setModalType(type)
      setId(id)
      resetFields()
      setInitialValues({
        dayDeduct: 0,
        dayDeductPeriod: 1,
      })
      setFileList([])
      setFileList1([])
      setPeriodList([])
      setActivity({
        modelId: 1,
        checked: [],
        logo: {},
        title1: '',
        title2: '',
        title3: '',
        des: '',
        originMoney: 0,
      })
      if (type !== 1) {
        await getDetail(id)
      }
      const userType = localStorage.getItem('type')
      const fid =
        userType === '0' ? getFieldValue('fid') : localStorage.getItem('fid')
      const { modifyPrice, dayDeduct } = await getFranchiseeDetail(fid)
      setAgentDayDeduct(dayDeduct || 0)
      if (userType !== '0') {
        setModifyPrice(modifyPrice)
        if (!modifyPrice) {
          setFieldsValue({ pmodifyPrice: 0 })
        }
      }
    }
    const getFranchiseeDetail = async (fid) => {
      if (!fid) {
        return {}
      }
      const params = {
        fid,
      }
      const res = await _franchiseeDetail(params)
      const detail = res.data.data || {}
      return detail
    }
    const getDetail = async (id) => {
      const params = {
        id,
      }
      setLoading(true)
      const res = await _packageDetail(params)
      setLoading(false)
      const { code, data = {}, message: msg } = res.data || {}
      if (code !== 200) {
        message.error(msg)
        return
      }
      const {
        province,
        city,
        county,
        street,
        thumbImages = [],
        contentImages = [],
        activity,
      } = data || {}
      let areaList = []
      province && areaList.push(Number(province))
      city && areaList.push(Number(city))
      county && areaList.push(Number(county))
      street && areaList.push(Number(street))
      data.areaList = areaList
      data.repairType = data.repairType ? data.repairType : null
      data.pmodifyPrice = data.pmodifyPrice || 0
      setInitialValues({
        ...data,
        dayDeductPeriod: data.dayDeductPeriod || 1,
        dayDeductTime: data.dayDeductTime || null,
      })
      setPeriodList(data.periodsList)
      if (activity) {
        try {
          let obj = JSON.parse(activity)
          setActivity(obj)
        } catch (error) {
          console.log(error)
        }
      }
      let fileList = thumbImages
        ? thumbImages.map((item, index) => ({
            uid: index,
            name: item.id,
            status: 'done',
            url: item.path,
          }))
        : []
      setFileList(fileList)
      let fileList1 = contentImages
        ? contentImages.map((item, index) => ({
            uid: index,
            name: item.id,
            status: 'done',
            url: item.path,
          }))
        : []
      setFileList1(fileList1)
    }
    const handleOk = async () => {
      if (modalType === 3) {
        return
      }
      let values = await validateFields()
      const {
        checked = [],
        title1,
        title2,
        title3,
        originMoney,
        des,
        logo,
      } = activity || {}
      if (checked.includes(1) && !title1) {
        message.error('买点文案1不能为空')
        return
      }
      if (checked.includes(2) && !title2) {
        message.error('买点文案2不能为空')
        return
      }
      if (checked.includes(3) && !title3) {
        message.error('买点文案3不能为空')
        return
      }
      if (checked.includes(4) && !des) {
        message.error('活动文案不能为空')
        return
      }
      if (checked.includes(6) && !originMoney) {
        message.error('套餐原价不能为空')
        return
      }
      let limit = values.money * 1.3
      if (activity.originMoney > limit) {
        message.error('套餐原价不得超过套餐金额的130%')
        return
      }
      if (values.thumb && values.thumb.constructor === Array) {
        let thumb = []
        values.thumb.map((item, index) => {
          if (item.response) {
            thumb.push(item.response.data.id)
          }
          return item
        })
        values.thumb = thumb.join(',')
      }
      if (values.content && values.content.constructor === Array) {
        let content = []
        values.content.map((item, index) => {
          if (item.response) {
            content.push(item.response.data.id)
          }
          return item
        })
        values.content = content.join(',')
      }
      values.pstatus = values.pstatus ? 1 : 0
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null
        values.city = values.areaList[1]
          ? values.areaList[1]
          : values.areaList[0]
        values.county = values.areaList[2] ? values.areaList[2] : null
        values.street = values.areaList[3] ? values.areaList[3] : null
        delete values.areaList
      }
      if (values.ptype == 1) {
        if (periodList.every((item) => item.period && item.price)) {
          values.periodsList = periodList
        } else {
          message.error('可选期数填写完整后，在提交')
          return
        }
      }
      let params = {
        ...values,
        activity: JSON.stringify(activity),
        diffId: initialValues.diffId || tempParams.diffId || undefined,
      }
      if (id) {
        params.id = id
        if (localStorage.getItem('type') !== '0') {
          let fid = localStorage.getItem('fid')
            ? Number(localStorage.getItem('fid'))
            : undefined
          params.fid = fid
        }
      }
      if (JSON.stringify(params) === JSON.stringify(tempParams)) {
        return { id }
      }
      const res = await _savePackageInfo(params)
      const { code, message: msg, data } = res.data || {}
      if (code !== 200) {
        message.warning(msg)
        return
      }
      message.success('操作成功')
      const curId = data || id || ''
      setId(curId)
      const res2 = await _packageDetail({ id: curId })
      const { data: data2 } = res2.data || {}
      const { diffId } = data2 || {}
      setTempParams({ ...params, id: curId, diffId })
      return { id: curId, refresh: true }
    }
    const showDeductType = () => {
      const value = getFieldValue('payTypeIds') || []
      const bool = value.includes(6) || value.includes(9)
      return bool
    }
    const showPeriodsType = () => {
      const value = getFieldValue('payTypeIds') || []
      const bool = value.includes(7) || value.includes(8)
      return bool
    }
    const showPeriodList = () => {
      const ptype = getFieldValue('ptype') || initialValues.ptype || null
      const bool = ptype == 1
      console.log(ptype)
      return bool
    }
    const handleMoney = (e) => {
      if (getFieldValue('ptype') == 1) {
        let list = periodList
        if (list.length) {
          list = list.map((item) => {
            item.price = (e.target.value || 0) * (item.period || 0)
            return item
          })
        } else {
          list.push({ period: 1, price: e.target.value })
        }
        setPeriodList(list)
      }
    }
    const handlePeriodChange = (key, index, value) => {
      const list = periodList.map((item, ind) => {
        if (index == ind) {
          item[key] = value
          if (key === 'period') {
            item['price'] = value * getFieldValue('money')
          }
        }
        return item
      })
      setPeriodList(list)
    }
    const handlePeriodAdd = () => {
      setPeriodList((value) => [...value, { period: '', price: '' }])
    }
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    }
    const handleUploadChange = (type, { file, fileList }) => {
      fileList.map((item, index) => {
        if (item.status === 'error') {
          fileList.splice(index, 1)
        }
        if (item.response) {
          if (item.response.code !== 200) {
            message.warning(item.response.message)
            fileList.splice(index, 1)
          }
        }
        return item
      })
      if (type == 'thumb') {
        setFileList(fileList)
      } else if (type == 'content') {
        setFileList1(fileList)
      } else if (type == 'logo') {
        let logo = {}
        const [file] = fileList
        if (file) {
          logo = {
            id: file.response ? file.response.data.id : '',
            url: file.response ? file.response.data.url : '',
          }
        }
        setActivity((value) => {
          return {
            ...value,
            logo,
          }
        })
      }
    }
    const handleBeforeUpload = async (file) => {
      const res = await zipImg({
        file,
      })
      return res
    }
    const handleCheckChange = (checkedValues) => {
      setActivity((value) => {
        return {
          ...value,
          checked: checkedValues,
        }
      })
    }
    const handleTextChange = (key, e) => {
      const { value: val } = e.target
      setActivity((value) => {
        return {
          ...value,
          [key]: val,
        }
      })
    }
    const handleNumberChange = (key, val) => {
      setActivity((value) => {
        return {
          ...value,
          [key]: val,
        }
      })
    }
    const handleFranchiseeChange = (value) => {
      const list = agentRef.current.getList()
      const franchisee = list.find((item) => item.fid == value)
      if (franchisee) {
        setFieldsValue({
          serviceMoney: franchisee.fpackageFee,
        })
        setAgentDayDeduct(franchisee.dayDeduct || 0)
      } else {
        setAgentDayDeduct(0)
      }
    }
    const getArray = ({ n = 0, min = 0, max = 100 }) => {
      const arr = []
      if (n > max) {
        n = max
      }
      if (n < min) {
        n = min
      }
      for (let i = 0; i < n; i++) {
        arr.push({})
      }
      return arr
    }
    const getInfo = () => {
      return getFieldsValue()
    }
    const handlePtypeChange = (value) => {
      setFieldsValue({ payTypeIds: [] })
      value != 1 && setPeriodList([])
    }
    const handleMonthNumChange = (value) => {
      const dayDeductPeriod = getFieldValue('dayDeductPeriod') || 1
      const monthNum = value > 100 ? 100 : value || 1
      setFieldsValue({
        dayDeductPeriod:
          dayDeductPeriod > monthNum ? monthNum : dayDeductPeriod,
      })
    }
    const handlePreviewModel = ({ id }) => {
      Modal.info({
        icon: null,
        content: (
          <div className={'preview_box preview_model' + id}>
            <img
              className="preview_cover"
              src={fileList[0] && fileList[0]['url']}
              alt=""
            />
            {activity.checked.includes(2) && (
              <div className="text5">
                <div>
                  <span>{activity.title2}</span>
                </div>
              </div>
            )}
            {activity.checked.includes(3) && (
              <div className="text6">
                <div>
                  <span>{activity.title3}</span>
                </div>
              </div>
            )}
            {activity.checked.includes(1) && (
              <div className="text1">
                <span>{activity.title1}</span>
              </div>
            )}
            <div className="bottom_text">
              {activity.checked.includes(6) && (
                <div className="money_text">
                  <div className="text3">
                    <span>{activity.originMoney}</span>元/月
                  </div>
                  <div className="text4">{getFieldValue('money')}元/月</div>
                </div>
              )}
              {activity.checked.includes(4) && (
                <div className="text2">
                  <span>{activity.des}</span>
                </div>
              )}
            </div>
            {activity.checked.includes(7) && (
              <img
                className="logo1"
                src={
                  activity.logo.url ||
                  require('@/statics/img/model/uma_logo.jpg')
                }
                alt=""
              />
            )}
          </div>
        ),
      })
    }
    useImperativeHandle(
      ref,
      () => ({
        init,
        handleOk,
        getInfo,
      }),
      [init, handleOk, getInfo]
    )
    return (
      <Form>
        <Spin spinning={loading}>
          <Row>
            {localStorage.getItem('type') === '0' && (
              <Col span={24}>
                <Form.Item label="选择代理商">
                  {getFieldDecorator('fid', {
                    initialValue: initialValues.fid,
                    rules: [{ required: true, message: '请选择代理商' }],
                  })(
                    modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      <FranchiseeSelect
                        ref={agentRef}
                        onChange={handleFranchiseeChange}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="套餐名称">
                {modalType === 3 ? (
                  <span>{initialValues.pname}</span>
                ) : (
                  getFieldDecorator('pname', {
                    initialValue: initialValues.pname,
                    rules: [{ required: true, message: '请输入套餐名称！' }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="套餐类型">
                {getFieldDecorator('ptype', {
                  initialValue: initialValues.ptype,
                  rules: [{ required: true, message: '请选择套餐类型！' }],
                })(
                  modalType === 3 ? (
                    <span>{initialValues.ptypeName}</span>
                  ) : (
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                      disabled={modalType === 2}
                      onChange={handlePtypeChange}
                    >
                      <Option value={1}>租车</Option>
                      <Option value={2}>销售</Option>
                      <Option value={3}>以租代售</Option>
                      <Option value={4}>连续租赁</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="套餐租期">
                {getFieldDecorator('btype', {
                  rules: [
                    {
                      required: true,
                      message: '请选择套餐租期！',
                    },
                  ],
                  initialValue:
                    typeof initialValues.btype === 'number'
                      ? initialValues.btype
                      : 1,
                })(
                  modalType === 3 ? (
                    <span>{initialValues.btypeName}</span>
                  ) : (
                    <RentTypeSelect disabled={modalType === 2} />
                  )
                )}
              </Form.Item>
            </Col>
            {getFieldValue('ptype') > 2 && (
              <Col span={24}>
                <Form.Item label="分期期数">
                  {modalType === 3 ? (
                    <span>{initialValues.monthNum}</span>
                  ) : (
                    getFieldDecorator('monthNum', {
                      initialValue: initialValues.monthNum,
                      rules: [{ required: true, message: '请输入期数' }],
                    })(
                      <InputNumber
                        allowClear
                        min={0}
                        max={100}
                        precision={0}
                        onChange={handleMonthNumChange}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="套餐基础服务费（元）">
                {modalType === 3 ? (
                  <span>{initialValues.serviceMoney}</span>
                ) : (
                  getFieldDecorator('serviceMoney', {
                    initialValue: initialValues.serviceMoney,
                  })(<InputNumber allowClear min={0} max={50} precision={2} />)
                )}
              </Form.Item>
            </Col>
            {getFieldValue('ptype') > 2 && (
              <Col span={24}>
                <Form.Item label="违约金（元）">
                  {modalType === 3 ? (
                    <span>{initialValues.diffAmount}</span>
                  ) : (
                    getFieldDecorator('diffAmount', {
                      initialValue: initialValues.diffAmount,
                      rules: [
                        { required: true, message: '请输入金额' },
                        {
                          message: '请输入正确的金额（保留两位小数）',
                          pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        },
                      ],
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="支付方式">
                {modalType === 3 ? (
                  <span>
                    {initialValues.payTypes &&
                      initialValues.payTypes.map((item, index) => {
                        return <span key={index}>{item.name}/</span>
                      })}
                  </span>
                ) : (
                  getFieldDecorator('payTypeIds', {
                    initialValue: initialValues.payTypeIds,
                    rules: [{ required: true, message: '请选择支付方式！' }],
                  })(
                    <PayTypeSelect
                      mode="multiple"
                      ptype={getFieldValue('ptype')}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            {showDeductType() && (
              <Col span={24}>
                <Form.Item label="代扣方式">
                  {getFieldDecorator('deductType', {
                    initialValue:
                      typeof initialValues.deductType === 'number'
                        ? initialValues.deductType
                        : 1,
                    rules: [{ required: true, message: '请选择代扣方式！' }],
                  })(
                    modalType === 3 ? (
                      <span>
                        {initialValues.deductType === 1 ? '先付' : '后付'}
                      </span>
                    ) : (
                      <Select
                        style={{ width: '100%' }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={1}>先付</Option>
                        <Option value={2}>后付</Option>
                      </Select>
                    )
                  )}
                </Form.Item>
              </Col>
            )}
            {showPeriodsType() && (
              <Col span={24}>
                <Form.Item label="期数">
                  {modalType === 3 ? (
                    <span>{initialValues.periods}期</span>
                  ) : (
                    getFieldDecorator('periods', {
                      initialValue: initialValues.periods,
                      rules: [{ required: true, message: '请选择期数！' }],
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={3}>3期</Option>
                        <Option value={6}>6期</Option>
                        <Option value={12}>12期</Option>
                      </Select>
                    )
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="合同类型">
                {modalType === 3 ? (
                  <span>{initialValues.contractTypeName}</span>
                ) : (
                  getFieldDecorator('contractType', {
                    initialValue:
                      initialValues.contractType !== 0
                        ? initialValues.contractType
                        : null,
                    rules: [{ required: true, message: '请选择合同类型！' }],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>自营</Option>
                      <Option value={2}>非自营</Option>
                      <Option value={3}>定制</Option>
                      <Option value={4}>钢铁侠</Option>
                      <Option value={5}>东北专用</Option>
                      <Option value={6}>三个月合同</Option>
                      <Option value={7}>销售合同(花呗分期)</Option>
                      <Option value={8}>销售合同(全款)</Option>
                      <Option value={9}>销售合同(合约购)</Option>
                      <Option value={10}>四川威信合同</Option>
                      <Option value={11}>租房合同</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display: agentDayDeduct ? 'block' : 'none',
              }}
            >
              <Form.Item
                label="是否配置分期付"
                help={
                  modalType === 3
                    ? ''
                    : '选择“是”后，用户指定月租金可以分为每日付款，代扣方式为“先付”，套餐期数为“30天”才可配置分期付'
                }
              >
                {getFieldDecorator('dayDeduct', {
                  initialValue: initialValues.dayDeduct,
                })(
                  modalType === 3 ? (
                    <span>{initialValues.dayDeduct ? '是' : '否'}</span>
                  ) : (
                    <Radio.Group
                      disabled={
                        (getFieldValue('ptype') || 0) < 3 ||
                        getFieldValue('btype') != 2 ||
                        getFieldValue('deductType') == 2
                      }
                    >
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </Radio.Group>
                  )
                )}
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display:
                  getFieldValue('dayDeduct') && agentDayDeduct
                    ? 'block'
                    : 'none',
              }}
            >
              <Form.Item label="可转为分期付的期数">
                {getFieldDecorator('dayDeductPeriod', {
                  initialValue: initialValues.dayDeductPeriod,
                  rules: [
                    {
                      required: getFieldValue('dayDeduct') && agentDayDeduct,
                      message: '请选择',
                    },
                  ],
                })(
                  modalType === 3 ? (
                    <span>前{initialValues.dayDeductPeriod}期</span>
                  ) : (
                    <Select
                      style={{ width: '200px' }}
                      placeholder="请选择"
                      allowClear
                    >
                      {getArray({
                        n: getFieldValue('monthNum'),
                        min: 1,
                        max: 100,
                      }).map((item, index) => (
                        <Option key={index} value={index + 1}>
                          前{index + 1}期
                        </Option>
                      ))}
                    </Select>
                  )
                )}
                {modalType === 3 ? null : (
                  <span className="bottom_tip">
                    选择可生成分期付订单的月租期
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display:
                  getFieldValue('dayDeduct') && agentDayDeduct
                    ? 'block'
                    : 'none',
              }}
            >
              <Form.Item label="首期分期付开始扣款时间">
                {getFieldDecorator('dayDeductTime', {
                  initialValue: initialValues.dayDeductTime,
                  rules: [
                    {
                      required: getFieldValue('dayDeduct') && agentDayDeduct,
                      message: '请选择',
                    },
                  ],
                })(
                  modalType === 3 ? (
                    <span>第{initialValues.dayDeductTime}日</span>
                  ) : (
                    <Select
                      style={{ width: '200px' }}
                      placeholder="请选择"
                      allowClear
                    >
                      {getArray({ n: 15 }).map((item, index) => (
                        <Option key={index} value={index + 1}>
                          第{index + 1}日
                        </Option>
                      ))}
                    </Select>
                  )
                )}
                {modalType === 3 ? null : (
                  <span className="bottom_tip">
                    首期分
                    {getFieldValue('dayDeductTime')
                      ? 30 - getFieldValue('dayDeductTime') + 1
                      : null}
                    期扣款，非首期租金生成分期付订单默认分30期扣款
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col
              span={24}
              style={{
                display:
                  modifyPrice || localStorage.getItem('type') === '0'
                    ? 'block'
                    : 'none',
              }}
            >
              <Form.Item label="是否可修改订单金额">
                {modalType === 3 ? (
                  <span>
                    {initialValues.pmodifyPrice ? '可修改' : '不可修改'}
                  </span>
                ) : (
                  getFieldDecorator('pmodifyPrice', {
                    initialValue: initialValues.pmodifyPrice,
                    rules: [{ required: true, message: '请选择！' }],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                      onChange={() => setFieldsValue({ bikeModelIds: [] })}
                    >
                      <Option value={1}>可修改</Option>
                      <Option value={0}>不可修改</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="车辆型号">
                {modalType === 3 ? (
                  <span>
                    {initialValues.bikeModels &&
                      initialValues.bikeModels.map((item, index) => {
                        return <span key={index}>{item.name}/</span>
                      })}
                  </span>
                ) : (
                  getFieldDecorator('bikeModelIds', {
                    initialValue: initialValues.bikeModelIds
                      ? initialValues.bikeModelIds
                      : [],
                  })(
                    <BikeModelSelect
                      self={getFieldValue('pmodifyPrice') ? 2 : undefined}
                      mode="multiple"
                    />
                  )
                )}
              </Form.Item>
            </Col>
            {!!(getFieldValue('bikeModelIds') || []).length && (
              <Col span={24}>
                <Form.Item label="车辆数">
                  {modalType === 3 ? (
                    <span>{initialValues.bikeNum}</span>
                  ) : (
                    getFieldDecorator('bikeNum', {
                      initialValue: initialValues.bikeNum,
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          message: '请输入正确的车辆数',
                          pattern: /^[+]{0,1}(\d+)$/,
                        },
                        { required: true, message: '请输入车辆数！' },
                      ],
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="电池型号">
                {modalType === 3 ? (
                  <span>
                    {initialValues.dcModels &&
                      initialValues.dcModels.map((item, index) => {
                        return <span key={index}>{item.name}/</span>
                      })}
                  </span>
                ) : (
                  getFieldDecorator('dcModelIds', {
                    initialValue: initialValues.dcModelIds
                      ? initialValues.dcModelIds
                      : [],
                  })(
                    <BatteryModelSelect
                      self={getFieldValue('pmodifyPrice') ? 2 : undefined}
                      mode="multiple"
                    />
                  )
                )}
              </Form.Item>
            </Col>
            {!!(getFieldValue('dcModelIds') || []).length && (
              <Col span={24}>
                <Form.Item label="电池数">
                  {modalType === 3 ? (
                    <span>{initialValues.dcNum}</span>
                  ) : (
                    getFieldDecorator('dcNum', {
                      initialValue: initialValues.dcNum,
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          message: '请输入正确的电池数',
                          pattern: /^[+]{0,1}(\d+)$/,
                        },
                        { required: true, message: '请输入电池数！' },
                      ],
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="套餐金额">
                {modalType === 3 ? (
                  <span>{initialValues.money}</span>
                ) : (
                  getFieldDecorator('money', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        message: '请输入正确的金额（保留两位小数）',
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                      },
                      { required: true, message: '请输入套餐金额！' },
                    ],
                    initialValue: initialValues.money,
                  })(
                    <Input
                      onChange={handleMoney}
                      allowClear
                      placeholder="请输入"
                    />
                  )
                )}
              </Form.Item>
            </Col>
            {getFieldValue('dcNum') && getFieldValue('bikeNum') && (
              <Col span={24}>
                <Form.Item label="电池金额">
                  {modalType === 3 ? (
                    <span>{initialValues.dcMoney}</span>
                  ) : (
                    getFieldDecorator('dcMoney', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          message: '请输入正确的金额（保留两位小数）',
                          pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        },
                      ],
                      initialValue: initialValues.dcMoney,
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="押金类型">
                {modalType === 3 ? (
                  <span>
                    {initialValues.authType === 1
                      ? '支付宝预授权'
                      : initialValues.authType === 2
                      ? '现金押金'
                      : initialValues.authType === 3
                      ? '免押金'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('authType', {
                    rules: [
                      {
                        required: true,
                        message: '请选择押金类型！',
                      },
                    ],
                    initialValue: initialValues.authType,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>支付宝预授权</Option>
                      <Option value={2}>现金押金</Option>
                      <Option value={3}>免押金</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            {getFieldValue('authType') !== 3 && (
              <Col span={24}>
                <Form.Item label="押金金额">
                  {modalType === 3 ? (
                    <span>{initialValues.authMoney}</span>
                  ) : (
                    getFieldDecorator('authMoney', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          message: '请输入正确的金额（保留两位小数）',
                          pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        },
                        { required: true, message: '请输入押金金额！' },
                      ],
                      initialValue: initialValues.authMoney,
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="免赔服务">
                {modalType === 3 ? (
                  <span>{initialValues.insureMoney}</span>
                ) : (
                  getFieldDecorator('insureMoney', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        message: '请输入正确的金额（保留两位小数）',
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                      },
                      { required: true, message: '请输入免赔服务！' },
                    ],
                    initialValue: initialValues.insureMoney,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            {showPeriodList() && (
              <Col span={24}>
                <Form.Item label="用户可选期数">
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    {periodList.map((item, index) => {
                      return (
                        <div className="periodList" key={index}>
                          <InputNumber
                            size="small"
                            style={{ width: 60, margin: '5px 0' }}
                            value={item.period}
                            onChange={(value) => {
                              handlePeriodChange('period', index, value)
                            }}
                            allowClear
                            placeholder="期数"
                            min={1}
                            max={12}
                            precision={0}
                            disabled={modalType === 3}
                          />
                          <span style={{ padding: '0 8px' }}>期</span>
                          <InputNumber
                            size="small"
                            style={{ width: 60, margin: '5px 0' }}
                            value={item.price}
                            onChange={(value) => {
                              handlePeriodChange('price', index, value)
                            }}
                            allowClear
                            placeholder="金额"
                            min={item.period * getFieldValue('money') * 0.6}
                            max={item.period * getFieldValue('money')}
                            precision={2}
                            disabled={modalType === 3}
                          />
                          <span style={{ padding: '0 8px' }}>元</span>
                          {periodList.length > 1 && modalType !== 3 && (
                            <Icon
                              onClick={() =>
                                setPeriodList((value) =>
                                  value.filter((_, ind) => ind != index)
                                )
                              }
                              type="close-circle"
                              theme="filled"
                              style={{ color: '#ccc' }}
                            />
                          )}
                        </div>
                      )
                    })}
                    {modalType !== 3 && periodList.length < 3 && (
                      <Button
                        type="link"
                        icon="plus-circle"
                        onClick={handlePeriodAdd}
                      >
                        新增
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Col>
            )}
            {/* <Col span={24}>
                <Form.Item label="身份验证">
                  {modalType === 3 ? (
                    <span>
                      {initialValues.needCertify === 1
                        ? "需要"
                        : initialValues.needCertify === 0
                        ? "不需要"
                        : null}
                    </span>
                  ) : (
                    getFieldDecorator("needCertify", {
                      initialValue:
                        typeof initialValues.needCertify === "number"
                          ? initialValues.needCertify
                          : 0,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={1}>需要</Option>
                        <Option value={0}>不需要</Option>
                      </Select>
                    )
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="tag">
                  {modalType === 3 ? (
                    <span>{initialValues.tag}</span>
                  ) : (
                    getFieldDecorator("tag", {
                      initialValue: initialValues.tag,
                    })(<Input allowClear placeholder="请输入" />)
                  )}
                </Form.Item>
              </Col> */}
            <Col span={24}>
              <Form.Item label="地区">
                {modalType === 3 ? (
                  <span>{initialValues.areaName}</span>
                ) : (
                  getFieldDecorator('areaList', {
                    initialValue: initialValues.areaList,
                    rules: [
                      {
                        required: modalType === 1 ? true : false,
                        message: '请选择地区！',
                      },
                    ],
                  })(<CityCascader changeOnSelect />)
                )}
              </Form.Item>
            </Col>
            {/* <Col span={24}>
                <Form.Item label="是否检修">
                  {modalType === 3 ? (
                    <span>
                      {initialValues.needRepair === 1
                        ? "是"
                        : initialValues.needRepair === 0
                        ? "否"
                        : null}
                    </span>
                  ) : (
                    getFieldDecorator("needRepair", {
                      initialValue:
                        typeof initialValues.needRepair === "number"
                          ? initialValues.needRepair
                          : 0,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={1}>是</Option>
                        <Option value={0}>否</Option>
                      </Select>
                    )
                  )}
                </Form.Item>
              </Col> */}
            <Col span={24}>
              <Form.Item label="是否原站点退车">
                {modalType === 3 ? (
                  <span>
                    {initialValues.returnType === 1
                      ? '是'
                      : initialValues.returnType === 2
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('returnType', {
                    initialValue:
                      typeof initialValues.returnType !== 'number' &&
                      modalType === 1
                        ? 1
                        : initialValues.returnType,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={2}>否（在站点绑定中选择退车站点）</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="是否原站点换车">
                {modalType === 3 ? (
                  <span>
                    {initialValues.transferType === 1
                      ? '是'
                      : initialValues.transferType === 2
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('transferType', {
                    initialValue:
                      typeof initialValues.transferType !== 'number' &&
                      modalType === 1
                        ? 1
                        : initialValues.transferType,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={2}>否（在站点绑定中选择换车站点）</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="换车是否需要维修">
                {modalType === 3 ? (
                  <span>
                    {initialValues.transferRepair === 1
                      ? '是'
                      : initialValues.transferRepair === 0
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('transferRepair', {
                    initialValue:
                      typeof initialValues.transferRepair !== 'number' &&
                      modalType === 1
                        ? 0
                        : initialValues.transferRepair,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={0}>否</Option>
                      <Option value={1}>是</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            {/* {getFieldValue("needRepair") == 1 && (
                <Col span={24}>
                  <Form.Item label="检修方式">
                    {modalType === 3 ? (
                      <span>
                        {initialValues.repairType === 1
                          ? "原站点检修"
                          : initialValues.repairType === 2
                          ? "退车点检修"
                          : null}
                      </span>
                    ) : (
                      getFieldDecorator("repairType", {
                        initialValue: initialValues.repairType,
                        rules: [
                          { required: true, message: "请选择检修方式！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                        >
                          <Option value={1}>原站点检修</Option>
                          <Option value={2}>退车点检修</Option>
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
              )} */}
            {/* <Col
                span={24}
                style={{
                  display:
                    getFieldValue("needRepair") == 1 &&
                    getFieldValue("repairType") == 2
                      ? "block"
                      : "none",
                }}
              >
                <Form.Item label="退车点">
                  {getFieldDecorator("rids", {
                    initialValue: initialValues.rids,
                  })(
                    <RepairCheckbox
                      disabled={modalType === 3}
                      area={getFieldValue("areaList")}
                    />
                  )}
                </Form.Item>
              </Col> */}
            <Col span={24}>
              <Form.Item label="缩略图">
                {getFieldDecorator('thumb', {
                  initialValue: initialValues.thumb || undefined,
                  rules: [{ required: true, message: '请上传缩略图！' }],
                  getValueFromEvent: normFile,
                })(
                  <Upload
                    disabled={modalType === 3}
                    name="uploadFile"
                    multiple={true}
                    action={_imageUpload}
                    listType="picture-card"
                    fileList={fileList}
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    onChange={(info) => handleUploadChange('thumb', info)}
                    beforeUpload={handleBeforeUpload}
                    data={{
                      scene: 'active',
                    }}
                  >
                    {fileList.length >= 5 ? null : (
                      <div>
                        <Icon type="plus" style={{ fontSize: '28px' }} />
                        <p>图片上传</p>
                      </div>
                    )}
                  </Upload>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="选择主图模版">
                <div className="model_wrap">
                  {modelList.map((item, index) => (
                    <div
                      className={
                        'model_box ' +
                        (activity.modelId == item.id ? 'model_active' : '')
                      }
                      key={index}
                      onClick={() =>
                        setActivity((value) => ({
                          ...value,
                          modelId: item.id,
                        }))
                      }
                    >
                      <img
                        className="model_img"
                        src={require(`@/statics/img/model/model_${item.id}.jpg`)}
                        alt=""
                      />
                      <div className="model_cover">
                        <Icon
                          type="eye"
                          style={{ color: '#fff', fontSize: '20px' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            handlePreviewModel(item)
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <br />
                {modalType !== 3 && (
                  <Checkbox.Group
                    style={{ width: '100%' }}
                    value={activity.checked}
                    onChange={handleCheckChange}
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={1}></Checkbox>&nbsp;
                              <span>买点文案1</span>
                            </span>
                          }
                        >
                          <Input
                            value={activity.title1}
                            onChange={(e) => handleTextChange('title1', e)}
                            placeholder="限6个字符"
                            maxLength={6}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={2}></Checkbox>&nbsp;
                              <span>买点文案2</span>
                            </span>
                          }
                        >
                          <Input
                            value={activity.title2}
                            onChange={(e) => handleTextChange('title2', e)}
                            placeholder="限6个字符"
                            maxLength={6}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={3}></Checkbox>&nbsp;
                              <span>买点文案3</span>
                            </span>
                          }
                        >
                          <Input
                            value={activity.title3}
                            onChange={(e) => handleTextChange('title3', e)}
                            placeholder="限6个字符"
                            maxLength={6}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={16}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={4}></Checkbox>&nbsp;
                              <span>活动文案</span>
                            </span>
                          }
                        >
                          <Input
                            value={activity.des}
                            onChange={(e) => handleTextChange('des', e)}
                            placeholder="请输入不超过24个字符的活动文案"
                            maxLength={24}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item
                          label={
                            <span>
                              <span>套餐金额</span>
                            </span>
                          }
                        >
                          <span>{getFieldValue('money')}</span>
                        </Form.Item>
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={6}></Checkbox>&nbsp;
                              <span>套餐原价</span>
                            </span>
                          }
                        >
                          <InputNumber
                            value={activity.originMoney}
                            onChange={(val) =>
                              handleNumberChange('originMoney', val)
                            }
                            min={0}
                            max={99999}
                            step={0.1}
                          />
                          <span style={{ color: 'gray' }}>
                            &nbsp;套餐原价不得超过套餐金额的130%
                          </span>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={16}>
                        <Form.Item
                          label={
                            <span>
                              <Checkbox value={7}></Checkbox>&nbsp;
                              <span>添加logo</span>
                            </span>
                          }
                        >
                          <div className="logo_wrap">
                            <Upload
                              className="logo_upload"
                              name="uploadFile"
                              multiple={false}
                              showUploadList={false}
                              action={_imageUpload}
                              listType="picture-card"
                              accept="image/jpg,image/jpeg,image/png,image/gif"
                              onChange={(info) =>
                                handleUploadChange('logo', info)
                              }
                              data={{
                                scene: 'active',
                              }}
                            >
                              {activity.logo.url ? (
                                <img
                                  src={activity.logo.url}
                                  alt="avatar"
                                  style={{ width: '100%' }}
                                />
                              ) : (
                                <div>
                                  <Icon
                                    type="plus"
                                    style={{ fontSize: '28px' }}
                                  />
                                  <p>图片上传</p>
                                </div>
                              )}
                            </Upload>
                            <img
                              className="default_logo"
                              src={require('@/statics/img/model/uma_logo.jpg')}
                              alt=""
                            />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="套餐内容">
                {getFieldDecorator('content', {
                  initialValue: initialValues.content || undefined,
                  getValueFromEvent: normFile,
                })(
                  <Upload
                    disabled={modalType === 3}
                    name="uploadFile"
                    multiple={true}
                    action={_imageUpload}
                    listType="picture-card"
                    fileList={fileList1}
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    onChange={(info) => handleUploadChange('content', info)}
                    data={{
                      scene: 'active',
                    }}
                  >
                    {fileList1.length >= 5 ? null : (
                      <div>
                        <Icon type="plus" style={{ fontSize: '28px' }} />
                        <p>图片上传</p>
                      </div>
                    )}
                  </Upload>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="排序">
                {modalType === 3 ? (
                  <span>{initialValues.orderNum}</span>
                ) : (
                  getFieldDecorator('orderNum', {
                    initialValue: initialValues.orderNum,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="是否平台套餐">
                {modalType === 3 ? (
                  <span>
                    {initialValues.self === 1
                      ? '是'
                      : initialValues.self === 0
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('self', {
                    initialValue:
                      typeof initialValues.self === 'number'
                        ? initialValues.self
                        : 0,
                    rules: [
                      { required: true, message: '请选择是否平台套餐！' },
                    ],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={0}>否</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="是否首页显示">
                {modalType === 3 ? (
                  <span>
                    {initialValues.showStatus === 1
                      ? '是'
                      : initialValues.showStatus === 0
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('showStatus', {
                    initialValue:
                      typeof initialValues.showStatus === 'number'
                        ? initialValues.showStatus
                        : 0,
                    rules: [
                      { required: true, message: '请选择是否首页显示！' },
                    ],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={0}>否</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="是否站点套餐显示">
                {modalType === 3 ? (
                  <span>
                    {initialValues.showSite === 1
                      ? '是'
                      : initialValues.showSite === 0
                      ? '否'
                      : null}
                  </span>
                ) : (
                  getFieldDecorator('showSite', {
                    initialValue:
                      typeof initialValues.showSite === 'number'
                        ? initialValues.showSite
                        : 0,
                    rules: [
                      {
                        required: true,
                        message: '请选择是否站点套餐显示！',
                      },
                    ],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      allowClear
                    >
                      <Option value={1}>是</Option>
                      <Option value={0}>否</Option>
                    </Select>
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="管理状态">
                {getFieldDecorator('pstatus', {
                  initialValue: !!initialValues.pstatus,
                  valuePropName: 'checked',
                })(
                  <Switch
                    disabled={modalType === 3}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    )
  })
)

export default Index
