import React, { Component } from "react";
import { Input, Button, message, Form } from "antd";
import { _modifyPassword } from "@/statics/js/api";
import md5 from "md5";
import "./style.less";

class ChangePwd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="当前密码">
          {getFieldDecorator("oldPassword", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "请输入您正确的密码（6到16个字符）！",
                pattern: /^\w{6,16}$/,
              },
            ],
          })(<Input allowClear type="password" placeholder="密码" />)}
        </Form.Item>
        <Form.Item label="新密码">
          {getFieldDecorator("password", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "请输入您正确的密码（6位个字符）！",
                pattern: /^\w{6}$/,
              },
            ],
          })(<Input allowClear type="password" placeholder="密码" />)}
        </Form.Item>
        <Form.Item label="确认新密码">
          {getFieldDecorator("checkPassword", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "请输入您正确的密码（6位字符）！",
                pattern: /^\w{6}$/,
              },
            ],
          })(<Input allowClear type="password" placeholder="密码" />)}
        </Form.Item>
        <Form.Item>
          <Button className="formBtn" type="primary" htmlType="submit">
            确定修改
          </Button>
        </Form.Item>
      </Form>
    );
  }
  render() {
    return (
      <div className="ChangePwd">
        <h1 className="title">修改密码</h1>
        {this.renderForm()}
      </div>
    );
  }

  componentDidMount() {}

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.password === values.checkPassword) {
        if (values.password === values.oldPassword) {
          message.warning("新旧密码请保持不一致！");
        } else {
          values.password = md5(values.password);
          values.oldPassword = md5(values.oldPassword);
          delete values.checkPassword;
          let params = {
            id: localStorage.getItem("userId"),
            ...values,
          };
          _modifyPassword(params)
            .then((res) => {
              if (res.data.code === 200) {
                message.success("操作成功！");
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              } else {
                message.warning(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        message.warning("请确认两次新密码是否填写一致！");
      }
    });
  };
}

ChangePwd = Form.create({})(ChangePwd);
export { ChangePwd };
