import React, { Component } from "react";
import {
  Button,
  Table,
  DatePicker,
  Input,
  Select,
  InputNumber,
  Modal,
  Form,
  Row,
  Col,
  message,
} from "antd";
import {
  _franchiseeList,
  _bikeRepairDetailList,
  _siteRepairList,
  _exportBikeRepairDetailList,
  _updateBikeRepairDetailList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import queryString from 'query-string';
const RangePicker = DatePicker.RangePicker;
const { Option } = Select;
class BikeRepairDetailList extends Component {
  constructor(props) {
    super(props);
    const { orderSn, imei, repairNo } = queryString.parse(this.props.location.search);
    this.state = {
      queryFormValues: {
        orderSn: orderSn,
        imei: imei,
        repairNo: repairNo,
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      bikeRepairList: [], //车辆检修列表
      franchiseeList: [], //代理商列表
      repairSiteList: [], //退车点列表
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "维修点",
        dataIndex: "rname",
      },
      {
        title: "检修名称",
        dataIndex: "name",
      },
      {
        title: "是否检修",
        dataIndex: "needRepair",
        render: (text) => (text === 0 ? "否" : "是"),
      },
      {
        title: "检修费用",
        align: "right",
        width: 100,
        dataIndex: "amount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "车辆信息",
        align: "right",
        dataIndex: "imei",
      },
      {
        title: "订单号",
        align: "right",
        dataIndex: "orderSn",
      },
      {
        title: "骑手信息",
        width: 130,
        dataIndex: "",
        render: (text) => text.realname + text.mobile,
      },
      {
        title: "维修状态",
        dataIndex: "repairStatus",
        render: (text) => (text === 1 ? "已完成" : "未完成"),
      },
      {
        title: "维修单状态",
        dataIndex: "status",
        render: (text) =>
          text === 0 ? "待维修" : text === 1 ? "维修中" : "已完成",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "维修单号",
        align: "right",
        dataIndex: "repairNo",
      },
      {
        title: "维修单备注",
        dataIndex: "repairRemark",
      },
      {
        title: "创建时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "转为可租赁时间",
        width: 140,
        dataIndex: "rentTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "维修项完成时间",
        width: 120,
        dataIndex: "finishTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "维修单完成时间",
        width: 140,
        dataIndex: "finishTimeWx",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "支付状态",
        dataIndex: "payState",
        render: (text) => (text === 0 ? "未支付" : text === 1 ? "已支付" : ""),
      },
      {
        title: "支付状态",
        dataIndex: "payState",
        render: (text) => (text === 0 ? "未支付" : text === 1 ? "已支付" : ""),
      },
      {
        title: "支付时间",
        width: 120,
        dataIndex: "payTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "流水号",
        align: "right",
        dataIndex: "tradeNo",
      },
      {
        title: "操作",
        dataIndex: "",
        width: 80,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <Button
            type="link"
            disabled={!getJurisdiction(899)}
            title={getJurisdiction(899) ? "" : "暂无操作权限！"}
            onClick={this.showModifyAmountConfirm.bind(this, { ...text })}
          >
            修改
          </Button>
        ),
      },
    ];
    localStorage.getItem("type") !== "0" && this.columns.splice(-1, 1);
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    onChange={this.getRepairSiteList.bind(this)}
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="退车点">
              {getFieldDecorator("rid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {this.state.repairSiteList.length &&
                    this.state.repairSiteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn", {
                initialValue: this.state.queryFormValues.orderSn,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="维修单号">
              {getFieldDecorator("repairNo", {
                initialValue: this.state.queryFormValues.repairNo,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆信息">
              {getFieldDecorator("imei", {
                initialValue: this.state.queryFormValues.imei,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户信息">
              {getFieldDecorator("userInfo")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="下单时间">
              {getFieldDecorator("addTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="支付时间">
              {getFieldDecorator("payTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="支付状态">
              {getFieldDecorator("payState")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>已支付</Option>
                  <Option value={0}>未支付</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否检修">
              {getFieldDecorator("needRepair")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>是</Option>
                  <Option value={0}>否</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="维修状态">
              {getFieldDecorator("repairStatus")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>已完成</Option>
                  <Option value={0}>未完成</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="维修单状态">
              {getFieldDecorator("status")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                >
                  <Option value="">全部</Option>
                  <Option value={0}>待维修</Option>
                  <Option value={1}>维修中</Option>
                  <Option value={2}>已完成</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="检测名称">
              {getFieldDecorator("name")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(898)}
                title={getJurisdiction(898) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { totalCount, curPage, pageSize, bikeRepairList } = this.state;
    return (
      <div>
        {!!getJurisdiction(800) ? (
          <div className="BikeRepairDetailList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={bikeRepairList}
                scroll={{ x: 2500, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    localStorage.getItem("type") === "1"
      ? this.getRepairSiteList(localStorage.getItem("fid"))
      : this.getFranchiseeList();
    this.getBikeRepairList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getRepairSiteList(value) {
    // 切换代理商时清空已选站点
    this.props.form.setFieldsValue({
      rid: undefined,
    });
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteRepairList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            repairSiteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车辆检修列表
  getBikeRepairList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _bikeRepairDetailList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeRepairList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getBikeRepairList(curPage, pageSize, this.state.queryFormValues);
  }

  //修改检修项金额
  showModifyAmountConfirm({ id, orderSn, imei, name, amount }) {
    let self = this;
    Modal.confirm({
      title: "修改车辆维修项详情",
      content: (
        <div style={{ marginTop: 30 }}>
          <Form.Item label="订单编号">{orderSn}</Form.Item>
          <Form.Item label="车辆信息">{imei}</Form.Item>
          <Form.Item label="检修名称">{name}</Form.Item>
          <Form.Item label="检修金额">
            <InputNumber
              style={{ width: "100%" }}
              onChange={(value) => {
                amount = value;
              }}
              placeholder={amount}
              min={0}
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = {
          id,
          amount,
        };
        _updateBikeRepairDetailList(params).then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.message);
            self.getBikeRepairList(
              self.state.curPage,
              self.state.pageSize,
              self.state.queryFormValues
            );
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.startTime = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payStartTime = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payEndTime = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      this.setState({
        queryFormValues: values,
      });
      this.getBikeRepairList(1, this.state.pageSize, values);
    });
  };

  // 表单筛选
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.addTime && values.addTime.length) {
        values.startTime = moment(values.addTime[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.addTime[1]).format("YYYY-MM-DD");
      }
      delete values.addTime;
      if (values.payTime && values.payTime.length) {
        values.payStartTime = moment(values.payTime[0]).format("YYYY-MM-DD");
        values.payEndTime = moment(values.payTime[1]).format("YYYY-MM-DD");
      }
      delete values.payTime;
      let params = {
        ...values,
      };
      _exportBikeRepairDetailList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

BikeRepairDetailList = Form.create({})(BikeRepairDetailList);
export { BikeRepairDetailList };
