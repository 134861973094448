import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import FranchiseeSelect from '@/components/franchiseeSelect'
import moment from 'moment';
import {
    _operateOutOrderHandleList,
    _operateOutOrderHandleExport,
    _operateMonthOutOrderHandleList,
    _operateMonthOutOrderHandleListExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Row,
    Col,
    DatePicker
} from "antd";
const {WeekPicker} = DatePicker

const columns1 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "未处理订单数",
        dataIndex: "noHandle",
    },
    {
        title: "未处理率",
        dataIndex: "noHandleEfficiency",
    },
    {
        title: "未处理率环比",
        dataIndex: "noHandleScale",
    },
    {
        title: "已处理订单数",
        dataIndex: "yesHandle",
    },
    {
        title: "已处理率",
        dataIndex: "yesHandleEfficiency",
    },
    {
        title: "已处理率环比",
        dataIndex: "yesHandleScale",
    },
    {
        title: "处理为“超时未挂起”订单数",
        dataIndex: "outNoHangUp",
    },
    {
        title: "处理为“超时未挂起”订单数的占比",
        dataIndex: "outNoHangUpEfficiency",
    },
    {
        title: "处理为“超时未挂起”订单数的占比环比",
        dataIndex: "outNoHangUpScale",
    },
    {
        title: "处理为“超时已挂起”订单数",
        dataIndex: "outHangUp",
    },
    {
        title: "处理为“超时已挂起”订单数的占比",
        dataIndex: "outHangUpEfficiency",
    },
    {
        title: "处理为“超时已挂起”订单数的占比环比",
        dataIndex: "outHangUpScale",
    },
]

const columns2 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "处理数",
        dataIndex: "yesHandle",
    },
    {
        title: "总超时",
        dataIndex: "outOrderSum",
    },
    {
        title: "处理率",
        dataIndex: "yesHandleEfficiency",
    },
    {
        title: "小于等于1天处理数",
        dataIndex: "smallDay",
    },
    {
        title: "大于1天的处理数",
        dataIndex: "bigDay",
    },
]

const Index1 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week} = values
            let time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week} = values
        let time = {
            startTime: moment(week).day(1).format('YYYY-MM-DD'),
            endTime: moment(week).day(7).format('YYYY-MM-DD'),
        }
        handleDownload({
            action: _operateOutOrderHandleExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间范围">
                        {getFieldDecorator("week",{
                            initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                        })(
                            <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                        )}
                    </Form.Item>
                </Col>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为周维度</p>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateOutOrderHandleList}
            options={{
                columns: columns1
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index2 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            tableRef.current.handleSearch(values)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        handleDownload({
            action: _operateMonthOutOrderHandleListExport,
            query: values
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为月维度</p>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateMonthOutOrderHandleList}
            options={{
                columns: columns2
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index = ()=>{
    return (<>
        <Index1 />
        <br />
        <Index2 />
    </>)
}

export default Index