import Axios from "axios";
// import { message } from "antd";
// import { browserHistory } from "react-router";
import { AXIOS_URL } from "./config";

const axios = Axios.create({
  timeout: 300000,
  baseURL: AXIOS_URL,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});

// 请求前拦截
axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = localStorage.getItem("authorization");
    return config;
  },
  (err) => {
    console.log("请求超时");
    return Promise.reject(err);
  }
);

// 返回后拦截
axios.interceptors.response.use(
  (data) => {
    if (data.data.code === 1005) {
      console.log(data.data.code);
      setTimeout(function () {
        window._history.push({ pathname: "/login" });
        localStorage.clear();
      }, 1000);
    }
    return data;
  },
  (err) => {
    if (err.response.status === 504 || err.response.status === 404) {
      console.log("服务器被吃了⊙﹏⊙∥");
    } else if (err.response.status === 401) {
      console.log("登录信息失效⊙﹏⊙∥");
    } else if (err.response.status === 500) {
      console.log("服务器开小差了⊙﹏⊙∥");
    }
    // message.warning(err);
    return Promise.reject(err);
  }
);
export default axios;

// import axios from 'axios'
// import EmitEventBus from '../../yui/model/emit-event-bus'
// import md5 from 'js-md5'

// const API_BASE = ''
// const MOCK_BASE = ' https://mock.yunxi.tv/mock/5f4df7d4de6a7400223b1d01'

// const secretKey = 'af28d755cca8992c' // 本地生成的 secret_key

// const STATUS_CODE = {
//   UNKNOWN: -1,
//   PARSE_JSON: -501,
//   SUCCEED: 200,
//   UNAUTHORIZED: 401,
//   FORBIDDEN: 403,
//   REALAUTH: -1003 // 账户封禁，需要实名认证
// }

// function ApiResult(statusCode, data, message, result) {
//   Object.assign(this, result)

//   this.statusCode = statusCode
//   this.data = data || {}
//   this.message = message || ''
// }

// ApiResult.prototype.succeed = function() {
//   return this.statusCode === STATUS_CODE.SUCCEED || this.statusCode === STATUS_CODE.SUCCEED_OLD
// }

// ApiResult.prototype.fail = function() {
//   if (this.statusCode === STATUS_CODE.REALAUTH) {
//     EmitEventBus.$emit(EmitEventBus.startRealAuth)
//   }
//   return !this.succeed()
// }

// ApiResult.prototype.notAuthorized = function() {
//   return this.statusCode === STATUS_CODE.UNAUTHORIZED
// }

// // md5加密
// function encrypting(data) {
//   const timestamp = Math.floor(Date.now() / 1000) + (window.__ts_offset__ || 0)
//   const keys = Object.keys(data || {}).sort()
//   const sign = keys.reduce((str, key) => {
//     if (data[key]) {
//       str += `&${key}=${data[key]}`
//     }
//     return str
//   }, `apiKey=${secretKey}`)
//   return {
//     yx: md5(`${sign}&ts=${timestamp}`),
//     ts: timestamp
//   }
// }

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// function handleResponse(response) {
//   if (response.status !== 200) {
//     var message = '请求出错，请稍后重试。'
//     switch (response.status) {
//       case 401:
//         message = '登录超时，请刷新页面后重试登录。'
//         break
//       case 302:
//       case 404:
//         message = '页面找不到了，请刷新页面后重试。'
//         break
//       case 501:
//         message = '服务器开小差了，请稍后重试。'
//         break
//     }
//     return new ApiResult(response.status, response.data, message, response)
//   }

//   var result = response.data
//   if (typeof result === 'string') {
//     try {
//       result = JSON.parse(result)
//     } catch (e) {
//       return new ApiResult(STATUS_CODE.PARSE_JSON, {}, '解析数据出错，请稍后重试。')
//     }
//   }

//   if (typeof result.statusCode === 'undefined') {
//     result.statusCode = result.code
//   }

//   if (result.statusCode != 200) {
//     return new ApiResult(result.statusCode, result, result.message, result)
//   }
//   return new ApiResult(result.statusCode, result.data, result.message, result)
// }

// export async function get(apiName, params, mock = false) {
//   try {
//     let base = API_BASE
//     if (process.env.PRO_ENV === 'development' && mock) {
//       base = MOCK_BASE
//     }
//     const response = await axios({
//       method: 'get',
//       url: base + apiName,
//       headers: {
//         'X-Requested-With': 'XMLHttpRequest'
//       },
//       params: {
//         ...params,
//         ...encrypting(params)
//       }
//     })
//     return handleResponse(response)
//   } catch (e) {
//     var resp = e.response
//     if (resp) {
//       return new ApiResult(resp.status, e, resp.statusText)
//     }
//     return new ApiResult(-1, e, '请求失败，请稍后重试。')
//   }
// }

// export async function post(apiName, data, mock = false) {
//   try {
//     const base = mock ? MOCK_BASE : API_BASE
//     var formData = new FormData()
//     for (var key in data) {
//       formData.append(key, data[key])
//     }

//     var response = await axios({
//       method: 'post',
//       url: base + apiName,
//       headers: {
//         'X-Requested-With': 'XMLHttpRequest'
//       },
//       params: encrypting(data),
//       data: formData
//     })
//     return handleResponse(response)
//   } catch (e) {
//     var resp = e.response
//     if (resp) {
//       return new ApiResult(resp.status, e, resp.statusText)
//     }
//     return new ApiResult(-1, {}, '请求失败，请稍后重试。')
//   }
// }
