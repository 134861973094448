import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Table,
  Divider,
  Tabs,
  Select,
  Form,
  Input,
  DatePicker,
  Modal,
  message,
  Row,
  Col,
} from 'antd'
import {
  _billingManagementList,
  _bikeModelList,
  _franchiseeList,
  _bikeOrderList,
  _siteBoxList,
  _exportPackageOrderBill,
  _batteryOrderList,
  _packageOrderLogList,
  _orderChangeList,
  _orderDeviceImages,
  _orderRefundLogs,
} from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import moment from 'moment'
import queryString from 'query-string'

const Option = Select.Option
const TabPane = Tabs.TabPane
const RangePicker = DatePicker.RangePicker
class CapitalistsBillManagement extends Component {
  constructor(props) {
    super(props)
    const { orderSn } = queryString.parse(this.props.location.search)
    this.state = {
      queryFormValues: {
        orderSn: orderSn,
      },
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packageOrderList: [], //套餐订单列表
      bikeModelList: [], //车辆型号列表
      siteList: [], //站点列表
      franchiseeList: [], //代理商列表
      bikeOrderList: [], //车辆订单列表
      batteryOrderList: [], //电池订单列表
      packageOrderLogList: [], //订单续租列表
      orderChangeList: [], //订单变更列表
      orderRefundLogs: [], //退款记录列表
      visible: false,
      visibleSingle: false,
    }
    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '订单编号',
        align: 'right',
        width: 180,
        dataIndex: 'orderSn',
      },
      {
        title: '订单类型',
        dataIndex: 'isNewOrder',
        render: (text) => (text === 1 ? '新增' : '续租'),
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点',
        dataIndex: 'sname',
      },
      {
        title: '套餐名',
        dataIndex: 'pname',
      },
      {
        title: '套餐类型',
        dataIndex: 'ptypeName',
      },
      {
        title: '套餐金额',
        align: 'right',
        width: 100,
        dataIndex: 'pmoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '实付金额',
        align: 'right',
        width: 100,
        dataIndex: 'payMoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '套餐费用构成',
        width: 175,
        dataIndex: 'settlementRules',
        render: (text) => (
          <span>
            {!!text && !!text.bikeMoney && '车辆：' + text.bikeMoney}
            {!!text && !!text.bikeMoney && <br />}
            {!!text && !!text.dcMoney && '电池：' + text.dcMoney}
            {!!text && !!text.dcMoney && <br />}
            {!!text && !!text.platMoney && '技术服务费：' + text.platMoney}
          </span>
        ),
      },
      {
        title: '在租车辆',
        dataIndex: 'bikeVinCode',
        render: (text) => (text ? text : '无'),
      },
      {
        title: '推荐人',
        dataIndex: 'tjMobile',
        render: (text) => (text ? text : '无'),
      },
      {
        title: '用户',
        dataIndex: '',
        render: (text) => text.username + '(' + text.kid + ')',
      },
      {
        title: '手机号',
        align: 'right',
        width: 130,
        dataIndex: 'mobile',
      },
      {
        title: '订单时间',
        width: 120,
        dataIndex: 'addtime',
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '支付时间',
        width: 120,
        dataIndex: 'logAddTime',
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '租赁时间',
        width: 135,
        dataIndex: '',
        render: (text) => (
          <span>
            {text.startDate && moment.unix(text.startDate).format('YYYY-MM-DD')}
            <br />～<br />
            {text.endDate && moment.unix(text.endDate).format('YYYY-MM-DD')}
          </span>
        ),
      },
      {
        title: '押金类型',
        width: 120,
        dataIndex: 'authType',
        render: (text) =>
          text === 1
            ? '支付宝预授权'
            : text === 2
            ? '现金押金'
            : text === 3
            ? '免押金'
            : null,
      },
      {
        title: '订单状态',
        dataIndex: 'orderStatus',
        width: 100,
        render: (text) =>
          text === 1
            ? '已下单'
            : text === 2
            ? '待提车'
            : text === 3
            ? '租车中'
            : text === 4
            ? '已超时'
            : text === 5
            ? '已完成'
            : text === 6
            ? '申请退租'
            : text === 7
            ? '待生效'
            : text === 8
            ? '已关闭'
            : text === 9
            ? '待签约'
            : '',
      },
      {
        title: '订单变更时间',
        dataIndex: 'changeTime',
        width: 120,
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '支付方式',
        dataIndex: 'payType',
        render: (text) => (
          <span>
            {text === 1
              ? '支付宝'
              : text === 2
              ? '花呗'
              : text === 3
              ? '余额支付'
              : ''}
          </span>
        ),
      },
      {
        title: '支付状态',
        dataIndex: 'payStat',
        width: 100,
        render: (text) =>
          text === 0
            ? '未支付'
            : text === 1
            ? '已经支付'
            : text === 3
            ? '退款'
            : '',
      },
      {
        title: '操作',
        dataIndex: '',
        width: 80,
        key: 'Action',
        fixed: 'right',
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(844)}
              title={getJurisdiction(844) ? '' : '暂无操作权限！'}
              onClick={this.showModal.bind(
                this,
                text.id,
                text.orderSn,
                text.orderStatus
              )}
            >
              详情
            </Button>
            {!!text.contractUrl && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(846)}
                  title={getJurisdiction(846) ? '' : '暂无操作权限！'}
                  target="_blank"
                  href={text.contractUrl}
                >
                  查看合同
                </Button>
              </span>
            )}
          </span>
        ),
      },
    ]
    this.logColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
        render: (text) => (text ? text : '无'),
      },
      {
        title: '订单起始时间',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.startDate && text.endDate
            ? moment.unix(text.startDate).format('YYYY.MM.DD') +
              '～' +
              moment.unix(text.endDate).format('YYYY.MM.DD')
            : '',
      },
      {
        title: '订单金额',
        align: 'right',
        dataIndex: 'money',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '套餐名',
        dataIndex: 'pname',
      },
      {
        title: '免赔服务',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.isInsure === 1 ? (
            <span>
              已购买
              <br />
              {text.startDate && text.endDate
                ? moment.unix(text.startDate).format('YYYY.MM.DD') +
                  '～' +
                  moment.unix(text.endDate).format('YYYY.MM.DD')
                : ''}
            </span>
          ) : (
            '未购买'
          ),
      },
      {
        title: '分成信息',
        dataIndex: '',
        width: 200,
        render: (text) => (
          <span>
            平台分成金额:{text.terraceMoney && text.terraceMoney.toFixed(2)}
            <br />
            代理商分成金额:
            {text.franchiseeMoney && text.franchiseeMoney.toFixed(2)}
            <br />
            站点分成金额:{text.siteMoney && text.siteMoney.toFixed(2)}
          </span>
        ),
      },
      {
        title: '支付时间',
        dataIndex: 'addTime',
        width: 120,
        render: (text) =>
          text && moment.unix(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '支付状态',
        dataIndex: 'payStat',
        render: (text) =>
          text === 0
            ? '未支付'
            : text === 1
            ? '已经支付'
            : text === 3
            ? '退款'
            : '',
      },
      {
        title: '逾期租金违约金',
        align: 'right',
        dataIndex: 'diffMoney',
        render: (text) => text && text.toFixed(2),
      },
    ]
    this.bikeColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '车辆信息',
        dataIndex: '',
        align: 'right',
        render: (text) => (text.bikeId ? text.vin : '未提车'),
      },
      {
        title: '车辆状态',
        dataIndex: '',
        render: (text) =>
          text.gps
            ? (text.lock ? '锁定，' : '未锁，') +
              (text.online ? '在线' : '离线')
            : '未知',
      },
      {
        title: '使用期限',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.createTime &&
          moment.unix(text.createTime).format('YYYY.MM.DD') +
            '~' +
            text.endTime &&
          moment.unix(text.endTime).format('YYYY.MM.DD'),
      },
      {
        title: '车辆更换信息',
        dataIndex: 'log',
        width: 200,
        render: (text) =>
          text ? (
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          ) : (
            '暂无'
          ),
      },
    ]
    this.batteryColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '电池信息',
        align: 'right',
        dataIndex: '',
        render: (text) => (text.batteryId ? text.iemi : '未提电池'),
      },
      {
        title: '使用期限',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.createTime &&
          moment.unix(text.createTime).format('YYYY.MM.DD') +
            '~' +
            text.endTime &&
          moment.unix(text.endTime).format('YYYY.MM.DD'),
      },
      {
        title: '电池更换信息',
        dataIndex: 'log',
        width: 200,
        render: (text) =>
          text ? (
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          ) : (
            '暂无'
          ),
      },
    ]
    this.orderChangeColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点名',
        dataIndex: 'sname',
      },
      {
        title: '原站点',
        dataIndex: 'preSName',
      },
      {
        title: '调单时间',
        width: 120,
        dataIndex: 'addTime',
        render: (text) =>
          text && moment.unix(text).format('YYYY.MM.DD HH:mm:ss'),
      },
    ]
    this.orderRefundLogs = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
      },
      {
        title: '订单金额',
        align: 'right',
        dataIndex: 'orderAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '退款金额',
        align: 'right',
        dataIndex: 'refundAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '退款状态',

        dataIndex: 'status',
        render: (text) => (text === 1 ? '成功' : '失败'),
      },
      {
        title: '退款时间',
        width: 120,
        dataIndex: 'refundTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '退款原因',
        dataIndex: 'refundReason',
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
    ]
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator('fid')(
                <Select
                  placeholder="请选择"
                  onChange={this.getSiteList.bind(this)}
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator('sid')(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.siteList.length &&
                    this.state.siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="下单时间">
              {getFieldDecorator('addTime')(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单变更时间">
              {getFieldDecorator('changeTime')(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator('orderSn', {
                initialValue: this.state.queryFormValues.orderSn,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="手机号">
              {getFieldDecorator('mobile')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单合同">
              {getFieldDecorator('hasContract')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="Y">已签</Option>
                  <Option value="N">未签</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单类型">
              {getFieldDecorator('isNewOrder')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>新增</Option>
                  <Option value={2}>续租</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆型号">
              {getFieldDecorator('modelId')(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.bikeModelList.length &&
                    this.state.bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(842)}
                title={getJurisdiction(842) ? '' : '暂无操作权限！'}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const { totalCount, curPage, pageSize, packageOrderList } = this.state
    return (
      <div>
        {getJurisdiction(841) ? (
          <div className="CapitalistsBillManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="logId"
                bordered
                columns={this.columns}
                dataSource={packageOrderList}
                scroll={{ x: 2600, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={`订单[${this.state.orderSnInfo}]详情`}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initModal.bind(this)}
            >
              <Tabs defaultActiveKey="1">
                {getJurisdiction(847) && (
                  <TabPane tab="订单续租列表" key="1">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.logColumns}
                      scroll={{ x: 1300, y: 500 }}
                      dataSource={this.state.packageOrderLogList}
                    />
                  </TabPane>
                )}
                {getJurisdiction(848) && (
                  <TabPane tab="车辆订单列表" key="2">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.bikeColumns}
                      scroll={{ x: 1000, y: 500 }}
                      dataSource={this.state.bikeOrderList}
                    />
                  </TabPane>
                )}
                {getJurisdiction(849) && (
                  <TabPane tab="电池订单列表" key="3">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.batteryColumns}
                      scroll={{ x: 750, y: 500 }}
                      dataSource={this.state.batteryOrderList}
                    />
                  </TabPane>
                )}
                {getJurisdiction(850) && (
                  <TabPane tab="调单记录列表" key="4">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.orderChangeColumns}
                      scroll={{ x: 750, y: 500 }}
                      dataSource={this.state.orderChangeList}
                    />
                  </TabPane>
                )}
                {getJurisdiction(851) && (
                  <TabPane tab="退款记录列表" key="5">
                    <Table
                      rowKey="id"
                      bordered
                      columns={this.orderRefundLogs}
                      scroll={{ x: 1000, y: 500 }}
                      dataSource={this.state.orderRefundLogs}
                    />
                  </TabPane>
                )}
                {getJurisdiction(852) && (
                  <TabPane tab="订单图片" key="6">
                    {!!this.state.fcImages && (
                      <div>
                        <h4
                          style={{
                            clear: 'both',
                            borderBottom: '1px solid #e8e8e8',
                            marginBottom: '10px',
                            paddingBottom: '10px',
                          }}
                        >
                          发车图片
                        </h4>
                        {this.state.fcImages.map((item, index) => {
                          return (
                            <Link target="_blank" key={index} to={item.hcImg}>
                              <img
                                key={index}
                                style={{
                                  width: 150,
                                  height: 150,
                                  margin: 10,
                                }}
                                src={item.hcImg}
                                alt=""
                              />
                            </Link>
                          )
                        })}
                      </div>
                    )}
                    {!!this.state.tcImages && (
                      <div>
                        <h4
                          style={{
                            clear: 'both',
                            borderBottom: '1px solid #e8e8e8',
                            marginBottom: '10px',
                            paddingBottom: '10px',
                          }}
                        >
                          退车图片
                        </h4>
                        {this.state.tcImages.map((item, index) => {
                          return (
                            <Link target="_blank" to={item.hcImg}>
                              <img
                                key={index}
                                style={{
                                  width: 150,
                                  height: 150,
                                  margin: 10,
                                }}
                                src={item.hcImg}
                                alt=""
                              />
                            </Link>
                          )
                        })}
                      </div>
                    )}
                    {!!this.state.hcImages &&
                      this.state.hcImages.map((item, index) => {
                        return (
                          <div key={index}>
                            <h4
                              style={{
                                clear: 'both',
                                borderBottom: '1px solid #e8e8e8',
                                marginBottom: '10px',
                                paddingBottom: '10px',
                              }}
                            >
                              {'新车架：' +
                                item.imei +
                                ' 更换时间 ：' +
                                moment
                                  .unix(item.addTime)
                                  .format('YYYY-MM-DD HH:mm:ss')}
                            </h4>
                            {item.hcImages.map((items, indexs) => {
                              return (
                                <Link target="_blank" to={item.hcImg}>
                                  <img
                                    key={indexs}
                                    style={{
                                      width: 150,
                                      height: 150,
                                      margin: 10,
                                    }}
                                    src={items}
                                    alt=""
                                  />
                                </Link>
                              )
                            })}
                          </div>
                        )
                      })}
                  </TabPane>
                )}
              </Tabs>
            </Modal>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getFranchiseeList()
    this.getBikeModelList()
    this.getPackageOrderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  //获取车辆型号列表
  getBikeModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _bikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeModelList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取套餐订单列表
  getPackageOrderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage,
      pageSize,
    }
    _billingManagementList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            packageOrderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageOrderList(curPage, pageSize, this.state.queryFormValues)
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取站点列表
  getSiteList(value) {
    this.props.form.setFieldsValue({
      sid: undefined,
    })
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    }
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.changeTime && values.changeTime.length) {
        values.changeTimeStart = moment(values.changeTime[0]).format(
          'YYYY-MM-DD'
        )
        values.changeTimeEnd = moment(values.changeTime[1]).format('YYYY-MM-DD')
      }
      delete values.changeTime
      this.setState({
        queryFormValues: values,
      })
      this.getPackageOrderList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.changeTime && values.changeTime.length) {
        values.changeTimeStart = moment(values.changeTime[0]).format(
          'YYYY-MM-DD'
        )
        values.changeTimeEnd = moment(values.changeTime[1]).format('YYYY-MM-DD')
      }
      delete values.changeTime
      let params = {
        ...values,
      }
      _exportPackageOrderBill(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  //获取车辆订单列表
  getBikeOrderList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _bikeOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeOrderList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取电池订单列表
  getBatteryOrderList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _batteryOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryOrderList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单续租列表
  getPackageOrderLogList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _packageOrderLogList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageOrderLogList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单图片
  getOrderDeviceImages(id) {
    let params = {
      id,
    }
    _orderDeviceImages(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            hcImages: res.data.data.hcImages,
            fcImages: res.data.data.fcImages,
            tcImages: res.data.data.tcImages,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单变更列表
  getOrderChangeList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderChangeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderChangeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取退款记录列表
  getOrderRefundLogs(orderId) {
    let params = {
      orderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderRefundLogs(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderRefundLogs: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handlRefundAmountChange(value) {
    this.setState({
      refundAmount: value,
    })
  }
  handlRefundReasonChange(e) {
    this.setState({
      refundReason: e.target.value,
    })
  }

  //Modal打开事件
  showModal(id, orderSnInfo, orderStatus) {
    this.getBikeOrderList(id)
    this.getBatteryOrderList(id)
    this.getPackageOrderLogList(id)
    this.getOrderDeviceImages(id)
    this.getOrderChangeList(id)
    this.getOrderRefundLogs(id)
    this.setState({
      orderSnInfo,
      orderStatus,
      visible: true,
    })
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault()
    this.setState({
      visible: false,
    })
  }

  //Modal取消事件
  initModal() {
    this.setState({
      visible: false,
    })
  }
}

CapitalistsBillManagement = Form.create({})(CapitalistsBillManagement)
export { CapitalistsBillManagement }
