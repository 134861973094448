import './index.less'
import React, {
  Component,
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Table,
  Divider,
  Tabs,
  Select,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Modal,
  Radio,
  message,
  Row,
  Col,
  Spin,
  Checkbox,
  Icon,
  Upload,
} from 'antd'
import TransferSelect from '@/components/transferSelect'
import {
  _packageOrderList,
  _sendInstructionBike,
  _devicePositionBike,
  _franchiseeList,
  _bikeOrderList,
  _siteBoxList,
  _packageBoxList,
  _packageList,
  _cancelDeductLog,
  _exportPackageOrder,
  _batteryOrderList,
  _packageOrderLogList,
  _orderChangeList,
  _orderDeviceImages,
  _orderRefundLogs,
  _orderRefund,
  _orderRefundDetails,
  _orderHangUp,
  _orderCancel,
  _orderChange,
  _cancelDayDeduct,
  _orderDiffInfo,
  _orderPenaltyInfo,
  _savePackageOrder,
  _performanceRecordList,
  _orderStatusLogRecordList,
  _siteRepairBoxList,
  _orderChangeSiteRepair,
  _exceptionOrderList,
  _authInfo,
  _orderRemarkList,
  _saveOrderRemark,
  _selectOrderChange,
  _dayDeductMonth,
  _dayDeductList,
  _modifyPrice,
  _imageUpload,
  _orderLiquidated,
  _newOrderHangHp,
  _cancelOrderHangHp,
} from '@/statics/js/api'
import { zipImg } from '@/statics/js/util'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import { Map, Marker } from 'react-amap'
import moment from 'moment'
import queryString from 'query-string'
import useList from '@/hooks/list'

const amapkey = '4792ad0f37a527c1535e83599762d894'
const Option = Select.Option
const TabPane = Tabs.TabPane
const RangePicker = DatePicker.RangePicker
const orderStatusItem = [
  { key: 1, title: '已下单' },
  { key: 2, title: '待提车' },
  { key: 3, title: '租车中' },
  { key: 4, title: '已超时' },
  { key: 5, title: '已完成' },
  { key: 6, title: '申请退租' },
  { key: 7, title: '待生效' },
  { key: 8, title: '已关闭' },
  { key: 9, title: '待签约' },
]

let HangupModal = Form.create({})(
  forwardRef((props, ref) => {
    const { form, done } = props
    const {
      getFieldDecorator,
      validateFields,
      resetFields,
      setFieldsValue,
      getFieldValue,
    } = form
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [id, setId] = useState('')
    const [repairList, setRepairList] = useState([])
    const [parkList, setParkList] = useState([])
    const [trailerList, setTrailerList] = useState([])
    const [ticketList, setTicketList] = useState([])
    const [deviceChecked, setDeviceChecked] = useState([])
    const [detail, setDetail] = useState({
      equipment: [],
      goods: [],
    })
    const { getHangSiteList, hangSiteList } = useList()

    const open = async ({ id }) => {
      setVisible(true)
      resetFields()
      setId(id)
      setLoading(false)
      setConfirmLoading(false)
      setCancelLoading(false)
      setDetail({
        equipment: [],
        goods: [],
      })
      getDetail(id)
      getHangSiteList({ orderId: id })
    }
    const getDetail = async (id) => {
      setLoading(true)
      const res = await _orderLiquidated({ orderId: id })
      const { code, data, message: msg } = res.data || {}
      if (code != 200) {
        message.warning(msg || '操作失败')
        return
      }
      if (!data) {
        message.warning('暂无数据')
        return
      }
      setLoading(false)
      try {
        data.goods = JSON.parse(data.goods) || []
      } catch (err) {
        data.goods = []
      }
      try {
        data.equipment = JSON.parse(data.equipment) || []
      } catch (err) {
        data.equipment = []
      }
      let checked = data.equipment.map((item) => item.id)
      setDeviceChecked(checked)
      setDetail(data)
      let fee = []
      data.parkingMoney && fee.push(1)
      data.towingMoney && fee.push(2)
      data.ticketMoney && fee.push(3)
      setFieldsValue({
        fee,
        lose: data.lose || 0,
      })
      setTimeout(() => {
        setFieldsValue({
          ...data,
          siteId: data.surrenderSiteVO.siteId,
        })
        if (data.lose) {
          data.goods.map((item, index) => {
            let info = data.equipment.find((row) => row.id == item.id)
            if (info) {
              setFieldsValue({
                [`loseMoney${index}`]: info.money,
              })
            }
          })
        }
      }, 10)
      setRepairList(getPicList(data.maintainPicture || ''))
      setParkList(getPicList(data.parkingPicture || ''))
      setTrailerList(getPicList(data.towingPicture || ''))
      setTicketList(getPicList(data.ticketPicture || ''))
    }
    const getPicList = (str) => {
      if (!str) {
        return []
      }
      let arr = str.split(',')
      return arr.map((item, index) => {
        const path = item.split('.com')[1] || ''
        return {
          uid: index,
          // name: index,
          status: 'done',
          url: item,
          path,
        }
      })
    }
    const totalMoney = (type) => {
      let { goods, packageType } = detail
      const fee = getFieldValue('fee') || []
      const overdueMoney = getFieldValue('overdueMoney') || 0
      const deditMoney = getFieldValue('deditMoney') || 0
      const dayOverdueMoney = getFieldValue('dayOverdueMoney') || 0
      const liquidatedMoney = getFieldValue('liquidatedMoney') || 0
      const maintainMoney = getFieldValue('maintainMoney') || 0
      const parkingMoney = fee.includes(1)
        ? getFieldValue('parkingMoney') || 0
        : 0
      const towingMoney = fee.includes(2)
        ? getFieldValue('towingMoney') || 0
        : 0
      const ticketMoney = fee.includes(3)
        ? getFieldValue('ticketMoney') || 0
        : 0
      let money =
        parseFloat(overdueMoney) +
        parseFloat(liquidatedMoney) +
        parseFloat(deditMoney) +
        parseFloat(dayOverdueMoney)
      if (type == 'deduct') {
        if (packageType == 1) {
          return 0
        } else {
          return money.toFixed(2)
        }
      }
      let loseMoney = 0
      goods.map((item, index) => {
        if (deviceChecked.includes(item.id)) {
          const m = getFieldValue(`loseMoney${index}`) || 0
          loseMoney += parseFloat(m || 0)
        }
      })
      let feeMoney =
        (parkingMoney * 1000 + towingMoney * 1000 + ticketMoney * 1000) / 1000
      let extraMoney =
        (maintainMoney * 1000 + feeMoney * 1000 + loseMoney * 1000) / 1000
      if (type == 'extra') {
        return extraMoney.toFixed(2)
      }
      money = parseFloat(money) + parseFloat(extraMoney)
      return money.toFixed(2)
    }
    const handleUploadChange = (type, { file, fileList }) => {
      fileList.map((item, index) => {
        if (item.status === 'error') {
          fileList.splice(index, 1)
        }
        if (item.response) {
          if (item.response.code !== 200) {
            message.warning(item.response.message)
            fileList.splice(index, 1)
          }
        }
        return item
      })
      if (type == 'repair') {
        setRepairList([...fileList])
      } else if (type == 'park') {
        setParkList([...fileList])
      } else if (type == 'trailer') {
        setTrailerList([...fileList])
      } else if (type == 'ticket') {
        setTicketList([...fileList])
      }
    }
    const handleBeforeUpload = async (file) => {
      const res = await zipImg({
        file,
      })
      return res
    }
    const handleLoseChange = (e) => {
      const { value } = e.target || {}
      if (value == 1) {
        setFieldsValue({
          maintainMoney: 0,
          fee: [],
          siteId: detail.surrenderSiteVO.siteId,
        })
        setParkList([])
        setTrailerList([])
        setTicketList([])
      } else {
        setDeviceChecked([])
      }
    }
    const handleConsultChange = (value) => {
      if (!value.includes(1)) {
        setParkList([])
      }
      if (!value.includes(2)) {
        setTrailerList([])
      }
      if (!value.includes(3)) {
        setTicketList([])
      }
    }
    const getUrlStr = (list) => {
      const arr = []
      list.map((item) => {
        if (item.response) {
          arr.push(item.response.data.path)
        } else if (item.status == 'done') {
          arr.push(item.path)
        }
      })
      return arr.join()
    }
    const handleDeviceChange = (value) => {
      setDeviceChecked(value)
    }
    // 提交
    const handleOk = async () => {
      if (confirmLoading || cancelLoading || loading) return
      const values = await validateFields()
      if (values.lose && !deviceChecked.length) {
        message.warning('请勾选设备型号')
        return
      }
      const equipment = []
      detail.goods.map((item, index) => {
        if (deviceChecked.includes(item.id)) {
          equipment.push({
            ...item,
            money: getFieldValue(`loseMoney${index}`) || 0,
          })
        }
      })
      const params = {
        ...values,
        orderId: id,
        id: detail.id || undefined,
        deditMoney: values.deditMoney || 0,
        liquidatedMoney: values.liquidatedMoney || 0,
        maintainMoney: values.maintainMoney || 0,
        maintainPicture: getUrlStr(repairList),
        parkingPicture: getUrlStr(parkList),
        towingPicture: getUrlStr(trailerList),
        ticketPicture: getUrlStr(ticketList),
        fee: undefined,
        equipment:
          equipment && equipment.length ? JSON.stringify(equipment) : '',
      }
      setConfirmLoading(true)
      const res = await _newOrderHangHp(params)
      setConfirmLoading(false)
      const { code, message: msg } = res.data || {}
      if (code !== 200) {
        message.warning(msg)
        return
      }
      message.success('操作成功')
      setVisible(false)
      done()
    }
    const handleHangCancel = async () => {
      if (confirmLoading || cancelLoading || loading) return
      Modal.confirm({
        title: '提示?',
        content: '取消挂起后，未支付的挂起金额将清除',
        okText: '确认取消挂起',
        onOk: async () => {
          setCancelLoading(true)
          const res = await _cancelOrderHangHp({ orderId: id })
          setCancelLoading(false)
          const { code, message: msg, data } = res.data || {}
          if (code !== 200) {
            message.warning(msg)
            return
          }
          if (!data) {
            message.success('操作成功')
            setVisible(false)
            done()
            return
          }
          Modal.confirm({
            content:
              data == 2
                ? '订单中的设备被禁用，取消挂起请绑定新设备（前去支付宝小程序）'
                : '订单内设备已被其他订单使用，取消挂起请绑定新设备（前去支付宝小程序）',
          })
        },
      })
    }
    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    )
    return (
      <Modal
        title={detail.id ? '挂起信息' : '确定挂起当前租车订单吗？'}
        width={750}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={
          <div>
            {!!detail.id && (
              <Button
                onClick={handleHangCancel}
                loading={cancelLoading}
                style={{ marginRight: '30px' }}
              >
                取消挂起
              </Button>
            )}
            <Button onClick={() => setVisible(false)}>取消</Button>
            <Button type="primary" onClick={handleOk} loading={confirmLoading}>
              {detail.id ? '确定修改挂起金额' : '确定'}
            </Button>
          </div>
        }
      >
        <div className="hang_tip">
          挂起说明:应收租金、逾期租金违约金、提前退款违约金可按填写金额进行代扣。维修费、资寻费、资产丢失费应监管要求只能由用户主动支付。挂起总金额结清后订单自动关闭。
        </div>
        <Spin spinning={loading}>
          <Form>
            <Form.Item label="应收租金(元)">
              {getFieldDecorator('overdueMoney', {
                rules: [
                  {
                    message: `请输入0~${detail.maxOverdueMoney}合法数字金额`,
                    type: 'number',
                    min: 0,
                    max: detail.maxOverdueMoney,
                  },
                ],
              })(
                <InputNumber allowClear precision={2} disabled={!!detail.id} />
              )}
            </Form.Item>
            <Form.Item label="逾期租金违约金(元)">
              {getFieldDecorator('deditMoney', {
                rules: [
                  {
                    message: `请输入0~${detail.maxDeditMoney}合法数字金额`,
                    type: 'number',
                    min: 0,
                    max: detail.maxDeditMoney,
                  },
                ],
              })(
                <InputNumber allowClear precision={2} disabled={!!detail.id} />
              )}
            </Form.Item>
            {detail.packageType !== 1 && (
              <Form.Item label="提前退租违约金(元)">
                {getFieldDecorator('liquidatedMoney', {
                  rules: [
                    {
                      message: `请输入0~${detail.maxLiquidatedMoney}合法数字金额`,
                      type: 'number',
                      min: 0,
                      max: detail.maxLiquidatedMoney,
                    },
                  ],
                })(
                  <InputNumber
                    allowClear
                    precision={2}
                    disabled={!!detail.id}
                  />
                )}
              </Form.Item>
            )}
            {!detail.historyData && detail.dayOverdueMoney > 0 && (
              <Form.Item label="剩余分期付订单金额(元)">
                {getFieldDecorator('dayOverdueMoney', {
                  rules: [
                    {
                      message: `请输入0~${detail.maxDayOverdueMoney}合法数字金额`,
                      type: 'number',
                      min: 0,
                      max: detail.maxDayOverdueMoney,
                    },
                  ],
                })(
                  <InputNumber
                    allowClear
                    precision={2}
                    disabled={!!detail.id}
                  />
                )}
              </Form.Item>
            )}
            <Form.Item label="维修费(元)">
              {getFieldDecorator('maintainMoney', {
                rules: [
                  {
                    message: '请输入0~1000合法数字金额',
                    type: 'number',
                    min: 0,
                    max: 1000,
                  },
                ],
              })(
                <InputNumber
                  disabled={getFieldValue('lose') == 1}
                  allowClear
                  precision={2}
                  onChange={(value) => !value && setRepairList([])}
                />
              )}
              <div
                style={{
                  marginTop: '6px',
                  display: getFieldValue('maintainMoney') ? 'block' : 'none',
                }}
              >
                <Upload
                  name="uploadFile"
                  multiple={true}
                  action={_imageUpload}
                  listType="picture-card"
                  fileList={repairList}
                  accept="image/jpg,image/jpeg,image/png,image/gif"
                  onChange={(info) => handleUploadChange('repair', info)}
                  beforeUpload={handleBeforeUpload}
                  data={{
                    scene: 'active',
                  }}
                >
                  {repairList.length >= 3 ? null : (
                    <div>
                      <Icon type="plus" style={{ fontSize: '28px' }} />
                      <p>图片上传</p>
                    </div>
                  )}
                </Upload>
              </div>
            </Form.Item>
            <Form.Item label="资寻费(元)">
              {getFieldDecorator('fee', {
                initialValue: [],
              })(
                <Checkbox.Group
                  disabled={getFieldValue('lose') == 1}
                  options={[
                    { value: 1, label: '停车费' },
                    { value: 2, label: '拖车费' },
                    { value: 3, label: '罚单' },
                  ]}
                  onChange={handleConsultChange}
                />
              )}
              {getFieldValue('fee') && getFieldValue('fee').includes(1) && (
                <div>
                  <Form.Item label="停车费">
                    {getFieldDecorator('parkingMoney', {
                      rules: [
                        { required: true, message: '请输入停车费' },
                        {
                          message: '请输入0~500合法数字金额',
                          type: 'number',
                          min: 0,
                          max: 500,
                        },
                      ],
                    })(<InputNumber allowClear precision={2} />)}
                  </Form.Item>
                  <Form.Item label=" " colon={false}>
                    <Upload
                      name="uploadFile"
                      multiple={true}
                      action={_imageUpload}
                      listType="picture-card"
                      fileList={parkList}
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                      onChange={(info) => handleUploadChange('park', info)}
                      beforeUpload={handleBeforeUpload}
                      data={{
                        scene: 'active',
                      }}
                    >
                      {parkList.length >= 3 ? null : (
                        <div>
                          <Icon type="plus" style={{ fontSize: '28px' }} />
                          <p>图片上传</p>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              )}
              {getFieldValue('fee') && getFieldValue('fee').includes(2) && (
                <div>
                  <Form.Item label="拖车费">
                    {getFieldDecorator('towingMoney', {
                      rules: [
                        { required: true, message: '请输入拖车费' },
                        {
                          message: '请输入0~500合法数字金额',
                          type: 'number',
                          min: 0,
                          max: 500,
                        },
                      ],
                    })(<InputNumber allowClear precision={2} />)}
                  </Form.Item>
                  <Form.Item label=" " colon={false}>
                    <Upload
                      name="uploadFile"
                      multiple={true}
                      action={_imageUpload}
                      listType="picture-card"
                      fileList={trailerList}
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                      onChange={(info) => handleUploadChange('trailer', info)}
                      beforeUpload={handleBeforeUpload}
                      data={{
                        scene: 'active',
                      }}
                    >
                      {trailerList.length >= 3 ? null : (
                        <div>
                          <Icon type="plus" style={{ fontSize: '28px' }} />
                          <p>图片上传</p>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              )}
              {getFieldValue('fee') && getFieldValue('fee').includes(3) && (
                <div>
                  <Form.Item label="罚单">
                    {getFieldDecorator('ticketMoney', {
                      rules: [
                        { required: true, message: '请输入罚单' },
                        {
                          message: '请输入0~500合法数字金额',
                          type: 'number',
                          min: 0,
                          max: 500,
                        },
                      ],
                    })(<InputNumber allowClear precision={2} />)}
                  </Form.Item>
                  <Form.Item label=" " colon={false}>
                    <Upload
                      name="uploadFile"
                      multiple={true}
                      action={_imageUpload}
                      listType="picture-card"
                      fileList={ticketList}
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                      onChange={(info) => handleUploadChange('ticket', info)}
                      beforeUpload={handleBeforeUpload}
                      data={{
                        scene: 'active',
                      }}
                    >
                      {ticketList.length >= 3 ? null : (
                        <div>
                          <Icon type="plus" style={{ fontSize: '28px' }} />
                          <p>图片上传</p>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              )}
            </Form.Item>
            <Form.Item label="资产丢失费(元)">
              {getFieldDecorator('lose', {
                initialValue: 0,
              })(
                <Radio.Group onChange={handleLoseChange} disabled={!!detail.id}>
                  <Radio value={0}>未丢失</Radio>
                  <Radio value={1}>
                    丢失
                    <span className="lose_tip">
                      （选择丢失挂起后，订单内设备将被禁用）
                    </span>
                  </Radio>
                </Radio.Group>
              )}
              {getFieldValue('lose') == 1 && (
                <div className="goods_wrap">
                  <Checkbox.Group
                    style={{ width: '100%' }}
                    onChange={handleDeviceChange}
                    value={deviceChecked}
                  >
                    {detail.goods.map((item, index) => (
                      <div className="goods_item" key={index}>
                        <Form.Item label="">
                          <Checkbox
                            value={item.id}
                            style={{ marginRight: '4px' }}
                          ></Checkbox>
                          {getFieldDecorator(`loseMoney${index}`, {
                            rules: [
                              {
                                message: `请输入0~10000合法数字金额`,
                                type: 'number',
                                min: 0,
                                max: 10000,
                              },
                            ],
                          })(<InputNumber allowClear precision={2} />)}
                          <span style={{ marginLeft: '10px' }}>
                            <span>
                              {item.flag == 'bike'
                                ? '车辆型号：'
                                : '电池型号：'}
                              {item.modelName}
                            </span>
                            <span style={{ marginLeft: '20px' }}>
                              {item.flag == 'bike'
                                ? '车辆二维码：'
                                : '电池二维码：'}
                              {item.imei}
                            </span>
                          </span>
                        </Form.Item>
                      </div>
                    ))}
                  </Checkbox.Group>
                </div>
              )}
            </Form.Item>
            <Form.Item label="资产归还站点">
              {getFieldDecorator('siteId')(
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  disabled={
                    getFieldValue('lose') == 1 ||
                    detail.returnType == 1 ||
                    detail.id
                  }
                >
                  {hangSiteList.map((item, index) => (
                    <Option value={item.siteId} key={index}>
                      {item.siteName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {!detail.addTime ? null : (
              <Form.Item label="挂起时间">
                <span>{detail.addTime}</span>
              </Form.Item>
            )}
          </Form>
        </Spin>
        <div className="money_total">
          <div>
            <span className="money_item">
              总计挂起金额：<span>{totalMoney()}</span>
            </span>
            <span className="money_item" style={{ marginLeft: '20px' }}>
              挂起可代扣金额：<span>{totalMoney('deduct')}</span>
            </span>
          </div>
          <p>（{totalMoney('extra')}元不可代扣金额需由用户主动支付）</p>
        </div>
      </Modal>
    )
  })
)

class PackageOrderManagement extends Component {
  constructor(props) {
    super(props)
    const { hangUp, orderSn, orderStatus, fid } = queryString.parse(
      this.props.location.search
    )
    this.state = {
      queryFormValues: {
        hangUp: hangUp,
        orderSn: orderSn,
        orderStatusList: orderStatus ? [orderStatus] : [],
        fid: fid ? Number(fid) : undefined,
      },
      loading: false,
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packageList: [], //套餐列表
      packageOrderList: [], //套餐订单列表
      siteList: [], //站点列表
      franchiseeList: [], //代理商列表
      bikeOrderList: [], //车辆订单列表
      batteryOrderList: [], //电池订单列表
      packageOrderLogList: [], //订单续租列表
      orderChangeList: [], //订单变更列表
      orderRefundLogs: [], //退款记录列表
      authInfo: {},
      visible: false,
      remarksVisible: false,
      visibleSingle: false,
      changeSiteList: [],
      spinning: false,
      hcImages: [],
      fcImages: [],
      tcImages: [],
      tcImages_user: [],
      tcImages_merchant: [],
    }
    this.hangRef = createRef()
    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '订单编号',
        align: 'right',
        width: 180,
        dataIndex: 'orderSn',
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点',
        dataIndex: 'sname',
      },
      {
        title: '套餐名',
        width: 200,
        dataIndex: 'pname',
        // ellipsis: true,
        // render: (pname) => (
        //   <Tooltip placement="topLeft" title={pname}>
        //     {pname}
        //   </Tooltip>
        // ),
      },
      {
        title: '套餐类型',
        width: 125,
        dataIndex: '',
        render: (text) =>
          text.ptypeName +
          (text.deductType === 1
            ? '（先付）'
            : text.deductType === 2
            ? '（后付）'
            : ''),
      },
      {
        title: '套餐金额',
        align: 'right',
        width: 100,
        dataIndex: 'pmoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '免赔服务',
        align: 'right',
        width: 120,
        dataIndex: '',
        render: (text) =>
          text.insureMoney &&
          text.insureMoney.toFixed(2) +
            (text.isInsure ? '(已购买)' : '(未购买)'),
      },
      {
        title: '套餐基础服务费',
        align: 'right',
        width: 135,
        dataIndex: '',
        hide: localStorage.getItem('type') !== '0',
        render: (text) =>
          text.serviceMoney
            ? text.serviceMoney.toFixed(2) +
              (text.servicePay ? '(已购买)' : '(未购买)')
            : '--',
      },
      {
        title: '押金类型',
        width: 120,
        dataIndex: 'authType',
        render: (text) =>
          text === 1
            ? '支付宝预授权'
            : text === 2
            ? '现金押金'
            : text === 3
            ? '免押金'
            : null,
      },
      {
        title: '押金金额',
        align: 'right',
        width: 100,
        dataIndex: 'authMoneyStr',
      },
      {
        title: '用户',
        dataIndex: '',
        render: (text) => text.username + '(' + text.kid + ')',
      },
      {
        title: '手机号',
        align: 'right',
        width: 130,
        dataIndex: 'mobile',
      },
      {
        title: '订单时间',
        width: 120,
        dataIndex: 'addtime',
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '租赁时间',
        width: 135,
        dataIndex: '',
        render: (text) => (
          <span>
            {text.startTime && moment.unix(text.startTime).format('YYYY-MM-DD')}
            <br />～<br />
            {text.endTime && moment.unix(text.endTime).format('YYYY-MM-DD')}
          </span>
        ),
      },
      {
        title: '订单金额',
        align: 'right',
        width: 100,
        dataIndex: 'orderMoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '期数',
        align: 'right',
        width: 100,
        dataIndex: 'monthNum',
      },
      {
        title: '实付金额',
        align: 'right',
        width: 100,
        dataIndex: 'payMoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '支付方式',
        width: 100,
        dataIndex: 'payTypeName',
      },

      {
        title: '支付状态',
        dataIndex: 'payStat',
        width: 100,
        render: (text) =>
          text === 0
            ? '未支付'
            : text === 1
            ? '已经支付'
            : text === 3
            ? '退款'
            : '',
      },
      {
        title: '支付时间',
        dataIndex: 'payTime',
        width: 120,
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '订单状态',
        dataIndex: '',
        width: 120,
        render: (text) => (
          <span>
            {orderStatusItem[text.orderStatus - 1].title}
            <br />
            {text.hangUp === 1 && '（已挂起）'}
          </span>
        ),
      },
      {
        title: '退租完成时间',
        dataIndex: 'finishTimeStr',
        width: 120,
      },
      {
        title: '订单变更时间',
        dataIndex: 'changeTime',
        width: 120,
        render: (text) =>
          text && moment.unix(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '在租车辆',
        width: 100,
        dataIndex: 'bikeVinCode',
        render: (text) => (text ? text : '无'),
      },
      {
        title: '车辆型号',
        width: 100,
        dataIndex: 'bikeModelName',
        render: (text) => (text ? text : '无'),
      },
      {
        title: '车辆型号类型',
        width: 100,
        dataIndex: 'bikeModelType',
      },
      {
        title: '在租电池',
        width: 100,
        dataIndex: 'batVinCode',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '电池型号',
        width: 100,
        dataIndex: 'batModelName',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '电池型号类型',
        width: 100,
        dataIndex: 'batModelType',
      },
      {
        title: '平台分润构成',
        width: 175,
        dataIndex: 'settlementRules',
        render: (text) =>
          text && (
            <span>
              {text.platMoney && (
                <span>
                  技术服务费：{text.platMoney}
                  <br />
                </span>
              )}
              {text.bikeMoney && (
                <span>
                  车辆： {text.bikeMoney}
                  <br />
                </span>
              )}
              {text.dcMoney && (
                <span>
                  电池：{text.dcMoney}
                  <br />
                </span>
              )}
            </span>
          ),
      },
      {
        title: '推荐人',
        dataIndex: '',
        render: (text) => (
          <span>
            {text.tjName}
            <br />
            {text.tjMobile}
          </span>
        ),
      },
      {
        title: '推荐人分润',
        width: 100,
        dataIndex: 'tcContent',
      },
      {
        title: '发车人',
        dataIndex: '',
        width: 130,
        render: (text) =>
          text.operatorVO &&
          text.operatorVO.fcUserName &&
          text.operatorVO.fcMobile
            ? text.operatorVO.fcMobile +
              '（' +
              text.operatorVO.fcUserName +
              '）'
            : '',
      },
      {
        title: '退车人',
        dataIndex: '',
        width: 130,
        render: (text) =>
          text.operatorVO &&
          text.operatorVO.tzUserName &&
          text.operatorVO.tzMobile
            ? text.operatorVO.tzMobile +
              '（' +
              text.operatorVO.tzUserName +
              '）'
            : '',
      },
      {
        title: '操作',
        dataIndex: '',
        width: 150,
        key: 'Action',
        fixed: 'right',
        render: (text) => (
          <span>
            {getJurisdiction(742) &&
              (text.orderStatus === 1 ||
                text.orderStatus === 3 ||
                text.orderStatus === 4 ||
                text.orderStatus === 6) && (
                <span>
                  <Button
                    type="link"
                    disabled={!getJurisdiction(742)}
                    title={getJurisdiction(742) ? '' : '暂无操作权限！'}
                    onClick={this.handleOverChange.bind(
                      this,
                      text.id,
                      text.dayDeductSum
                    )}
                  >
                    结束
                  </Button>
                  <Divider type="vertical" />
                </span>
              )}
            {(getJurisdiction(1113) || getJurisdiction(1114)) &&
              (text.orderStatus === 2 ||
                text.orderStatus === 7 ||
                text.orderStatus === 9) &&
              text.orderRefundStatus !== 3 && (
                <span>
                  {getJurisdiction(1113) && (
                    <Button
                      type="link"
                      disabled={!getJurisdiction(1113)}
                      title={getJurisdiction(1113) ? '' : '暂无操作权限！'}
                      onClick={this.handleRefundChange.bind(
                        this,
                        text.id,
                        'Y',
                        text.dayDeductSum
                      )}
                    >
                      全退
                    </Button>
                  )}
                  {localStorage.getItem('type') === '0' && (
                    <Divider type="vertical" />
                  )}
                  {getJurisdiction(1114) && (
                    <Button
                      type="link"
                      disabled={!getJurisdiction(1114)}
                      title={getJurisdiction(1114) ? '' : '暂无操作权限！'}
                      onClick={this.handleRefundChange.bind(
                        this,
                        text.id,
                        text.payMoney,
                        text.dayDeductSum
                      )}
                    >
                      部分退
                    </Button>
                  )}
                  <Divider type="vertical" />
                </span>
              )}
            <Button
              type="link"
              disabled={!getJurisdiction(743)}
              title={getJurisdiction(743) ? '' : '暂无操作权限！'}
              onClick={this.showModal.bind(
                this,
                text.id,
                text.orderSn,
                text.orderStatus
              )}
            >
              详情
            </Button>
            {text.orderStatus === 2 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(768)}
                  title={getJurisdiction(768) ? '' : '暂无操作权限！'}
                  onClick={this.showModalSingle.bind(this, { ...text })}
                >
                  发车调单
                </Button>
              </span>
            )}
            {text.orderStatus === 6 && text.rtype === 2 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(768)}
                  title={getJurisdiction(768) ? '' : '暂无操作权限！'}
                  onClick={this.showModalSingle.bind(this, { ...text })}
                >
                  退车调单
                </Button>
              </span>
            )}
            <Divider type="vertical" />
            {text.orderStatus === 5 ? (
              <Button
                type="link"
                disabled={!getJurisdiction(1112)}
                title={getJurisdiction(1112) ? '' : '暂无操作权限！'}
                onClick={this.showModalModification.bind(
                  this,
                  text.id,
                  text.orderStatus,
                  text.endTime,
                  text.orderSn
                )}
              >
                状态修改
              </Button>
            ) : (
              <Button
                type="link"
                disabled={!getJurisdiction(771)}
                title={getJurisdiction(771) ? '' : '暂无操作权限！'}
                onClick={this.showModalModification.bind(
                  this,
                  text.id,
                  text.orderStatus,
                  text.endTime,
                  text.orderSn
                )}
              >
                状态修改
              </Button>
            )}
            {text.orderStatus === 4 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction(771)}
                  title={getJurisdiction(771) ? '' : '暂无操作权限！'}
                  onClick={this.handleDiffMoneyChange.bind(this, text.id)}
                >
                  逾期租金违约金修改
                </Button>
              </span>
            )}
            {/* {!!text.deductType &&
              (text.orderStatus === 3 ||
                text.orderStatus === 4 ||
                text.orderStatus === 6) && (
                <span>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    disabled={!getJurisdiction(1017)}
                    title={getJurisdiction(1017) ? "" : "暂无操作权限！"}
                    onClick={this.handlePenaltyChange.bind(this, text.id)}
                  >
                    违约金修改
                  </Button>
                </span>
              )} */}
            {(text.orderStatus === 3 ||
              text.orderStatus === 4 ||
              text.orderStatus === 6) && (
              <>
                {!text.examineHangUp ? (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      disabled={!getJurisdiction(1018)}
                      title={getJurisdiction(1018) ? '' : '暂无操作权限！'}
                      onClick={this.handleHangUpChange.bind(this, text)}
                    >
                      {text.hangUp === 1 ? '挂起信息' : '挂起'}
                    </Button>
                  </>
                ) : (
                  text.hangUp === 1 && (
                    <>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        disabled={!getJurisdiction(1018)}
                        title={getJurisdiction(1018) ? '' : '暂无操作权限！'}
                        onClick={this.handleHangCancel.bind(this, text)}
                      >
                        取消挂起
                      </Button>
                    </>
                  )
                )}
              </>
            )}
            {!!text.contractUrl && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  target="_blank"
                  href={text.contractUrl}
                  disabled={!getJurisdiction(743)}
                  title={getJurisdiction(743) ? '' : '暂无操作权限！'}
                >
                  查看合同
                </Button>
              </span>
            )}
            {text.modifyPriceTag === 1 && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={!getJurisdiction()}
                  title={getJurisdiction() ? '' : '暂无操作权限！'}
                  onClick={this.handleModifyPriceChange.bind(this, text)}
                >
                  修改金额
                </Button>
              </span>
            )}
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={this.showModalRemarks.bind(this, text.id)}
            >
              备注信息
            </Button>
          </span>
        ),
      },
    ]
    this.columns = this.columns.filter((item) => !item.hide)
    this.logColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
        // render: (text) =>
        //   text.payWay === 1
        //     ? text.tradeNo
        //       ? text.tradeNo + "（代）"
        //       : "已生成分期付订单"
        //     : text.tradeNo
        //     ? text.tradeNo
        //     : "无",
      },
      {
        title: '订单起始时间',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.startDate && text.endDate
            ? moment.unix(text.startDate).format('YYYY.MM.DD') +
              '～' +
              moment.unix(text.endDate).format('YYYY.MM.DD')
            : '',
      },
      {
        title: '套餐名',
        dataIndex: 'pname',
      },
      {
        title: '免赔服务',
        dataIndex: '',
        width: 135,
        render: (text) =>
          text.isInsure === 1 ? (
            <span>
              已购买
              <br />
              {text.startDate && text.endDate
                ? moment.unix(text.startDate).format('YYYY.MM.DD') +
                  '～' +
                  moment.unix(text.endDate).format('YYYY.MM.DD')
                : ''}
            </span>
          ) : (
            '未购买'
          ),
      },
      {
        title: '分成信息',
        dataIndex: '',
        width: 200,
        render: (text) =>
          text && (
            <span>
              平台分成金额:{text.terraceMoney && text.terraceMoney.toFixed(2)}
              <br />
              代理商分成金额:
              {text.franchiseeMoney && text.franchiseeMoney.toFixed(2)}
              <br />
              站点分成金额:{text.siteMoney && text.siteMoney.toFixed(2)}
            </span>
          ),
      },
      {
        title: '支付类目',
        dataIndex: 'tradeTypeStr',
      },
      {
        title: '支付金额',
        dataIndex: 'payMoney',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '单次支付期数',
        dataIndex: 'periods',
      },
      {
        title: '支付时间',
        dataIndex: '',
        width: 120,
        render: (text) =>
          text.payTime &&
          moment.unix(text.payTime).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '支付状态',
        dataIndex: 'payStat',
        render: (text) =>
          text === 0
            ? '未支付'
            : text === 1
            ? '已经支付'
            : text === 3
            ? '退款'
            : '',
      },
      {
        title: '逾期租金违约金',
        align: 'right',
        dataIndex: 'diffMoney',
        render: (text) => text && text.toFixed(2),
      },
    ]
    this.bikeColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '车辆信息',
        dataIndex: '',
        align: 'right',
        render: (text) => (text.bikeId ? text.vin : '未提车'),
      },
      {
        title: '车辆状态',
        dataIndex: '',
        render: (text) =>
          text.gps
            ? (text.lock ? '锁定，' : '未锁，') +
              (text.online ? '在线' : '离线')
            : '未知',
      },
      {
        title: '车辆更换信息',
        dataIndex: 'log',
        width: 200,
        render: (text) =>
          text ? (
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          ) : (
            '暂无'
          ),
      },
      {
        title: '操作',
        dataIndex: '',
        fixed: 'right',
        width: 100,
        key: 'Action',
        render: (text) => (
          <span>
            {this.state.orderStatus === 2 ||
            this.state.orderStatus === 3 ||
            this.state.orderStatus === 4 ||
            this.state.orderStatus === 6 ? (
              <span>
                <Button
                  type="link"
                  onClick={this.modalViewhandle.bind(this, { ...text })}
                >
                  操作
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.modalViewMap.bind(this, { ...text })}
                >
                  定位
                </Button>
              </span>
            ) : (
              '无'
            )}
          </span>
        ),
      },
    ]
    this.batteryColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'porderSn',
      },
      {
        title: '电池信息',
        align: 'right',
        dataIndex: '',
        render: (text) => (text.batteryId ? text.iemi : '未提电池'),
      },
      {
        title: '电池更换信息',
        dataIndex: 'log',
        width: 200,
        render: (text) =>
          text ? (
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
          ) : (
            '暂无'
          ),
      },
    ]
    this.orderChangeColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '调单类型',
        dataIndex: 'type',
        render: (text) => (text == 2 ? '退车调单' : '发车调单'),
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点名',
        dataIndex: 'sname',
      },
      {
        title: '原站点',
        dataIndex: 'preSName',
      },
      {
        title: '调单时间',
        width: 120,
        dataIndex: 'addTime',
        render: (text) =>
          text && moment.unix(text).format('YYYY.MM.DD HH:mm:ss'),
      },
    ]
    this.orderRefundLogsColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
      },
      {
        title: '套餐金额',
        align: 'right',
        dataIndex: 'orderAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '退款金额',
        align: 'right',
        dataIndex: 'refundAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '退款状态',
        dataIndex: 'status',
        render: (text) =>
          text === 0
            ? '未退'
            : text === 1
            ? '成功'
            : text === 2
            ? '失败'
            : '退款中',
      },
      {
        title: '退款时间',
        width: 120,
        dataIndex: 'refundTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '退款原因',
        dataIndex: 'refundReason',
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
    ]
    //履约记录Columns
    this.performanceRecordColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '扣款金额',
        align: 'right',
        dataIndex: 'payAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '期数',
        dataIndex: 'period',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
      },
      {
        title: '计划扣款时间',
        width: 120,
        dataIndex: 'deductTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '实际扣款时间',
        width: 120,
        dataIndex: 'payTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '扣款状态',
        render: (text) => {
          const statusOptions = {
            0: '待支付',
            1: '已支付',
            2: '已取消',
            3: '部分支付',
            4: '已逾期',
          }
          return (
            <div>
              {text.dayDeduct
                ? statusOptions[text.dayStatus]
                : text.statusString}
            </div>
          )
        },
      },
      {
        title: '代扣方式',
        dataIndex: 'payType',
      },
      {
        title: '备注',
        render: (text) => {
          return (
            <div>
              <span>
                {text.remark}
                {text.status == 1 && (text.prepaidMoney || text.paidMoney) ? (
                  <span>
                    {text.dayDeduct ? (
                      text.prepaidMoney ? (
                        <span>（已付¥{text.prepaidMoney}元）</span>
                      ) : null
                    ) : text.paidMoney && text.status == 3 ? (
                      <span>
                        （已付¥
                        {text.paidMoney}元
                        {text.overdueAmount ? (
                          <span>
                            （含¥{text.overdueAmount}元逾期租金违约金）
                          </span>
                        ) : null}
                        ）
                      </span>
                    ) : null}
                  </span>
                ) : null}
              </span>
            </div>
          )
        },
      },
      {
        title: '操作',
        dataIndex: '',
        width: 80,
        key: 'Action',
        fixed: 'right',
        render: (text) =>
          text.status === 0 && (
            <Button
              type="link"
              onClick={this.handleCancelPerformanceChange.bind(this, text.id)}
            >
              取消
            </Button>
          ),
      },
    ]
    localStorage.getItem('type') !== '0' &&
      this.performanceRecordColumns.splice(-1, 1)
    //还款履约记录Columns
    this.repaymentPerformanceRecordsColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '扣款金额',
        align: 'right',
        dataIndex: 'payAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '期数',
        dataIndex: 'subPeriod',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
      },
      {
        title: '计划扣款时间',
        width: 120,
        dataIndex: 'deductTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '实际扣款时间',
        width: 120,
        dataIndex: 'payTime',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '扣款状态',
        dataIndex: 'statusString',
      },
      {
        title: '代扣方式',
        dataIndex: 'payType',
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
      {
        title: '操作',
        dataIndex: '',
        width: 80,
        key: 'Action',
        fixed: 'right',
        render: (text) =>
          text.status === 0 &&
          (text.month === 1 || !!text.advanced) &&
          !text.hangUp &&
          text.surrenderBillStatus !== 0 &&
          text.surrenderBillStatus !== 1 && (
            <Button
              type="link"
              disabled={!getJurisdiction(1102)}
              title={getJurisdiction(1102) ? '' : '暂无操作权限！'}
              onClick={this.handleCancelRepaymentPerformanceChange.bind(
                this,
                text.id,
                text.orderId
              )}
            >
              取消
            </Button>
          ),
      },
    ]
    //状态变更Columns
    this.orderStatusLogRecordColumns = [
      {
        title: '序号',
        width: 100,
        dataIndex: 'id',
      },
      {
        title: '订单编号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '前状态',
        dataIndex: 'preOrderStatusString',
      },
      {
        title: '后状态',
        dataIndex: 'orderStatusString',
      },
      {
        title: '变更时间',
        width: 120,
        dataIndex: 'addTimeString',
        render: (text) => text && moment(text).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
    ]
    //线下处理Columns
    this.exceptionOrderColumns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'id',
      },
      {
        title: '订单号',
        dataIndex: 'orderSn',
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点',
        dataIndex: 'sname',
      },
      {
        title: '用户信息',
        dataIndex: '',
        render: (text) => text.userName + text.mobile,
      },
      {
        title: '金额',
        dataIndex: '',
        width: 160,
        render: (text) => (
          <span>
            申请金额：
            {text.applyAmount}
            元<br />
            {text.status === 2 && (
              <span>
                审核金额：{text.amount}元<br /> 结算金额：{text.receiveAmount}元
              </span>
            )}
          </span>
        ),
      },
      {
        title: '天数',
        dataIndex: '',
        width: 160,
        render: (text) => (
          <span>
            申请天数：
            {text.applyDays}
            天<br />
            {text.status === 2 && <span>审核天数：{text.days}天</span>}
          </span>
        ),
      },
      {
        title: '处理类型',
        dataIndex: 'type',
        render: (text) =>
          text === 1 ? '订单延时' : text === 2 ? '结束订单' : '订单退款',
      },
      {
        title: '处理状态',
        dataIndex: 'status',
        width: 120,
        render: (text, record) =>
          text === 1 ? (
            '申请中'
          ) : text === 2 ? (
            <span>
              已通过
              <br />
              {moment(record.handleTime).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          ) : (
            <span>
              已拒绝
              <br />
              {moment(record.handleTime).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          ),
      },
      {
        title: '创建时间',
        width: 120,
        dataIndex: 'addTime',
        render: (text) => text && moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
    ]
    //订单备注Columns
    this.remarksColumns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'id',
      },
      {
        title: '内容',
        dataIndex: 'content',
      },
      {
        title: '备注账号',
        dataIndex: '',
        render: (text) => text.creatorName + '（' + text.creatorAccount + '）',
      },
      {
        title: '创建时间',
        width: 120,
        dataIndex: 'addTime',
        render: (text) => text && moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
    ]
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    const { queryFormValues, franchiseeList, siteList, exportload } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem('type') === '0' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator('fid', {
                  initialValue: queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.handleFidChange.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    <Option value="">全部</Option>
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="站点">
                {getFieldDecorator('sid')(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    <Option value="">全部</Option>
                    {siteList.length &&
                      siteList.map((item, index) => {
                        return (
                          <Option key={index} value={item.sid}>
                            {item.sname}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="套餐">
                {getFieldDecorator('pids')(
                  <TransferSelect
                    dataSource={this.state.packageList.map((item) => ({
                      key: item.id,
                      title: item.pname,
                    }))}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="下单时间">
                {getFieldDecorator('addTime')(
                  <RangePicker format="YYYY-MM-DD" />
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="订单变更时间">
                {getFieldDecorator('changeTime')(
                  <RangePicker format="YYYY-MM-DD" />
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="订单状态">
                {getFieldDecorator('orderStatusList', {
                  initialValue: queryFormValues.orderStatusList,
                })(<TransferSelect dataSource={orderStatusItem} />)}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="支付状态">
                {getFieldDecorator('payStat')(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">未支付</Option>
                    <Option value="1">已经支付</Option>
                    <Option value="3">退款</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          {localStorage.getItem('userId') === '1288' ? null : (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="是否挂起">
                {getFieldDecorator('hangUp', {
                  initialValue: queryFormValues.hangUp,
                })(
                  <Select placeholder="请选择" allowClear>
                    <Option value="">全部</Option>
                    <Option value="0">未挂起</Option>
                    <Option value="1">已挂起</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator('orderSn', {
                initialValue: queryFormValues.orderSn,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户/手机号">
              {getFieldDecorator('mobile')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单合同">
              {getFieldDecorator('hasContract')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="Y">已签</Option>
                  <Option value="N">未签</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="扣款类型">
              {getFieldDecorator('deductType')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">先付</Option>
                  <Option value="2">后付</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={exportload}
                disabled={!getJurisdiction(816)}
                title={getJurisdiction(816) ? '' : '暂无操作权限！'}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const {
      totalCount,
      curPage,
      pageSize,
      visibleSingle,
      sname,
      orderSn,
      visibleModification,
      monthObj,
      dayDeductList,
      performanceRecordList,
      exceptionOrderList,
      status,
      packageOrderList,
      authInfo,
      loading,
    } = this.state
    return (
      <div>
        {!!getJurisdiction(741) ? (
          <Spin spinning={this.state.spinning} tip="退款中...">
            <div className="PackageOrderManagement">
              <div className="select-form">{this.renderForm()}</div>
              <div className="content-table">
                <Table
                  rowKey="id"
                  bordered
                  loading={loading}
                  columns={this.columns}
                  dataSource={packageOrderList}
                  scroll={{ x: 4300, y: 500 }}
                  pagination={{
                    showQuickJumper: true,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    onShowSizeChange: this.handlePageChange.bind(this),
                    onChange: this.handlePageChange.bind(this),
                    total: totalCount,
                    current: curPage,
                    showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                  }}
                />
              </div>
              <Modal
                title={this.state.orderStatus == 2 ? '发车调单' : '退车调单'}
                destroyOnClose
                maskClosable={false}
                visible={visibleSingle}
                onOk={this.handleOkSingle.bind(this)}
                onCancel={this.initModalSingle.bind(this)}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        this.state.orderStatus === 2 ? '原站点' : '原退车点'
                      }
                    >
                      {sname}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={
                        this.state.orderStatus === 2 ? '新站点' : '新退车点'
                      }
                    >
                      <Select
                        style={{ width: '100%' }}
                        placeholder="请选择"
                        onChange={this.handleSiteChange.bind(this)}
                        allowClear
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          )
                        }}
                      >
                        {this.state.changeSiteList.length &&
                          this.state.changeSiteList.map((item, index) => {
                            return (
                              <Option key={index} value={item.sid}>
                                {item.sname}
                              </Option>
                            )
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
              <Modal
                title={`订单修改[${orderSn}]`}
                destroyOnClose
                maskClosable={false}
                visible={visibleModification}
                onOk={this.handleOkModification.bind(this)}
                onCancel={this.initModalModification.bind(this)}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="原状态">
                      {status && orderStatusItem[status - 1].title}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="新状态">
                      {status === 5 ? (
                        <Select
                          style={{ width: '100%' }}
                          placeholder="请选择新的订单状态"
                          allowClear
                          onChange={this.handleStatusChange.bind(this)}
                        >
                          <Option value={8}>已关闭</Option>
                        </Select>
                      ) : (
                        <Select
                          style={{ width: '100%' }}
                          placeholder="请选择新的订单状态"
                          allowClear
                          onChange={this.handleStatusChange.bind(this)}
                        >
                          <Option
                            disabled={status === 3 || status === 8}
                            value={3}
                          >
                            租车中
                          </Option>
                          <Option disabled={status === 5} value={5}>
                            已完成
                          </Option>
                          <Option
                            disabled={status === 6 || status === 8}
                            value={6}
                          >
                            申请退租
                          </Option>
                          <Option
                            disabled={status === 4 || status === 8}
                            value={4}
                          >
                            已超时
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="结束时间">
                      <DatePicker
                        disabled={status === 5 || status === 8}
                        value={
                          this.state.endTime
                            ? moment.unix(this.state.endTime)
                            : null
                        }
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          if (localStorage.getItem('type') === '1') {
                            return current && current > this.state.disabledTime
                          }
                        }}
                        onChange={this.handleEndTimeChange.bind(this)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
              <Modal
                width="1100px"
                title={`订单[${this.state.orderSnInfo}]详情`}
                destroyOnClose
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleOk.bind(this)}
                onCancel={this.initModal.bind(this)}
              >
                <Tabs defaultActiveKey="1">
                  {!!getJurisdiction(744) && (
                    <TabPane tab="订单支付列表" key="1">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.logColumns}
                        scroll={{ x: 1550, y: 500 }}
                        dataSource={this.state.packageOrderLogList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(745) && (
                    <TabPane tab="车辆订单列表" key="2">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.bikeColumns}
                        scroll={{ x: 1000, y: 500 }}
                        dataSource={this.state.bikeOrderList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(746) && (
                    <TabPane tab="电池订单列表" key="3">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.batteryColumns}
                        scroll={{ x: 750, y: 500 }}
                        dataSource={this.state.batteryOrderList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane tab="状态变更记录" key="4">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.orderStatusLogRecordColumns}
                        scroll={{ x: 750, y: 500 }}
                        dataSource={this.state.orderStatusLogRecordList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane
                      tab={
                        '履约记录' +
                        (!!performanceRecordList &&
                        !!performanceRecordList.length
                          ? '(' +
                            performanceRecordList[0].sinLonStatusString +
                            ')'
                          : '')
                      }
                      key="5"
                    >
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.performanceRecordColumns}
                        scroll={{ x: 1000, y: 500 }}
                        dataSource={performanceRecordList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane tab={'分期付履约记录'} key="11">
                      {monthObj &&
                        monthObj.months &&
                        monthObj.months.length && (
                          <Radio.Group defaultValue={monthObj.month}>
                            {monthObj.months.map((item, index) => {
                              return (
                                <Radio.Button
                                  key={item}
                                  style={{ margin: '0 10px 10px 0' }}
                                  value={item}
                                  onChange={(e) => {
                                    this.getDayDeductList(
                                      monthObj.orderId,
                                      e.target.value
                                    )
                                  }}
                                >
                                  第{item}期
                                </Radio.Button>
                              )
                            })}
                          </Radio.Group>
                        )}
                      {!!this.state.distance &&
                        this.state.activeMonth === 1 && (
                          <div>
                            第一期包含{this.state.distance}
                            元手续费，该费用由技术服务商收取。
                          </div>
                        )}
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.repaymentPerformanceRecordsColumns}
                        scroll={{ x: 1000, y: 500 }}
                        dataSource={dayDeductList}
                      />
                    </TabPane>
                  )}
                  {authInfo && (
                    <TabPane tab="预授权押金" key="10">
                      <div>
                        <p>{authInfo.authNo + '（' + authInfo.status + '）'}</p>
                        <p>
                          金额：
                          {authInfo.money && authInfo.money.toFixed(2)}
                        </p>
                        <p>类型：{authInfo.authTypeName}</p>
                        <p>
                          冻结时间：
                          {authInfo.freezeTime &&
                            moment(authInfo.freezeTime).format(
                              'YYYY.MM.DD HH:mm:ss'
                            )}
                        </p>
                        <p>
                          解冻时间：
                          {authInfo.unfreezeTime &&
                            moment(authInfo.unfreezeTime).format(
                              'YYYY.MM.DD HH:mm:ss'
                            )}
                        </p>
                      </div>
                    </TabPane>
                  )}
                  {exceptionOrderList && exceptionOrderList.length && (
                    <TabPane tab="线下处理" key="9">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.exceptionOrderColumns}
                        scroll={{ x: 1000, y: 500 }}
                        dataSource={exceptionOrderList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane tab="调单记录列表" key="6">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.orderChangeColumns}
                        scroll={{ x: 750, y: 500 }}
                        dataSource={this.state.orderChangeList}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane tab="退款记录列表" key="7">
                      <Table
                        rowKey="id"
                        bordered
                        columns={this.orderRefundLogsColumns}
                        scroll={{ x: 1000, y: 500 }}
                        dataSource={this.state.orderRefundLogs}
                      />
                    </TabPane>
                  )}
                  {!!getJurisdiction(744) && (
                    <TabPane tab="订单图片" key="8">
                      {!!this.state.fcImages &&
                        !!this.state.fcImages.length && (
                          <div>
                            <h4
                              style={{
                                clear: 'both',
                                borderBottom: '1px solid #e8e8e8',
                                marginBottom: '10px',
                                paddingBottom: '10px',
                              }}
                            >
                              发车图片
                            </h4>
                            {this.state.fcImages.map((item, index) => {
                              return (
                                <a
                                  target="_blank"
                                  key={index}
                                  href={item.hcImg}
                                >
                                  <img
                                    key={index}
                                    style={{
                                      width: 150,
                                      height: 150,
                                      margin: 10,
                                    }}
                                    src={item.hcImg}
                                    alt=""
                                  />
                                </a>
                              )
                            })}
                          </div>
                        )}
                      {!!this.state.tcImages &&
                        !!this.state.tcImages.length && (
                          <div>
                            <h4
                              style={{
                                clear: 'both',
                                borderBottom: '1px solid #e8e8e8',
                                marginBottom: '10px',
                                paddingBottom: '10px',
                              }}
                            >
                              退车图片
                            </h4>
                            <div className="img_wrap">
                              {!!this.state.tcImages_user.length && (
                                <div className="img_item">
                                  <div>用户上传图片</div>
                                  {this.state.tcImages_user.map(
                                    (item, index) => {
                                      return (
                                        <a
                                          target="_blank"
                                          key={index}
                                          href={item.hcImg}
                                        >
                                          <img
                                            key={index}
                                            style={{
                                              width: 150,
                                              height: 150,
                                              margin: 10,
                                            }}
                                            src={item.hcImg}
                                            alt=""
                                          />
                                        </a>
                                      )
                                    }
                                  )}
                                </div>
                              )}
                              {!!this.state.tcImages_merchant.length && (
                                <div className="img_item">
                                  <div>商家上传图片</div>
                                  {this.state.tcImages_merchant.map(
                                    (item, index) => {
                                      return (
                                        <a
                                          target="_blank"
                                          key={index}
                                          href={item.hcImg}
                                        >
                                          <img
                                            key={index}
                                            style={{
                                              width: 150,
                                              height: 150,
                                              margin: 10,
                                            }}
                                            src={item.hcImg}
                                            alt=""
                                          />
                                        </a>
                                      )
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      {!!this.state.hcImages &&
                        !!this.state.hcImages.length &&
                        this.state.hcImages.map((item, index) => {
                          return (
                            <div key={index}>
                              <h4
                                style={{
                                  clear: 'both',
                                  borderBottom: '1px solid #e8e8e8',
                                  marginBottom: '10px',
                                  paddingBottom: '10px',
                                }}
                              >
                                {'新车架：' +
                                  item.imei +
                                  ' 更换时间 ：' +
                                  moment
                                    .unix(item.addTime)
                                    .format('YYYY-MM-DD HH:mm:ss')}
                              </h4>
                              {item.hcImages.map((items, indexs) => {
                                return (
                                  <a target="_blank" key={indexs} href={items}>
                                    <img
                                      key={indexs}
                                      style={{
                                        width: 150,
                                        height: 150,
                                        margin: 10,
                                      }}
                                      src={items}
                                      alt=""
                                    />
                                  </a>
                                )
                              })}
                            </div>
                          )
                        })}
                    </TabPane>
                  )}
                </Tabs>
              </Modal>
              <Modal
                className="bigModal"
                title="备注信息"
                destroyOnClose
                maskClosable={false}
                visible={this.state.remarksVisible}
                onOk={this.handleRemarksOk.bind(this)}
                onCancel={this.initRemarksModal.bind(this)}
              >
                <Col span={24}>
                  <Form.Item label="添加备注">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="请输入备注"
                      onChange={(e) => {
                        this.setState({ orderRemark: e.target.value })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.remarksColumns}
                    scroll={{ x: 750, y: 500 }}
                    dataSource={this.state.orderRemarkList}
                  />
                </Col>
              </Modal>
              <HangupModal
                wrappedComponentRef={(ref) => (this.hangRef = ref)}
                done={() =>
                  this.getPackageOrderList(
                    this.state.curPage,
                    this.state.pageSize,
                    this.state.queryFormValues
                  )
                }
              />
            </div>
          </Spin>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }

  componentDidMount() {
    const fid = localStorage.getItem('fid') || undefined
    if(localStorage.getItem('type') === '0'){
      this.getFranchiseeList()
      this.getPackageList()
    }else{
      this.getSiteList(fid)
      this.getPackageList(fid)
    }
    this.getPackageOrderList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  //获取套餐订单列表
  getPackageOrderList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    }
    this.setState({
      loading: true,
    })
    _packageOrderList(params)
      .then((res) => {
        this.setState({
          loading: false,
        })
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            packageOrderList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageOrderList(curPage, pageSize, this.state.queryFormValues)
  }

  //车辆操作
  modalViewhandle({ imei, lock, online, gps }) {
    if (gps) {
      if (online) {
        let instructType
        Modal.confirm({
          title: `车辆操作 [${imei}]`,
          content: (
            <Radio.Group
              onChange={(e) => {
                instructType = e.target.value
              }}
            >
              <Radio disabled={lock} value={1}>
                锁车
              </Radio>
              <Radio disabled={!lock} value={2}>
                开锁
              </Radio>
              <Radio value={3}>寻车</Radio>
            </Radio.Group>
          ),
          onOk() {
            let params = { imei, instructType }
            _sendInstructionBike(params).then((res) => {
              if (res.data.code === 200) {
                message.success(res.data.message)
              } else {
                message.warning(res.data.message)
              }
            })
          },
        })
      } else {
        message.warning('车辆离线无法进行操作！')
      }
    } else {
      message.warning('车辆未安装定位系统无法进行操作！')
    }
  }

  //查看定位
  modalViewMap({ bikeId, vin }) {
    let params = { id: bikeId }
    _devicePositionBike(params)
      .then((res) => {
        if (!!res.data.data) {
          let mapPosition = {
            longitude: res.data.data.lon,
            latitude: res.data.data.lat,
          }
          Modal.info({
            title: `查看定位 [${vin}]`,
            width: '1000px',
            maskClosable: true,
            content: (
              <div style={{ height: 500 }}>
                <Map amapkey={amapkey} zoom={14} center={mapPosition}>
                  <Marker position={mapPosition} />
                </Map>
              </div>
            ),
            onOk() {},
          })
        } else {
          message.warning('未查询到该车辆的定位信息！')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  handleFidChange(value){
    this.props.form.setFieldsValue({
      sid: undefined,
      pids: []
    })
    this.getSiteList(value)
    this.getPackageList(value)
  }
  //获取站点列表
  getSiteList(value) {
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    }
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取退车点列表
  getRepairSiteList(value) {
    let params = {
      pid: value,
      curPage: 1,
      pageSize: 999,
    }
    _siteRepairBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            repairSiteList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取套餐列表
  async getPackageList(fid) {
    let params = {
      curPage: 1,
      pageSize: 9999,
      fid,
    }
    const res = await (fid ? _packageList(params) : _packageBoxList(params))
    const {data} = res.data || {}
    const {rows=[]} = data || {}
    this.setState({
      packageList: rows || [],
    })
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      console.log(values)
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.changeTime && values.changeTime.length) {
        values.changeTimeStart = moment(values.changeTime[0]).format(
          'YYYY-MM-DD'
        )
        values.changeTimeEnd = moment(values.changeTime[1]).format('YYYY-MM-DD')
      }
      delete values.changeTime
      this.setState({
        queryFormValues: values,
      })
      this.getPackageOrderList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.changeTime && values.changeTime.length) {
        values.changeTimeStart = moment(values.changeTime[0]).format(
          'YYYY-MM-DD'
        )
        values.changeTimeEnd = moment(values.changeTime[1]).format('YYYY-MM-DD')
      }
      delete values.changeTime
      let params = {
        ...values,
      }
      _exportPackageOrder(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  //获取车辆订单列表
  getBikeOrderList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _bikeOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeOrderList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取线下处理列表
  getExceptionOrderList(orderSn) {
    let params = {
      orderSn,
      curPage: 1,
      pageSize: 999,
    }
    _exceptionOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            exceptionOrderList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取电池订单列表
  getBatteryOrderList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _batteryOrderList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryOrderList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单续租列表
  getPackageOrderLogList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _packageOrderLogList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageOrderLogList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取履约记录列表
  getPerformanceRecordList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _performanceRecordList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          performanceRecordList: res.data.data.rows,
        })
      }
    })
  }
  //获取还款履约记录列表
  getDayDeductMonth(orderId) {
    let params = {
      orderId,
    }
    _dayDeductMonth(params).then((res) => {
      if (res.data.code === 200) {
        let monthObj = res.data.data || {}
        monthObj.orderId = orderId
        this.setState({
          monthObj,
        })
        if (monthObj.month) {
          this.getDayDeductList(orderId, monthObj.month)
        } else {
          this.setState({
            monthObj: null,
            distance: null,
            dayDeductList: null,
          })
        }
      }
    })
  }
  getDayDeductList(orderId, month) {
    let params = {
      orderId,
      month,
      page: 1,
      rows: 999,
    }
    _dayDeductList(params).then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.rows.length > 1) {
          let distance =
            (res.data.data.rows[0].payAmount * 1000 -
              res.data.data.rows[1].payAmount * 1000) /
            1000
          this.setState({
            distance,
          })
        }
        this.setState({
          dayDeductList: res.data.data.rows,
          activeMonth: month,
        })
      }
    })
  }
  //获取预授权押金列表
  getAuthInfo(id) {
    let params = {
      id,
      curPage: 1,
      pageSize: 999,
    }
    _authInfo(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          authInfo: res.data.data,
        })
      }
    })
  }

  //获取订单状态变更列表
  getOrderStatusLogRecordList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderStatusLogRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderStatusLogRecordList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单图片
  getOrderDeviceImages(id) {
    let params = {
      id,
    }
    _orderDeviceImages(params)
      .then((res) => {
        if (res.data.code === 200) {
          const {
            hcImages = [],
            fcImages = [],
            tcImages = [],
          } = res.data.data || {}
          const tcImages_user = []
          const tcImages_merchant = []
          if (tcImages && tcImages.length) {
            tcImages.map((item) => {
              if (item.roleType == 2) {
                tcImages_merchant.push(item)
              } else {
                tcImages_user.push(item)
              }
            })
          }
          this.setState({
            hcImages: hcImages || [],
            fcImages: fcImages || [],
            tcImages: tcImages || [],
            tcImages_user,
            tcImages_merchant,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取订单变更列表
  getOrderChangeList(porderId) {
    let params = {
      porderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderChangeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderChangeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取退款记录列表
  getOrderRefundLogs(orderId) {
    let params = {
      orderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderRefundLogs(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderRefundLogs: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //取消该笔履约
  handleCancelPerformanceChange(id) {
    let self = this
    let remark
    Modal.confirm({
      title: '取消履约信息',
      content: (
        <div>
          <p>确定要取消该笔履约信息吗？</p>
          <Input
            style={{ width: '100%' }}
            placeholder="请输入取消履约备注"
            onChange={(e) => {
              remark = e.target.value
            }}
          />
        </div>
      ),
      onOk() {
        let params = {
          id,
          remark,
        }
        _cancelDeductLog(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              self.getPackageOrderList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              )
            } else {
              message.warning(res.data.message)
            }
          })
          .catch(() => {
            message.warning('操作失败')
          })
      },
    })
  }
  //取消还款履约
  handleCancelRepaymentPerformanceChange(id, orderId) {
    let self = this
    let state = 0
    let remark
    Modal.confirm({
      title: '取消履约信息',
      content: (
        <div>
          <Form.Item label="取消类型">
            <Radio.Group
              defaultValue={state}
              onChange={(e) => {
                state = e.target.value
              }}
            >
              <Radio value={0}>取消单期</Radio>
              <Radio value={1}>取消全部</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="备注原因">
            <Input
              style={{ width: '100%' }}
              placeholder="请输入取消代扣原因"
              onChange={(e) => {
                remark = e.target.value
              }}
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params
        let cancelDeductIds = self.state.dayDeductList
          .filter((item) => item.status === 0)
          .map((item) => item.id)
        if (state) {
          params = {
            orderId,
            cancelDeductIds: cancelDeductIds,
            remark,
          }
        } else {
          params = {
            orderId,
            cancelDeductIds: [id],
            remark,
          }
        }
        _cancelDayDeduct(params).then((res) => {
          if (res.data.code === 200) {
            message.success('操作成功')
            self.getDayDeductList(orderId, self.state.activeMonth)
          } else {
            message.warning(res.data.message)
          }
        })
      },
    })
  }

  //退款操作
  handleRefundChange(orderId, allRefund, dayDeductSum) {
    let refundReason, refundAmount
    Modal.confirm({
      title: '退款信息',
      content:
        allRefund === 'Y' ? (
          <div>
            <p>
              {dayDeductSum
                ? '该订单含有未完成的日还款代扣任务，是否继续确定要全额退款订单金额后关闭订单吗？'
                : '确定要全额退款订单金额后关闭订单吗？'}
            </p>

            <Input.TextArea
              style={{ width: '100%' }}
              placeholder="请输入退款备注"
              onChange={(e) => {
                refundReason = e.target.value
              }}
            />
          </div>
        ) : (
          <div>
            {dayDeductSum ? (
              <p>
                该订单含有未完成的日还款代扣任务，是否继续确定要退款订单金额后关闭订单吗？
              </p>
            ) : null}
            <Form.Item label="订单金额">{allRefund.toFixed(2)}元</Form.Item>
            <Form.Item label="应退金额">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="请输入应退金额"
                min={0}
                max={allRefund}
                formatter={(value) => {
                  let reg = /^(-)*(\d+)\.(\d\d).*$/
                  return `￥ ${value}`
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replace(reg, '$1$2.$3')
                }}
                parser={(value) => {
                  let reg = /^(-)*(\d+)\.(\d\d).*$/
                  return value
                    .replace(/￥\s?|(,*)/g, '')
                    .replace(reg, '$1$2.$3')
                }}
                onChange={(value) => {
                  refundAmount = value
                }}
              />
            </Form.Item>
            <Form.Item label="退款备注">
              <Input.TextArea
                style={{ width: '100%' }}
                placeholder="请输入退款备注"
                onChange={(e) => {
                  refundReason = e.target.value
                }}
              />
            </Form.Item>
          </div>
        ),
      onOk: () => {
        let params =
          allRefund === 'Y'
            ? {
                orderId,
                allRefund,
                refundReason,
              }
            : {
                orderId,
                allRefund: 'N',
                refundAmount,
                refundReason,
              }
        _orderRefund(params)
          .then((res) => {
            if (res.data.code === 200) {
              //   this.setState({ spinning: true });
              //   this.startGetPaySuccess(params);
              let { orderRefundStatus } = res.data.data
              orderRefundStatus == 1
                ? message.success('退款成功')
                : message.warning(
                    orderRefundStatus == 2 ? '退款失败' : '退款中'
                  )
              this.getPackageOrderList(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              )
            }
          })
          .catch(() => {
            message.warning('操作失败')
          })
      },
    })
  }
  //轮询退款回调
  // startGetPaySuccess(params) {
  //   clearInterval(this.payTimer);
  //   let n = 14;
  //   this.payTimer = setInterval(() => {
  //     if (n) {
  //       n--;
  //       this.httpGetPaySuccess(params);
  //     } else {
  //       clearInterval(this.payTimer);
  //       this.payTimer = null;
  //       this.setState({
  //         spinning: false,
  //       });
  //       message.warning("退款失败");
  //     }
  //   }, 500);
  // }
  // stopGetPaySuccess() {
  //   if (this.payTimer) {
  //     clearInterval(this.payTimer);
  //     this.payTimer = null;
  //   }
  // }
  //获取支付成功状态
  // async httpGetPaySuccess(params) {
  //   const res = await _orderRefundDetails(params);
  //   if (res.data.code === 200 && res.data.data.status === 1) {
  //     message.success("退款成功");
  //     this.setState({
  //       spinning: false,
  //     });
  //     this.stopGetPaySuccess();
  //     this.getPackageOrderList(
  //       this.state.curPage,
  //       this.state.pageSize,
  //       this.state.queryFormValues
  //     );
  //   }
  // }
  //退款操作
  handleModifyPriceChange(text) {
    let self = this
    let orderMoneyStr
    Modal.confirm({
      title: '金额信息',
      content: (
        <div>
          <Form.Item label="套餐金额">{text.pmoney}元</Form.Item>
          <Form.Item label="订单金额">{text.orderMoney}元</Form.Item>
          <Form.Item label="修改后订单金额">
            <InputNumber
              style={{ width: '100%' }}
              placeholder={'请输入修改后订单金额'}
              onChange={(value) => {
                orderMoneyStr = value
              }}
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = { id: text.id, orderMoneyStr }
        _modifyPrice(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              self.getPackageOrderList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              )
            } else {
              message.warning(res.data.message)
            }
          })
          .catch(() => {
            message.warning('操作失败')
          })
      },
    })
  }
  //逾期租金违约金操作
  handleDiffMoneyChange(id) {
    let self = this
    let diffMoneyStr
    _orderDiffInfo({ id }).then((res) => {
      let diffData = res.data.data
      Modal.confirm({
        title: '逾期租金违约金信息',
        content: (
          <div className="diffModalDiv" style={{ marginTop: 30 }}>
            {/* <Form.Item label="订单截止时间">{diffData.orderEndTime}</Form.Item> */}
            {/* <Form.Item label="逾期租金违约金上次支付">
              {diffData.lastDiffTime}
            </Form.Item> */}
            <Form.Item label="逾期天数">{diffData.diff_day}天</Form.Item>
            <Form.Item label="应付逾期租金违约金">
              {diffData.diff_money_goal}元
            </Form.Item>
            {/* <Form.Item label="原逾期租金违约金">
              {diffData.diff_money}元
            </Form.Item> */}
            <Form.Item label="实付逾期租金违约金">
              <InputNumber
                style={{ width: 200 }}
                onChange={(value) => {
                  diffMoneyStr = value
                }}
                allowClear
                placeholder="请输入实付逾期租金违约金金额"
                min={0}
              />
            </Form.Item>
          </div>
        ),
        onOk() {
          let params = { id, diffMoneyStr }
          _savePackageOrder(params)
            .then((result) => {
              if (result.data.code === 200) {
                message.success('操作成功')
                self.getPackageOrderList(
                  self.state.curPage,
                  self.state.pageSize,
                  self.state.queryFormValues
                )
              } else {
                message.warning(result.data.message)
              }
            })
            .catch(() => {
              message.warning('操作失败')
            })
        },
      })
    })
  }

  //违约金操作
  handlePenaltyChange(id) {
    let self = this
    _orderPenaltyInfo({ id }).then((res) => {
      let penaltyData = res.data.data
      let penaltyMoneyStr
      Modal.confirm({
        title: '违约金信息',
        content: (
          <div className="diffModalDiv" style={{ marginTop: 30 }}>
            <Form.Item label="配置期数">{penaltyData.monthNum}期</Form.Item>
            <Form.Item label="配置违约金">
              {penaltyData.penaltyMoneyConfig}元
            </Form.Item>
            {!!penaltyData.penaltyTime && (
              <Form.Item label="违约金支付时间">
                {penaltyData.penaltyTime}
              </Form.Item>
            )}
            <Form.Item label="应付违约金">
              {penaltyData.penaltyMoneyGoal}元
            </Form.Item>
            <Form.Item label="实付期数">{penaltyData.payNum}期</Form.Item>
            {!penaltyData.penaltyTime && (
              <Form.Item label="实付违约金金额">
                <InputNumber
                  min={0}
                  style={{ width: 200 }}
                  onChange={(value) => {
                    // if (
                    //   penaltyData.lowPenaltyMoney &&
                    //   value < penaltyData.lowPenaltyMoney
                    // ) {
                    //   message.warn(
                    //     `最低违约金金额为${penaltyData.lowPenaltyMoney}元。`
                    //   );
                    // }
                    penaltyMoneyStr = value
                  }}
                  allowClear
                  placeholder="请输入实付违约金金额"
                />
                {/* {penaltyData.lowPenaltyMoney && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *最低违约金金额为{penaltyData.lowPenaltyMoney}元。
                  </span>
                )} */}
              </Form.Item>
            )}
          </div>
        ),
        onOk() {
          return new Promise(async (resolve, reject) => {
            if (penaltyMoneyStr === 1 && !penaltyData.penaltyTime) {
              Modal.confirm({
                content: '是否联系小哥确认取消?',
                onOk: async () => {
                  await self.penaltyChange({
                    id,
                    penaltyMoneyStr,
                  })
                  resolve()
                },
                onCancel() {
                  reject()
                },
              })
            } else if (!penaltyData.penaltyTime) {
              await self.penaltyChange({
                id,
                penaltyMoneyStr,
              })
              resolve()
            } else {
              resolve()
            }
          })
        },
      })
    })
  }

  //违约金修改
  async penaltyChange({ id, penaltyMoneyStr }) {
    let self = this
    let params = { id, penaltyMoneyStr }
    const res = await _savePackageOrder(params)
    if (res.data.code === 200) {
      message.success('操作成功')
      self.getPackageOrderList(
        self.state.curPage,
        self.state.pageSize,
        self.state.queryFormValues
      )
    } else {
      message.warning(res.data.message)
    }
  }

  //结束操作
  handleOverChange(orderId, dayDeductSum) {
    let self = this
    Modal.confirm({
      title: '信息',
      content: dayDeductSum
        ? '该订单含有未完成的日还款代扣任务，是否继续关闭订单？'
        : '确定结束当前租车订单吗？',
      onOk() {
        let params = { orderId }
        _orderCancel(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              self.getPackageOrderList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              )
            } else {
              message.warning(res.data.message)
            }
          })
          .catch(() => {
            message.warning('操作失败')
          })
      },
    })
  }

  //挂起操作
  handleHangUpChange({ id }) {
    this.hangRef.open({ id })
  }
  handleHangCancel({ id }) {
    Modal.confirm({
      title: '提示?',
      content: '取消挂起后，未支付的挂起金额将清除',
      okText: '确认取消挂起',
      onOk: async () => {
        const res = await _cancelOrderHangHp({ orderId: id })
        const { code, message: msg, data } = res.data || {}
        if (code !== 200) {
          message.warning(msg)
          return
        }
        if (!data) {
          message.success('操作成功')
          this.getPackageOrderList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          )
          return
        }
        Modal.confirm({
          content:
            data == 2
              ? '订单中的设备被禁用，取消挂起请绑定新设备（前去支付宝小程序）'
              : '订单内设备已被其他订单使用，取消挂起请绑定新设备（前去支付宝小程序）',
        })
      },
    })
  }
  //获取订单备注列表
  getOrderRemarkList(orderId) {
    let params = {
      orderId,
      curPage: 1,
      pageSize: 999,
    }
    _orderRemarkList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderRemarkList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //备注信息Modal打开事件
  showModalRemarks(id) {
    this.getOrderRemarkList(id)
    this.setState({
      orderId: id,
      remarksVisible: true,
    })
  }

  //备注信息Modal提交事件
  handleRemarksOk(e) {
    e.preventDefault()
    let params = {
      orderId: this.state.orderId,
      content: this.state.orderRemark,
    }
    _saveOrderRemark(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            orderId: null,
            content: null,
            remarksVisible: false,
          })
          message.success('操作成功')
        } else {
          message.warning(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //备注信息Modal取消事件
  initRemarksModal() {
    this.setState({
      orderId: null,
      content: null,
      remarksVisible: false,
    })
  }
  //Modal打开事件
  showModal(id, orderSnInfo, orderStatus) {
    this.getBikeOrderList(id)
    this.getExceptionOrderList(orderSnInfo)
    this.getBatteryOrderList(id)
    this.getPackageOrderLogList(id)
    this.getPerformanceRecordList(id)
    this.getDayDeductMonth(id)
    this.getAuthInfo(id)
    this.getOrderStatusLogRecordList(id)
    this.getOrderDeviceImages(id)
    this.getOrderChangeList(id)
    this.getOrderRefundLogs(id)
    this.setState({
      orderSnInfo,
      orderStatus,
      visible: true,
    })
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault()
    this.setState({
      visible: false,
      orderSnInfo: null,
      orderStatus: null,
      monthObj: {},
      activeMonth: null,
    })
  }

  //Modal取消事件
  initModal() {
    this.setState({
      visible: false,
      orderSnInfo: null,
      orderStatus: null,
      monthObj: {},
      activeMonth: null,
    })
  }
  // 调单关联站点列表
  async getChangeSiteList(sid) {
    const { orderId, orderStatus } = this.state
    const params = {
      order_id: orderId,
      type: orderStatus == 2 ? 0 : 1,
      userType: localStorage.getItem('type')
        ? Number(localStorage.getItem('type'))
        : '',
    }
    const res = await _selectOrderChange(params)
    const { data = [] } = res.data || {}
    let list = data || []
    list = list.filter((item) => item.sid != sid)
    this.setState({
      changeSiteList: list,
    })
  }
  //Modal打开事件（调单）
  showModalSingle({ id, fid, sid, sname, rname, orderStatus, rid, pid }) {
    this.setState(
      {
        sname: orderStatus == 2 ? sname : rname,
        fid,
        rid,
        pid,
        orderId: id,
        orderStatus,
        visibleSingle: true,
      },
      () => {
        this.getChangeSiteList(orderStatus == 2 ? sid : rid)
      }
    )
  }

  //Modal提交事件（调单）
  async handleOkSingle(e) {
    e.preventDefault()
    const { orderStatus } = this.state
    let params = {
      order_id: this.state.orderId,
      site_id: Number(this.state.newSid),
    }
    const res = await (orderStatus === 2
      ? _orderChange(params)
      : _orderChangeSiteRepair(params))
    if (res.data.code === 200) {
      message.success('操作成功')
      this.getPackageOrderList(
        this.state.curPage,
        this.state.pageSize,
        this.state.queryFormValues
      )
    } else {
      message.warning(res.data.message)
    }
    this.initModalSingle()
  }

  //更换站点
  handleSiteChange(value) {
    this.setState({
      newSid: value,
    })
  }

  //Modal取消事件（调单）
  initModalSingle() {
    this.setState({
      siteList: [],
      newSid: null,
      orderId: null,
      fid: null,
      pid: null,
      sname: null,
      visibleSingle: false,
      orderStatus: null,
      rid: null,
      repairSiteList: [],
    })
  }

  //Modal打开事件（修改）
  showModalModification(orderId, status, endTime, orderSn) {
    this.setState({
      orderId,
      status,
      endTime,
      disabledTime: moment.unix(endTime).add(35, 'days'),
      orderSn,
      visibleModification: true,
    })
  }

  handleStatusChange(value) {
    this.setState({
      orderStatus: value,
    })
  }

  handleEndTimeChange(date) {
    this.setState({
      endTime: moment(date).valueOf() / 1000,
    })
  }

  //Modal提交事件（修改）
  handleOkModification(e) {
    e.preventDefault()
    let params = {
      id: this.state.orderId,
      endTime: this.state.endTime
        ? moment.unix(this.state.endTime).format('YYYY-MM-DD')
        : this.state.endTime,
      orderStatus: this.state.orderStatus,
    }
    _savePackageOrder(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success('操作成功')
          this.getPackageOrderList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          )
        } else {
          message.warning(res.data.message)
        }
        this.setState({
          orderId: null,
          orderSn: null,
          status: null,
          orderStatus: null,
          endTime: null,
          disabledTime: null,
          visibleModification: false,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //Modal取消事件（调单）
  initModalModification() {
    this.setState({
      orderId: null,
      orderSn: null,
      status: null,
      orderStatus: null,
      endTime: null,
      disabledTime: null,
      visibleModification: false,
    })
  }
}

PackageOrderManagement = Form.create({})(PackageOrderManagement)
export { PackageOrderManagement }
