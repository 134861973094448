import React, { useEffect } from "react";
import useList from "../../../../../hooks/list";
import { Form, Button, Input, Select, Row, Col } from "antd";
const { Option } = Select;

var Index = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const { franchiseeList, getFranchiseeList } = useList();
  const userType = localStorage.getItem("type");
  useEffect(() => {
    getFranchiseeList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      search(values);
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="套餐名称">
              {getFieldDecorator("pname")(<Input placeholder="套餐名称" />)}
            </Form.Item>
          </Col>
          {
            userType != 0 ? null :
            <Col lg={5} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          }
          <Col lg={5} md={12} sm={24}>
            <Form.Item label="审核状态">
              {getFieldDecorator("status")(
                <Select placeholder="请选择" allowClear>
                  <Option value={""}>全部</Option>
                  <Option value={1}>申请中</Option>
                  <Option value={2}>已通过</Option>
                  <Option value={3}>已拒绝</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={4} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

Index = Form.create({})(Index);
export default Index;
