import React, { Component } from 'react'
import {
  Button,
  Table,
  Form,
  DatePicker,
  Input,
  Select,
  message,
  Row,
  Col,
} from 'antd'
import TransferSelect from '@/components/transferSelect'
import {
  _packagesOrderLogList,
  _franchiseeList,
  _siteBoxList,
  _packageBoxList,
  _packageList,
  _exportpackagesOrderLogList,
} from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import moment from 'moment'
import queryString from 'query-string'

const Option = Select.Option
const RangePicker = DatePicker.RangePicker
const orderStatusOptions = [
  { key: 1, title: '已下单' },
  { key: 2, title: '待提车' },
  { key: 3, title: '租车中' },
  { key: 4, title: '已超时' },
  { key: 5, title: '已完成' },
  { key: 6, title: '申请退租' },
  { key: 7, title: '待生效' },
  { key: 8, title: '已关闭' },
  { key: 9, title: '待签约' },
]

class PackagesOrderLogList extends Component {
  constructor(props) {
    super(props)
    const { orderStatus, fid } = queryString.parse(this.props.location.search)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packagesOrderLogList: [], //套餐订单列表
      franchiseeList: [], //代理商列表
      siteList: [], //站点列表
      packageList: [], //套餐列表
      queryFormValues: {
        orderStatusList: orderStatus ? [orderStatus] : [3, 4, 5],
        fid: fid ? Number(fid) : undefined,
      },
      payTime: [moment().startOf('month'), moment()],
      handleTime: Date.now(),
      loading: false,
    }

    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '订单编号',
        align: 'right',
        width: 105,
        dataIndex: 'orderSn',
      },
      {
        title: '流水号',
        align: 'right',
        width: 120,
        dataIndex: 'tradeNo',
      },
      {
        title: '结算流水号',
        dataIndex: '',
        align: 'left',
        width: 120,
        render: (text) => (
          <span>
            {text.settleNo ? text.settleNo : null}
            <br />
            {text.settleTimeStr ? text.settleTimeStr : null}
          </span>
        ),
      },
      {
        title: '订单所属站点',
        dataIndex: '',
        width: 160,
        render: (text) => (
          <span>
            {text.fname}
            <br />
            {text.sname}
          </span>
        ),
      },
      {
        title: '套餐名',
        width: 160,
        dataIndex: 'pname',
      },
      {
        title: '套餐金额',
        align: 'right',
        width: 90,
        dataIndex: 'pmoney',
      },
      {
        title: '支付时间',
        width: 120,
        dataIndex: 'payTimeStr',
      },
      {
        title: '支付方式',
        width: 100,
        dataIndex: 'payTypeName',
      },
      {
        title: '折扣金额',
        width: 90,
        align: 'right',
        dataIndex: 'discountMoney',
      },
      {
        title: '流水号金额',
        width: 110,
        align: 'right',
        dataIndex: 'tradeNoMoney',
      },
      {
        title: '单次支付期数',
        width: 120,
        dataIndex: 'periods',
      },
      {
        title: '结算金额',
        width: 90,
        align: 'right',
        dataIndex: 'payMoney',
      },
      {
        title: '实际支付应收金额',
        width: 90,
        align: 'right',
        dataIndex: 'realAmount',
      },
      {
        title: '交易类型',
        width: 130,
        dataIndex: 'tradeTypeStr',
      },
      {
        title: '期数',
        width: 70,
        align: 'right',
        dataIndex: 'payNumber',
      },
      {
        title: '当前订单的状态',
        width: 90,
        dataIndex: 'orderStatusStr',
      },
      {
        title: '租金信息',
        align: 'left',
        width: 160,
        render: (text) => (
          <span>
            车辆租金：{text.bikeMoney ? text.bikeMoney + '元' : '0元'}
            <br />
            电池租金：{text.batteryMoney ? text.batteryMoney + '元' : '0元'}
            <br />
            代理商分润：{text.agentMoney ? text.agentMoney + '元' : '0元'}
            <br />
            平台分润：{text.platformMoney ? text.platformMoney + '元' : '0元'}
            <br />
            二级代理商：
            {text.subAgentMoney ? text.subAgentMoney + '元' : '0元'}
            <br />
            站点分润：{text.tjMoney ? text.tjMoney + '元' : '0元'}
          </span>
        ),
      },
      {
        title: '免赔服务分润',
        align: 'left',
        width: 160,
        render: (text) => (
          <span>
            代理商免赔服务：
            {text.agentInsureMoney ? text.agentInsureMoney + '元' : '0元'}
            <br />
            平台免赔服务：
            {text.platformInsureMoney ? text.platformInsureMoney + '元' : '0元'}
          </span>
        ),
      },
      {
        title: '车辆',
        width: 120,
        dataIndex: 'bikeVinCode',
      },
      {
        title: '车辆型号',
        width: 120,
        dataIndex: 'bikeModelName',
      },
      {
        title: '车辆类型',
        width: 120,
        dataIndex: 'bikeModelType',
      },
      {
        title: '电池',
        width: 120,
        dataIndex: 'batVinCode',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '电池型号',
        width: 120,
        dataIndex: 'batModelName',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '电池类型',
        width: 120,
        dataIndex: 'batModelType',
      },
      {
        title: '代扣类型',
        width: 100,
        dataIndex: 'deductType',
      },
      {
        title: '下单时间',
        width: 120,
        dataIndex: 'addtimeStr',
      },
      {
        title: '本期起止时间',
        dataIndex: '',
        width: 130,
        render: (text) => text.startDateStr + ' ~ ' + text.endDateStr,
      },

      {
        title: '用户信息',
        dataIndex: '',
        width: 130,
        render: (text) => text.realname + text.mobile,
      },
      {
        title: '推荐人信息',
        dataIndex: '',
        width: 130,
        render: (text) =>
          text.tjMobile
            ? text.tjMobile +
              (text.tjKid ? '(其他用户)' : text.tjFid ? '(BD)' : '')
            : '',
      },
      {
        title: '发车人',
        dataIndex: '',
        width: 130,
        render: (text) => {
          if (text.operatorVO) {
            return (
              (text.operatorVO.fcUserName || '') +
              (text.operatorVO.fcMobile || '')
            )
          } else {
            return ''
          }
        },
      },
      {
        title: '维修项',
        dataIndex: 'repairName',
        width: 130,
      },
      {
        title: '退款记录',
        dataIndex: '',
        align: 'left',
        width: 180,
        render: (text) =>
          text.refundStatus === 1 && (
            <span>
              退款状态：已退款
              <br />
              退款金额：{text.refundAmount}元
              <br />
              备注：{text.refundRemark}
            </span>
          ),
      },
      {
        title: '套餐金额即时分润权重',
        width: 110,
        dataIndex: 'transferTypeLabel',
      },
      {
        title: '分润记录',
        dataIndex: 'transferList',
        align: 'left',
        width: 180,
        render: (text) => (
          <span style={{ whiteSpace: 'pre-line' }}>
            {text &&
              text.map((item) => {
                return (
                  item.flag +
                  '：' +
                  item.transferAmount +
                  '元\n\r' +
                  item.transTradeNo +
                  '\n\r' +
                  moment(item.transferTime).format('YYYY-MM-DD HH:mm') +
                  '\n\r'
                )
              })}
          </span>
        ),
      },
      {
        title: '是否结算',
        dataIndex: '',
        width: 100,
        render: (text) =>
          text.needSettle === 1
            ? text.settleNo
              ? text.settleNo + '已结算'
              : '未结算'
            : '',
      },
      //2023/10/10  取消结束操作
      // {
      //   title: "操作",
      //   dataIndex: "",
      //   width: 80,
      //   key: "Action",
      //   fixed: "right",
      //   render: (text) =>
      //     text.needSettle === 1 &&
      //     !text.settleNo && (
      //       <Button
      //         type="link"
      //         disabled={!getJurisdiction(1043)}
      //         title={getJurisdiction(1043) ? "" : "暂无操作权限！"}
      //         onClick={this.handleOverChange.bind(this, text.tradeNo)}
      //       >
      //         结算
      //       </Button>
      //     ),
      // },
    ]
  }
  // 筛选表头
  renderForm() {
    const { queryFormValues } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator('orderSn')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="流水号">
              {getFieldDecorator('tradeNo')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem('type') === '0' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator('fid', {
                  initialValue: queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.handleFidChange.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator('multiSId')(
                <Select
                  mode="multiple"
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  {this.state.siteList.length &&
                    this.state.siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐">
              {getFieldDecorator('pids')(
                <TransferSelect
                  dataSource={this.state.packageList.map((item) => ({
                    key: item.id,
                    title: item.pname,
                  }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="下单时间">
              {getFieldDecorator('addTime')(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="支付时间">
              {getFieldDecorator('payTime', {
                initialValue: this.state.payTime,
                valuePropName: 'defaultValue',
              })(<RangePicker format="YYYY-MM-DD" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户/手机号">
              {getFieldDecorator('mobile')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="当前状态">
              {getFieldDecorator('orderStatusList', {
                initialValue: queryFormValues.orderStatusList,
              })(<TransferSelect dataSource={orderStatusOptions} />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代扣类型">
              {getFieldDecorator('deductType')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>智能合同代扣</Option>
                  <Option value={2}>宝付银行卡代扣</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="交易类型">
              {getFieldDecorator('tradeTypes')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>订单</Option>
                  <Option value={2}>免赔服务</Option>
                  {localStorage.getItem('type') === '0' && (
                    <Option value={3}>逾期租金违约金</Option>
                  )}
                  {localStorage.getItem('type') === '0' && (
                    <Option value={'1,3'}>订单+逾期租金违约金</Option>
                  )}
                  <Option value={4}>退租违约金</Option>
                  <Option value={'5,16'}>维修费用</Option>
                  <Option value={6}>现金押金</Option>
                  <Option value={'10,18'}>分期付</Option>
                  <Option value={11}>套餐基础服务费</Option>
                  <Option value={13}>拖车费（资寻费）</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="是否结算">
              {getFieldDecorator('needSettle')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>已结算</Option>
                  <Option value={0}>未结算</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="结算时间">
              {getFieldDecorator('settleTime')(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(854)}
                title={getJurisdiction(854) ? '' : '暂无操作权限！'}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const { totalCount, curPage, pageSize, packagesOrderLogList, loading } =
      this.state
    return (
      <div>
        {!!getJurisdiction(853) ? (
          <div className="PackagesOrderLogList">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={packagesOrderLogList}
                scroll={{ x: 2300, y: 500 }}
                loading={loading}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    const fid = localStorage.getItem('fid') || undefined
    if(localStorage.getItem('type') === '0'){
      this.getFranchiseeList()
      this.getPackageList()
    }else{
      this.getSiteList(fid)
      this.getPackageList(fid)
    }
    // const { payTime } = this.state.queryFormValues;
    const values = {
      ...this.state.queryFormValues,
      payTimeStart: moment(this.state.payTime[0]).format('YYYY-MM-DD'),
      payTimeEnd: moment(this.state.payTime[1]).format('YYYY-MM-DD'),
    }
    this.setState({
      queryFormValues: values,
    })
    this.getPackagesOrderLogList(
      this.state.curPage,
      this.state.pageSize,
      values
    )
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //结束操作
  // handleOverChange(tradeNo) {
  //   let self = this;
  //   Modal.confirm({
  //     title: tradeNo,
  //     content: "确定结算该笔流水？",
  //     onOk() {
  //       let params = { tradeNo };
  //       _orderLogSettle(params)
  //         .then((res) => {
  //           if (res.data.code === 200) {
  //             message.success("操作成功");
  //             self.getPackagesOrderLogList(
  //               self.state.curPage,
  //               self.state.pageSize,
  //               self.state.queryFormValues
  //             );
  //           } else {
  //             message.warning(res.data.message);
  //           }
  //         })
  //         .catch(() => {
  //           message.warning("操作失败");
  //         });
  //     },
  //   });
  // }
  handleFidChange(value){
    this.props.form.setFieldsValue({
      multiSId: [],
      pids: []
    })
    this.getSiteList(value)
    this.getPackageList(value)
  }
  //获取站点列表
  getSiteList(value) {
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    }
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //获取套餐列表
  async getPackageList(fid) {
    let params = {
      curPage: 1,
      pageSize: 9999,
      fid,
    }
    const res = await (fid ? _packageList(params) : _packageBoxList(params))
    const {data} = res.data || {}
    const {rows=[]} = data || {}
    this.setState({
      packageList: rows || [],
    })
  }

  //获取套餐订单列表
  async getPackagesOrderLogList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    }
    let currentTime = Date.now()
    this.setState({
      loading: true,
      handleTime: currentTime,
    })
    const res = await _packagesOrderLogList(params)
    this.setState({
      loading: false,
    })
    if (currentTime != this.state.handleTime) {
      return
    }
    if (res.data.code === 200) {
      if (!res.data.data.rows.length) {
        message.warning('暂无数据！')
      }
      this.setState({
        packagesOrderLogList: res.data.data.rows,
        totalCount: res.data.data.totalCount,
        curPage: curPage,
      })
    }
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackagesOrderLogList(curPage, pageSize, this.state.queryFormValues)
  }

  //不可选择时间
  disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.settleTime && values.settleTime.length) {
        values.settleTimeStart = moment(values.settleTime[0]).format(
          'YYYY-MM-DD'
        )
        values.settleTimeEnd = moment(values.settleTime[1]).format('YYYY-MM-DD')
      }
      delete values.settleTime
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format('YYYY-MM-DD')
        values.payTimeEnd = moment(values.payTime[1]).format('YYYY-MM-DD')
      }
      delete values.payTime
      if (values.orderStatusList && !values.orderStatusList.length) {
        delete values.orderStatusList
      }
      if (values.multiSId && !values.multiSId.length) {
        delete values.multiSId
      }
      this.setState({
        queryFormValues: values,
      })
      this.getPackagesOrderLogList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.addTime && values.addTime.length) {
        values.addTimeStart = moment(values.addTime[0]).format('YYYY-MM-DD')
        values.addTimeEnd = moment(values.addTime[1]).format('YYYY-MM-DD')
      }
      delete values.addTime
      if (values.settleTime && values.settleTime.length) {
        values.settleTimeStart = moment(values.settleTime[0]).format(
          'YYYY-MM-DD'
        )
        values.settleTimeEnd = moment(values.settleTime[1]).format('YYYY-MM-DD')
      }
      delete values.settleTime
      if (values.payTime && values.payTime.length) {
        values.payTimeStart = moment(values.payTime[0]).format('YYYY-MM-DD')
        values.payTimeEnd = moment(values.payTime[1]).format('YYYY-MM-DD')
      }
      delete values.payTime
      if (values.orderStatusList && !values.orderStatusList.length) {
        delete values.orderStatusList
      }
      if (values.multiSId && !values.multiSId.length) {
        delete values.multiSId
      }
      let params = {
        ...values,
      }
      _exportpackagesOrderLogList(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}
PackagesOrderLogList = Form.create({})(PackagesOrderLogList)
export { PackagesOrderLogList }
