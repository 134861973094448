import React,{
    useEffect,
    useState,
    useRef,
} from 'react';
import './index.less'
import OrderBar from './components/orderBar'
import TurnoverLine from './components/turnoverLine'
import ConditionLiqiud from './components/conditionLiqiud'
import NumberCard from './components/numberCard'
import Map3d from './components/map3d'
import MapBase from './components/mapBase'
import TableCard from './components/tableCard'
import RankCard from './components/rankCard'
import {
    _corporationOrderRanking,
    _corporationMapOrderList,
    _corporationMoneySumList,
    _monthOrderSize,
    _moneySumList,
    _corporationMoneyRankingList,
    _selectAllSize,
} from "@/statics/js/api"

const Index = () => {
    const orderBarRef = useRef()
    const turnoverLineRef = useRef()
    const conditionLiqiudRef = useRef()
    const map3dRef = useRef()
    const mapBaseRef = useRef()
    const [allData,setAllData] = useState({
        batteryChargeSize:0,
        batterySize:0,
        batteryUseScale:0,
        bikeSize:0,
        bikeUseScale:0,
        moneySum:0,
        orderSize:0,
        turnoverSize:0,
        userSize:0,
    })
    const [moneyRank,setMoneyRank] = useState([])
    const [orderRank,setOrderRank] = useState([])
    const [userRank,setUserRank] = useState([])
    const [mapType,setMapType] = useState(0)
    let timer = null
    useEffect(()=>{
        init()
        return ()=>{
            clearTimeout(timer)
            timer = null
        }
    },[])
    const init = async ()=>{
        clearTimeout(timer)
        timer = null
        await Promise.all([
            initMonthMoney(),
            initMonthOrder(),
            initAllData(),
            initMoneyRank(),
            initMonthOrderRank(),
            initUserRank(),
            initMapOrder(),
        ])
        timer = setTimeout(()=>{
            init()
        },20000)
    }
    // 月营业额
    const initMonthMoney = async ()=>{
        const {data:{data=[]}} = await _moneySumList()
        let options = {
            xdata: data.map(item=>item.date),
            ydata: data.map(item=>(item.money/10000).toFixed(2))
        }
        turnoverLineRef.current.init(options)
    }
    // 月订单
    const initMonthOrder = async ()=>{
        const {data:{source=[]}} = await _monthOrderSize()
        let options = {
            xdata: source.map(item=>item.product),
            ydata: source.map(item=>item.data)
        }
        orderBarRef.current.init(options)
    }
    // 数据汇总
    const initAllData = async ()=>{
        const res = await _selectAllSize()
        const data = res.data || {}
        setAllData(data)
        conditionLiqiudRef.current.init({
            bike: parseFloat(data.bikeUseScale)/100,
            battery: parseFloat(data.batteryUseScale)/100,
        })
    }
    // 城市月营业额排行
    const initMoneyRank = async ()=>{
        const {data:{data=[]}} = await _corporationMoneyRankingList()
        const list = data.map(item=>({
            name: item.fname,
            value: item.money
        }))
        setMoneyRank(list)
    }
    // 月订单排行
    const initMonthOrderRank = async ()=>{
        const {data:{data=[]}} = await _corporationOrderRanking()
        const list = data.map(item=>({
            name: item.fname,
            value: item.orderSize
        }))
        setOrderRank(list)
    }
    // 总用户排行
    const initUserRank = async ()=>{
        const {data:{data=[]}} = await _corporationMoneySumList()
        const list = data.map(item=>({
            name: item.fname,
            value: item.userSize
        }))
        setUserRank(list)
    }
    // 地图数据
    const initMapOrder = async ()=>{
        const {data:{data=[]}} = await _corporationMapOrderList()
        const list = data.map(item=>{
            let [lng=0,lat=0] = item.address ? item.address.split(',') : []
            return {
                value: [parseFloat(lng),parseFloat(lat),item.orderSize],
                number: item.orderSize,
            }
        })
        mapBaseRef.current.init({data: list})
        // map3dRef.current.init({data: list})
    }
    const handleMapChange = ()=>{
        setMapType((value)=>!value)
    }
    return (
        <div className="board_wrap">
            <div className="left">
                <div className="item">
                    <div className="item_header">近一月营业额</div>
                    <TurnoverLine ref={turnoverLineRef} />
                </div>
                <div className="item">
                    <div className="item_header">近一月订单数</div>
                    <OrderBar ref={orderBarRef} />
                </div>
                <div className="item">
                    <div className="item_header">月新增订单数量排行</div>
                    <TableCard data={orderRank} />
                </div>
            </div>
            <div className="center">
                <div className="center_header">
                    <span>智慧云马</span>
                </div>
                <div className="card_row">
                    <NumberCard title="总营业额" value={allData.moneySum} />
                    <NumberCard title="总订单数" value={allData.orderSize} />
                    <NumberCard title="总用户数" value={allData.userSize} />
                </div>
                <div className="card_map">
                    {/* <button onClick={handleMapChange} className="map_change">triggle</button> */}
                    {/* <div style={{visibility: mapType ? 'visible' : 'hidden',width: '100%',height: '100%'}}>
                        <Map3d ref={map3dRef} />
                    </div> */}
                    {/* <div style={{visibility: !mapType ? 'visible' : 'hidden',width: '100%',height: '100%'}}> */}
                        <MapBase ref={mapBaseRef} />
                    {/* </div> */}
                </div>
                <div className="card_row">
                    <NumberCard title="总车辆数" value={allData.bikeSize} />
                    <NumberCard title="总电池数" value={allData.batterySize} />
                    <NumberCard title="总电柜数" value={allData.batteryChargeSize} />
                </div>
            </div>
            <div className="right">
                <div className="item">
                    <div className="item_header">top10城市月营业额</div>
                    <RankCard data={moneyRank} />
                </div>
                <div className="item">
                    <div className="item_header">活跃情况</div>
                    <ConditionLiqiud ref={conditionLiqiudRef} />
                </div>
                <div className="item">
                    <div className="item_header">总用户量排行</div>
                    <TableCard data={userRank} />
                </div>
            </div>
        </div>
    )
}

export default Index
