import React,{
    useState,
    useEffect,
    useRef,
} from 'react';
import './index.less'

const Index = (props,ref) => {
    const {data=[],rows=7,render,duration=3000,stripe=false} = props
    const [rowHeight,setRowHeight] = useState(0)
    const cardRef = useRef()
    const contentRef = useRef()
    let timer = null
    useEffect(()=>{
        init()
        return ()=>{
            clearInterval(timer)
            timer = null
        }
    },[props.data])
    const init = ()=>{
        let height = cardRef.current.clientHeight / rows
        setRowHeight(height)
        clearInterval(timer)
        timer = null
        cardRef.current.scrollTop = 0
        if(data.length <= rows){
            return
        }
        timer = setInterval(()=>{
            const distance = contentRef.current.clientHeight - cardRef.current.clientHeight - 1
            if(cardRef.current.scrollTop >= distance){
                cardRef.current.scrollTop = 0
            }else{
                cardRef.current.scrollTop = cardRef.current.scrollTop + height
            }
        },duration)
    }
    return (
        <div className="scroll_card" ref={cardRef}>
            <div className="scroll_content" ref={contentRef}>
                {
                    data.map((item,index)=>(
                        <div className={'scroll_item ' + (stripe ? 'scroll_item_stripe' : '')} key={index} style={{height: rowHeight + 'px'}}>
                            {render && render(item,index)}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Index
