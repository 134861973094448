import React, { useEffect, useState, useRef } from "react";
import "./index.less";
import moment from "moment";
import { _batteryTrack } from "@/statics/js/api.js";
import MapCard from "./components/MapCard";
import { Form, Button, DatePicker, message, Card, Slider, Divider } from "antd";
const { RangePicker } = DatePicker;

var Index = (props) => {
  const { form, id } = props;
  const { getFieldDecorator } = form;
  const mapRef = useRef();
  const [filter, setFilter] = useState({
    startTime: moment(
      moment().format("YYYY-MM-DD") + " 00:00:00",
      "YYYY-MM-DD HH:mm:ss"
    ),
    endTime: moment(
      moment().format("YYYY-MM-DD") + " 23:59:59",
      "YYYY-MM-DD HH:mm:ss"
    ),
  });
  const [totalDistance, setTotalDistance] = useState(0);
  const [speed, setSpeed] = useState(500);
  useEffect(() => {
    initData();
  }, []);
  // 开始巡航
  const handleStart = () => {
    mapRef.current.setSpeed(speed);
    mapRef.current.start();
  };
  // 暂停巡航
  const handlePause = () => {
    mapRef.current.pause();
  };
  // 恢复巡航
  const handleResume = () => {
    mapRef.current.resume();
  };
  // 清除巡航线路
  const handleClear = () => {
    mapRef.current.clear();
  };
  // 搜索
  const handleSearch = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        message.warning("请选择日期范围");
        return;
      }
      initData();
    });
  };
  const initData = async () => {
    const values = form.getFieldsValue();
    let [startTime, endTime] = values.date;
    setFilter({
      ...filter,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
    });
    await loadData({
      startTime,
      endTime,
    });
  };
  // 加载数据
  const loadData = async (query = {}) => {
    const { startTime = "", endTime = "" } = query;
    const params = {
      id,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
      pageSize: 1000,
      page: 1,
    };
    const res = await _batteryTrack(params);
    const { data, code, message: msg } = res.data;
    if (code !== 200) {
      message.warning(msg);
      return;
    }
    if (!data) {
      message.warning("暂无数据");
      return;
    }
    const { batteryTraces, totaldisanctAll } = data || {};
    let totalDistance = parseFloat(totaldisanctAll).toFixed(2);
    setTotalDistance(totalDistance);
    const list = [];
    const traces = []
    batteryTraces.map((item) => {
      let { lat, lng } = item;
      list.unshift([parseFloat(lng), parseFloat(lat)]);
      traces.unshift(item)
    });
    mapRef.current.load(list,traces);
  };

  const tipFormatter = (value) => {
    return value + "KM/H";
  };
  const handleSpeedChange = (value) => {
    setSpeed(value);
    mapRef.current.setSpeed(value);
  };
  return (
    <>
      <div className="layout_wrap">
        <div className="left_sider">
          <Card bodyStyle={{ padding: "10px" }}>
            <Form onSubmit={handleSearch} layout="vertical">
              <Form.Item>
                {getFieldDecorator("date", {
                  initialValue: [filter.startTime, filter.endTime],
                  rules: [{ required: true, message: "请选择日期范围" }],
                  valuePropName: "defaultValue",
                })(
                  <RangePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
              {/* <Form.Item>
                            {
                                getFieldDecorator("hour")(
                                    <Radio.Group defaultValue={filter.hour} buttonStyle="solid" className="custom_radio">
                                        <Radio.Button value={24}>24小时</Radio.Button>
                                        <Radio.Button value={12}>12小时</Radio.Button>
                                        <Radio.Button value={6}>6小时</Radio.Button>
                                        <Radio.Button value={3}>3小时</Radio.Button>
                                    </Radio.Group>
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            {
                                getFieldDecorator("day")(
                                    <Radio.Group defaultValue={filter.day} buttonStyle="solid" className="custom_radio">
                                        <Radio.Button value={3}>3天前</Radio.Button>
                                        <Radio.Button value={2}>2天前</Radio.Button>
                                        <Radio.Button value={1}>1天前</Radio.Button>
                                        <Radio.Button value={0}>当日</Radio.Button>
                                    </Radio.Group>
                                )
                            }
                        </Form.Item> */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                {/* <Divider type="vertical" />
                            <Button icon="download">导出</Button> */}
              </Form.Item>
              {/* <Form.Item>
                            <Radio.Group defaultValue="" buttonStyle="solid" className="custom_radio">
                                <Radio.Button value="1">停留点</Radio.Button>
                                <Radio.Button value="2">原始GPS</Radio.Button>
                                <Radio.Button value="3">聚合轨迹</Radio.Button>
                            </Radio.Group>
                        </Form.Item> */}
            </Form>
          </Card>
        </div>
        <div className="content_wrap">
          <Card bodyStyle={{ padding: "10px" }}>
            <div className="track_bar">
              <Button icon="caret-right" onClick={handleStart}>
                播放
              </Button>
              <Button icon="border" onClick={handlePause}>
                暂停
              </Button>
              <Button onClick={handleResume}>恢复</Button>
              {/* <Button>卫星</Button> */}
              <Button onClick={handleClear}>清除</Button>
              <Divider type="vertical" />
              <div className="slider_box" id="slider_box">
                <Slider
                  min={0}
                  max={10000}
                  defaultValue={speed}
                  onChange={handleSpeedChange}
                  tooltipVisible
                  tipFormatter={tipFormatter}
                  getTooltipPopupContainer={() =>
                    document.getElementById("slider_box")
                  }
                />
              </div>
              <Divider type="vertical" />
              <div className="track_tip">
                <div>
                  从{moment(filter.startTime).format("YYYY-MM-DD HH:mm:ss")} 到{" "}
                  {moment(filter.endTime).format("YYYY-MM-DD HH:mm:ss")}
                </div>
                <div>总里程 {totalDistance} km</div>
              </div>
            </div>
            <div className="track_map">
              <MapCard ref={mapRef} />
            </div>
          </Card>
        </div>
        {/* <div className="right_sider">
                <Card bodyStyle={{padding: '10px'}}>
                    
                </Card>
            </div> */}
      </div>
    </>
  );
};

Index = Form.create({})(Index);
export default Index;
