import React,{
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from "echarts";
import mapWithoutHainanIslands from '../map3d/mapWithoutHainanIsLands.json'
import './index.less'

const Index = forwardRef((props,ref) => {
    const myChart = useRef()
    useEffect(()=>{
        echarts.registerMap('china', mapWithoutHainanIslands);
        myChart.current = echarts.init(document.getElementById('map'))
        init({})
    },[])
    const init = async ({data=[]})=>{
        let option = {
            tooltip: {
                show: false,
                trigger: 'item'
              },
              geo: {
                show: false,
                type: 'map',
                roam: false,
                map: 'china',
                selectedMode: false,
                zoom: 1,
              },
              series: [
                {
                  name: '区域',
                  type: 'map',
                  map: 'china',
                  data: [],
                  selectedMode: false,
                  zoom: 1,
                  geoIndex: 1,
                  // left: "0%",
                  // top: "0%",
                  // right: "0%",
                  // bottom: "70px",
                  tooltip: {
                    show: true,
                    backgroundColor: '#00000060',
                    borderColor: 'rgba(147, 235, 248, 0.8)',
                    textStyle: {
                      color: '#FFFFFF',
                      fontSize: 12,
                    }
                  },
                  label: {
                    show: true,
                    color: '#FFFFFF',
                    fontSize: 12,
                  },
                  emphasis: {
                    disabled: false,
                    label: {
                      color: '#FFFFFF',
                      fontSize: 12,
                    },
                    itemStyle: {
                      areaColor: '#389BB7',
                      shadowColor: '#389BB7',
                      borderWidth: 1
                    }
                  },
                  itemStyle: {
                    borderColor: '#93EBF8',
                    borderWidth: 1,
                    areaColor: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.8,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#93ebf800' // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: '#93ebf820' // 100% 处的颜色
                        }
                      ],
                      globalCoord: false
                    },
                    shadowColor: '#80D9F842',
                    shadowOffsetX: -2,
                    shadowOffsetY: 2,
                    shadowBlur: 10
                  }
                },
                {
                  name: '',
                  type: 'effectScatter',
                  coordinateSystem: 'geo',
                  symbolSize: 4,
                  legendHoverLink: true,
                  showEffectOn: 'render',
                  rippleEffect: {
                    scale: 6,
                    color: '#FFFFFF',
                    brushType: 'fill'
                  },
                  tooltip: {
                    show: true,
                    backgroundColor: 'rgba(0,0,0,.6)',
                    borderColor: 'rgba(147, 235, 248, .8)',
                    textStyle: {
                      color: '#FFF'
                    }
                  },
                  label: {
                    formatter: '{b}',
                    fontSize: 11,
                    offset: [0, 2],
                    position: 'bottom',
                    textBorderColor: '#fff',
                    textShadowColor: '#000',
                    textShadowBlur: 10,
                    textBorderWidth: 0,
                    color: '#FFFFFF',
                    show: true
                  },
                  itemStyle: {
                    color: '#FFFFFF',
                    borderColor: 'rgba(225,255,255,2)',
                    borderWidth: 4,
                    shadowColor: '#E1FFFF',
                    shadowBlur: 10
                  },
                  data: data,
                  encode: {
                    value: 2
                  }
                },
            ]
        };
        myChart.current.setOption(option);
    }
    useImperativeHandle(ref, () => ({
        init,
    }),[]);
    return (
        <div id="map" className="map"></div>
    )
})

export default Index
