import React, { useEffect, useState } from "react";
import { Tabs,Badge } from "antd";
import ZFBComplaint from "./ZFBComplaint";
import Feedback from "./Feedback";
import CardComplaint from "./CardComplaint";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _complainMsgSize } from "@/statics/js/api";

const { TabPane } = Tabs;

const Index = () => {
  const userType = localStorage.getItem("type")
  const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
  const [count,setCount] = useState({
    zfbMsg: 0,
    kfMsg: 0,
  })
  useEffect(()=>{
    getCount()
  },[])
  const getCount = async ()=>{
    const params = {
      fid: userType === '0' ? undefined : fid
    }
    const res = await _complainMsgSize(params)
    const {code} = res.data || {}
    if(code === 200){
      const {zfbMsg=0,kfMsg=0} = res.data.data || {}
      setCount({
        zfbMsg,
        kfMsg,
      })
    }
  }
  return !getJurisdiction(1013) ? (
    "暂无操作权限！"
  ) : (
    <Tabs defaultActiveKey='1'>
      <TabPane tab={
        <Badge count={count.zfbMsg} offset={[10,-4]}>
          <span>支付宝投诉</span>
        </Badge>
      } key="1">
        <ZFBComplaint refresh={getCount} />
      </TabPane>
      <TabPane tab={
        <Badge count={count.kfMsg} offset={[10,-4]}>
          <span>卡付投诉</span>
        </Badge>
      } key="2">
        <CardComplaint refresh={getCount} />
      </TabPane>
      {
        userType === '0' &&
        <TabPane tab="用户反馈" key="3">
          <Feedback />
        </TabPane>
      }
    </Tabs>
  );
};

export default Index;
