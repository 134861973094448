import React, { 
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    _checkDeviceException,
} from "@/statics/js/api"
import {
    Form,
    Button,
    Input,
    message,
    Spin
} from "antd";
import './index.less'

const Index = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,getFieldsValue} = form
    const [detail,setDetail] = useState({})
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        
    },[])
    const getDetail = async ()=>{
        const values = await getFieldsValue()
        setLoading(true)
        const res = await _checkDeviceException(values)
        setLoading(false)
        const {data} = res.data
        setDetail(data || {})
    }
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        const {imei} = await getFieldsValue()
        if(!imei){
            message.error('请输入设备编号')
            return
        }
        getDetail()
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline" className='search_wrap'>
            <Form.Item>
                {getFieldDecorator("imei")(
                    <Input placeholder="设备编号" />
                )}
            </Form.Item>
            <Form.Item>
                <Button block type="primary" htmlType="submit">搜索</Button>
            </Form.Item>
        </Form>
        <Spin spinning={loading}>
            <div className='row_wrap' style={{display: Object.keys(detail).length === 0 ? 'none' : 'block'}}>
                <div className='row'>
                    <label>BMS版本检测：</label>
                    <span>{detail.bmsCheck}</span>
                </div>
                <div className='row'>
                    <label>设备编码检测：</label>
                    <span>{detail.uidCheck}</span>
                </div>
                <div className='row'>
                    <label>设备在线状态检测：</label>
                    <span>{detail.onlineCheck}</span>
                </div>
                <div className='row'>
                    <label>充电控制状态检测：</label>
                    <span>{detail.chargeCheck}</span>
                </div>
                <div className='row'>
                    <label>放电控制状态检测：</label>
                    <span>{detail.disChargeCheck}</span>
                </div>
                <div className='row'>
                    <label>电芯检测：</label>
                    <span>{detail.cellCheck}</span>
                </div>
                <div className='row'>
                    <label>当前电量：</label>
                    <span>{detail.current}</span>
                </div>
                <div className='row'>
                    <label>电池电量检测：</label>
                    <span>{detail.batteryLevel}</span>
                </div>
                <div className='row'>
                    <label>MOS温度检测：</label>
                    <span>{detail.tempMos}</span>
                </div>
            </div>
        </Spin>
    </>)
})

export default Index