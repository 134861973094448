import React from "react";
import { Table } from "antd";
import SearchBox from "./components/searchBox";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _tradeList } from "@/statics/js/api.js";
import moment from "moment";

const SerialReport = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "流水号",
      dataIndex: "tradeNo",
    },
    {
      title: "公司名称",
      dataIndex: "fname",
    },
    {
      title: "类型",
      dataIndex: "tradeTypeName",
    },
    {
      title: "金额",
      dataIndex: "payAmount",
    },
    {
      title: "支付方式",
      dataIndex: "payTypeName",
    },
    {
      title: "支付账号",
      dataIndex: "",
    },
    {
      title: "报修批号",
      dataIndex: "repairNo",
    },
    {
      title: "账单号",
      dataIndex: "billNo",
    },
    {
      title: "支付时间",
      width: 120,
      dataIndex: "payTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
  } = useTable({
    action: _tradeList,
  });

  return !getJurisdiction(991) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 2000, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
    </div>
  );
};

export default SerialReport;
