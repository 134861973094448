import React, { 
  useEffect,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Divider,
  message
} from "antd";
import axios from "@/statics/js/request";

const Index = ()=> {
  const handleTest = async ()=>{
      const res = await axios.post("https://94b4-240e-391-eba-9c70-848b-181d-472b-be9a.ngrok-free.app/unifiedOrder/create", {
        "mchPayOrderNo": "N0001",
        "payChannel": 2,
        "payWay": 25,
        "amount": 1,
        "currency": "CNY",
        "subject": "测试",
        "body": "测试消息",
        "channelUser": "",
        "expiredTime": 1800,
        "mchFeeRate": 1,
        "channelExtra": "",
        "extParam": "",
        "clientIp": "192.168.0.86"
      }).catch((err)=>{console.log(err)})
      console.log(res)
      const {data,massage:msg} = res.data || {}
      const {payInfo} = data || {}
      if(payInfo){
        window.location.href = payInfo
      }else{
        message.warning(msg);
      }
  }
  return (<div>
    <Button type="primary" onClick={handleTest}>支付</Button>
    <Divider />
  </div>)
}

export default Index