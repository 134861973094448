import React, { Component, createRef } from 'react'
import {
  Row,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Table,
  Modal,
  Button,
  Switch,
  Radio,
  Select,
  Divider,
  message,
} from 'antd'
import Benefit from '@/views/packageMGT/package/components/edit/benefit'
import {
  _getBikeModels,
  _getRuleDetails,
  _franchiseeList,
  _packageBoxList,
  _savePackageTranferRuleInfo,
  _deletePackageTranferRuleInfo,
  _getPagePackageTranferRuleInfo,
  _franchiseeDetail,
  _getRuleDetailsById,
  _exportPackageTransferRuleList,
} from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import './style.less'
const { Option } = Select
const modalTitle = ['添加套餐分润规则', '编辑套餐分润规则', '套餐分润规则详情']
class PackageTranferRule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      id: '', //套餐分润id
      modalType: '', //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      packageList: [], //套餐列表
      initialValues: {}, //套餐分润详情
      franchiseeList: [], //代理商列表
      queryFormValues: {}, //筛选项
      packageTranferRuleList: [], //套餐分润列表
      packageItem: {},
      ruleShow: false,
      exportload: false,
    }
    this.benefitRef = createRef()
    this.columns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'id',
      },
      {
        title: '套餐名称',
        width: 180,
        dataIndex: 'pname',
      },
      {
        title: '代理商',
        width: 180,
        dataIndex: 'fname',
      },
      {
        title: '转账账户名称',
        width: 180,
        dataIndex: 'transferAccountName',
      },
      {
        title: '转账账户',
        width: 180,
        dataIndex: 'transferAccount',
      },
      {
        title: '套餐金额',
        width: 180,
        dataIndex: 'pmoney',
      },
      {
        title: '免赔服务',
        width: 180,
        dataIndex: 'insureMoney',
      },
      {
        title: '套餐基础服务费',
        width: 180,
        dataIndex: 'serviceMoney',
      },
      {
        title: '商家基础服务费',
        width: 180,
        dataIndex: 'custServiceMoney',
      },
      {
        title: '套餐不即时分润金额',
        width: 180,
        dataIndex: 'notTransferMoney',
      },
      {
        title: '套餐分润平台占比',
        width: 180,
        dataIndex: 'transferRatio',
      },
      {
        title: '免赔服务分润平台占比',
        width: 180,
        dataIndex: 'insureRatio',
      },
      {
        title: '套餐转账金额',
        width: 180,
        dataIndex: 'custTransferAmount',
      },
      {
        title: '免赔服务转账金额',
        width: 180,
        dataIndex: 'custInsureAmount',
      },
      {
        title: '添加时间',
        width: 180,
        dataIndex: 'addTime',
      },
      {
        title: '备注',
        width: 180,
        dataIndex: 'remark',
      },
      {
        title: '管理状态',
        width: 90,
        dataIndex: '',
        render: (text) => (
          <Switch
            defaultChecked={!!text.packageTransferStatus}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            disabled={!getJurisdiction(881)}
            title={getJurisdiction(881) ? '' : '暂无操作权限！'}
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        key: 'Action',
        fixed: 'right',
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(865)}
              title={getJurisdiction(865) ? '' : '暂无操作权限！'}
              onClick={this.handleRuleDetails.bind(this, text.id, 3)}
            >
              查看
            </Button>
            {/* <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(863)}
              title={getJurisdiction(863) ? "" : "暂无操作权限！"}
              onClick={this.getPackageRuleDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button> */}
          </span>
        ),
      },
    ]
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator('fidSearch')(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐名称">
              {getFieldDecorator('pidSearch')(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.packageList &&
                    this.state.packageList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.pname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  render() {
    const {
      totalCount,
      curPage,
      pageSize,
      modalType,
      packageItem,
      packageTranferRuleList,
      initialValues,
    } = this.state
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    }
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 5 },
        sm: { span: 12, offset: 5 },
        md: { span: 10 },
      },
    }
    return (
      <div>
        {getJurisdiction(862) ? (
          <div className="PackageTranferRule">
            <div className="select-form">{this.renderForm()}</div>
            {/* <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(864)}
                title={getJurisdiction(864) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加套餐分润规则
              </Button>
            </div> */}
            <div className="content-table">
              <Table
                scroll={{ x: 1300, y: 500 }}
                rowKey="id"
                columns={this.columns}
                dataSource={packageTranferRuleList}
                bordered
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="套餐名称">
                    {modalType === 3 ? (
                      <span>{initialValues.pname}</span>
                    ) : (
                      getFieldDecorator('pid', {
                        initialValue: initialValues.pid,
                        rules: [
                          { required: true, message: '请选择套餐名称！' },
                        ],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: '100%' }}
                          onChange={this.getBikeModels.bind(this)}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            )
                          }}
                        >
                          {this.state.packageList &&
                            this.state.packageList.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.pname +
                                    '（套餐金额:' +
                                    item.money +
                                    '元 / 免赔服务:' +
                                    item.insureMoney +
                                    '元）'}
                                </Option>
                              )
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator('fid', {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: '请选择代理商！' }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: '100%' }}
                          showSearch
                          onChange={this.handleFranchiseeChange.bind(this)}
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            )
                          }}
                        >
                          {this.state.franchiseeList &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              )
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                {this.state.bikeModels && this.state.bikeModels.length > 1 && (
                  <Col span={24}>
                    {this.renderPeriodItems(
                      formItemLayout,
                      formItemLayoutWithOutLabel
                    )}
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="转账账户名称">
                    {modalType === 3 ? (
                      <span>{initialValues.transferAccountName}</span>
                    ) : (
                      getFieldDecorator('transferAccountName', {
                        initialValue: initialValues.transferAccountName,
                        rules: [
                          { required: true, message: '请输入转账账户名称！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账账户">
                    {modalType === 3 ? (
                      <span>{initialValues.transferAccount}</span>
                    ) : (
                      getFieldDecorator('transferAccount', {
                        initialValue: initialValues.transferAccount,
                        rules: [
                          { required: true, message: '请输入转账账号！' },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="套餐转账金额">
                    <Form.Item>
                      {getFieldDecorator('transferType', {
                        initialValue: initialValues.transferType,
                      })(
                        <Radio.Group
                          disabled={modalType === 3}
                          onChange={() => {
                            setFieldsValue({
                              transferMoney: packageItem.money,
                            })
                          }}
                        >
                          <Radio value={1}>
                            套餐金额全部参与分润{' '}
                            <span style={{ color: '#999' }}>
                              {console.log(packageItem)}
                              {packageItem.money &&
                                '(套餐金额：' + packageItem.money + '元)'}
                            </span>
                          </Radio>
                          <Radio value={2}>
                            套餐金额部分参与分润{' '}
                            <span style={{ color: '#999' }}>
                              {packageItem.money &&
                                '(套餐金额：' + packageItem.money + '元)'}
                            </span>
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    <div className="transferDiv">
                      转账金额：
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('transferMoney', {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              message: '请输入正确的金额（保留两位小数）',
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                            { required: true, message: '请输入转账金额！' },
                          ],
                          initialValue:
                            modalType !== 1 &&
                            getFieldValue('transferType') == 1
                              ? initialValues.pmoney
                              : initialValues.transferMoney,
                        })(
                          <InputNumber
                            size="small"
                            min={0}
                            max={packageItem.money && packageItem.money}
                            className="inputNumberWidth"
                            disabled={
                              getFieldValue('transferType') == 1 ||
                              modalType === 3
                            }
                            onChange={(value) => {
                              setFieldsValue({
                                transferAmount: this.getTransferAmount(
                                  value,
                                  getFieldValue('transferRatio'),
                                  getFieldValue('transferType') === 2
                                ),
                              })
                            }}
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">x</span>
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('transferRatio', {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              message: '请输入正确的比例（保留两位小数）',
                              pattern:
                                /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/,
                            },
                            { required: true, message: '请输入转账比例！' },
                          ],
                          initialValue: initialValues.transferRatio,
                        })(
                          <InputNumber
                            size="small"
                            min={0}
                            max={100}
                            className="inputNumberWidth"
                            onChange={(value) => {
                              setFieldsValue({
                                transferAmount: this.getTransferAmount(
                                  getFieldValue('transferMoney'),
                                  value,
                                  getFieldValue('transferType') === 2
                                ),
                              })
                            }}
                            disabled={modalType === 3}
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">% =</span>
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('transferAmount', {
                          initialValue: initialValues.transferAmount,
                        })(
                          <InputNumber
                            size="small"
                            className="inputNumberWidth"
                            disabled
                            precision={2}
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">元</span>
                    </div>
                    <p className="descFont">
                      套餐转账金额：
                      {getFieldValue('transferType') === 2
                        ? '参与分润的套餐金额 x 代理商默认分润比例 -（套餐金额 - 参与分润的金额）x（1-代理商默认分润比例）'
                        : '套餐金额 x 代理商分润比例'}
                    </p>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="免赔转账金额">
                    <Form.Item>
                      {getFieldDecorator('insureType', {
                        initialValue: initialValues.insureType,
                      })(
                        <Radio.Group disabled={modalType === 3}>
                          <Radio value={1}>免赔分润</Radio>
                          <Radio value={2}>免赔不分润</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    <div
                      className="transferDiv"
                      style={{
                        display:
                          getFieldValue('insureType') === 1 ? 'flex' : 'none',
                      }}
                    >
                      转账金额：
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('insureMoney', {
                          initialValue: initialValues.insureMoney,
                        })(
                          <InputNumber
                            size="small"
                            className="inputNumberWidth"
                            disabled
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">x</span>
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('insureRatio', {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              message: '请输入正确的比例（保留两位小数）',
                              pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                            },
                            {
                              required: getFieldValue('insureType') === 1,
                              message: '请输入转账比例！',
                            },
                          ],
                          initialValue: initialValues.insureRatio,
                        })(
                          <InputNumber
                            size="small"
                            className="inputNumberWidth"
                            min={0}
                            max={100}
                            onChange={(value) => {
                              setFieldsValue({
                                insureAmount: this.getTransferAmount(
                                  getFieldValue('insureMoney'),
                                  value
                                ),
                              })
                            }}
                            disabled={modalType === 3}
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">% =</span>
                      <Form.Item className="formItemMB">
                        {getFieldDecorator('insureAmount', {
                          initialValue: initialValues.insureAmount,
                        })(
                          <InputNumber
                            precision={2}
                            size="small"
                            className="inputNumberWidth"
                            disabled
                          />
                        )}
                      </Form.Item>
                      <span className="spanPadding">元</span>
                    </div>
                    <p
                      className="descFont"
                      style={{
                        display:
                          getFieldValue('insureType') === 1 ? 'flex' : 'none',
                      }}
                    >
                      免赔转账金额：套餐金额 x 代理商分润比例
                    </p>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator('remark', {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator('packageTransferStatus', {
                      initialValue: !!initialValues.packageTransferStatus,
                      valuePropName: 'checked',
                    })(
                      <Switch
                        disabled={modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="查看套餐即时分润规则"
              maskClosable={false}
              visible={this.state.ruleShow}
              onOk={() => this.setState({ ruleShow: false })}
              onCancel={() => this.setState({ ruleShow: false })}
            >
              <Benefit
                by="id"
                wrappedComponentRef={(ref) => (this.benefitRef = ref)}
              />
            </Modal>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getFranchiseeList()
    this.getPackageList()
    this.getPagePackageTranferRuleInfo(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  //获取套餐分润列表
  getPagePackageTranferRuleInfo(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    }
    _getPagePackageTranferRuleInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            packageTranferRuleList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  getTransferAmount(a, b, c) {
    if (c) {
      return parseFloat(
        (
          (a * 100 * (b * 100) -
            (this.state.packageItem.money * 100 - a * 100) *
              (10000 - b * 100)) /
            1000000 +
          0.000000000001
        ).toFixed(2)
      )
    } else {
      return parseFloat(
        ((a * 100 * b * 100) / 1000000 + 0.000000000001).toFixed(2)
      )
    }
  }
  //获取套餐车型列表
  getBikeModels(value) {
    const packageItem = this.state.packageList.find((item) => item.id == value)
    this.setState({
      packageItem,
    })
    const transferRatio = this.props.form.getFieldValue('transferRatio')
    const insureRatio = this.props.form.getFieldValue('insureRatio')
    this.props.form.setFieldsValue({
      params: [],
      transferMoney: packageItem.money,
      insureMoney: packageItem.insureMoney,
      transferAmount: this.getTransferAmount(packageItem.money, transferRatio),
      insureAmount: this.getTransferAmount(
        packageItem.insureMoney,
        insureRatio
      ),
    })
    let params = {
      curPage: 1,
      pageSize: 999,
      id: value,
    }
    _getBikeModels(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeModels: res.data.data,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //获取套餐列表
  getPackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    }
    _packageBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            packageList: res.data.data.rows,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPagePackageTranferRuleInfo(
      curPage,
      pageSize,
      this.state.queryFormValues
    )
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, packageTransferStatus: Number(checked) }
    _deletePackageTranferRuleInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success('操作成功')
          this.getPagePackageTranferRuleInfo(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          )
        } else {
          message.warning(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  handleFranchiseeChange = async (value) => {
    if (!value) {
      return
    }
    const res = await _franchiseeDetail({ fid: value })
    const { data } = res.data || {}
    if (!data) return
    this.props.form.setFieldsValue({
      transferAccountName: data.falipayName || '',
      transferAccount: data.falipayAccounts || '',
    })
  }
  // 获取筛选
  async getQueryForm() {
    const { form } = this.props
    const values = await form.getFieldsValue()
    for (let key in values) {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ''
      ) {
        delete values[key]
      }
    }
    delete values.packageTransferStatus
    if (values.fidSearch) {
      values.fid = values.fidSearch
      delete values.fidSearch
    }
    if (values.pidSearch) {
      values.pid = values.pidSearch
      delete values.pidSearch
    }
    return values
  }
  // 表单筛选
  handleSubmit = async (e) => {
    e.preventDefault()
    const values = await this.getQueryForm()
    console.log(values)
    this.setState({
      queryFormValues: values,
    })
    this.getPagePackageTranferRuleInfo(1, this.state.pageSize, values)
  }
  // 导出
  async handleExportChange() {
    const values = await this.getQueryForm()
    this.setState({
      exportload: true,
    })
    const res = await _exportPackageTransferRuleList(values)
    this.setState({
      exportload: false,
    })
    const { code, data, message: msg } = res.data || {}
    if (code !== 200) {
      message.warning(msg)
      return
    }
    if (!data) {
      message.warning('暂无数据')
      return
    }
    window.open(`${data}`, '_blank').focus()
  }
  //获取套餐规则详情
  getPackageRuleDetail(id, type) {
    let params = { id }
    _getRuleDetails(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          id,
          initialValues: res.data.data,
        })
        this.getBikeModels(res.data.data.pid)
        this.showModal(type)
      }
    })
  }
  // 分润规则ID获取分润套餐详情
  async handleRuleDetails(id, type) {
    this.setState(
      {
        ruleShow: true,
      },
      () => {
        setTimeout(() => {
          this.benefitRef.init(type, { id })
        })
      }
    )
  }
  //Modal打开事件
  showModal(modalType) {
    this.setState(
      {
        modalType,
        visible: true,
      },
      () => {
        this.props.form && this.props.form.resetFields()
        if (modalType == 1) {
          this.props.form.setFieldsValue({
            insureType: 2,
            transferType: 1,
            insureRatio: 98.5,
            transferRatio: 98.5,
            packageTransferStatus: true,
          })
        }
      }
    )
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault()
    const { form } = this.props
    const { modalType, id } = this.state
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      let parameters = []
      if (values.packageTransferStatus !== undefined) {
        values.packageTransferStatus = Number(values.packageTransferStatus)
      }
      values.periodKeys &&
        values.periodKeys.length &&
        values.periodKeys.map((item, index) => {
          if (values.keyName[`key_${item}`] && values.keyValue[`key_${item}`]) {
            parameters[index] = {
              keyName: values.keyName[`key_${item}`],
              keyValue: values.keyValue[`key_${item}`],
            }
          }
          return item
        })
      values.params = parameters
      delete values.keyName
      delete values.keyValue
      delete values.periodKeys
      if (modalType === 3) {
        this.setState({
          visible: false,
        })
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {}
        _savePackageTranferRuleInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              this.setState({
                visible: false,
                bikeModels: [],
              })
              this.initFormModal()
              this.getPagePackageTranferRuleInfo(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              )
            } else {
              message.warning(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields()
    this.setState({
      initialValues: {},
      visible: false,
      bikeModels: [],
    })
  }

  addPeriodItem = () => {
    const { form } = this.props
    const keys = form.getFieldValue('periodKeys')
    const nextKey = keys.concat(keys[keys.length - 1] + 1)
    form.setFieldsValue({
      periodKeys: nextKey,
    })
  }

  removePeriodItem = (k) => {
    const { form } = this.props
    const keys = form.getFieldValue('periodKeys')
    if (keys.length === 1) {
      return
    }
    form.setFieldsValue({
      periodKeys: keys.filter((key) => key !== k),
    })
  }

  //自定义input参数
  renderPeriodItems = (formItemLayout, formItemLayoutWithOutLabel) => {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props
    const { initialValues, modalType } = this.state
    let activeKeys = []
    let editCustomN = {}
    let editCustomV = {}
    if (initialValues.paramList) {
      initialValues.paramList.forEach((item, index) => {
        activeKeys.push(index)
        editCustomN[`key_${index}`] = item.keyName
        editCustomV[`key_${index}`] = item.keyValue
      })
    }
    getFieldDecorator('periodKeys', {
      initialValue: activeKeys.length > 0 ? activeKeys : [0],
    })
    const periodKeys = getFieldValue('periodKeys')
    const periodItems = periodKeys.map((k, index) => {
      return (
        <Form.Item
          {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? '车辆型号' : ''}
          key={`handle_period_${k}`}
        >
          {getFieldDecorator(`keyName['key_${k}']`, {
            initialValue: editCustomN[`key_${k}`]
              ? Number(editCustomN[`key_${k}`])
              : undefined,
            validateTrigger: ['onChange', 'onBlur'],
          })(
            <Select
              placeholder="请选择车辆型号"
              allowClear
              disabled={modalType === 3}
              style={{ width: '40%', marginRight: 10 }}
            >
              {this.state.bikeModels &&
                this.state.bikeModels.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  )
                })}
            </Select>
          )}
          {getFieldDecorator(`keyValue['key_${k}']`, {
            initialValue: editCustomV[`key_${k}`]
              ? editCustomV[`key_${k}`]
              : undefined,
            validateTrigger: ['onChange', 'onBlur'],
          })(
            <Input
              allowClear
              placeholder="请输入转账金额"
              disabled={modalType === 3}
              style={{ width: '40%', marginRight: 10 }}
            />
          )}
          {periodKeys.length > 1 && modalType !== 3 ? (
            <Icon
              type="minus-circle-o"
              disabled={periodKeys.length === 1}
              onClick={() => this.removePeriodItem(k)}
            />
          ) : null}
        </Form.Item>
      )
    })
    if (periodItems.length && modalType !== 3) {
      periodItems.push(
        <Form.Item key="last" {...formItemLayoutWithOutLabel}>
          <Button
            type="dashed"
            onClick={this.addPeriodItem}
            style={{ width: '40%' }}
          >
            <Icon type="plus" /> 添加参数
          </Button>
        </Form.Item>
      )
    }
    return periodItems
  }
}

PackageTranferRule = Form.create({})(PackageTranferRule)
export { PackageTranferRule }
