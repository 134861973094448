import React,{
    useState,
    useEffect,
} from 'react';
import './index.less'
import ScrollCard from '../scrollCard'

const Index = (props,ref) => {
    const {data=[]} = props
    useEffect(()=>{
        
    },[])
    const volume = (value)=>{
        const {value:max=0} = data[0] || {}
        const percent = (value / max) * 100
        return percent + '%'
    }
    const renderRow = (row,index)=>{
        return (
            <div className="rank_row" key={index}>
                <div className="rank_header">
                    <div className="rank_title">
                        <span className="rank_number">No.{index+1}</span>
                        <span>{row.name}</span>
                    </div>  
                    <div className="rank_value">{row.value}</div>           
                </div>
                <div className="rank_volume" style={{width: volume(row.value)}}></div>
            </div>
        )
    }
    return (
        <ScrollCard rows={5} data={data} render={renderRow} />
    )
}

export default Index
