import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Table,
  Modal,
  Button,
  Switch,
  Select,
  Divider,
  message,
} from "antd";
import {
  _franchiseeList,
  _batteryModelList,
  _repairTransferRules,
  _repairRuleDetail,
  _saveRepairTransferRule,
  _updateTransferRuleStatus,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const { Option } = Select;
const modalTitle = ["添加维修分润规则", "编辑维修分润规则", "维修分润规则详情"];
class RepairTransferRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      batteryRuleList: [], //电池分润列表
      batteryModelList: [], //代理商列表
      franchiseeList: [],
      queryFormValues: {
        status: 1,
      }, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      id: "", //电池分润id
      initialValues: {}, //电池分润详情
      options: [], //地区
    };
    this.columns = [
      {
        title: "序号",
        width: 80,
        dataIndex: "id",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "转账账户名称",
        dataIndex: "transferAccountName",
      },
      {
        title: "转账账户",
        align: "right",
        dataIndex: "transferAccount",
      },
      {
        title: "代理商占比",
        align: "right",
        width: 110,
        dataIndex: "agentRate",
        render: (text) => text && text.toFixed(2) + "%",
      },
      {
        title: "平台占比",
        align: "right",
        width: 100,
        dataIndex: "platRate",
        render: (text) => text && text.toFixed(2) + "%",
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "备注",
        width: 180,
        dataIndex: "remark",
      },
      {
        title: "管理状态",
        width: 90,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            disabled={!getJurisdiction(959)}
            title={getJurisdiction(959) ? "" : "暂无操作权限！"}
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(958)}
              title={getJurisdiction(958) ? "" : "暂无操作权限！"}
              onClick={this.getBatteryModelRuleDetails.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(956)}
              title={getJurisdiction(956) ? "" : "暂无操作权限！"}
              onClick={this.getBatteryModelRuleDetails.bind(this, text.id, 2)}
            >
              编辑
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator("fidSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { totalCount, curPage, pageSize, batteryRuleList, initialValues } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {getJurisdiction(955) ? (
          <div className="RepairTransferRule">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(957)}
                title={getJurisdiction(957) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加维修分润规则
              </Button>
            </div>
            <div className="content-table">
              <Table
                scroll={{ x: 1300, y: 500 }}
                rowKey="id"
                columns={this.columns}
                dataSource={batteryRuleList}
                bordered
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账账户名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.transferAccountName}</span>
                    ) : (
                      getFieldDecorator("transferAccountName", {
                        initialValue: initialValues.transferAccountName,
                        rules: [
                          { required: true, message: "请输入转账账号名称！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="转账账户">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.transferAccount}</span>
                    ) : (
                      getFieldDecorator("transferAccount", {
                        initialValue: initialValues.transferAccount,
                        rules: [
                          { required: true, message: "请输入转账账号！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="代理商分润占比%">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.agentRate}</span>
                    ) : (
                      getFieldDecorator("agentRate", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的金额（保留两位小数）",
                            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                          },
                          { required: true, message: "请输入代理商分润占比！" },
                        ],
                        initialValue: initialValues.agentRate,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="平台分润占比%">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.platRate}</span>
                    ) : (
                      getFieldDecorator("platRate", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的金额（保留两位小数）",
                            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                          },
                          { required: true, message: "请输入平台分润占比！" },
                        ],
                        initialValue: initialValues.platRate,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("batteryModelStatus", {
                      initialValue:
                        initialValues.batteryModelStatus === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getBtteryModelList();
    this.getBatteryModelRuleList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取电池分润列表
  getBatteryModelRuleList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _repairTransferRules(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            batteryRuleList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取电池型号列表
  getBtteryModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _batteryModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            batteryModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getBatteryModelRuleList(curPage, pageSize, this.state.queryFormValues);
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateTransferRuleStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBatteryModelRuleList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getBatteryModelRuleList(1, this.state.pageSize, values);
    });
  };

  //获取电池规则详情
  getBatteryModelRuleDetails(id, type) {
    let params = { id };
    _repairRuleDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveRepairTransferRule(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              this.initFormModal();
              this.getBatteryModelRuleList(
                this.state.curPage,
                this.state.pageSize,
                this.state.queryFormValues
              );
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
      modalType: null,
    });
  }
}

RepairTransferRule = Form.create({})(RepairTransferRule);
export { RepairTransferRule };
