import React from "react";
import { forwardRef, useState, useImperativeHandle } from "react";
import useList from "@/hooks/list";
import { _gpsImport, _whiteDetail, _saveWhite } from "@/statics/js/api.js";
import FranchiseeSelect from "@/components/franchiseeSelect";
import { Form, Input, Modal, Select, Row, Col, message } from "antd";
const { Option } = Select;
const Index = Form.create({})(
  forwardRef((props, ref) => {
    const { form, done } = props;
    const { getFieldDecorator } = form;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [type, setType] = useState(false);
    const [id, setId] = useState();
    const { getGpsTypeList, gpsTypeList } = useList();
    const [visible, setVisible] = useState(false);
    const open = async ({ type, id }) => {
      setType(type);
      setId(id);
      id && getDetail(id);
      setVisible(true);
      getGpsTypeList();
      form.resetFields();
      setConfirmLoading(false);
    };
    //获取GPS白名单详情
    const getDetail = async (id) => {
      const res = await _whiteDetail({ id });
      const { data, code, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      form.setFieldsValue(data);
    };
    const close = async () => {
      setVisible(false);
    };
    // 提交
    const handleOk = async () => {
      const values = await form.validateFields();
      if (type == 1) {
        const params = {
          ...values,
          codeList: values.codeList.split("\n"),
        };
        setConfirmLoading(true);
        const res = await _gpsImport(params);
        setConfirmLoading(false);
        const { code, message: msg } = res.data || {};
        if (code != 200) {
          message.warning(msg);
          return;
        }
        message.success("操作成功");
        done();
        close();
      } else if (type === 2) {
        const params = {
          id,
          ...values,
        };
        setConfirmLoading(true);
        const res = await _saveWhite(params);
        setConfirmLoading(false);
        const { code, message: msg } = res.data || {};
        if (code != 200) {
          message.warning(msg);
          return;
        }
        message.success("操作成功");
        done();
        close();
      } else {
        done();
        close();
      }
    };
    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      []
    );
    return (
      <div>
        <Modal
          className="bigModal"
          title={type === 1 ? "导入GPS白名单" : type === 2 ? "编辑" : "查看"}
          visible={visible}
          onOk={handleOk}
          onCancel={close}
          confirmLoading={confirmLoading}
        >
          <Form>
            <Row>
              {localStorage.getItem("type") === "0" && (
                <Col span={24}>
                  <Form.Item label="代理商">
                    {getFieldDecorator(
                      "agentId",
                      {}
                    )(<FranchiseeSelect disabled={type === 3} />)}
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item label="定位系统">
                  {getFieldDecorator("type", {
                    rules: [{ required: true, message: "请选择" }],
                  })(
                    <Select
                      disabled={type === 3}
                      style={{ width: "100%" }}
                      placeholder="请选择"
                      allowClear
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {gpsTypeList.length &&
                        gpsTypeList.map((item, index) => {
                          return (
                            <Option key={index} value={item.gpsType}>
                              {item.gpsName}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              {type === 1 ? (
                <Col span={24}>
                  <Form.Item label="GPS/SIM编号">
                    {getFieldDecorator("codeList", {
                      rules: [{ required: true, message: "请输入" }],
                    })(
                      <Input.TextArea
                        rows={5}
                        placeholder="输入GPS/SIM编码分别为:[GPS编号XXXXX,SIM编号XXXXX]一组。用“,”分割，一行一组"
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    <Form.Item label="GPS编号">
                      {getFieldDecorator("codeId", {
                        rules: [{ required: true, message: "请输入" }],
                      })(<Input disabled={type === 3} />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="SIM编号">
                      {getFieldDecorator("simId")(
                        <Input disabled={type === 3} />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Modal>
      </div>
    );
  })
);
export default Index;
