import React, { 
    useEffect, useState,
} from 'react';
import DetailSheet from './detailSheet'
import ChartSheet from './chartSheet'
import TrackSheet from './trackSheet'
import {
    Tabs,
} from "antd";
const { TabPane } = Tabs;

const Index = (props)=> {
    const {id,imei} = props
    const [active,setActive] = useState('1')
    useEffect(() => {
        
    },[]);
    const handleChange = (value) => {
        setActive(value)
    }
    return (<div className="card_wrap">
        <Tabs activeKey={active} type="card" onChange={handleChange}>
            <TabPane tab="详情" key="1">
                <DetailSheet id={id} imei={imei} />
            </TabPane>
            <TabPane tab="图表" key="2">
                {
                    active == 2 ? <ChartSheet id={id} /> : null
                }
            </TabPane>
            <TabPane tab="轨迹" key="3">
                <TrackSheet id={id} />
            </TabPane>
        </Tabs>
    </div>)
}

export default Index