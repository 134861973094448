import React, { useState } from "react";
import { Form, Button, Row, Select, Col, Input, message } from "antd";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";
import { _exportDayRepaymentList } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";

const userType = localStorage.getItem("type");
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [exportload, setExportload] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = form.getFieldsValue();
    search(values);
  };

  const handleExportChange = async () => {
    setExportload(true);
    let values = form.getFieldsValue();
    const res = await _exportDayRepaymentList(values);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          {userType === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("agentId")(
                  <FranchiseeSelect
                    onChange={() => {
                      setFieldsValue({ siteId: "" });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("siteId")(
                <SiteSelect fid={getFieldValue("agentId")} />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="分期付订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Select.Option value={""}>全部</Select.Option>
                  <Select.Option value={0}>未完成</Select.Option>
                  <Select.Option value={1}>已完成</Select.Option>
                  <Select.Option value={4}>已逾期</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1105)}
                title={getJurisdiction(1105) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
