import React, { useRef } from "react";
import SearchBox from "./components/searchBox";
import { Button } from "antd";
import CommonTable from "@/components/commonTable";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _dayDayRepaymentList } from "@/statics/js/api";
import { Link } from "react-router-dom";
const SummarySheet = (props) => {
  const tableRef = useRef();
  const handleSearch = async (params) => {
    tableRef.current.handleSearch(params);
  };
  const handleGoTabs = (orderSn) => {
    props.orderGo(orderSn);
  };
  const columns = [
    {
      title: "分期付订单号",
      width: 180,
      dataIndex: "",
      render: (text) => (
        <Button
          type="link"
          onClick={() => {
            handleGoTabs(text.orderSn);
          }}
        >
          {text.orderSn}
        </Button>
      ),
    },
    {
      title: "关联的租赁订单号",
      width: 180,
      dataIndex: "orderSn",
      render: (text) => (
        <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
          {text}
        </Link>
      ),
    },
    {
      title: "代理商",
      width: 160,
      dataIndex: "agentName",
    },
    {
      title: "站点",
      width: 150,
      dataIndex: "siteName",
    },
    {
      title: "生成分期付的月期数",
      width: 110,
      dataIndex: "month",
      render: (text) => <span>共{text}期</span>,
    },
    {
      title: "总分期付金额",
      width: 120,
      dataIndex: "amountCount",
    },
    {
      title: "剩余待支付金额",
      width: 140,
      dataIndex: "repayment",
    },
    {
      title: "总分期付期数",
      width: 120,
      dataIndex: "dayCount",
    },
    {
      title: "剩余待支付期数",
      width: 140,
      dataIndex: "residueDay",
    },
    {
      title: "分期付期限",
      width: 110,
      dataIndex: "deadline",
    },
    {
      title: "状态",
      width: 100,
      dataIndex: "statusName",
    },
    {
      title: "逾期天数",
      width: 100,
      dataIndex: "overdueDays",
    },
    {
      title: "租赁订单下单时间",
      width: 150,
      dataIndex: "orderBeginTime",
    },
    {
      title: "分期付订单开始时间",
      width: 160,
      dataIndex: "startDeductTime",
    },
  ];

  return !getJurisdiction(1104) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <CommonTable
        action={_dayDayRepaymentList}
        showSort
        pageSizeKey="rows"
        curPageKey="page"
        options={{
          columns: columns,
          scroll: { x: 2000, y: 500 },
        }}
        ref={tableRef}
      />
    </div>
  );
};

export default SummarySheet;
