import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Table } from "antd";
import useTable from "@/hooks/table";
import "./index.less";

const Index = forwardRef((props, ref) => {
  const {
    showSort = false,
    fixedScroll = false,
    options,
    action,
    response,
    auto,
    params,
    pageSizeKey,
    curPageKey,
  } = props;
  const sortCol = {
    title: "序号",
    width: 100,
    align: "center",
    // dataIndex: "no",
    render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
  };
  const columns = showSort ? [sortCol, ...options.columns] : options.columns;
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    setList,
    loading,
    handleSearch,
    handleRefresh,
    handleReset,
    handlePageChange,
    handleSizeChange,
  } = useTable({
    action,
    response,
    auto,
    params,
    pageSizeKey,
    curPageKey,
  });

  useImperativeHandle(
    ref,
    () => ({
      handleSearch,
      handleRefresh,
      handleReset,
      setList,
      pageSize,
      curPage,
    }),
    [handleSearch, handleRefresh, handleReset, setList, pageSize, curPage]
  );
  const tableWrapRef = useRef();
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (tableWrapRef.current) {
      let h = tableWrapRef.current.getBoundingClientRect().height;
      setHeight(h);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === tableWrapRef.current) {
          let h = tableWrapRef.current.getBoundingClientRect().height;
          setHeight(h);
        }
      }
    });

    if (tableWrapRef.current) {
      resizeObserver.observe(tableWrapRef.current);
    }

    return () => {
      if (tableWrapRef.current) {
        resizeObserver.unobserve(tableWrapRef.current);
      }
    };
  }, []);
  return (
    <div className={fixedScroll ? "table_wrap" : ""} ref={tableWrapRef}>
      <Table
        rowKey="id"
        bordered
        sticky
        dataSource={list}
        loading={loading}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handleSizeChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
        {...options}
        columns={columns}
        scroll={{
          y: fixedScroll ? height - 104 : "100%",
          ...(options.scroll || {}),
        }}
      />
    </div>
  );
});

export default Index;
