import React, { 
  useRef,
} from 'react';
import {
  Button,
  Table,
  Divider,
  Switch,
  message,
} from "antd";
import PayModal from './components/payModal'
import BillModal from './components/billModal'
import SearchBox from './components/searchBox';
import useTable from '@/hooks/table'
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _billList,
  _updateBillStatus,
} from "@/statics/js/api.js"
import moment from "moment";

const typeObj = {
  1: '支付宝扫码',
  2: '微信扫码',
  3: '银行卡支付',
  4: '支付宝小程序',
  5: '余额支付'
}

const Bill = ()=> {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) =>
        pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "账单号",
      dataIndex: "billNo",
    },
    {
      title: "公司名称",
      dataIndex: "fname",
    },
    {
      title: "物流单号",
      dataIndex: "expressNo",
    },
    {
      title: "设备类型",
      dataIndex: "typeName",
    },
    {
      title: "车辆型号",
      dataIndex: "bikeModelName",
    },
    {
      title: "车辆数",
      dataIndex: "realBikeNum",
    },
    {
      title: "电池型号",
      dataIndex: "dcModelName",
    },
    {
      title: "电池数",
      dataIndex: "realDcNum",
    },
    {
      title: "账单时间",
      width: 120,
      dataIndex: "billTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "应付金额",
      dataIndex: "amount",
    },
    {
      title: "实付金额",
      dataIndex: "payAmount",
    },
    {
      title: "支付方式",
      dataIndex: "payType",
      render: (text) => typeObj[text],
    },
    {
      title: "支付账户",
      dataIndex: "accountName",
    },
    {
      title: "支付流水号",
      dataIndex: "tradeNo",
    },
    {
      title: "支付时间",
      width: 120,
      dataIndex: "payTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "创建时间",
      width: 120,
      dataIndex: "addTime",
      render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "管理状态",
      width: 100,
      dataIndex: "",
      render: (text) =>
        <Switch
          defaultChecked={!!text.status}
          checkedChildren="启用"
          unCheckedChildren="禁用"
          onChange={(checked)=>handleStateChange(text.id,checked)}
        />
    },
    {
      title: "操作",
      dataIndex: "",
      width: 140,
      key: "Action",
      fixed: "right",
      render: (text) => (
        <span>
          {
            text.payStatus ? 
            (
              text.payType === 3 ? 
              <Button
                type="link"
                disabled={!getJurisdiction(997)}
                title={getJurisdiction(997) ? "" : "暂无操作权限！"}
                onClick={()=>showPayModal(text)}
              >
                支付记录
              </Button> : null
            )
            :
            <Button
              type="link"
              disabled={!getJurisdiction(998)}
              title={getJurisdiction(998) ? "" : "暂无操作权限！"}
              onClick={()=>showPayModal(text)}
            >
              支付
            </Button>
          }
          <Divider type="vertical" />
          <Button
            type="link"
            disabled={!getJurisdiction(996)}
            title={getJurisdiction(996) ? "" : "暂无操作权限！"}
            onClick={()=>showRecordModal(text.id)}
          >
            清单
          </Button>
        </span>
      ),
    },
  ]

  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handleRefresh,
    handlePageChange,
  } = useTable({
    action: _billList,
  })

  const payRef = useRef();
  const recordRef = useRef();

  // 支付
  const showPayModal = async (row)=>{
    payRef.current.open({...row})
  }

  // 清单
  const showRecordModal = async (id)=>{
    recordRef.current.open({id})
  }

  // 搜索
  const search = async (data)=>{
    let [start='',end=''] = data.billTime || []
    let query = {
      ...data,
      billTimeStart: start && moment(start).format("YYYY-MM-DD"),
      billTimeEnd: end && moment(end).format("YYYY-MM-DD"),
    }
    handleSearch(query)
  }
  // 状态管理
  const handleStateChange = async (id, checked)=> {
    let params = { 
      id,
      status: Number(checked)
    };
    const res = await _updateBillStatus(params);
    const {code,message:msg} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    message.success("操作成功");
    handleRefresh()
  }
  return (
    !getJurisdiction(990) ? '暂无操作权限！' :
    <div>
      <div className="select-form">
        <SearchBox search={search} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 2000, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
      <PayModal 
        ref={payRef} 
        done={handleRefresh} 
      />
      <BillModal 
        ref={recordRef}
      />
    </div>
  )
}

export default Bill