import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import FranchiseeSelect from '@/components/franchiseeSelect'
import moment from 'moment';
import {
    _operateTimeOutOrderList,
    _operateTimeOutOrderExport
} from "@/statics/js/api"
import {
    Form,
    Button,
    Select,
    Row,
    Col,
    DatePicker
} from "antd";
const { Option } = Select;
const {WeekPicker} = DatePicker

const columns = [
    {
        title: "天数",
        dataIndex: "days",
    },
    {
        title: "挂起占比",
        dataIndex: "hangUpProportion",
    },
    {
        title: "挂起占比环比",
        dataIndex: "hangUpProportionScale",
    },
    {
        title: "未挂起占比",
        dataIndex: "noHangUpProportion",
    },
    {
        title: "未挂起占比环比",
        dataIndex: "noHangUpProportionScale",
    },
    {
        title: "挂起/总超时",
        dataIndex: "hangUpTimeProportion",
    },
    {
        title: "挂起/总超时环比",
        dataIndex: "hangUpTimeProportionScale",
    },
    {
        title: "未挂起/总超时",
        dataIndex: "noHangUpTimeProportion",
    },
    {
        title: "未挂起/总超时环比",
        dataIndex: "noHangUpTimeProportionScale",
    },
]

const Index = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type} = values
            let time = {}
            if(type == 0){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type} = values
        let time = {}
        if(type == 0){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }
        handleDownload({
            action: _operateTimeOutOrderExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 0
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>月报表</Option>
                                <Option value={0}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 0 && 
                    <Col lg={6} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                }
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={4} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateTimeOutOrderList}
            options={{
                columns: columns
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

export default Index