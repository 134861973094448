import React, { Component } from "react";
import {
  Button,
  Table,
  Select,
  Form,
  Input,
  DatePicker,
  Divider,
  Modal,
  message,
  Row,
  Col,
} from "antd";
import {
  _franchiseeList,
  _siteBoxList,
  _exportInsureTransferLog,
  _transferList,
  _transferSite,
  _updateTransferSite,
  _transferBatteryBikeInfo,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
class TransferOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryFormValues: {}, //筛选项
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      transferList: [], //更换列表
      siteList: [], //站点列表
      franchiseeList: [], //代理商列表
      transferInfo: {}, //更换详情
      visible: false,
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "换车单号",
        align: "right",
        width: 180,
        dataIndex: "transferSn",
      },
      {
        title: "订单编号",
        align: "right",
        width: 180,
        dataIndex: "orderSn",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "租赁站点",
        dataIndex: "zsname",
      },
      {
        title: "更换站点",
        dataIndex: "transferName",
      },
      {
        title: "发起更换",
        width: 100,
        dataIndex: "sourceType",
        render: (text) => (text === 0 ? "用户" : text === 1 ? "商家" : ""),
      },
      {
        title: "申请时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "更换单状态",
        align: "right",
        width: 110,
        dataIndex: "status",
        render: (text) =>
          text === 1
            ? "处理中"
            : text === 2
            ? "超时取消"
            : text === 3
            ? "用户取消"
            : text === 4
            ? "商家拒绝"
            : text === 5
            ? "商家确认换车"
            : text === 6
            ? "已完成"
            : text === 7
            ? "待处理"
            : text === 8
            ? "商家拒绝"
            : "",
      },
      {
        title: "是否维修",
        align: "right",
        width: 100,
        dataIndex: "needRepair",
        render: (text) => (text === 1 ? "是" : "否"),
      },
      {
        title: "维修单号",
        width: 120,
        dataIndex: "repairNo",
      },
      {
        title: "结束时间",
        dataIndex: "updateTime",
        width: 120,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 130,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <>
            <Button
              type="link"
              onClick={this.showModal.bind(
                this,
                text.id,
                text.orderSn,
                text.orderStatus
              )}
            >
              查看
            </Button>
            {text.status === 7 && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.modalViewChangeSite.bind(this, text)}
                >
                  更换站点
                </Button>
              </>
            )}
          </>
        ),
      },
    ];
    this.InfoColumns = [
      {
        title: "原车辆信息",
        dataIndex: "oleBikeQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              车架号：{record.oleBikeImei}
              <br />
              GPS号：{record.oleBikeCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
      {
        title: "原电池信息1",
        dataIndex: "oleBatteryQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              电池编号：{record.oleBatteryImei}
              <br />
              GPS号：{record.oleBatteryCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
      {
        title: "原电池信息2",
        dataIndex: "twoOleBatteryQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              电池编号：{record.twoOleBatteryImei}
              <br />
              GPS号：{record.twoOleBatteryCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
      {
        title: "更换后车辆信息",
        dataIndex: "bikeQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              车架号：{record.bikeImei}
              <br />
              GPS号：{record.bikeCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
      {
        title: "更换后电池信息1",
        dataIndex: "batteryQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              电池编号：{record.batteryImei}
              <br />
              GPS号：{record.batteryCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
      {
        title: "更换后电池信息2",
        dataIndex: "twoBatteryQCodeId",
        render: (text, record) =>
          !!text && (
            <span>
              电池编号：{record.twoBatteryImei}
              <br />
              GPS号：{record.twoBatteryCCodeId}
              <br />
              二维码：{text}
            </span>
          ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { queryFormValues, franchiseeList, siteList, exportload } =
      this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fid", {
                  initialValue: queryFormValues.fid,
                })(
                  <Select
                    placeholder="请选择"
                    onChange={this.getSiteList.bind(this)}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {franchiseeList.length &&
                      franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("sid")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {siteList.length &&
                    siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="更换单号">
              {getFieldDecorator("transferSn", {
                initialValue: queryFormValues.transferSn,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn", {
                initialValue: queryFormValues.orderSn,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆二维码">
              {getFieldDecorator("bikeImei", {
                initialValue: queryFormValues.bikeImei,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="电池二维码">
              {getFieldDecorator("batteryImei", {
                initialValue: queryFormValues.batteryImei,
              })(<Input allowClear placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="更换单状态">
              {getFieldDecorator("status")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">处理中</Option>
                  <Option value="2">超时取消</Option>
                  <Option value="3">用户取消</Option>
                  <Option value="4">商家拒绝</Option>
                  <Option value="5">商家确认换车</Option>
                  <Option value="6">已完成</Option>
                  <Option value="7">待处理</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="发起更换">
              {getFieldDecorator("sourceType")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">用户</Option>
                  <Option value="1">商家</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="选择时间">
              {getFieldDecorator("pushTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={exportload}
                disabled={!getJurisdiction(1015)}
                title={getJurisdiction(1015) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, transferList } = this.state;
    return (
      <div className="TransferOrder">
        <div className="select-form">{this.renderForm()}</div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            columns={this.columns}
            dataSource={transferList}
            scroll={{ x: 2000, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title="查看"
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initModal.bind(this)}
        >
          <div>
            <p>代理商：{this.state.transferInfo.fname}</p>
            <p>租赁站点：{this.state.transferInfo.rentName}</p>
            <p>更换站点：{this.state.transferInfo.replaceName}</p>
            <Table
              rowKey="id"
              bordered
              columns={this.InfoColumns}
              scroll={{ x: 1400, y: 500 }}
              dataSource={this.state.transferInfo.batteryBikeVOs}
            />
          </div>
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    localStorage.getItem("type") === "1"
      ? this.getSiteList(localStorage.getItem("fid"))
      : this.getFranchiseeList();
    this.getTransferList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取更换列表
  getTransferList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _transferList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            transferList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getTransferList(curPage, pageSize, this.state.queryFormValues);
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取站点列表
  getSiteList(value) {
    this.props.form.setFieldsValue({
      sid: undefined,
    });
    let params = {
      fid: value,
      curPage: 1,
      pageSize: 999,
    };
    _siteBoxList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            siteList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      console.log(values);
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      this.setState({
        queryFormValues: values,
      });
      this.getTransferList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      let params = {
        ...values,
      };
      _exportInsureTransferLog(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //获取更换信息
  getTransferInfo(id) {
    let params = {
      id,
    };
    _transferBatteryBikeInfo(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            transferInfo: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(id, orderSnInfo, orderStatus) {
    this.getTransferInfo(id);
    this.setState({
      orderSnInfo,
      orderStatus,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    this.setState({
      visible: false,
      orderSnInfo: null,
      orderStatus: null,
    });
  }

  //Modal取消事件
  initModal() {
    this.setState({
      visible: false,
      orderSnInfo: null,
      orderStatus: null,
    });
  }
  //更换站点
  modalViewChangeSite(text) {
    let self = this;
    let sid;
    let params = {
      porderId: text.porderId,
      kid: text.kid,
    };
    _transferSite(params).then((res) => {
      if (res.data.code === 200) {
        Modal.confirm({
          title: "更换换车点",
          content: (
            <div style={{ marginTop: 30 }}>
              <Form.Item label="原更换点">{text.transferName}</Form.Item>
              <Form.Item label="新更换点">
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    sid = value;
                  }}
                >
                  {res.data.data &&
                    res.data.data.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          ),
          onOk() {
            let params = {
              sid,
              transferSn: text.transferSn,
            };
            _updateTransferSite(params).then((res) => {
              if (res.data.code === 200) {
                message.success(res.data.message);
                self.getTransferList(
                  self.state.curPage,
                  self.state.pageSize,
                  self.state.queryFormValues
                );
              } else {
                message.warning(res.data.message);
              }
            });
          },
        });
      }
    });
  }
}

TransferOrder = Form.create({})(TransferOrder);
export { TransferOrder };
