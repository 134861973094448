import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Modal,
  DatePicker,
  Tabs,
  message,
  Row,
  Col,
} from "antd";
import {
  _bikeStaticList,
  _bikeOrderStaticList,
  _exportBikeStatic,
  _insertBikePayed,
} from "@/statics/js/api";
import moment from "moment";

import { getJurisdiction } from "@/statics/js/jurisdiction";
const TabPane = Tabs.TabPane;
const RangePicker = DatePicker.RangePicker;
class FrameRefundReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bikeStaticList: [], //车架列表
      bikeOrderStaticList: [], //订单列表
    };
    this.bikeColumns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "车辆二维码",
        align: "right",
        dataIndex: "vin",
      },
      {
        title: "次数",
        align: "right",
        dataIndex: "count",
      },
    ];
    this.bikeOrderColumns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: "车辆二维码",
        align: "right",
        dataIndex: "vin",
      },
      {
        title: "订单号",
        align: "right",
        dataIndex: "orderSn",
      },
      {
        title: "订单时间",
        width: 120,
        dataIndex: "createTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "订单状态",
        width: 110,
        dataIndex: "orderStatusTxt",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "站点",
        dataIndex: "sname",
      },
      {
        title: "变更记录",
        dataIndex: "log",
        width: 200,
        render: (text) => (
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
        ),
      },
    ];
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="统计日期">
              {getFieldDecorator("statisticalDate")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                disabled={!getJurisdiction(828)}
                title={getJurisdiction(828) ? "" : "暂无操作权限！"}
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
              <Button
                disabled={!getJurisdiction(829)}
                title={getJurisdiction(829) ? "" : "暂无操作权限！"}
                onClick={this.handleInsertBikePayedChange.bind(this)}
                style={{ marginLeft: 10 }}
              >
                写入
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { bikeStaticList, bikeOrderStaticList } = this.state;
    return (
      <div className="FrameRefundReport">
        <div className="select-form">{this.renderForm()}</div>
        <div className="content-table">
          <Tabs defaultActiveKey="1">
            {getJurisdiction(826) && (
              <TabPane tab="车架列表" key="1">
                <Table
                  rowKey="id"
                  bordered
                  columns={this.bikeColumns}
                  dataSource={bikeStaticList}
                  scroll={{ x: 1000, y: 500 }}
                />
              </TabPane>
            )}
            {getJurisdiction(827) && (
              <TabPane tab="订单列表" key="2">
                <Table
                  rowKey="id"
                  bordered
                  columns={this.bikeOrderColumns}
                  dataSource={bikeOrderStaticList}
                  scroll={{ x: 1300, y: 500 }}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.getBikeStaticList(this.state.queryFormValues);
    this.getBikeOrderStaticList(this.state.queryFormValues);
  }

  //获取车架列表
  getBikeStaticList(queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: 1,
      pageSize: 999,
    };
    _bikeStaticList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeStaticList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车架订单列表
  getBikeOrderStaticList(queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: 1,
      pageSize: 999,
    };
    _bikeOrderStaticList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeOrderStaticList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.statisticalDate && values.statisticalDate.length) {
        values.startDate = moment(values.statisticalDate[0]).format(
          "YYYY-MM-DD"
        );
        values.endDate = moment(values.statisticalDate[1]).format("YYYY-MM-DD");
      }
      delete values.statisticalDate;
      this.setState({
        queryFormValues: values,
      });
      this.getBikeStaticList(values);
      this.getBikeOrderStaticList(values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.statisticalDate && values.statisticalDate.length) {
        values.startDate = moment(values.statisticalDate[0]).format(
          "YYYY-MM-DD"
        );
        values.endDate = moment(values.statisticalDate[1]).format("YYYY-MM-DD");
      }
      delete values.statisticalDate;
      let params = {
        ...values,
      };
      _exportBikeStatic(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // 写入
  handleInsertBikePayedChange = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.statisticalDate && values.statisticalDate.length) {
        values.startDate = moment(values.statisticalDate[0]).format(
          "YYYY-MM-DD"
        );
        values.endDate = moment(values.statisticalDate[1]).format("YYYY-MM-DD");
      }
      delete values.statisticalDate;
      Modal.confirm({
        title: "提示信息",
        content: `是否确定写入？`,
        onOk() {
          let params = {
            ...values,
          };
          _insertBikePayed(params)
            .then((res) => {
              if (res.data.code === 200) {
                message.success(res.data.message);
              } else {
                message.warning(res.data.message);
              }
            })
            .catch(() => {
              message.warning("操作失败");
            });
        },
      });
    });
  };
}

FrameRefundReport = Form.create({})(FrameRefundReport);
export { FrameRefundReport };
