import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Input,
  Select,
  Form,
  Divider,
  Modal,
  Upload,
  Icon,
  Row,
  Col,
  message,
} from "antd";
import {
  _imageUpload,
  _repBikeModelList,
  _updateRepBikeModelStatus,
  _deleteRepBikeModel,
  _repBikeModelDetail,
  _addRepBikeModel,
} from "@/statics/js/api";

const { Option } = Select;
const modalTitle = ["添加车辆型号", "编辑车辆型号"];
class KABikeModelRepair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      fileList: [], //上传格式
      bikeModelList: [], //车辆型号列表
      initialValues: {}, //车辆详情
      id: "", //电池型号id
      modalType: "", //1-添加，2-编辑
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "名称",
        dataIndex: "name",
      },
      {
        title: "添加时间",
        dataIndex: "addTime",
      },
      {
        title: "车辆数",
        dataIndex: "bikeCount",
      },
      {
        title: "管理状态",
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        key: "Action",
        width: 100,
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.getRepBikeModelDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            {!!(text.bikeCount === 0) && (
              <span>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.handleDeleteChange.bind(this, text.id)}
                >
                  删除
                </Button>
              </span>
            )}
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="名称">
              {getFieldDecorator("nameSearch")(<Input placeholder="请输入" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>禁用</Option>
                  <Option value={1}>正常</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      bikeModelList,
      initialValues,
      fileList,
      pageSize,
      totalCount,
      curPage,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="KABikeModelRepair">
        <div className="select-form">{this.renderForm()}</div>
        <div className="top-btn">
          <Button
            icon="plus"
            type="primary"
            onClick={this.showModal.bind(this, 1)}
          >
            新增
          </Button>
        </div>
        <div className="content-table">
          <Table
            rowKey="id"
            bordered
            columns={this.columns}
            dataSource={bikeModelList}
            scroll={{ x: 1200, y: 500 }}
            pagination={{
              showQuickJumper: true,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.handlePageChange.bind(this),
              onChange: this.handlePageChange.bind(this),
              total: totalCount,
              current: curPage,
              showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
            }}
          />
        </div>
        <Modal
          className="bigModal"
          title={modalTitle[this.state.modalType - 1]}
          destroyOnClose
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this)}
          onCancel={this.initFormModal.bind(this)}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="型号名称">
                {getFieldDecorator("name", {
                  initialValue: initialValues.name,
                  rules: [{ required: true, message: "请输入型号名称！" }],
                })(<Input allowClear placeholder="请输入" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="图片">
                {getFieldDecorator("img", {
                  initialValue: initialValues.img_id || undefined,
                  getValueFromEvent: this.normFile,
                })(
                  <Upload
                    name="uploadFile"
                    action={_imageUpload}
                    listType="picture-card"
                    fileList={fileList}
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    onChange={this.handleChange.bind(this)}
                    data={{
                      scene: "active",
                    }}
                  >
                    {fileList.length >= 1 ? null : (
                      <div>
                        <Icon type="plus" style={{ fontSize: "28px" }} />
                        <p>车辆型号上传</p>
                      </div>
                    )}
                  </Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
  componentDidMount() {
    this.getRepBikeModelLis(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取车辆型号列表
  getRepBikeModelLis(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _repBikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeModelList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getRepBikeModelLis(curPage, pageSize, this.state.queryFormValues);
  }

  //车辆型号状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateRepBikeModelStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getRepBikeModelLis(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.nameSearch) {
        values.name = values.nameSearch;
        delete values.nameSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getRepBikeModelLis(1, this.state.pageSize, values);
    });
  };

  //删除型号
  handleDeleteChange(id) {
    let self = this;
    Modal.confirm({
      title: "删除信息",
      content: "是否删除此数据？",
      onOk() {
        let params = { id };
        _deleteRepBikeModel(params).then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            self.getRepBikeModelLis(
              self.state.curPage,
              self.state.pageSize,
              self.state.queryFormValues
            );
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  //获取车辆型号详情
  getRepBikeModelDetail(id, type) {
    let params = { id };
    _repBikeModelDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: "done",
                url: res.data.data.imgObject.path,
              },
            ]);
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.img && values.img.constructor === Array) {
        values.img =
          values.img.length === 1 ? values.img[0].response.data.id : "";
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      // (values.status === undefined && modalType === 1) || !!values.status
      //   ? (values.status = 1)
      //   : (values.status = 0);
      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _addRepBikeModel(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //updata end

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.updateFileList([]);
    this.setState({
      id: "",
      initialValues: {},
      visible: false,
    });
  }
}
KABikeModelRepair = Form.create({})(KABikeModelRepair);
export { KABikeModelRepair };
