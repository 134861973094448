import {useState, useEffect,useCallback} from 'react'
import {message} from 'antd'

function useHandle(props) {
    const [loading,setLoading] = useState(false)
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    useEffect(()=>{
        
    },[])

    const handleDownload = useCallback(async ({action,query={}})=> {
        let params = {
            ...query
        };
        if(userType !== '0'){
            params.fid = fid
        }
        setLoading(true)
        const res = await action(params)
        setLoading(false)
        const {code,data,message:msg} = res.data || {}
        if(code != 200){
            message.warning(msg);
            return
        }
        window.open(data, "_blank").focus()
    },[])

    return {
        handleDownload,
        loading,
    }
}

export default useHandle