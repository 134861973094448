import React from 'react';
import moment from "moment";
import {
  Table,
  Popover,
} from "antd";
import useTable from '../../../../../hooks/table'
import {
  _getAlarm,
} from "../../../../../statics/js/api.js"

const Index = (props) => {
  const { filter } = props
  let {imei,startTime,endTime} = filter
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => index + 1
    },
    {
      title: "告警类型",
      dataIndex: "alarmMsg",
    },
    {
      title: "告警时间",
      dataIndex: "alarmTime",
      width: 180,
    },
    {
      title: "告警地址",
      dataIndex: "alarmAddr",
      ellipsis: true,
      render: (text, record, index) => <Popover content={text} placement="left">{text}</Popover>
    },
  ]

  const {
    list,
    loading,
  } = useTable({
    action: _getAlarm,
    params: {
      imei,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
    },
    response: (res) => {
      const { alarmInfos = [] } = res.data.data || {}
      return {
        rows: alarmInfos
      }
    }
  })

  return (<>
    <Table
      rowKey="id"
      bordered
      scroll={{ y: 500 }}
      columns={columns}
      dataSource={list}
      loading={loading}
      pagination={false}
    />
  </>)
}

export default Index