import React, { useEffect, useRef } from "react";
import { _bank } from "@/statics/js/api";
import { Spin } from "antd";
import queryString from 'query-string';

const Index = (props) => {
  const btnRef = useRef();
  // const { bankUrl, epccGwMsg, back } = props.location.query;
  const { bankUrl, epccGwMsg, back } =  queryString.parse(props.location.search)
  useEffect(() => {
    if (back) {
      window.ap.popWindow();
    } else {
      btnRef.current.click();
    }
  }, []);
  return (
    <>
      {back ? (
        <div
          style={{
            marginTop: 200,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 81.75, height: 50 }}
            src={require("@/statics/img/icon/icon.jpg")}
            alt=""
          />
          <h1>绑卡成功</h1>
          <div>请返回银行卡列表页面</div>
        </div>
      ) : (
        <div>
          <div style={{ display: "none" }}>
            <form action={bankUrl} method="post">
              <input
                readOnly
                type="text"
                name="epccGwMsg"
                value={epccGwMsg?.replace(/\s/g, "+")}
              />
              <input ref={btnRef} type="submit" name="提交" />
            </form>
          </div>
          <Spin />
        </div>
      )}
    </>
  );
};

export default Index;
