import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Input,
  Cascader,
  Select,
  Modal,
  Form,
  Row,
  Col,
  message,
} from "antd";
import {
  _franchiseeList,
  _bikeRepairList,
  _deleteBikeRepair,
  _batchDelBikeRepair,
  _updateBikeRepairStatus,
  _bikeRepairDetail,
  _getSonsOfRegion,
  _saveBikeRepairInfo,
  _bikeModelList,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";

const { Option } = Select;
const modalTitle = ["添加维修项", "编辑维修项", "维修项详情"];
class BikeRepairManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      bikeRepairList: [], //维修项列表
      franchiseeList: [], //代理商列表
      bikeModelList: [], //车辆型号列表
      queryFormValues: {}, //筛选项
      modalType: "", //1-添加，2-编辑，3-查看
      visible: false, //Modal是否打开
      initialValues: {}, //维修项详情
      options: [], //地区
      id: "", //维修项id
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "车型",
        dataIndex: "modelName",
      },
      {
        title: "检修名称",
        dataIndex: "name",
      },
      {
        title: "检修费用",
        align: "right",
        width: 100,
        dataIndex: "amount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "城市",
        width: 100,
        dataIndex: "cityName",
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "排序",
        align: "right",
        width: 80,
        dataIndex: "orderNum",
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            disabled={!getJurisdiction(890)}
            title={getJurisdiction(890) ? "" : "暂无操作权限！"}
            defaultChecked={!!text.state}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(887)}
              title={getJurisdiction(887) ? "" : "暂无操作权限！"}
              onClick={this.getBikeRepairDetail.bind(this, text.id, 3)}
            >
              查看
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(885)}
              title={getJurisdiction(885) ? "" : "暂无操作权限！"}
              onClick={this.getBikeRepairDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(889)}
              title={getJurisdiction(889) ? "" : "暂无操作权限！"}
              onClick={this.handleDeleteBikeChange.bind(this, text.id)}
            >
              删除
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="检修名称">
              {getFieldDecorator("nameSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <Cascader
                  options={this.state.options}
                  loadData={this.getLoadData.bind(this)}
                  changeOnSelect
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商">
              {getFieldDecorator("fidSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {this.state.franchiseeList.length &&
                    this.state.franchiseeList.map((item, index) => {
                      return (
                        <Option key={index} value={item.fid}>
                          {item.fname}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {this.state.bikeModelList &&
                    this.state.bikeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("stateSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">启用</Option>
                  <Option value="0">禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      loading,
      selectedRowKeys,
      totalCount,
      curPage,
      pageSize,
      bikeRepairList,
      initialValues,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(884) ? (
          <div className="BikeRepairManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                onClick={this.showModal.bind(this, 1)}
                disabled={!getJurisdiction(886)}
                title={getJurisdiction(889) ? "" : "暂无操作权限！"}
              >
                添加维修项
              </Button>
              <Button
                onClick={this.handleBatchDeleteChange.bind(this)}
                disabled={!getJurisdiction(888) || !hasSelected}
                loading={loading}
                icon="delete"
                type="danger"
              >
                批量删除
              </Button>
              {hasSelected && `已选中 ${selectedRowKeys.length} 项`}
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={bikeRepairList}
                scroll={{ x: 1300, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.fname}</span>
                    ) : (
                      getFieldDecorator("fid", {
                        initialValue: initialValues.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.franchiseeList.length &&
                            this.state.franchiseeList.map((item, index) => {
                              return (
                                <Option key={index} value={item.fid}>
                                  {item.fname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车辆型号">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.modelName}</span>
                    ) : (
                      getFieldDecorator("modelId", {
                        initialValue: initialValues.modelId,
                        rules: [
                          { required: true, message: "请选择车辆型号！" },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择"
                          allowClear
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.state.bikeModelList.length &&
                            this.state.bikeModelList.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="检修名称">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.name}</span>
                    ) : (
                      getFieldDecorator("name", {
                        initialValue: initialValues.name,
                        rules: [
                          { required: true, message: "请输入检修名称！" },
                        ],
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="检修费用">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.amount}</span>
                    ) : (
                      getFieldDecorator("amount", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的金额（保留两位小数）",
                            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                          },
                          { required: true, message: "请输入检修费用！" },
                        ],
                        initialValue: initialValues.amount,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="地区">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.areaName}</span>
                    ) : (
                      getFieldDecorator("areaList", {
                        rules: [
                          {
                            required: this.state.modalType === 1 ? true : false,
                            message: "请选择地区！",
                          },
                        ],
                      })(
                        <Cascader
                          placeholder={
                            initialValues.areaName && initialValues.areaName
                          }
                          style={{ width: "100%" }}
                          options={this.state.options}
                          loadData={this.getLoadData.bind(this)}
                          changeOnSelect
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="排序">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.orderNum}</span>
                    ) : (
                      getFieldDecorator("orderNum", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的排序数",
                            pattern: /^([1-9]{1}\d*)?$/,
                          },
                        ],
                        initialValue: initialValues.orderNum,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {this.state.modalType === 3 ? (
                      <span>{initialValues.remark}</span>
                    ) : (
                      getFieldDecorator("remark", {
                        initialValue: initialValues.remark,
                      })(<Input allowClear placeholder="请输入" />)
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="管理状态">
                    {getFieldDecorator("state", {
                      initialValue: initialValues.state === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch
                        disabled={this.state.modalType === 3 && true}
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getBikeModelList();
    this.getBikeRepairList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
    this.getSonsOfRegion(0);
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车辆型号列表
  getBikeModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _bikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取维修项列表
  getBikeRepairList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _bikeRepairList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            bikeRepairList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getBikeRepairList(curPage, pageSize, this.state.queryFormValues);
  }

  //单条删除
  handleDeleteBikeChange(id) {
    Modal.confirm({
      title: "删除信息",
      content: "是否确定删除此数据？",
      onOk() {
        let params = { id };
        _deleteBikeRepair(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
          });
      },
    });
  }

  //批量删除
  handleBatchDeleteChange() {
    let self = this;
    self.setState({ loading: true });
    Modal.confirm({
      title: "删除信息",
      content: "是否确定批量删除数据吗？",
      onOk() {
        let params = self.state.selectedRowKeys;
        _batchDelBikeRepair(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            message.warning("操作失败");
            setTimeout(() => {
              self.setState({
                selectedRowKeys: [],
                loading: false,
              });
            }, 1000);
          });
      },
    });
  }

  //管理状态Switch
  handleStateChange(id, checked) {
    let params = { id, state: Number(checked) };
    _updateBikeRepairStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getBikeRepairList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //选中数据
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.state;
      if (values.nameSearch) {
        values.name = values.nameSearch;
        delete values.nameSearch;
      }
      if (values.stateSearch) {
        values.state = values.stateSearch;
        delete values.stateSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      let province, city, county, street;
      if (values.areaList) {
        province = values.areaList[0] ? values.areaList[0] : null;
        city = values.areaList[1] ? values.areaList[1] : null;
        county = values.areaList[2] ? values.areaList[2] : null;
        street = values.areaList[3] ? values.areaList[3] : null;
        values.province = province;
        values.city = city;
        values.county = county;
        values.street = street;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getBikeRepairList(1, this.state.pageSize, values);
    });
  };

  //获取维修单详情
  getBikeRepairDetail(id, type) {
    let params = { id };
    _bikeRepairDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          this.showModal(type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //加载地区数据
  getLoadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let params = { parentid: targetOption.value };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          targetOption.children = areaList;
          targetOption.loading = false;
          let options = [...this.state.options];
          this.setState({
            options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取一级地区
  getSonsOfRegion(parentid) {
    let params = { parentid };
    _getSonsOfRegion(params)
      .then((res) => {
        if (res.data.code === 200) {
          let areaList = res.data.data;
          if (areaList instanceof Array) {
            areaList = areaList.map((item, index) => {
              return {
                label: item.name,
                value: +item.areaid,
                isLeaf: !item.hasChild,
              };
            });
          } else {
            areaList = [];
          }
          this.setState({
            options: [...areaList],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      options: [],
      modalType,
      visible: true,
    });
    this.getSonsOfRegion(0);
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.state !== undefined) {
        values.state = Number(values.state);
      }
      // values.state === undefined && modalType === 1
      //   ? (values.state = 1)
      //   : !!values.state
      //   ? (values.state = 1)
      //   : (values.state = 0);
      if (values.areaList) {
        values.province = values.areaList[0] ? values.areaList[0] : null;
        values.city = values.areaList[1] ? values.areaList[1] : null;
        values.county = values.areaList[2] ? values.areaList[2] : null;
        values.street = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (modalType === 3) {
        this.setState({
          visible: false,
        });
      } else {
        let params =
          modalType === 1
            ? { ...values }
            : modalType === 2
            ? {
                id,
                ...values,
              }
            : {};
        _saveBikeRepairInfo(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              this.setState({
                visible: false,
              });
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: "",
      initialValues: {},
      modalType: null,
      options: [],
      visible: false,
    });
    this.getSonsOfRegion(0);
  }
}

BikeRepairManagement = Form.create({})(BikeRepairManagement);
export { BikeRepairManagement };
