import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
const amapkey = "4792ad0f37a527c1535e83599762d894";
var AMap = null;
var map_instance = null; //地图实例
var pathSimplifierIns = null; //路线实例
var pathNavigator = null; //巡航器
var infoWindow = null //信息框

const Index = forwardRef((props, ref) => {
  useEffect(() => {
    return () => {
      map_instance && map_instance.destroy();
      AMap = null;
      map_instance = null;
      pathSimplifierIns = null;
      pathNavigator = null;
      infoWindow = null
    };
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      load,
      start,
      pause,
      resume,
      stop,
      clear,
      setSpeed,
    }),
    [load, start, pause, resume, stop, clear, setSpeed]
  );
  // 加载路线
  var load = async (data,list) => {
    if (!map_instance) {
      await initMap();
    }
    if (!pathSimplifierIns) {
      await drawPath();
    }
    pathSimplifierIns.setData([
      {
        name: "轨迹",
        path: data,
      },
    ]);
    setMarker({
      start: {
        position: data[0],
      },
      end: {
        position: data[data.length - 1],
      },
    });
    drawNavigator(data,list);
  };
  // 启动巡航
  var start = async () => {
    pathNavigator && pathNavigator.start();
  };
  // 暂停巡航
  var pause = async () => {
    pathNavigator && pathNavigator.pause();
  };
  // 停止巡航
  var stop = async () => {
    pathNavigator && pathNavigator.stop();
  };
  // 恢复巡航
  var resume = async () => {
    pathNavigator && pathNavigator.resume();
  };
  // 清除巡航
  var clear = async () => {
    pathSimplifierIns && pathSimplifierIns.setData([]);
    map_instance.clearMap();
  };
  var setSpeed = (value) => {
    pathNavigator && pathNavigator.setSpeed(value);
  };
  // 初始化地图
  var initMap = async () => {
    AMap = await AMapLoader.load({
      key: amapkey, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      AMapUI: {
        version: "1.1",
      },
    });
    map_instance = new AMap.Map("map_box", {
      //设置地图容器id
      mapStyle: "amap://styles/dark",
      zoom: 4, //初始化地图级别
      center: [105.602725, 37.076636], //初始化地图中心点位置
    });
    return new Promise((resolve, reject) => {
      map_instance.on("complete", () => {
        resolve();
      });
    });
  };
  // 绘制路线
  var drawPath = async () => {
    return new Promise((resolve, reject) => {
      window.AMapUI.load(["ui/misc/PathSimplifier"], (PathSimplifier) => {
        if (!PathSimplifier.supportCanvas) {
          alert("当前环境不支持 Canvas！");
          reject();
          return;
        }
        pathSimplifierIns = new PathSimplifier({
          zIndex: 100,
          map: map_instance, //绑定的地图
          data: [
            {
              // 巡航路径
              path: [],
            },
          ],
          getPath: (pathData, pathIndex) => {
            // 获取巡航路径中的路径坐标数组
            return pathData.path;
          },
          // getHoverTitle: (pathData, pathIndex, pointIndex)=> {
          //   return 'test';
          // },
          renderOptions: {
            renderAllPointsIfNumberBelow: 100, //绘制路线节点，如不需要可设置为-1
            pathLineStyle: {
              strokeStyle: "#67C23A",
              lineWidth: 6,
            },
          },
        });
        resolve();
      });
    });
  };
  // 设置标记点
  var setMarker = ({ start, end }) => {
    map_instance.clearMap();
    const startIcon = new AMap.Icon({
      size: new AMap.Size(25, 34),
      image: "//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
      imageSize: new AMap.Size(135, 40),
      imageOffset: new AMap.Pixel(-9, -3),
    });
    const endIcon = new AMap.Icon({
      size: new AMap.Size(25, 34),
      image: "//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
      imageSize: new AMap.Size(135, 40),
      imageOffset: new AMap.Pixel(-95, -3),
    });
    const start_marker = new AMap.Marker({
      position: start.position,
      icon: startIcon,
      offset: new AMap.Pixel(-13, -30),
      zIndex: 100,
    });
    const end_marker = new AMap.Marker({
      position: end.position,
      icon: endIcon,
      offset: new AMap.Pixel(-13, -30),
      zIndex: 100,
    });
    map_instance.add([start_marker, end_marker]);
  };
  // 绘制巡航器
  var drawNavigator = (data,list) => {
    pathNavigator = pathSimplifierIns.createPathNavigator(0, {
      loop: false, // 是否循环
      speed: 500, // 速度(km/h)
      pathNavigatorStyle: {
        width: 26,
        height: 26,
        fillStyle: "red",
      },
      strokeStyle: null,
      fillStyle: null,
      //经过路径的样式
      pathLinePassedStyle: {
        lineWidth: 6,
        strokeStyle: "black",
        dirArrowStyle: {
          stepSpace: 15,
          strokeStyle: "red",
        },
      },
    });
    initInfoWindow(data,list,0)
    let curIndex = 0
    pathNavigator.on('move', (e, position) => {
        const index = position.dataItem.pointIndex
        if(curIndex != index){
            curIndex = index
            initInfoWindow(data,list,index)
        }
    })
  };
  // 创建信息框
  var initInfoWindow = (data,list,index,address) => {
    if(!infoWindow){
        infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(6, -6),
            content: '',
        })
    }
    const content = `<div style="width: 270px"
        <div>定位时间：${list[index]['time']}</div>
        <div>地址：
            ${
                address ? 
                `<span>${address}</span>`
                : 
                `<button id="checkAddress">查看地址</button>`
            }
        </div>
    </div>`
    
    infoWindow.setContent(content)
    infoWindow.open(map_instance, data[index])
    setTimeout(()=>{
        if(document.getElementById("checkAddress")){
            document.getElementById("checkAddress").onclick = async ()=> {
                const address = await getAddress(data[index])
                initInfoWindow(data,list,index,address)
            }
        }
    },10)
  }
  // 经纬度获取地理位置
  var getAddress = async(lnglat)=>{
      return new Promise((resolve,reject)=>{
          AMap.plugin('AMap.Geocoder', function() {
              let geocoder = new AMap.Geocoder({
                  city: '010'
              })
              geocoder.getAddress(lnglat, function(status, result) {
                  if (status === 'complete' && result.info === 'OK') {
                      let {formattedAddress} = result.regeocode
                      resolve(formattedAddress)
                  }else {
                      resolve()
                  }
              })
          })
      })
  }
  return (
    <>
      <div id="map_box" style={{ height: "500px", width: "100%" }}></div>
    </>
  );
});

export default Index;
