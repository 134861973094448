import React, { useState } from "react";
import { Form, Button, Input, Row, Col, DatePicker, message } from "antd";
import { _userRechargeExport } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
var SearchBox = (props) => {
  const { search, form } = props;
  const { getFieldDecorator } = form;
  const [exportload, setExportload] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      let [startTime, endTime] = values.selectTime || [];
      values.startTime = startTime
        ? moment(startTime).format("YYYY-MM-DD")
        : undefined;
      values.endTime = endTime
        ? moment(endTime).format("YYYY-MM-DD")
        : undefined;
      delete values.selectTime;
      search(values);
    });
  };

  const handleExportChange = async () => {
    setExportload(true);
    let values = form.getFieldsValue();
    let [startTime, endTime] = values.selectTime || [];
    values.startTime = startTime
      ? moment(startTime).format("YYYY-MM-DD")
      : undefined;
    values.endTime = endTime ? moment(endTime).format("YYYY-MM-DD") : undefined;
    delete values.selectTime;
    const params = {
      ...values,
    };
    const res = await _userRechargeExport(params);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户名/手机号">
              {getFieldDecorator("userNameMobile")(
                <Input placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="选择时间">
              {getFieldDecorator("selectTime")(
                <DatePicker.RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1025)}
                title={getJurisdiction(1025) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
