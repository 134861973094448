import React from "react";
import {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  createContext,
  useContext,
  useRef,
} from "react";
import "./index.less";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import {
  _imageUpload,
  _payBill,
  _getAccountInfo,
  _getBillPayCode,
  _payBilInfo,
  _kaAccountDetail,
  _payBillAccount,
} from "@/statics/js/api.js";
import moment from "moment";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  InputNumber,
  message,
  DatePicker,
  Radio,
  Upload,
  Icon,
} from "antd";
const cxt = createContext({
  payType: "",
  type: "",
  id: "",
  bill: {},
  balance: 0,
});

// 余额支付
var BalancePay = Form.create({})(
  forwardRef((props, ref) => {
    const { bill, balance } = useContext(cxt);

    useEffect(() => {}, []);

    return (
      <>
        <div className="scan_wrap">
          <h3>当前余额 {balance}</h3>
          <h3>需要支付</h3>
          <h2 style={{ color: "red" }}>{bill.amount}</h2>
        </div>
      </>
    );
  })
);

// 扫码支付
var ScanPay = Form.create({})(
  forwardRef((props, ref) => {
    const { id, payType, bill } = useContext(cxt);
    const [aliPay, setAliPay] = useState({
      amount: bill.amount,
      qrcode: "",
    });
    const [weiPay, setWeiPay] = useState({
      amount: bill.amount,
      qrcode: "",
    });

    useEffect(() => {
      initQrcode();
    }, []);

    const initQrcode = async () => {
      let [aliUrl, weiUrl] = await Promise.all([
        getPayCode({ payType: 1 }),
        getPayCode({ payType: 2 }),
      ]);
      setAliPay((value) => ({
        ...value,
        qrcode: aliUrl,
      }));
      setWeiPay((value) => ({
        ...value,
        qrcode: weiUrl,
      }));
    };
    // 获取付款码
    const getPayCode = async ({ payType }) => {
      let params = {
        billId: id,
        payType,
      };
      const res = await _getBillPayCode(params);
      const { code, data = "", message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      return data;
    };
    return (
      <>
        <div className="scan_wrap">
          <h3>{payType == 1 ? "支付宝" : "微信"}扫一扫支付账单（元）</h3>
          <h2 style={{ color: "red" }}>
            {payType == 1 ? aliPay.amount : weiPay.amount}
          </h2>
          <img src={payType == 1 ? aliPay.qrcode : weiPay.qrcode} alt="" />
        </div>
      </>
    );
  })
);

// 银行卡支付
var BankPay = Form.create({})(
  forwardRef((props, ref) => {
    const { form } = props;
    const { getFieldDecorator } = form;
    const { id, type, bill } = useContext(cxt);
    const [accountInfo, setAccountInfo] = useState({
      bankName: "",
      cardNo: "",
      companyName: "",
    });
    const [query, setQuery] = useState({
      transferAmount: "",
      transferTime: "",
      tradeNo: "",
      img: [],
      remark: "",
    });

    const [record, setRecord] = useState({
      fname: "",
      changeAmount: "",
      changeTime: "",
      tradeNo: "",
      imgList: [],
      remark: "",
    });

    useEffect(() => {
      getAccountInfo();
      getBalanceDetail();
    }, []);

    // 收款方
    const getAccountInfo = async () => {
      const res = await _getAccountInfo();
      const { code, data = {}, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      setAccountInfo(data);
    };
    // 充值详情
    const getBalanceDetail = async () => {
      const params = {
        id,
      };
      const res = await _payBilInfo(params);
      const { code, data = {}, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      setRecord(data);
    };
    const handleUploadChange = ({ file, fileList }) => {
      if (fileList.length === 1) {
        if (!!fileList[0].response) {
          if (fileList[0].response.code !== 200) {
            message.warning(fileList[0].response.message);
            fileList.splice(0, 1);
          }
        }
      }
      setQuery((val) => ({ ...val, img: fileList }));
    };

    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    return (
      <>
        <Form>
          <Row>
            <div className="info_wrap">
              <Row>
                <Col span={12}>收款公司：{accountInfo.companyName}</Col>
                <Col span={12}>收款银行账号：{accountInfo.cardNo}</Col>
                <br />
                <br />
                <Col span={12}>收款银行：{accountInfo.bankName}</Col>
                <Col span={12}>账单金额：{bill.amount}</Col>
              </Row>
            </div>
            <Col span={24}>
              <Form.Item label="付款公司">
                <span>{record.fname}</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="转账金额">
                {type == 1 ? (
                  <span>{record.payAmount}</span>
                ) : (
                  getFieldDecorator("transferAmount", {
                    initialValue: query.transferAmount,
                    rules: [{ required: true, message: "请输入转账金额" }],
                  })(<InputNumber min={0} precision={2} />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="转账时间">
                {type == 1 ? (
                  <span>{moment(record.changeTime).format("YYYY-MM-DD")}</span>
                ) : (
                  getFieldDecorator("transferTime", {
                    rules: [{ required: true, message: "请选择日期！" }],
                  })(<DatePicker format="YYYY-MM-DD" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="转账流水号">
                {type == 1 ? (
                  <span>{record.tradeNo}</span>
                ) : (
                  getFieldDecorator("tradeNo", {
                    initialValue: query.tradeNo,
                    rules: [{ required: true, message: "请输入转账流水号" }],
                  })(<Input />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="上传图片">
                {type == 1 ? (
                  <img
                    src={record.imgList && record.imgList[0]}
                    width="102px"
                    height="102px"
                  />
                ) : (
                  getFieldDecorator("img", {
                    initialValue: query.img,
                    getValueFromEvent: normFile,
                    rules: [{ required: true, message: "请选择图片" }],
                  })(
                    <Upload
                      name="uploadFile"
                      action={_imageUpload}
                      listType="picture-card"
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                      fileList={query.img}
                      onChange={handleUploadChange}
                      data={{
                        scene: "active",
                      }}
                    >
                      {query.img.length < 1 && (
                        <div>
                          <Icon type="plus" style={{ fontSize: "28px" }} />
                          <p>图片上传</p>
                        </div>
                      )}
                    </Upload>
                  )
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  })
);

// 弹框
var PayModal = forwardRef((props, ref) => {
  const { done } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(0);
  const [id, setId] = useState("");
  const [bill, setBill] = useState({});
  const [payType, setPayType] = useState("1");
  const bankRef = useRef();
  const scanRef = useRef();
  const userType = localStorage.getItem("type");
  const [balance, setBalance] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );
  // 账户余额
  const getAccountDetail = async () => {
    const res = await _kaAccountDetail();
    const { code, data = {}, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    const { amount } = data || {};
    setBalance(amount);
  };
  const handlePayTypeChange = (e) => {
    let { value } = e.target;
    setPayType(value);
  };
  const open = async (row) => {
    const { payStatus: type, id } = row;
    setVisible(true);
    setType(type);
    setId(id);
    setBill(row);
    getAccountDetail();
    if (type == 1) {
      setPayType("3");
    } else {
      setPayType("1");
    }
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    if (payType == 4) {
      paySave();
      return;
    }
    if (payType != 3 || type == 1) {
      close();
      return;
    }
    const form = bankRef.current;
    form.validateFields((err, values) => {
      if (err) return;
      if (payType == 3) {
        let ids = [];
        if (values.img && values.img.constructor === Array) {
          ids = values.img.map((item) => item.response.data.id);
        }
        const params = {
          ...values,
          transferTime: moment(values.transferTime).format("YYYY-MM-DD"),
          img: ids.join(),
          billId: bill.id,
        };
        bankSave(params);
      }
    });
  };
  // 银行卡提交
  const bankSave = async (params) => {
    setConfirmLoading(true);
    const res = await _payBill(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };
  // 余额支付
  const paySave = async () => {
    if (balance < bill.amount) {
      message.warning("余额不足");
      return;
    }
    const params = {
      billId: bill.id,
    };
    setConfirmLoading(true);
    const res = await _payBillAccount(params);
    setConfirmLoading(false);
    const { code, message: msg } = res.data || {};
    if (code != 200) {
      message.warning(msg);
      return;
    }
    message.success("操作成功");
    done();
    close();
  };
  return (
    <div>
      <Modal
        title="支付"
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <cxt.Provider value={{ type, id, payType, bill, balance }}>
          {visible && (
            <>
              {payType == 3 ? (
                <BankPay ref={bankRef} />
              ) : payType == 4 ? (
                <BalancePay />
              ) : (
                <ScanPay ref={scanRef} />
              )}
            </>
          )}
        </cxt.Provider>
        {type == 1 ? null : (
          <Row>
            <Col span={24}>
              <Form.Item label="支付方式">
                <Radio.Group onChange={handlePayTypeChange} value={payType}>
                  <Radio value="1">支付宝扫码</Radio>
                  <Radio value="2">微信扫码</Radio>
                  <Radio value="3" disabled={!getJurisdiction(1012)}>
                    银行卡支付
                  </Radio>
                  <Radio value="4" disabled={userType == 0}>
                    余额支付
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
});

export default PayModal;
