import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useHandle from "@/hooks/handle";
import CommonTable from "@/components/commonTable";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _orderSurrenderList, _exportSurrenderList } from "@/statics/js/api";
import { Form, Button, Row, Col, Input } from "antd";

const columns = [
  {
    title: "代理商",
    dataIndex: "fname",
  },
  {
    title: "站点名称",
    dataIndex: "sname",
  },
  {
    title: "订单号",
    dataIndex: "orderSn",
    render: (text) => (
      <Link target="_blank" to={"/packageOrderManagement?orderSn=" + text}>
        {text}
      </Link>
    ),
  },
  {
    title: "用户姓名",
    dataIndex: "realname",
  },
  {
    title: "用户联系方式",
    dataIndex: "mobile",
  },
  {
    title: "设备号",
    dataIndex: "",
    render: (text) => {
      return (
        <div>
          {text.bikeImei && <div>车辆：{text.bikeImei}</div>}
          {text.batteryImei && <div>电池：{text.batteryImei}</div>}
        </div>
      );
    },
  },
  {
    title: "状态",
    dataIndex: "statusName",
  },
];

const Index = Form.create({})((props) => {
  const { form } = props;
  const { getFieldDecorator, validateFields, getFieldsValue } = form;
  const tableRef = useRef();
  const { loading: exportLoading, handleDownload } = useHandle();

  useEffect(() => {}, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      tableRef.current.handleSearch(values);
    });
  };
  const handleExport = async () => {
    const values = getFieldsValue();
    handleDownload({
      action: _exportSurrenderList,
      query: {
        ...values,
      },
    });
  };
  return (
    <div>
      {getJurisdiction(1080) ? (
        <>
          <Form onSubmit={handleSubmit} layout="inline">
            <Row gutter={16}>
              <Col lg={6} md={12} sm={24}>
                <Form.Item label="用户姓名/手机号">
                  {getFieldDecorator("nameMobile")(
                    <Input allowClear placeholder="请输入" />
                  )}
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={24}>
                <Form.Item label="订单号">
                  {getFieldDecorator("orderSn")(
                    <Input allowClear placeholder="请输入" />
                  )}
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                  <Button
                    disabled={!getJurisdiction(1081)}
                    title={getJurisdiction(1081) ? "" : "暂无操作权限！"}
                    onClick={handleExport}
                    style={{ marginLeft: 10 }}
                    loading={exportLoading}
                  >
                    导出
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <CommonTable
            ref={tableRef}
            action={_orderSurrenderList}
            options={{
              columns: columns,
            }}
          />
        </>
      ) : (
        "暂无操作权限！"
      )}
    </div>
  );
});

export default Index;
