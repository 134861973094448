import React, { Component } from 'react'
import { Button, Table, Form, DatePicker, message, Row, Col } from 'antd'
import { _insureRecordList, _exportInsureRecord } from '@/statics/js/api'
import moment from 'moment'

import { getJurisdiction } from '@/statics/js/jurisdiction'
const MonthPicker = DatePicker.MonthPicker
class FinancialBuyInsurance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      insureRecordList: [], //实付逾期租金违约金列表
    }

    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '订单号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '支付宝流水号',
        align: 'right',
        width: 160,
        dataIndex: 'tradeNo',
      },
      {
        title: '车辆二维码',
        align: 'right',
        dataIndex: 'vin',
      },
      {
        title: '电池编号',
        align: 'right',
        dataIndex: 'imei',
      },
      {
        title: '套餐名称',
        dataIndex: 'pname',
      },
      {
        title: '套餐金额',
        align: 'right',
        dataIndex: 'pmoney',
      },
      {
        title: '订单开始时间',
        width: 120,
        dataIndex: 'orderStartTime',
      },
      {
        title: '是否买免赔服务',
        dataIndex: 'isInsure',
      },
      {
        title: '代理商名称',
        dataIndex: 'fname',
      },
      {
        title: '站点名称',
        dataIndex: 'sname',
      },
      {
        title: '最近实付时间',
        width: 120,
        dataIndex: 'lastPayTime',
      },
      {
        title: '实付免赔服务',
        align: 'right',
        dataIndex: 'insureMoney',
      },
      {
        title: '是否平台车辆',
        dataIndex: 'isBelong',
      },
    ]
  }
  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="选择月份">
              {getFieldDecorator('staticMonth')(
                <MonthPicker
                  disabledDate={this.disabledDate.bind(this)}
                  placeholder="选择月份"
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(840)}
                title={getJurisdiction(840) ? '' : '暂无操作权限！'}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const { totalCount, curPage, pageSize, insureRecordList } = this.state
    return (
      <div>
        {getJurisdiction(839) ? (
          <div className="FinancialBuyInsurance">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={insureRecordList}
                scroll={{ x: 1700, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getDiffRecordList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }

  //获取实付免赔列表
  getDiffRecordList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    }
    _insureRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            insureRecordList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          })
        } else {
          message.warning(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getDiffRecordList(curPage, pageSize, this.state.queryFormValues)
  }

  //不可选择时间
  disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.staticMonth) {
        values.staticMonth = moment(values.staticMonth).format('YYYY-MM')
      }
      this.setState({
        queryFormValues: values,
      })
      this.getDiffRecordList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.staticMonth) {
        values.staticMonth = moment(values.staticMonth).format('YYYY-MM')
      }
      let params = {
        ...values,
      }
      _exportInsureRecord(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            })
            window.open(`${res.data.data}`, '_blank').focus()
          } else {
            message.warning(res.data.message)
            this.setState({
              exportload: false,
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}

FinancialBuyInsurance = Form.create({})(FinancialBuyInsurance)
export { FinancialBuyInsurance }
