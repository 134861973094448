import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Table,
  Modal,
  Select,
  Switch,
  Button,
  Divider,
  message,
  DatePicker,
} from "antd";
import {
  _getQRCode,
  _fastChargeList,
  _saveFastCharge,
  _fastChargeDetail,
  _fastChargeLogList,
  _fastChargeModelList,
  _batchImportFastCharge,
  _updateFastChargeStatus,
  _updateFastChargeControl,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
import queryString from 'query-string';
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const modalTitle = ["添加充电桩", "编辑充电桩"];
class EVCharger extends Component {
  constructor(props) {
    super(props);
    const { id } = queryString.parse(props.location.search)
    this.state = {
      id: "", //id
      cid: id,
      queryFormValues: {
        status: 1,
      }, //筛选项
      curPage: 1, //当前页数
      pageSize: 10, // 每页展示个数
      totalCount: 0, //数据总数量
      fastChargeList: [], //管理列表
      fastChargeModelList: [], //型号列表
      fastChargeLogList: [], //充电记录列表
      initialValues: {}, //详情
      modalType: "", //1-添加，2-编辑，3-记录
      visible: false, //Modal是否打开
      visibleDetail: false, //Modal是否打开（记录）
      visibleImport: false, //Modal是否打开（导入）
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "充电桩信息",
        dataIndex: "imei",
      },
      {
        title: "二维码号",
        dataIndex: "qrcode",
      },
      {
        title: "电柜名称",
        dataIndex: "chargeName",
      },
      {
        title: "充电桩型号",
        dataIndex: "modelName",
      },
      {
        title: "充电桩状态",
        dataIndex: "fastChargeType",
        render: (text) =>
          text === 0
            ? "离网"
            : text === 1
            ? "空闲"
            : text === 2
            ? "占用(未充电)"
            : text === 3
            ? "占用(充电中)"
            : text === 4
            ? "占用(预约锁定)"
            : text === 255
            ? "故障"
            : "",
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "pushTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.imei, text.cid)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 120,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button
              type="link"
              onClick={this.modalViewQrcode.bind(this, text.id, text.imei)}
            >
              二维码
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(752)}
              title={getJurisdiction(752) ? "" : "暂无操作权限！"}
              onClick={this.getFastChargeDetail.bind(this, text.id, 2)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(928)}
              title={getJurisdiction(928) ? "" : "暂无操作权限！"}
              onClick={this.getFastChargeLogList.bind(this, text.imei)}
            >
              记录
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(925)}
              title={getJurisdiction(925) ? "" : "暂无操作权限！"}
              onClick={this.modalViewhandle.bind(this, text)}
            >
              控制
            </Button>
          </span>
        ),
      },
    ];
    this.logColumns = [
      {
        title: "序号",
        dataIndex: "id",
      },
      {
        title: "充电流水号",
        width: 120,
        align: "right",
        dataIndex: "blotterId",
      },
      {
        title: "代理商",
        dataIndex: "fname",
      },
      {
        title: "快充点",
        dataIndex: "chargeName",
      },
      {
        title: "用户",
        dataIndex: "yid",
      },
      {
        title: "开始时间",
        width: 120,
        dataIndex: "startTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "结束时间",
        width: 120,
        dataIndex: "endTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "充电量",
        dataIndex: "capacity",
        render: (text) => (text ? text + "kW·h" : null),
      },
      {
        title: "金额",
        dataIndex: "money",
        render: (text) => (text ? text + "元" : null),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="充电桩信息">
              {getFieldDecorator("imeiSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="充电桩型号">
              {getFieldDecorator("modelIdSearch")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.fastChargeModelList.length &&
                    this.state.fastChargeModelList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="充电桩状态">
              {getFieldDecorator("fastChargeTypeSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="0">离网</Option>
                  <Option value="1">空闲</Option>
                  <Option value="2">占用(未充电)</Option>
                  <Option value="3">占用(充电中)</Option>
                  <Option value="4">占用(预约锁定)</Option>
                  <Option value="255">故障</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="添加时间">
              {getFieldDecorator("pushTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("statusSearch", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>禁用</Option>
                  <Option value={1}>启用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      fastChargeList,
      initialValues,
      fastChargeLogList,
      pageSize,
      totalCount,
      curPage,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(922) ? (
          <div className="EVCharger">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(923)}
                title={getJurisdiction(923) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加
              </Button>
              <Button
                icon="cloud-upload"
                disabled={!getJurisdiction(927)}
                title={getJurisdiction(927) ? "" : "暂无操作权限！"}
                onClick={this.showImportModal.bind(this)}
              >
                导入数据
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={fastChargeList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="充电桩编号">
                    {getFieldDecorator("imei", {
                      initialValue: initialValues.imei,
                      rules: [
                        { required: true, message: "请输入充电桩编号！" },
                      ],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="充电桩二维码">
                    {getFieldDecorator("qrcode", {
                      initialValue: initialValues.qrcode,
                      rules: [{ required: true, message: "充电桩二维码" }],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="充电桩型号">
                    {getFieldDecorator("modelId", {
                      initialValue: initialValues.modelId,
                      rules: [
                        { required: true, message: "请选择充电桩型号！" },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.fastChargeModelList.length &&
                          this.state.fastChargeModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="导入充电桩"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleImport}
              onOk={this.handleImportOk.bind(this)}
              onCancel={this.initImportFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="充电桩型号">
                    {getFieldDecorator("modelId", {
                      rules: [
                        { required: true, message: "请选择充电桩型号！" },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.fastChargeModelList.length &&
                          this.state.fastChargeModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="编号">
                    {getFieldDecorator("imeiList", {
                      rules: [{ required: true, message: "请输入编号！" }],
                    })(
                      <TextArea
                        placeholder="输入编码分别为:[充电桩编号XXXXX,二维码编号XXXXX]一组。用“,”分割，一行一组"
                        autoSize={{ minRows: 10, maxRows: 15 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="充电记录"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleDetail}
              onOk={this.handleClose.bind(this)}
              onCancel={this.handleClose.bind(this)}
            >
              <Table
                rowKey="id"
                bordered
                columns={this.logColumns}
                dataSource={fastChargeLogList}
                scroll={{ x: 1200, y: 500 }}
              />
            </Modal>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFastChargeModelList();
    this.getFastChargeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues,
      this.state.cid
    );
  }

  //查看详情（打开）
  showDetialModal() {
    this.setState({
      visibleDetail: true,
    });
  }

  //关闭记录
  handleClose(e) {
    this.setState({
      id: "",
      initialValues: {},
      visibleDetail: false,
    });
  }

  //获取详情
  getFastChargeDetail(id, type) {
    let params = { id };
    _fastChargeDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.showModal(type);
  }

  //获取记录列表
  getFastChargeLogList(imei) {
    let params = {
      imei,
      curPage: 1,
      pageSize: 999,
    };
    _fastChargeLogList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            fastChargeLogList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.props.form && this.props.form.resetFields();
    this.setState({
      visibleDetail: true,
    });
  }

  //获取充电桩型号列表
  getFastChargeModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _fastChargeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            fastChargeModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //二维码查看
  modalViewQrcode(deviceId, imei) {
    let params = { deviceId, type: "fastCharge" };
    _getQRCode(params)
      .then((res) => {
        if (res.data.code === 200) {
          Modal.info({
            title: `充电桩二维码 [${imei}]`,
            content: (
              <img src={res.data.data} style={{ width: "100%" }} alt="" />
            ),
            onOk() {},
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //控制操作
  modalViewhandle(text) {
    let self = this;
    let prohibit = null;
    let open = null;
    let control;
    if (!text.fastChargeType) {
      prohibit = true;
      open = true;
    } else {
      prohibit = !text.control;
      open = !!text.control;
    }
    Modal.confirm({
      title: `充电桩控制 [${text.imei}]`,
      content: (
        <Radio.Group
          onChange={(e) => {
            control = e.target.value;
          }}
        >
          <Radio disabled={open} value={1}>
            开启充电
          </Radio>
          <Radio disabled={prohibit} value={0}>
            禁止充电
          </Radio>
        </Radio.Group>
      ),
      onOk() {
        let params = {
          id: text.id,
          imei: text.imei,
          control,
        };
        _updateFastChargeControl(params).then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.message);
          } else {
            message.warning(res.data.message);
          }
          self.getFastChargeList(
            self.state.curPage,
            self.state.pageSize,
            self.state.queryFormValues,
            self.state.cid
          );
        });
      },
    });
  }

  //状态Switch
  handleStateChange(imei, cid, checked) {
    let params = { imei, cid, status: Number(checked) };
    _updateFastChargeStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getFastChargeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues,
            this.state.cid
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取列表
  getFastChargeList(curPage, pageSize, queryFormValues, cid) {
    let params = {
      ...queryFormValues,
      curPage,
      pageSize,
      cid,
    };
    _fastChargeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            fastChargeList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getFastChargeList(
      curPage,
      pageSize,
      this.state.queryFormValues,
      this.state.cid
    );
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.pushTime && values.pushTime.length) {
        values.pushTimeStart = moment(values.pushTime[0]).format("YYYY-MM-DD");
        values.pushTimeEnd = moment(values.pushTime[1]).format("YYYY-MM-DD");
      }
      delete values.pushTime;
      if (typeof values.statusSearch === "number") {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      if (values.modelIdSearch) {
        values.modelId = values.modelIdSearch;
        delete values.modelIdSearch;
      }
      if (values.fastChargeTypeSearch) {
        values.fastChargeType = values.fastChargeTypeSearch;
        delete values.fastChargeTypeSearch;
      }
      if (values.imeiSearch) {
        values.imei = values.imeiSearch;
        delete values.imeiSearch;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getFastChargeList(1, this.state.pageSize, values, this.state.cid);
    });
  };

  //Modal打开事件
  showModal(modalType) {
    this.props.form && this.props.form.resetFields();
    this.setState({
      modalType,
      visible: true,
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err.imei || err.qrcode || err.modelId) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
        values.cid = this.state.cid;
      }
      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _saveFastCharge(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: "",
      initialValues: {},
      visible: false,
      orderId: null,
    });
  }
  //Modal打开事件（导入）
  showImportModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({ visibleImport: true });
  }

  //Modal提交事件（导入）
  handleImportOk(e) {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err.modelId || err.imeiList) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.statusSearch;
      if (values.imeiList) {
        let imeiList = values.imeiList.split("\n");
        values.imeiList = Array.from(new Set(imeiList));
      }
      values.cid = this.state.cid;
      let params = { ...values };
      _batchImportFastCharge(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visibleImport: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件（导入）
  initImportFormModal() {
    this.props.form && this.props.form.resetFields();
    this.setState({
      visibleImport: false,
    });
  }
}
EVCharger = Form.create({})(EVCharger);
export { EVCharger };
