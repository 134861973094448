import React, { 
    useEffect,
    useRef,
} from 'react';
import useHandle from '@/hooks/handle'
import CommonTable from '@/components/commonTable'
import BikeModelSelect from '@/components/bikeModelSelect'
import FranchiseeSelect from '@/components/franchiseeSelect'
import moment from 'moment';
import {
    _operateDayBikeOrderList,
    _operateDayBikeOrderExport,
    _operateBikeOrderList,
    _operateBikeOrderExport,
} from "@/statics/js/api"
import {
    Form,
    Button,
    Select,
    Row,
    Col,
    Tooltip,
    Icon,
    DatePicker
} from "antd";
const { Option } = Select;
const {WeekPicker,RangePicker,MonthPicker} = DatePicker

const columns1 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "设备型号",
        dataIndex: "modelName",
    },
    {
        title: "设备类型",
        dataIndex: "self",
        render: (text)=> text == 1 ? '自营' : (text == 0 ? '非自营' : '')
    },
    {
        title: ()=>(
            <>
                <span>新增</span>&nbsp;
                <Tooltip placement="top" title="当日变为租车中状态的订单">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "newOrderSize",
    },
    {
        title: ()=>(
            <>
                <span>续租</span>&nbsp;
                <Tooltip placement="top" title="当日续租的订单 不算线下处理订单">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "reletOrderSize",
    },
    {
        title: "退租",
        dataIndex: "tzOrderSize",
    },
    {
        title: "租车中",
        dataIndex: "rentOrderSize",
    },
    {
        title: "待提车",
        dataIndex: "dtOrderSize",
    },
    {
        title: ()=>(
            <>
                <span>累计在租</span>&nbsp;
                <Tooltip placement="top" title="查询时间范围内曾有过在租车中状态的订单">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "rentBikeSum",
    },
]

const columns2 = [
    {
        title: "代理商",
        dataIndex: "fname",
    },
    {
        title: "设备型号",
        dataIndex: "modelName",
    },
    {
        title: ()=>(
            <>
                <span>新增</span>&nbsp;
                <Tooltip placement="top" title="时间段内绑定过车辆的订单（不管订单状态）+线下处理订单（线下处理订单为订单延时+有金额结束订单）">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "newOrderSize",
    },
    {
        title: "新增环比",
        dataIndex: "newOrderSizeScale",
    },
    {
        title: ()=>(
            <>
                <span>续租</span>&nbsp;
                <Tooltip placement="top" title="时间段内的续租的订单（线上+线下处理订单为订单延时+有金额结束订单）">
                    <Icon type="question-circle" />
                </Tooltip>
            </>
        ),
        dataIndex: "reletOrderSize",
    },
    {
        title: "续租环比",
        dataIndex: "reletOrderSizeScale",
    },
]

const Index1 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()
    
    useEffect(()=>{
        handleSearch()
    },[])
    
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const [startTime,endTime] = values.date || []
            const params = {
                ...values,
                startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
                endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const [startTime,endTime] = values.date || []
        handleDownload({
            action: _operateDayBikeOrderExport,
            query: {
                ...values,
                startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
                endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
                type: 0
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={8} md={12} sm={24}>
                    <Form.Item label="设备型号">
                        {getFieldDecorator("modelId")(
                            <BikeModelSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={8} md={12} sm={24}>
                    <Form.Item label="设备类型">
                        {getFieldDecorator("self")(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={1}>自营</Option>
                                <Option value={0}>非自营</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col lg={8} md={12} sm={24}>
                    <Form.Item label="日期">
                        {getFieldDecorator("date",{
                            initialValue: [moment().subtract(1, 'days'),moment().subtract(1, 'days')]
                        })(
                            <RangePicker format="YYYY-MM-DD" />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={4} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <p>*报表数据为日维度</p>
        <CommonTable
            ref={tableRef}
            action={_operateDayBikeOrderList}
            options={{
                columns: columns1
            }}
            params={{
                type: 0,
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index2 = Form.create({})((props)=> {
    const {form} = props
    const {getFieldDecorator,validateFields,getFieldsValue,getFieldValue} = form
    const tableRef = useRef();
    let defaultfid = ''
    const userType = localStorage.getItem("type")
    const fid = localStorage.getItem("fid") ? Number(localStorage.getItem("fid")) : ''
    if(userType !== '0'){
        defaultfid = fid
    }
    const {
        loading:exportLoading,
        handleDownload,
    } = useHandle()

    useEffect(()=>{
        handleSearch()
    },[])
    const handleSearch = async (e)=>{
        e && e.preventDefault();
        validateFields((err, values) => {
            const {week,type,month} = values
            let time = {}
            if(type == 1){
                time = {
                    startTime: moment(week).day(1).format('YYYY-MM-DD'),
                    endTime: moment(week).day(7).format('YYYY-MM-DD'),
                }
            }else{
                time = {
                    startTime: month ? moment(month).startOf('month').format('YYYY-MM-DD') : undefined,
                    endTime: month ? moment(month).endOf('month').format('YYYY-MM-DD') : undefined,
                }
            }
            const params = {
                ...values,
                ...time,
            }
            tableRef.current.handleSearch(params)
        })
    }
    const handleExport = async ()=>{
        const values = getFieldsValue()
        const {week,type,month} = values
        let time = {}
        if(type == 1){
            time = {
                startTime: moment(week).day(1).format('YYYY-MM-DD'),
                endTime: moment(week).day(7).format('YYYY-MM-DD'),
            }
        }else{
            time = {
                startTime: month ? moment(month).startOf('month').format('YYYY-MM-DD') : undefined,
                endTime: month ? moment(month).endOf('month').format('YYYY-MM-DD') : undefined,
            }
        }
        handleDownload({
            action: _operateBikeOrderExport,
            query: {
                ...values,
                ...time,
            }
        })
    }
    return (<>
        <Form onSubmit={handleSearch} layout="inline">
            <Row gutter={16}>
                <Col lg={5} md={12} sm={24}>
                    <Form.Item label="时间维度">
                        {getFieldDecorator("type",{
                            initialValue: 1
                        })(
                            <Select
                                placeholder="请选择"
                                allowClear
                                style={{ width: "100%" }}
                            >
                                <Option value={2}>月报表</Option>
                                <Option value={1}>周报表</Option>
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                {
                    getFieldValue('type') == 1 ? 
                    <Col lg={5} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("week",{
                                initialValue: moment().subtract(1,'week').startOf('week').add(1,'day')
                            })(
                                <WeekPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }}/>
                            )}
                        </Form.Item>
                    </Col>
                    :
                    <Col lg={5} md={12} sm={24}>
                        <Form.Item label="时间范围">
                            {getFieldDecorator("month",{
                                initialValue: moment().month(moment().month() - 1).startOf('month')
                            })(
                                <MonthPicker allowClear={false} placeholder="请选择" style={{ width: "100%" }} />
                            )}
                        </Form.Item>
                    </Col>
                }
                {
                    userType == 0 && 
                    <Col lg={8} md={12} sm={24}>
                        <Form.Item label="代理商">
                            {getFieldDecorator("fid")(
                                <FranchiseeSelect />
                            )}
                        </Form.Item>
                    </Col>
                }
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="设备型号">
                        {getFieldDecorator("modelId")(
                            <BikeModelSelect />
                        )}
                    </Form.Item>
                </Col>
                <Col lg={5} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button onClick={handleExport} style={{ marginLeft: 10 }} loading={exportLoading}>导出</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CommonTable
            ref={tableRef}
            auto={false}
            action={_operateBikeOrderList}
            options={{
                columns: columns2
            }}
            params={{
                fid: defaultfid || undefined,
            }}
        />
    </>)
})

const Index = ()=>{
    return (<>
        <Index1 />
        <br />
        <Index2 />
    </>)
}

export default Index