import React,{ 
    useEffect,
    forwardRef,
} from 'react'
import useList from '@/hooks/list'
import {
    Select,
} from "antd";
const { Option } = Select;

const Index = forwardRef((props,ref)=>{
    const {self} = props
    const {
        getBatteryModelList,
        batteryModelList,
    } = useList()

    useEffect(() => {
        getBatteryModelList({self})
    },[props.self]);
    
    return (
        <Select
            placeholder="请选择"
            allowClear
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...props}
        >
            {
                batteryModelList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
            }
        </Select>
    )
})

export default Index