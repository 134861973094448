import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Input,
  DatePicker,
  Col,
  message,
  Select,
} from "antd";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";
import { _exportDayRepaymentInfoList } from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import moment from "moment";
const userType = localStorage.getItem("type");
var SearchBox = (props) => {
  const { search, form, orderSn } = props;
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [exportload, setExportload] = useState(false);
  const [startTime, setStartTime] = useState(
    moment(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endTime, setEndTime] = useState(moment(new Date()));
  const [endOpen, setEndOpen] = useState(false);
  const [startOrderBeginTime, setStartOrderBeginTime] = useState(null);
  const [endOrderBeginTime, setEndOrderBeginTime] = useState(null);
  const [endOrderBeginOpen, setEndOrderBeginOpen] = useState(false);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ orderSn });
    let params = getFormValues();
    search(params);
  }, [props.orderSn]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let params = getFormValues();
    search(params);
  };
  const handleExportChange = async () => {
    setExportload(true);
    let params = getFormValues();
    const res = await _exportDayRepaymentInfoList(params);
    if (res.data.code === 200) {
      window.open(`${res.data.data}`, "_blank").focus();
    } else {
      message.warning(res.data.message);
    }
    setExportload(false);
  };
  const getFormValues = () => {
    let values = form.getFieldsValue();
    console.log(values, endTime, startTime);
    if (startTime && endTime) {
      values.startTime = moment(startTime).format("YYYY-MM-DD");
      values.endTime = moment(endTime).format("YYYY-MM-DD");
    } else {
      setEndTime(null);
      setStartTime(null);
      values.startTime = undefined;
      values.endTime = undefined;
    }
    if (startOrderBeginTime && endOrderBeginTime) {
      values.startOrderBeginTime =
        moment(startOrderBeginTime).format("YYYY-MM-DD");
      values.endOrderBeginTime = moment(endOrderBeginTime).format("YYYY-MM-DD");
    } else {
      setEndOrderBeginTime(null);
      setStartOrderBeginTime(null);
      values.startOrderBeginTime = undefined;
      values.endOrderBeginTime = undefined;
    }
    return values;
  };

  const disabledStartDate = (startTime) => {
    if (!startTime || !endTime) {
      return false;
    }
    return (
      startTime.valueOf() > endTime.valueOf() ||
      startTime.valueOf() < endTime.valueOf() - 90 * 24 * 60 * 60 * 1000
    );
  };

  const disabledEndDate = (endTime) => {
    if (!endTime || !startTime) {
      return false;
    }
    return (
      endTime.valueOf() <= startTime.valueOf() ||
      endTime.valueOf() > startTime.valueOf() + 90 * 24 * 60 * 60 * 1000
    );
  };

  const onChangeTime = (value, type) => {
    console.log(value);
    if (type === "start") {
      setStartTime(value);
    } else {
      setEndTime(value);
    }
    setStartOrderBeginTime(null);
    setEndOrderBeginTime(null);
  };

  const handleOpenChange = (open, type) => {
    if (type === "start") {
      if (!open) {
        setEndOpen(true);
      }
    } else {
      setEndOpen(open);
    }
  };

  const disabledOrderStartDate = (startOrderBeginTime) => {
    if (!startOrderBeginTime || !endOrderBeginTime) {
      return false;
    }
    return (
      startOrderBeginTime.valueOf() > endOrderBeginTime.valueOf() ||
      startOrderBeginTime.valueOf() <
        endOrderBeginTime.valueOf() - 90 * 24 * 60 * 60 * 1000
    );
  };

  const disabledOrderEndDate = (endOrderBeginTime) => {
    if (!endOrderBeginTime || !startOrderBeginTime) {
      return false;
    }
    return (
      endOrderBeginTime.valueOf() <= startOrderBeginTime.valueOf() ||
      endOrderBeginTime.valueOf() >
        startOrderBeginTime.valueOf() + 90 * 24 * 60 * 60 * 1000
    );
  };
  const onChangeOrderTime = (value, type) => {
    if (type === "start") {
      setStartOrderBeginTime(value);
    } else {
      setEndOrderBeginTime(value);
    }
    setStartTime(null);
    setEndTime(null);
  };

  const handleOrderOpenChange = (open, type) => {
    if (type === "start") {
      if (!open) {
        setEndOrderBeginOpen(true);
      }
    } else {
      setEndOrderBeginOpen(open);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} layout="inline">
        <Row gutter={16}>
          {userType === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("agentId")(
                  <FranchiseeSelect
                    onChange={() => {
                      setFieldsValue({ siteId: "" });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点">
              {getFieldDecorator("siteId")(
                <SiteSelect fid={getFieldValue("agentId")} />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="分期付订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("status")(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Select.Option value={""}>全部</Select.Option>
                  <Select.Option value={0}>未完成</Select.Option>
                  <Select.Option value={1}>已完成</Select.Option>
                  <Select.Option value={2}>已取消</Select.Option>
                  <Select.Option value={3}>已逾期</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24}>
            <Form.Item label="计划扣款时间">
              <div style={{ display: "flex" }}>
                <DatePicker
                  disabledDate={disabledStartDate}
                  format="YYYY-MM-DD"
                  value={startTime}
                  placeholder="开始"
                  onChange={(value) => onChangeTime(value, "start")}
                  onOpenChange={(open) => handleOpenChange(open, "start")}
                />
                <DatePicker
                  disabledDate={disabledEndDate}
                  format="YYYY-MM-DD"
                  value={endTime}
                  placeholder="结束"
                  onChange={(value) => onChangeTime(value, "end")}
                  open={endOpen}
                  onOpenChange={(open) => handleOpenChange(open, "end")}
                />
              </div>
            </Form.Item>
          </Col>
          <Col lg={8} md={12} sm={24}>
            <Form.Item label="订单下单时间">
              <div style={{ display: "flex" }}>
                <DatePicker
                  disabledDate={disabledOrderStartDate}
                  format="YYYY-MM-DD"
                  value={startOrderBeginTime}
                  placeholder="开始"
                  onChange={(value) => onChangeOrderTime(value, "start")}
                  onOpenChange={(open) => handleOrderOpenChange(open, "start")}
                />
                <DatePicker
                  disabledDate={disabledOrderEndDate}
                  format="YYYY-MM-DD"
                  value={endOrderBeginTime}
                  placeholder="结束"
                  onChange={(value) => onChangeOrderTime(value, "end")}
                  open={endOrderBeginOpen}
                  onOpenChange={(open) => handleOrderOpenChange(open, "end")}
                />
              </div>
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                loading={exportload}
                onClick={handleExportChange}
                style={{ marginLeft: 10 }}
                disabled={!getJurisdiction(1105)}
                title={getJurisdiction(1105) ? "" : "暂无操作权限！"}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBox = Form.create({})(SearchBox);
export default SearchBox;
