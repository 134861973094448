import React, { 
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef
} from 'react';
import { Map, Marker, InfoWindow } from "react-amap";
const amapkey = "4792ad0f37a527c1535e83599762d894";

const Index = forwardRef((props,ref) => {
    const [info,setInfo] = useState({
        position: [0,0],
        time: '',
        address: ''
    })

    useEffect(() => {
        
    },[]);

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);
    var init = (data)=>{
        setInfo(data)
    }
    return (<>
        <Map amapkey={amapkey} zoom={14} center={info.position} mapStyle="amap://styles/dark">
            <Marker position={info.position} />
            <InfoWindow
                visible={true}
                position={info.position}
                offset={[0, -40]}
            >
                <div style={{color: 'black'}}>
                    <p>最后定位时间：{info.time}</p>
                    {
                        info.address ? 
                        <p>最后定位地址：{info.address}</p>
                        :
                        null
                    }
                </div>
            </InfoWindow>
        </Map>
    </>)
})

export default Index