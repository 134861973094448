import React,{useState,useEffect,forwardRef,useImperativeHandle} from "react";
import { Modal,message,Spin } from "antd";
import { 
    _detailsComplain
} from "@/statics/js/api.js";
import './index.less'

const Index = forwardRef((props,ref)=>{
    const [visible, setVisible] = useState(false);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const open = async ({id})=>{
        setVisible(true)
        setList([])
        getDetail(id)
    }
    useImperativeHandle(ref, () => ({
        open
    }),[
        open
    ]);
    const getDetail = async (id) => {
        const params = {
          id,
        };
        setLoading(true)
        const res = await _detailsComplain(params);
        setLoading(false)
        const {code,data=[],message:msg} = res.data || {}
        if(code !== 200){
          message.error(msg);
          return
        }
        setList(data || [])
    }
    return (
      <Modal
        title="查看处理历史"
        visible={visible}
        onOk={()=>setVisible(false)}
        onCancel={()=>setVisible(false)}
      >
        <Spin spinning={loading}>
            {
                list.length ? 
                list.map((item,index)=>
                (
                    <div className="history_wrap" key={index}>
                        <div className="sheet_row">
                            <label>申诉工单：</label>
                            <span>{item.orderSn}</span>
                        </div>
                        <div className="sheet_row">
                            <label>工单创建时间：</label>
                            <span>{item.addTime}</span>
                        </div>
                        <div className="sheet_row">
                            <label>反馈类目：</label>
                            <span>{item.statusName}</span>
                        </div>
                        <div className="sheet_row">
                            <label>反馈内容：</label>
                            <span>{item.statusText}</span>
                        </div>
                    </div>
                ))
                :
                <div className="history_empty">暂无数据</div>
            }
        </Spin>
      </Modal>
    );
})

export default Index;