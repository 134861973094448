import React from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import useList from "@/hooks/list";
import { _batchPushBattery } from "@/statics/js/api.js";
import { Form, Modal, Select, Row, Col, message, Table } from "antd";
const { Option } = Select;

var PushModal = forwardRef((props, ref) => {
  const columns = [
    {
      title: "设备二维码",
      dataIndex: "imei",
    },
    {
      title: "设备型号",
      dataIndex: "modelName",
    },
    {
      title: "所属站点",
      dataIndex: "sname",
    },
  ];
  const { form, done } = props;
  const { getFieldDecorator } = form;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [query, setQuery] = useState({
    fid: "",
  });
  const fid = localStorage.getItem("fid");
  const userType = localStorage.getItem("type");
  const {
    franchiseeList,
    getFranchiseeList,
    getSiteList,
    siteList,
    setSiteList,
  } = useList();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  const handleFranchiseeChange = async (value) => {
    setSiteList([]);
    form.setFieldsValue({ sid: "" });
    if (value) {
      getSiteList(value);
    }
  };

  const open = async ({ selectedData }) => {
    setVisible(true);
    setList([...selectedData]);
    form.resetFields();
    setQuery({
      fid: "",
      sid: ""
    });
    if (userType === "0") {
      getFranchiseeList();
    } else {
      getSiteList(fid);
    }
  };
  const close = async () => {
    setVisible(false);
  };
  // 提交
  const handleOk = async () => {
    form.validateFields(async (err, values) => {
      if (err) return;
      const params = { 
        ...values,
        ids: list.map(item=>item.id)
      };
      setConfirmLoading(true);
      const res = await _batchPushBattery(params);
      setConfirmLoading(false);
      const { code, message: msg } = res.data || {};
      if (code != 200) {
        message.warning(msg);
        return;
      }
      message.success("操作成功");
      done();
      close();
    });
  };
  return (
    <div>
      <Modal
        title="投放电池"
        visible={visible}
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Row>
            <Col span={24}>
                <Form.Item label="代理商">
                    {getFieldDecorator("fid", {
                        initialValue: query.fid,
                        rules: [{ required: true, message: "请选择代理商！" }],
                    })(
                    <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        onChange={handleFranchiseeChange}
                        filterOption={(input, option) => {
                        return (
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                    >
                        {franchiseeList.map((item, index) => {
                            return (
                                <Option key={index} value={item.fid}>
                                    {item.fname}
                                </Option>
                            );
                        })}
                    </Select>
                    )}
                </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="站点">
                {getFieldDecorator("sid", {
                  initialValue: query.sid,
                  rules: [{ required: true, message: "请选择站点！" }],
                })(
                  <Select
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {siteList.map((item, index) => {
                      return (
                        <Option key={index} value={item.sid}>
                          {item.sname}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <h3>投放数量 共{list.length}组</h3>
        <Table
          rowKey="id"
          bordered
          columns={columns}
          scroll={{ x: 1000, y: 500 }}
          dataSource={list}
        />
      </Modal>
    </div>
  );
});

PushModal = Form.create({})(PushModal);
export default PushModal;
