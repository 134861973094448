import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Form,
  Modal,
  Input,
  Table,
  Radio,
  Button,
  Select,
  message,
  DatePicker,
} from 'antd'
import {
  _packageList,
  _franchiseeList,
  _packageBoxList,
  _getBatteryStatus,
  _exportTransferLog,
  _packageTransferList,
  _packageOrderUpdateTransferStatus,
} from '@/statics/js/api'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import ApprovalModal from './components/approvalModal/index'
import moment from 'moment'

const Option = Select.Option
const RangePicker = DatePicker.RangePicker
class FeeSplitting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packageTransferList: [], //套餐即时分润列表
      franchiseeList: [], //代理商列表
      packageList: [], //套餐列表
      selectedRowKeys: [],
      handleTime: Date.now(),
      loading: false,
    }
    this.approvalRef = createRef()
    this.columns = [
      {
        title: '序号',
        dataIndex: 'no',
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: '审核原因',
        dataIndex: 'checkReason',
      },
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点',
        dataIndex: 'sname',
      },

      {
        title: '套餐名',
        dataIndex: 'pname',
      },
      {
        title: '套餐金额',
        align: 'right',
        width: 90,
        dataIndex: 'pamount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '分润类型',
        width: 90,
        dataIndex: 'transferTypeName',
      },
      {
        title: '维修单号',
        dataIndex: 'repairNo',
        render: (text) => (
          <Link target="_blank" to={'/bikeRepairDetailList?repairNo=' + text}>
            {text}
          </Link>
        ),
      },
      {
        title: '维修站点',
        dataIndex: 'rname',
      },
      {
        title: '车辆二维码',
        align: 'right',
        width: 180,
        dataIndex: 'vin',
      },
      {
        title: '车辆型号',
        align: 'right',
        width: 120,
        dataIndex: 'modelName',
      },
      {
        title: '电池',
        width: 120,
        dataIndex: 'batVinCode',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '电池型号',
        width: 120,
        dataIndex: 'batModelName',
        render: (text) =>
          text ? <span style={{ whiteSpace: 'pre-line' }}>{text}</span> : ' 无',
      },
      {
        title: '订单编号',
        align: 'right',
        width: 180,
        dataIndex: 'orderSn',
      },
      {
        title: '订单金额',
        align: 'right',
        width: 90,
        dataIndex: 'orderAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '支付金额',
        align: 'right',
        width: 90,
        dataIndex: 'payAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '待转账金额',
        align: 'right',
        width: 105,
        dataIndex: 'transferAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '转账金额',
        align: 'right',
        width: 90,
        dataIndex: 'realAmount',
        render: (text) => text && text.toFixed(2),
      },
      {
        title: '期数',
        width: 80,
        dataIndex: 'payNumber',
        render: (text) => text && text + '期',
      },
      {
        title: '创建时间',
        width: 120,
        dataIndex: 'addTime',
        render: (text) => text && moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '转账时间',
        width: 120,
        dataIndex: 'transferTime',
        render: (text) => text && moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '转账账号',
        align: 'right',
        width: 180,
        dataIndex: 'transferAccount',
      },
      {
        title: '转账流水号',
        align: 'right',
        width: 180,
        dataIndex: 'tradeNo',
      },
      {
        title: '支付流水号',
        align: 'right',
        width: 160,
        dataIndex: 'payTradeNo',
      },
      {
        title: '支付方式',
        align: 'right',
        width: 90,
        dataIndex: 'payTypeName',
      },
      {
        title: '备注',
        width: 150,
        dataIndex: 'remark',
      },
      {
        title: '转账结果',
        width: 90,
        dataIndex: 'status',
        render: (text) => (text === 0 ? '未转' : text === 1 ? '成功' : null),
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        key: 'Action',
        fixed: 'right',
        render: (text) =>
          text.checkStatus === 1 ? (
            <span>
              待审核
              <br />
              <Button
                type="link"
                disabled={!getJurisdiction(1079)}
                title={getJurisdiction(1079) ? '' : '暂无操作权限！'}
                onClick={this.handleReviewChange.bind(
                  this,
                  text.id,
                  text.checkReason
                )}
              >
                去审核
              </Button>
            </span>
          ) : text.checkStatus === 2 ? (
            '已审核'
          ) : text.checkStatus === 3 ? (
            '已拒绝'
          ) : null,
      },
    ]
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem('type') === '0' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator('fid')(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        )
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="分润类型">
              {getFieldDecorator('transferTypes')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>套餐分润</Option>
                  <Option value={2}>免赔服务分润</Option>
                  <Option value="3,7">维修分润</Option>
                  <Option value="4,12,13">分期付分润</Option>
                  <Option value={5}>逾期租金违约金分润</Option>
                  <Option value={6}>提前退租违约金分润</Option>
                  {/* <Option value={7}>挂起维修费分润</Option> */}
                  <Option value={8}>停车费分润</Option>
                  <Option value={9}>拖车费分润</Option>
                  <Option value={10}>罚单费分润</Option>
                  <Option value={11}>资产丢失费分润</Option>
                  {/* <Option value={12}>挂起日还款分润</Option> */}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="套餐">
              {getFieldDecorator('pid')(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.packageList.length &&
                    this.state.packageList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.pname}
                        </Option>
                      )
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator('orderSn')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="流水号">
              {getFieldDecorator('payTradeNo')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="维修单号">
              {getFieldDecorator('repairNo')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="站点/维修站点">
              {getFieldDecorator('sname')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="时间周期">
              {getFieldDecorator('transferTime')(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="转账结果">
              {getFieldDecorator('status')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={0}>未转</Option>
                  <Option value={1}>成功</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="审核状态">
              {getFieldDecorator('checkStatus')(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value={1}>待审核</Option>
                  <Option value={2}>已审核 </Option>
                  <Option value={3}>已拒绝 </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(817)}
                title={getJurisdiction(817) ? '' : '暂无操作权限！'}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  render() {
    const {
      curPage,
      pageSize,
      totalCount,
      selectedRowKeys,
      packageTransferList,
      queryFormValues,
      loading,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.checkStatus !== 1,
      }),
    }
    const hasSelected = selectedRowKeys.length > 0
    return (
      <div>
        {!!getJurisdiction(818) ? (
          <div className="FeeSplitting">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                onClick={this.showBatchCheckModal.bind(this)}
                disabled={!getJurisdiction(1111) || !hasSelected}
                type="primary"
                ghost
              >
                批量审核
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                rowSelection={rowSelection}
                bordered
                columns={this.columns}
                dataSource={packageTransferList}
                scroll={{ x: 3200, y: 500 }}
                loading={loading}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <ApprovalModal
              ref={this.approvalRef}
              done={() => {
                this.getPackageTransferList(curPage, pageSize, queryFormValues)
                this.initPushFormModal()
              }}
            />
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }
  componentDidMount() {
    this.getFranchiseeList()
    this.getPackageList()
    this.getPackageTransferList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    )
  }
  // 批量审核
  async showBatchCheckModal() {
    this.approvalRef.current.open({ ids: this.state.selectedRowKeys })
  }
  //已选中数据
  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  //Modal取消事件（投放）
  initPushFormModal() {
    this.props.form && this.props.form.resetFields()
    this.setState({
      selectedRowKeys: [],
    })
  }
  //获取套餐即时分润列表
  async getPackageTransferList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    }
    let currentTime = Date.now()
    this.setState({
      loading: true,
      handleTime: currentTime,
    })
    const res = await _packageTransferList(params)
    this.setState({
      loading: false,
    })
    if (currentTime != this.state.handleTime) {
      return
    }
    if (res.data.code === 200) {
      if (!res.data.data.rows.length) {
        message.warning('暂无数据！')
      }
      this.setState({
        curPage: curPage,
        totalCount: res.data.data.totalCount,
        packageTransferList: res.data.data.rows,
      })
    }
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageTransferList(curPage, pageSize, this.state.queryFormValues)
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    }
    _franchiseeList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          franchiseeList: res.data.data.rows,
        })
      }
    })
  }

  //获取套餐列表
  getPackageList() {
    let params = {
      curPage: 1,
      pageSize: 9999,
    }
    if (localStorage.getItem('type') === '0') {
      _packageBoxList(params).then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            packageList: res.data.data.rows,
          })
        }
      })
    } else {
      params['fid'] = localStorage.getItem('fid')
      _packageList(params).then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning('暂无数据！')
          }
          this.setState({
            packageList: res.data.data.rows,
          })
        }
      })
    }
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.transferTime && values.transferTime.length) {
        values.transferStart = moment(values.transferTime[0]).format(
          'YYYY-MM-DD'
        )
        values.transferEnd = moment(values.transferTime[1]).format('YYYY-MM-DD')
      }
      delete values.transferTime
      this.setState({
        queryFormValues: values,
      })
      this.getPackageTransferList(1, this.state.pageSize, values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ''
        ) {
          delete values[key]
        }
      }
      if (values.transferTime && values.transferTime.length) {
        values.transferStart = moment(values.transferTime[0]).format(
          'YYYY-MM-DD'
        )
        values.transferEnd = moment(values.transferTime[1]).format('YYYY-MM-DD')
      }
      delete values.transferTime
      let params = {
        ...values,
      }
      _exportTransferLog(params).then((res) => {
        if (res.data.code === 200) {
          this.setState({
            exportload: false,
          })
          window.open(`${res.data.data}`, '_blank').focus()
        } else {
          message.warning(res.data.message)
          this.setState({
            exportload: false,
          })
        }
      })
    })
  }

  //审核交易
  handleReviewChange(id, checkReason) {
    let self = this
    let status
    Modal.confirm({
      title: '是否审核通过该笔交易？',
      content: (
        <div style={{ marginTop: 30 }}>
          <Form.Item label="审核原因">{checkReason}</Form.Item>
          <Form.Item label="审核">
            <Radio.Group
              onChange={(e) => {
                status = e.target.value
              }}
            >
              <Radio value={2}>通过</Radio>
              <Radio value={4}>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = { id, status }
        _packageOrderUpdateTransferStatus(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success('操作成功')
              self.getPackageTransferList(
                self.state.curPage,
                self.state.pageSize,
                self.state.queryFormValues
              )
            }
          })
          .catch((err) => {
            message.warning('操作失败')
          })
      },
    })
  }
}

FeeSplitting = Form.create({})(FeeSplitting)
export { FeeSplitting }
