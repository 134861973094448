import React from "react";
import { Table } from "antd";
import SearchBox from "./components/searchBox";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _userRechargeList } from "@/statics/js/api.js";

const Index = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "充值流水号",
      dataIndex: "tradeNo",
    },
    {
      title: "用户名",
      dataIndex: "userName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "充值金额",
      dataIndex: "changeAmount",
    },

    {
      title: "充值时间",
      dataIndex: "addTime",
      width: 135,
    },
  ];
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handlePageChange,
  } = useTable({
    action: _userRechargeList,
    response: async (res) => {
      return res.data.data;
    },
  });
  return !getJurisdiction(1020) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 1200, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
    </div>
  );
};

export default Index;
