import React, { 
    useEffect,
    useState
  } from 'react';
  import './index.less'
  import useList from '@/hooks/list'
  import {
    _unbindUser,
    _deviceBindInfo,
  } from "@/statics/js/api.js"
  import {
    Form,
    Modal,
    Select,
    Row,
    Col,
    message,
    Divider,
  } from "antd";
  const { Option } = Select;

  var BackUser = (props)=> {
    const {visible,close,form,id,flag,done} = props
    const {getFieldDecorator} = form
    const [confirmLoading,setConfirmLoading] = useState(false)
    const [initialValues,setInitialValue] = useState({
      sid: '',
    })
    const [info,setInfo] = useState({})

    const {
      kaSiteList,
      getKaSiteList,
    } = useList()

    useEffect(() => {
      if(visible){
        form.resetFields()
        setInfo({})
        setInitialValue({
            sid: '',
        })
        getDetail()
      }
    },[visible]);
    
    const getDetail = async ()=>{
      const params = {
        bikeId: id,
        flag
      }
      const res = await _deviceBindInfo(params)
      const {code,data,message:msg} = res.data || {}
      if(code != 200){
        message.warning(msg);
        return
      }
      setInfo(data || {})
      getKaSiteList(data.fid)
    }
    // 关闭
    const handleCancel = async ()=>{
        close()
    }
    // 提交
    const handleOk = async ()=>{
      form.validateFields((err, values) => {
        if(err) return
        const params = {
            ...values,
            id: info.id,
        }
        handleSave(params)
      })
    }
    // 保存
    const handleSave = async (params)=>{
      setConfirmLoading(true)
      const res = await _unbindUser(params)
      setConfirmLoading(false)
      const {code,message:msg} = res.data || {}
      if(code != 200){
        message.warning(msg);
        return
      }
      message.success("操作成功");
      done()
      close()
    }

    return (<div>
        <Modal 
          title="退还设备" 
          visible={visible} 
          width={700}
          onOk={handleOk} 
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
        >
            <Form>
              <Row>
                <Col span={24}>
                  <Form.Item label="退还站点">
                    {
                      getFieldDecorator("sid",{
                        initialValue: initialValues.sid,
                        rules: [{ required: true, message: "请选择退还站点" }],
                      })(
                        <Select
                          placeholder="请选择"
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {kaSiteList.length &&
                            kaSiteList.map((item, index) => {
                              return (
                                <Option key={item.sid} value={item.sid}>
                                  {item.sname}
                                </Option>
                              );
                            })}
                        </Select>
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Divider />
            <Row>
              <Col span={12}>
                所属站点：{info.sname}
              </Col>
              <Col span={12}>
                设备编号：{info.deviceNo}
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={12}>
                二维码：{info.qcodeId}
              </Col>
              <Col span={12}>
                GPS：{info.ccodeId}
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                绑定照片：
                {
                  info.imgList && 
                  info.imgList.map(item=>(
                    <img className="pic" key={item} src={item} alt="" />
                  ))
                }
              </Col>
            </Row>
        </Modal>
    </div>)
  }
  
  BackUser = Form.create({})(BackUser);
  export default BackUser