import React from 'react';
import { 
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  createContext,
  useContext,
  useRef,
} from 'react';
import "./index.less"
import useList from '@/hooks/list'
import {
  _imageUpload,
  _addAccountBalance,
  _getAccountPayCode,
  _getAccountInfo,
  _balanceDetail,
} from "@/statics/js/api.js"
import moment from "moment";
import {
  Form,
  Input,
  Modal,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  DatePicker,
  Radio,
  Upload,
  Icon,
} from "antd";
const { Option } = Select;

const cxt = createContext({
  franchiseeList: [],
  id: '',
  type: '',
})

// 扫码支付
var ScanPay = Form.create({})(forwardRef((props,ref)=>{
  const {form} = props
  const {getFieldDecorator} = form
  const {franchiseeList} = useContext(cxt)
  const userType = localStorage.getItem('type')
  
  const [query] = useState({
    fid: '',
    amount: '',
    remark: '',
  })

  useEffect(()=>{
    if(userType == 9 || userType == 10){
      const fid = localStorage.getItem('fid')
      form.setFieldsValue({
        fid: Number(fid),
      })
    }
  },[])

  return (
    <>
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item label="付款公司">
              {
                getFieldDecorator("fid",{
                  initialValue: query.fid,
                  rules: [{ required: true, message: "请选择公司名称" }],
                })(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  disabled={userType == 9 || userType == 10}
                  filterOption={(input, option) => {
                      return (
                      option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                  }}
                >
                  {
                    franchiseeList.map((item, index) => {
                      return (
                          <Option key={index} value={item.fid}>
                              {item.fname}
                          </Option>
                      );
                    })
                  }
                </Select>)
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="充值金额">
              {
                getFieldDecorator("amount",{
                  initialValue: query.amount,
                  rules: [{ required: true, message: "请输入金额" }],
                })(
                    <InputNumber min={0} precision={2}/>
                  )
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注">
              {
                getFieldDecorator("remark",{
                  initialValue: query.remark,
                })(
                    <Input />
                  )
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}))

// 银行卡支付
var BankPay = Form.create({})(forwardRef((props,ref)=>{
  const {form} = props
  const {getFieldDecorator} = form
  const {franchiseeList,type,id} = useContext(cxt)
  const [accountInfo,setAccountInfo] = useState({
    bankName: '',
    cardNo: '',
    companyName: '',
  })
  const [query,setQuery] = useState({
    fid: '',
    transferAmount: '',
    transferTime: '',
    tradeNo: '',
    img: [],
    remark: '',
  })

  const [record,setRecord] = useState({
    fname: '',
    changeAmount: '',
    changeTime: '',
    tradeNo: '',
    imgList: [],
    remark: '',
  })

  useEffect(()=>{
    getAccountInfo()
    getBalanceDetail()
  },[])

  // 收款方
  const getAccountInfo = async ()=> {
    const res = await _getAccountInfo();
    const {code,data={},message:msg} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    setAccountInfo(data)
  }
  // 充值详情
  const getBalanceDetail = async ()=> {
    const params = {
      id
    }
    const res = await _balanceDetail(params);
    const {code,data={},message:msg} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    setRecord(data)
  }
  const handleUploadChange = ({ file, fileList })=>{
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    setQuery(val=>({...val,img:fileList}));
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  return (
    <>
      <Form>
        <Row>
          <div className="info_wrap">
            <Row>
              <Col span={12}>
                收款公司：{accountInfo.companyName}
              </Col>
              <Col span={12}>
                收款银行账号：{accountInfo.cardNo}
              </Col>
              <br /><br />
              <Col span={12}>
                收款银行：{accountInfo.bankName}
              </Col>
            </Row>
          </div>
          <Col span={24}>
            <Form.Item label="付款公司">
              {
                type == 1 ? <span>{record.fname}</span> : 
                getFieldDecorator("fid",{
                  initialValue: query.fid,
                  rules: [{ required: true, message: "请选择公司名称" }],
                })(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) => {
                      return (
                      option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                  }}
                >
                  {
                    franchiseeList.map((item, index) => {
                      return (
                          <Option key={index} value={item.fid}>
                              {item.fname}
                          </Option>
                      );
                    })
                  }
                </Select>)
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="转账金额">
              {
                type == 1 ? <span>{record.changeAmount}</span> : 
                getFieldDecorator("transferAmount",{
                  initialValue: query.transferAmount,
                  rules: [{ required: true, message: "请输入转账金额" }],
                })(
                    <InputNumber min={0} precision={2}/>
                  )
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="转账时间">
              {
                type == 1 ? <span>{moment(record.changeTime).format("YYYY-MM-DD")}</span> : 
                getFieldDecorator("transferTime", {
                  rules: [
                    { required: true, message: "请选择日期！" },
                  ],
                })(<DatePicker format="YYYY-MM-DD" />)
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="转账流水号">
              {
                type == 1 ? <span>{record.tradeNo}</span> : 
                getFieldDecorator("tradeNo",{
                  initialValue: query.tradeNo,
                  rules: [{ required: true, message: "请输入转账流水号" }],
                })(
                    <Input />
                  )
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="上传图片">
              {
                type == 1 ? <img src={record.imgList && record.imgList[0]} width="102px" height="102px" /> : 
                getFieldDecorator("img",{
                  initialValue: query.img,
                  getValueFromEvent: normFile,
                  rules: [{ required: true, message: "请选择图片" }],
                })(
                  <Upload
                    name="uploadFile"
                    action={_imageUpload}
                    listType="picture-card"
                    accept="image/jpg,image/jpeg,image/png,image/gif"
                    fileList={query.img}
                    onChange={handleUploadChange}
                    data={{
                      scene: "active",
                    }}
                  >
                    {
                      query.img.length < 1 &&
                      <div>
                        <Icon type="plus" style={{ fontSize: "28px" }} />
                        <p>图片上传</p>
                      </div>
                    }
                  </Upload>
                  )
              }
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注">
              {
                type == 1 ? <span>{record.remark}</span> : 
                getFieldDecorator("remark",{
                  initialValue: query.remark,
                })(
                    <Input />
                  )
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}))


// 弹框
var PayModal = forwardRef((props,ref)=> {
  const {done} = props
  const [confirmLoading,setConfirmLoading] = useState(false)
  const [visible,setVisible] = useState(false)
  const [type,setType] = useState(0)
  const [id,setId] = useState('')
  const [qrcodeVisible,setQrcodeVisible] = useState(false)
  const [payType,setPayType] = useState('1')
  const [payInfo,setPayInfo] = useState({
    amount: 0,
    qrcode: '',
  })
  const {
    franchiseeList,
    getFranchiseeList,
  } = useList()
  const bankRef = useRef()
  const scanRef = useRef()
  const userType = localStorage.getItem("type")

  useImperativeHandle(ref, () => ({
    open
  }),[]);
  const handlePayTypeChange = (e)=>{
    let {value} = e.target
    setPayType(value)
  }
  const open = async ({type,id})=>{
    setVisible(true)
    setType(type)
    if(type == 1){
      setPayType('3')
      setId(id)
    }else{
      setPayType('1')
      getFranchiseeList({ftype:1})
    }
  }
  const close = async ()=>{
    setVisible(false)
  }
  // 提交
  const handleOk = async ()=>{
    if(type == 1){
      close()
      return
    }
    const form = payType == 3 ? bankRef.current : scanRef.current
    form.validateFields((err, values) => {
      if(err) return
      if(payType == 3){
        let ids = []
        if (values.img && values.img.constructor === Array) {
          ids = values.img.map(item=>item.response.data.id)
        }
        const params = {
          ...values,
          transferTime: moment(values.transferTime).format("YYYY-MM-DD"),
          img: ids.join()
        }
        bankSave(params)
      }else{
        const params = {
          ...values,
          payType,
        }
        scanSave(params)
      }
    })
  }
  // 银行卡提交
  const bankSave = async (params)=>{
    setConfirmLoading(true)
    const res = await _addAccountBalance(params)
    setConfirmLoading(false)
    const {code,message:msg} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    message.success("操作成功");
    done()
    close()
  }
  // 获取支付码
  const scanSave = async (params)=>{
    setConfirmLoading(true)
    const res = await _getAccountPayCode(params)
    setConfirmLoading(false)
    const {code,message:msg,data} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    setQrcodeVisible(true)
    setPayInfo({
      qrcode: data,
      amount: params.amount,
    })
  }
  
  return (<div>
      <Modal 
        title="支付" 
        visible={visible} 
        width={700}
        onOk={handleOk}
        onCancel={close}
        confirmLoading={confirmLoading}
      >   
        <cxt.Provider value={{franchiseeList,type,id}}>
          {
            visible && 
            <>
              {
                payType == 3 ? 
                <BankPay ref={bankRef} /> 
                : 
                <ScanPay ref={scanRef} />
              }
            </>
          }
        </cxt.Provider>
        {
          type == 1 ? null :
          <Row>
            <Col span={24}>
              <Form.Item label="支付方式">
                <Radio.Group onChange={handlePayTypeChange} value={payType}>
                  <Radio value="1">支付宝扫码</Radio>
                  <Radio value="2">微信扫码</Radio>
                  <Radio value="3" disabled={userType != 0}>银行卡支付</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        }
      </Modal>
      <Modal 
        title="扫码支付" 
        visible={qrcodeVisible} 
        footer={null}
        onCancel={()=>setQrcodeVisible(false)}
      >   
        <div className="scan_wrap">
          <h3>{payType == 1 ? '支付宝' : '微信'}扫一扫支付金额（元）</h3>
          <h2 style={{color: 'red'}}>{payInfo.amount}</h2>
          <img src={payInfo.qrcode} />
        </div>
      </Modal>
  </div>)
})

export default PayModal