import React, { Component, createElement } from "react";
import { Link } from "react-router-dom";
// import {browserHistory} from '@/statics/js/browserHistory';
import { Layout, Menu, Icon, Dropdown, Breadcrumb, message, Badge } from "antd";
import NavigateBar from "@/components/navigateBar";
import {
  _logout,
  _complainMsgSize,
  _complainSize,
  _overdueCount,
} from "@/statics/js/api";
import menus from "./menus";
import { envVersion } from "@/statics/js/config";
import routeConfig from "@/router";
// import cloneDeep from 'lodash-es';
import "./style.less";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

//右侧的下拉menu
const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/basicInfo">基本信息</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/changePwd">修改密码</Link>
    </Menu.Item>
    <Menu.Item style={{ borderTop: "1px #e8e8e8 solid" }}>
      <Link to="" onClick={(e) => handleLogoutChange(e)}>
        退出
      </Link>
    </Menu.Item>
  </Menu>
);

//退出登录
const handleLogoutChange = (e) => {
  e.preventDefault();
  let id = localStorage.getItem("userId");
  let params = { id };
  _logout(params).then((res) => {
    if (res.data.code === 200) {
      message.success("退出成功！");
      localStorage.clear();
      setTimeout(function () {
        window._history.push({ pathname: "/login" });
      }, 1000);
    } else {
      message.warning(res.data.message);
    }
  });
};

class Common extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
      collapsed: false, //是否打开menu
      badgeStatistics: {
        complainMsgSize: 0,
        complainSize: 0,
        overdueCountSize: 0,
      },
    };
  }
  permission(list) {
    const {
      location: { pathname },
    } = this.props;
    let route = {};
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.children && item.children.length) {
        route = this.permission(item.children);
        if (route.path) {
          break;
        }
      }
      if (item.path == pathname) {
        route = item;
        break;
      }
    }
    return route;
  }
  formatChildren() {
    const { children } = this.props;
    let { key, path } = this.permission(menus) || {};
    key = key ? String(key) : "";
    if (localStorage.getItem("sys") === "1") {
      return children;
    }
    if (path && key) {
      let menuList = localStorage.getItem("menuIds");
      menuList = menuList && menuList.split(",");
      if (!menuList?.includes(key)) {
        return <div>暂无操作权限</div>;
      }
    }
    return children;
  }
  formatMenu(list) {
    const { badgeStatistics } = this.state;
    const menuDom = list.map((item, index) => {
      if (item.children && item.children.length) {
        return (
          <SubMenu
            key={item.key}
            code={item.key}
            name="jurisdiction"
            className="classShow"
            title={
              <span>
                {item.icon ? <Icon type={item.icon} /> : null}
                <span>{item.title}</span>
                {item.badgeKey ? (
                  <Badge
                    count={badgeStatistics[item.badgeKey]}
                    style={{ marginLeft: 10 }}
                  />
                ) : null}
              </span>
            }
          >
            {this.formatMenu(item.children)}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item
            name="jurisdiction"
            className="classShow"
            key={item.path}
            code={item.key}
          >
            <Link to={item.path}>
              {item.icon ? <Icon type={item.icon} /> : null}
              {item.title}
              {item.badgeKey ? (
                <Badge
                  count={badgeStatistics[item.badgeKey]}
                  style={{ marginLeft: 10 }}
                />
              ) : null}
            </Link>
          </Menu.Item>
        );
      }
    });
    return menuDom;
  }

  renderBreadcrumbItems() {
    const { location } = this.props;
    const cloneDeepRoutes = JSON.parse(JSON.stringify(routeConfig));
    const routes = [...cloneDeepRoutes.filter((route) => route.path === "/")];

    // 合并 breadcrumbName 字段使用 / 拼接
    function concatBreadcrumbName(routes, name) {
      for (const route of routes) {
        route["breadcrumbName"] =
          name && route["breadcrumbName"]
            ? `${name}/${route["breadcrumbName"]}`
            : route["breadcrumbName"];
        if ("childRoutes" in route) {
          concatBreadcrumbName(route["childRoutes"], route["breadcrumbName"]);
        }
      }
    }
    concatBreadcrumbName([...routes[0].childRoutes], "");

    // 计算routes中与path相同的route
    let breadcrumb = [];
    function findBreadcrumbInRoutes(routes, targetPath) {
      routes.forEach((route) => {
        if (targetPath === route.path) {
          breadcrumb.push(route);
        }
        if (route.childRoutes && route.childRoutes.length > 0) {
          findBreadcrumbInRoutes(route.childRoutes, targetPath);
        }
      });
    }
    findBreadcrumbInRoutes(routes, location.pathname);

    // 通过 route 生成 Breadcrumb
    let extraBreadcrumbItems = [];
    if (breadcrumb && breadcrumb.length > 0) {
      const { breadcrumbName, path } = breadcrumb[0];
      const splitBreadcrumbs = breadcrumbName?.split("/") || [];
      extraBreadcrumbItems = splitBreadcrumbs.map((item, index) => {
        return (
          breadcrumbName &&
          path !== "/" && (
            <Breadcrumb.Item key={path}>
              {path && index === splitBreadcrumbs.length - 1 ? (
                <Link to={path}>{item}</Link>
              ) : (
                <span>{item}</span>
              )}
            </Breadcrumb.Item>
          )
        );
      });
    }
    return [
      <Breadcrumb.Item key="home">
        <Link to="/">首页</Link>
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);
  }

  render() {
    // const routes = routeConfig.filter(route => route.path === '/');
    return (
      <Layout className="Common">
        <Sider
          className="sider_menu_wrap"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
        >
          <div className="logo">
            {localStorage.getItem("userId") === "1722" ? (
              <Link to="/jzBatteryMgt" className="logo-title">
                金租数据
              </Link>
            ) : !!this.state.collapsed ? (
              <img
                className="logo-title"
                style={{ width: 35 }}
                src={require("@/statics/img/login/ymzc_logo.png")}
                alt=""
              />
            ) : (
              <Link to="/" className="logo-title">
                <img
                  style={{ width: 25, marginTop: "-4px", marginRight: "5px" }}
                  src={require("@/statics/img/login/ymzc_logo.png")}
                  alt=""
                />
                云马车服
              </Link>
            )}
          </div>
          <Menu
            forceSubMenuRender={true}
            theme="dark"
            mode="inline"
            openKeys={this.state.openKeys}
            selectedKeys={[this.props.location.pathname]}
            onOpenChange={this.onOpenChange}
          >
            {this.formatMenu(menus)}
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: "#fff", padding: 0, height: "auto" }}>
            <div className="header-top">
              {localStorage.getItem("userId") !== "1722" && (
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={this.toggle}
                />
              )}
              <Dropdown className="header-dropdown" overlay={menu}>
                <Link
                  className="ant-dropdown-link"
                  to=""
                  onClick={(e) => e.preventDefault()}
                >
                  {localStorage.getItem("username")}
                  <Icon type="down" />
                </Link>
              </Dropdown>
              <span className="header-userPermissions">
                {localStorage.getItem("authGroupName")}
              </span>
            </div>
            {localStorage.getItem("userId") !== "1722" && (
              <>
                <div className="header-bottom">
                  {/* <Breadcrumb routes={routes} itemRender={this.itemRender} /> */}
                  <Breadcrumb>{this.renderBreadcrumbItems()}</Breadcrumb>
                </div>
                <NavigateBar />
              </>
            )}
          </Header>
          <Content
            style={{
              padding: "16px",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {this.formatChildren()}
          </Content>
        </Layout>
      </Layout>
    );
  }
  componentWillMount() {
    this.initDefaultOpenMenu();
  }
  componentDidMount() {
    const userType = localStorage.getItem("type");
    const fid = localStorage.getItem("fid")
      ? Number(localStorage.getItem("fid"))
      : "";
    // console.log总开关，上线时请打开下方函数
    if (envVersion === "production") {
      console.log = function () {};
    }
    if (localStorage.getItem("authorization")) {
      this.getMenuList();
      if (
        localStorage.getItem("sys") === "1" ||
        localStorage.getItem("menuIds").includes("1013")
      ) {
        _complainMsgSize({
          fid: userType === "0" ? undefined : fid,
        }).then((res) => {
          if (res.data.code === 200) {
            let { zfbMsg = 0, kfMsg = 0 } = res.data.data || {};
            let complainMsgSize = zfbMsg + kfMsg;
            this.setState({
              badgeStatistics: {
                ...this.state.badgeStatistics,
                complainMsgSize,
              },
            });
          }
        });
      }
      _complainSize().then((res) => {
        if (res.data.code === 200) {
          let complainSize = res.data.data || 0;
          this.setState({
            badgeStatistics: {
              ...this.state.badgeStatistics,
              complainSize,
            },
          });
        }
      });
      if (
        localStorage.getItem("sys") === "1" ||
        localStorage.getItem("menuIds").includes("1104")
      ) {
        _overdueCount().then((res) => {
          if (res.data.code === 200) {
            let overdueCountSize = res.data.data || 0;
            this.setState({
              badgeStatistics: {
                ...this.state.badgeStatistics,
                overdueCountSize,
              },
            });
          }
        });
      }
    } else {
      message.warning("您暂未登录，请先进行登录！");
      const { history: browserHistory } = this.props;
      setTimeout(function () {
        browserHistory.push({ pathname: "/login" });
      }, 2000);
    }
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.toggle();
    }
  }

  initDefaultOpenMenu() {
    const { pathname } = this.props.location;
    let openKeys = [];
    menus.map((item) => {
      if (item.children) {
        if (item.children.some((row) => row.path == pathname)) {
          const key = item.key + "";
          openKeys.push(key);
        } else {
          item.children.map((cell) => {
            if (
              cell.children &&
              cell.children.some((row2) => row2.path == pathname)
            ) {
              const key1 = item.key + "";
              const key2 = cell.key + "";
              openKeys.push(key1);
              openKeys.push(key2);
            }
          });
        }
      }
    });
    this.setState({
      openKeys,
    });
  }
  //将参数转化为面包屑
  itemRender = (route, params, routes, paths) => {
    const { linkElement = "a" } = this.props;
    const last = routes.indexOf(route) === routes.length - 1;
    return last || !route.component ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      createElement(
        linkElement,
        {
          href: paths.join("/") || "/",
          to: paths.join("/") || "/",
        },
        route.breadcrumbName
      )
    );
  };

  //切换操作
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  //SubMenu 展开/关闭的回调 唯一SubMenu展开
  onOpenChange = (openKeys) => {
    let key = openKeys[openKeys.length - 1] || "";
    if (menus.some((item) => item.key == key)) {
      this.setState({
        openKeys: key ? [key] : [],
      });
    } else {
      this.setState({
        openKeys: openKeys,
      });
    }
    this.getMenuList();
  };

  //获取用户menu
  getMenuList() {
    let inputValue = document.getElementsByName("jurisdiction"); //menu列表
    if (localStorage.getItem("sys") === "1") {
      for (let i = 0; i < inputValue.length; i++) {
        inputValue[i].style.display = "block";
      }
    } else {
      let menuList = localStorage.getItem("menuIds"); //权限列表
      menuList = menuList && menuList.split(",");
      if (menuList) {
        for (let i = 0; i < inputValue.length; i++) {
          let code = inputValue[i].getAttribute("code");
          for (let j = 0; j < menuList.length; j++) {
            if (Number(menuList[j]) === Number(code)) {
              inputValue[i].style.display = "block";
            }
          }
        }
      }
    }
  }
}
export default Common;
