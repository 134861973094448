import React, { Component } from "react";
import "./style.less";
import { Button, message } from "antd";

class AppDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: 0, //1为显示提示，0为不显示提示
    };
    this.getAppVersion = this.getAppVersion.bind(this);
  }

  render() {
    return (
      <div className="AppDownload">
        <div className="appDownload_btn_container">
          <img
            alt=""
            src={require("@/statics/img/download/download.jpg")}
          />
          <Button
            type="primary"
            className="appDownload_btn"
            onClick={this.handleClickDownloadBtnChange.bind(this)}
          >
            下载
          </Button>
        </div>
        <div
          className={
            this.state.hide === 0
              ? "appDownload_hint_container1"
              : this.state.hide === 1
              ? "appDownload_hint_container"
              : null
          }
        >
          <img
            src={require("@/statics/img/download/download_hint.png")}
            alt=""
            className="appDownload_hint_img"
          />
        </div>
      </div>
    );
  }

  componentDidMount() {}

  isAlipayOrWechat() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.match(/Alipay/i) == "alipay") {
      // return "alipay";
      alert("支付宝浏觉器");
    } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
      // return "wechat";
      alert("微信浏觉器");
    } else {
      // return "";
      alert("浏觉器");
    }
  }

  handleClickDownloadBtnChange() {
    let ua = navigator.userAgent.toLowerCase();
    //Android终端
    let isAndroid = ua.indexOf("android") > -1 || ua.indexOf("adr") > -1;
    if (isAndroid) {
      if (ua.match(/MicroMessenger/i) === "micromessenger") {
        this.setState({
          hide: 1,
        });
      } else {
        if (ua.match(/Alipay/i) === "alipay") {
          this.setState({
            hide: 1,
          });
        } else {
          if (/\s+QQ/.test(window.navigator.userAgent)) {
            this.setState({
              hide: 1,
            });
          } else {
            this.getAppVersion(this);
          }
        }
      }
    } else {
      if (ua.match(/MicroMessenger/i) === "micromessenger") {
        this.setState({
          hide: 1,
        });
      } else {
        if (ua.match(/Alipay/i) === "alipay") {
          this.setState({
            hide: 1,
          });
        } else {
          if (/\s+QQ/.test(window.navigator.userAgent)) {
            this.setState({
              hide: 1,
            });
          } else {
            // window.open(
            //   "https://apps.apple.com/us/app/%E7%A0%82%E6%B5%86%E4%BA%91%E7%AE%A1%E5%AE%B6/id1468874874?l=zh&ls=1"
            // );
            message.warning("IOS版本还未完成开发，敬请期待！");
          }
        }
      }
    }
  }

  //获取安卓APP
  getAppVersion() {
    window.location.href =
      "https://admin.zuche.miway.com/uploads/app/ymzc-1.1.6.apk";
    //   let time = new Date().valueOf();
    //   let params = {
    //     timeStamp: time,
    //   };
    //   axios
    //     .post(AXIOS_URL + "/scm/api/scm/init/appVersion", params, {
    //       headers: { "app-device": "android" },
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //       window.location.href = res.data.data.downUrl;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  }
}

export default AppDownload;
