import React, { Component } from "react";
import { Input, Button, Form, Upload, Icon, message } from "antd";
import { _saveAccountInfo, _imageUpload } from "@/statics/js/api";
import "./style.less";
const { TextArea } = Input;
class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [], //上传格式
      initialValues: {}, //用户信息
    };
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const { initialValues, fileList } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="我的角色">
          {getFieldDecorator("type", {
            initialValue: initialValues.authGroupName,
          })(<Input disabled />)}
          <span>当前角色不可更改为其它角色</span>
        </Form.Item>
        <Form.Item label="手机号码">
          {getFieldDecorator("phone", {
            initialValue: initialValues.phone,
          })(<Input disabled />)}
          <span>不可修改。一般用于后台登入名</span>
        </Form.Item>
        <Form.Item label="用户名">
          {getFieldDecorator("username", {
            initialValue: initialValues.username,
          })(<Input allowClear placeholder="请输入" />)}
        </Form.Item>
        <Form.Item label="头像">
          {getFieldDecorator("avatar", {
            initialValue: initialValues.avatar || undefined,
            getValueFromEvent: this.normFile,
          })(
            <Upload
              name="uploadFile"
              action={_imageUpload}
              listType="picture-card"
              fileList={fileList}
              accept="image/jpg,image/jpeg,image/png,image/gif"
              onChange={this.handleChange.bind(this)}
              data={{
                scene: "active",
              }}
            >
              {fileList.length >= 1 ? null : (
                <div>
                  <Icon type="plus" style={{ fontSize: "28px" }} />
                  <p>头像上传</p>
                </div>
              )}
            </Upload>
          )}
        </Form.Item>
        <Form.Item label="备注">
          {getFieldDecorator("remark", {
            initialValue: initialValues.remark,
          })(
            <TextArea
              placeholder="请输入"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button className="formBtn" type="primary" htmlType="submit">
            确定修改
          </Button>
        </Form.Item>
      </Form>
    );
  }

  render() {
    return (
      <div className="BasicInfo">
        <h1 className="title">设置我的资料</h1>
        {this.renderForm()}
      </div>
    );
  }

  componentDidMount() {
    this.getAccountDetail();
  }

  //获取用户信息
  getAccountDetail() {
    let initialValues = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({
      initialValues,
    });
    initialValues.avatar &&
      this.updateFileList([
        {
          uid: -1,
          name: "img",
          status: "done",
          url: initialValues.avatar,
        },
      ]);
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.avatar && values.avatar.length === 1) {
        values.avatar = values.avatar[0].response.data.url;
      } else {
        values.avatar = "";
      }
      delete values.type;
      let params = {
        id: this.state.initialValues.id,
        ...values,
      };
      _saveAccountInfo(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功！");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //updata end
}

BasicInfo = Form.create({})(BasicInfo);
export { BasicInfo };
