import React, { Component, createRef } from 'react'
import {
  Col,
  Row,
  Form,
  Input,
  Radio,
  Modal,
  Select,
  Button,
  message,
  DatePicker,
} from 'antd'
import {
  _applyList,
  _getPayCode,
  _payment,
  _exportApplyList,
} from '@/statics/js/api'
import CommonTable from '@/components/commonTable'
import { getJurisdiction } from '@/statics/js/jurisdiction'
import FranchiseeSelect from '@/components/franchiseeSelect'
import moment from 'moment'

const RangePicker = DatePicker.RangePicker
const { Option } = Select
class OrderApplyManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.tableRef = createRef()
    this.columns = [
      {
        title: '代理商',
        dataIndex: 'fname',
      },
      {
        title: '站点',
        dataIndex: 'sname',
      },
      {
        title: '订单号',
        align: 'right',
        dataIndex: 'orderSn',
      },
      {
        title: '交易类型',
        dataIndex: 'tradeTypeName',
      },
      {
        title: '用户信息',
        width: 130,
        dataIndex: '',
        render: (text) => text.userName + text.mobile,
      },
      {
        title: '代付金额',
        width: 130,
        dataIndex: 'payAmount',
      },
      {
        title: '申请时间',
        width: 120,
        dataIndex: 'addTimeStr',
      },
      {
        title: '支付状态',
        dataIndex: 'payStatus',
        render: (text) =>
          text === 0
            ? '申请中'
            : text === 1
            ? '已付款'
            : text === 2
            ? '已拒绝'
            : '已取消',
      },
      {
        title: '支付时间',
        width: 120,
        dataIndex: 'payTimeStr',
      },
      {
        title: '流水号',
        align: 'right',
        dataIndex: 'tradeNo',
      },
      {
        title: '备注',
        align: 'right',
        dataIndex: 'remark',
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        key: 'Action',
        fixed: 'right',
        render: (text) =>
          text.payStatus === 0 ? (
            <span>
              <Button
                type="link"
                disabled={!getJurisdiction(902)}
                title={getJurisdiction(902) ? '' : '暂无操作权限！'}
                onClick={this.modalViewQrcode.bind(this, { ...text }, 2)}
              >
                微信支付
              </Button>
              <Button
                type="link"
                disabled={!getJurisdiction(902)}
                title={getJurisdiction(902) ? '' : '暂无操作权限！'}
                onClick={this.modalViewQrcode.bind(this, { ...text }, 1)}
              >
                支付宝支付
              </Button>
            </span>
          ) : (
            '暂无'
          ),
      },
    ]
    localStorage.getItem('type') !== '0' && this.columns.splice(1, 1)
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          {localStorage.getItem('type') === '0' && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator('fid')(<FranchiseeSelect />)}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号/姓名">
              {getFieldDecorator('keyword')(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="申请时间">
              {getFieldDecorator('time')(<RangePicker format="YYYY-MM-DD" />)}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代付类型">
              {getFieldDecorator('tradeType')(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Option value="">全部</Option>
                  <Option value={1}>购买租车套餐</Option>
                  <Option value={4}>续租租车套餐</Option>
                  <Option value={7}>免赔服务</Option>
                  <Option value={9}>逾期租金违约金支付</Option>
                  <Option value={10}>违约金支付</Option>
                  <Option value={5}>设备检修费用</Option>
                  <Option value={3}>订单代扣费</Option>
                  <Option value={6}>申请退租费用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="支付类型">
              {getFieldDecorator('payStatus')(
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: '100%' }}
                >
                  <Option value="">全部</Option>
                  <Option value={0}>申请中</Option>
                  <Option value={1}>已付款</Option>
                  <Option value={2}>已拒绝</Option>
                  <Option value={3}>已取消</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
                disabled={!getJurisdiction(909)}
                title={getJurisdiction(909) ? '' : '暂无操作权限！'}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  render() {
    return (
      <div>
        {!!getJurisdiction(901) ? (
          <div className="OrderApplyManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <CommonTable
                ref={this.tableRef}
                action={_applyList}
                showSort={true}
                options={{
                  columns: this.columns,
                  rowKey: (_, index) => String(index),
                  scroll: { x: 1500, y: 500 },
                }}
              />
            </div>
          </div>
        ) : (
          '暂无操作权限！'
        )}
      </div>
    )
  }

  //modal查看
  modalViewQrcode({ id }, payType) {
    let params = { id, payType }
    _getPayCode(params).then((res) => {
      if (res.data.code === 200) {
        Modal.info({
          title: '代付二维码',
          content: <img src={res.data.data} style={{ width: '100%' }} alt="" />,
        })
      }
    })
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      if (values.time && values.time.length) {
        values.startTime = moment(values.time[0]).format('YYYY-MM-DD')
        values.endTime = moment(values.time[1]).format('YYYY-MM-DD')
      }
      delete values.time
      this.tableRef.current.handleSearch(values)
    })
  }

  // 导出
  handleExportChange = (e) => {
    e.preventDefault()
    this.setState({
      exportload: true,
    })
    const { form } = this.props
    form.validateFields((err, values) => {
      if (err) return
      if (values.time && values.time.length) {
        values.startTime = moment(values.time[0]).format('YYYY-MM-DD')
        values.endTime = moment(values.time[1]).format('YYYY-MM-DD')
      }
      delete values.time
      let params = {
        ...values,
      }
      _exportApplyList(params).then((res) => {
        if (res.data.code === 200) {
          this.setState({
            exportload: false,
          })
          window.open(`${res.data.data}`, '_blank').focus()
        } else {
          message.warning(res.data.message)
          this.setState({
            exportload: false,
          })
        }
      })
    })
  }
}

OrderApplyManagement = Form.create({})(OrderApplyManagement)
export { OrderApplyManagement }
