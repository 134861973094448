import React,{useState,useEffect,forwardRef,useImperativeHandle} from "react";
import { Table,Button,Form,Modal,message,Input } from "antd";
import { 
    _batchGetPackage,
    _saveSitePackage
} from "@/statics/js/api.js";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";

const Index = Form.create({})(forwardRef((props,ref)=>{
    const {
        onFinish = () => {},
        form,
    } = props;
    const { getFieldDecorator, getFieldsValue,getFieldValue, setFieldsValue } = form;
    const [visible, setVisible] = useState(false);
    const [ids, setIds] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 100,
        render: (text, record, index) => index + 1,
      },
      {
        title: "套餐名称",
        dataIndex: "pname",
      },
      {
        title: "套餐类型",
        dataIndex: "ptypeName",
      },
      {
        title: "合同类型",
        dataIndex: "contractTypeName",
      },
      {
        title: "套餐金额",
        dataIndex: "money",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <>
            <Button type="link" onClick={() => handleDelete(text)}>
              移除
            </Button>
          </>
        ),
      },
    ];
    const open = async ({list=[]})=>{
        setVisible(true)
        setIds(list);
        setPackageList([]);
        setFieldsValue({
            fid: "",
            sids: [],
        });
        setConfirmLoading(false);
        getPackageList(list);
    }
    useImperativeHandle(ref, () => ({
        open
    }),[
        open
    ]);
    const handleDelete = async ({ id }) => {
      if (packageList.length < 2) {
        message.error("套餐列表不能为空");
        return;
      }
      const pids = ids.filter((item) => item != id);
      setIds(pids);
      getPackageList(pids);
    };
    const getPackageList = async (pids) => {
      const params = {
        ids: pids,
      };
      setLoading(true);
      const res = await _batchGetPackage(params);
      setLoading(false);
      const { data = [] } = res.data || {};
      setPackageList(data || []);
    };
    const handleOk = async () => {
      const { sids } = getFieldsValue();
      const params = {
        sids,
        pids: ids,
      };
      if (!params.pids.length) {
        message.error("套餐不能为空");
        return;
      }
      if (!params.sids.length) {
        message.error("站点不能为空");
        return;
      }
      setConfirmLoading(true);
      const res = await _saveSitePackage(params);
      setConfirmLoading(false);
      const { code, message: msg } = res.data || {};
      if (code != 200) {
        message.error(msg);
        return;
      }
      setVisible(false);
      onFinish();
    };
    return (
      <Modal
        width={900}
        title="批量站点绑定"
        visible={visible}
        onOk={handleOk}
        onCancel={()=>setVisible(false)}
        confirmLoading={confirmLoading}
      >
        <Form>
          <Form.Item label="选择代理商">
            {getFieldDecorator("fid", {
              rules: [{ required: true, message: "请选择代理商！" }],
            })(
              <FranchiseeSelect onChange={()=>setFieldsValue({sids: []})} />
            )}
          </Form.Item>
          <Form.Item label="选择关联站点">
            {getFieldDecorator("sids", {
              rules: [{ required: true, message: "请选择关联站点！" }],
            })(
              <SiteSelect mode="multiple" fid={getFieldValue('fid')} />
            )}
          </Form.Item>
        </Form>
        <h4>已选中套餐{packageList.length}项</h4>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={packageList}
          bordered
          loading={loading}
        />
      </Modal>
    );
}))

export default Index;