import React from "react";
import { Table, Button, Modal, Form, Radio, Input, message } from "antd";
import SearchBox from "./components/searchBox";
import useTable from "@/hooks/table";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import { _extractApplyList, _extractProcess } from "@/statics/js/api.js";

const Index = () => {
  const columns = [
    {
      title: "序号",
      dataIndex: "no",
      width: 100,
      render: (text, record, index) => pageSize * (curPage - 1) + index + 1,
    },
    {
      title: "用户名",
      dataIndex: "userName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "提现金额",
      dataIndex: "extractAmount",
    },
    {
      title: "申请时间",
      dataIndex: "createTime",
      width: 135,
    },
    {
      title: "申请状态",
      dataIndex: "extractStatus",
      width: 135,
      render: (text) =>
        text == 1
          ? "申请中"
          : text == 2
          ? "已通过"
          : text == 3
          ? "已拒绝"
          : text == 4
          ? "已取消"
          : "",
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      width: 135,
    },
    {
      title: "备注",
      dataIndex: "remark",
      width: 135,
    },
    {
      title: "拒绝原因",
      dataIndex: "refuse",
      width: 135,
    },
    {
      title: "操作",
      dataIndex: "",
      width: 100,
      key: "Action",
      fixed: "right",
      render: (text) =>
        text.extractStatus == 1 && (
          <Button
            type="link"
            disabled={!getJurisdiction(1026)}
            title={getJurisdiction(1026) ? "" : "暂无操作权限！"}
            onClick={() => showAuditModal(text.id)}
          >
            审核
          </Button>
        ),
    },
  ];
  const {
    pageSize,
    curPage,
    totalCount,
    list,
    loading,
    handleSearch,
    handleRefresh,
    handlePageChange,
  } = useTable({
    action: _extractApplyList,
  });

  //审核
  const showAuditModal = (id) => {
    let extractStatus, refuse, remark;
    Modal.confirm({
      title: "提现审核",
      content: (
        <div style={{ marginTop: 30 }}>
          <Form.Item label="审核">
            <Radio.Group
              onChange={(e) => {
                extractStatus = e.target.value;
                if (e.target.value == 3) {
                  document.getElementById("ads").style.display = "block";
                } else {
                  document.getElementById("ads").style.display = "none";
                }
              }}
            >
              <Radio value={2}>通过</Radio>
              <Radio value={3}>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
          <div id="ads" style={{ display: "none" }}>
            <Form.Item label="拒绝理由">
              <Input
                style={{ width: "100%" }}
                onChange={(e) => {
                  refuse = e.target.value;
                }}
                allowClear
              />
            </Form.Item>
          </div>
          <Form.Item label="备注">
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                remark = e.target.value;
              }}
              allowClear
            />
          </Form.Item>
        </div>
      ),
      onOk() {
        let params = {
          id,
          refuse,
          remark,
          extractStatus,
        };
        _extractProcess(params).then((res) => {
          if (res.data.code === 200) {
            message.success(res.data.message);
            handleRefresh();
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  };

  return !getJurisdiction(1024) ? (
    "暂无操作权限！"
  ) : (
    <div>
      <div className="select-form">
        <SearchBox search={handleSearch} />
      </div>
      <Table
        rowKey="id"
        bordered
        columns={columns}
        dataSource={list}
        loading={loading}
        scroll={{ x: 1400, y: 500 }}
        pagination={{
          showQuickJumper: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageChange,
          onChange: handlePageChange,
          total: totalCount,
          current: curPage,
          showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
        }}
      />
    </div>
  );
};

export default Index;
