import React from 'react';
import { 
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  _billDetail,
} from "@/statics/js/api.js"
import {
  Modal,
  Row,
  Col,
  message,
  Table,
  Tabs,
} from "antd";
import moment from 'moment';
const {TabPane} = Tabs


var BillModal = forwardRef((props,ref)=> {
  // 车辆
  const bikeColumns = [
    {
      title: "设备类型",
      dataIndex: "typeName",
    },
    {
      title: "车辆型号",
      dataIndex: "modelName",
    },
    {
      title: "车架号",
      dataIndex: "imei",
    },
    {
      title: "车辆二维码",
      dataIndex: "qcode",
    },
    {
      title: "GPS号",
      dataIndex: "ccode",
    },
    {
      title: "车牌号",
      dataIndex: "vin",
    },
    {
      title: "租金",
      dataIndex: "amount",
    },
  ]
  // 电池
  const batteryColumns = [
    {
      title: "电池型号",
      dataIndex: "modelName",
    },
    {
      title: "电池编号",
      dataIndex: "imei",
    },
    {
      title: "车辆二维码",
      dataIndex: "qcode",
    },
    {
      title: "GPS号",
      dataIndex: "ccode",
    },
    {
      title: "租金",
      dataIndex: "amount",
    },
  ]
  const [visible,setVisible] = useState(false)
  const [bikeList,setBikeList] = useState([])
  const [batteryList,setBatteryList] = useState([])
  const [info,setInfo] = useState({})

  useImperativeHandle(ref, () => ({
    open
  }),[]);

  // 关闭
  const open = async ({id})=>{
    setVisible(true)
    getDetail(id)
  }
  const close = async ()=>{
    setVisible(false)
  }
  // 详情
  const getDetail = async (id)=>{
    let params = { 
      id,
    };
    const res = await _billDetail(params);
    const {code,message:msg,data={}} = res.data || {}
    if(code != 200){
      message.warning(msg);
      return
    }
    setInfo(data)
    let bikeList = data.bikeBillList || []
    let batteryList = data.batBillList || []
    setBikeList(bikeList)
    setBatteryList(batteryList)
  }
  return (
    <>
      <Modal 
        title="清单" 
        visible={visible} 
        width={800}
        onOk={close}
        onCancel={close}
      >
        <Row>
          <Col span={10}>
            公司名称：{info.fname}
          </Col>
          <Col span={14}>
            物流单号：{info.expressNo}
          </Col>
        </Row>
        <br/>
        <Row>
          <Col span={10}>
            账单金额：{info.amount}
          </Col>
          <Col span={14}>
            账单时间：{moment(info.billTime).format("YYYY-MM-DD HH:mm")}
          </Col>
        </Row>
        <br/>
        <Tabs defaultActiveKey="1">
          <TabPane tab="车辆列表" key="1">
            <Table
              rowKey="id"
              bordered
              columns={bikeColumns}
              scroll={{ x: 1000, y: 500 }}
              dataSource={bikeList}
            />
          </TabPane>
          <TabPane tab="电池列表" key="2">
            <Table
              rowKey="id"
              bordered
              columns={batteryColumns}
              scroll={{ x: 1000, y: 500 }}
              dataSource={batteryList}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  )
})

export default BillModal