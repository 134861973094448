/* 
	该文件专门为Count组件生成action对象
*/
import {UPDATEREGION,UPDATECITY} from '../constant'

//同步action，就是指action的值为Object类型的一般对象
export const updateRegionAction = data => ({type:UPDATEREGION,data})
export const updateCityAction = data => ({type:UPDATECITY,data})

//异步action，就是指action的值为函数,异步action中一般都会调用同步action，异步action不是必须要用的。
export const updateRegionAsyncAction = (data,time) => {
	return (dispatch)=>{
		dispatch(updateRegionAction(data))
	}
}
export const updateCityAsyncAction = (data,time) => {
	return (dispatch)=>{
		dispatch(updateCityAction(data))
	}
}