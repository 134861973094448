import { useState, useEffect, useCallback } from "react";

function useTable(props) {
  const {
    auto = true,
    action,
    response,
    params = {},
    pageSizeKey,
    curPageKey,
  } = props;

  const [query, setQuery] = useState({
    pageSize: 10,
    curPage: 1,
    ...params,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    auto && initSearch();
  }, []);
  //回到初始化，回到第一页
  const initSearch = useCallback(() => {
    const { pageSize } = query;
    getList(1, pageSize);
  }, [query]);
  //搜索
  const handleSearch = useCallback(
    async (data = {}) => {
      const { pageSize } = query;
      return getList(1, pageSize, data);
    },
    [query]
  );
  //刷新列表，不改变页码
  const handleRefresh = useCallback(async () => {
    const { curPage, pageSize } = query;
    getList(curPage, pageSize);
  }, [query]);
  //翻页
  const handlePageChange = useCallback(
    async (curPage, pageSize) => {
      getList(curPage, pageSize);
    },
    [query]
  );
  //页条数
  const handleSizeChange = useCallback(
    async (curPage, pageSize) => {
      getList(1, pageSize);
    },
    [query]
  );
  //获取列表
  const getList = useCallback(
    async (curPage, pageSize, data = {}) => {
      const params = {
        ...query,
        curPage,
        pageSize,
        ...data,
      };
      setQuery(params);
      setLoading(true);
      const res = await action({
        ...params,
        curPage: undefined,
        pageSize: undefined,
        [curPageKey || "curPage"]: params.curPage,
        [pageSizeKey || "pageSize"]: params.pageSize,
      }).catch(err=>err);
      setLoading(false);
      if (typeof response === "function") {
        const data = await response(res);
        if (data) {
          const { rows, totalCount } = data;
          setList(rows);
          setTotalCount(totalCount);
          return totalCount;
        }
        return;
      }
      const { code } = res?.data || {};
      const { rows, totalCount } = res?.data?.data || {};
      if (code == 200) {
        setList(rows);
        setTotalCount(totalCount);
        return totalCount;
      }
    },
    [query, response]
  );

  return {
    ...query,
    totalCount,
    list,
    loading,
    setTotalCount,
    setList,
    handleSearch,
    handleRefresh,
    handlePageChange,
    handleSizeChange
  };
}

export default useTable;
