import React,{ 
    useEffect,
    forwardRef,
} from 'react'
import useList from '@/hooks/list'
import {
    Checkbox,
} from "antd";

const Index = forwardRef((props,ref)=>{
    const {
        getRepairList,
        repairList,
        setRepairList
    } = useList()

    useEffect(() => {
        init()
    },[props.area]);
    const init = async ()=>{
        setRepairList([])
        getRepairList(props.area)
    }
    return (
        <Checkbox.Group
            options={repairList.map(item=>({value: item.id,label: item.name}))}
            {...props}
        />
    )
})

export default Index