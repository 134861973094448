import React, { Component, createRef } from "react";
import {
  Button,
  Table,
  Modal,
  Switch,
  Divider,
  DatePicker,
  Select,
  Input,
  Icon,
  Form,
  Row,
  Col,
  Upload,
  message,
} from "antd";
import {
  _upload,
  _franchiseeList,
  _kaFranchiseeDetail,
  _updateKaFranchiseeStatus,
  _saveKaFranchisee,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import md5 from "md5";
import moment from "moment";
import AreaCascader from "@/components/areaCascader";

const { RangePicker } = DatePicker;
const { Option } = Select;
const modalTitle = ["添加代理商", "编辑代理商", "代理商详情"];

// 操作弹窗
class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      fid: "", //代理商id
      initialValues: {}, //代理商详情
      fileList: [], //上传格式
      visible: false,
    };
    props.onRef(this);
    this.open = this.open.bind(this);
  }
  open({ type, fid }) {
    this.setState(
      {
        visible: true,
        type,
        fid,
      },
      () => {
        if (type == 1) {
          // this.props.form && this.props.form.resetFields();
        } else if (type == 2) {
          this.getFranchiseeDetail();
        } else if (type == 3) {
          this.getFranchiseeDetail();
        }
      }
    );
  }
  //Modal取消事件
  close() {
    let { form } = this.props;
    form && form.resetFields();
    this.setState({
      initialValues: {},
      visible: false,
    });
  }
  //获取代理商详情
  getFranchiseeDetail() {
    let { fid } = this.state;
    let params = { fid };
    _kaFranchiseeDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          let { data } = res.data || {};
          let initialValues = {
            ...data,
            areaList: [
              data.fprovince,
              data.fcity,
              data.fcounty,
              data.fstreet,
            ].filter((item) => item),
          };
          this.setState({
            initialValues,
          });
          res.data.data.imgObject &&
            this.updateFileList([
              {
                uid: -1,
                name: res.data.data.imgObject.id,
                status: "done",
                url: res.data.data.imgObject.path,
              },
            ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //updata start
  handleChange = ({ file, fileList }) => {
    if (fileList.length === 1) {
      if (!!fileList[0].response) {
        if (fileList[0].response.code !== 200) {
          message.warning(fileList[0].response.message);
          fileList.splice(0, 1);
        }
      }
    }
    this.updateFileList(fileList);
  };
  updateFileList = (fileList) => {
    this.setState({
      fileList,
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form, close } = this.props;
    const { fid, type } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.fwork && values.fwork.constructor === Array) {
        values.fwork =
          values.fwork.length === 1 ? values.fwork[0].response.data.id : "";
      }
      if (values.password) {
        values.password = md5(values.password);
      }
      values.fstate === undefined && type === 1
        ? (values.fstate = 1)
        : !!values.fstate
        ? (values.fstate = 1)
        : (values.fstate = 0);
      if (values.fcooperation && values.fcooperation.length) {
        values.fstartCooperation = moment(values.fcooperation[0]).format(
          "YYYY-MM-DD"
        );
        values.fendCooperation = moment(values.fcooperation[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.fcooperation;
      if (values.areaList) {
        values.fprovince = values.areaList[0] ? values.areaList[0] : null;
        values.fcity = values.areaList[1] ? values.areaList[1] : null;
        values.fcounty = values.areaList[2] ? values.areaList[2] : null;
        values.fstreet = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      if (type === 3) {
        close();
      } else {
        let params =
          type === 1
            ? {
                ...values,
              }
            : type === 2
            ? {
                fid,
                ...values,
              }
            : {};
        _saveKaFranchisee(params)
          .then((res) => {
            if (res.data.code === 200) {
              message.success("操作成功");
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            } else {
              message.warning(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { initialValues, fileList, type, visible } = this.state;
    return (
      <div>
        <Modal
          className="bigModal"
          title={modalTitle[type - 1]}
          destroyOnClose
          maskClosable={false}
          visible={visible}
          onOk={this.handleOk.bind(this)}
          onCancel={() => this.close()}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="代理商名称">
                {type === 3 ? (
                  <span>{initialValues.fname}</span>
                ) : (
                  getFieldDecorator("fname", {
                    initialValue: initialValues.fname,
                    rules: [{ required: true, message: "请输入代理商名称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="联系人">
                {type === 3 ? (
                  <span>{initialValues.fcontacts}</span>
                ) : (
                  getFieldDecorator("fcontacts", {
                    initialValue: initialValues.fcontacts,
                    rules: [{ required: true, message: "请输入联系人！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="手机号">
                {type === 3 ? (
                  <span>{initialValues.ftel}</span>
                ) : (
                  getFieldDecorator("ftel", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: "请输入正确的手机号格式",
                      },
                      { required: true, message: "请输入手机号！" },
                    ],
                    initialValue: initialValues.ftel,
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            {type === 1 && (
              <Col span={24}>
                <Form.Item label="密码">
                  {getFieldDecorator("password", {
                    validateTrigger: "onBlur",
                    rules: [
                      {
                        message: "请输入至少六位数密码",
                        pattern: /^.{6,}$/,
                      },
                    ],
                  })(<Input allowClear placeholder="默认密码123456" />)}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="合作期限">
                {type === 3 ? (
                  <span>
                    {initialValues.fstartCooperation &&
                    initialValues.fendCooperation
                      ? initialValues.fstartCooperation +
                        "～" +
                        initialValues.fstartCooperation
                      : ""}
                  </span>
                ) : (
                  getFieldDecorator("fcooperation", {
                    initialValue:
                      initialValues.fstartCooperation &&
                      initialValues.fendCooperation
                        ? [
                            moment(initialValues.fstartCooperation),
                            moment(initialValues.fendCooperation),
                          ]
                        : undefined,
                    rules: [{ required: true, message: "请选择合作期限！" }],
                  })(<RangePicker format="YYYY-MM-DD" />)
                )}
              </Form.Item>
            </Col>
            {type !== 3 && (
              <Col span={24}>
                <Form.Item label="合同文档">
                  {getFieldDecorator("fwork", {
                    initialValue: initialValues.fwork || undefined,
                    getValueFromEvent: this.normFile,
                  })(
                    <Upload
                      name="uploadFile"
                      action={_upload}
                      listType="picture-card"
                      fileList={fileList}
                      accept=".pdf"
                      onChange={this.handleChange.bind(this)}
                      data={{
                        scene: "active",
                      }}
                    >
                      {fileList.length >= 1 ? null : (
                        <div>
                          <Icon type="plus" style={{ fontSize: "28px" }} />
                          <p>PDF文件上传</p>
                        </div>
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label="地区">
                {type === 3 ? (
                  <span>{initialValues.areaName}</span>
                ) : (
                  getFieldDecorator("areaList", {
                    initialValue: initialValues.areaList,
                    rules: [
                      {
                        required: type === 1 ? true : false,
                        message: "请选择地区！",
                      },
                    ],
                  })(
                    <AreaCascader
                      changeOnSelect
                      placeholder="请选择地区！"
                      style={{ width: "100%" }}
                    />
                  )
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="详情地址">
                {type === 3 ? (
                  <span>{initialValues.fadd}</span>
                ) : (
                  getFieldDecorator("fadd", {
                    initialValue: initialValues.fadd,
                    rules: [{ required: true, message: "请输入详情地址！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="公司全称">
                {type === 3 ? (
                  <span>{initialValues.fcompanyName}</span>
                ) : (
                  getFieldDecorator("fcompanyName", {
                    initialValue: initialValues.fcompanyName,
                    rules: [{ required: true, message: "请输入公司全称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="纳税识别号">
                {type === 3 ? (
                  <span>{initialValues.ftaxNo}</span>
                ) : (
                  getFieldDecorator("ftaxNo", {
                    initialValue: initialValues.ftaxNo,
                    rules: [{ required: true, message: "请输入纳税识别号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="开户行">
                {type === 3 ? (
                  <span>{initialValues.fbankName}</span>
                ) : (
                  getFieldDecorator("fbankName", {
                    initialValue: initialValues.fbankName,
                    rules: [{ required: true, message: "请输入开户行！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="银行账号">
                {type === 3 ? (
                  <span>{initialValues.fbankAccounts}</span>
                ) : (
                  getFieldDecorator("fbankAccounts", {
                    initialValue: initialValues.fbankAccounts,
                    rules: [{ required: true, message: "请输入银行账号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付宝名称">
                {type === 3 ? (
                  <span>{initialValues.falipayName}</span>
                ) : (
                  getFieldDecorator("falipayName", {
                    initialValue: initialValues.falipayName,
                    rules: [{ required: true, message: "请输入支付宝名称！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付宝账号">
                {type === 3 ? (
                  <span>{initialValues.falipayAccounts}</span>
                ) : (
                  getFieldDecorator("falipayAccounts", {
                    initialValue: initialValues.falipayAccounts,
                    rules: [{ required: true, message: "请输入支付宝账号！" }],
                  })(<Input allowClear placeholder="请输入" />)
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="状态">
                {getFieldDecorator("fstate", {
                  initialValue: initialValues.fstate === 0 ? false : true,
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={type === 3 && true}
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

AddModal = Form.create({})(AddModal);

class KaManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      franchiseeList: [], //代理商管理列表
      queryFormValues: {
        status: 1,
        ftype: 1,
      }, //筛选项
      userType: localStorage.getItem("type"),
      userFid: localStorage.getItem("fid"),
    };
    this.addModal = createRef();
    this.columns = [
      {
        title: "代理商编号",
        width: 120,
        dataIndex: "fid",
      },
      {
        title: "代理商名称",
        width: 250,
        dataIndex: "fname",
      },
      {
        title: "联系人",
        width: 120,
        dataIndex: "fcontacts",
      },
      {
        title: "手机号",
        align: "right",
        width: 150,
        dataIndex: "ftel",
      },
      {
        title: "城市",
        width: 120,
        dataIndex: "fcityName",
      },
      {
        title: "合作期限",
        width: 145,
        dataIndex: "",
        render: (text) => (
          <span>
            {text.fstartCooperation ? text.fstartCooperation : ""}
            {text.fstartCooperation && text.fendCooperation ? (
              <span>
                <br />
                ~
                <br />
              </span>
            ) : null}
            {text.fendCooperation ? text.fendCooperation : ""}
          </span>
        ),
      },
      {
        title: "添加时间",
        width: 120,
        dataIndex: "fupdate",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "管理状态",
        dataIndex: "",
        width: 100,
        render: (text) => (
          <Switch
            defaultChecked={!!text.fstate}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.fid)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        key: "Action",
        fixed: "right",
        render: (text) => (
          <span>
            <Button type="link" onClick={() => this.showModal(3, text.fid)}>
              查看
            </Button>
            <Divider type="vertical" />
            <Button type="link" onClick={() => this.showModal(2, text.fid)} disabled={this.state.userType != 0}>
              编辑
            </Button>
          </span>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="代理商/手机号">
              {getFieldDecorator("queryString")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="城市">
              {getFieldDecorator("areaList")(
                <AreaCascader
                  changeOnSelect
                  placeholder="城市"
                  style={{ width: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="管理状态">
              {getFieldDecorator("status", {
                initialValue: this.state.queryFormValues.status,
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="请选择"
                  allowClear
                >
                  <Option value={""}>全部</Option>
                  <Option value={1}>启用</Option>
                  <Option value={0}>禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const {
      franchiseeList,
      totalCount,
      curPage,
      pageSize,
      userType,
    } = this.state;
   
    return (
      <div>
        {!!getJurisdiction(968) ? (
          <div className="AgentManagement">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                disabled={userType != 0}
                icon="plus"
                type="primary"
                onClick={() => this.showModal(1, "")}
              >
                添加代理商
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="fid"
                bordered
                columns={this.columns}
                dataSource={franchiseeList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <AddModal
              onRef={(ref) => {
                this.addModal = ref;
              }}
            />
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }
  //获取代理商列表
  getFranchiseeList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    let { userFid, userType } = this.state;
    if (userType == 8 || userType == 9) {
      params.fid = userFid;
    }
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            franchiseeList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getFranchiseeList(curPage, pageSize, this.state.queryFormValues);
  }

  //表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.fstate;
      if (values.areaList) {
        values.sprovince = values.areaList[0] ? values.areaList[0] : null;
        values.scity = values.areaList[1] ? values.areaList[1] : null;
        values.scounty = values.areaList[2] ? values.areaList[2] : null;
        values.sstreet = values.areaList[3] ? values.areaList[3] : null;
        delete values.areaList;
      }
      this.setState({
        queryFormValues: values,
      });
      this.getFranchiseeList(1, this.state.pageSize, values);
    });
  };

  //管理状态Switch
  handleStateChange(fid, checked) {
    let params = { fid, fstate: Number(checked) };
    _updateKaFranchiseeStatus(params)
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getFranchiseeList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Modal打开事件
  showModal(type, fid) {
    this.addModal.open({ fid, type });
  }

}

KaManagement = Form.create({})(KaManagement);
export { KaManagement };
