import React, { 
    useEffect,
    useState,
} from 'react';
import {
    Tabs,
    Radio
} from "antd";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import Property from "./property"
import Order from "./order"
import Timeout from "./timeout"
import Withhold from "./withhold"
import queryString from 'query-string';

const propertyOptions = [
    {value: 1, label: '自营数据'},
    {value: 2, label: '自营+SAAS数据'},
    {value: 3, label: '自营电池'},
]
const orderOptions = [
    {value: 1, label: '订单报表'},
    {value: 2, label: 'BD订单报表'},
    {value: 3, label: '套餐订单报表'},
]
const timeoutOptions = [
    {value: 1, label: '超时订单报表'},
    {value: 2, label: '超时订单处理效率报表'},
]

const OperationButton = (props)=>{
    const {options=[],value,onChange} = props
    const [active,setActive] = useState(value)
    const handleChange = (e)=>{
        const {value} = e.target || {}
        setActive(value)
        onChange(value)
    }
    return(
        <Radio.Group value={active} onChange={handleChange} buttonStyle="solid">
            {
                options.map((item,index)=>(
                    <Radio.Button key={index} value={item.value}>{item.label}</Radio.Button>
                ))
            }
        </Radio.Group>
    )
}

const Index = (props)=> {
    const {active:defaultActive,fid='',date:defaultdate} = queryString.parse(props.location.search) || {}
    const defaultfid = fid ? Number(fid) : null
    const [active,setActive] = useState('1')
    const [propertyValue,setPropertyValue] = useState(1)
    const [orderValue,setOrderValue] = useState(1)
    const [timeoutValue,setTimeoutValue] = useState(1)
    useEffect(()=>{
        if(getJurisdiction(960)){
            setActive('1')
        }else if(getJurisdiction(962)){
            setActive('2')
        }else if(getJurisdiction(964)){
            setActive('3')
        }else if(getJurisdiction(1077)){
            setActive('4')
        }
        if(defaultActive){
            setActive(defaultActive)
        }
    },[])
    return (<div>
        {getJurisdiction(967) ? (
            <Tabs 
                activeKey={active} 
                onChange={(key)=>{setActive(key)}} 
                tabBarExtraContent={
                    <>
                        <div style={{display: active == 1 ? 'block' : 'none'}}>
                            <OperationButton value={propertyValue} options={propertyOptions} onChange={(value)=>{setPropertyValue(value)}} />
                        </div>
                        <div style={{display: active == 2 ? 'block' : 'none'}}>
                            <OperationButton value={orderValue} options={orderOptions} onChange={(value)=>{setOrderValue(value)}}/>
                        </div>
                        <div style={{display: active == 3 ? 'block' : 'none'}}>
                            <OperationButton value={timeoutValue} options={timeoutOptions} onChange={(value)=>{setTimeoutValue(value)}}/>
                        </div>
                    </>
                }
            >
                {getJurisdiction(960) && (
                    <Tabs.TabPane tab="资产报表" key="1">
                        <Property value={propertyValue} />
                    </Tabs.TabPane>
                )}
                {getJurisdiction(962) && (
                    <Tabs.TabPane tab="订单报表" key="2">
                        <Order value={orderValue} />
                    </Tabs.TabPane>
                )}
                {getJurisdiction(964) && (
                    <Tabs.TabPane tab="超时订单报表" key="3">
                        <Timeout value={timeoutValue} />
                    </Tabs.TabPane>
                )}
                {getJurisdiction(1077) && (
                    <Tabs.TabPane tab="挂起后代扣成功" key="4">
                        <Withhold defaultfid={defaultfid} defaultdate={defaultdate} />
                    </Tabs.TabPane>
                )}
            </Tabs>
        ) : (
            "暂无操作权限！"
        )}
    </div>)
}

export default Index