// -----------公共部分------------
import BankPage from '@/views/bankPage' //银行跳转页
import { View as Common } from '@/views/common' //整站公共部分（头部,侧边栏）
import { View as Index } from '@/views/index' //首页
import { View as Login } from '@/views/login' //登录
import { View as Terms } from '@/views/terms' //声明条款
import { View as AppDownload } from '@/views/appDownload' //下载
import { View as ApplyView } from '@/views/applyView' //h5代付页面
import { View as JZBatteryMgt } from '@/views/jzBatteryMgt' //金租电池管理
import Upload from '@/views/upload'
// -----------个人信息------------
import { BasicInfo } from '@/views/user/basicInfo' //基本资料
import { ChangePwd } from '@/views/user/changePwd' //修改密码
//-----------systemMGT系统管理------------
import { SystemManagement } from '@/views/systemMGT/systemManagement.js' //系统菜单
import { RoleManagement } from '@/views/systemMGT/roleManagement.js' //角色管理
//-----------businessMGT商家管理------------
import { AgentManagement } from '@/views/businessMGT/agentManagement.js' //代理商管理
import { SiteManagement } from '@/views/businessMGT/siteManagement.js' //站点管理
import { SiteAdministrator } from '@/views/businessMGT/siteAdministrator.js' //站点管理员
import Task from '@/views/businessMGT/task' //超时工单
// ka商家管理
import { KaManagement } from '@/views/businessMGT/kaManagement.js' //代理商管理
import { KaSiteManagement } from '@/views/businessMGT/kaSiteManagement.js' //站点管理
//-----------memberMGT用户管理-------------
import { MemberManagement } from '@/views/memberMGT' //用户管理
import UserFeedbackList from '@/views/memberMGT/userFeedbackList' //用户反馈
//-----------ka用户管理-------------
import { KaMemberManagement } from '@/views/memberMGT/kaMemberManagement' //用户管理
//-----------equipmentMGT设备管理------------
import { ChargeBattery } from '@/views/equipmentMGT/chargeBattery.js' //电柜管理
import { EVCharger } from '@/views/equipmentMGT/evCharger.js' //充电桩管理
import { BatteryManagement } from '@/views/equipmentMGT/batteryManagement.js' //电池管理
import { BatteryModel } from '@/views/equipmentMGT/batteryModel.js' //电池型号
import { BikeManagement } from '@/views/equipmentMGT/bikeManagement.js' //车辆管理
import { BikeModel } from '@/views/equipmentMGT/bikeModel.js' //车辆型号
import { EVChargerModel } from '@/views/equipmentMGT/evChargerModel.js' //充电桩型号
import { ModelPrice } from '@/views/equipmentMGT/modelPrice.js' //型号价格
import BatteryStatistic from '@/views/equipmentMGT/batteryStatistic' // 电池数据
import BatteryAlarmList from '@/views/equipmentMGT/batteryAlarmList' // 设备异常
import BatteryBoard from '@/views/equipmentMGT/batteryBoard' // 电池大屏
import GpsManagement from '@/views/equipmentMGT/gpsManagement' // 电池大屏
import Exception from '@/views/equipmentMGT/exception' // 设备异常查询
//-----------logisticsMGT物流管理------------
import { LogisticsSingle } from '@/views/logisticsMGT' //物流单
import { TransferringOrder } from '@/views/logisticsMGT/transferringOrder' //调拨单
//-----------packageMGT套餐管理------------
import CarRentalPackages from '@/views/packageMGT/package' //车辆套餐
import PackageApply from '@/views/packageMGT/packageApply' //套餐申请
//-----------activityMGT活动管理------------
import { FavourableActivity } from '@/views/activityMGT/favourableActivity.js' //优惠活动
import { CouponsList } from '@/views/activityMGT/couponsList.js' //优惠券
import { Releaserecord } from '@/views/activityMGT/releaserecord.js' //发放记录
import { CashApplyList } from '@/views/activityMGT/cashApplyList.js' //提现申请
//-----------orderMGT订单管理------------
import { PackageOrderManagement } from '@/views/orderMGT/packageOrderManagement/index.js' //套餐订单管理
import { TransferOrder } from '@/views/orderMGT/transferOrder.js' //换车订单
import { ExceptionOrderList } from '@/views/orderMGT/exceptionOrderList.js' //线下处理订单管理
import { OrderApplyManagement } from '@/views/orderMGT/orderApplyManagement.js' //订单代付管理
import { TodayOrder } from '@/views/orderMGT/todayOrder.js' //今日订单
import { FeeSplitting } from '@/views/orderMGT/feeSplitting/index.js' //代理商套餐即时分润
import WithholdComplain from '@/views/orderMGT/withholdComplain' //代扣申诉
//-----------refundMGT退车管理------------
import { SiteRepairManagement } from '@/views/refundMGT/siteRepairManagement.js' //退车点管理
import { BikeRepairManagement } from '@/views/refundMGT/bikeRepairManagement.js' //车辆维修管理
import { BikeRepairDetailList } from '@/views/refundMGT/bikeRepairDetailList.js' //车辆维修项详情
import { SiteRepairAdministrator } from '@/views/refundMGT/siteRepairAdministrator.js' //退车点管理员
//-----------reporMGT报表管理------------
import Operation from '@/views/reportMGT/operation' //运营报表
import Overdue from '@/views/reportMGT/overdue' //逾期报表
import RepaymentOrder from '@/views/reportMGT/repaymentOrder' //分期付订单
import { RealTimeDistributionBattery } from '@/views/reportMGT/realTimeDistributionBattery.js' //供应商即时分润（电池）
import { PackagesOrderLogList } from '@/views/reportMGT/packagesOrderLogList.js' //流水号报表
import { PerformanceRecordList } from '@/views/reportMGT/performanceRecordList.js' //履约记录报表
import { FastChargeLogList } from '@/views/reportMGT/fastChargeLogList.js' //充电桩报表
import ElectricCollectionReport from '@/views/reportMGT/electricCollectionReport/index.jsx' //电催报表
import { CapitalistsBillManagement } from '@/views/reportMGT/capitalistsBillManagement.js' //账单管理
import { FrameRefundReport } from '@/views/reportMGT/frameRefundReport.js' //车架返款报表
import { LeaseDistributionalReport } from '@/views/reportMGT/leaseDistributionalReport.js' //租赁分润报表
import { FinancialPayLateFees } from '@/views/reportMGT/financialPayLateFees.js' //财务报表实付逾期租金违约金
import { FinancialBuyInsurance } from '@/views/reportMGT/financialBuyInsurance.js' //财务报表单买免赔
import { RealTimeDistributionBike } from '@/views/reportMGT/realTimeDistributionBike.js' //供应商实即分润（车辆）
import { ReportManagement } from '@/views/reportMGT/reportManagement.js' //订单报表管理
//-----------configurationMGT配置管理------------
import { PackageTranferRule } from '@/views/configurationMGT/packageTranferRule.js' //套餐分润
import { RepairTransferRule } from '@/views/configurationMGT/repairTransferRule.js' //维修分润
import { BatteryTranferRule } from '@/views/configurationMGT/batteryTranferRule.js' //电池分润
import { SettlementRule } from '@/views/configurationMGT/settlementRule.js' //分润规则配置
import ReferrerRule from '@/views/configurationMGT/referrerRule' //推荐人分润规则
import ShuntRule from '@/views/configurationMGT/shuntRule' //支付分流配置
//-----------repairMGT维修管理------------
import { KARepCustomerList } from '@/views/repairMGT/kaRepCustomerList.js' //维修KA用户管理
import { KABikeModelRepair } from '@/views/repairMGT/kaBikeModelRepair.js' //维修车辆型号
import { KABikeRepairList } from '@/views/repairMGT/kaBikeRepairList.js' //维修车辆管理
import { KARepRiderList } from '@/views/repairMGT/kaRepRiderList.js' //骑手管理
import { RepAgentList } from '@/views/repairMGT/repAgentList.js' //维修代理商管理
import { RepManList } from '@/views/repairMGT/repManList.js' //维修师管理
//-----------财务管理------------
import SerialReport from '@/views/financeMGT/serialReport' // 流水号报表
import Bill from '@/views/financeMGT/bill' // 财务账单
import Balance from '@/views/financeMGT/balance' // 账户充值
//-----------充值管理------------
import MemberBalance from '@/views/rechargeMGT/memberBalance' // 会员余额
import WithdrawalApplication from '@/views/rechargeMGT/withdrawalApplication' // 会员提现申请
import RechargeStatement from '@/views/rechargeMGT/rechargeStatement' // 会员充值报表
//-----------数据大屏------------
import Company from '@/views/board/company'
import Pay from '@/views/pay'
import Website from '@/views/website'

const routeConfig = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/batteryBoard',
    component: BatteryBoard,
  },
  {
    path: '/company',
    component: Company,
  },
  {
    path: '/uploadBattery',
    component: Upload,
  },
  {
    path: '/',
    component: Common,
    breadcrumbName: '首页',
    indexRoute: { component: Index },
    childRoutes: [
      {
        path: '/jzBatteryMgt',
        component: JZBatteryMgt,
      },
      {
        path: '/basicInfo',
        breadcrumbName: '基本资料',
        component: BasicInfo,
      },
      {
        path: '/changePwd',
        breadcrumbName: '修改密码',
        component: ChangePwd,
      },
      {
        breadcrumbName: '系统管理',
        childRoutes: [
          {
            breadcrumbName: '系统菜单',
            path: '/systemManagement',
            component: SystemManagement,
          },
          {
            breadcrumbName: '角色管理',
            path: '/roleManagement',
            component: RoleManagement,
          },
        ],
      },
      {
        breadcrumbName: '商家管理',
        childRoutes: [
          {
            breadcrumbName: '代理商管理',
            path: '/agentManagement',
            component: AgentManagement,
          },
          {
            breadcrumbName: '站点管理',
            path: '/siteManagement',
            component: SiteManagement,
          },
          {
            breadcrumbName: '站点管理员',
            path: '/siteAdministrator',
            component: SiteAdministrator,
          },
          {
            breadcrumbName: 'KA代理商管理',
            path: '/kaManagement',
            component: KaManagement,
          },
          {
            breadcrumbName: 'KA站点管理',
            path: '/kaSiteManagement',
            component: KaSiteManagement,
          },
          {
            breadcrumbName: '超时工单',
            path: '/task',
            component: Task,
          },
        ],
      },
      {
        breadcrumbName: '用户管理',
        childRoutes: [
          {
            breadcrumbName: '用户管理',
            path: '/memberManagement',
            component: MemberManagement,
          },
          {
            breadcrumbName: 'KA用户管理',
            path: '/kaMemberManagement',
            component: KaMemberManagement,
          },
          {
            breadcrumbName: '用户反馈',
            path: '/userFeedbackList',
            component: UserFeedbackList,
          },
        ],
      },
      {
        breadcrumbName: '设备管理',
        childRoutes: [
          {
            breadcrumbName: '电柜管理',
            path: '/chargeBattery',
            component: ChargeBattery,
          },
          {
            breadcrumbName: '充电桩管理',
            path: '/evCharger',
            component: EVCharger,
          },
          {
            breadcrumbName: '充电桩型号',
            path: '/evChargerModel',
            component: EVChargerModel,
          },
          {
            breadcrumbName: '电池管理',
            path: '/batteryManagement',
            component: BatteryManagement,
          },
          {
            breadcrumbName: '电池数据',
            path: '/batteryStatistic',
            component: BatteryStatistic,
          },
          {
            breadcrumbName: '设备异常',
            path: '/BatteryAlarmList',
            component: BatteryAlarmList,
          },
          {
            breadcrumbName: '设备异常查询',
            path: '/exception',
            component: Exception,
          },
          {
            breadcrumbName: '电池型号',
            path: '/batteryModel',
            component: BatteryModel,
          },
          {
            breadcrumbName: '车辆管理',
            path: '/bikeManagement',
            component: BikeManagement,
          },
          {
            breadcrumbName: '车辆型号',
            path: '/bikeModel',
            component: BikeModel,
          },
          {
            breadcrumbName: '型号价格',
            path: '/modelPrice',
            component: ModelPrice,
          },
          {
            breadcrumbName: 'GPS白名单管理',
            path: '/gpsManagement',
            component: GpsManagement,
          },
        ],
      },
      {
        breadcrumbName: '物流管理',
        childRoutes: [
          {
            breadcrumbName: '物流单',
            path: '/logisticsSingle',
            component: LogisticsSingle,
          },
          {
            breadcrumbName: '调拨单',
            path: '/transferringOrder',
            component: TransferringOrder,
          },
        ],
      },
      {
        breadcrumbName: '套餐管理',
        childRoutes: [
          {
            breadcrumbName: '车辆套餐',
            path: '/carRentalPackages',
            component: CarRentalPackages,
          },
          {
            breadcrumbName: '套餐申请',
            path: '/packageApply',
            component: PackageApply,
          },
        ],
      },
      {
        breadcrumbName: '活动管理',
        childRoutes: [
          {
            breadcrumbName: '优惠活动',
            path: '/favourableActivity',
            component: FavourableActivity,
          },
          {
            breadcrumbName: '优惠券',
            path: '/couponsList',
            component: CouponsList,
          },
          {
            breadcrumbName: '提现申请',
            path: '/cashApplyList',
            component: CashApplyList,
          },
          {
            breadcrumbName: '发放记录',
            path: '/releaserecord',
            component: Releaserecord,
          },
        ],
      },
      {
        breadcrumbName: '订单管理',
        childRoutes: [
          {
            breadcrumbName: '套餐订单管理',
            path: '/packageOrderManagement',
            component: PackageOrderManagement,
          },
          {
            breadcrumbName: '换车订单',
            path: '/transferOrder',
            component: TransferOrder,
          },
          {
            breadcrumbName: '线下处理订单',
            path: '/exceptionOrderList',
            component: ExceptionOrderList,
          },
          {
            breadcrumbName: '订单代付管理',
            path: '/orderApplyManagement',
            component: OrderApplyManagement,
          },
          {
            breadcrumbName: '今日订单',
            path: '/todayOrder',
            component: TodayOrder,
          },
          {
            breadcrumbName: '用户代扣申诉',
            path: '/withholdComplain',
            component: WithholdComplain,
          },
        ],
      },
      {
        breadcrumbName: '退车管理',
        childRoutes: [
          {
            breadcrumbName: '退车点管理',
            path: '/siteRepairManagement',
            component: SiteRepairManagement,
          },
          {
            breadcrumbName: '维修项管理',
            path: '/bikeRepairManagement',
            component: BikeRepairManagement,
          },
          {
            breadcrumbName: '车辆维修项详情',
            path: '/bikeRepairDetailList',
            component: BikeRepairDetailList,
          },
          {
            breadcrumbName: '退车点管理员',
            path: '/siteRepairAdministrator',
            component: SiteRepairAdministrator,
          },
        ],
      },
      {
        breadcrumbName: '财务管理',
        childRoutes: [
          {
            breadcrumbName: '流水号报表',
            path: '/serialReport',
            component: SerialReport,
          },
          {
            breadcrumbName: '财务账单',
            path: '/bill',
            component: Bill,
          },
          {
            breadcrumbName: '账户充值',
            path: '/balance',
            component: Balance,
          },
        ],
      },
      {
        breadcrumbName: '报表管理',
        childRoutes: [
          {
            breadcrumbName: '业务报表',
            childRoutes: [
              {
                breadcrumbName: '运营报表',
                path: '/operation',
                component: Operation,
              },
              {
                breadcrumbName: '逾期报表',
                path: '/overdue',
                component: Overdue,
              },
              {
                breadcrumbName: '履约记录报表',
                path: '/performanceRecordList',
                component: PerformanceRecordList,
              },
              {
                breadcrumbName: '充电桩报表',
                path: '/fastChargeLogList',
                component: FastChargeLogList,
              },
              {
                breadcrumbName: '电催报表',
                path: '/electricCollectionReport',
                component: ElectricCollectionReport,
              },
            ],
          },
          {
            breadcrumbName: '财务报表',
            childRoutes: [
              {
                breadcrumbName: '供应商电池即时分润',
                path: '/RealTimeDistributionBattery',
                component: RealTimeDistributionBattery,
              },
              {
                breadcrumbName: '代理商套餐即时分润',
                path: '/feeSplitting',
                component: FeeSplitting,
              },
              {
                breadcrumbName: '流水号报表',
                path: '/packagesOrderLogList',
                component: PackagesOrderLogList,
              },
              {
                breadcrumbName: '分期付订单',
                path: '/repaymentOrder',
                component: RepaymentOrder,
              },
            ],
          },
        ],
      },
      {
        breadcrumbName: '充值管理',
        childRoutes: [
          {
            breadcrumbName: '会员余额',
            path: '/memberBalance',
            component: MemberBalance,
          },
          {
            breadcrumbName: '会员提现申请',
            path: '/withdrawalApplication',
            component: WithdrawalApplication,
          },
          {
            breadcrumbName: '会员充值报表',
            path: '/rechargeStatement',
            component: RechargeStatement,
          },
        ],
      },
      {
        breadcrumbName: '配置管理',
        childRoutes: [
          {
            breadcrumbName: '套餐分润',
            path: '/PackageTranferRule',
            component: PackageTranferRule,
          },
          {
            breadcrumbName: '维修分润',
            path: '/repairTransferRule',
            component: RepairTransferRule,
          },
          {
            breadcrumbName: '电池分润',
            path: '/batteryTranferRule',
            component: BatteryTranferRule,
          },
          {
            breadcrumbName: '分润规则',
            path: '/settlementRule',
            component: SettlementRule,
          },
          {
            breadcrumbName: '推荐人分润规则',
            path: '/referrerRule',
            component: ReferrerRule,
          },
          {
            breadcrumbName: '支付分流配置',
            path: '/shuntRule',
            component: ShuntRule,
          },
        ],
      },
      {
        breadcrumbName: '维修管理',
        childRoutes: [
          {
            breadcrumbName: 'KA用户管理',
            path: '/kaRepCustomerList',
            component: KARepCustomerList,
          },
          {
            breadcrumbName: '维修代理商管理',
            path: '/repAgentList',
            component: RepAgentList,
          },
          {
            breadcrumbName: '车辆管理',
            path: '/kaBikeRepairList',
            component: KABikeRepairList,
          },
          {
            breadcrumbName: '车辆型号',
            path: '/kaBikeModelRepair',
            component: KABikeModelRepair,
          },
          {
            breadcrumbName: '骑手管理',
            path: '/kaRepRiderList',
            component: KARepRiderList,
          },
          {
            breadcrumbName: '维修师管理',
            path: '/repManList',
            component: RepManList,
          },
        ],
      },
    ],
  },
  {
    path: '/bankPage',
    component: BankPage,
  },
  {
    path: '/terms',
    component: Terms,
  },
  {
    path: '/appDownload',
    component: AppDownload,
  },
  {
    path: '/applyView',
    component: ApplyView,
  },
  {
    path: '/pay',
    component: Pay,
  },
  {
    path: '/website',
    component: Website,
  },
]

export default routeConfig

// 未开启的路由
//  <Route
//   breadcrumbName="订单报表管理"
//   path="/reportManagement"
//   component={ReportManagement}
// />
// <Route
//   breadcrumbName="账单管理"
//   path="/capitalistsBillManagement"
//   component={CapitalistsBillManagement}
// />
// <Route
//   breadcrumbName="车架返款报表"
//   path="/frameRefundReport"
//   component={FrameRefundReport}
// />
// <Route
//   breadcrumbName="租赁分润报表"
//   path="/leaseDistributionalReport"
//   component={LeaseDistributionalReport}
// />
// <Route
//   breadcrumbName="财务报表实付逾期租金违约金"
//   path="/financialPayLateFees"
//   component={FinancialPayLateFees}
// />
// <Route
//   breadcrumbName="财务报表单买免赔"
//   path="/financialBuyInsurance"
//   component={FinancialBuyInsurance}
// />
// <Route
//   breadcrumbName="供应商车辆即时分润"
//   path="/realTimeDistributionBike"
//   component={RealTimeDistributionBike}
// />
