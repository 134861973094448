import React, { Component } from "react";
import {
  Col,
  Row,
  Form,
  Table,
  Input,
  Button,
  Select,
  message,
  DatePicker,
} from "antd";
import {
  _couponList,
  _couponSendList,
  _exportCouponSend,
} from "@/statics/js/api";
import moment from "moment";
import { getJurisdiction } from "@/statics/js/jurisdiction";
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
class Releaserecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      couponList: [], //优惠券列表
      packageTransferList: [], //套餐即时分润列表
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "优惠券",
        dataIndex: "couponName",
      },
      {
        title: "优惠券编号",
        dataIndex: "couponNo",
      },
      {
        title: "批次号",
        dataIndex: "batchNo",
      },
      {
        title: "用户名",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.realName}
            <br />
            {text.mobile}
          </span>
        ),
      },
      {
        title: "支付宝ID",
        dataIndex: "alipayUserId",
      },
      {
        title: "发放时间",
        width: 120,
        dataIndex: "addTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "使用状态",
        dataIndex: "useStatus",
        render: (text) =>
          text === 0
            ? "禁用"
            : text === 1
            ? "未使用"
            : text === 2
            ? "已使用"
            : text === 3
            ? "已过期"
            : null,
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="优惠券">
              {getFieldDecorator("couponId")(
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  <Option value="">全部</Option>
                  {this.state.couponList.length &&
                    this.state.couponList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="批次号">
              {getFieldDecorator("batchNo")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="用户/手机号">
              {getFieldDecorator("userInfo")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="发放时间">
              {getFieldDecorator("sendTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(943)}
                title={getJurisdiction(943) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, packageTransferList } = this.state;
    return (
      <div>
        {getJurisdiction(932) ? (
          <div className="Releaserecord">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={packageTransferList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getCouponList();
    this.getCouponSendList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取优惠券列表
  getCouponList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _couponList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            couponList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取列表
  getCouponSendList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _couponSendList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            packageTransferList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getCouponSendList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.sendTime && values.sendTime.length) {
        values.startTime = moment(values.sendTime[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.sendTime[1]).format("YYYY-MM-DD");
      }
      delete values.sendTime;
      this.setState({
        queryFormValues: values,
      });
      this.getCouponSendList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.sendTime && values.sendTime.length) {
        values.startTime = moment(values.sendTime[0]).format("YYYY-MM-DD");
        values.endTime = moment(values.sendTime[1]).format("YYYY-MM-DD");
      }
      delete values.sendTime;
      let params = {
        ...values,
      };
      _exportCouponSend(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

Releaserecord = Form.create({})(Releaserecord);
export { Releaserecord };
