import React, { 
    useEffect,
    useRef,
} from 'react';
import { useHistory } from "react-router-dom";

import CommonLayout from '@/components/commonLayout'
import CommonTable from '@/components/commonTable'
import SearchBox from "./components/searchBox";
import HistoryModal from "./components/historyModal";
import {
    _withholdComplainList,
    _withholdUpdateComplain
} from "@/statics/js/api"
import {
    Button,
    Modal,
    Input,
    message
} from "antd";
const { TextArea } = Input;

const getColumns = ({handleHistory,handleRefuse,handleAgree})=>{
    const columns = [
        {
            title: "订单号",
            dataIndex: "orderSn",
        },
        {
            title: "代理商",
            dataIndex: "fname",
        },
        {
            title: "站点",
            dataIndex: "sname",
        },
        {
            title: "申诉状态",
            dataIndex: "statusName",
        },
        {
            title: "申诉原因",
            dataIndex: "userReason",
        },
        {
            title: "申诉扣款期数",
            dataIndex: "period",
        },
        {
            title: "申诉金额",
            dataIndex: "money",
        },
        {
            title: "申诉时间",
            dataIndex: "addTime",
        },
        {
            title: "申诉人姓名",
            dataIndex: "realName",
        },
        {
            title: "申诉人手机号",
            dataIndex: "mobile",
        },
        {
            title: "操作",
            render: (text) => {
                return (
                    <span>
                        {
                            text.status ? 
                            <Button type="link" onClick={()=>handleHistory(text)}>处理历史</Button>
                            :
                            <>
                                <Button type="link" onClick={()=>handleRefuse(text)}>拒绝</Button>
                                <Button type="link" onClick={()=>handleAgree(text)}>同意</Button>
                            </>
                        }
                    </span>
                );
            },
        },
    ]
    return columns
}

const Index = (props)=> {
    const tableRef = useRef();
    const historyRef = useRef();
    const browserHistory = useHistory();
    const handleSearch = async (params)=>{
        tableRef.current.handleSearch(params)
    }
    const handleRefuse = async ({id})=>{
        let merchantReason = ''
        Modal.confirm({
            title: `拒绝申诉`,
            content: (
                <TextArea rows={4} onChange={(e)=>{merchantReason = e.target.value}} placeholder='请输入申诉处理的内容' />
            ),
            onOk: async ()=> {
                const params = {
                    id,
                    status: 3,
                    merchantReason,
                }
                const res = await _withholdUpdateComplain(params)
                const {code,message:msg} = res.data || {}
                if(code != 200){
                    message.error(msg);
                    return
                }
                tableRef.current.handleRefresh()
            }
        })
    }
    const handleAgree = async ({orderSn})=>{
        browserHistory.push({
            pathname: "/exceptionOrderList",
            query: { 
                createByOrderSn: orderSn,
            },
        });
    }
    const handleHistory = async ({id})=>{
        historyRef.current.open({id})
    }
    const columns = getColumns({
        handleHistory,
        handleRefuse,
        handleAgree
    })
    return (
        <CommonLayout header={
            <>
                <SearchBox search={handleSearch} />
            </>
        }>
            <CommonTable
                ref={tableRef}
                action={_withholdComplainList}
                fixedScroll
                options={{
                    columns: columns,
                }}
            />
            <HistoryModal ref={historyRef} />
        </CommonLayout>
    )
}

export default Index