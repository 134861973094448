import React, { Component } from "react";
import {
  Button,
  Table,
  Switch,
  Divider,
  Modal,
  message,
  Tabs,
  Input,
  Select,
  Icon,
  Form,
  Row,
  Col,
  DatePicker,
  Radio,
} from "antd";
import {
  _getBatteryTypeList,
  _batteryModelList,
  _bikeModelList,
  _franchiseeList,
  _getGpsTypeList,
  _expressRecordList,
  _exportExpressRecord,
  _exportRecordDetailList,
  _expressRecordDetail,
  _updateExpressStatus,
  _updateDetailStatus,
  _saveExpressDetail,
  _saveExpressRecord,
  _batchAddDetail,
  _updateBillDate,
} from "@/statics/js/api";
import { getJurisdiction } from "@/statics/js/jurisdiction";
import FranchiseeSelect from "@/components/franchiseeSelect";
import SiteSelect from "@/components/siteSelect";
import Highlighter from "react-highlight-words";
import moment from "moment";

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { TextArea } = Input;
const modalTitle = ["添加物流单", "编辑物流单"];

class DateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      initialValues: {},
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      confirmLoading: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    let { visible, curRow } = nextProps;
    if (visible) {
      if (curRow.billDate) {
        this.setState({
          id: curRow.id,
          initialValues: {
            billDateStr: curRow.billDate,
            billPeriod: curRow.billPeriod,
            billMonth: curRow.billMonth,
            billStatus: curRow.billStatus,
          },
        });
      } else {
        this.setState({
          id: curRow.id,
          initialValues: {
            billDateStr: undefined,
            billPeriod: 1,
            billMonth: 1,
            billStatus: 1,
          },
        });
      }
    }
  }
  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      const params = {
        billDateStr: moment(values.billDateStr).format("YYYY-MM-DD"),
        id,
        billPeriod: values.billPeriod,
        billMonth: values.billMonth,
        billStatus: values.billStatus ? 1 : 0,
      };
      this.setState({ confirmLoading: true });
      _updateBillDate(params)
        .then((res) => {
          this.setState({ confirmLoading: false });
          if (res.data.code === 200) {
            message.success("操作成功");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ confirmLoading: false });
        });
    });
  }
  //Modal取消事件
  cancelModal() {
    let { form, close } = this.props;
    form && form.resetFields();
    close();
  }

  render() {
    const {
      visible,
      form: { getFieldDecorator },
    } = this.props;
    const { initialValues, months, confirmLoading } = this.state;
    return (
      <div>
        <Modal
          className="bigModal"
          title="出账日期"
          destroyOnClose
          maskClosable={false}
          visible={visible}
          onOk={this.handleOk.bind(this)}
          onCancel={() => this.cancelModal()}
          confirmLoading={confirmLoading}
        >
          <Row>
            <Col span={24}>
              <Form.Item label="开始计费日期">
                {getFieldDecorator("billDateStr", {
                  initialValue: moment(initialValues.billDateStr),
                  rules: [{ required: true, message: "请选择开始计费日期！" }],
                })(
                  <DatePicker
                    format="YYYY-MM-DD"
                    disabled={!!initialValues.billDateStr}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="计费周期">
                {getFieldDecorator("billPeriod", {
                  initialValue: initialValues.billPeriod,
                  rules: [{ required: true, message: "请选择计费周期！" }],
                })(
                  <Select
                    placeholder="请选择"
                    allowClear
                    disabled={!!initialValues.billDateStr}
                    style={{ width: "100%" }}
                  >
                    {months.map((item, index) => {
                      return (
                        <Option key={index} value={item}>
                          {item}月
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="出账类型"
                help="“当月出账”开始计费日期的当月出账，“当月不出账”开始计费日期的当月不出账"
              >
                {getFieldDecorator("billMonth", {
                  initialValue: initialValues.billMonth,
                  rules: [{ required: true, message: "请选择出账类型！" }],
                })(
                  <Radio.Group disabled={!!initialValues.billDateStr}>
                    <Radio value={1}>当月出账</Radio>
                    <Radio value={0}>当月不出账</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="出账开关">
                {getFieldDecorator("billStatus", {
                  initialValue: initialValues.billStatus === 1,
                  valuePropName: "checked",
                })(<Switch checkedChildren="启用" unCheckedChildren="禁用" />)}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

DateModal = Form.create({})(DateModal);

class LogisticsSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      expressRecordList: [], //物流单列表
      franchiseeList: [], //代理商列表
      batteryTypeList: [], //电池类型列表
      batteryModelList: [], //电池模型列表
      bikeModelList: [], //车辆模型列表
      gpsTypeList: [], //车辆模型列表
      id: "", //物流单id
      initialValues: {}, //物流单详情
      modalType: "", //1-添加，2-编辑
      visible: false, //Modal是否打开
      visibleListing: false, //Modal是否打开（清单）
      searchText: "",
      searchedColumn: "",
      dateVisible: false,
      curRow: {},
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "代理商",
        dataIndex: "",
        render: (text) => (
          <span>
            {text.fname}
            {text.fType == 1 ? <span>（KA）</span> : ""}
          </span>
        ),
      },
      {
        title: "出账时间",
        dataIndex: "",
        width: 200,
        render: (text) => (
          <span>
            {text.billDate ? moment(text.billDate).format("YYYY-MM-DD") : ""}
          </span>
        ),
      },
      {
        title: "物流单号",
        align: "right",
        dataIndex: "expNo",
      },
      {
        title: "车辆型号",
        dataIndex: "bikeModelName",
      },
      {
        title: "车辆数",
        align: "right",
        width: 80,
        dataIndex: "bikeNum",
      },
      {
        title: "入库车辆数",
        align: "right",
        width: 110,
        dataIndex: "exportBikeNum",
      },
      {
        title: "电池型号",
        dataIndex: "dcModelName",
      },
      {
        title: "电池数",
        align: "right",
        width: 80,
        dataIndex: "dcNum",
      },
      {
        title: "入库电池数",
        align: "right",
        width: 110,
        dataIndex: "exportDcNum",
      },
      {
        title: "物流单状态",
        width: 110,
        dataIndex: "expStatusCN",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "创建时间",
        width: 135,
        dataIndex: "addTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(775)}
            title={getJurisdiction(775) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleStateChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 100,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <span>
            <Button
              type="link"
              disabled={!getJurisdiction(775)}
              title={getJurisdiction(775) ? "" : "暂无操作权限！"}
              onClick={this.showModalEntry.bind(
                this,
                text.id,
                text.fid,
                text.sid
              )}
            >
              录入
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(860)}
              title={getJurisdiction(860) ? "" : "暂无操作权限！"}
              onClick={this.getExpressRecordDetail.bind(
                this,
                text.id,
                "detail"
              )}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={!getJurisdiction(861)}
              title={getJurisdiction(861) ? "" : "暂无操作权限！"}
              onClick={this.getExpressRecordDetail.bind(
                this,
                text.id,
                "listing",
                text.fname,
                text.expNo
              )}
            >
              清单
            </Button>
            {!getJurisdiction(1005) ? null : (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={this.changeBillDate.bind(this, text)}
                >
                  出账设置
                </Button>
              </>
            )}
          </span>
        ),
      },
    ];

    this.batteryColumns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 80,
      },
      {
        title: "设备编号",
        align: "right",
        dataIndex: "imei",
        key: "imei",
        ...this.getColumnSearchProps("imei"),
      },
      {
        title: "电池编号",
        align: "right",
        dataIndex: "qcode",
        key: "qcode",
        ...this.getColumnSearchProps("qcode"),
      },
      {
        title: "GPS号",
        align: "right",
        dataIndex: "ccode",
        key: "ccode",
        ...this.getColumnSearchProps("ccode"),
      },
      {
        title: "站点",
        dataIndex: "siteName",
        key: "siteName",
      },
      {
        title: "是否入库",
        width: 100,
        dataIndex: "exportStatus",
        render: (text) => (text === 1 ? "已入库" : "未入库"),
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(775)}
            title={getJurisdiction(775) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleDetailStatusChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "创建时间",
        dataIndex: "addTime",
        width: 135,
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 80,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Button
            type="link"
            disabled={!getJurisdiction(775)}
            title={getJurisdiction(775) ? "" : "暂无操作权限！"}
            onClick={this.showConfirm.bind(this, { ...text })}
          >
            编辑
          </Button>
        ),
      },
    ];

    this.bikeColumns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 80,
      },
      {
        title: "设备编号",
        dataIndex: "imei",
        align: "right",
        key: "imei",
        ...this.getColumnSearchProps("imei"),
      },
      {
        title: "车辆二维码",
        dataIndex: "qcode",
        align: "right",
        width: 120,
        key: "qcode",
        ...this.getColumnSearchProps("qcode"),
      },
      {
        title: "GPS号",
        dataIndex: "ccode",
        align: "right",
        key: "ccode",
        ...this.getColumnSearchProps("ccode"),
      },
      {
        title: "车牌号",
        dataIndex: "vin",
        align: "right",
        key: "vin",
        ...this.getColumnSearchProps("vin"),
      },
      {
        title: "站点",
        dataIndex: "siteName",
        key: "siteName",
      },
      {
        title: "是否入库",
        width: 100,
        dataIndex: "exportStatus",
        render: (text) => (text === 1 ? "已入库" : "未入库"),
      },
      {
        title: "管理状态",
        width: 100,
        dataIndex: "",
        render: (text) => (
          <Switch
            defaultChecked={!!text.status}
            disabled={!getJurisdiction(775)}
            title={getJurisdiction(775) ? "" : "暂无操作权限！"}
            checkedChildren="启用"
            unCheckedChildren="禁用"
            onChange={this.handleDetailStatusChange.bind(this, text.id)}
          />
        ),
      },
      {
        title: "创建时间",
        width: 135,
        dataIndex: "addTime",
        render: (text) =>
          text && moment.unix(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "",
        width: 80,
        fixed: "right",
        key: "Action",
        render: (text) => (
          <Button
            type="link"
            disabled={!getJurisdiction(775)}
            title={getJurisdiction(775) ? "" : "暂无操作权限！"}
            onClick={this.showConfirm.bind(this, { ...text })}
          >
            编辑
          </Button>
        ),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="物流单号">
              {getFieldDecorator("expNoSearch")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          {localStorage.getItem("type") === "0" && (
            <Col lg={6} md={12} sm={24}>
              <Form.Item label="代理商">
                {getFieldDecorator("fidSearch")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.franchiseeList.length &&
                      this.state.franchiseeList.map((item, index) => {
                        return (
                          <Option key={index} value={item.fid}>
                            {item.fname}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="物流状态">
              {getFieldDecorator("expStatusSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">未发货</Option>
                  <Option value="2">已发货</Option>
                  <Option value="3">未入库</Option>
                  <Option value="4">部分入库</Option>
                  <Option value="5">已入库</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="状态">
              {getFieldDecorator("statusSearch")(
                <Select placeholder="请选择" allowClear>
                  <Option value="">全部</Option>
                  <Option value="1">启用</Option>
                  <Option value="0">禁用</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(858)}
                title={getJurisdiction(858) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`搜索 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          清空
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  changeBillDate = (row) => {
    this.setState({
      dateVisible: true,
      curRow: { ...row },
    });
  };
  closeDate = () => {
    this.setState({
      dateVisible: false,
    });
  };
  render() {
    const {
      expressRecordList,
      initialValues,
      pageSize,
      totalCount,
      curPage,
      dateVisible,
      curRow,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {!!getJurisdiction(857) ? (
          <div className="LogisticsSingle">
            <div className="select-form">{this.renderForm()}</div>
            <div className="top-btn">
              <Button
                icon="plus"
                type="primary"
                disabled={!getJurisdiction(775)}
                title={getJurisdiction(775) ? "" : "暂无操作权限！"}
                onClick={this.showModal.bind(this, 1)}
              >
                添加
              </Button>
            </div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={expressRecordList}
                scroll={{ x: 2000, y: 500 }}
                pagination={{
                  showQuickJumper: true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
            <Modal
              className="bigModal"
              title="录入"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleEntry}
              onOk={this.handleOkEntry.bind(this)}
              onCancel={this.initFormModalEntry.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {getFieldDecorator("fid")(
                      <FranchiseeSelect
                        disabled
                        onChange={() => {
                          this.props.form.setFieldsValue({ sid: "" });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="站点">
                    {getFieldDecorator("sid")(
                      <SiteSelect fid={this.props.form.getFieldValue("fid")} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车辆型号">
                    {getFieldDecorator("bikeModelId")(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.bikeModelList.length &&
                          this.state.bikeModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车辆GPS">
                    {getFieldDecorator("gpsType")(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        {this.state.gpsTypeList.length &&
                          this.state.gpsTypeList.map((item, index) => {
                            return (
                              <Option key={index} value={item.gpsType}>
                                {item.gpsName}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车辆编号">
                    {getFieldDecorator("bikeList")(
                      <TextArea
                        placeholder="请输入编码，一行一个"
                        autoSize={{ minRows: 10, maxRows: 15 }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电池型号">
                    {getFieldDecorator("dcModelId")(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.batteryModelList.length &&
                          this.state.batteryModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电池类型">
                    {getFieldDecorator("batteryType")(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        {this.state.batteryTypeList.length &&
                          this.state.batteryTypeList.map((item, index) => {
                            return (
                              <Option key={index} value={item.batteryType}>
                                {item.batteryName}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="电池编号">
                    {getFieldDecorator("batteryList")(
                      <TextArea
                        placeholder="请输入编码，一行一个"
                        autoSize={{ minRows: 10, maxRows: 15 }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title={modalTitle[this.state.modalType - 1]}
              destroyOnClose
              maskClosable={false}
              visible={this.state.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.initFormModal.bind(this)}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label="代理商">
                    {getFieldDecorator("fid", {
                      initialValue: initialValues.fid,
                      rules: [{ required: true, message: "请选择代理商！" }],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        disabled={localStorage.getItem("type") != 0}
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.franchiseeList.length &&
                          this.state.franchiseeList.map((item, index) => {
                            return (
                              <Option key={index} value={item.fid}>
                                {item.fname}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="物流单号">
                    {getFieldDecorator("expNo", {
                      initialValue: initialValues.expNo,
                      rules: [{ required: true, message: "请输入物流单号！" }],
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="车辆型号">
                    {getFieldDecorator("bikeModelId", {
                      initialValue: initialValues.bikeModelId,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        onChange={this.handleIsBikeChange.bind(this)}
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.bikeModelList.length &&
                          this.state.bikeModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {this.state.isBike && (
                  <Col span={24}>
                    <Form.Item label="车辆数">
                      {getFieldDecorator("bikeNum", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的整数",
                            pattern: /^[+]{0,1}(\d+)$/,
                          },
                          { required: true, message: "请输入车辆数！" },
                        ],
                        initialValue: initialValues.bikeNum,
                      })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.modalType === 2 && (
                  <Col span={24}>
                    <Form.Item label="入库车辆数">
                      {getFieldDecorator("exportBikeNum", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的整数",
                            pattern: /^[+]{0,1}(\d+)$/,
                          },
                        ],
                        initialValue: initialValues.exportBikeNum,
                      })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="电池型号">
                    {getFieldDecorator("dcModelId", {
                      initialValue: initialValues.dcModelId,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                        showSearch
                        onChange={this.handleIsBatteryChange.bind(this)}
                        filterOption={(input, option) => {
                          return (
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {this.state.batteryModelList.length &&
                          this.state.batteryModelList.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {this.state.isBattery && (
                  <Col span={24}>
                    <Form.Item label="电池数">
                      {getFieldDecorator("dcNum", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的整数",
                            pattern: /^[+]{0,1}(\d+)$/,
                          },
                          { required: true, message: "请输入电池数！" },
                        ],
                        initialValue: initialValues.dcNum,
                      })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                {this.state.modalType === 2 && (
                  <Col span={24}>
                    <Form.Item label="入库电池数">
                      {getFieldDecorator("exportDcNum", {
                        validateTrigger: "onBlur",
                        rules: [
                          {
                            message: "请输入正确的整数",
                            pattern: /^[+]{0,1}(\d+)$/,
                          },
                        ],
                        initialValue: initialValues.exportDcNum,
                      })(<Input allowClear placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="物流单状态">
                    {getFieldDecorator("expStatus", {
                      initialValue: initialValues.expStatus,
                      rules: [
                        { required: true, message: "请选择物流单状态！" },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="请选择"
                        allowClear
                      >
                        <Option value={1}>未发货</Option>
                        <Option value={2}>已发货</Option>
                        <Option value={3}>未入库</Option>
                        <Option value={4}>部分入库</Option>
                        <Option value={5}>已入库</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="备注">
                    {getFieldDecorator("remark", {
                      initialValue: initialValues.remark,
                    })(<Input allowClear placeholder="请输入" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="状态">
                    {getFieldDecorator("status", {
                      initialValue: initialValues.status === 0 ? false : true,
                      valuePropName: "checked",
                    })(
                      <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
            <Modal
              className="bigModal"
              title="物流单清单"
              destroyOnClose
              maskClosable={false}
              visible={this.state.visibleListing}
              onOk={this.handleOkListing.bind(this)}
              onCancel={this.initModalListing.bind(this)}
            >
              <p>代理商：{this.state.fname}</p>
              <p>物流单号：{this.state.expNo}</p>
              <Tabs defaultActiveKey="1">
                <TabPane tab="车辆列表" key="1">
                  <Button
                    onClick={this.handleExportDataChange.bind(
                      this,
                      this.state.id,
                      this.state.expNo,
                      1
                    )}
                    style={{ marginBottom: 10 }}
                    loading={this.state.exportload1}
                  >
                    导出
                  </Button>
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.bikeColumns}
                    scroll={{ x: 1000, y: 500 }}
                    dataSource={this.state.initialValues.bikeList}
                  />
                </TabPane>
                <TabPane tab="电池列表" key="2">
                  <Button
                    onClick={this.handleExportDataChange.bind(
                      this,
                      this.state.id,
                      this.state.expNo,
                      2
                    )}
                    style={{ marginBottom: 10 }}
                    loading={this.state.exportload2}
                  >
                    导出
                  </Button>
                  <Table
                    rowKey="id"
                    bordered
                    columns={this.batteryColumns}
                    scroll={{ x: 1000, y: 500 }}
                    dataSource={this.state.initialValues.batteryList}
                  />
                </TabPane>
              </Tabs>
            </Modal>
            <DateModal
              visible={dateVisible}
              curRow={curRow}
              close={() => this.closeDate()}
            />
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getFranchiseeList();
    this.getBatteryTypeList();
    this.getBatteryModelList();
    this.getGpsTypeList();
    this.getBikeModelList();
    this.getExpressRecordList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取电池型号列表
  getBatteryModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _batteryModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //是否车辆型号
  handleIsBikeChange(value) {
    if (value) {
      this.setState({
        isBike: true,
      });
    } else {
      this.setState({
        isBike: false,
      });
    }
  }
  //是否电池型号
  handleIsBatteryChange(value) {
    if (value) {
      this.setState({
        isBattery: true,
      });
    } else {
      this.setState({
        isBattery: false,
      });
    }
  }

  //获取车辆GPS
  getGpsTypeList() {
    _getGpsTypeList()
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            gpsTypeList: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取车辆型号列表
  getBikeModelList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _bikeModelList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            bikeModelList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取代理商列表
  getFranchiseeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _franchiseeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            franchiseeList: res.data.data.rows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //获取电池类型列表
  getBatteryTypeList() {
    let params = {
      curPage: 1,
      pageSize: 999,
    };
    _getBatteryTypeList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            batteryTypeList: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取物流单列表
  getExpressRecordList(curPage, pageSize, queryFormValues) {
    let params = {
      ...queryFormValues,
      curPage: curPage,
      pageSize: pageSize,
    };
    _expressRecordList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            expressRecordList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getExpressRecordList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.expNoSearch) {
        values.expNo = values.expNoSearch;
        delete values.expNoSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.expStatusSearch) {
        values.expStatus = values.expStatusSearch;
        delete values.expStatusSearch;
      }
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }

      this.setState({
        queryFormValues: values,
      });
      this.getExpressRecordList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.expNoSearch) {
        values.expNo = values.expNoSearch;
        delete values.expNoSearch;
      }
      if (values.fidSearch) {
        values.fid = values.fidSearch;
        delete values.fidSearch;
      }
      if (values.expStatusSearch) {
        values.expStatus = values.expStatusSearch;
        delete values.expStatusSearch;
      }
      if (values.statusSearch) {
        values.status = values.statusSearch;
        delete values.statusSearch;
      }
      let params = {
        ...values,
      };
      _exportExpressRecord(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  // 导出（清单）
  handleExportDataChange(id, expNo, exportListTpye) {
    this.setState({
      [`exportload${exportListTpye}`]: true,
    });
    let params = {
      id,
      expNo,
      exportListTpye,
    };
    _exportRecordDetailList(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            [`exportload${exportListTpye}`]: false,
          });
          window.open(`${res.data.data}`, "_blank").focus();
        } else {
          message.warning(res.data.message);
          this.setState({
            [`exportload${exportListTpye}`]: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取物流单详情
  getExpressRecordDetail(id, type, fname, expNo) {
    let params = { id };
    _expressRecordDetail(params)
      .then((res) => {
        if (res.data.code === 200) {
          this.setState({
            id,
            initialValues: res.data.data,
          });
          if (res.data.data.dcNum) {
            this.setState({
              isBattery: true,
            });
          } else {
            this.setState({
              isBattery: false,
            });
          }
          if (res.data.data.bikeNum) {
            this.setState({
              isBike: true,
            });
          } else {
            this.setState({
              isBike: false,
            });
          }
          if (type === "detail") {
            this.showModal(2);
          } else if (type === "listing") {
            this.showModalListing(fname, expNo);
          } else {
            return;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //状态Switch
  handleStateChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateExpressStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getExpressRecordList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        } else {
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //状态Switch（清单）
  handleDetailStatusChange(id, checked) {
    let params = { id, status: Number(checked) };
    _updateDetailStatus(params)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("操作成功");
          this.getExpressRecordList(
            this.state.curPage,
            this.state.pageSize,
            this.state.queryFormValues
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //修改清单详情
  showConfirm({
    id,
    imei,
    qcode,
    ccode,
    exportStatus,
    deviceType,
    vin,
    siteName,
  }) {
    confirm({
      title: "修改设备信息",
      content: (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="设备编号">
              <Input
                placeholder="请输入"
                onChange={(e) => {
                  imei = e.target.value;
                }}
                defaultValue={imei}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="二维码">
              <Input
                placeholder="请输入"
                onChange={(e) => {
                  qcode = e.target.value;
                }}
                defaultValue={qcode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="GPS号">
              <Input
                placeholder="请输入"
                onChange={(e) => {
                  ccode = e.target.value;
                }}
                defaultValue={ccode}
              />
            </Form.Item>
          </Col>
          {deviceType === 1 && (
            <Col span={24}>
              <Form.Item label="车牌号">
                <Input
                  placeholder="请输入"
                  onChange={(e) => {
                    vin = e.target.value;
                  }}
                  defaultValue={vin}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
              <Form.Item label="站点">
                <Input
                  disabled
                  defaultValue={siteName}
                />
              </Form.Item>
            </Col>
          <Col span={24}>
            <Form.Item label="是否入库">
              <Select
                style={{ width: "100%" }}
                placeholder="请选择"
                defaultValue={exportStatus}
                onChange={(value) => {
                  exportStatus = value;
                }}
              >
                <Option value={1}>已入库</Option>
                <Option value={0}>未入库</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
      onOk() {
        let params = {
          id,
          imei,
          qcode,
          ccode,
          vin,
          exportStatus,
        };
        _saveExpressDetail(params).then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
          } else {
            message.warning(res.data.message);
          }
        });
      },
    });
  }

  //Modal打开事件
  showModal(modalType) {
    this.setState(
      {
        modalType,
        visible: true,
      },
      () => {
        if (this.props.form) {
          this.props.form.resetFields();
          let type = localStorage.getItem("type");
          let fid = localStorage.getItem("fid");
          if (modalType == 1) {
            this.props.form.setFieldsValue({
              fid: type === "0" ? null : Number(fid),
            });
          } else if (modalType == 2) {
            this.props.form.setFieldsValue({
              fid: this.state.initialValues.fid,
            });
          }
        }
      }
    );
  }

  //Modal打开事件
  showModalEntry(id, fid, sid) {
    this.props.form && this.props.form.resetFields();
    this.props.form.setFieldsValue({
      fid,
      sid,
    });
    this.setState({
      id,
      visibleEntry: true,
    });
  }

  //Modal打开事件(清单)
  showModalListing(fname, expNo) {
    !!this.state.initialValues &&
      this.setState({
        fname,
        expNo,
        visibleListing: true,
      });
  }

  //Modal提交事件
  handleOkListing(e) {
    e.preventDefault();
    this.setState({
      fname: null,
      expNo: null,
      visibleListing: false,
      initialValues: {},
    });
  }

  //Modal取消事件
  initModalListing(e) {
    e.preventDefault();
    this.props.form && this.props.form.resetFields();
    this.setState({
      fname: null,
      expNo: null,
      visibleListing: false,
      initialValues: {},
    });
  }

  //Modal提交事件
  handleOk(e) {
    e.preventDefault();
    const { form } = this.props;
    const { modalType, id } = this.state;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.status !== undefined) {
        values.status = Number(values.status);
      }
      let params =
        modalType === 1
          ? { ...values }
          : modalType === 2
          ? {
              id,
              ...values,
            }
          : {};
      _saveExpressRecord(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visible: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal提交事件
  handleOkEntry(e) {
    e.preventDefault();
    const { form } = this.props;
    const { id } = this.state;
    form.validateFields((err, values) => {
      // if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      delete values.status;
      if (values.bikeList) {
        let bikeList = values.bikeList.split("\n");
        values.bikeList = Array.from(new Set(bikeList));
      }
      if (values.batteryList) {
        let batteryList = values.batteryList.split("\n");
        values.batteryList = Array.from(new Set(batteryList));
      }

      let params = {
        expId: id,
        ...values,
      };
      _batchAddDetail(params)
        .then((res) => {
          if (res.data.code === 200) {
            message.success("操作成功");
            this.setState({
              visibleEntry: false,
            });
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            message.warning(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  //Modal取消事件
  initFormModalEntry(e) {
    e.preventDefault();
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: null,
      initialValues: {},
      visibleEntry: false,
    });
  }

  //Modal取消事件
  initFormModal(e) {
    e.preventDefault();
    this.props.form && this.props.form.resetFields();
    this.setState({
      id: null,
      initialValues: {},
      isBattery: false,
      isBike: false,
      visible: false,
    });
  }
}
LogisticsSingle = Form.create({})(LogisticsSingle);
export { LogisticsSingle };
