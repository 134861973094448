import React,{
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from "echarts";
import "echarts-gl";
import mapWithoutHainanIslands from './mapWithoutHainanIsLands.json'

const Index = forwardRef((props,ref) => {
    const myChart = useRef()
    useEffect(()=>{
        echarts.registerMap('china', mapWithoutHainanIslands);
        myChart.current = echarts.init(document.getElementById('map3d'))
        init({})
    },[])
    const init = async ({data=[]})=>{
        let option = {
            geo3D: {
                map: 'china',
                roam: true,
                boxWidth: 110,
                boxHeight: 20,
                top: -80,
                viewControl: {
                    alpha: 50,
                    rotateSensitivity: 0,
                    zoomSensitivity: false,
                },
                itemStyle: {
                    color: '#1b57ac',
                    opacity: 1,
                    borderWidth: 0.4,
                    borderColor: '#F2F6FC',
                },
                label: {
                    show: true,
                    textStyle: {
                        color: '#fff', //地图初始化区域字体颜色
                        fontSize: 10,
                        opacity: 1,
                        backgroundColor: 'rgba(0,23,11,0)'
                    },
                },
                shading: 'lambert',
                light: { //光照阴影
                    ambient: {
                        intensity: 0.6
                    }
                }
            },
            series: [
                //柱状图
                {
                    name: "名字",
                    type: "bar3D",
                    coordinateSystem: "geo3D",
                    barSize: 1.4,
                    shading: "lambert",
                    bevelSize: 0,
                    itemStyle: {
                        color: "rgba(39,169,231,0.4)",
                    },
                    emphasis: {
                        itemStyle: {
                            color: 'red'
                        },
                        label: {
                            formatter: (record) => {
                                return `${record.name}\n用户：${record.data.number}`;
                            },
                            color: "#fff",
                            distance: .2,
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            padding: 4,
                            borderRadius: 4,
                        }
                    },
                    label: {
                        show: true,
                        textStyle: {
                            color: '#fff',
                        },
                        formatter: (record) => {
                            return `${record.name}`;
                        },
                    },
                    data: data
                },
            ],
        };
        myChart.current.setOption(option);
    }
    useImperativeHandle(ref, () => ({
        init,
    }),[]);
    return (
        <div id="map3d" style={{width: '100%',height: '100%'}}></div>
    )
})

export default Index
