import React,{
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as echarts from 'echarts';
var myChart = null
const Index = forwardRef((props,ref) => {

    useEffect(()=>{
        myChart = echarts.init(document.getElementById('bar_chart'));
    },[])

    useImperativeHandle(ref, () => ({
        init,
    }),[
        init,
    ]);
    
    var init = (data)=>{
        let max = Math.max(...data);
        let min = Math.min(...data);
        let hasMax = false,
          hasMin = false;
        let TimeXScale = [];
        let cellList = data.map((item, index) => {
          TimeXScale.push(index + 1);
          if (item == max && !hasMax) {
            hasMax = true;
            return {
              value: item,
              itemStyle: {
                color: "#FFB800",
              },
            };
          }
          if (item == min && !hasMin) {
            hasMin = true;
            return {
              value: item,
              itemStyle: {
                color: "#1E9FFF",
              },
            };
          }
          return item;
        });
        var options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: ['电芯电压']
            },
            grid: {
                left: '2%',
                right: '2%',
                bottom: '10%',
                containLabel: true
            },
            xAxis: [
                {
                    data: TimeXScale
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} mV'
                    },
                    splitLine: {
                        lineStyle: {
                          type: "dashed",
                          color:"rgba(255,255,255,.1)"
                        },
                    }
                }
            ],
            series: [
                {
                    name: '电压(mV)',
                    type: 'bar',
                    stack: '总量',
                    data: cellList,
                    color: ['rgb(78,115,250)'],
                    markPoint:{
                        label: {
                            show: true,
                            position: "top",
                            distance: 7,
                            offset: [1, 1],
                            formatter: '{c} mV',
                            color: 'white'
                        },
                        data: [
                            {
                                type:'max',
                                name:'最大值',
                                itemStyle:{
                                    color: '#FFB800'
                                }
                            },
                            {
                                type:'min',
                                name:'最小值',
                                itemStyle:{
                                    color: '#1E9FFF'
                                }
                            }
                        ],
                    },
                }
            ]
        };
        myChart.setOption(options)
    }

    return (
        <>
            <div id="bar_chart" style={{height: '100%',width: '100%'}}></div>
        </>
    )
})

export default Index