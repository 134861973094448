import React, { Component } from "react";
import {
  Button,
  Table,
  Form,
  Input,
  DatePicker,
  message,
  Row,
  Col,
} from "antd";
import {
  _bikeTransferList,
  _exportTransferLogBike,
} from "@/statics/js/api";
import moment from "moment";

import { getJurisdiction } from "@/statics/js/jurisdiction";
const RangePicker = DatePicker.RangePicker;
class RealTimeDistributionBike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10, // 每页展示个数
      curPage: 1, //当前页数
      totalCount: 0, //数据总数量
      packageTransferList: [], //套餐即时分润列表
    };
    this.columns = [
      {
        title: "序号",
        dataIndex: "no",
        width: 80,
        render: (text, record, index) =>
          this.state.pageSize * (this.state.curPage - 1) + index + 1,
      },
      {
        title: "车辆编号",
        align: "right",
        dataIndex: "imei",
      },
      {
        title: "订单编号",
        align: "right",
        dataIndex: "orderSn",
      },
      {
        title: "转账金额",
        align: "right",
        width: 100,
        dataIndex: "transferAmount",
        render: (text) => text && text.toFixed(2),
      },
      {
        title: "转账时间",
        width: 120,
        dataIndex: "transferTime",
        render: (text) => text && moment(text).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "转账账号",
        align: "right",
        dataIndex: "transferAccount",
      },
      {
        title: "转账流水号",
        align: "right",
        dataIndex: "tradeNo",
      },
      {
        title: "转账结果",
        width: 100,
        dataIndex: "status",
        render: (text) => (text === 1 ? "成功" : "未转"),
      },
    ];
  }

  // 筛选表头
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="车辆编号">
              {getFieldDecorator("imei")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="订单号">
              {getFieldDecorator("orderSn")(
                <Input allowClear placeholder="请输入" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item label="时间周期">
              {getFieldDecorator("transferTime")(
                <RangePicker format="YYYY-MM-DD" />
              )}
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                onClick={this.handleExportChange.bind(this)}
                disabled={!getJurisdiction(834)}
                title={getJurisdiction(834) ? "" : "暂无操作权限！"}
                style={{ marginLeft: 10 }}
                loading={this.state.exportload}
              >
                导出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
  render() {
    const { totalCount, curPage, pageSize, packageTransferList } = this.state;
    return (
      <div>
        {getJurisdiction(833) ? (
          <div className="RealTimeDistributionBike">
            <div className="select-form">{this.renderForm()}</div>
            <div className="content-table">
              <Table
                rowKey="id"
                bordered
                columns={this.columns}
                dataSource={packageTransferList}
                scroll={{ x: 1200, y: 500 }}
                pagination={{
                  showQuickJumper:true,
                  defaultPageSize: pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.handlePageChange.bind(this),
                  onChange: this.handlePageChange.bind(this),
                  total: totalCount,
                  current: curPage,
                  showTotal: (totalCount) => `总共 ${totalCount} 条记录`,
                }}
              />
            </div>
          </div>
        ) : (
          "暂无操作权限！"
        )}
      </div>
    );
  }
  componentDidMount() {
    this.getPackageTransferList(
      this.state.curPage,
      this.state.pageSize,
      this.state.queryFormValues
    );
  }

  //获取套餐即时分润列表
  getPackageTransferList(curPage, pageSize, queryFormValues) {
    let params = {
      curPage: curPage,
      pageSize: pageSize,
      ...queryFormValues,
    };
    _bikeTransferList(params)
      .then((res) => {
        if (res.data.code === 200) {
          if (!res.data.data.rows.length) {
            message.warning("暂无数据！");
          }
          this.setState({
            packageTransferList: res.data.data.rows,
            totalCount: res.data.data.totalCount,
            curPage: curPage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //分页
  handlePageChange(curPage, pageSize) {
    this.getPackageTransferList(curPage, pageSize, this.state.queryFormValues);
  }

  // 表单筛选
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.transferTime && values.transferTime.length) {
        values.transferStart = moment(values.transferTime[0]).format(
          "YYYY-MM-DD"
        );
        values.transferEnd = moment(values.transferTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.transferTime;
      this.setState({
        queryFormValues: values,
      });
      this.getPackageTransferList(1, this.state.pageSize, values);
    });
  };

  // 导出
  handleExportChange = (e) => {
    e.preventDefault();
    this.setState({
      exportload: true,
    });
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) return;
      for (let key in values) {
        if (
          values[key] === undefined ||
          values[key] === null ||
          values[key] === ""
        ) {
          delete values[key];
        }
      }
      if (values.transferTime && values.transferTime.length) {
        values.transferStart = moment(values.transferTime[0]).format(
          "YYYY-MM-DD"
        );
        values.transferEnd = moment(values.transferTime[1]).format(
          "YYYY-MM-DD"
        );
      }
      delete values.transferTime;
      let params = {
        ...values,
      };
      _exportTransferLogBike(params)
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              exportload: false,
            });
            window.open(`${res.data.data}`, "_blank").focus();
          } else {
            message.warning(res.data.message);
            this.setState({
              exportload: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
}

RealTimeDistributionBike = Form.create({})(RealTimeDistributionBike);
export { RealTimeDistributionBike };
